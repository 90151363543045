import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';

import { classNames } from '@/libs/styleUtils';

import Divider from '@/components/Common/Divider';

import AllCommentItem from '@/components/Items/Comments/All/AllCommentItem';

import useCommentsGroup from '@/hooks/useCommentsGroup';

import useProjects from '@/hooks/useProjects';

export interface IAllCommentListProps {
  className?: string;
  closeDrawer: (value: boolean) => void;
}

/**
 * 全てのコメント一覧
 * @param props
 * @returns
 */
export default function AllCommentList(props: IAllCommentListProps) {
  const { className, closeDrawer } = props;
  const { commentsGroup, loadNextPage, hasNext } = useCommentsGroup();
  const { updateMarkAsRead } = useProjects();

  useEffect(() => {
    // 既読化
    updateMarkAsRead();
  }, []);

  // コメントリストを時差表示しないとInfiniteScrollが正常に発火しない問題の対処
  const [isDelayCommentList, setIsDelayCommentList] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsDelayCommentList(true);
    });
  }, []);

  return (
    <div
      id="allCommentScroll"
      className={classNames(
        'relative flex-1 h-screen px-4 pt-1 pb-6 overflow-y-auto sm:px-6',
        className,
      )}
    >
      {isDelayCommentList && (
        <InfiniteScroll
          scrollThreshold="100px"
          dataLength={commentsGroup.length}
          next={loadNextPage}
          hasMore={hasNext}
          loader={<Divider label={t('読込中')} className="mx-4 mb-3" />}
          scrollableTarget="allCommentScroll"
          initialScrollY={0}
        >
          <ul className="grid grid-cols-1 gap-1">
            {/* コメント本体のレンダリング処理 */}
            {commentsGroup.map((comment) => (
              <AllCommentItem
                key={comment.id}
                comment={comment}
                closeDrawer={closeDrawer}
              />
            ))}
          </ul>
        </InfiniteScroll>
      )}
    </div>
  );
}
