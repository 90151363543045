import { MemberGuestViewItem } from '@/@types/models';
import { IViewRow } from '@/@types/viewItem';
import lodash from 'lodash';
import { DraggableLocation, DropResult } from 'react-beautiful-dnd';

import { INSERT_ITEM_POS } from './const';

export interface IMoveResult {
  item: MemberGuestViewItem;
  statusId: MemberGuestViewItem['statusId'];
  position: string;
}

/**
 * アイテムの移動
 * @param droppedItems // 移動元のstatusのitems
 * @param destinationItems 移動先のstatusのitems
 * @param droppableSource
 * @param dStatus
 * @param destinationSource
 * @returns
 */
export const move = (
  droppedItems: MemberGuestViewItem[],
  destinationItems: MemberGuestViewItem[],
  droppableSource: DraggableLocation,
  dStatus: MemberGuestViewItem['statusId'],
  destinationSource: DraggableLocation,
): IMoveResult => {
  const sourceClone = [...droppedItems];
  // status間の移動
  if (droppableSource.droppableId === destinationSource.droppableId) {
    const targetItem = sourceClone[droppableSource.index];
    // 先頭に移動
    if (destinationSource.index === 0) {
      return {
        item: targetItem,
        statusId: dStatus,
        position: INSERT_ITEM_POS.TOP,
      };
    }
    // 最後尾に移動
    if (destinationSource.index === sourceClone.length) {
      return {
        item: targetItem,
        statusId: dStatus,
        position: INSERT_ITEM_POS.LAST,
      };
    }
    // itemの間に移動
    const idx =
      droppableSource.index > destinationSource.index
        ? destinationSource.index - 1
        : destinationSource.index;
    const destinationItem = sourceClone[idx];
    return {
      item: targetItem,
      statusId: dStatus,
      position: destinationItem.id as string,
    };
  }
  // statusをまたいだ移動
  const [removedItem] = sourceClone.splice(droppableSource.index, 1);
  const destinationItemsClone = lodash.cloneDeep(destinationItems);
  if (destinationSource.index === 0) {
    return {
      item: removedItem,
      statusId: dStatus,
      position: INSERT_ITEM_POS.TOP,
    };
  }
  if (destinationSource.index === destinationItemsClone.length) {
    return {
      item: removedItem,
      statusId: dStatus,
      position: INSERT_ITEM_POS.LAST,
    };
  }
  const forWordItem = destinationItemsClone[destinationSource.index - 1];

  return {
    item: removedItem,
    statusId: dStatus,
    position: forWordItem.id as string,
  };
};

export const onDragEnd = (
  result: DropResult,
  viewRows: IViewRow[],
): IMoveResult | null => {
  const { source, destination, draggableId } = result;
  // リストの外にD&Dした場合は何もしない
  if (!destination) {
    return null;
  }
  const sIndex = +source.droppableId;
  const dIndex = +destination.droppableId;
  const moveResult = move(
    viewRows[sIndex].viewItems,
    viewRows[dIndex].viewItems,
    source,
    viewRows[dIndex].status.id,
    destination,
  );

  // ドラッグしたアイテムIDと移動対象のアイテムが異なる場合何もしない（同時編集対策）
  if (moveResult.item.id !== draggableId) {
    return null;
  }

  return moveResult;
};
