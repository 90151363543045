import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';
import React from 'react';

import Logo from '@/components/Common/Logo';
import LogoTitle from '@/components/Common/LogoTitle';
import NotificationDropdown from '@/components/Notification/NotificationDropdown';

interface IGlobalHeaderProps {
  onSlideMenuClick: () => void;
}

export default function GlobalHeader(props: IGlobalHeaderProps) {
  const { onSlideMenuClick } = props;
  return (
    <div className="sticky top-0 z-10 flex items-center flex-shrink-0 h-16 shadow bg-gray-50 dark:bg-gray-800 md:hidden">
      <button
        type="button"
        className="h-8 px-4 text-gray-400 border-r border-gray-200 dark:border-gray-700 dark:text-gray-500 hover:text-primary-500 dark:hover:text-gray-300 md:hidden"
        onClick={() => onSlideMenuClick()}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
      </button>

      <div className="justify-center w-auto h-8 pl-5">
        <Logo className="w-auto h-8" />
      </div>
      <div className="justify-center w-auto h-8 pl-3 mt-3">
        <LogoTitle className="w-auto h-5" />
      </div>

      <div className="flex justify-between flex-1 px-4">
        <div className="flex flex-1" />
        <div className="flex items-center ml-4 md:ml-6">
          {/* <NotificationDropdown /> */}
          <NotificationDropdown className="py-3" iconOnly />
        </div>
      </div>
    </div>
  );
}
