import { RootState } from '@/@types/models';
import {
  CodeBracketIcon,
  FaceSmileIcon,
  LinkIcon,
  CommandLineIcon,
} from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { $getRoot, $getSelection, FORMAT_TEXT_COMMAND } from 'lexical';
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactChild,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import {
  closeProjectDescriptionEmojiPopup,
  openProjectDescriptionEmojiPopup,
} from '@/reducers/currentProjectReducer';

import EmojiPicker from '@/components/Common/EmojiPicker';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import IconBtn from '@/components/Common/Icon/IconBtn';
import {
  // AlertBannedOutlineIcon,
  // AlertOutlineIcon,
  BoldOutlineIcon,
  BulletListIcon,
  ItalicOutlineIcon,
  MarkerOutlineIcon,
  NumberedListIcon,
  StrikethroughOutlineIcon,
  UnderlineOutlineIcon,
} from '@/components/Common/Icon/Icons';
import useLexicalMonitor from '@/components/Common/Lexical/utils/useLexicalMonitor';

export interface IProjectDescriptionToolbarHandler {
  send: () => void;
  setShouldAlert: (shouldAlert: boolean) => void;
}

type IProjectDescriptionToolbarProps = ComponentPropsWithoutRef<'div'> & {
  children: ReactChild;
  canSend: boolean;
  onSend: (jsonState: string, textState: string, shouldAlert: boolean) => void;
  onCancel: () => void;
};

const ProjectDescriptionToolbar = forwardRef<
  IProjectDescriptionToolbarHandler,
  IProjectDescriptionToolbarProps
>((props: IProjectDescriptionToolbarProps, ref) => {
  const { t } = useTranslation();
  const toolbarRef = useRef(null);
  const { canSend, children, onSend, onCancel } = props;
  const [editor] = useLexicalComposerContext();
  const dispatch = useDispatch();
  const isDescriptionEmojiOpen = useSelector(
    (state: RootState) => state.projectState?.isDescriptionEmojiOpen,
  );
  // エディタ用ローカルステート
  const [shouldAlert, setShouldAlert] = useState(false);
  const [pickerPos, setPickerPos] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const emojiRef = useRef<HTMLSpanElement | null>(null);

  // Lexicalの状態監視用
  const {
    isLink,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    isCode,
    isQuote,
    isMarker,
    blockType,
    toggleLinkMode,
    formatQuote,
    formatMarker,
    formatBulletList,
    formatNumberedList,
  } = useLexicalMonitor(editor);

  const closeEmojiPicker = useCallback(() => {
    dispatch(closeProjectDescriptionEmojiPopup());
  }, []);

  const openEmojiPicker = useCallback(() => {
    dispatch(openProjectDescriptionEmojiPopup());
  }, []);

  /**
   * 絵文字の挿入処理
   */
  const onEmojiSelect = useCallback((emoji: any) => {
    editor.focus();
    editor.update(() => {
      const selection = $getSelection();
      selection?.insertRawText(emoji.native);
    });
    closeEmojiPicker();
  }, []);

  /**
   * 絵文字ピッカーの表示/非表示トグル
   */
  const toggleEmojiPicker = () => {
    const OFFSET = 50;
    const EMOJI_POPUP_RECT = { width: 352, height: 435 };
    editor.update(() => {
      // ポジション初期値
      const pos = { top: OFFSET, right: 0, bottom: 0, left: 0 };
      // 絵文字ボタンのポジション
      const pCoords = emojiRef.current?.getBoundingClientRect();
      const top = pCoords?.top || 0;
      const left = pCoords?.left || 0;
      // ウィンドウ領域
      const windowRect =
        window.document.documentElement.getBoundingClientRect();
      pos.top =
        top > EMOJI_POPUP_RECT.height
          ? EMOJI_POPUP_RECT.height * -1
          : (EMOJI_POPUP_RECT.height -
              OFFSET -
              (EMOJI_POPUP_RECT.height - top)) *
            -1;
      pos.left =
        left + EMOJI_POPUP_RECT.width > windowRect.width
          ? 0
          : EMOJI_POPUP_RECT.width - OFFSET;
      setPickerPos(pos);
      (isDescriptionEmojiOpen ? closeEmojiPicker : openEmojiPicker)();
    });
  };

  /**
   * 保存処理
   */
  const save = useCallback(() => {
    if (!canSend) return;
    const editorState = editor.getEditorState();
    const jsonState = JSON.stringify(editorState.toJSON());

    editorState.read(() => {
      const root = $getRoot();
      const textState = root.getTextContent();
      onSend(jsonState, textState, shouldAlert);
    });
  }, [canSend, shouldAlert, onSend]);

  const iconCommonClass = 'text-gray-400 w-4 h-4';

  /** 親コンポーネントから送信処理を実行 */
  useImperativeHandle(ref, () => ({
    send() {
      save();
    },
    setShouldAlert(st: boolean) {
      setShouldAlert(st);
    },
  }));

  return (
    <div ref={toolbarRef} className="flex-col">
      {/* エディターコンテンツ */}
      {children}

      {/* ツールバー下部 */}
      <div className="flex items-center py-2">
        <div className="flex-1 w-full mr-auto space-x-1">
          {/* ボールド */}
          <IconBtn
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
            tooltipMessage={t('太字')}
            active={isBold}
          >
            <BoldOutlineIcon className={iconCommonClass} />
          </IconBtn>

          {/* イタリック */}
          <IconBtn
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
            }
            tooltipMessage={t('斜体')}
            active={isItalic}
          >
            <ItalicOutlineIcon className={iconCommonClass} />
          </IconBtn>

          {/* アンダーライン */}
          <IconBtn
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
            }
            tooltipMessage={t('下線')}
            active={isUnderline}
          >
            <UnderlineOutlineIcon className={iconCommonClass} />
          </IconBtn>

          {/* 打消し */}
          <IconBtn
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
            }
            tooltipMessage={t('打消し線')}
            active={isStrikethrough}
          >
            <StrikethroughOutlineIcon className={iconCommonClass} />
          </IconBtn>

          {/* 引用 他に引用らしいアイコンが見つからない */}
          <IconBtn
            onClick={formatQuote}
            tooltipMessage={t('引用')}
            active={isQuote}
          >
            <CommandLineIcon className={iconCommonClass} />
          </IconBtn>

          {/* コード */}
          <IconBtn
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')}
            tooltipMessage={t('コード')}
            active={isCode}
          >
            <CodeBracketIcon className={iconCommonClass} />
          </IconBtn>

          {/* マーカー */}
          <IconBtn
            onClick={formatMarker}
            tooltipMessage={t('マーカー')}
            active={isMarker}
          >
            <MarkerOutlineIcon className={iconCommonClass} />
          </IconBtn>

          {/* リスト */}
          {/* 箇条書きリスト */}
          <IconBtn
            onClick={() => formatBulletList()}
            tooltipMessage={t('箇条書きリスト')}
            active={blockType === 'bullet'}
          >
            <BulletListIcon className={iconCommonClass} />
          </IconBtn>

          {/* 数字付きリスト */}
          <IconBtn
            onClick={() => formatNumberedList()}
            tooltipMessage={t('数字付きリスト')}
            active={blockType === 'number'}
          >
            <NumberedListIcon className={iconCommonClass} />
          </IconBtn>

          {/* リンク */}
          <IconBtn
            onClick={toggleLinkMode}
            tooltipMessage={t('リンク')}
            active={isLink}
          >
            <LinkIcon className={iconCommonClass} />
          </IconBtn>

          {/* 絵文字 */}
          <span ref={emojiRef}>
            <IconBtn onClick={toggleEmojiPicker} tooltipMessage={t('絵文字')}>
              <FaceSmileIcon className={iconCommonClass} />
            </IconBtn>
          </span>
        </div>

        <div className="flex items-center">
          <div className="ml-2">
            <MenuButton
              id="cancelEditDescription"
              type="text"
              className="w-auto !px-3 !py-1"
              onClick={onCancel}
            >
              {t('キャンセル')}
            </MenuButton>
          </div>

          <div className="ml-2">
            <MenuButton
              id="applyEditDescription"
              type="text"
              variant="primary"
              className="w-auto !px-3 !py-1"
              disabled={!canSend}
              onClick={save}
            >
              {t('更新')}
            </MenuButton>
          </div>
        </div>
      </div>

      {/* ツールバー触れなくなるので 固定でエディタの上に出した方が良いかも */}
      {isDescriptionEmojiOpen ? (
        <EmojiPicker
          onEmojiSelect={onEmojiSelect}
          coords={pickerPos}
          onClickOutside={() => {
            closeEmojiPicker();
          }}
        />
      ) : (
        <div />
      )}
    </div>
  );
});
export default ProjectDescriptionToolbar;
