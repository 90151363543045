import { Presence, RootState } from '@/@types/models';
import { ThenableReference } from '@firebase/database-types';
import { useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFirebase, useFirestoreConnect } from 'react-redux-firebase';

import { COL_PRESENCES, getPresencesPath } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

interface IRtdPresence {
  workspaceId: string;
  projectId: string;
}

export default function usePresence() {
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject, myProjects } = useProjects();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const rrfFirebase = useFirebase();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.id,
    [currentMyWorkspace?.id],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const connection = useRef<{
    listener: number | null;
    ref: ThenableReference | null;
  }>({ listener: null, ref: null });

  useEffect(() => {
    if (!userId || !projectId || !workspaceId || rrfAuth.isAnonymous) return;
    const db = rrfFirebase.database();
    const userConnectionsRef = db.ref(`/connections/${userId}`);
    const connectedInfoRef = db.ref('/info/connected');

    // プレゼンスの構築
    // 参考: https://firebase.google.com/docs/database/web/offline-capabilities#section-sample
    const connect = async () => {
      connectedInfoRef.on('value', async (snapshot) => {
        if (!snapshot.val()) {
          connectedInfoRef.set({ dummy: true });
          return;
        }
        if (!connection.current.ref) {
          connection.current.ref = userConnectionsRef.push();
        }
        await connection.current.ref.onDisconnect().remove();
        const data: IRtdPresence = {
          workspaceId: workspaceId as string,
          projectId: projectId as string,
        };
        await connection.current.ref.set(data);
      });
    };
    connect().catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });

    // ...
  }, [projectId]);

  useEffect(
    () => () => {
      // unmountされるタイミングで保持しているプレゼンスを削除
      if (connection.current.ref) {
        connection.current.ref.remove();
      }
    },
    [],
  );

  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !projectId) return [];
    return myProjects.map((p) => ({
      collection: getPresencesPath(workspaceId, p.id),
      storeAs: `${COL_PRESENCES}/${p.id}`,
    }));
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);

  // returnするプロパティオーダー
  const presences: Presence[] = useMemo(
    () => (projectId ? ordered[`${COL_PRESENCES}/${projectId}`] || [] : []),
    [ordered, ordered[`${COL_PRESENCES}/${projectId}`]],
  );

  return { presences };
}
