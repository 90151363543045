import React, { useRef, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

type ITextProps = Omit<JSX.IntrinsicElements['span'], 'children'> & {
  children?: string | null;
  className?: string;
};

export default function EllipsisText(props: ITextProps) {
  const { children, className } = props;
  const refOuter = useRef<HTMLSpanElement>(null);
  const refInner = useRef<HTMLSpanElement>(null);
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);

  // ツールチップ再構成
  useEffect(() => {
    if (isEllipsis) {
      ReactTooltip.rebuild();
    }
  }, [isEllipsis]);

  // リサイズ監視
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const rectOuter = refOuter.current?.getBoundingClientRect();
      const rectInner = refInner.current?.getBoundingClientRect();
      // 外枠 < 内枠 なら省略表記
      setTimeout(() => {
        setIsEllipsis((rectOuter?.width ?? 0) < (rectInner?.width ?? 0));
      });
    });
    const el = refOuter.current as Element;
    resizeObserver.observe(el);
    return () => resizeObserver.unobserve(el);
  });

  return (
    <span
      data-tip={isEllipsis ? children : ''}
      ref={refOuter}
      className={classNames('block truncate', className)}
    >
      <span ref={refInner}>{children}</span>
    </span>
  );
}
