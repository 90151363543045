import { MyProject, RootState } from '@/@types/models';

import { Transition } from '@headlessui/react';
import React, { useEffect, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { ViewTypes } from '@/libs/const';

import Head from '@/components/Common/Head';
import ItemEditDialog from '@/components/Items/ItemEditDialog';
import NewItemDialog from '@/components/Items/NewItemDialog';
import ProjectHeader from '@/components/Project/ProjectHeader';
import { ViewHeaderOverlay } from '@/components/Share/SelectPublishItemsControl';
import BillboardView from '@/components/View/Billboard/BillboardView';
import KanbanView from '@/components/View/Kanban/KanbanView';
import ListView from '@/components/View/List/ListView';

import ViewHeader from '@/components/View/ViewHeader';

import LoadingPage from '@/pages/LoadingPage';

import useGuests from '@/hooks/useGuests';
import useItemOrders from '@/hooks/useItemOrders';
import useMembers from '@/hooks/useMembers';
import useMonitorSubscription from '@/hooks/useMonitorSubscription';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useOrderedProperties from '@/hooks/useOrderedProperties';
import useOrderedStatuses from '@/hooks/useOrderedStatuses';
import useProjects from '@/hooks/useProjects';

import useProperties from '@/hooks/useProperties';
import usePropertyOrders from '@/hooks/usePropertyOrders';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';
import useStatuses from '@/hooks/useStatuses';
import useStatusOrders from '@/hooks/useStatusOrders';
import useViewItems from '@/hooks/useViewItems';
import useViewRows from '@/hooks/useViewRows';
import useViews from '@/hooks/useViews';

/**
 * 非認証アクセスのルーティング制御
 * @returns void
 */
export default function UnauthorizedIndex() {
  const { currentViewItem, viewItems, docName } = useViewItems();
  const { isEditOpen } = useSelector((state: RootState) => state.items);

  // 必要なSubscriptionの開始をチェック
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject, myProjects, updateAccessedProject, isReadOnly } =
    useProjects();
  const { currentView } = useViews();
  const { currentItemOrderList } = useItemOrders();
  const { statuses } = useStatuses();
  const { statusOrder } = useStatusOrders();
  const { properties } = useProperties();
  const { propertyOrder } = usePropertyOrders();
  useMembers();
  useGuests();
  useRemovedMembers();
  useRemovedGuests();

  const { isOpen: isNewItemDialogOpen } = useSelector(
    (state: RootState) => state.newItemDialogState,
  );

  // anyと一緒にすると過剰プロパティチェックが効かなくなるので配列として別に用意
  const monitorTargets: (keyof RootState['firestore']['ordered'])[] = [
    'myWorkspaces',
    'myProjects',
    'members',
  ];

  // 必要なデータのサブスクリプション状況をキーの有無で検知
  // 動的にストア名を振っているものはanyで対応
  const { isSubscriptionPrepared } = useMonitorSubscription([
    ...monitorTargets,
    ...(myProjects.map((p) => `${docName}/${p.projectId}`) as any),
    ...(myProjects.map((p) => `statuses/${p.projectId}`) as any),
    ...(myProjects.map((p) => `statusOrders/${p.projectId}`) as any),
    ...(myProjects.map((p) => `properties/${p.projectId}`) as any),
    ...(myProjects.map((p) => `propertyOrders/${p.projectId}`) as any),
    ...(myProjects.map((p) => `views/${p.projectId}`) as any),
    ...(myProjects.map((p) => `guests/${p.projectId}`) as any),
    ...(myProjects.map((p) => `removedGuests/${p.projectId}`) as any),
    ...(myProjects.map((p) => `itemOrders/${p.projectId}`) as any),
  ]);

  // ステータスをソート
  const orderedStatuses = useOrderedStatuses(statuses, statusOrder);

  // ビュー表示に必要なデータを生成
  const { viewRows, onChangeRowItem } = useViewRows({
    viewItems,
    orderList: currentItemOrderList,
    statuses: orderedStatuses,
  });

  // ビューに表示するプロパティをソート
  const orderedProperties = useOrderedProperties(properties, propertyOrder);

  // ビューの種別
  const currentViewType = useMemo(() => currentView?.viewType, [currentView]);

  // ページタイトル
  const pageTitle = useMemo(
    () => `${currentMyProject?.projectName} - ${currentView?.viewName}`,
    [currentMyProject, currentView],
  );

  // 表示するビューコンポーネントの判定
  const renderView = useMemo(() => {
    switch (currentViewType) {
      case ViewTypes.KANBAN:
        return (
          <KanbanView
            properties={orderedProperties}
            viewRows={viewRows}
            isReadonly={isReadOnly}
            onChangeRowItem={onChangeRowItem}
          />
        );
      case ViewTypes.LIST:
        return (
          <ListView
            properties={orderedProperties}
            viewRows={viewRows}
            isReadonly={isReadOnly}
            onChangeRowItem={onChangeRowItem}
          />
        );
      case ViewTypes.BILLBOARD:
        return (
          <BillboardView
            properties={orderedProperties}
            viewRows={viewRows}
            isReadonly={isReadOnly}
            onChangeRowItem={onChangeRowItem}
          />
        );
      default:
        return <div />;
    }
  }, [currentViewType, orderedProperties, viewRows, isReadOnly]);

  // プロジェクトをアクセス済みにする
  useEffect(() => {
    if (!!currentMyProject?.id && isSubscriptionPrepared) {
      updateAccessedProject(currentMyProject as MyProject);
    }
  }, [currentMyProject?.id, isSubscriptionPrepared]);

  // 必要な処理終了後はサブスクリプション & レンダリング
  if (
    !currentMyWorkspace ||
    !currentMyProject ||
    !currentView ||
    !isSubscriptionPrepared
  ) {
    return <LoadingPage />;
  }

  return (
    <>
      <div className="flex flex-row h-screen">
        <div className="flex flex-col w-full h-[calc(100dvh-4rem)] md:h-full">
          <div className="relative px-4 pt-4">
            <Head title={pageTitle} />
            <ProjectHeader name={currentMyProject?.projectName ?? ''} />
            <ViewHeader />
            <ViewHeaderOverlay />
          </div>
          {renderView}
        </div>
      </div>

      <Transition.Root show={isEditOpen && !!currentViewItem} unmount>
        <ItemEditDialog />
      </Transition.Root>

      <Transition.Root show={isNewItemDialogOpen} unmount>
        <NewItemDialog />
      </Transition.Root>
    </>
  );
}
