import firebase from 'firebase/compat/app';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getAvatarFilesPath } from '@/libs/docPathUtils';
import { classNames } from '@/libs/styleUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

export interface IAvatarIconProps {
  avatarName: string | null | undefined;
  src?: string | null | undefined;
  isAnonymous?: boolean;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | number;
}

/**
 * アバターアイコン
 * @param props
 * @returns
 */
export default function AvatarIcon(props: IAvatarIconProps) {
  const { src, avatarName, isAnonymous, className, size } = props;
  const { currentMyWorkspace } = useMyWorkspaces();

  // アバター画像パス正規表現
  const avatarFilePathRegExp = useMemo(() => {
    if (!currentMyWorkspace || !currentMyWorkspace.workspaceId) return null;
    return new RegExp(
      `^${getAvatarFilesPath(currentMyWorkspace.workspaceId)}/.+$`,
    );
  }, [currentMyWorkspace]);

  const [url, setUrl] = useState<string | undefined>(undefined);
  const [isNotFound, setIsNotFound] = useState<boolean>(true);

  const getAvatarIconName = useCallback(
    () => avatarName?.slice(0, 2).toUpperCase(),
    [avatarName],
  );

  const iconSizeClasses = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'h-4 w-4';
      case 'sm':
        return 'h-6 w-6';
      case 'md':
        return 'h-9 w-9';
      case 'lg':
        return 'h-12 w-12';
      case 'xl':
        return 'h-16 w-16';
      case '2xl':
        return 'h-24 w-24';
      default:
        return `h-${size} w-${size}`;
    }
  }, [size]);

  useEffect(() => {
    const fn = async () => {
      if (!src) {
        setUrl(undefined);
        setIsNotFound(true);
        return;
      }

      // storageにアップロードしたアバター画像
      if (avatarFilePathRegExp && avatarFilePathRegExp.test(src)) {
        const ref = firebase.storage().ref(src);
        const downloadUrl = await ref.getDownloadURL();
        setUrl(downloadUrl);
        setIsNotFound(false);
        return;
      }

      // 外部URL
      setUrl(src);
      setIsNotFound(false);
    };
    try {
      fn();
    } catch (e) {
      setIsNotFound(true);
      throw e;
    }
  }, [src]);

  return (
    <>
      {/* アイコンが設定されている場合 */}
      {!isNotFound && (
        <div
          className={classNames(
            'inline-block rounded-full dark:ring-gray-300 overflow-hidden',
            iconSizeClasses,
            className,
          )}
        >
          <img
            className={classNames('object-cover', iconSizeClasses)}
            decoding="async"
            src={url}
            alt={getAvatarIconName()}
          />
        </div>
      )}
      {/* アイコン設定なしの場合 */}
      {isNotFound && !isAnonymous && (
        <div
          className={classNames(
            'rounded-full dark:ring-gray-300',
            iconSizeClasses,
            className,
          )}
        >
          <svg viewBox="0 0 40 40">
            <circle
              cx="20"
              cy="20"
              r="20"
              className="fill-primary-600 dark:fill-primary-700"
            />
            <text
              x="50%"
              y="50%"
              style={{
                fontSize: '85%',
                fontWeight: 'bold',
                fill: '#ffffff',
              }}
              textAnchor="middle"
              dominantBaseline="central"
            >
              {getAvatarIconName()}
            </text>
          </svg>
        </div>
      )}
      {/* 匿名ゲストの場合 */}
      {isNotFound && isAnonymous && (
        <div
          className={classNames(
            className,
            'rounded-full group-hover:ring-2 ring-white dark:ring-gray-300',
            iconSizeClasses,
          )}
        >
          <svg viewBox="0 0 24 24">
            <circle
              cx="12"
              cy="12"
              r="12"
              className="fill-gray-300 dark:fill-gray-700"
            />
            <path
              fill="#7d7d7d"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
        </div>
      )}
    </>
  );
}
