import { MemberGuestViewItem } from '@/@types/models';
import { Menu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import RelativePortal from 'react-relative-portal';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { COPY_ITEM_RETRY, FUNCTIONS_ERROR_DETAILS } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import { getBaseURL, getNavigatePath, promisedSleep } from '@/libs/utils';

import ItemRepository from '@/repositories/ItemRepository';

import ConfirmDialog from '@/components/Common/ConfirmDialog';

import PopupTransition from '@/components/Common/Transitions/PopupTransition';

import { auth } from '@/firebase';
import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useViewItems from '@/hooks/useViewItems';

export interface IItemDropDownProps {
  className?: string;
  viewItem: MemberGuestViewItem;
  onDeleteItem?: () => void;
}

/**
 * アイテムのドロップダウンメニュー
 * @param props
 * @returns
 */
export default function ItemDropDown(props: IItemDropDownProps) {
  const { t } = useTranslation();
  const { className, viewItem, onDeleteItem } = props;
  const { deleteItem, isItemLimitOver } = useItems();
  const { getIsOwnItem } = useViewItems();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const { isMember, currentMyWorkspace } = useMyWorkspaces();
  const { isReadOnly, currentMyProject } = useProjects();

  const itemRepository = new ItemRepository(
    currentMyWorkspace?.id as string,
    currentMyProject?.id as string,
  );

  const isOwnItem = getIsOwnItem(viewItem.id);

  // アイテム削除処理
  const handleDeleteConfirm = useCallback(
    async (deleteFlg: boolean | undefined, itemId?: string) => {
      if (deleteFlg && itemId) {
        if (onDeleteItem) onDeleteItem();
        await deleteItem(itemId);
      }
      setDeleteDialogOpen(false);
    },
    [setDeleteDialogOpen, deleteItem],
  );

  // アイテム複製処理
  const handleCopy = useCallback(async (itemId?: string) => {
    if (itemId) {
      const successMessage = t('アイテムが複製されました');
      const pendingMessage = t('処理中です。');
      const retryMessage = t(
        '複製元のアイテムが更新中のため、複製を再試行します。',
      );
      const errorMessage = t('アイテムの複製に失敗しました');
      let retryCount = 0;
      // eslint-disable-next-line consistent-return
      const fn = async () => {
        try {
          await toast.promise(itemRepository.copyItem(itemId), {
            pending: pendingMessage,
            success: successMessage,
          });
        } catch (err: any) {
          // 移動中のファイルが確実に取得できるようにリトライを行う
          if (err.details === FUNCTIONS_ERROR_DETAILS.ITEM_UPDATE_LOADING) {
            if (retryCount >= COPY_ITEM_RETRY.COUNT) {
              toast.error(errorMessage);
              return;
            }
            if (retryCount === 0) {
              // 複数回エラーが出てしまうので最初の一回のみ表示
              toast.error(retryMessage);
            }
            await promisedSleep(COPY_ITEM_RETRY.DELAY * retryCount);
            await auth.currentUser?.getIdToken(true);
            retryCount += 1;
            await fn();
            return;
          }
          toast.error(errorMessage);
        }
      };
      await fn();
    }
  }, []);

  const {
    workspaceId: urlWorkspaceId,
    projectId: urlProjectId,
    viewId: urlViewId,
  } = useParams();

  const linkUrl = useMemo(
    () =>
      `${getBaseURL()}${getNavigatePath(
        urlWorkspaceId as string,
        urlProjectId,
        urlViewId,
        viewItem.id,
      )}`,
    [urlWorkspaceId, urlProjectId, urlViewId, viewItem],
  );
  return (
    <>
      {' '}
      <Menu
        as="div"
        className={classNames(className, 'relative inline-block text-left')}
      >
        {({ open }) => (
          <>
            <Menu.Button
              className={classNames(
                'flex items-center p-1 text-gray-400 rounded-md hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500',
              )}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <span className="sr-only">Open item options</span>
              <EllipsisVerticalIcon className="w-4 h-4" aria-hidden="true" />
            </Menu.Button>
            <RelativePortal component="div" right={0}>
              <PopupTransition show={open}>
                <Menu.Items
                  static
                  className="absolute right-0 z-20 mt-2 text-gray-800 bg-white shadow-lg min-w-max max-w-max origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 dark:divide-gray-600 dark:text-gray-100"
                >
                  <div className="py-1">
                    <CopyToClipboard
                      text={linkUrl}
                      onCopy={() => {
                        toast.success(t('URLをクリップボードへコピーしました'));
                      }}
                    >
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={(e) => e.stopPropagation()}
                            type="button"
                            className={classNames(
                              active
                                ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                : '',
                              'w-full block px-4 py-2 text-left',
                            )}
                          >
                            {t('アイテムのURLをコピー')}
                          </button>
                        )}
                      </Menu.Item>
                    </CopyToClipboard>
                    {(isMember || !isReadOnly) && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCopy(viewItem.id);
                            }}
                            className={classNames(
                              isItemLimitOver
                                ? 'cursor-not-allowed hover:bg-gray-50/0 bg-gray-50/0 dark:bg-gray-800/100 dark:hover:text-gray-700/100 dark:text-gray-700/100 hover:text-gray-200/100 text-gray-200/100'
                                : '',
                              active
                                ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                : '',
                              'w-full block px-4 py-2 text-left',
                            )}
                            disabled={isItemLimitOver}
                          >
                            {t('アイテムを複製')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {(isMember || (!isReadOnly && isOwnItem)) && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteDialogOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-red-400 dark:bg-red-500 text-white dark:text-white'
                                : '',
                              'w-full block px-4 py-2 text-left',
                            )}
                          >
                            {t('アイテムを削除')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </div>{' '}
                </Menu.Items>
              </PopupTransition>
            </RelativePortal>
          </>
        )}
      </Menu>
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={(deleteFlg) => handleDeleteConfirm(deleteFlg, viewItem.id)}
        title={t('アイテムを削除')}
        positive={t('削除')}
        warning
      >
        <p className="mb-2">
          {t('<itemTitle> を削除しようとしています', {
            itemTitle: (
              <span className="mr-1 font-bold break-all">{viewItem.title}</span>
            ),
          })}
        </p>
        <p>
          {t(
            '削除されたアイテムは復元できません。<br> 本当によろしいですか？',
            { br: <br /> },
          )}
        </p>
      </ConfirmDialog>
    </>
  );
}
