import { IAuthType, IJoinedMember, RootState } from '@/@types/models';
import {
  MinusCircleIcon,
  PlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { UserPlusIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';

import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import { MEMBER_LIMIT } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import AvatarIcon from '@/components/Common/AvatarIcon';
import { Badge } from '@/components/Common/Badge';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import LinkButton from '@/components/Common/Forms/Buttons/LinkButton';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import Input from '@/components/Common/Forms/Input';
import DropDown from '@/components/Member/AuthDropDown';

import useAdminMembers from '@/hooks/useAdminMembers';
import usePlanProtections from '@/hooks/usePlanProtections';
import usePlans from '@/hooks/usePlans';

export type MemberListChangeEvent =
  | 'invite'
  | 'uninvite'
  | 'remove'
  | 'unremove';

export interface InviteDestination {
  id: number;
  email: string | null;
}

export interface IMemberListProps {
  joinedMembers: IJoinedMember[];
  disabledAuthMember?: boolean;
  onChange?: (event?: MemberListChangeEvent) => void;
}

export interface IInviteEmail {
  id: string;
  value: string;
  invitedAt: Date;
}

export interface IChengeAuth {
  id: string;
  value: IAuthType;
}

/**
 * メンバーアカウントリスト
 *     scheme: 削除対象IDs: removeIds: string[], 招待Eメール配列: inviteEmails {id:string, value:string}
 * @param props
 * @returns
 */
export default function MemberMemberList(props: IMemberListProps) {
  const { t } = useTranslation();
  const { joinedMembers, onChange, disabledAuthMember } = props;
  const { control, setValue, getValues, trigger, formState } = useFormContext();
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const { workspaceId: urlWorkspaceId } = useParams();
  const { adminMember } = useAdminMembers();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const { plan } = usePlans();
  const { isShowDowngradeBanner, isOverflowMaxMember } = usePlanProtections();

  /**
   * 管理者ユーザー判定
   */
  const isAdmin = useMemo(() => {
    if (!adminMember.memberList) return false;
    return adminMember.memberList.includes(userId);
  }, [userId, adminMember.memberList]);

  /** 変更イベント Emit */
  function emitChange(event: MemberListChangeEvent) {
    if (onChange) onChange(event);
  }

  /**
   * メンバーの並び替え
   *
   * ソート：管理者 > メンバー、joinedAt ASC
   * ソートロジックを変更する場合はfunctionsの
   * src/functions/pubsub/downgradeBatch.ts
   * のソートロジックも同様に修正すること
   */
  joinedMembers.sort((a, b) => {
    if (a.authType === b.authType) {
      if (!a?.joinedAt) return -1; // ワークスペースの作成者は創生者なのでトップとする
      const at = a?.joinedAt?.toMillis() as number;
      const bt = b?.joinedAt?.toMillis() as number;
      if (at > bt) return 1;
      if (at < bt) return -1;
      return 0;
    }
    if (a.authType === 'administrator') {
      return -1;
    }
    return 1;
  });

  // ダウングレードにより削除するメンバーの選定
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'inviteEmails',
  });

  // 招待できる最大メンバー数
  const memberMaxCount = useMemo(() => plan?.memberMaxCount ?? 0, [plan]);

  // 除外対象アカウント数
  const removeTargetAccounts = joinedMembers.filter(
    (item) =>
      getValues('removeIds')?.includes(item.id) ||
      getValues('inviteRemoveIds')?.includes(item.id),
  ).length;

  // 招待可能アカウント数
  const invocableAccounts = useMemo(() => {
    const diffCount =
      memberMaxCount -
      joinedMembers.length +
      removeTargetAccounts -
      fields.length;
    return diffCount;
  }, [
    joinedMembers.length,
    removeTargetAccounts,
    fields.length,
    memberMaxCount,
  ]);

  // 招待中含む使用済アカウント数
  const usedAccounts = useMemo(
    () => memberMaxCount - invocableAccounts,
    [memberMaxCount, invocableAccounts],
  );

  // メンバーがmax人数存在している状態で除外→新たに招待（この時点で再度max人数）のとき除外キャンセルができないようにする
  const removeCheck = useMemo(
    () =>
      joinedMembers.length + fields.length - removeTargetAccounts >=
      memberMaxCount,
    [joinedMembers.length, removeTargetAccounts, fields.length, memberMaxCount],
  );

  /**
   * 招待追加
   */
  const handleInvite = useCallback(() => {
    append({
      id: new Date().getTime().toString(),
      value: '',
      invitedAt: new Date(),
    } as IInviteEmail);
    trigger();
    setTimeout(() => {
      scrollBottomRef!.current!.scrollIntoView({ behavior: 'smooth' });
    });
    emitChange('invite');
  }, []);

  /**
   * 招待削除
   */
  const handleUnInvite = useCallback((index: number) => {
    remove(index);
    emitChange('uninvite');
  }, []);

  const toggleRemove = useCallback(
    (name: string, id: string) => {
      const arr = [...getValues(name)];
      const list = getValues('changeAuths') as IChengeAuth[];
      if (arr.includes(id)) {
        arr.splice(arr.indexOf(id), 1);
        // HACK: 権限変更ドロップダウンのバリデーションをを反応させるHack
        setValue(
          'changeAuths',
          _.difference(list, [
            {
              id: '',
              value: 'member',
            },
          ]),
          { shouldDirty: true, shouldTouch: true },
        );

        emitChange('unremove');
      } else {
        arr.push(id);

        // HACK: 権限変更ドロップダウンのバリデーションをを反応させるHack
        list.push({
          id: '',
          value: 'member',
        });
        setValue('changeAuths', list, { shouldDirty: true, shouldTouch: true });

        emitChange('remove');
      }
      setValue(name, arr, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setTimeout(() => {
        trigger();
      });
    },
    [formState],
  );

  /**
   * 除外/キャンセル トグル
   */
  const handleToggleRemove = useCallback((joined: boolean, id: string) => {
    if (joined) {
      toggleRemove('removeIds', id);
    } else {
      toggleRemove('inviteRemoveIds', id);
    }
  }, []);

  // アカウント登録率
  const registrationRate = useMemo(
    () => (usedAccounts / memberMaxCount) * 100,
    [usedAccounts, memberMaxCount],
  );

  return (
    <div className="max-w-3xl">
      <div className="flex items-end mb-6">
        <div className="flex-1 mr-4">
          <div className="mb-2 text-xs dark:text-gray-400">
            <div className="flex-1">
              {t('参加中のメンバー数：<usedAccounts> / <memberMaxCount>', {
                usedAccounts,
                memberMaxCount,
              })}
            </div>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className={classNames(
                'transition-all rounded-full h-2.5',
                registrationRate >= 0 && registrationRate < 70
                  ? 'bg-sky-400 dark:bg-sky-700'
                  : '',
                registrationRate >= 70 && registrationRate < 100
                  ? 'bg-originalYellow-light dark:bg-originalYellow'
                  : '',
                registrationRate >= 100 ? 'bg-red-400 dark:bg-red-500' : '',
              )}
              style={{
                width: `${registrationRate > 100 ? 100 : registrationRate}%`,
              }}
            />
          </div>
        </div>
        {isAdmin && (
          <LinkButton
            id="gotoPlan"
            to={`/${urlWorkspaceId}/settings/plans`}
            primary={!invocableAccounts}
          >
            <UserPlusIcon
              className={classNames(
                'w-4 h-4 sm:mr-1',
                invocableAccounts
                  ? ' text-gray-400 dark:text-gray-400 '
                  : 'text-white dark:text-white',
              )}
              aria-hidden="true"
            />
            <div className="hidden sm:block">{t('メンバーの上限を増やす')}</div>
          </LinkButton>
        )}
      </div>
      <div className="max-w-3xl">
        <ul className="w-full">
          {/** Skeleton */}
          {!joinedMembers.length && (
            <li className="py-4">
              <div className="items-center md:flex grid grid-cols-1">
                <div className="flex items-center flex-1 space-x-4">
                  <div className="flex-shrink-0">
                    <Skeleton circle className="!w-9 !h-9" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="mr-0 font-medium truncate md:mr-4">
                      <Skeleton className="!w-40" />
                    </p>
                    <p className="mr-0 text-sm text-gray-500 truncate dark:text-gray-400 md:mr-4">
                      <Skeleton className="!max-w-md" />
                    </p>
                  </div>
                </div>
              </div>
            </li>
          )}
          {/** Substance */}
          {joinedMembers.map((item, index) => {
            const ex =
              getValues('removeIds')?.includes(item.id) ||
              getValues('inviteRemoveIds')?.includes(item.id);
            return (
              <li
                key={item.id}
                className={classNames(
                  ex ? 'bg-gray-50 dark:bg-gray-900' : '',
                  'mb-1 dark:divide-gray-700 rounded-md border dark:border-gray-700 px-4 py-4',
                )}
              >
                <div className="items-center md:flex grid grid-cols-1">
                  <div className="flex items-center flex-1 space-x-4">
                    <div
                      className={classNames(
                        ex ? 'opacity-40' : '',
                        'relative flex-shrink-0',
                      )}
                    >
                      <AvatarIcon
                        src={item.photoURL}
                        avatarName={item.displayName || item.email}
                        size="md"
                      />
                      {((isShowDowngradeBanner && index >= MEMBER_LIMIT.FREE) ||
                        (isOverflowMaxMember && index >= memberMaxCount)) && (
                        <MinusCircleIcon className="absolute inline-block w-4 h-4 m-0 mx-1 text-white align-text-bottom bg-red-400 rounded-full dark:bg-red-700/80 -top-1 -left-3 dark:ring-offset-red-700/80 ring-offset-red-400 ring-offset-1" />
                      )}
                    </div>
                    <div
                      className={classNames(
                        ex ? 'opacity-40' : '',
                        'flex-1 min-w-0',
                      )}
                    >
                      <p className="mr-0 font-medium truncate md:mr-4">
                        {item.displayName}
                      </p>
                      <p className="mr-0 text-sm text-gray-500 truncate dark:text-gray-400 md:mr-4">
                        {item.email}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center mt-4 md:mt-0 space-x-4">
                    {ex ? (
                      <Badge color="red" content={t('除外対象')} />
                    ) : (
                      !item.joined && (
                        <Badge color="blue" content={t('参加待ち')} />
                      )
                    )}
                    {isAdmin && userId !== item.id && (
                      <div>
                        {ex ? (
                          <FormButton
                            id="cancelRemoveMember"
                            disabled={removeCheck}
                            className="py-1 mr-4"
                            onClick={() => {
                              if (item.id)
                                handleToggleRemove(item.joined, item.id);
                            }}
                          >
                            {t('キャンセル')}
                          </FormButton>
                        ) : (
                          <FormButton
                            id="removeMember"
                            className="py-1 mr-4"
                            onClick={() => {
                              if (item.id)
                                handleToggleRemove(item.joined, item.id);
                            }}
                          >
                            {t('除外')}
                          </FormButton>
                        )}
                      </div>
                    )}
                    {item.joined && (
                      <div className={ex ? 'hidden' : ''}>
                        <DropDown
                          uid={item.id as string}
                          defaultValue={item.authType as IAuthType}
                          disabledAuthMember={disabledAuthMember}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
          {fields.map((item, index) => (
            <li key={`invite - email - ${item.id}`} className="px-0 py-2">
              <div className="flex items-center space-x-2">
                <div className="flex-1 min-w-0">
                  <Input
                    idx={index}
                    property="value"
                    key={item.id}
                    name="inviteEmails"
                    placeholder={t('招待先のEメールアドレス')}
                    autoFocus
                  />
                </div>
                <div>
                  <MenuButton
                    id="removeMemberEmailInput"
                    type="icon"
                    onClick={() => handleUnInvite(index)}
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </MenuButton>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {isAdmin && (
        <div className="sticky bottom-0 mt-2 overflow-hidden border rounded-md dark:border-gray-700">
          <FormButton
            id="addInviteMember"
            disabled={invocableAccounts < 1}
            onClick={() => handleInvite()}
            className="flex items-center justify-center border-0 rounded-none outline-none ring-0 focus:ring-0 focus:!ring-offset-0"
          >
            {invocableAccounts ? (
              <>
                <PlusIcon className="w-4 h-4" aria-hidden="true" />
                <div className="ml-2">
                  {t('メンバーを招待 (あと <invocableAccounts>アカウント)', {
                    invocableAccounts,
                  })}
                </div>
              </>
            ) : (
              t('これ以上メンバーを招待できません')
            )}
          </FormButton>
        </div>
      )}
      <div ref={scrollBottomRef} />
    </div>
  );
}
