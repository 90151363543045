import { RootState } from '@/@types/models';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  routeHandleMyWorkspaceQuery,
  getMyWorkspaceList,
} from '@/libs/repositoryUtils';

import { getNavigatePath } from '@/libs/utils';

import PlanRepository from '@/repositories/PlanRepository';

import { setCurrentMyWorkspaceIdAction } from '@/reducers/currentWorkspaceReducer';
import { setIsLoaded } from '@/reducers/uiStateReducer';

import Loading from '@/components/Common/Loading';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useQuery from '@/hooks/useQuery';

function useRouteNavigate() {
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const urlParams = useParams();
  const { workspaceId: originalWorkspaceId } = urlParams;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const resolve = useCallback(async () => {
    if (originalWorkspaceId) {
      // 要求されたWSの存在検証
      const workspaceQuery = routeHandleMyWorkspaceQuery(originalWorkspaceId);
      const myWorkspaceList = await getMyWorkspaceList(userId, workspaceQuery);
      if (myWorkspaceList.length > 0 && myWorkspaceList[0].id) {
        const { workspaceId, joinType } = myWorkspaceList[0];
        // メンバーのみアクセス可能
        if (workspaceId && joinType === 'member') {
          dispatch(setCurrentMyWorkspaceIdAction(workspaceId));
          return dispatch(setIsLoaded(true));
        }
        return navigate('/404');
      }
    }
    return null;
  }, []);

  return {
    resolve,
  };
}

/**
 * チェックアウト
 * @returns
 */
export function Checkout() {
  const navigate = useNavigate();
  const { resolve } = useRouteNavigate();
  // 必要なSubscriptionの開始をチェック
  const { currentMyWorkspace } = useMyWorkspaces();
  const queries = useQuery();

  const planRepository = new PlanRepository();

  useEffect(() => {
    resolve();
  }, []);

  useEffect(() => {
    const fn = async () => {
      // カレントワークスペースが正しく取得できた場合
      if (currentMyWorkspace) {
        await planRepository.createStripeSession({
          workspaceId: currentMyWorkspace.id,
          sessionId: queries.get('session_id') as string,
        });
        navigate(
          `${getNavigatePath(
            currentMyWorkspace?.originalWorkspaceId as string,
          )}/settings/plans`,
          {
            replace: true,
          },
        );
      }
    };
    fn();
  }, [currentMyWorkspace]);

  // ローディング表示
  return <Loading className="w-screen h-screen" />;
}

/**
 * チェックアウト キャンセル
 * @returns
 */
export function CancelCheckout() {
  const navigate = useNavigate();
  const { resolve } = useRouteNavigate();
  // 必要なSubscriptionの開始をチェック
  const { currentMyWorkspace } = useMyWorkspaces();
  const queries = useQuery();

  const planRepository = new PlanRepository();

  useEffect(() => {
    resolve();
  }, []);

  useEffect(() => {
    const fn = async () => {
      // カレントワークスペースが正しく取得できた場合
      if (currentMyWorkspace) {
        await planRepository.removeStripeSession({
          workspaceId: currentMyWorkspace.id,
          sessionId: queries.get('session_id') as string,
        });
        navigate(
          `${getNavigatePath(
            currentMyWorkspace?.originalWorkspaceId as string,
          )}/settings/plans`,
          {
            replace: true,
          },
        );
      }
    };
    fn();
  }, [currentMyWorkspace]);

  // ローディング表示
  return <Loading className="w-screen h-screen" />;
}

export default {};
