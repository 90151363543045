import { CommentReactionGroup, IEmoji } from '@/@types/common';

import { Comment, RootState } from '@/@types/models';

import React, { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { classNames } from '@/libs/styleUtils';
import ua from '@/libs/ua';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import { emojis } from '@/assets/native.jp.json';

import useCommentReactionWriter from '@/hooks/useCommentReactionWriter';

export interface IDefaultReactionButtonProps {
  className?: string;
  emojiId: string;
  commentId: Comment['id'];
  disabled?: boolean;
  reactionGroup: CommentReactionGroup[];
}

/**
 * デフォルトリアクションボタン
 * @param props
 * @returns
 */
export default function DefaultReactionButton(
  props: IDefaultReactionButtonProps,
) {
  const { disabled, emojiId, className, commentId, reactionGroup } = props;
  const { createReaction } = useCommentReactionWriter();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // useAgentによるOS判定
  const isIos = ua.isIOS();

  /** 絵文字オブジェクト */
  const emoji = useMemo(() => (emojis as IEmoji)[emojiId], [emojiId]);

  /** すでにリアクション済み */
  const alreadySelected = useMemo(
    () =>
      !!reactionGroup
        .find((o) => o.emojiId === emojiId)
        ?.row.find((o) => o.uid === userId),
    [reactionGroup, emojiId, userId],
  );

  /** リアクションクリック */
  const handleClick = useCallback(() => {
    createReaction(emojiId, commentId);
  }, [emojiId, commentId]);

  return (
    <MenuButton
      id="editComment"
      type="icon"
      onClick={handleClick}
      disabled={disabled || alreadySelected}
      className={classNames('flex items-center', className)}
    >
      <div
        className={classNames(
          'overflow-hidden leading-none',
          alreadySelected ? 'grayscale opacity-50' : '',
          isIos ? 'w-4 h-4 pt-0.5' : 'w-full h-4',
        )}
      >
        {emoji.skins[0].native}
      </div>
    </MenuButton>
  );
}
