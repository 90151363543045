import { Account, AccountStatus } from '@/@types/models';
import { doc, getDoc } from 'firebase/firestore';

import { REP_ERROR_MESSAGE } from '@/libs/const';
import { getAccountStatusesPath } from '@/libs/docPathUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { firestore } from '@/firestore';

export interface IAccountStatusesRepository {
  findById: (
    uid: Account['uid'],
    isAnonymous: boolean,
  ) => Promise<AccountStatus | null>;
}

export default class AccountStatusesRepository
  implements IAccountStatusesRepository
{
  /**
   * Find an accountStatuses by uid
   * @param {Account['uid']} uid
   * @returns Promise<AccountStatuses | null>
   */
  async findById(
    uid: Account['uid'],
    isAnonymous: boolean,
  ): Promise<AccountStatus | null> {
    if (isAnonymous) return null;
    try {
      const docRef = doc(
        firestore,
        getAccountStatusesPath(uid as string),
        uid as string,
      ).withConverter(fsConverter<AccountStatus>());
      return (await getDoc(docRef)).data() || null;
    } catch (err: any) {
      repositoryError(this.findById.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }
}
