import { ITagManagerDataLayer } from '@/@types/common';

import { useEffect } from 'react';

import TagManager from 'react-gtm-module';

import useAccounts from '@/hooks/useAccounts';
import useFirebaseAuth from '@/hooks/useFirebaseAuth';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePlans from '@/hooks/usePlans';
import useProfiles from '@/hooks/useProfiles';

/** GTMイベント */
const EVENTS = {
  USER_CHANGE: 'User Change',
};

/**
 * GoogleTagManager
 * @param props
 * @returns
 */
export default function GoogleTagManager() {
  const { isAuthenticated } = useFirebaseAuth();

  useEffect(() => {
    TagManager.initialize({
      gtmId: import.meta.env.VITE_GTM_ID,
    });
  }, []);

  /** GTM DataLayer 送出処理 start */
  const { plan } = usePlans();
  const { profile } = useProfiles();
  const { account } = useAccounts();
  const { currentMyWorkspace } = useMyWorkspaces();

  useEffect(() => {
    if (isAuthenticated && currentMyWorkspace && plan && profile && account) {
      const tagManagerArgs: ITagManagerDataLayer = {
        dataLayer: {
          event: EVENTS.USER_CHANGE,
          workspaceName: currentMyWorkspace.workspaceName,
          workspaceId: currentMyWorkspace.id,
          displayName: profile.displayName,
          accountId: account.uid,
          email: account.email,
        },
      };

      if (currentMyWorkspace.joinType === 'member') {
        // メンバーの場合の契約中プラン区分を付加
        tagManagerArgs.dataLayer.planName = plan.type;
      }

      TagManager.dataLayer(tagManagerArgs);
    }
  }, [currentMyWorkspace, plan, profile, account]);
  /** GTM DataLayer 送出処理 end */

  return null;
}
