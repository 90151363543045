import { IFirestoreQuery } from '@/@types/common';
import { View, Workspace } from '@/@types/models';
import { collection, getDocs, query } from 'firebase/firestore';

import { REP_ERROR_MESSAGE } from '@/libs/const';

import { getViewsPath } from '@/libs/docPathUtils';
import { composeAppendQuery } from '@/libs/repositoryUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { firestore } from '@/firestore';

export const ViewCollectionName = 'views';

export interface IViewRepository {
  viewPath: string;
  list: (params?: IFirestoreQuery<View>) => Promise<View[]>;
}

export default class ViewRepository implements IViewRepository {
  viewPath: string;

  constructor(private workspaceId: Workspace['id'], private projectId: string) {
    this.viewPath = getViewsPath(this.workspaceId, this.projectId);
  }

  /**
   * List view docs
   * @returns Promise<View[]>
   */
  async list(params?: IFirestoreQuery<View>): Promise<View[]> {
    const appends = composeAppendQuery(params);
    try {
      const collectionRef = query(
        collection(firestore, this.viewPath).withConverter(fsConverter<View>()),
        ...appends,
      );
      return (await getDocs(collectionRef)).docs.map((d) => {
        const view = d.data();
        view.id = d.id;
        view._projectId = this.projectId;
        return view;
      });
    } catch (err: any) {
      repositoryError(this.list.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * トップビューのIDを取得
   * @returns Promise<View | null>
   */
  async topView(): Promise<View | null> {
    const viewList = await this.list({
      orderBy: 'viewName' as const,
      limit: 1,
    });
    return viewList.length > 0 ? viewList[0] : null;
  }
}
