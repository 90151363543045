import React from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '@/libs/styleUtils';

import { Badge } from '@/components/Common/Badge';

export interface IPlanUpgradeBadgeProps {
  className?: string;
}

/**
 * アップグレードを促すバッジ
 * @param props
 * @returns
 */
export default function PlanUpgradeBadge(props: IPlanUpgradeBadgeProps) {
  const { t } = useTranslation();
  const { className } = props;

  return (
    <Badge
      color="blue"
      className={classNames(
        'mt-3 text-xs text-primary-500 dark:text-primary-400',
        className,
      )}
      content={t('プランのアップグレードが必要')}
    />
  );
}
