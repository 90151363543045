import { Property, Status } from '@/@types/models';
import { IViewProps, IViewRow } from '@/@types/viewItem';
import {
  ArrowSmallDownIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  LIST_VIEW_ITEM_LENGTH,
  FIXED_COLUMN_LENGTH,
  DEFAULT_LIST_VIEW_TITLE_COLUMN_WIDTH,
  MIN_LIST_VIEW_TITLE_COLUMN_WIDTH,
  LIST_VIEW_TITLE_COLUMN_KEY,
} from '@/libs/const';
import { onDragEnd } from '@/libs/dndUtils';

import { classNames } from '@/libs/styleUtils';

import { getNavigatePath } from '@/libs/utils';

import { openItemEditDialog } from '@/reducers/itemsReducer';
import { openNewItemDialog } from '@/reducers/newItemDialogReducer';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import { isSelectPublishItemModeContext } from '@/components/Share/SelectPublishItemsContext';

import ColumnWidthContext from '@/components/View/List/ColumnWidthContext';
import ListItem from '@/components/View/List/ListItem';
import ResizableTh from '@/components/View/List/ResizableTh';
import ViewStatusHeader from '@/components/View/ViewStatusHeader';

import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useViews from '@/hooks/useViews';

interface IListViewRow extends IViewRow {
  hasNext: boolean;
}

/**
 * リストビューのメインエリア表示
 * @param props
 * @returns
 */
export default function ListView(props: IViewProps) {
  const { properties, viewRows, isReadonly, onChangeRowItem } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentView } = useViews();
  const { workspaceId, projectId, viewId } = useParams();
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  const { dragItem, isItemLimitOver } = useItems();
  const { isMember } = useMyWorkspaces();

  const [allViewStatusIds, setAllViewStatusIds] = useState<string[]>([]);

  const [omitStatusIds, setOmitStatusIds] = useState<string[]>([]);

  // リストビューは初期表示件数を制限する
  const filteredViewRows = useMemo(() => {
    // 公開アイテム設定モードでは全表示
    if (!isSelectPublishItemMode) {
      return viewRows.reduce<IListViewRow[]>((acc, po) => {
        const isAllView = allViewStatusIds.some((id) => id === po.status.id);
        const filteredItems = po.viewItems.slice(0, 7);
        const { viewItems: allItems } = po;

        const viewRow: IListViewRow = {
          status: po.status,
          viewItems: isAllView === true ? allItems : filteredItems,
          hasNext: po.viewItems.length > LIST_VIEW_ITEM_LENGTH && !isAllView,
        };
        acc.push(viewRow);
        return acc;
      }, []);
    }
    return viewRows.reduce<IListViewRow[]>((acc, po) => {
      const { viewItems } = po;
      const viewRow: IListViewRow = {
        status: po.status,
        viewItems,
        hasNext: false,
      };
      acc.push(viewRow);
      return acc;
    }, []);
  }, [isSelectPublishItemMode, viewRows, allViewStatusIds]);

  // ドロップ時ハンドラ
  const handleDragEnd = (result: DropResult) => {
    const moveResult = onDragEnd(result, viewRows);
    if (moveResult !== null) {
      // DNDの不正挙動を防ぐため ローカルで保持しているstateを更新
      onChangeRowItem(moveResult);
      // ステータス更新処理
      dragItem(moveResult.item.id, moveResult.statusId, moveResult.position);
    }
  };

  // リスト行クリック時ハンドラ
  const handleRowClick = (itemId: string) => {
    navigate(
      getNavigatePath(workspaceId as string, projectId, viewId, itemId),
      {
        replace: true,
      },
    );
    dispatch(openItemEditDialog(itemId));
  };

  // アイテム新規追加
  const handleCreateClick = (statusId: Status['id']) => {
    if (statusId) {
      dispatch(openNewItemDialog(statusId, true));
    }
  };

  // 公開アイテム設定モードでは折りたたみ解除
  useEffect(() => {
    if (isSelectPublishItemMode) {
      setOmitStatusIds([]);
    }
  }, [isSelectPublishItemMode]);

  // プロパティをpropertyOrderListでソート&フィルタリング
  const filteredProperties = useMemo(() => {
    const result =
      currentView?.propertyOrderList?.reduce<Property[]>((acc, po) => {
        // ゲスト権限は公開されているプロパティのみ
        const found = properties.find((d) =>
          isMember ? d.id === po : d.id === po && d.isShare === true,
        );

        if (!found) return acc;

        acc.push(found);
        return acc;
      }, []) || [];
    return result;
  }, [properties, currentView]);

  // 残り全件を読み込む
  const loadNextPage = (status: string) => {
    setAllViewStatusIds((old: string[]) => [...old, status]);
  };

  // おりたたみボタン
  const handleClickOmitButton = (statusId: string) => {
    if (omitStatusIds.includes(statusId)) {
      setOmitStatusIds((old) => old.filter((id) => id !== statusId));
    } else {
      setOmitStatusIds((old) => [...old, statusId as string]);
    }
  };

  const render = useMemo(
    () =>
      filteredViewRows.map((row, rowIndex) => {
        const isOmitStatus = omitStatusIds.includes(row.status.id as string);
        return (
          <div className="w-fit" key={`row-status-${row.status.id}`}>
            <div className="sticky top-0 z-20 flex items-center">
              <ViewStatusHeader row={row} className="w-60 lg:w-72 xl:w-80" />
              <div className="ml-2 dark:bg-gray-800/80 bg-white/80 rounded-md">
                <MenuButton
                  id="statusAreaOmit"
                  type="icon"
                  variant="warning"
                  onClick={() => handleClickOmitButton(row.status.id as string)}
                >
                  {isOmitStatus ? (
                    <ChevronDoubleDownIcon
                      className="w-3 h-3"
                      aria-hidden="true"
                    />
                  ) : (
                    <ChevronDoubleUpIcon
                      className="w-3 h-3"
                      aria-hidden="true"
                    />
                  )}
                </MenuButton>
              </div>
            </div>
            {!isOmitStatus && (
              <div
                className={classNames(
                  'w-fit mt-4 text-gray-800 border-t border-l border-r rounded-md dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100 overflow-hidden',
                  !isSelectPublishItemMode && isMember ? 'rounded-b-none' : '',
                )}
              >
                <table
                  className="w-full border-separate table-fixed-sticky-l divide-y divide-gray-200 dark:divide-gray-700 dark:border-gray-700"
                  style={{ borderSpacing: 0 }}
                >
                  <thead>
                    <tr className="bg-gray-100 border-b border-gray-200 dark:border-gray-700 dark:bg-gray-700">
                      <ResizableTh
                        sizingKey={LIST_VIEW_TITLE_COLUMN_KEY}
                        key={LIST_VIEW_TITLE_COLUMN_KEY}
                        className={classNames(
                          'font-medium text-gray-500 dark:text-gray-300 z-10 p-0 tracking-wider text-left  left-0 ',
                          'border-b border-gray-200 dark:border-gray-700',
                        )}
                        label={t('タイトル')}
                        minWidth={MIN_LIST_VIEW_TITLE_COLUMN_WIDTH}
                        defaultWidth={DEFAULT_LIST_VIEW_TITLE_COLUMN_WIDTH}
                      />
                      {/* -- 固定ヘッダ ここまで -- */}
                      {filteredProperties.map((ps) => (
                        <ResizableTh
                          property={ps}
                          key={ps.id}
                          className="p-0 font-medium tracking-wider text-left text-gray-500 break-words border-b border-gray-200 dark:text-gray-300 dark:border-gray-700"
                        />
                      ))}
                      <th
                        aria-label="empty-th"
                        className="w-auto px-6 py-2 font-medium tracking-wider text-left text-gray-500 border-b border-gray-200 dark:text-gray-300 dark:border-gray-700"
                      />
                    </tr>
                  </thead>
                  <Droppable droppableId={rowIndex.toString()}>
                    {(providedDroppable) => (
                      <tbody
                        className="divide-y divide-gray-200 dark:divide-gray-700"
                        ref={providedDroppable.innerRef}
                        {...providedDroppable.droppableProps}
                      >
                        {row.viewItems.length === 0 && (
                          <tr>
                            <td
                              colSpan={
                                FIXED_COLUMN_LENGTH + filteredProperties.length
                              }
                            />
                          </tr>
                        )}
                        {row.viewItems.map((viewItem, itemIndex) => (
                          <Draggable
                            isDragDisabled={
                              isSelectPublishItemMode || isReadonly
                            }
                            key={`item-${viewItem.id}`}
                            draggableId={viewItem.id as string}
                            index={itemIndex}
                          >
                            {(providedDraggable, snapshotDraggable) => (
                              <tr
                                ref={providedDraggable.innerRef}
                                {...providedDraggable.draggableProps}
                                {...providedDraggable.dragHandleProps}
                                className={classNames(
                                  'hover:bg-gray-50 dark:hover:bg-gray-700 group focus-within:z-10',
                                  !isSelectPublishItemMode
                                    ? 'cursor-pointer'
                                    : '',
                                  snapshotDraggable.isDragging
                                    ? 'table border border-collapse bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700'
                                    : '',
                                )}
                                onClick={() => {
                                  if (isSelectPublishItemMode) return;
                                  handleRowClick(viewItem.id as string);
                                }}
                              >
                                <ListItem
                                  properties={filteredProperties}
                                  viewItem={viewItem}
                                />
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {providedDroppable.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </div>
            )}
            {!isOmitStatus && row.hasNext && (
              <div className="overflow-hidden border-b border-l border-r border-gray-200 rounded-t-none dark:border-gray-700 border-t-none">
                <MenuButton
                  id="loadAllItem"
                  type="text"
                  align="left"
                  className="flex items-center py-1 text-gray-400 border-0 rounded-none shadow-none outline-none ring-0 focus:ring-0 focus:!ring-offset-0 dark:text-gray-500 dark:hover:text-gray-300 hover:text-gray-500"
                  onClick={() => loadNextPage(row.status.id as string)}
                >
                  <ArrowSmallDownIcon className="w-4 h-4" aria-hidden="true" />
                  <div className="ml-2">{t('残りの全件を読み込む')}</div>
                </MenuButton>
              </div>
            )}
            {!isOmitStatus && !isSelectPublishItemMode && !isReadonly && (
              <div className="overflow-hidden border-b border-l border-r border-gray-200 rounded-t-none rounded-md dark:border-gray-700 border-t-none">
                <MenuButton
                  id="showNewItem"
                  type="text"
                  align="left"
                  className={classNames(
                    'flex items-center py-1 text-gray-400 border-0 rounded-none shadow-none outline-none ring-0 focus:ring-0 focus:!ring-offset-0 dark:text-gray-500 dark:hover:text-gray-300 hover:text-gray-500',
                    isItemLimitOver
                      ? 'hover:bg-gray-50/0 bg-gray-50/0 dark:bg-gray-800/100 dark:hover:text-gray-700/100 dark:text-gray-700/100 hover:text-gray-200/100 text-gray-200/100'
                      : '',
                  )}
                  onClick={() => handleCreateClick(row.status.id)}
                  disabled={isItemLimitOver}
                  toolTip={
                    isItemLimitOver
                      ? t(
                          'アイテム数上限に達しました。アイテムを作成したい場合は、既存のアイテムを削除してください。',
                        )
                      : ''
                  }
                >
                  <PlusIcon className="w-4 h-4" aria-hidden="true" />
                  <div className="ml-2">{t('アイテムを追加')}</div>
                </MenuButton>
              </div>
            )}
          </div>
        );
      }),
    [
      filteredViewRows,
      omitStatusIds,
      filteredProperties,
      isReadonly,
      isSelectPublishItemMode,
    ],
  );

  return (
    <ColumnWidthContext>
      <div
        className={classNames(
          'pt-0 px-4 pb-4 sticky overscroll-none overflow-y-auto space-y-6 pb-28',
          isSelectPublishItemMode ? 'mb-28 sm:mb-16' : '',
        )}
      >
        <DragDropContext onDragEnd={handleDragEnd}>{render}</DragDropContext>
      </div>
    </ColumnWidthContext>
  );
}
