import React, { ReactNode, useEffect } from 'react';

import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

export interface IBaseButtonProps {
  className?: string;
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  dataTip?: string;
  disabled?: boolean;
}

/**
 * リアクションのベースボタン
 * @param props
 * @returns
 */
export default function BaseButton(props: IBaseButtonProps) {
  const { className, onClick, children, dataTip, disabled } = props;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [dataTip]);

  return (
    <button
      data-tip={dataTip}
      onClick={(e) => {
        if (!disabled && onClick) onClick(e);
      }}
      type="button"
      className={classNames(
        'flex items-center py-1 leading-none border rounded-full px-1.5 space-x-1 dark:border-gray-600 ring-opacity-100 h-6 overflow-hidden',
        !disabled
          ? 'dark:hover:bg-primary-700/50 dark:hover:border-primary-700  hover:bg-primary-400/50 hover:border-primary-400'
          : '',
        className,
      )}
    >
      {children}
    </button>
  );
}
