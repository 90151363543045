import { RootState } from '@/@types/models';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3BottomLeftIcon,
  Bars3BottomRightIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

import { setSidebarMinimize } from '@/reducers/uiStateReducer';

import DowngradeBanner from '@/components/Banners/DowngradeBanner';
import MemberOverflowBanner from '@/components/Banners/MemberOverflowBanner';
import Logo from '@/components/Common/Logo';
import GlobalHeader from '@/components/GlobalHeader';

import AccountDropdown from '@/components/GlobalNavigation/AccountDropdown';
import FunctionNavigation from '@/components/GlobalNavigation/FunctionNavigation';
import ProjectNavigation from '@/components/GlobalNavigation/ProjectNavigation';
import WorkspaceSelect from '@/components/GlobalNavigation/WorkspaceSelect';
import NewItemDialog from '@/components/Items/NewItemDialog';
import NotificationDropdown from '@/components/Notification/NotificationDropdown';
import RedDot from '@/components/RedDot';
import { isSelectPublishItemModeContext } from '@/components/Share/SelectPublishItemsContext';
import {
  GlobalNavigationOverlay,
  MobileHeaderOverlay,
} from '@/components/Share/SelectPublishItemsControl';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

interface IBasicLayout {
  children: React.ReactChild;
}

/**
 * ログイン後の基本レイアウト
 * @returns
 */
export default function BasicLayout(props: IBasicLayout) {
  const { children } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { myWorkspaces, currentMyWorkspace } = useMyWorkspaces();
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  const { isOpen: isNewItemDialogOpen } = useSelector(
    (state: RootState) => state.newItemDialogState,
  );

  const uiState = useSelector((state: RootState) => state.uiState);
  /** サイドバーの最小化状態 */
  const isSidebarMinimize = useMemo(() => uiState.isSidebarMinimize, [uiState]);

  /** サイドバーの最小化状態トグル */
  const toggleSidebarMinimize = useCallback(() => {
    dispatch(setSidebarMinimize(!isSidebarMinimize));
    setTimeout(() => {
      ReactTooltip.rebuild();
    });
  }, [isSidebarMinimize]);

  /** ヘッダのサイドメニューボタンクリック */
  function handleSideMenuClick() {
    setSidebarOpen(true);
  }

  const isUnknownGuest = useMemo(
    () => currentMyWorkspace?.joinType === 'unknownGuest',
    [currentMyWorkspace?.joinType],
  );

  /** 未読通知件数合計 */
  const totalNotificationCount = useMemo(
    () =>
      myWorkspaces.reduce(
        (sum, ws) => sum + (ws?.toCommentsUnReadCount || 0),
        0,
      ),
    [myWorkspaces],
  );

  return (
    <>
      <div>
        {isUnknownGuest ? (
          <div>{children}</div>
        ) : (
          <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-40 flex md:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-black dark:bg-opacity-40" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <div className="relative flex flex-col flex-1 w-full max-w-xs bg-white dark:bg-gray-800">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 pt-2 -mr-12">
                        <button
                          type="button"
                          className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:focus:ring-gray-400"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="w-6 h-6 text-white dark:text-gray-400"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="relative px-4 py-2 border-b border-gray-200 dark:border-gray-700">
                      <WorkspaceSelect />
                    </div>
                    <div className="flex-1 h-0 overflow-y-auto">
                      <nav className="px-2 mt-3 space-y-1" aria-label="Sidebar">
                        <ProjectNavigation />
                        <FunctionNavigation />
                      </nav>
                    </div>
                    <AccountDropdown className="border-t border-gray-200 dark:border-gray-700" />
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </Dialog>
            </Transition.Root>
            <div
              className={classNames(
                !isSidebarMinimize
                  ? 'hidden relative md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 bg-gray-50  dark:bg-gray-800'
                  : 'hidden md:flex md:w-12 md:flex-col md:fixed md:inset-y-0 relative',
                'transition-all ease-in-out border-r border-gray-200 dark:border-gray-700',
              )}
            >
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div
                className={classNames(
                  isSidebarMinimize
                    ? 'opacity-0  top-0 left-0 z-0'
                    : 'md:w-64 opacity-100',
                  'transition-all ease-in-out flex-col flex flex-1 min-h-0',
                )}
              >
                <div className="flex flex-col flex-1 w-64 min-h-0">
                  <div className="flex items-center flex-shrink-0 h-16 px-4 border-t border-gray-200 dark:border-gray-700">
                    {/** ブランドロゴ */}
                    <div className="flex-none">
                      <Logo className="w-auto h-9" />
                    </div>
                    {/** ワークスペース */}
                    <div className="flex items-center justify-center ml-4">
                      <div className="w-40">
                        <WorkspaceSelect popupClassName="-ml-11 w-56" />
                      </div>
                      <div className="relative flex items-center justify-center h-12 border-gray-200 w-7 md:inset-y-0 dark:border-gray-700">
                        <button
                          type="button"
                          className="flex items-center justify-center w-6 h-8 focus:outline-none dark:text-gray-500 hover:text-primary-500 dark:hover:text-gray-300"
                          data-tip={t('サイドバーを非表示にする')}
                          onClick={() => toggleSidebarMinimize()}
                        >
                          <Bars3BottomRightIcon
                            className="w-5 h-5"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 overflow-y-auto border-t border-gray-200 dark:border-gray-700">
                    {/** 通知 */}
                    <nav className="flex pl-4 pr-2" aria-label="Sidebar">
                      <NotificationDropdown className="py-3" />
                    </nav>
                    <div className="relative">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-200 dark:border-gray-700 " />
                      </div>
                    </div>
                    {/** プロジェクト、機能 */}
                    <nav
                      className="flex-1 px-2 py-4 space-y-1"
                      aria-label="Sidebar"
                    >
                      <ProjectNavigation />
                      <FunctionNavigation />
                    </nav>
                  </div>
                </div>
                <AccountDropdown className="border-t border-gray-200 dark:border-gray-700 " />
                <GlobalNavigationOverlay />
              </div>
              <div
                className={classNames(
                  isSidebarMinimize
                    ? 'absolute -ml-[1px] justify-center flex-shrink-0 hidden w-12 md:flex z-10 opacity-100 flex-1 bg-gray-50 dark:bg-gray-800 h-screen mt-1'
                    : ' opacity-0 h-0 mt-0',
                  'transition-opacity ease-in-out',
                )}
              >
                <button
                  type="button"
                  className="relative flex items-center justify-center w-8 h-8 mt-3 focus:outline-none dark:text-gray-500 hover:text-primary-500 dark:hover:text-gray-300"
                  data-tip={t('サイドバーを表示する')}
                  onClick={() => toggleSidebarMinimize()}
                >
                  <Bars3BottomLeftIcon className="w-5 h-5" aria-hidden="true" />
                  {totalNotificationCount > 0 && (
                    <RedDot className="absolute ml-1 top-1 right-1" />
                  )}
                </button>
              </div>
            </div>
            {/* グローバルヘッダ */}
            <div
              className={classNames(
                !isSidebarMinimize ? 'md:pl-64' : 'md:pl-12',
                'flex flex-col transition-all ease-in-out',
              )}
            >
              {/** バナー類はアイテム選択モードでは表示しない */}
              {!isSelectPublishItemMode && (
                <>
                  {/* ダウングレードバナー */}
                  <DowngradeBanner />
                  {/* メンバー数超過バナー */}
                  <MemberOverflowBanner />
                </>
              )}
              <div className="relative">
                <GlobalHeader
                  onSlideMenuClick={() => {
                    handleSideMenuClick();
                  }}
                />
                <MobileHeaderOverlay />
              </div>
              <main className="relative flex-1">{children}</main>
            </div>
          </>
        )}
      </div>
      <Transition.Root show={isNewItemDialogOpen} unmount>
        <NewItemDialog />
      </Transition.Root>
    </>
  );
}
