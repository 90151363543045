import {
  CreateUpdateItemStatus,
  CreateUpdateItemProperty,
  CreateUpdateItemPropertyValue,
  CreateUpdateItemItem,
  CreateUpdateItemBody,
} from '@/@types/common';
import {
  Item,
  Property,
  PropertyValue,
  RootState,
  LikedUser,
  Status,
  Body,
  FileArrayValue,
  MultiSelectArrayValue,
  MemberGuestViewItem,
} from '@/@types/models';
import { IProperty, SystemProperties } from '@/@types/viewItem';

import { Popover } from '@headlessui/react';

import { XMarkIcon, EyeSlashIcon, EyeIcon } from '@heroicons/react/24/outline';

import firebase from 'firebase/compat';
import { Timestamp } from 'firebase/firestore';

import { getDownloadURL } from 'firebase/storage';
import _ from 'lodash';

import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { FieldArrayWithId, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
  INITIAL_EDITOR_STATE,
  BODY_FILE_LOAD_RETRY,
  PLANS,
  PROPERTY_TYPE,
  SHARING_PERMISSION_TYPES,
} from '@/libs/const';
import { DayjsUtil, FormatType } from '@/libs/dayjs';
import { classNames } from '@/libs/styleUtils';

import ua from '@/libs/ua';
import {
  formatNumberProperty,
  getNavigatePath,
  parseFsTimestamp,
  promisedSleep,
} from '@/libs/utils';

import ItemRepository from '@/repositories/ItemRepository';

import { closeItemEditDialog } from '@/reducers/itemsReducer';

import PopupTransition from '@/components/Common//Transitions/PopupTransition';
import AvatarIcon from '@/components/Common/AvatarIcon';
import BaseDialog from '@/components/Common/BaseDialog';
import BodyLoading from '@/components/Common/BodyLoading';
import ConfirmDialog from '@/components/Common/ConfirmDialog';
import Divider from '@/components/Common/Divider';
import FileLoader from '@/components/Common/FileLoader';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import Checkbox from '@/components/Common/Forms/Checkbox';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';
import HeartIconBtn from '@/components/Common/Icon/HeartIconBtn/HeartIconBtn';
import initialState from '@/components/Common/Lexical/configs/initialState';
import ItemEditor, {
  IItemEditorHandler,
} from '@/components/Common/Lexical/editors/itemText/Editor';

import LexicalReadOnlyEditor from '@/components/Common/Lexical/LexicalReadOnlyEditor';
import { SelectBadge } from '@/components/Common/SelectBadge';
import Comments from '@/components/Items/Comments/Comments';
import ItemProperties from '@/components/Items/ItemProperties';
import SystemProperty from '@/components/Items/SystemProperty';

import ItemDropDown from '@/components/View/ItemDropDown';

import DImgNotFound from '@/assets/d_image_not_found.svg';
import LImgNotFound from '@/assets/l_image_not_found.svg';

import { auth } from '@/firebase';
import useAccessSettings from '@/hooks/useAccessSettings';
import useBodies from '@/hooks/useBodies';
import useEditItem, { IItemFormDefaultValues } from '@/hooks/useEditItem';
import useIsReadableComments from '@/hooks/useIsReadableComments';
import useItems from '@/hooks/useItems';
import useKeyBind from '@/hooks/useKeyBind';
import useLikedActions from '@/hooks/useLikedActions';
import useLikedItems from '@/hooks/useLikedItems';
import useLikedUsers from '@/hooks/useLikedUsers';
import useMentions from '@/hooks/useMentions';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useOrderedProperties from '@/hooks/useOrderedProperties';
import useOrderedStatuses from '@/hooks/useOrderedStatuses';
import usePlans from '@/hooks/usePlans';
import useProjects from '@/hooks/useProjects';
import useProperties from '@/hooks/useProperties';
import usePropertyOrders from '@/hooks/usePropertyOrders';
import usePropertyValues from '@/hooks/usePropertyValues';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';
import useStatuses from '@/hooks/useStatuses';
import useStatusOrders from '@/hooks/useStatusOrders';
import useToggleTheme from '@/hooks/useToggleTheme';
import useUnknownGuests from '@/hooks/useUnknownGuests';
import useViewItems from '@/hooks/useViewItems';
import useViews from '@/hooks/useViews';

interface ILikedUser {
  id: LikedUser['id'];
  likedAt: LikedUser['likedAt'];
  displayName: string | null;
  photoURL: string | null;
  isAnonymous: boolean;
}

export interface IchargeList {
  id: string;
  value: string;
  name: string;
  optionName?: string;
  photoURL?: string;
  isAnonymous: boolean;
}

function ItemEditDialog() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { workspaceId, projectId, viewId } = useParams();

  // ストア/サブスクリプション
  const dispatch = useDispatch();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const { updateIsShare } = useItems();
  const { currentItemId, currentItem, getIsOwnItem } = useItems();
  const { currentViewItem } = useViewItems();
  const { isDarkTheme } = useToggleTheme();

  const { currentBody } = useBodies();
  const { properties } = useProperties();
  const { propertyOrder } = usePropertyOrders();
  const orderedProperties = useOrderedProperties(properties, propertyOrder);
  const { statuses } = useStatuses();
  const { statusOrder } = useStatusOrders();
  const orderedStatuses = useOrderedStatuses(statuses, statusOrder);
  const { currentPropertyValues } = usePropertyValues();
  const { likedUsers, hasNext, loadNextPage } = useLikedUsers(currentItemId);
  const { createLikedUser, deleteLikedUser } = useLikedActions();
  const { membersDic, guestsDic } = useMentions();
  const { removedMembersDic } = useRemovedMembers();
  const { removedGuestsDic } = useRemovedGuests();
  const { unknownGuestsDic } = useUnknownGuests();
  const membersAndGuestsDic = {
    ...removedGuestsDic,
    ...removedMembersDic,
    ...guestsDic,
    ...membersDic,
    ...unknownGuestsDic,
  };

  const { isReadableComments } = useIsReadableComments(currentItemId);
  const { accessSetting } = useAccessSettings();
  const { isMember } = useMyWorkspaces();
  const { isReadOnly, sharingPermission } = useProjects();

  // useAgentによるOS判定
  const isIos = ua.isIOS();
  const editorRef = useRef({} as IItemEditorHandler);
  const [editorValue, setEditorValue] = useState<string>('');
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false);
  const isSubmit = useRef(false as boolean);
  const [isOwnItem, setIsOwnItem] = useState<boolean>(false);
  const { isDisplayComment, isDisplayLiked } = useAccessSettings();
  const { isCommentEmojiOpen, reactionOpenCommentId } = useSelector(
    (state: RootState) => state.items,
  );
  const submitCloseButtonRef = useRef<HTMLButtonElement>(null);
  // ローカルで同期的に扱うカウント数のstate
  const [localCountState, setLocalCountState] = useState<
    Pick<Item, 'likeCount' | 'commentCount'>
  >({
    likeCount: 0,
    commentCount: 0,
  });
  const [isShareDialogOpen, setIsShareDialogOpen] = useState<boolean>(false);

  const [editorState, setEditorState] = useState<string>('');
  const [oldEditorState, setOldEditorState] = useState<string>('');
  const [isEditorLoaded, setIsEditorLoaded] = useState<boolean>(false);
  const systemProperties = useRef<SystemProperties>({});
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const { currentView } = useViews();

  const { plan } = usePlans();

  const { isCurrentItemLiked } = useLikedItems();
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const isMounted = useRef(false);

  // 本文の初期化時にupdateが走る回数
  const INIT_LEXICAL_UPDATE_COUNT = 2;

  // 若干無理やり目に共通化
  const {
    methods,
    zodPropertySetting,
    defaultValues,
    composeProperties,
    reduceFuncPropertyValues,
    getUpdateStatuses,
    getUpdateAndComposePropertyValues,
    getEditOption,
  } = useEditItem();

  // リセット処理
  const resetEditDialog = useCallback(
    (data: typeof defaultValues) => {
      methods.reset({ ...data });
    },
    [methods],
  );

  // 閲覧可能なコメントがあるか（閲覧権限ゲスト）
  const isReadableCommentExists = useMemo(
    () =>
      sharingPermission === SHARING_PERMISSION_TYPES.READ && isReadableComments,
    [isReadableComments, sharingPermission],
  );

  // コメントエリアを表示するか
  const isEditCommentArea = useMemo(
    () =>
      isMember ||
      (isDisplayComment &&
        (isReadableCommentExists ||
          sharingPermission !== SHARING_PERMISSION_TYPES.READ)),
    [isDisplayComment, isReadableCommentExists, sharingPermission],
  );

  // いいねクリック時
  const onClickLike = useCallback(async () => {
    if (userId) {
      let likeCount = localCountState.likeCount || 0;
      if (isLiked) {
        // いいね削除
        likeCount -= 1;
        setIsLiked(false);
        deleteLikedUser(userId, currentItemId);
      } else {
        // いいね新規作成
        likeCount += 1;
        setIsLiked(true);
        createLikedUser(userId, currentItemId);
      }
      // // ローカルのカウント用ステートの更新
      setLocalCountState((old) => ({
        ...old,
        likeCount,
      }));
    }
  }, [
    isLiked,
    deleteLikedUser,
    createLikedUser,
    likedUsers,
    userId,
    localCountState,
    setLocalCountState,
  ]);

  const changeCount = useRef(0);
  // Lexicalのdirty判定更新
  const onEditorChange = (html: string) => {
    // 初期化で２回changeが走ってしまうため、dirty判定に含まない
    changeCount.current += 1;
    setEditorState(html);
  };

  const isEditorDirty = useMemo(() => {
    if (editorState === '' || changeCount.current <= INIT_LEXICAL_UPDATE_COUNT)
      return false;
    if (!isEditorLoaded) {
      setIsEditorLoaded(true);
      return false;
    }
    return oldEditorState !== editorState;
  }, [editorState]);

  // ダイアログクローズ時処理
  const onClose = useCallback(() => {
    // 絵文字ピッカーが開いてる間はダイアログを閉じない
    if (isCommentEmojiOpen || !!reactionOpenCommentId) return null;
    if (methods.formState.isDirty || (isEditorDirty && !isSubmit.current)) {
      return setDiscardDialogOpen(true);
    }
    dispatch(closeItemEditDialog());

    return navigate(getNavigatePath(workspaceId as string, projectId, viewId), {
      replace: true,
    });
  }, [
    methods.formState.isDirty,
    isEditorDirty,
    isSubmit.current,
    isCommentEmojiOpen,
    reactionOpenCommentId,
  ]);

  // 変更破棄確認ダイアログクローズ時処理
  const handleDiscardConfirm = useCallback((acceptFlg: boolean | undefined) => {
    if (acceptFlg) {
      dispatch(closeItemEditDialog());
      navigate(getNavigatePath(workspaceId as string, projectId, viewId), {
        replace: true,
      });
    }
    setDiscardDialogOpen(false);
  }, []);

  /**
   * 画像取得処理（unmount時に中断するため同コンポーネント内に定義）
   * @param st
   * @returns
   */
  const convertImageSrc = async (st: string) => {
    let retryCount = 0;
    const convertPath2URL = async (path: string) => {
      if (!path) return '';

      const func = async () => {
        let url = '';
        try {
          const storage = firebase.storage();
          const refs = storage.ref(path);
          url = await getDownloadURL(refs);
        } catch (error) {
          if (
            retryCount >= BODY_FILE_LOAD_RETRY.COUNT ||
            isMounted.current === false
          )
            return 'failed';
          // パスが変更される前に画像読み込みに失敗してしまうケースがあるのでリトライを行う
          await promisedSleep(BODY_FILE_LOAD_RETRY.DELAY * retryCount);
          await auth.currentUser?.getIdToken(true);
          retryCount += 1;
          await func();
        }
        return url;
      };
      const result = await func();
      if (result === 'failed' || result === '') {
        // 取得失敗
        return isDarkTheme ? DImgNotFound : LImgNotFound;
      }
      return result;
    };
    const func = async (state: any, targetType: string) => {
      if (state.type === targetType && state.path) {
        // eslint-disable-next-line no-param-reassign
        state.src = await convertPath2URL(state.path);
      } else {
        if (state.root) {
          await Promise.all(
            state.root.children.map(async (ro: any) => {
              await func(ro, targetType);
            }),
          );
        }
        if (state.children) {
          await Promise.all(
            (state.children || []).map(async (it: any) => {
              if (it.type === 'image' && it.path) {
                // eslint-disable-next-line no-param-reassign
                it.src = await convertPath2URL(it.path);
              }
              await func(it, targetType);
            }),
          );
        }
      }
      return state;
    };

    const obj = JSON.parse(st);
    const result = await func(obj, 'image');
    const json = JSON.stringify(result);
    return json;
  };

  /**
   * 画像の変換処理
   * @param st
   * @returns
   */
  useEffect(() => {
    const func = async () => {
      if (isMounted.current === true && currentBody.editorState) {
        const convertedEditorState = await convertImageSrc(
          currentBody.editorState,
        );
        setEditorValue(convertedEditorState);
        setOldEditorState(convertedEditorState);
      }
    };
    func();
  }, [currentBody, isMounted.current]);

  // 公開設定ダイアログ
  const handleChangeIsShare = useCallback(
    async (deleteFlg: boolean | undefined) => {
      if (currentItemId === null) return;
      if (deleteFlg) {
        await updateIsShare(currentItemId, !currentItem?.isShare);
        onClose();
      }
      setIsShareDialogOpen(false);
    },
    [onClose, currentItemId, updateIsShare],
  );

  // likedUserを表示用に処理
  const likedUsersMemo = useMemo(
    () =>
      likedUsers.reduce<ILikedUser[]>((acc, cur) => {
        if (!cur.likedBy) return acc;
        const user = membersAndGuestsDic[cur.likedBy];
        acc.push({
          id: cur.id,
          likedAt: cur.likedAt,
          displayName: user ? user.displayName || null : t('匿名ゲスト'),
          photoURL: user ? user.photoURL || null : '',
          isAnonymous: !user,
        });
        return acc;
      }, []),
    [likedUsers, membersAndGuestsDic],
  );

  // 本文の表示(editor,readOnly,disabled)
  const bodyType = useMemo(() => {
    if (isMember) {
      return 'editor';
    }
    if (accessSetting?.isBodyVisible) {
      if (sharingPermission === SHARING_PERMISSION_TYPES.EDIT && isOwnItem) {
        return 'editor';
      }
      return 'readOnly';
    }
    return 'disabled';
  }, [isMember, accessSetting?.isBodyVisible, sharingPermission, isOwnItem]);
  // フォームの更新状態を制御する
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 送信可否判定
  const canSubmit = useMemo(
    () =>
      methods.formState.isValid && (methods.formState.isDirty || isEditorDirty),
    [methods.formState.isValid, methods.formState.isDirty, isEditorDirty],
  );

  // items更新処理
  const formSubmit = methods.handleSubmit((formData) => {
    // みなし更新で体感レスポンスを改善
    const fn = async (data: typeof formData) => {
      const { itemTitle: title, properties: ps, deletedProperties: dps } = data;
      // 更新中のフラグをセット 更新中は画面データの初期化を行わない
      setIsSubmitting(true);
      if (!currentItem) return;

      // 選択されたステータスを取得する
      const status = ps.find((p) => p.propertyType === PROPERTY_TYPE.STATUS);

      try {
        // ステータスがセットされていないデータは不正
        if (!status) throw new Error('status is not set');

        // ステータス更新時にiconデータを追加
        const optionsWithIcons = status.options.map((s) => {
          const result = s;
          const org = orderedStatuses.find((x) => x.id === s.id);
          result.icon = org?.icon ?? '';
          return result;
        });
        status.options = optionsWithIcons;

        // ステータスとステータスオーダーデータ
        const statusesData = isMember ? getUpdateStatuses(status) : undefined;

        const itemData: CreateUpdateItemItem = {
          ...currentItem,
          title,
          statusId: status.stringValue,
        };

        let bodyData: CreateUpdateItemBody = {
          id: currentItem.id,
          editorState: INITIAL_EDITOR_STATE,
          plainText: '',
        };

        // アイテムの更新
        // 本文の更新
        // ゲストの本文更新は自身が作成したアイテムのみ
        if (bodyType === 'editor') {
          const editorBody = editorRef.current.save();
          bodyData = {
            id: currentItem.id,
            editorState: editorBody.jsonState,
            plainText: editorBody.textState,
          };
          // editorStateを初期化（更新判定のため）
          setOldEditorState('');
          setEditorState('');
        }

        let propsData: {
          createProperties?: CreateUpdateItemProperty[];
          updateProperties?: CreateUpdateItemProperty[];
          deleteProperties?: (string | undefined)[];
          propertyOrderList?: string[] | undefined;
          viewPropertyOrderList?: string[] | undefined;
          createPropertyValues?: CreateUpdateItemPropertyValue[];
          updatePropertyValues?: CreateUpdateItemPropertyValue[];
        } = {
          createProperties: [],
          updateProperties: [],
          deleteProperties: [],
          propertyOrderList: [],
          viewPropertyOrderList: [],
          createPropertyValues: [],
          updatePropertyValues: [],
        };

        // propertyに変更があるときだけ更新
        if (isMember) {
          // プロパティ作成・更新・削除処理
          const propertiesData = getUpdateAndComposePropertyValues(ps, dps);

          propsData = {
            ...propertiesData,
          };
        } else {
          // 編集権限は新規プロパティ追加なし
          const currentProperties = data.properties.filter((ip) =>
            properties.find((v) => v.id === ip.docId),
          );
          const updatePropertyValues = currentProperties.reduce(
            reduceFuncPropertyValues,
            [],
          );

          propsData = {
            updatePropertyValues,
          };
        }

        // アイテム保存
        const itemRepository = new ItemRepository(
          currentMyWorkspace?.id,
          currentMyProject?.id as string,
        );

        // フォームのリセット
        methods.reset({
          ...data,
          properties: data.properties.map((p) => ({
            ...p,
            editOption: getEditOption(p.propertyType),
          })),
        });

        if (isMember) {
          await itemRepository.memberUpdateItem(
            currentView?.id,
            statusesData?.createStatuses as CreateUpdateItemStatus[],
            statusesData?.updateStatuses as CreateUpdateItemStatus[],
            statusesData?.deleteStatuses as Status['id'][],
            statusesData?.statusOrder,
            itemData,
            bodyData,
            propsData?.createProperties as CreateUpdateItemProperty[],
            propsData?.updateProperties as CreateUpdateItemProperty[],
            propsData?.deleteProperties as Property['id'][],
            propsData?.propertyOrderList,
            propsData?.viewPropertyOrderList,
            propsData.createPropertyValues as CreateUpdateItemPropertyValue[],
            propsData.updatePropertyValues as CreateUpdateItemPropertyValue[],
          );
        } else {
          await itemRepository.guestUpdateItem(
            itemData,
            bodyData,
            propsData.updatePropertyValues as CreateUpdateItemPropertyValue[],
          );
        }

        changeCount.current = 1;
      } catch (e) {
        toast.error(t('アイテムの更新に失敗しました'));
        throw e;
      } finally {
        setIsSubmitting(false);
      }
    };
    fn(formData);
  });

  // 取得したデータに基づいてローカルのstateを更新
  const setInitializedData = useCallback(
    async (item: Item, body: Body, propertyList: IProperty[]) => {
      if (body.editorState === '' || initialState) {
        // 初期値が空の場合のdirty判定を考慮
        changeCount.current = INIT_LEXICAL_UPDATE_COUNT;
      }
      resetEditDialog({
        ...defaultValues,
        itemTitle: item.title as string,
        properties: propertyList,
        deletedProperties: [] as IProperty[],
      });
    },
    [defaultValues, bodyType],
  );

  // 初期化処理
  const initialize = useCallback(
    async (
      data: Item,
      prs: Property[],
      pvs: PropertyValue[],
      sts: Status[],
      body: Body,
    ) => {
      //  本文のリセット処理
      const propertyList = composeProperties(prs, sts, data.statusId, pvs);
      await setInitializedData(data, body, propertyList);
    },
    [setInitializedData, currentPropertyValues],
  );

  const formSubmitAndClose = useCallback(async () => {
    if (!canSubmit) return;
    await formSubmit();
    isSubmit.current = true;
    // ダイアログを閉じる
    onClose();
  }, [canSubmit]);

  // ctrl+enterでsubmit
  useKeyBind({
    key: 'Enter',
    ctrlOrCmdKey: true,
    onKeyDown: () => formSubmitAndClose(),
  });

  // inputでctrl+enterでsubmit
  const enterForm = (keyEvent: KeyboardEvent) => {
    if (keyEvent.key === 'Enter' && (keyEvent.ctrlKey || keyEvent.metaKey)) {
      formSubmitAndClose();
    }
  };

  // システムプロパティを初期化
  useEffect(() => {
    if (!currentItem) return;
    if (
      currentItem.itemCreatedAt &&
      currentItem.itemCreatedBy &&
      currentItem.itemUpdatedAt &&
      currentItem.itemUpdatedBy
    ) {
      const sysCreatedBy = membersAndGuestsDic[currentItem.itemCreatedBy];
      const sysUpdatedBy = membersAndGuestsDic[currentItem.itemUpdatedBy];
      const format = t('YYYY-MM-DD HH-mm');
      const currentSysProperties = {
        sysCreatedAt: new DayjsUtil(
          parseFsTimestamp(currentItem.itemCreatedAt as Timestamp),
        ).format(format as FormatType),
        sysUpdatedAt: new DayjsUtil(
          parseFsTimestamp(currentItem.itemUpdatedAt as Timestamp),
        ).format(format as FormatType),
        sysCreatedBy,
        sysUpdatedBy,
      };
      systemProperties.current = currentSysProperties;
    }
  }, [currentItem, membersAndGuestsDic]);

  // いいね・コメント数の状態監視 functionsで更新等された場合に反映する
  useEffect(() => {
    if (currentItem) {
      setLocalCountState({
        likeCount: currentItem.likeCount,
        commentCount: currentItem.commentCount,
      });
    }
  }, [setLocalCountState, currentItem]);

  // いいね状態監視
  useEffect(() => {
    if (isCurrentItemLiked) {
      setIsLiked(isCurrentItemLiked);
    }
  }, [setIsLiked, isCurrentItemLiked]);

  // プロパティ設定値やcurrentItem情報が更新されたら初期化
  const currentLikedUserRef = useRef<LikedUser[] | null>(null);

  // 直前のデータと比較用に参照を保持
  const currentItemRef = useRef<Item | null>(null);
  const currentBodyRef = useRef<Body | null>(null);
  const currentPropertyRef = useRef<Property[] | null>(null);
  const currentStatusRef = useRef<Status[] | null>(null);
  const currentPropertyValueRef = useRef<PropertyValue[] | null>(null);

  useEffect(() => {
    // ※ レンダリング回数抑制用制御 いずれかの依存データが更新された場合のみinitializeする
    let itemEq = _.isEqual(currentItemRef.current, currentItem);
    const bodyEq = _.isEqual(currentBodyRef.current, currentBody);
    const propsEq = _.isEqual(currentPropertyRef.current, orderedProperties);
    const statusEq = _.isEqual(currentStatusRef.current, orderedStatuses);
    const propValsEq = _.isEqual(
      currentPropertyValueRef.current,
      currentPropertyValues,
    );

    // itemのコメント数といいね数は変化してもinitializeしたくない
    if (
      itemEq === false &&
      (currentItemRef.current?.likeCount !== currentItem?.likeCount ||
        currentItemRef.current?.commentCount !== currentItem?.commentCount)
    ) {
      itemEq = true;
    }

    currentItemRef.current = currentItem || null;
    currentBodyRef.current = currentBody || null;
    currentPropertyRef.current = orderedProperties || null;
    currentStatusRef.current = orderedStatuses || null;
    currentPropertyValueRef.current = currentPropertyValues || null;
    if (
      isSubmitting === false &&
      currentItem &&
      currentBody &&
      orderedProperties &&
      orderedStatuses &&
      (!itemEq || !bodyEq || !propsEq || !statusEq || !propValsEq)
    ) {
      initialize(
        currentItem,
        orderedProperties,
        currentPropertyValues,
        orderedStatuses,
        currentBody,
      );
    }
  }, [
    isSubmitting,
    currentItem,
    orderedProperties,
    currentPropertyValues,
    orderedStatuses,
    currentBody,
  ]);

  // モーダルマウント時処理
  useEffect(() => {
    if (currentItem) {
      initialize(
        currentItem,
        orderedProperties,
        currentPropertyValues,
        orderedStatuses,
        currentBody,
      );
    }

    const isOwn = getIsOwnItem(currentItem?.id);
    setIsOwnItem(isOwn);
    isMounted.current = true;
    return () => {
      // リセット処理
      resetEditDialog({ ...defaultValues });
      currentItemRef.current = null;
      currentBodyRef.current = null;
      currentPropertyRef.current = null;
      currentStatusRef.current = null;
      currentLikedUserRef.current = null;
      isMounted.current = false;
    };
  }, []);

  // アイテム送信のツールチップ
  const itemSubmitTooltip = useMemo(() => {
    const baseMsg = isIos
      ? t('アイテムを更新__br__cmd + Enter')
      : t('アイテムを更新__br__Ctrl + Enter');
    return canSubmit ? baseMsg.replace('__br__', '<br />') : '';
  }, [canSubmit, isIos]);

  // アイテム送信のショートカット案内
  const itemSubmitMemo = useMemo(() => {
    const baseMsg = isIos ? 'cmd + Enter' : 'Ctrl + Enter';
    return t(`${baseMsg} で 更新して閉じる`);
  }, [isIos]);

  // プロパティ種別ごとに閲覧権限用プロパティ出力
  const readOnlyPropertySelector = useCallback(
    (
      property: FieldArrayWithId<IItemFormDefaultValues, 'properties', 'id'>,
    ) => {
      // シングルセレクト、ステータス
      if (
        property.propertyType === PROPERTY_TYPE.SINGLE_SELECT ||
        property.propertyType === PROPERTY_TYPE.STATUS
      ) {
        return property.options
          .filter((option) => option.id === property.stringValue)
          .map((option) => (
            <SelectBadge
              key={option.id}
              color={option.color}
              content={option.text}
            />
          ));
      }
      // マルチセレクト
      if (
        property.propertyType === PROPERTY_TYPE.MULTI_SELECT &&
        property.arrayValue
      ) {
        const inMultiList = (
          property.arrayValue as MultiSelectArrayValue[]
        ).flatMap((p) =>
          property.options.filter((option) => option.id === p.stringValue),
        );
        return (
          <div className="flex flex-wrap gap-x-2 gap-y-2">
            {inMultiList.map((option, idx) => (
              <div
                className="relative flex items-center"
                key={`inMultiItem-${idx + 1}`}
              >
                <SelectBadge
                  key={option.id}
                  color={option.color}
                  content={option.text}
                />
              </div>
            ))}
          </div>
        );
      }
      // ファイル添付
      if (property.propertyType === PROPERTY_TYPE.FILE) {
        return (
          <div className="flex flex-wrap gap-x-2 gap-y-2">
            {property.arrayValue &&
              (property.arrayValue as FileArrayValue[]).map((o, idx) => (
                <FileLoader
                  key={`${o.fileName}-${idx + 1}`}
                  src={o}
                  size="md"
                  clickable
                />
              ))}
          </div>
        );
      }
      // 担当者
      if (
        property.propertyType === PROPERTY_TYPE.INCHARGE &&
        property.arrayValue
      ) {
        const inChargeList = (property.arrayValue as string[]).map(
          (uid) => membersAndGuestsDic[uid],
        );
        return (
          <div className="flex flex-wrap gap-x-2 gap-y-2">
            {inChargeList.map((o, idx) => (
              <div
                className="relative flex items-center"
                key={`inchargeItem-${idx + 1}`}
              >
                <AvatarIcon
                  size="sm"
                  src={o.photoURL}
                  avatarName={o.displayName}
                />
                <div className="flex-1 min-w-0 ml-2">
                  <span>{o.displayName}</span>
                </div>
              </div>
            ))}
          </div>
        );
      }
      // 日付
      if (property.propertyType === PROPERTY_TYPE.DATE) {
        return (
          <span className="font-medium text-gray-800 dark:text-gray-100">
            {property.numberValue !== null
              ? new DayjsUtil(new Date(property.numberValue as number)).format(
                  t('YYYY-MM-DD'),
                )
              : ''}
          </span>
        );
      }
      // チェックボックス
      if (property.propertyType === PROPERTY_TYPE.CHECKBOX) {
        return (
          <Checkbox
            name={property.id}
            readOnly
            checked={property.booleanValue}
          />
        );
      }
      // 数値
      if (property.propertyType === PROPERTY_TYPE.NUMBER) {
        return (
          <span className="font-medium text-gray-800 dark:text-gray-100">
            {formatNumberProperty(property.numberValue, property.format)}
          </span>
        );
      }
      // テキスト
      return (
        <span className="font-medium text-gray-800 dark:text-gray-100">
          {property.stringValue}
        </span>
      );
    },
    [],
  );

  const isDisabledEditorChild = useMemo(
    () => isShareDialogOpen || discardDialogOpen,
    [isShareDialogOpen, discardDialogOpen],
  );

  // コメントエリアのレンダリング
  const renderCommentArea = useMemo(() => {
    // ゲストの場合、コメントが公開されている,閲覧権限の場合は閲覧可能なコメントがあること
    if (isEditCommentArea) {
      return (
        <div className="px-2 mt-2 md:col-span-2 sm:mt-0">
          <div className="flex items-center justify-between h-8 ">
            <div className="flex-1 my-auto">
              <span className="mr-2">{t('コメント')}</span>
              <span
                className={classNames(
                  'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100',
                  'inline-flex items-center font-medium mr-1.5 px-2.5 py-0.5 rounded-full',
                )}
              >
                {currentItem?.commentCount}
              </span>
            </div>
            <MenuButton type="icon" id="closeItemEdit" onClick={onClose}>
              <XMarkIcon className="w-4 h-4" />
            </MenuButton>
          </div>

          <Comments
            className="max-h-[calc(100vh-196px)] min-h-[calc(100vh-196px)]"
            itemId={currentItemId}
            commentCountNr={currentItem?.commentCount || 0}
            isEditorChildDisabled={isDisabledEditorChild}
          />
        </div>
      );
    }
    return <div />;
  }, [
    isMember,
    isEditCommentArea,
    currentItem?.commentCount,
    currentItemId,
    onClose,
    isDisabledEditorChild,
  ]);

  const bodySubmit = useCallback(async () => {
    if (!(submitCloseButtonRef.current && !canSubmit)) return;
    submitCloseButtonRef.current.click();
  }, [canSubmit]);

  // 本文エリアのレンダリング
  const renderBody = useMemo(() => {
    if (editorValue === '') {
      // ロード中はreadOnlyでloading表示
      return (
        <div className="flex-1 p-4 pt-0">
          <div className="px-3">
            <BodyLoading className="w-full" />
          </div>
        </div>
      );
    }
    switch (bodyType) {
      case 'editor':
        return (
          <div className="flex-1 min-h-full p-4 pt-0">
            <ItemEditor
              ref={editorRef}
              initialData={editorValue}
              onChange={onEditorChange}
              send={bodySubmit}
            />
          </div>
        );
      case 'readOnly':
        return (
          <div className="flex-1 min-h-full p-4 pt-0">
            <div className="px-3">
              <LexicalReadOnlyEditor initialData={editorValue} />
            </div>
          </div>
        );
      case 'disabled':
        return <div className="flex-1 p-4 pt-0" />;
      default:
        return (
          <div className="flex-1 min-h-full p-4 pt-0">
            <div className="px-3">
              <LexicalReadOnlyEditor initialData={editorValue} />
            </div>
          </div>
        );
    }
  }, [bodyType, editorValue, bodySubmit]);

  // editorの子要素を非表示（フロートバーなど）
  useEffect(() => {
    if (bodyType !== 'editor') return;
    if (editorRef.current) {
      if (Object.keys(editorRef.current).length === 0) return;
      if (isDisabledEditorChild) {
        editorRef.current.setChildDisabled(true);
      } else {
        editorRef.current.setChildDisabled(false);
      }
    }
  }, [editorRef.current, isDisabledEditorChild, bodyType]);

  const renderHeartButton = useMemo(
    () => (
      <HeartIconBtn
        onClick={onClickLike}
        like={isLiked}
        disabled={
          !isMember && sharingPermission === SHARING_PERMISSION_TYPES.READ
        }
      />
    ),
    [isLiked, isMember, sharingPermission, onClickLike],
  );

  return (
    <>
      <BaseDialog
        onClose={onClose}
        zIndex={20}
        className={
          isEditCommentArea
            ? classNames('sm:max-w-7xl')
            : classNames('md:max-w-3xl')
        }
      >
        <div className="px-0 py-1 sm:p-6 md:grid md:grid-cols-5">
          {/* left-part */}
          <div
            className={
              isEditCommentArea
                ? classNames('px-2 md:col-span-3')
                : classNames('px-2 md:col-span-5')
            }
          >
            <div className="flex items-center justify-between h-8 ">
              {/* ヘッド部分 */}
              {isDisplayLiked && (
                <>
                  {renderHeartButton}
                  <Popover className="relative inline-block  mr-auto">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          tabIndex={-1}
                          className="inline-flex items-center justify-center"
                        >
                          <span
                            className={classNames(
                              'bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-100',
                              'inline-flex items-center font-medium mr-1.5 px-2.5 py-0.5 rounded-full',
                            )}
                          >
                            {localCountState.likeCount}
                          </span>
                        </Popover.Button>
                        <PopupTransition show={open}>
                          <Popover.Panel className="absolute left-0 z-20 mt-2 bg-white shadow-lg max-w-min min-w-max  origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none">
                            <div
                              id="likedScrollDiv"
                              className="overflow-y-auto max-h-56"
                            >
                              {open && (
                                <InfiniteScroll
                                  scrollThreshold="100px"
                                  dataLength={likedUsersMemo.length}
                                  next={loadNextPage}
                                  hasMore={hasNext}
                                  loader={
                                    <Divider
                                      label={t('読込中')}
                                      className="mx-4 mb-3"
                                    />
                                  }
                                  scrollableTarget="likedScrollDiv"
                                >
                                  {likedUsersMemo.map((likedUser) => (
                                    <div key={likedUser.id}>
                                      <div className="block px-3 py-2 text-left 'w-full relative'">
                                        <div className="relative flex items-center">
                                          <AvatarIcon
                                            src={likedUser.photoURL}
                                            avatarName={likedUser.displayName}
                                            isAnonymous={likedUser.isAnonymous}
                                            size="md"
                                          />
                                          <div className="flex-1 min-w-0 ml-2 text-xs">
                                            <span>{likedUser.displayName}</span>
                                            <div className="mt-1 dark:text-gray-400">
                                              {new DayjsUtil(
                                                likedUser.likedAt?.toDate(),
                                              ).format(t('YYYY-MM-DD HH-mm'))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </InfiniteScroll>
                              )}
                            </div>
                          </Popover.Panel>
                        </PopupTransition>
                      </>
                    )}
                  </Popover>
                </>
              )}
              <div className="flex items-center ml-auto">
                <div className="flex ml-4 lg:ml-6">
                  {/* 公開設定更新 */}
                  {isMember && plan?.type !== PLANS.FREE && (
                    <div className="relative inline-block p-1 ml-auto">
                      <MenuButton
                        toolTip={
                          currentItem?.isShare === true
                            ? t('ゲストに公開されています')
                            : t('ゲストに公開されていません')
                        }
                        id="togglePublishItems"
                        type="icon"
                        onClick={() => setIsShareDialogOpen(true)}
                      >
                        {currentItem?.isShare === true && (
                          <EyeIcon className="w-4 h-4" aria-hidden="true" />
                        )}
                        {currentItem?.isShare === false && (
                          <EyeSlashIcon
                            className="w-4 h-4"
                            aria-hidden="true"
                          />
                        )}
                      </MenuButton>
                    </div>
                  )}
                  {/* システムプロパティ */}
                  <SystemProperty systemProperties={systemProperties.current} />
                  {/* プロパティ一覧側メニュー */}
                  {currentItem && (
                    <ItemDropDown
                      className="relative inline-block p-1 ml-auto"
                      viewItem={currentViewItem as MemberGuestViewItem}
                      onDeleteItem={onClose}
                    />
                  )}
                  {/* コメントエリアがない場合の×ボタン */}
                  {!isEditCommentArea ? (
                    <div className="relative inline-block p-1 ml-auto">
                      <MenuButton
                        id="closeItemEdit"
                        type="icon"
                        onClick={onClose}
                      >
                        <XMarkIcon className="w-4 h-4" />
                      </MenuButton>
                    </div>
                  ) : (
                    <MenuButton
                      type="icon"
                      id="closeItemEdit"
                      className="flex sm:hidden"
                      onClick={onClose}
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </MenuButton>
                  )}
                </div>
              </div>
            </div>

            <div
              className="flex flex-col border border-gray-200 dark:border-gray-600 sm:mt-4 rounded-md"
              style={{ height: 'calc(100vh - 196px)' }}
            >
              {/* 閲覧権限の場合 */}
              {isReadOnly && (
                <div className="overflow-y-auto">
                  <div className="p-4 space-y-4">
                    {/* タイトル */}
                    <div className="items-start px-2 border-b border-gray-200 dark:bg-gray-800 grid grid-cols-12 gap-2 dark:border-gray-600">
                      <div className="block py-2 text-2xl font-bold text-gray-800 break-all dark:text-gray-100 col-span-12">
                        {currentItem?.title}
                      </div>
                    </div>

                    {/* プロパティ */}
                    {zodPropertySetting.fields.map((property) => (
                      <div
                        key={property.id}
                        className="items-start px-3 py-1 dark:bg-gray-800 sm:grid sm:grid-cols-12 gap-2 "
                      >
                        <div className="block py-1 my-auto font-medium text-gray-800 break-all dark:text-gray-100 col-span-3">
                          {property.propertyName}
                        </div>

                        <div className="block py-1 col-span-9">
                          {readOnlyPropertySelector(property)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="p-4 pt-0 mb-2">
                    <Divider />
                  </div>
                  {/* 本文 */}
                  {renderBody}
                </div>
              )}
              {/* 閲覧権限以外の場合 */}
              {(isMember || !isReadOnly) && (
                <div className="relative flex flex-col justify-between h-full">
                  <div className="flex-1 overflow-y-auto border-b dark:border-gray-700">
                    <FormProvider {...methods}>
                      <div className="p-4">
                        <div className="pb-4 border-b border-gray-200 dark:border-gray-600 grid grid-cols-12 gap-2">
                          <div className="block font-medium text-gray-800 col-span-12 dark:text-gray-100">
                            <div className="block font-medium text-gray-800 col-span-12 dark:text-gray-100">
                              <FormCol>
                                {/* 編集の場合はタイトルのツールチップは下に */}
                                <Input
                                  className="text-lg font-bold"
                                  name="itemTitle"
                                  placeholder={t('タイトル')}
                                  showTooltipBellow
                                  onKeyDown={enterForm}
                                />
                              </FormCol>
                            </div>
                          </div>
                        </div>
                        {/* プロパティ設定 */}
                        <ItemProperties
                          isValueEditPermission={!isMember && !isReadOnly}
                          onKeyDown={enterForm}
                        />
                      </div>
                      <div className="p-4 pt-0 mb-2">
                        <Divider />
                      </div>
                      {/* 本文 */}
                      {renderBody}
                    </FormProvider>
                  </div>
                  <div className="z-20 flex flex-row-reverse order-last px-4 py-3 max-h-20 rounded-md sm:px-6 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
                    <FormProvider {...methods}>
                      <FormButton
                        ref={submitCloseButtonRef}
                        id="applyEditItemClose"
                        submit
                        variant="primary"
                        className="ml-3 sm:ml-3 sm:w-auto"
                        disabled={!canSubmit}
                        onClick={formSubmitAndClose}
                        toolTip={itemSubmitTooltip}
                      >
                        {t('更新して閉じる')}
                      </FormButton>
                      <FormButton
                        id="applyEditItem"
                        submit
                        variant="primary"
                        className="sm:ml-3 sm:w-auto"
                        disabled={!canSubmit}
                        onClick={formSubmit}
                      >
                        {t('更新')}
                      </FormButton>
                      <FormButton
                        id="cancelEditItem"
                        className="mr-3 sm:mr-0 sm:w-auto"
                        onClick={onClose}
                      >
                        {t('キャンセル')}
                      </FormButton>
                      <div className="hidden sm:flex sm:items-center sm:justify-end sm:pr-3 sm:text-gray-400 sm:grow sm:text-xxs sm:dark:text-gray-600">
                        {itemSubmitMemo}
                      </div>
                    </FormProvider>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* right-part */}
          {renderCommentArea}
        </div>
      </BaseDialog>
      {/* 編集破棄確認ダイアログ */}
      <ConfirmDialog
        key="item-discard-confirm"
        open={discardDialogOpen}
        onClose={handleDiscardConfirm}
        title={t('アイテムの編集を破棄')}
        positive={t('破棄')}
        warning
      >
        <p className="mb-2">
          {t('<title> の編集を破棄しようとしています。', {
            title: <span className="mr-1 font-bold">アイテム</span>,
          })}
        </p>
        <p>
          {t('破棄された内容は復元できません。<br> 本当によろしいですか？', {
            br: <br />,
          })}
        </p>
      </ConfirmDialog>
      {/* 公開設定変更ダイアログ */}
      <ConfirmDialog
        key="item-isShare-confirm"
        open={isShareDialogOpen}
        onClose={handleChangeIsShare}
        title={t('公開設定を更新')}
        positive={t('更新')}
        warning
      >
        <p className="mb-2">
          {currentItem?.isShare === false
            ? t('<name> を 公開 しますか？', {
                name: (
                  <span className="mr-1 font-bold">{currentItem?.title}</span>
                ),
              })
            : t('<name> を 非公開 にしますか？', {
                name: (
                  <span className="mr-1 font-bold">{currentItem?.title}</span>
                ),
              })}
        </p>
      </ConfirmDialog>
    </>
  );
}
export default ItemEditDialog;
