import { ILoginMode } from '@/@types/common';
import { RootState } from '@/@types/models';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { LOGIN_MODE, VALIDATION_MODE } from '@/libs/const';
import { t2s } from '@/libs/utils';

import { INVITE_EMAIL } from '@/libs/validations';

import HeaderLogo from '@/components/Common/HeaderLogo';
import Loading from '@/components/Common/Loading';

import EmailPasswordLoginForm from '@/components/Login/EmailPasswordLoginForm';
import GoogleLogin from '@/components/Login/GoogleLogin';
import LoginModeContext from '@/components/Login/LoginModeContext';

import InvitingGuest from '@/pages/Login/InvitingGuest';
import InvitingMember from '@/pages/Login/InvitingMember';

import { HowToUse } from '@/pages/SignUp';

import useInviteInfo from '@/hooks/useInviteInfo';

interface ILoginProps {
  mode?: ILoginMode;
}

/**
 * ログイン
 * @param props
 * @returns
 */
export default function Login(props: ILoginProps) {
  const { mode } = props;
  const { t } = useTranslation();
  const { isLoaded } = useSelector((state: RootState) => state.uiState);

  const { isInviteDataLoaded, invitingWorkspaceName, signupUrl } =
    useInviteInfo(mode);

  // Zod schema定義
  const schema = z.object({
    email: z
      .string()
      .min(1, { message: t('入力してください') })
      .email(t2s(t('正しいメールアドレスを入力してください')))
      .max(
        INVITE_EMAIL.max,
        t2s(t('<max>文字以内で入力してください', INVITE_EMAIL)),
      ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      email: '',
    },
  });

  // Loading表示
  if (!isLoaded) return <Loading className="w-full h-screen" />;

  if (mode && !isInviteDataLoaded)
    return <Loading className="w-full h-screen" />;

  return (
    <LoginModeContext mode={mode}>
      <div className="flex flex-col h-full min-h-full px-6 py-12 overflow-auto lg:px-8">
        <HeaderLogo />

        {mode ? (
          <div className="mt-6 text-xl text-center">
            {t('招待されたワークスペースに参加')}
          </div>
        ) : (
          <>
            <div className="mt-6 text-xl text-center">
              {t('collabbleにログイン')}
            </div>

            <HowToUse />
          </>
        )}

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 border shadow sm:rounded-lg sm:px-10 dark:border-gray-700">
            {/** メンバー招待時の表示 */}
            {mode === LOGIN_MODE.INVITING_MEMBER && (
              <InvitingMember workspaceName={invitingWorkspaceName as string} />
            )}
            {/** ゲスト招待時の表示 */}
            {mode === LOGIN_MODE.INVITING_GUEST && (
              <InvitingGuest workspaceName={invitingWorkspaceName as string} />
            )}
            <EmailPasswordLoginForm email={methods.getValues().email} />

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300 dark:border-gray-700" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white dark:bg-gray-800">
                    {t('またはお持ちのアカウントで')}
                  </span>
                </div>
              </div>

              <div className="mt-6 ">
                <GoogleLogin />
              </div>
            </div>
          </div>
          <div className="px-2 mt-6 text-center">
            {t('新規アカウント登録は<signUpLink>', {
              signUpLink: (
                <Link to={signupUrl} className="ml-2 link">
                  {t('こちら')}
                </Link>
              ),
            })}
          </div>
        </div>
      </div>
    </LoginModeContext>
  );
}
