class Ua {
  private ua = '';

  constructor() {
    this.ua = window.navigator.userAgent.toLowerCase();
  }

  isIOS() {
    return (
      this.ua.indexOf('ipad') >= 0 ||
      this.ua.indexOf('iphone') >= 0 ||
      this.ua.indexOf('mac os') >= 0
    );
  }

  isWindowsOS() {
    return this.ua.indexOf('windows') >= 0;
  }

  // それぞれのブラウザ/OS毎に必要な対応があれば追記

  isFireFox() {
    return this.ua.indexOf('firefox');
  }
}

export default new Ua();
