import { IModalDialogProps } from '@/@types/ui';
import { Transition, Dialog } from '@headlessui/react';

import React, { ReactNode, Fragment, useRef } from 'react';

import { classNames } from '@/libs/styleUtils';

interface IBaseDialogProps extends IModalDialogProps {
  children: ReactNode;
  zIndex?: 10 | 20 | 30 | 40 | 50; // 多重モーダルのばあいzIndexを指定する
  className?: string;
  top?: boolean;
}

// サイズ指定・zIndex指定のみのダイアログ
export default function BaseDialog(props: IBaseDialogProps) {
  const { children, zIndex, className, top, onClose } = props;
  const dialogZIndex = zIndex ? `z-${zIndex}` : 'z-10';
  const buttonRef = useRef(null);

  return (
    <Dialog
      initialFocus={buttonRef}
      unmount={false}
      as="div"
      className={classNames(dialogZIndex, 'fixed inset-0 overflow-y-auto')}
      onClose={onClose}
    >
      <div className="flex items-end justify-center min-h-screen p-0 text-center sm:block">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-black bg-opacity-75 dark:bg-opacity-40 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        {!top && (
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
        )}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            ref={buttonRef}
            className={classNames(
              className,
              'inline-block w-full overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl dark:bg-gray-800 transform transition-all sm:my-8 sm:align-middle sm:w-full',
            )}
          >
            {children}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  );
}
