import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IInvitingMemberProps {
  workspaceName: string;
}

/**
 * メンバー招待用ログイン画面表示 (TODO: メンバー招待未実装)
 * 暫定ゲスト招待と同様のスタイル
 * @returns
 */
export default function InvitingMember(props: IInvitingMemberProps) {
  const { t } = useTranslation();
  const { workspaceName } = props;

  return (
    <div>
      <div className="mb-4 text-center sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <div className="mb-3 text-sm">
            {t('アカウントを登録して以下のワークスペースに参加します')}
          </div>
          <div className="py-2">
            <p className="block text-xs font-medium text-gray-700 dark:text-gray-400">
              {t('ワークスペース')}
            </p>
            <p className="block font-bold">{workspaceName}</p>
          </div>
        </div>
      </div>
      <div className="mb-6 border-b border-gray-200 dark:border-gray-700" />
    </div>
  );
}
