import {
  Guest,
  IJoinedGuestWithProjects,
  RootState,
  TempGuestDoc,
} from '@/@types/models';
import { Transition, Dialog } from '@headlessui/react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { serverTimestamp } from 'firebase/firestore';
import i18next from 'i18next';
import React, { Fragment, useCallback, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import { toast } from 'react-toastify';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';
import {
  getGuestsPath,
  getInvitingGuestsPath,
  getTempGuestsPath,
} from '@/libs/docPathUtils';

import { t2s } from '@/libs/utils';

import { EMAIL } from '@/libs/validations';

import InvitingGuestRepository from '@/repositories/InvitingGuestRepository';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import ConfirmDialog from '../Common/ConfirmDialog';
import FormButton from '../Common/Forms/Buttons/FormButton';
import Input from '../Common/Forms/Input';
import GuestProjectList from './GuestProjectList';

export interface INotificationDropdownProps {
  accounts?: IJoinedGuestWithProjects[];
  show?: boolean;
  onClose: () => void;
  selectedAccount: IJoinedGuestWithProjects | null;
}

export type AccountListChangeEvent =
  | 'invite'
  | 'uninvite'
  | 'remove'
  | 'unremove';

export default function GuestProjectDrawer(props: INotificationDropdownProps) {
  const { t } = useTranslation();
  const rrfFirestore = useFirestore();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCheckboxDialogOpen, setDeleteCheckboxDialogOpen] =
    useState(false);
  const { show, onClose, selectedAccount, accounts } = props;
  const { currentMyWorkspace } = useMyWorkspaces();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // メールアドレス重複チェック（mock）
  let checkDuplicateEmail = (v: string) => typeof v === 'string';

  // Zod schema定義
  const schema =
    selectedAccount === null
      ? z.object({
          inviteEmail: z
            .string()
            .min(1, { message: t('入力してください') || '入力してください' })
            .email(t2s(t('正しいメールアドレスを入力してください')))
            .max(EMAIL.max, {
              message: t2s(t('<max>文字以内で入力してください', EMAIL)),
            })
            .refine(
              (v) => checkDuplicateEmail(v),
              t2s(t('<label>が重複しています', { label: t('メールアドレス') })),
            ),
          projects: z.array(
            z.object({
              hasChanged: z.string(),
              shouldBeGuest: z.boolean(),
              projectId: z.string(),
            }),
          ),
        })
      : z.object({
          projects: z.array(
            z.object({
              hasChanged: z.string(),
              shouldBeGuest: z.boolean(),
              projectId: z.string(),
            }),
          ),
        });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      inviteEmail: null,
      projects: [],
    },
  });

  const watchProject = methods.watch('projects', []);

  // メールアドレス重複チェック
  checkDuplicateEmail = (v: string) => {
    const res = accounts?.find((o) => o.email === v);
    if (res) return false;
    return true;
  };

  // チェック全解除ボタン状態
  const isDisabled = useMemo(
    () => !watchProject?.some((x) => (x as any).shouldBeGuest === true),
    [JSON.stringify(watchProject)],
  );

  const hasChanges = useMemo(
    () => watchProject?.some((x) => (x as any).hasChanged === 'true'),
    [JSON.stringify(watchProject)],
  );

  // Submitボタン状態
  const isDisabledApply = useMemo(
    () => !methods.formState.isValid || !hasChanges,
    [methods.formState.isValid, hasChanges],
  );

  const submitAndConfirm = (data: any) => {
    // 変更があったプロジェクト取得
    const changedProjects = data.projects.filter(
      (p: any) => p.hasChanged === 'true',
    );

    if (currentMyWorkspace === null || currentMyWorkspace?.id === null) {
      return;
    }
    const email = data.inviteEmail ?? selectedAccount?.email;
    if (!email) {
      return;
    }

    // プロジェクト一覧
    const projectIds = data.projects
      .filter((p: any) => p.shouldBeGuest)
      .map((p: any) => p.projectId);

    const hasJoinedAccount = selectedAccount?.joined === true;
    if (!hasJoinedAccount && projectIds.length > 0) {
      // function：inviteGuestをコール
      try {
        const invitingGuestRepository = new InvitingGuestRepository();
        invitingGuestRepository.inviteGuest(
          currentMyWorkspace.id,
          projectIds,
          email,
          i18next.resolvedLanguage,
        );
      } catch (e) {
        toast.error(t('ゲストの招待に失敗しました'));
      }
    }

    // ゲスト招待の変更があったプロジェクトの処理
    changedProjects.forEach((p: any) => {
      const { guestId, projectId } = selectedAccount?.userIdAndProjectIds.find(
        (x) => x.projectId === p.projectId,
      ) || { guestId: selectedAccount?.id || '', projectId: p.projectId };

      if (selectedAccount && selectedAccount?.id) {
        // tempGestの追加
        if (p.shouldBeGuest && hasJoinedAccount) {
          const tempGuests: TempGuestDoc = {
            sysCreatedAt: serverTimestamp(),
            sysCreatedBy: userId,
            sysUpdatedAt: serverTimestamp(),
            sysUpdatedBy: userId,
          };
          // 招待中ゲスト追加
          rrfFirestore.set<TempGuestDoc>(
            `${getTempGuestsPath(currentMyWorkspace.id, projectId)}/${guestId}`,
            tempGuests,
          );
        }

        if (!p.shouldBeGuest) {
          // 参加済みゲストの除外
          rrfFirestore.delete<Guest>(
            `${getGuestsPath(currentMyWorkspace.id, projectId)}/${guestId}`,
          );
          // 招待中の除外
          rrfFirestore.delete<Guest>(
            `${getInvitingGuestsPath(
              currentMyWorkspace.id,
              projectId,
            )}/${guestId}`,
          );
        }
      }
    });
    onClose();
  };

  const onSubmit = methods.handleSubmit(async (data: any) => {
    const noProjectsAtAll = !watchProject?.some(
      (x) => (x as any).shouldBeGuest === true,
    );
    if (noProjectsAtAll) {
      setDeleteCheckboxDialogOpen(true);
      return false;
    }
    submitAndConfirm(data);
    return true;
  });

  // ゲスト招待一括編集破棄確認モーダル
  const handleDeleteConfirm = useCallback(
    async (feedback?: boolean) => {
      if (feedback === true) {
        onClose();
      }
      setDeleteDialogOpen(false);
    },
    [deleteDialogOpen],
  );

  // ゲスト招待一括解除更新確認モーダル
  const handleCheckboxDeleteConfirm = useCallback(
    async (feedback?: boolean) => {
      if (feedback === true) {
        submitAndConfirm(methods.getValues());
      }
      setDeleteCheckboxDialogOpen(false);
    },
    [deleteCheckboxDialogOpen],
  );

  const uncheckAllProjects = useCallback(async () => {
    watchProject.forEach((p: any, i: number) => {
      // ネストされた式はReactのsetValueでサポートされているが、ESLintでエラーが出るためエラー回避として記載。
      // @ts-ignore: False positive error
      methods.setValue(`projects[${i}].shouldBeGuest`, false, {
        shouldDirty: true,
      });
    });
    methods.trigger();
  }, [watchProject]);

  const defaultProjects = new Set(
    selectedAccount?.userIdAndProjectIds.map((p) => p.projectId) || [],
  );

  const onDrawerClose = useCallback(() => {
    if (hasChanges) {
      setDeleteDialogOpen(true);
    } else {
      onClose();
    }
  }, [hasChanges, deleteDialogOpen, onClose]);

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 h-screen overflow-hidden"
          onClose={onDrawerClose}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-400"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-400"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md md::max-w-md lg:max-w-lg xl:max-w-xl">
                  <div className="flex flex-col h-full pt-6 border-l border-gray-200 shadow-xl dark:border-gray-800 bg-gray-50 dark:bg-gray-700">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="flex px-2 font-medium text-gray-800 text-md dark:text-gray-300">
                          <div className="flex-1 mr-4">
                            {t('プロジェクト一覧')}
                          </div>
                        </Dialog.Title>
                        <div className="flex items-center ml-3 h-7">
                          <MenuButton
                            id="showModal"
                            type="icon"
                            tabIndex={-1}
                            onClick={onDrawerClose}
                          >
                            <XMarkIcon className="w-4 h-4" />
                          </MenuButton>
                        </div>
                      </div>
                    </div>
                    <FormProvider {...methods}>
                      <form onSubmit={onSubmit}>
                        <div className="sticky flex-1 h-screen pb-0 overflow-y-auto">
                          <div className="sticky top-0 z-40 pb-2 bg-gray-50 dark:bg-gray-700">
                            <div className="p-4 pb-2">
                              {/* 登録済みゲスト */}
                              {selectedAccount !== null && (
                                <div className="p-4 mt-2 bg-gray-100 dark:bg-gray-900 rounded-md">
                                  <h3 className="font-medium">
                                    {selectedAccount.displayName || ''}
                                  </h3>
                                  <h3 className="font-medium">
                                    {selectedAccount.email}
                                  </h3>
                                </div>
                              )}
                              {/* 新規ゲスト */}
                              {selectedAccount === null && (
                                <div className="mt-4">
                                  <Input
                                    property="value"
                                    placeholder={
                                      t('招待先のEメールアドレス') ||
                                      '招待先のEメールアドレス'
                                    }
                                    autoFocus
                                    name="inviteEmail"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="max-w-3xl px-2 mx-2 sm:py-4 sm:flex sm:flex-row-reverse">
                              {/* 参加するプロジェクトをすべて外す */}
                              {selectedAccount === null ? (
                                ''
                              ) : (
                                <FormButton
                                  id="unSelectAllGuest"
                                  disabled={isDisabled}
                                  className="mt-4 md-mt-0 sm:w-auto sm:ml-3"
                                  onClick={uncheckAllProjects}
                                >
                                  {t('すべてのプロジェクトから外す')}
                                </FormButton>
                              )}
                            </div>
                          </div>
                          <div className="px-2 mb-24 h-fit">
                            <GuestProjectList
                              defaultProjects={defaultProjects}
                              key="guestProjectList"
                              accounts={selectedAccount}
                            />
                          </div>
                          <div className="fixed bottom-0 z-10 w-full max-w-3xl px-4 py-3 dark:border-t dark:border-gray-500 sm:py-4 sm:flex sm:flex-row-reverse bg-gray-100/80 dark:bg-gray-700/80 backdrop-blur-sm">
                            {selectedAccount === null ? (
                              <FormButton
                                id="addInviteGuest"
                                disabled={isDisabledApply}
                                submit
                                variant="primary"
                                className="sm:w-auto sm:ml-3"
                              >
                                {t('招待')}
                              </FormButton>
                            ) : (
                              <FormButton
                                id="applyGuest"
                                disabled={isDisabledApply}
                                submit
                                variant="primary"
                                className="sm:w-auto sm:ml-3"
                              >
                                {t('更新')}
                              </FormButton>
                            )}
                            <FormButton
                              id="cancelRemoveGuest"
                              className="mt-4 md-mt-0 sm:w-auto sm:ml-3"
                              onClick={onDrawerClose}
                            >
                              {t('キャンセル')}
                            </FormButton>
                          </div>
                        </div>
                      </form>
                    </FormProvider>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ConfirmDialog
        key="comment-delete-confirm"
        open={deleteDialogOpen}
        onClose={handleDeleteConfirm}
        title={t('プロジェクトの一括共有編集を削除')}
        positive={t('破棄')}
        warning
      >
        <div />
        <p>
          {t('設定を破棄します。<br> 本当によろしいですか？', { br: <br /> })}
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        key="checkbox-all-delete-confirm"
        open={deleteCheckboxDialogOpen}
        onClose={handleCheckboxDeleteConfirm}
        title={t('ゲストをワークスペースから除外')}
        positive={t('実行')}
        warning
      >
        <div />
        <p>
          {t(
            '参加プロジェクトがないため本ゲストはワークスペースから除外されます。<br> 本当によろしいですか？',
            { br: <br /> },
          )}
        </p>
      </ConfirmDialog>
    </>
  );
}
