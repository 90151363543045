/* This example requires Tailwind CSS v2.0+ */
import { ISharingPermissionType } from '@/@types/models';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';

import { SHARING_PERMISSION_TYPES } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import PlanUpgradeBadge from '@/components/Common/PlanUpgradeBadge';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';

import usePlanProtections from '@/hooks/usePlanProtections';

export type IDropDownProps = {
  sharingPermission: ISharingPermissionType;
  disabledAuthMember?: boolean;
};

/**
 * 共有設定内の権限選択ドロップダウン
 * @param {IDropDownProps} props
 * @returns ShareDropDown
 */
export default function ShareDropDown(props: IDropDownProps) {
  const { disabledAuthMember, sharingPermission } = props;

  const { t } = useTranslation();
  const { isShowDowngradeBanner } = usePlanProtections();

  const publishingOptions = useMemo(
    () => [
      {
        title: t('編集'),
        description: t(
          '共有されたプロジェクトのアイテムの編集やコメント、いいねが行えます。',
        ),
        current: true,
        auth: SHARING_PERMISSION_TYPES.EDIT,
        disabled:
          disabledAuthMember ||
          (isShowDowngradeBanner &&
            sharingPermission !== SHARING_PERMISSION_TYPES.EDIT),
      },
      {
        title: t('コメント・いいね'),
        description: t(
          '共有されたプロジェクトのアイテムへのコメントといいねが行えます。',
        ),
        current: false,
        auth: SHARING_PERMISSION_TYPES.COMMENT,
        disabled: false,
      },
      {
        title: t('閲覧'),
        description: t(
          '共有されたプロジェクトの閲覧のみ行えます。コメントやいいねはできません。',
        ),
        current: false,
        auth: SHARING_PERMISSION_TYPES.READ,
        disabled:
          disabledAuthMember ||
          (isShowDowngradeBanner &&
            sharingPermission !== SHARING_PERMISSION_TYPES.READ),
      },
    ],
    [disabledAuthMember, isShowDowngradeBanner, sharingPermission],
  );

  // プランによってセレクト項目の並び替え処理
  const publishingOptionsOrder = useMemo(
    () =>
      publishingOptions.sort((a, b) => Number(a.disabled) - Number(b.disabled)),
    [publishingOptions],
  );

  const currentAuth = useMemo(
    () => publishingOptions.find((p) => p.auth === sharingPermission),
    [publishingOptions, sharingPermission],
  );

  const [selected, setSelected] = useState(currentAuth);

  const { setValue, trigger } = useFormContext();

  const changeAuth = (defValue: {
    title: string;
    description: string;
    current: boolean;
    auth: string;
    disabled: boolean;
  }) => {
    setSelected(defValue);
    setValue('sharingPermission', defValue.auth, {
      shouldValidate: true,
      shouldDirty: true,
    });
    trigger();
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <Listbox value={selected} onChange={changeAuth}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">
            Change published status
          </Listbox.Label>
          <div className="relative">
            <div className="inline-flex border border-gray-300 rounded-md dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-primary-500">
              <div className="relative z-0 inline-flex rounded-md">
                <Listbox.Button className="relative inline-flex items-center text-sm font-medium text-gray-500 rounded-md focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2  dark:ring-gray-500 dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600">
                  <div className="relative inline-flex items-center px-1 py-2 text-sm font-medium text-gray-500 border border-transparent bg-white-100 rounded-l-md ml-2.5 dark:text-gray-300">
                    {selected && selected.title}
                  </div>
                  <ChevronDownIcon
                    className="w-4 h-4 ml-2 mr-2"
                    aria-hidden="true"
                  />
                </Listbox.Button>
                <span className="sr-only">Change sharing permission</span>
              </div>
            </div>
            <PopupTransition show={open}>
              <Listbox.Options className="absolute z-20 mt-2 overflow-hidden bg-white shadow-lg origin-top-right right-100 w-72 rounded-md dark:bg-gray-700 divide-y divide-gray-100 dark:divide-gray-600 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {publishingOptionsOrder.map((option) => {
                  const { disabled } = option;
                  return (
                    <Listbox.Option
                      disabled={disabled}
                      key={option.auth}
                      className={classNames(
                        disabled
                          ? 'bg-gray-50 cursor-not-allowed'
                          : 'bg-white cursor-pointer',
                        'text-gray-600 dark:text-gray-200 dark:bg-gray-700 relative text-sm',
                      )}
                      value={option}
                    >
                      {({ active }) => (
                        <div
                          className={classNames(
                            'flex flex-col p-4',
                            active
                              ? 'text-white bg-primary-500 dark:bg-primary-600'
                              : '',
                          )}
                        >
                          <div className="flex justify-between">
                            <p
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                disabled ? 'opacity-40' : '',
                              )}
                            >
                              {option.title}
                            </p>
                            {selected?.auth === option?.auth ? (
                              <span
                                className={
                                  active
                                    ? 'text-gray-500 dark:text-gray-200'
                                    : 'text-gray-200'
                                }
                              >
                                <CheckIcon
                                  className="w-5 h-5 dark:text-primary-600 text-primary-500"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                          <div className="mt-3">
                            <span className={disabled ? 'opacity-40' : ''}>
                              {option.description}
                            </span>
                            {disabled && <PlanUpgradeBadge className="mt-3" />}
                          </div>
                        </div>
                      )}
                    </Listbox.Option>
                  );
                })}
              </Listbox.Options>
            </PopupTransition>
          </div>
        </>
      )}
    </Listbox>
  );
}
