import React from 'react';

import { classNames } from '@/libs/styleUtils';

// Tailwind Icon用SVGソース
// https://www.tailwindtoolbox.com/icons

interface IIconProps {
  className?: string;
}

interface IMentionIconProps {
  className?: string;
  bgColor?: string;
}

// 書式アイコン
export function FormatOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
      <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
      <line x1="16" y1="5" x2="19" y2="8" />
    </svg>
  );
}

// ボールドアイコン
export function BoldOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <path d="M7 5h6a3.5 3.5 0 0 1 0 7h-6z" />
      <path d="M13 12h1a3.5 3.5 0 0 1 0 7h-7v-7" />
    </svg>
  );
}

// イタリックアイコン
export function ItalicOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <line x1="11" y1="5" x2="17" y2="5" />
      <line x1="7" y1="19" x2="13" y2="19" />
      <line x1="14" y1="5" x2="10" y2="19" />
    </svg>
  );
}

// 下線アイコン
export function UnderlineOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <line x1="6" y1="20" x2="18" y2="20" />
      <path d="M8 5v6a4 4 0 0 0 8 0v-6" />
    </svg>
  );
}

// 打消し線
export function StrikethroughOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <line x1="5" y1="12" x2="19" y2="12" />
      <path d="M16 6.5a4 2 0 0 0 -4 -1.5h-1a3.5 3.5 0 0 0 0 7" />
      <path d="M16.5 16a3.5 3.5 0 0 1 -3.5 3h-1.5a4 2 0 0 1 -4 -1.5" />
    </svg>
  );
}

// 引用
export function QuoteOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <circle cx="12" cy="12" r="9" />
      <path d="M12 3a4.5 4.5 0 0 0 0 9a4.5 4.5 0 0 1 0 9" />
      <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
      <circle cx="12" cy="16.5" r=".5" fill="currentColor" />
    </svg>
  );
}

// マーカー
export function MarkerOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12 20h9" />
      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z" />
    </svg>
  );
}

// アラート
export function AlertOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" />
      <path d="M13.73 21a2 2 0 0 1-3.46 0" />
    </svg>
  );
}

// アラート(禁止)
export function AlertBannedOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.73 21a2 2 0 0 1-3.46 0" />
      <path d="M18.63 13A17.89 17.89 0 0 1 18 8" />
      <path d="M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14" />
      <path d="M18 8a6 6 0 0 0-9.33-5" /> <line x1="1" y1="1" x2="23" y2="23" />
    </svg>
  );
}

// スピナーサークル
export function SpinnerCircleIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={classNames(
        'text-primary-600 dark:text-primary-500 animate-spin ',
        className,
      )}
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="text-white opacity-40 dark:text-white dark:opacity-10"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
}

export function GroupOutlineIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
      />
    </svg>
  );
}

// 水平線
export function HorizontalIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 13h6m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
      />
    </svg>
  );
}

// 箇条書きリスト
export function BulletListIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" />
      <line x1="9" y1="6" x2="20" y2="6" />
      <line x1="9" y1="12" x2="20" y2="12" />
      <line x1="9" y1="18" x2="20" y2="18" />
      <line x1="5" y1="6" x2="5" y2="6.01" />
      <line x1="5" y1="12" x2="5" y2="12.01" />
      <line x1="5" y1="18" x2="5" y2="18.01" />
    </svg>
  );
}

// 数字付きリスト
export function NumberedListIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line strokeWidth="2" x1="8" y1="5.9999852" x2="21" y2="5.9999852" />
      <line strokeWidth="2" x1="8" y1="11.9999857" x2="21" y2="11.9999857" />
      <line strokeWidth="2" x1="8" y1="17.9999847" x2="21" y2="17.9999847" />
      <g>
        <path
          strokeWidth="0.5"
          d="M3.4213867,3.9310157v3.8291013c0,0.0859375-0.0273438,0.1582031-0.0820312,0.2167969
          c-0.078125,0.0839849-0.1855469,0.125977-0.3222656,0.125977c-0.1582031,0-0.2734375-0.0537109-0.3457031-0.1611333
          C2.6362305,7.8890233,2.6186523,7.8284764,2.6186523,7.7601171V3.9310157c0-0.1230469,0.0527344-0.2148438,0.1582031-0.2753906
          C2.8510742,3.6126564,2.9311523,3.591172,3.0170898,3.591172c0.1015625,0,0.1914062,0.0263672,0.2695312,0.0791016
          C3.3764648,3.7327735,3.4213867,3.8196876,3.4213867,3.9310157z"
        />
      </g>
      <g>
        <path
          strokeWidth="0.5"
          d="M2.3286133,13.4603128h2.0214844c0.0820312,0,0.1494141,0.0273438,0.2021484,0.0820312
          c0.0703125,0.0722656,0.1054688,0.1660156,0.1054688,0.28125c0,0.1503906-0.0517578,0.2578125-0.1552734,0.3222656
          c-0.0429688,0.0273438-0.09375,0.0410156-0.1523438,0.0410156H1.8540039c-0.3164062,0-0.4746094-0.1396484-0.4746094-0.4189453
          c0-0.1855469,0.0869141-0.3798828,0.2607422-0.5830078c0.2207031-0.2617188,0.6435547-0.6474609,1.2685547-1.1572266
          c0.2851562-0.234375,0.4853516-0.4199219,0.6005859-0.5566406c0.140625-0.1699219,0.2109375-0.3349609,0.2109375-0.4951172
          c0-0.1992188-0.0849609-0.3544922-0.2548828-0.4658203c-0.1308594-0.0859375-0.2841797-0.1289062-0.4599609-0.1289062
          c-0.3476562,0-0.609375,0.1591797-0.7851562,0.4775391c-0.0800781,0.1445312-0.1943359,0.2167969-0.3427734,0.2167969
          c-0.0703125,0-0.1376953-0.0185547-0.2021484-0.0556641c-0.1152344-0.0664062-0.1728516-0.1640625-0.1728516-0.2929688
          c0-0.1347656,0.0546875-0.2744141,0.1640625-0.4189453c0.1132812-0.1523438,0.2646484-0.2792969,0.4541016-0.3808594
          c0.2714844-0.1484375,0.5693359-0.2226562,0.8935547-0.2226562c0.3574219,0,0.6708984,0.0849609,0.9404297,0.2548828
          C4.347168,10.20543,4.543457,10.5472269,4.543457,10.9847269c0,0.3066406-0.1074219,0.5820312-0.3222656,0.8261719
          c-0.1289062,0.1445312-0.390625,0.3691406-0.7851562,0.6738281C2.9692383,12.8441019,2.6000977,13.1692972,2.3286133,13.4603128z"
        />
      </g>
      <g>
        <path
          strokeWidth="0.5"
          d="M3.4951172,18.0179291c0.234375,0.0253906,0.4287109,0.0830078,0.5830078,0.1728516
          c0.2128906,0.125,0.3642578,0.296875,0.4541016,0.515625c0.0488281,0.1210938,0.0732422,0.25,0.0732422,0.3867188
          c0,0.4492188-0.1855469,0.7978516-0.5566406,1.0458984C3.7480469,20.3401947,3.375,20.4407806,2.9296875,20.4407806
          c-0.5859375,0-1.0390625-0.1630859-1.359375-0.4892578c-0.1464844-0.1464844-0.2197266-0.2861328-0.2197266-0.4189453
          c0-0.09375,0.0322266-0.1708984,0.0966797-0.2314453c0.0742188-0.0703125,0.1611328-0.1054688,0.2607422-0.1054688
          c0.0878906,0,0.1621094,0.0283203,0.2226562,0.0849609c0.0136719,0.0117188,0.0429688,0.0458984,0.0878906,0.1025391
          c0.2128906,0.2578125,0.515625,0.3867188,0.9082031,0.3867188c0.2246094,0,0.4111328-0.046875,0.5595703-0.140625
          c0.2011719-0.125,0.3017578-0.3066406,0.3017578-0.5449219c0-0.203125-0.0605469-0.3681641-0.1816406-0.4951172
          c-0.0996094-0.1035156-0.2324219-0.1708984-0.3984375-0.2021484c-0.1171875-0.0214844-0.3066406-0.0332031-0.5683594-0.0351562
          c-0.2578125,0-0.3867188-0.109375-0.3867188-0.328125c0-0.2089844,0.1337891-0.3134766,0.4013672-0.3134766
          c0.2382812,0,0.4414062-0.0371094,0.609375-0.1113281c0.2421875-0.1054688,0.3632812-0.2802734,0.3632812-0.5244141
          c0-0.1738281-0.0693359-0.3115234-0.2080078-0.4130859c-0.1328125-0.0976562-0.3046875-0.1464844-0.515625-0.1464844
          c-0.3398438,0-0.6005859,0.1259766-0.7822266,0.3779297c-0.0683594,0.0957031-0.1582031,0.1435547-0.2695312,0.1435547
          c-0.0898438,0-0.1689453-0.0292969-0.2373047-0.0878906c-0.0742188-0.0625-0.1113281-0.1396484-0.1113281-0.2314453
          c0-0.0996094,0.0488281-0.2070312,0.1464844-0.3222656c0.1113281-0.1289062,0.2519531-0.2382812,0.421875-0.328125
          C2.3310547,15.9300394,2.6162109,15.86168,2.9267578,15.86168c0.3515625,0,0.65625,0.0751953,0.9140625,0.225585
          c0.3847656,0.2246094,0.5771484,0.5576172,0.5771484,0.9990234c0,0.2832031-0.1113281,0.5136719-0.3339844,0.6914062
          c-0.1503906,0.1191406-0.3291016,0.1962891-0.5361328,0.2314453C3.5361328,18.0110931,3.5185547,18.0140228,3.4951172,18.0179291z"
        />
      </g>
    </svg>
  );
}

export function MentionToMemberIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="レイヤー_1" />
      <g id="レイヤー_2" />
      <g id="レイヤー_3">
        <path
          d="M20.9990559,21H3.4890454c0.1547074-4.2382774,2.3512003-7.8372717,5.4138784-9.3737717
        c-1.1137295-0.9487-1.8149481-2.3614597-1.8149481-3.9289265c0-2.8461633,2.3099117-5.1560745,5.1560745-5.1560745
        s5.1560755,2.3099115,5.1560755,5.1560745c0,1.5674667-0.7012196,2.9802265-1.814949,3.9289265
        C18.6479187,13.1524696,20.8444099,16.7514,20.9990559,21z"
        />
      </g>
    </svg>
  );
}

export function MentionToMemberGroupIcon(props: IMentionIconProps) {
  const { className, bgColor } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="レイヤー_1" />
      <g id="レイヤー_2" />
      <g id="レイヤー_3">
        <g>
          <g>
            <circle
              strokeWidth="1.5"
              cx="7.9854312"
              cy="8.2714634"
              r="4.8445306"
            />
            <path
              strokeWidth="1.5"
              d="M14.4910603,21H1.4798015c0.0925843-4.0100098,1.7132052-7.4200439,3.9913554-8.8900146
            c0.7778664-0.5,1.6206207-0.7700195,2.5096107-0.7700195c0.5741582,0,1.120564,0.1100464,1.6484303,0.3300171h0.0092697
            C9.8699846,11.75,10.082963,11.8599854,10.3052101,11.9899902c0.1389332,0.0899658,0.2870798,0.1799927,0.426012,0.2799683
            c0.1296635,0.1000366,0.2685404,0.2000122,0.3982029,0.3099976c0.111124,0.1000366,0.2222481,0.2000122,0.3241014,0.3000488
            C13.2223501,14.5999756,14.4169588,17.5799561,14.4910603,21z"
            />
          </g>
          <g>
            <circle
              className={bgColor}
              cx="13.6840181"
              cy="8.2714634"
              r="4.8445306"
            />
            <path
              className={bgColor}
              d="M20.1896477,21H7.1783886c0.0125127-0.7393742,0.08075-1.3634415,0.152668-1.8414497
            c0.0000143-0.0000992,0.5790477-3.848609,2.7181234-6.1185722c0.5361328-0.5689373,1.1204863-0.9299488,1.1205645-0.9299927
            c0.0740442-0.039978,0.138876-0.0800171,0.2037077-0.1199951c0.2963486-0.1699829,0.5926981-0.3099976,0.8983164-0.4100342
            c0.1203938-0.0499878,0.2222471-0.0799561,0.3426409-0.0999756h0.0092697
            c0.3426409-0.0999756,0.6945524-0.1400146,1.0556765-0.1400146c0.2870789,0,0.5649443,0.0300293,0.8334846,0.0900269
            c0.676012,0.1199951,1.3242712,0.4099731,1.9169693,0.8399658c0.2222481,0.1600342,0.4444942,0.3500366,0.6574726,0.5500488
            C18.8931293,14.5299683,20.1062775,17.539978,20.1896477,21z"
            />
          </g>
          <g>
            <circle
              strokeWidth="1.5"
              cx="16.0155144"
              cy="8.2714634"
              r="4.8445306"
            />
            <path
              strokeWidth="1.5"
              d="M22.521143,21H9.5098839c0.0833712-3.5499878,1.361351-6.6199951,3.2412424-8.2999878
            c0.0370789-0.0500488,0.0833712-0.0800171,0.1296625-0.1200562c0.2037086-0.1699829,0.4167433-0.3299561,0.6297207-0.4699707
            c0.5278664-0.3400269,1.0927544-0.5800171,1.6854525-0.6799927c0.2685394-0.0599976,0.5463495-0.0900269,0.8242159-0.0900269
            c0.6019096,0,1.1760693,0.1199951,1.7317429,0.3600464c0.3519115,0.1499634,0.6852837,0.3399658,1.0093842,0.5699463
            C20.9190617,13.8200073,22.4285583,17.1300049,22.521143,21z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function MentionToGuestIcon(props: IIconProps) {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="レイヤー_1" />
      <g id="レイヤー_2" />
      <g id="レイヤー_3">
        <path
          d="M20.7399902,21H3.7600098c0.1199951-4.1700439,2.25-7.7000122,5.2399902-9.210022
        C7.9299927,10.8800049,7.25,9.5199585,7.25,8c0-2.7600098,2.2399902-5,5-5s5,2.2399902,5,5
        c0,1.5199585-0.6799927,2.8800049-1.75,3.789978C18.4899902,13.2999878,20.6199951,16.8299561,20.7399902,21z"
        />
      </g>
    </svg>
  );
}
