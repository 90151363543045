import {
  IFuncCreateWorkspace,
  IFuncCreateWorkspaceResult,
  IFuncUpdateOriginalWorkspaceId,
  FunctionsResult,
  IFuncExitWorkspace,
} from '@/@types/common';
import { Workspace } from '@/@types/models';
import { doc, getDoc } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';

import { REP_ERROR_MESSAGE } from '@/libs/const';

import { getWorkspacesPath } from '@/libs/docPathUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { functions } from '@/firebase';
import { firestore } from '@/firestore';

export interface IWorkspaceRepository {
  findById: (workspaceId: Workspace['id']) => Promise<Workspace | null>;
  createWorkspace: (
    data: IFuncCreateWorkspace,
  ) => Promise<IFuncCreateWorkspaceResult | null>;
  updateOriginalWorkspaceId: (
    data: IFuncUpdateOriginalWorkspaceId,
  ) => Promise<FunctionsResult | null>;
}

const funcCreateWorkspace = httpsCallable<
  IFuncCreateWorkspace,
  IFuncCreateWorkspaceResult
>(functions, 'createWorkspace');

const funcUpdateOriginalWorkspaceId = httpsCallable<
  IFuncUpdateOriginalWorkspaceId,
  FunctionsResult
>(functions, 'updateOriginalWorkspaceId');

const funcExitWorkspace = httpsCallable<IFuncExitWorkspace, FunctionsResult>(
  functions,
  'exitWorkspace',
);

export default class WorkspaceRepository implements IWorkspaceRepository {
  /**
   * find single workspace by id.
   * @param workspaceId
   * @returns Promise<Workspace | null>
   */
  async findById(workspaceId: Workspace['id']): Promise<Workspace | null> {
    try {
      const path = `${getWorkspacesPath()}/${workspaceId}`;
      const docRef = doc(firestore, path).withConverter(
        fsConverter<Workspace>(),
      );
      const d = await getDoc(docRef);
      const workspace = d.data();
      if (workspace === undefined) {
        return null;
      }
      workspace.id = d.id;
      return workspace;
    } catch (err) {
      repositoryError(this.findById.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * ワークスペース作成
   * @param data zodScheme
   * @returns
   */
  async createWorkspace(data: IFuncCreateWorkspace) {
    try {
      const res = await funcCreateWorkspace(data);
      return res.data;
    } catch (error) {
      throw new Error('Workspace creation failed.');
    }
  }

  /**
   * オリジナルワークスペースIDの更新
   * @param data zodScheme
   * @returns
   */
  async updateOriginalWorkspaceId(data: IFuncUpdateOriginalWorkspaceId) {
    const res = await funcUpdateOriginalWorkspaceId(data);
    return res.data;
  }

  /**
   * ワークスペース退出
   * @param data zodScheme
   * @returns
   */
  async exitWorkspace(data: IFuncExitWorkspace) {
    try {
      const res = await funcExitWorkspace(data);
      return res.data;
    } catch (error) {
      throw new Error('Failed to exit workspace.');
    }
  }
}
