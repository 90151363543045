import * as React from 'react';

import { MAX_NOTIFICATION_DISPLAY_COUNT } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

export interface INotificationBadgeProps {
  className?: string;
  unread: boolean;
  total: number;
  noDot?: boolean;
}

/**
 * 通知バッジ
 * @param props
 * @returns
 */
export default function NotificationBadge(props: INotificationBadgeProps) {
  const { unread, total, noDot, className } = props;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <span
        className={classNames(
          'inline-flex items-center text-xs font-medium rounded-full px-2 py-0.5 !leading-none',
          unread
            ? 'text-red-600 bg-red-100 dark:text-red-200 dark:bg-red-800'
            : 'text-gray-400 bg-black/5 dark:text-gray-400 dark:bg-white/10',
          className,
        )}
      >
        {!noDot && unread && (
          <svg
            className="w-2 h-2 text-red-400 -ml-0.5 mr-1.5"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx={4} cy={4} r={3} />
          </svg>
        )}
        {total > MAX_NOTIFICATION_DISPLAY_COUNT
          ? `+${MAX_NOTIFICATION_DISPLAY_COUNT}`
          : total}
      </span>
    </>
  );
}
