import { RootState, RRFUploadedFile } from '@/@types/models';

import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ExtendedFirebaseInstance,
  useFirebase,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { COL_AVATAR_FILES, getAvatarFilesPath } from '@/libs/docPathUtils';

import { uploadSelectFiles } from '@/libs/utils';

import { AVATAR_FILES } from '@/libs/validations';

import useHandleApi from '@/hooks/useHandleApi';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProfiles from '@/hooks/useProfiles';

type RRFUploadFileResult = Record<'File', RRFUploadedFile>;

/**
 * RRFのアップロード処理結果からファイルパスを抜き出す
 * @param uploadResult
 */
const unwrapFileUploadResult = <T extends RRFUploadFileResult>(
  uploadResult: T[],
) => uploadResult.map((ur) => ur.File.fullPath);

/**
 * アバター画像アップロード
 * @returns
 */
export default function useAvatarFiles() {
  const firebase: ExtendedFirebaseInstance = useFirebase();
  const rrfFirestore = useFirestore();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { profile, updatePhotoURL } = useProfiles();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const { exec } = useHandleApi();

  // カレントワークスペースID
  const currentWorkspaceId = useMemo(() => {
    if (!currentMyWorkspace || !currentMyWorkspace.workspaceId) return null;
    return currentMyWorkspace.workspaceId;
  }, [currentMyWorkspace]);

  // アバター画像パス正規表現
  const avatarFilePathRegExp = useMemo(() => {
    if (!currentMyWorkspace || !currentMyWorkspace.workspaceId) return null;
    return new RegExp(
      `^${getAvatarFilesPath(currentMyWorkspace.workspaceId)}/.+$`,
    );
  }, [currentMyWorkspace]);

  useFirestoreConnect(() =>
    currentWorkspaceId
      ? [
          {
            collection: getAvatarFilesPath(currentWorkspaceId),
            storeAs: COL_AVATAR_FILES,
          },
        ]
      : [],
  );

  // ファイルアップロード時のファイル名変更処理
  const onSelectFiles = useCallback(
    async (files: File[]) => {
      if (!currentWorkspaceId) throw new Error('current workspace not loaded.');
      return uploadSelectFiles(
        files,
        getAvatarFilesPath(currentWorkspaceId),
        rrfAuth,
        firebase,
      );
    },
    [currentWorkspaceId, profile],
  );

  // アップロード済ファイル削除
  const onDeleteFile = useCallback(
    (photoURL: string, docPath: string) => {
      if (!currentWorkspaceId) return;
      firebase.deleteFile(photoURL, docPath);
    },
    [currentWorkspaceId],
  );

  const uploadAvatarFile = async (files: File[]) => {
    exec(async () => {
      // 1. ファイル形式チェック
      await Promise.all(
        files.map(async (f) => {
          // 拡張子チェック
          const ext = f.name.split('.').pop() || '';
          if (
            !f.type.startsWith('image') ||
            !AVATAR_FILES.ext.includes(ext.toLowerCase())
          )
            throw new Error('File type error.');

          // ファイルサイズチェック
          if (f.size > AVATAR_FILES.size) throw new Error('File size error.');

          // ファイルフォーマットチェック
          const arrayBuffer = await f.arrayBuffer();
          const uint8array = new Uint8Array(arrayBuffer).subarray(0, 4);
          const header = uint8array.reduce(
            (prev, cur) => prev + cur.toString(16),
            '',
          );
          if (!AVATAR_FILES.magicNumber.test(header))
            throw new Error('File type error.');
        }),
      );

      // 2. 既存のファイルを削除
      if (
        currentWorkspaceId &&
        avatarFilePathRegExp &&
        profile?.photoURL &&
        avatarFilePathRegExp.test(profile?.photoURL)
      ) {
        // firestore(メタデータ)のパスはphotoURLと異なるので別途取得する
        const res = await rrfFirestore
          .collection(getAvatarFilesPath(currentWorkspaceId))
          .where('fullPath', '==', profile?.photoURL)
          .get();
        const docPath = res.docs.length > 0 ? res.docs[0].ref.path : '';
        onDeleteFile(profile?.photoURL, docPath);
      }

      // 3. ファイルアップロード
      const resultFiles = await onSelectFiles(files);
      // Storageにアップロードしたファイルのパス
      const paths = unwrapFileUploadResult(resultFiles);

      // 4. photoURL更新
      await updatePhotoURL(paths[0]);
    }, t('アバター画像アップロードを失敗しました'));
  };

  return {
    onSelectFiles,
    onDeleteFile,
    uploadAvatarFile,
  };
}
