import { zodResolver } from '@hookform/resolvers/zod';
import { DefaultTFuncReturn } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useFirebase } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { BASE_DOMAIN, RE_CAPTCHA_ACTIONS, VALIDATION_MODE } from '@/libs/const';

import { t2s } from '@/libs/utils';

import { INVITE_EMAIL } from '@/libs/validations';

import AccountRepository from '@/repositories/AccountRepository';

import Alert from '@/components/Common/Alert';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import Form from '@/components/Common/Forms/Form';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';
import Logo from '@/components/Common/Logo';
import LogoTitle from '@/components/Common/LogoTitle';

import useReCaptcha from '@/hooks/useReCaptcha';
import useSubmitState from '@/hooks/useSubmitState';
import useUrlQuery from '@/hooks/useUrlQuery';

/**
 * パスワードリセット
 * @param props
 * @returns
 */
export default function PasswordReset() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const rrfFirebase = useFirebase();
  const query = useUrlQuery();
  const [isSendEmail, setIsSendEmail] = useState<boolean>(false);
  const [error, setError] = useState<string | DefaultTFuncReturn | null>(null);
  const accountRepository = new AccountRepository();
  const { refreshToken, reCaptchaToken } = useReCaptcha(
    RE_CAPTCHA_ACTIONS.PASSWORD_RESET,
  );
  // Zod schema定義
  const schema = z.object({
    email: z
      .string()
      .min(1, { message: t('入力してください') })
      .email(t2s(t('正しいメールアドレスを入力してください')))
      .max(
        INVITE_EMAIL.max,
        t2s(t('<max>文字以内で入力してください', INVITE_EMAIL)),
      ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    methods.setValue('email', query.get('m') as string, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [query]);

  const formLoginSubmit = methods.handleSubmit(async (data) => {
    try {
      setError(null);
      const res = await accountRepository.checkEmailExistence(
        data.email,
        reCaptchaToken as string,
      );
      if (!res.data) {
        setError(t('登録されていないメールアドレスです'));
        return;
      }

      if (!res.data?.providerId) {
        setError(t('異なる認証方法で登録されたアカウントです'));
        return;
      }

      await rrfFirebase.auth().sendPasswordResetEmail(data.email);
      setIsSendEmail(true);
    } catch (e) {
      toast.error(t('メールの送信に失敗しました'));
      throw e;
    } finally {
      refreshToken();
      methods.reset({ ...data });
    }
  });

  useEffect(() => {
    setError(null);
  }, [methods.formState.isValid]);

  // Submitボタン状態
  const isDisabledSubmit = useSubmitState(methods) || !reCaptchaToken;

  const backHandler = useCallback(() => {
    navigate('/login');
  }, []);

  return (
    <div className="flex flex-col h-full min-h-full px-6 py-12 overflow-auto lg:px-8">
      <div className="flex items-center justify-center sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="w-auto login-logo h-[4.125rem] mr-[1.1875rem]" />
        <LogoTitle className="w-auto h-8 " />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 py-8 border shadow sm:rounded-lg sm:px-10 dark:border-gray-700">
          {!isSendEmail ? (
            <>
              <div className="mb-5">
                {t(
                  'アカウントのメールアドレス宛にパスワード変更手続きのご案内を送信します。',
                )}
              </div>
              <Alert color="gray" className="w-full mt-4 mb-5 text-xs">
                <h3 className="font-medium">
                  {t(
                    '<domain> ドメインからのメールが迷惑メールへ振り分けられないように設定してください。',
                    { domain: BASE_DOMAIN },
                  )}
                </h3>
              </Alert>
              <FormProvider {...methods}>
                <Form onSubmit={formLoginSubmit}>
                  <FormCol>
                    <Input
                      name="email"
                      label={t('メールアドレス')}
                      error={error}
                    />
                  </FormCol>
                  <div className="relative sm:col-span-6">
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="w-full border-t border-gray-100 dark:border-gray-700" />
                    </div>
                  </div>
                  <FormCol>
                    <FormButton
                      id="requestChangePassword"
                      className="sm:w-full"
                      submit
                      variant="primary"
                      disabled={isDisabledSubmit}
                    >
                      {t('送信')}
                    </FormButton>
                  </FormCol>
                  <FormCol>
                    <FormButton
                      id="returnLogin"
                      className="sm:w-full"
                      onClick={backHandler}
                    >
                      {t('戻る')}
                    </FormButton>
                  </FormCol>
                </Form>
              </FormProvider>
            </>
          ) : (
            <>
              <div className="mb-5">
                {t(
                  '<toEmail> 宛にパスワード変更手続きのご案内を送信しました。',
                  { toEmail: methods.getValues().email },
                )}
              </div>
              <FormProvider {...methods}>
                <form>
                  <FormCol>
                    <FormButton
                      id="returnLogin"
                      className="sm:w-full"
                      onClick={backHandler}
                      variant="primary"
                    >
                      {t('OK')}
                    </FormButton>
                  </FormCol>
                </form>
              </FormProvider>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
