import { IModalDialogProps } from '@/@types/ui';
import { Dialog } from '@headlessui/react';
import React, { ReactNode } from 'react';

import { classNames } from '@/libs/styleUtils';

import BaseDialog from '@/components/Common/BaseDialog';

interface IFormDialogProps extends IModalDialogProps {
  title: string;
  children: ReactNode[];
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  className?: string;
  top?: boolean;
  buttonsAlign?: 'right' | 'left';
  zIndex?: 10 | 20 | 30 | 40 | 50;
  headerContent?: JSX.Element;
}

export default function FormDialog(props: IFormDialogProps) {
  const {
    onClose,
    title,
    onSubmit,
    children,
    top,
    className,
    buttonsAlign,
    zIndex,
    headerContent,
  } = props;
  const dialogZIndex = zIndex || 40;

  return (
    <BaseDialog
      zIndex={dialogZIndex}
      className={classNames(
        className,
        'sm:max-w-xl sm:align-middle sm:w-full max-h-[calc(100vh-2rem)] pb-0 !mb-0 sm:max-h-[calc(100vh-7rem)] overflow-y-auto',
      )}
      onClose={onClose}
      top={top}
    >
      <form onSubmit={onSubmit}>
        <div className="sticky top-0 z-10 flex items-center justify-between px-4 pt-5 pb-4 bg-white/80 dark:bg-gray-800/80 sm:p-6 sm:pb-6 backdrop-blur-sm space-x-4">
          <Dialog.Title
            as="h3"
            className="flex-1 text-lg font-medium text-gray-900 truncate dark:text-gray-300 leading-6"
          >
            {title}
          </Dialog.Title>
          {headerContent && (
            <div className="flex-shrink-0">{headerContent}</div>
          )}
        </div>
        <div className="px-4 pb-4 -mt-4 sm:px-6 sm:pb-6">
          <div className="mt-5 space-y-5">{children[0]}</div>
        </div>
        <div
          className={classNames(
            'sticky z-10 bottom-0 px-4 py-3 dark:border-t bg-gray-50/80 dark:bg-gray-800/80 dark:border-gray-700 sm:px-6 flex backdrop-blur-sm',
            buttonsAlign === 'left' ? 'sm:flex-row' : 'flex-row-reverse',
          )}
        >
          {children[1]}
        </div>
      </form>
    </BaseDialog>
  );
}
