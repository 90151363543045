import { ja, enUS as en } from 'date-fns/locale';
import i18next, { t } from 'i18next';
import React, { ReactNode, useCallback, useMemo } from 'react';
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

import { useFormContext, UseFormReturn } from 'react-hook-form';

import { t2s } from '@/libs/utils';

import { ISearchFilter } from '@/hooks/useFilters';

export interface ICalContainerProps {
  children: ReactNode;
}

export interface IFilterDateProps {
  methods?: UseFormReturn<ISearchFilter, any>;
  name?: string;
  idx?: number;
  property?: string;
}

/**
 *  filter用DateInput
 * @param props
 * @returns
 */
export default function FilterDate(props: IFilterDateProps) {
  registerLocale('ja', ja);
  registerLocale('en', en);
  const { name, methods, idx, property } = props;
  const { setValue, trigger, getValues } = useFormContext<ISearchFilter>();
  const calContainer = useCallback((calProps: ICalContainerProps) => {
    const { children } = calProps;

    return (
      <CalendarContainer>
        <div className="pt-2 overflow-hidden bg-white shadow-lg max-w-min dark:bg-gray-700 rounded-md ring-1 ring-black ring-opacity-5">
          <div className="relative">
            <div className="">{children}</div>
          </div>
        </div>
      </CalendarContainer>
    );
  }, []);

  const zodName: any = useMemo(() => {
    if (name) {
      return `basicFilter.${name}`;
    }
    return `searchProperties.${idx}.${property}`;
  }, [idx, name, property]);

  const field = getValues(zodName);

  // 日付更新
  const handleChange = useCallback(
    (value: Date | null) => {
      const input = value?.getTime() ?? null;
      if (methods && name) {
        methods.setValue(zodName, input, {
          shouldDirty: true,
          shouldTouch: true,
        });
        methods.trigger(zodName);
      } else {
        setValue(zodName, input, {
          shouldDirty: true,
          shouldTouch: true,
        });
        trigger();
      }
    },
    [zodName],
  );
  return (
    <div className="w-full h-full">
      <DatePicker
        closeOnScroll={(e) => e.target === document}
        locale={i18next.resolvedLanguage}
        dateFormat={t2s(t('yyyy-MM-dd'))}
        selected={field || undefined}
        onChange={handleChange}
        calendarContainer={calContainer}
        isClearable
        highlightDates={[new Date()]}
        className="w-full h-full px-3 py-2 text-left text-gray-700 bg-white border border-gray-300 dark:bg-gray-700 focus:outline-none rounded-md dark:border-gray-600 shadow-sm dark:text-gray-200 hover:bg-gray-50 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 min-h-[2.62rem]"
      />
    </div>
  );
}
