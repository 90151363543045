import { ILoginMode } from '@/@types/common';
import { Workspace } from '@/@types/models';

import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { LOGIN_MODE } from '@/libs/const';

import AccountRepository from '@/repositories/AccountRepository';

import { setIsLoaded } from '@/reducers/uiStateReducer';

/**
 * 招待情報取得
 * @returns { exec }
 */
export default function useInviteInfo(mode?: ILoginMode) {
  const navigate = useNavigate();

  const {
    workspaceId: invitingWorkspaceId,
    invitingMemberId,
    invitingGuestId,
  } = useParams();
  const dispatch = useDispatch();

  const accountRepository = new AccountRepository();

  const [isInviteDataLoaded, setIsInviteDataLoaded] = useState<boolean>(false);
  const [invitingWorkspaceName, setInvitingWorkspaceName] =
    useState<Workspace['workspaceName']>();

  // 画面レンダリング時に一度だけ実行
  useEffect(() => {
    // 画面初期化時に必要な処理
    dispatch(setIsLoaded(true));

    const fn = async () => {
      try {
        if (mode === LOGIN_MODE.INVITING_MEMBER) {
          const res = await accountRepository.checkInvitingMemberExistence(
            invitingWorkspaceId as string,
            invitingMemberId as string,
          );
          setInvitingWorkspaceName(res.workspaceName);
        } else if (mode === LOGIN_MODE.INVITING_GUEST) {
          const res = await accountRepository.checkInvitingGuestExistence(
            invitingWorkspaceId as string,
            invitingGuestId as string,
          );
          setInvitingWorkspaceName(res.workspaceName);
        }
        setIsInviteDataLoaded(true);
        // eslint-disable-next-line no-shadow
      } catch (error: any) {
        if (error.code === 'failed-precondition') {
          navigate('/expired');
        } else {
          navigate('/404');
        }
      }
    };

    fn();
  }, []);

  const loginUrl = useMemo(() => {
    if (mode === LOGIN_MODE.INVITING_MEMBER) {
      return `/login/im/${invitingWorkspaceId}/${invitingMemberId}`;
    }
    if (mode === LOGIN_MODE.INVITING_GUEST) {
      return `/login/ig/${invitingWorkspaceId}/${invitingGuestId}`;
    }
    return '/login';
  }, [mode, invitingWorkspaceId, invitingMemberId, invitingGuestId]);

  const signupUrl = useMemo(() => {
    if (mode === LOGIN_MODE.INVITING_MEMBER) {
      return `/signup/im/${invitingWorkspaceId}/${invitingMemberId}`;
    }
    if (mode === LOGIN_MODE.INVITING_GUEST) {
      return `/signup/ig/${invitingWorkspaceId}/${invitingGuestId}`;
    }
    return '/signup';
  }, [mode, invitingWorkspaceId, invitingMemberId, invitingGuestId]);
  return {
    isInviteDataLoaded,
    invitingWorkspaceName,
    loginUrl,
    signupUrl,
  };
}
