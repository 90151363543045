import { RootState, LikedItem } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { COL_LIKED_ITEMS, getLikedItemsPath } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useProjects from '@/hooks/useProjects';

/**
 * useLikedItemsのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useLikedItems() {
  const { currentItemId } = useSelector((state: RootState) => state.items);
  const { currentMyProject, myProjects } = useProjects();
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // アイテムID切替ごとにストアが洗い替えされないためprojectId毎にサブスクリプションしている
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => {
      const query: ReduxFirestoreQuerySetting = {
        collection: getLikedItemsPath(userId, workspaceId, p.id as string),
        storeAs: `${COL_LIKED_ITEMS}/${p.id}`,
      };
      return query;
    });
  });

  const ordered = useSelector((state: any) => state.firestore.ordered);
  const likedItems = useMemo(() => {
    if (!currentMyProject?.id) return [];
    return (ordered[`${COL_LIKED_ITEMS}/${currentMyProject.id}`] ||
      []) as LikedItem[];
  }, [ordered, currentMyProject]);

  const isCurrentItemLiked = useMemo(
    () => likedItems.find((i) => i.id === currentItemId) !== undefined,
    [likedItems, currentItemId],
  );

  return {
    likedItems,
    currentItemId,
    isCurrentItemLiked,
  };
}
