import { View, MyProject } from '@/@types/models';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  EllipsisVerticalIcon,
  MinusCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { UsersIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';

import RelativePortal from 'react-relative-portal';
import { Link, NavLink, useParams } from 'react-router-dom';

import { PROJECT_CREATION_LIMIT, PLANS } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import { getNavigatePath } from '@/libs/utils';

import ConfirmDialog from '@/components/Common/ConfirmDialog';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';
import ProjectDropdown from '@/components/GlobalNavigation/ProjectDropdown';
import NewProjectDialog from '@/components/Project/NewProjectDialog';

import EditViewNameDialog from '@/components/View/EditViewNameDialog';

import useJoinType from '@/hooks/useJoinType';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePlanProtections from '@/hooks/usePlanProtections';
import usePlans from '@/hooks/usePlans';
import useProjects from '@/hooks/useProjects';
import useSlackIntegrations from '@/hooks/useSlackIntegrations';
import useViews from '@/hooks/useViews';

interface ProjectAndViews {
  myProject: MyProject;
  views: View[];
}

/**
 * カテゴリ ナビゲーション[]
 * @returns
 */
export default function ProjectNavigation() {
  const { t } = useTranslation();
  useProjects();
  // Slack連携設定
  useSlackIntegrations();

  const { currentMyWorkspace } = useMyWorkspaces();
  const { workspaceId: urlWorkspaceId } = useParams();
  const { isMember } = useMyWorkspaces();
  const { isPossibleCreationProject, maxProjectCreationCount, plan } =
    usePlans();
  const { projectsByJoinType: myProjects, viewsByJoinType } = useJoinType();
  const { isShowDowngradeBanner } = usePlanProtections();
  const { viewId: currentViewId } = useParams();

  const { currentMyProject } = useProjects();
  const { currentView, deleteView } = useViews();
  const { projectsByJoinType } = useJoinType();
  const projects = useMemo(() => projectsByJoinType, [projectsByJoinType]);

  const currentMyProjectData = useMemo(
    () => projects.find((p) => p.id === currentMyProject?.id) || null,
    [projects, currentMyProject?.id],
  );

  const views = useMemo(() => viewsByJoinType, [viewsByJoinType]);

  // カレントプロジェクトのviews
  const currentProjectViews = useMemo(
    () => views.filter((v) => v._projectId === currentMyProjectData?.id),
    [views, currentMyProjectData?.id],
  );

  // ビュー削除可否のフラグ
  const canDeleteView = useMemo(
    () => currentProjectViews.length > 1,
    [currentProjectViews],
  );

  const [newDialogOpen, setNewDialogOpen] = useState<boolean>(false);
  const [editViewNameDialogOpen, setEditViewNameDialogOpen] =
    useState<boolean>(false);
  const [deleteViewDialogOpen, setDeleteViewDialogOpen] =
    useState<boolean>(false);

  // storeに保持されたプロジェクトリストとビューリストをマージする
  const myProjectList: ProjectAndViews[] = useMemo(
    () =>
      myProjects.map((myProject) => {
        const { projectId } = myProject;
        if (!projectId) return { myProject, views: [] };
        return {
          myProject,
          views: views.filter((view) => view._projectId === projectId),
        };
      }),
    [myProjects, views],
  );

  // Disclosureオープン用 {workspaceId}/{projectId}が一致しているかの判定処理
  const { workspaceId, projectId } = useParams();
  const urlToProject = useMemo(
    () => getNavigatePath(urlWorkspaceId as string, projectId),
    [workspaceId, projectId],
  );
  const isMatchUrl = (url: string) => url === urlToProject;

  // ビュー削除確認ダイアログ
  const handleDeleteViewConfirm = useCallback(
    async (deleteFlg: boolean | undefined) => {
      if (deleteFlg && currentView && currentMyProjectData) {
        await deleteView(currentView?.id, currentMyProjectData?.id);
      }
      setDeleteViewDialogOpen(false);
    },
    [setDeleteViewDialogOpen, currentView, currentMyProjectData],
  );

  return (
    <>
      <h3
        className="pl-3 mb-2 text-sm font-semibold tracking-wider text-gray-400 uppercase dark:text-gray-500"
        id="projects-headline"
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex-1">{t('プロジェクト')}</div>
          {isMember && (
            <div className="flex-none shrink-0">
              <MenuButton
                id="showNewProject"
                type="icon"
                onClick={() => setNewDialogOpen(true)}
              >
                <PlusIcon className="w-4 h-4" aria-hidden="true" />
              </MenuButton>
            </div>
          )}
        </div>
      </h3>

      {/* ※ ネストあり三項演算子 */}
      {/* eslint-disable no-nested-ternary */}
      {currentMyWorkspace && myProjectList ? (
        myProjectList.map((p: ProjectAndViews, index) => (
          <Disclosure
            as="div"
            key={p.myProject.id}
            defaultOpen={isMatchUrl(
              getNavigatePath(
                currentMyWorkspace.originalWorkspaceId!,
                p.myProject.projectId,
              ),
            )}
            className="space-y-1"
          >
            {({ open }) => (
              <>
                <div className="flex items-center w-full py-2 group">
                  <Disclosure.Button
                    className={classNames(
                      'shrink-0 text-left font-medium rounded-md focus:outline-none hover:bg-gray-200 dark:hover:bg-gray-700 mr-2 relative',
                    )}
                  >
                    <svg
                      className={classNames(
                        open ? 'text-gray-400 rotate-90' : 'text-gray-400',
                        'flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
                      )}
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                    </svg>
                  </Disclosure.Button>
                  <div className="relative mr-2 grow">
                    {/** 削除予約アイコン表示 */}
                    {isShowDowngradeBanner &&
                      index >= PROJECT_CREATION_LIMIT.FREE && (
                        <MinusCircleIcon className="absolute inline-block w-4 h-4 m-0 mx-1 text-white align-text-bottom bg-red-400 rounded-full dark:bg-red-700/80 -top-2 -left-4 dark:ring-offset-red-700/80 ring-offset-red-400 ring-offset-1" />
                      )}
                    {/** プロジェクト名リンク */}
                    <div className="flex items-center justify-between w-44 space-x-1">
                      <Link
                        to={getNavigatePath(
                          currentMyWorkspace.originalWorkspaceId!,
                          p.myProject.projectId,
                          p.views.length > 0 ? p.views[0].id : undefined,
                        )}
                        className={classNames(
                          'block truncate hover:text-gray-500 dark:hover:text-gray-300 w-full',
                          !p.myProject.isAccessed ? 'font-bold' : '',
                        )}
                      >
                        {p.myProject.projectName}
                      </Link>
                      {p.myProject.isShare && (
                        <UsersIcon
                          className="flex-shrink-0 w-3 h-3 text-gray-400 dark:text-gray-500"
                          data-tip="共有されています"
                        />
                      )}
                    </div>
                  </div>
                  <ProjectDropdown
                    className="flex-none shrink-0"
                    myProject={p.myProject}
                  />
                </div>
                <Disclosure.Panel className="space-y-1">
                  {p.views.map((view: View) => (
                    <NavLink
                      end
                      key={`${p.myProject.projectId}-${view.id}`}
                      to={getNavigatePath(
                        currentMyWorkspace.originalWorkspaceId!,
                        p.myProject.projectId,
                        view.id,
                      )}
                      className={({ isActive }) =>
                        classNames(
                          'flex items-center w-full h-9 pl-10 font-medium group rounded-md',
                          isActive
                            ? 'bg-primary-500 text-white dark:bg-primary-600 dark:text-white'
                            : 'text-gray-700 dark:text-gray-300 hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white',
                        )
                      }
                    >
                      <span className="flex-1 truncate">{view.viewName}</span>
                      {isMember && currentViewId === view.id && (
                        <div className="shrink-0">
                          <Menu
                            as="div"
                            className={classNames(
                              'relative inline-block text-left ml-1',
                            )}
                          >
                            {({ open: viewMenuOpen }) => (
                              <>
                                <div>
                                  <Menu.Button
                                    as="div"
                                    className={classNames(
                                      'flex items-center p-1 text-gray-50 rounded-md hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500',
                                    )}
                                  >
                                    <EllipsisVerticalIcon
                                      className="w-4 h-4"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <RelativePortal component="div" right={0}>
                                  <PopupTransition show={viewMenuOpen}>
                                    <Menu.Items
                                      static
                                      className="absolute right-0 z-20 w-40 mt-2 text-gray-800 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 dark:divide-gray-600 dark:text-gray-100"
                                    >
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <button
                                              type="button"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setEditViewNameDialogOpen(true);
                                              }}
                                              className={classNames(
                                                active
                                                  ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                                  : '',
                                                'w-full px-4 py-2 text-left flex items-center justify-start',
                                              )}
                                            >
                                              {t('ビュー名を変更')}
                                            </button>
                                          )}
                                        </Menu.Item>
                                        {canDeleteView && (
                                          <Menu.Item>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  // ビューが削除可能の場合はダイアログを表示する
                                                  setDeleteViewDialogOpen(
                                                    canDeleteView,
                                                  );
                                                }}
                                                className={classNames(
                                                  active
                                                    ? 'bg-red-400 dark:bg-red-500 text-white dark:text-white'
                                                    : '',
                                                  'w-full block px-4 py-2 text-left',
                                                )}
                                              >
                                                {t('ビューを削除')}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </PopupTransition>
                                </RelativePortal>
                              </>
                            )}
                          </Menu>
                        </div>
                      )}
                    </NavLink>
                  ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))
      ) : myProjects.length > 0 ? (
        <div className="flex-1 min-w-0">
          <p className="flex items-center px-3 mr-0">
            <Skeleton className="!w-40" />
          </p>
        </div>
      ) : (
        <div />
      )}
      {currentMyWorkspace && (
        <div>
          {isPossibleCreationProject ? (
            <Transition.Root show={newDialogOpen} unmount>
              <NewProjectDialog onClose={() => setNewDialogOpen(false)} />
            </Transition.Root>
          ) : (
            <ConfirmDialog
              key="new-project-limit"
              open={newDialogOpen}
              onClose={() => setNewDialogOpen(false)}
              title={t('作成できるプロジェクト数の上限に達しています')}
              positive={t('OK')}
              warning
              positiveOnly
            >
              <p className="mb-2">
                {t(
                  '現在のプランで作成できるプロジェクト数の上限は <num> です。',
                  {
                    num: maxProjectCreationCount,
                  },
                )}
              </p>
              {plan?.type === PLANS.FREE && (
                <p>
                  {t(
                    'より多くのプロジェクトを作成したい場合はプランを <upgradeLink> してください。',
                    {
                      upgradeLink: (
                        <Link
                          to={`/${urlWorkspaceId}/settings/plans`}
                          className="dark:text-primary-500 dark:hover:text-primary-600"
                        >
                          {t('アップグレード')}
                        </Link>
                      ),
                    },
                  )}
                </p>
              )}

              {plan?.type === PLANS.STANDARD && (
                <p>
                  {t(
                    '新たにプロジェクトを作成したい場合は、不要なプロジェクトを削除した上で再度お試しください。',
                  )}
                </p>
              )}
            </ConfirmDialog>
          )}
        </div>
      )}
      {currentMyProjectData && currentView && (
        <Transition.Root show={editViewNameDialogOpen} unmount>
          <EditViewNameDialog
            project={currentMyProjectData}
            view={currentView as View}
            onClose={() => setEditViewNameDialogOpen(false)}
          />
        </Transition.Root>
      )}
      <ConfirmDialog
        open={deleteViewDialogOpen}
        onClose={handleDeleteViewConfirm}
        title={t('ビューを削除')}
        positive={t('削除')}
        warning
      >
        <p className="mb-2">
          {t('<name> を削除しようとしています。', {
            name: (
              <span className="mr-1 font-bold">{currentView?.viewName}</span>
            ),
          })}
        </p>
        <p>
          {t('削除されたビューは復元できません。<br> 本当によろしいですか？', {
            br: <br />,
          })}
        </p>
      </ConfirmDialog>
    </>
  );
}
