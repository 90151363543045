import '@/App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { IJoinType, RootState } from '@/@types/models';
import React, { useEffect, useMemo, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { getNavigatePath } from '@/libs/utils';

import GoogleTagManager from '@/components/Common/GoogleTagManager';
import Loading from '@/components/Common/Loading';

import UpdateConfirmDialog from '@/components/Common/UpdateConfirmDialog';
import SelectPublishItemsContext from '@/components/Share/SelectPublishItemsContext';

import Error403 from '@/pages/errors/Error403';
import Error404 from '@/pages/errors/Error404';
import Error500 from '@/pages/errors/Error500';
import ErrorExpired from '@/pages/errors/ErrorExpired';

import UpdateInProgress from '@/pages/errors/UpdateInProgress';
import GetStarted from '@/pages/GetStarted';
import SlackCallback from '@/pages/integrations/SlackCallback';
import Login from '@/pages/Login';
import PasswordReset from '@/pages/Login/PasswordReset';
import { CancelCheckout, Checkout } from '@/pages/payment/Checkout';
import ProjectIndex from '@/pages/ProjectIndex';
import Account from '@/pages/settings/Account';
import Guests from '@/pages/settings/Guests';
import Members from '@/pages/settings/Members';
import Plans from '@/pages/settings/Plans';
import Workspace from '@/pages/settings/Workspace';
import SettingsIndex from '@/pages/SettingsIndex';
import SignUp from '@/pages/SignUp';
import ViewIndex from '@/pages/ViewIndex';
import Wait from '@/pages/Wait';
import WorkspaceIndex from '@/pages/WorkspaceIndex';

import useAdmin from '@/hooks/useAdmin';
import useFirebaseAuth from '@/hooks/useFirebaseAuth';
import useLastAccess from '@/hooks/useLastAccess';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useToggleTheme from '@/hooks/useToggleTheme';
import Index from '@/pages';

import errorReporting from './errorReporting';
// import Guests from './pages/settings/Guests';

// ルーティング定義用オブジェクトinterface
type RouteType = RouteObject & {
  auths: IJoinType[];
  children?: RouteType[];
};

// ログイン・認証を含めたルーティング制御
function useRouteGuard() {
  const { isAuthenticated, isAnonymous } = useFirebaseAuth();
  const { currentMyWorkspace, isMember, isGuest } = useMyWorkspaces();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);

  // エラーレポートにUserIDをセット
  useEffect(() => {
    let authType = 'U';
    if (rrfAuth.isAnonymous) authType = 'A';
    else if (isMember) authType = 'M';
    else if (isGuest) authType = 'G';
    const userId = rrfAuth.uid ? `${rrfAuth.uid} / ${authType}` : undefined;
    errorReporting.setUser(userId);
  }, [rrfAuth.uid, isMember, isGuest]);

  // ワークスペース権限
  const joinType = useMemo(
    () => currentMyWorkspace?.joinType,
    [currentMyWorkspace?.joinType],
  );

  // ルーティング用の定義を認証情報をもとに成形
  // TODO: ルーティング制御でわかりづらくなってしまうので :workspaceId -> :originalWorkspaceIdに変更したほうが良い
  const routes = useMemo<RouteType[]>(() => {
    // 認証済み/未認証共通のルート
    const commonRouteList: RouteType[] = [
      {
        path: '/login',
        auths: [],
        element: isAuthenticated ? <Navigate to="/" /> : <Login />,
        index: false,
      },
      {
        path: '/signup',
        auths: [],
        element: isAuthenticated ? <Navigate to="/" /> : <SignUp />,
        index: false,
      },
      {
        path: '/login/passwordReset',
        auths: [],
        element: <PasswordReset />,
        index: false,
      },
      {
        path: '/wait',
        auths: [],
        element: <Wait />,
        index: false,
      },
      {
        path: '/signup/im/:workspaceId/:invitingMemberId',
        auths: [],
        element: <SignUp mode="invitingMember" />,
        index: false,
      },
      {
        path: '/signup/ig/:workspaceId/:invitingGuestId',
        auths: [],
        element: <SignUp mode="invitingGuest" />,
        index: false,
      },
      {
        path: '/login/im/:workspaceId/:invitingMemberId',
        auths: [],
        element: <Login mode="invitingMember" />,
        index: false,
      },
      {
        path: '/login/ig/:workspaceId/:invitingGuestId',
        auths: [],
        element: <Login mode="invitingGuest" />,
        index: false,
      },
      // プロジェクト/ビュールートは非認証ユーザーのハンドリングをコンポーネント内部で実施
      // 認証/非認証いずれの状態でも踏めるルートなので ログアウト時のリダイレクト処理もコンポーネント内で実施
      // プロジェクトルート
      {
        path: getNavigatePath(':workspaceId', ':projectId'),
        element: <ProjectIndex />,
        auths: [],
        index: false,
      },
      // ビュールート
      {
        path: getNavigatePath(':workspaceId', ':projectId', ':viewId'),
        element: <ViewIndex />,
        auths: [],
        index: false,
      },
      // アイテムルート
      {
        path: getNavigatePath(
          ':workspaceId',
          ':projectId',
          ':viewId',
          ':itemId',
        ),
        element: <ViewIndex />,
        auths: [],
        index: false,
      },
      {
        path: '*',
        element: isAuthenticated ? (
          <Navigate to="/404" />
        ) : (
          <Navigate to="/login" />
        ),
        auths: [],
        index: false,
      },
      { path: '/403', element: <Error403 />, auths: [], index: false },
      { path: '/404', element: <Error404 />, auths: [], index: false },
      { path: '/500', element: <Error500 />, auths: [], index: false },
      { path: '/expired', element: <ErrorExpired />, auths: [], index: false },
    ];

    // 未認証(または匿名認証)の場合のルート
    const unAuthorizedRouteList: RouteType[] = [
      {
        path: '/',
        element: <SignUp />,
        auths: [],
        index: false,
      },
      {
        path: '/login',
        element: <Login />,
        auths: [],
        index: false,
      },
      ...commonRouteList,
    ];

    // 認証済みの場合のルート
    const routeList: RouteType[] = [
      {
        path: '/get_started',
        auths: [],
        element: <GetStarted />,
        index: false,
      },

      {
        path: '/',
        element: <Index />,
        auths: [],
        index: false,
      },

      // ワークスペースルート
      {
        path: getNavigatePath(':workspaceId'),
        element: <WorkspaceIndex />,
        auths: [],
        index: false,
      },

      // 設定画面ルート
      {
        path: '/:workspaceId/settings',
        element: <SettingsIndex />,
        auths: [],
        index: false,
        children: [
          {
            index: true,
            element: <Account />,
            auths: ['member', 'guest'],
          },
          {
            path: 'workspace',
            element: <Workspace />,
            auths: ['member', 'guest'],
            index: false,
          },
          {
            path: 'members',
            element: <Members />,
            auths: ['member'],
            index: false,
          },
          {
            path: 'plans',
            element: <Plans />,
            auths: ['member'],
            index: false,
          },
          { path: 'members', element: <Members />, auths: ['member'] },
          { path: 'plans', element: <Plans />, auths: ['member'] },
          { path: 'guests', element: <Guests />, auths: ['member'] },
        ],
      },

      // 決済成功ルート
      {
        path: `/:workspaceId/${import.meta.env.VITE_CHECKOUT_CODE}`,
        element: <Checkout />,
        auths: [],
        index: false,
      },
      // 決済キャンセルルート
      {
        path: `/:workspaceId/${import.meta.env.VITE_CANCEL_CHECKOUT_CODE}`,
        element: <CancelCheckout />,
        auths: [],
        index: false,
      },
      // Slack OAuth Callback
      {
        path: `/:workspaceId/:projectId/slack/callback`,
        element: <SlackCallback />,
        auths: [],
      },

      ...commonRouteList,
    ];

    // 定義されたルーティング構造をパーミッションに合わせて再帰的にマッピングする
    // 非アクセス許可ルートは404へのリダイレクションに変更
    const recMap = (ary: RouteType[]): RouteType[] =>
      ary.map((route) => {
        const hasAuth: boolean = (() => {
          if (route.auths.length) {
            return joinType ? route.auths.includes(joinType) : false;
          }
          // authの要素0はフルアクセス
          return true;
        })();
        let children: RouteType[] | undefined = [];
        if (route.children) {
          children = recMap(route.children);
        }
        // 正しいjoinTypeが取得できるまではloading表示
        const unknownPage = joinType ? (
          <Navigate to="/404" replace />
        ) : (
          <Loading className="w-full h-screen" />
        );

        return route.index
          ? {
              ...route,
              children: undefined,
              element: hasAuth ? route.element : unknownPage,
            }
          : {
              ...route,
              children,
              element: hasAuth ? route.element : unknownPage,
            };
      });

    return recMap(
      !isAuthenticated || isAnonymous ? unAuthorizedRouteList : routeList,
    );
  }, [isAuthenticated, isAnonymous, joinType]);

  return useRoutes(routes);
}

function App() {
  useToggleTheme();
  useLastAccess();

  const routeElement = useRouteGuard();
  const { isLoaded } = useSelector((state: RootState) => state.uiState);

  const [isUpdateInProgress, setIsUpdateInProgress] = useState<boolean>(false);

  const { versionControl } = useAdmin();
  useEffect(() => {
    if (versionControl?.workCompletionDate === undefined) return;
    setIsUpdateInProgress(
      !!versionControl && versionControl.workCompletionDate !== null,
    );
  }, [versionControl]);

  /**
   * Toastify Common settings.
   */
  toast.configure({
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    closeButton: false,
  });

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);

  // アップデート中
  if (isUpdateInProgress) {
    return (
      <UpdateInProgress
        workCompletionDate={versionControl?.workCompletionDate?.toDate()}
      />
    );
  }

  if (!rrfAuth.isLoaded) {
    return <Loading className="w-screen h-screen" />;
  }

  return (
    <HelmetProvider>
      <GoogleReCaptchaProvider
        reCaptchaKey={import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY}
      >
        {!isLoaded && <Loading className="w-screen h-screen" />}
        <DndProvider backend={HTML5Backend}>
          <SelectPublishItemsContext>
            {routeElement}
            <UpdateConfirmDialog />
            <ReactTooltip
              delayShow={500}
              className="max-w-xs break-all !px-3 !py-1 md:max-w-sm"
              effect="solid"
              backgroundColor="#4b5563"
              multiline
            />
            <GoogleTagManager />
          </SelectPublishItemsContext>
        </DndProvider>
      </GoogleReCaptchaProvider>
    </HelmetProvider>
  );
}

export default App;
