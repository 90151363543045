import { IPropertyFormatType, IPropertyOptionType } from '@/@types/models';
import { IProperty } from '@/@types/viewItem';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import {
  VALIDATION_MODE,
  PROPERTY_TYPE,
  NUMBER_PROPERTY_FORMAT_TYPE,
} from '@/libs/const';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import MultiSelectForm from '@/components/Items/PropertyEditForms/MultiSelectForm';
import NumberForm from '@/components/Items/PropertyEditForms/NumberForm';
import SingleSelectForm from '@/components/Items/PropertyEditForms/SingleSelectForm';
import StatusForm from '@/components/Items/PropertyEditForms/StatusForm';

import useSubmitState from '@/hooks/useSubmitState';

interface IPropertyEditDialogProps {
  zIndex: 10 | 20 | 30 | 40 | 50;
  property: IProperty;
  onEditProperty: (newProperty: IProperty) => void;
  onClose: () => void;
}

export default function PropertyEditDialog(props: IPropertyEditDialogProps) {
  const { t } = useTranslation();
  const { property, onEditProperty, onClose } = props;
  const [mounted, setMounted] = useState(false);

  // デフォルト値
  const defaultValues = {
    propertyTypeOptions: [] as IPropertyOptionType[],
    propertyTypeFormat: null as IPropertyFormatType | null,
  };

  // Zod schema定義
  const schema = z.object({
    propertyTypeOptions: z.array(
      z.object({
        id: z.string(),
        text: z
          .string()
          .trim()
          .min(1, { message: t('入力してください') }),
        color: z
          .string()
          .trim()
          .min(1, { message: t('入力してください') }),
      }),
    ),
    propertyTypeFormat: z.nativeEnum(NUMBER_PROPERTY_FORMAT_TYPE).nullable(),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues,
  });
  // 送信時処理
  const formSubmit = methods.handleSubmit((data) => {
    // 選択肢が削除されていた場合は空文字をセット
    // TODO: それぞれのデータ型に合わせたチェックが必要
    const pos = data.propertyTypeOptions;
    const stringValue =
      pos.length > 0
        ? pos.find((op) => op.id === property.stringValue)?.id || ''
        : '';
    const saveOptions = [...data.propertyTypeOptions];
    const saveFormat =
      property.propertyType === PROPERTY_TYPE.NUMBER
        ? data.propertyTypeFormat || NUMBER_PROPERTY_FORMAT_TYPE.FLOAT
        : null;
    const newProp = {
      ...property,
      stringValue,
      options: saveOptions,
      format: saveFormat,
    };
    onEditProperty(newProp);
    onClose();
  });

  // キャンセル押下時ハンドラ
  const onCancelClick = () => {
    onClose();
  };

  // マウント時処理
  useEffect(() => {
    // HACK: セレクト選択肢のカラーピッカーが潜り込む事象の解消
    // 親コンポ―ネントのマウントおよびzodの初期化が完了していない時点で子コンポーネントがマウントされるのが原因と思われる
    setTimeout(() => {
      methods.reset({
        propertyTypeOptions: _.cloneDeep(property.options),
        propertyTypeFormat: property.format,
      });
    });
    setMounted(true);
    return () => {
      methods.reset(defaultValues);
    };
  }, []);

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <FormDialog
      onClose={() => {}}
      title={t('<propertyName>を編集', { propertyName: property.propertyName })}
      onSubmit={formSubmit}
      top
      className="!max-w-2xl"
    >
      <FormProvider {...methods}>
        {/* SINGLE_SELECTの場合 */}
        {mounted && property.propertyType === PROPERTY_TYPE.SINGLE_SELECT && (
          <SingleSelectForm />
        )}
        {/* MULTI_SELECTの場合 */}
        {mounted && property.propertyType === PROPERTY_TYPE.MULTI_SELECT && (
          <MultiSelectForm />
        )}
        {/* NUMBERの場合 */}
        {mounted && property.propertyType === PROPERTY_TYPE.NUMBER && (
          <NumberForm />
        )}
        {/* STATUSの場合 */}
        {mounted && property.propertyType === PROPERTY_TYPE.STATUS && (
          <StatusForm />
        )}
      </FormProvider>
      <>
        <FormButton
          id="applyPropertyOption"
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
          disabled={isDisabledApply}
          onClick={formSubmit}
        >
          {t('適用')}
        </FormButton>
        <FormButton
          id="cancelPropertyOption"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={onCancelClick}
        >
          {t('キャンセル')}
        </FormButton>
      </>
    </FormDialog>
  );
}
