import { ActionType } from '@/@types/common';
import { MyWorkspace } from '@/@types/models';

import actions from '@/libs/actions';

type Types = Pick<typeof actions, 'SET_STATIC_MY_WORKSPACES'>;

type Action = ActionType<
  Types,
  { SET_STATIC_MY_WORKSPACES: { payload: MyWorkspace[] } }
>;

export const staticMyWorkspacesReducer = (
  // eslint-disable-next-line default-param-last
  state: MyWorkspace[] | null = [],
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_STATIC_MY_WORKSPACES:
      // eslint-disable-next-line no-param-reassign
      state = [...action.payload];
      break;
    default:
  }
  return state;
};

export const setStaticMyWorkspacesAction = (
  wss: MyWorkspace[] | null | undefined,
) => ({
  type: actions.SET_STATIC_MY_WORKSPACES,
  payload: wss,
});

export default {};
