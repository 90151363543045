import { ProjectDescription, RootState } from '@/@types/models';
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_PROJECT_DESCRIPTIONS,
  getProjectDescriptionsPath,
} from '@/libs/docPathUtils';

import { getFullSystemFields, getUpdateSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * ProjectDescriptionsの集約に関するHooks
 * @returns { projectDescription, updateDescription, isEmptyDescription }
 */
export default function useProjectDescriptions() {
  const { currentMyProject, myProjects } = useProjects();
  const { currentMyWorkspace, isMember } = useMyWorkspaces();

  const rrfFirestore = useFirestore();
  const { t } = useTranslation();
  const { exec } = useHandleApi();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  useFirestoreConnect(() => {
    if (!workspaceId || myProjects.length === 0) return [];

    return myProjects.map((p) => ({
      collection: getProjectDescriptionsPath(workspaceId, p.id),
      doc: p.id,
      storeAs: `${COL_PROJECT_DESCRIPTIONS}/${p.id}`,
    }));
  });

  const ordered = useSelector((state: any) => state.firestore.ordered);

  const projectDescriptions = useMemo<ProjectDescription[]>(() => {
    if (!currentMyProject?.id) return [];
    const pDescriptions =
      ordered[`${COL_PROJECT_DESCRIPTIONS}/${currentMyProject?.id}`];
    return pDescriptions;
  }, [ordered, currentMyProject?.id]);

  const projectDescription = useMemo(
    () =>
      projectDescriptions && projectDescriptions.length > 0
        ? projectDescriptions[0]
        : null,
    [projectDescriptions, projectId],
  );

  /**
   * プロジェクト説明の更新
   * @param editorState
   * @param plainText
   */
  const updateDescription = useCallback(
    async (
      editorState: ProjectDescription['editorState'],
      plainText: ProjectDescription['plainText'],
    ) => {
      exec(async () => {
        if (!workspaceId || !projectId || !userId || !isMember)
          throw new Error('updateProjectDescriptions error');
        if (projectDescriptions.length > 0) {
          rrfFirestore.update<ProjectDescription>(
            `${getProjectDescriptionsPath(
              workspaceId,
              projectId,
            )}/${projectId}`,
            {
              editorState,
              plainText,
              ...getUpdateSystemFields(userId),
            },
          );
        } else {
          rrfFirestore.set<ProjectDescription>(
            `${getProjectDescriptionsPath(
              workspaceId,
              projectId,
            )}/${projectId}`,
            {
              editorState,
              plainText,
              ...getFullSystemFields(userId),
            },
          );
        }
      }, t('プロジェクト説明の更新に失敗しました。'));
    },
    [workspaceId, projectId, userId, isMember, projectDescriptions],
  );

  /**
   * 説明が未記入
   */
  const isEmptyDescription = useMemo(
    () =>
      !projectDescription ||
      !projectDescription.plainText ||
      projectDescription?.plainText.trim().length === 0,
    [projectDescription],
  );

  return {
    projectDescription,
    updateDescription,
    isEmptyDescription,
  };
}
