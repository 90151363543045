import { Item } from '@/@types/models';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { VALIDATION_MODE } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import {
  isSelectPublishItemModeContext,
  itemContext,
  setIsSelectPublishItemModeContext,
} from '@/components/Share/SelectPublishItemsContext';

import useItems from '@/hooks/useItems';
import useViewItems from '@/hooks/useViewItems';

/**
 * 公開アイテム選択モード コントロール部
 * @param props
 * @returns
 */
export default function SelectPublishItemsControl() {
  const { bulkUpdateItemIsShare } = useItems();
  const { t } = useTranslation();
  const setIsSelectPublishItemMode = useContext(
    setIsSelectPublishItemModeContext,
  );

  const {
    checkedItemIds,
    setContextItems,
    setCheckedItemIds,
    checkAllItem,
    contextItems,
  } = useContext(itemContext);
  const { viewItems } = useViewItems();

  const [initialChecked, setInitialChecked] = useState<boolean>(false);

  // Zod Form
  const methods = useForm({
    // resolver: zodResolver(),
    mode: VALIDATION_MODE,
  });

  const currentShareItems = useMemo(
    () =>
      viewItems
        .filter((i) => i.isShare === true)
        .map((i) => i.id)
        .filter((id): id is string => typeof id === 'string'),
    [viewItems],
  );

  // 初期化処理
  useEffect(() => {
    setContextItems(() => [...viewItems]);
    setCheckedItemIds([...currentShareItems]);
    setInitialChecked(true);
  }, []);

  // アイテム選択モード中にアイテムが追加された場合は、チェック状態の配列を更新する。
  useEffect(() => {
    setContextItems(() => [...viewItems]);
    if (currentShareItems.length !== checkedItemIds.length && initialChecked) {
      const difference = currentShareItems.filter(
        (v) => !checkedItemIds.includes(v),
      );
      if (difference[0]) {
        setCheckedItemIds((old) => [...old, difference[0]]);
      }
    }
  }, [currentShareItems, initialChecked]);

  // Submitボタン状態 配列比較
  const isDisabledApply = useMemo(
    () => isEqual(currentShareItems.sort(), checkedItemIds.sort()),
    [initialChecked, checkedItemIds],
  );

  const handleSubmit = () => {
    const updateValue: { id: string; isShare: boolean; title: string }[] = [];
    contextItems.forEach((v: Item) => {
      if (!v.id) return; // 差分があるアイテムだけ更新
      // エラー表示用にタイトルも渡す
      if (v.isShare !== checkedItemIds.includes(v.id) && v.title) {
        const value = {
          id: v.id,
          isShare: checkedItemIds.includes(v.id),
          title: v.title,
        };
        updateValue.push(value);
      }
    });

    bulkUpdateItemIsShare(updateValue);
    setIsSelectPublishItemMode(() => false);
  };

  return (
    <>
      {/** 公開アイテム選択モード表示ヘッダ */}
      <div className="fixed top-0 left-0 right-0 z-10 flex flex-col justify-center w-full">
        <div className="w-full px-4 py-2 ml-auto mr-auto font-bold text-center text-white shadow-md bg-slate-500 dark:text-slate-300 dark:bg-slate-600 sm:w-1/2 md:w-2/5 lg:w-1/4 rounded-0 sm:rounded-b-md">
          {t('公開アイテム選択モード')}
          <div className="flex flex-wrap mt-3 sm:content-between sm:grid sm:grid-cols-2 sm:gap-2">
            <FormProvider {...methods}>
              <FormButton
                id="selectAllItems"
                className="text-xs sm:w-auto sm:mt-0"
                onClick={() => {
                  checkAllItem(true);
                }}
              >
                {t('全てのアイテムを選択')}
              </FormButton>
            </FormProvider>
            <FormProvider {...methods}>
              <FormButton
                id="unSelectAllItems"
                className="mt-2 text-xs sm:w-auto sm:mt-0"
                onClick={() => {
                  checkAllItem(false);
                }}
              >
                {t('全てのアイテムを解除')}
              </FormButton>
            </FormProvider>
          </div>
        </div>
      </div>
      {/** ボトムコントロール */}
      <div className="absolute left-0 right-0 z-10 p-4 px-4 py-3 border-t bottom-16 md:bottom-0 dark:bg-slate-600 dark:border-gray-700 dark:border-t bg-slate-500 sm:px-6 sm:flex sm:flex-row-reverse ">
        <FormProvider {...methods}>
          <FormButton
            id="applyPublishItems"
            disabled={isDisabledApply}
            variant="primary"
            className="sm:w-auto sm:ml-3"
            onClick={handleSubmit}
          >
            {t('更新')}
          </FormButton>
          <FormButton
            id="cancelPublishItems"
            className="mt-2 mr-3 sm:mr-0 sm:w-auto"
            onClick={() => {
              setIsSelectPublishItemMode(() => false);
            }}
          >
            {t('キャンセル')}
          </FormButton>
        </FormProvider>
      </div>
    </>
  );
}

/** オーバレイ背景 */
const OVERLAY_BG = 'bg-gray-500/40 dark:bg-black/50';

/**
 * グローバルナビゲーションのオーバーレイ
 * @returns
 */
export function GlobalNavigationOverlay() {
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isSelectPublishItemMode && (
        <div
          className={classNames(
            'absolute top-0 bottom-0 left-0 z-10 -right-px',
            OVERLAY_BG,
          )}
        />
      )}
    </>
  );
}
/**
 * モバイルヘッダのオーバーレイ
 * @returns
 */
export function MobileHeaderOverlay() {
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isSelectPublishItemMode && (
        <div
          className={classNames(
            'absolute top-0 bottom-0 left-0 right-0 z-10',
            OVERLAY_BG,
          )}
        />
      )}
    </>
  );
}
/**
 * ビュー領域ヘッダのオーバーレイ
 * @returns
 */
export function ViewHeaderOverlay() {
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isSelectPublishItemMode && (
        <div
          className={classNames(
            'absolute top-0 bottom-0 left-0 z-10 -right-px',
            OVERLAY_BG,
          )}
        />
      )}
    </>
  );
}
