import { Menu, Transition } from '@headlessui/react';
import { SunIcon, MoonIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from 'react-loading-skeleton';
import RelativePortal from 'react-relative-portal';
import { Link } from 'react-router-dom';

import { classNames } from '@/libs/styleUtils';

import AvatarIcon from '@/components/Common/AvatarIcon';

import ContactDialog from '@/components/Common/ContactDialog';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';

import useAccounts from '@/hooks/useAccounts';
import useFirebaseAuth from '@/hooks/useFirebaseAuth';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProfiles from '@/hooks/useProfiles';
import useToggleTheme from '@/hooks/useToggleTheme';

export interface IAccountDropdownProps {
  className?: string;
}

interface IUserNavigation {
  name: string;
  href: string;
  workspace: boolean;
}

export default function AccountDropdown(props: IAccountDropdownProps) {
  const { t } = useTranslation();
  const { isDarkTheme } = useToggleTheme();
  const { className } = props;
  const { profile } = useProfiles();
  const { account } = useAccounts();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { logOut } = useFirebaseAuth();

  const [contactDialogOpen, setContactDialogOpen] = useState<boolean>(false);

  // UIテーマ切替用カスタムフック
  const { toggleTheme } = useToggleTheme();

  const userNavigation: IUserNavigation[] = [
    { name: t('設定'), href: '/settings/', workspace: true },
  ];

  return (
    <>
      <Menu as="div" className={classNames(className, 'flex-shrink-0 flex')}>
        {({ open }) => (
          <>
            <Menu.Button className="flex-shrink-0 block w-full p-4 text-gray-500 truncate hover:text-white group hover:bg-primary-500 dark:hover:bg-primary-600 dark:text-gray-400  dark:hover:text-white">
              <div className="flex items-center">
                <div>
                  {profile ? (
                    <AvatarIcon
                      src={profile?.photoURL}
                      avatarName={profile?.displayName || account?.email}
                      className="group-hover:ring-2 ring-white"
                      size="md"
                    />
                  ) : (
                    <Skeleton circle className="!w-9 !h-9" />
                  )}
                </div>
                <div className="w-56 ml-3 sm:w-44">
                  {profile?.displayName && (
                    <p className="font-medium text-left text-gray-500 truncate dark:text-gray-300 group-hover:text-white">
                      {profile ? profile?.displayName : <Skeleton />}
                    </p>
                  )}
                  <p
                    className={classNames(
                      profile?.displayName
                        ? 'text-gray-400 dark:text-gray-400 text-sm'
                        : 'text-gray-500 dark:text-gray-300',
                      'font-medium text-left truncate group-hover:text-white',
                    )}
                  >
                    {account ? account?.email : <Skeleton />}
                  </p>
                </div>
              </div>
            </Menu.Button>
            <PopupTransition show={open}>
              <Menu.Items className="absolute w-48 py-1 mt-2 bg-white shadow-lg origin-top-right bottom-16 right-3 rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 dark:divide-gray-600">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <Link
                        to={
                          item.workspace
                            ? `/${currentMyWorkspace?.originalWorkspaceId}${item.href}`
                            : item.href
                        }
                        className={classNames(
                          active
                            ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                            : '',
                          'block px-4 py-2',
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
                <Menu.Item
                  as="a"
                  key="menu-user-help"
                  href="https://lp.collabble.jp/user/help"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                >
                  <span className="flex-1">{t('操作ガイド')}</span>
                </Menu.Item>
                <Menu.Item
                  as="button"
                  key="menu-contact"
                  className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                  onClick={() => {
                    setContactDialogOpen(true);
                  }}
                >
                  <span className="flex-1">{t('問い合わせ')}</span>
                </Menu.Item>
                <Menu.Item
                  as="button"
                  key="menu-theme"
                  className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                  onClick={toggleTheme}
                >
                  {isDarkTheme ? (
                    <>
                      <span className="flex-1">{t('ライトモード')}</span>
                      <SunIcon
                        className="w-4 h-4 text-gray-300 dark:text-gray-400 text-middle group-hover:text-white"
                        aria-hidden="true"
                      />
                    </>
                  ) : (
                    <>
                      <span className="flex-1">{t('ダークモード')}</span>
                      <MoonIcon
                        className="w-4 h-4 text-gray-300 dark:text-gray-400 text-middle group-hover:text-white"
                        aria-hidden="true"
                      />
                    </>
                  )}
                </Menu.Item>
                <Menu.Item
                  as="button"
                  key="menu-logout"
                  className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                  onClick={logOut}
                >
                  <span className="flex-1">{t('ログアウト')}</span>
                </Menu.Item>
              </Menu.Items>
            </PopupTransition>
          </>
        )}
      </Menu>
      <div>
        <RelativePortal component="div">
          <Transition.Root show={contactDialogOpen} unmount>
            <ContactDialog onClose={() => setContactDialogOpen(false)} />
          </Transition.Root>
        </RelativePortal>
      </div>
    </>
  );
}
