import { Property, RootState } from '@/@types/models';
import { IProperty } from '@/@types/viewItem';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import {
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { COL_PROPERTIES, getPropertiesPath } from '@/libs/docPathUtils';

import { getFullSystemFields, getUpdateSystemFields } from '@/libs/utils';

import { generateDocId } from '@/firestore';
import useHandleApi from '@/hooks/useHandleApi';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * propertiesのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useProperties() {
  const rrfFirestore = useFirestore();
  const { t } = useTranslation();
  const { exec } = useHandleApi();
  const { currentMyProject, myProjects } = useProjects();

  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // アイテムID切替ごとにストアが洗い替えされないため projectId毎にサブスクリプションしている
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => {
      const query: ReduxFirestoreQuerySetting = {
        collection: getPropertiesPath(workspaceId, p.id as string),
        storeAs: `${COL_PROPERTIES}/${p.id}`,
      };
      // ゲストの場合は絞り込みを追加
      if (
        ['unknownGuest', 'guest'].includes(
          currentMyWorkspace?.joinType as string,
        )
      ) {
        query.where = [['isShare', '==', true]];
      }
      return query;
    });
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);

  const properties = useMemo(() => {
    if (!currentMyProject) return [];
    return (ordered[`${COL_PROPERTIES}/${currentMyProject.id}`] ||
      []) as Property[];
  }, [ordered, currentMyProject]);

  /**
   * プロパティ設定の新規ID取得
   * @returns
   */
  const generatePropertyId = () => {
    if (!workspaceId || !projectId)
      throw new Error('property generateId failed.');
    return generateDocId(getPropertiesPath(workspaceId, projectId));
  };

  /**
   * プロパティ設定新規作成
   * @param data
   * @param docId
   */
  const createProperty = async (data: Property, docId: IProperty['docId']) => {
    exec(async () => {
      if (!docId || !workspaceId || !projectId)
        throw new Error('property create failed.');
      const property: Property = {
        ...data,
        ...getFullSystemFields(userId),
      };
      // ※ 事前に作成したIDを指定して保存するため`set`を利用
      // DB保存値との重複チェックのためIDを事前生成しているはず
      await rrfFirestore.set<Property>(
        `${getPropertiesPath(workspaceId, projectId)}/${docId}`,
        property,
      );
    }, t('プロパティの追加に失敗しました'));
  };

  /**
   * プロパティ設定更新
   * @param data
   * @param docId
   */
  const updateProperty = async (data: Property, docId: IProperty['docId']) => {
    exec(async () => {
      if (!docId || !workspaceId || !projectId)
        throw new Error('property update failed.');

      rrfFirestore.update<Property>(
        `${getPropertiesPath(workspaceId, projectId)}/${docId}`,
        {
          ...data,
          ...getUpdateSystemFields(userId),
        },
      );
    }, t('プロパティの更新に失敗しました'));
  };

  /**
   * プロパティ設定削除
   * @param propertyId
   */
  const deleteProperty = (propertyId: Property['id']) => {
    if (!workspaceId || !projectId) throw new Error('property delete failed.');
    exec(async () => {
      // プロパティ削除
      rrfFirestore.delete(
        `${getPropertiesPath(workspaceId, projectId)}/${propertyId}`,
      );
    }, t('プロパティの削除に失敗しました'));
  };

  /**
   * プロパティの公開設定を更新
   * @param composedData
   */
  const updatePropertyIsShare = (
    composedData: Array<{
      property: Property;
      isShare: Property['isShare'];
    }>,
  ) => {
    if (!workspaceId || !projectId)
      throw new Error('property isShare update failed.');
    composedData.forEach((d) => {
      exec(async () => {
        rrfFirestore.update(
          `${getPropertiesPath(workspaceId, projectId)}/${d.property.id}`,
          {
            isShare: d.isShare,
            ...getUpdateSystemFields(userId),
          },
        );
      }, t('プロパティの公開設定の更新に失敗しました'));
    });
  };

  return {
    properties,
    generatePropertyId,
    createProperty,
    updateProperty,
    deleteProperty,
    updatePropertyIsShare,
  };
}
