// Import the functions you need from the SDKs you need
// Firebase SDK v9 (modular style)
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import {
  initializeFirestore,
  connectFirestoreEmulator,
  setLogLevel,
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

import { ENV } from '@/libs/const';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, 'asia-northeast1');

// for Emulator
if (import.meta.env.VITE_NODE_ENV === ENV.DEV) {
  connectAuthEmulator(auth, import.meta.env.VITE_FIREBASE_EMULATOR_URL);
  connectFunctionsEmulator(functions, 'localhost', 5001);

  const firestoreDb = initializeFirestore(firebaseApp, {
    //  experimentalForceLongPolling: true,
  });
  setLogLevel('error');
  connectFirestoreEmulator(firestoreDb, 'localhost', 8080);
}

export { firebaseApp, auth, functions };
