import { Project, RootState, SlackIntegration, View } from '@/@types/models';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_SLACK_INTEGRATIONS,
  getSlackIntegrationsPath,
} from '@/libs/docPathUtils';

import { getUpdateSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useProjects from '@/hooks/useProjects';

/**
 * SlackIntegrationsの集約に関するHooks
 */
export default function useSlackIntegrations() {
  const rrfFirestore = useFirestore();
  const { t } = useTranslation();
  const { exec } = useHandleApi();
  const { currentMyWorkspace, isMember } = useMyWorkspaces();
  const { myProjects } = useProjects();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  // 現在
  const projectIds = useMemo(
    () => myProjects.map((d) => d.projectId as string),
    [myProjects],
  );

  // プロジェクトID毎にサブスクリプションを実行
  useFirestoreConnect(() => {
    if (!workspaceId || projectIds.length === 0 || !isMember) return [];
    return projectIds.map((pId) => ({
      collection: getSlackIntegrationsPath(workspaceId, pId),
      storeAs: `${COL_SLACK_INTEGRATIONS}/${pId}`,
    }));
  });
  // viewsのセレクタ（project毎にサブスクリプションする必要があるため、stateの型はany）
  const ordered = useSelector((state: any) => state.firestore.ordered);
  const slackIntegrations = useMemo(
    () =>
      projectIds.flatMap<SlackIntegration>((pId) => {
        const pSlackIntegrations = ordered[`${COL_SLACK_INTEGRATIONS}/${pId}`];
        if (!pSlackIntegrations) return [];

        return pSlackIntegrations;
      }),
    [ordered],
  );

  /**
   * Slack連携設定更新
   * @param projectId
   * @param onCreateComment
   * @param onCreateLike
   */
  const updateSlackIntegration = async (
    projectId: Project['id'],
    onCreateComment: SlackIntegration['onCreateComment'],
    onCreateLike: SlackIntegration['onCreateLike'],
    onCreateItem: SlackIntegration['onCreateItem'],
    onUpdateItem: SlackIntegration['onUpdateItem'],
    onDeleteItem: SlackIntegration['onDeleteItem'],
    onDueDate: SlackIntegration['onDueDate'],
  ) => {
    exec(async () => {
      if (!userId || !workspaceId || !projectId)
        throw new Error('updateView error');

      const slackIntegration: SlackIntegration = {
        onCreateComment,
        onCreateLike,
        onCreateItem,
        onUpdateItem,
        onDeleteItem,
        onDueDate,
        ...getUpdateSystemFields(userId),
      };
      await rrfFirestore.update<View>(
        `${getSlackIntegrationsPath(workspaceId, projectId)}/${projectId}`,
        slackIntegration,
      );
    }, t('Slack連携設定の更新に失敗しました'));
  };

  /**
   * Slack連携情報の削除
   * @param projectId
   */
  const deleteSlackIntegration = async (projectId: Project['id']) => {
    exec(async () => {
      if (!userId || !workspaceId || !projectId)
        throw new Error('updateView error');
      await rrfFirestore.delete(
        `${getSlackIntegrationsPath(workspaceId, projectId)}/${projectId}`,
      );
    }, t('Slack連携設定の更新に失敗しました'));
  };

  return { slackIntegrations, updateSlackIntegration, deleteSlackIntegration };
}
