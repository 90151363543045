import { RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_INVITING_MEMBERS,
  getInvitingMembersPath,
} from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * 招待中メンバーの集約に関するHooks
 * @returns { invitingMembers }
 */
export default function useInvitingMembers() {
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const invitingMembers = useSelector(
    (state: RootState) => state.firestore.ordered[COL_INVITING_MEMBERS] || [],
  );

  useFirestoreConnect(() =>
    workspaceId
      ? [
          {
            collection: getInvitingMembersPath(workspaceId),
            orderBy: ['invitedAt', 'asc'],
            storeAs: COL_INVITING_MEMBERS,
          },
        ]
      : [],
  );

  return { invitingMembers };
}
