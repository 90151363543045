import { RootState, Status } from '@/@types/models';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { COL_STATUSES, getStatusesPath } from '@/libs/docPathUtils';

import { getFullSystemFields, getUpdateSystemFields } from '@/libs/utils';

import { generateDocId } from '@/firestore';
import useHandleApi from '@/hooks/useHandleApi';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * statusesのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useStatuses() {
  const rrfFirestore = useFirestore();
  const { t } = useTranslation();
  const { exec } = useHandleApi();

  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject, myProjects } = useProjects();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => ({
      collection: getStatusesPath(workspaceId, p.id as string),
      storeAs: `${COL_STATUSES}/${p.id}`,
    }));
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);

  // returnするステータス
  const statuses = useMemo(() => {
    if (!currentMyProject) return [];
    return (ordered[`${COL_STATUSES}/${currentMyProject.id}`] ||
      []) as Status[];
  }, [ordered, currentMyProject]);

  /**
   * 新規ID取得
   * @returns
   */
  const generateStatusId = () => {
    if (!workspaceId || !projectId)
      throw new Error('status generateId failed.');
    return generateDocId(getStatusesPath(workspaceId, projectId));
  };

  /**
   * ステータス新規作成
   * @param statusName
   * @param statusColor
   * @param shareItemCount
   * @param nonShareItemCount
   * @param docId
   * @param icon
   */
  const createStatus = async (
    statusName: Status['statusName'],
    statusColor: Status['statusColor'],
    shareItemCount: Status['shareItemCount'],
    nonShareItemCount: Status['nonShareItemCount'],
    docId: Status['id'],
    icon: Status['icon'],
  ) => {
    if (!docId || !workspaceId || !projectId || !userId)
      throw new Error('status create failed.');
    exec(async () => {
      const status: Status = {
        statusName,
        statusColor,
        shareItemCount,
        nonShareItemCount,
        icon,
        ...getFullSystemFields(userId),
      };
      // ※ 事前に作成したIDを指定して保存するため`set`を利用
      // DB保存値との重複チェックのためIDを事前生成しているはず
      await rrfFirestore.set<Status>(
        `${getStatusesPath(workspaceId, projectId)}/${docId}`,
        status,
      );
    }, t('ステータスの追加に失敗しました'));
  };

  /**
   * ステータス更新
   * @param statusName
   * @param icon
   * @param statusColor
   * @param docId
   */
  const updateStatus = async (
    statusName: Status['statusName'],
    icon: Status['icon'],
    statusColor: Status['statusColor'],
    docId: Status['id'],
  ) => {
    if (!workspaceId || !projectId || !userId || !docId)
      throw new Error('status update failed.');
    exec(async () => {
      rrfFirestore.update<Status>(
        `${getStatusesPath(workspaceId, projectId)}/${docId}`,
        {
          statusName,
          icon,
          statusColor,
          ...getUpdateSystemFields(userId),
        },
      );
    }, t('ステータスの更新に失敗しました'));
  };

  /**
   *ステータス削除
   * @param docId
   */
  const deleteStatus = async (docId: Status['id']) => {
    if (!workspaceId || !projectId || !docId)
      throw new Error('status delete failed.');
    exec(async () => {
      rrfFirestore.delete(
        `${getStatusesPath(workspaceId, projectId)}/${docId}`,
      );
    }, t('ステータスの削除に失敗しました'));
  };

  return {
    statuses,
    generateStatusId,
    createStatus,
    updateStatus,
    deleteStatus,
  };
}
