import { MyProject, View } from '@/@types/models';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getNavigatePath } from '@/libs/utils';

import { setCurrentProjectIdAction } from '@/reducers/currentProjectReducer';
import { setCurrentViewId as setCurrentViewIdAction } from '@/reducers/currentViewIdReducer';

import useProjects from '@/hooks/useProjects';
import useViews from '@/hooks/useViews';

import useMyWorkspaces from './useMyWorkspaces';

/**
 * アプリケーション実行に必要なリソースの存在有無をモニター/ナビゲーション制御するフック
 */
export default function useMonitorApp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workspaceId, projectId, viewId } = useParams();

  const { currentMyProject } = useProjects();
  const { currentView } = useViews();
  const { isMember, isGuest, isUnknownGuest } = useMyWorkspaces();

  // 1. projectId, viewIdをURLに応じて変更
  useEffect(() => {
    dispatch(setCurrentProjectIdAction(projectId));
    dispatch(setCurrentViewIdAction(viewId));
  }, [projectId, viewId]);

  // メンバー以外の場合、currentViewは共有されているもを有効とする
  const currentMyView = useMemo(() => {
    if (isMember) return currentView;
    return currentView?.isGuestAccessible ? currentView : null;
  }, [currentView, isMember]);

  // 参加タイプによって参照可能なプロジェクトを選定する
  const currentMyAccessibleProject = useMemo(() => {
    // 匿名ゲストは共有かつリンク共有されているプロジェクトのみ
    if (isUnknownGuest) {
      return currentMyProject?.isShare && currentMyProject?.allowLinkShare
        ? currentMyProject
        : null;
    }
    // ゲストは共有されているプロジェクトのみ
    if (isGuest) return currentMyProject?.isShare ? currentMyProject : null;
    // メンバーは全てのプロジェクト
    return currentMyProject;
  }, [currentMyProject, isMember, isGuest, isUnknownGuest]);

  // 2. ワークスペース・プロジェクト・ビュー削除時はリダイレクト
  // 参照中のカレントプロジェクト/カレントビューが存在しなくなった場合ナビゲーションする
  const [cachedProject, setCachedProject] = useState<MyProject | null>(null);
  const [cachedView, setCachedView] = useState<View | null>(null);
  useEffect(() => {
    // currentProject !== null -> null になった場合
    if (cachedProject && currentMyAccessibleProject === null && workspaceId) {
      navigate(getNavigatePath(workspaceId));
    }
    // currentView !== null -> null になった場合
    else if (cachedView && currentMyView === null && workspaceId && projectId) {
      navigate(getNavigatePath(workspaceId, projectId));
    }
    // 変更されたcurrentProjectをキャッシュする
    setCachedProject(currentMyAccessibleProject);
    // 変更されたcurrentViewをキャッシュする
    setCachedView(currentMyView);
  }, [currentMyAccessibleProject, currentMyView, workspaceId, projectId]);
}
