import { MemberGuestViewItem, Property } from '@/@types/models';
import { PropertyTypes } from '@/@types/viewItem';
import {
  ChatBubbleBottomCenterTextIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';

import React, { useCallback, useContext, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import reactStringReplace from 'react-string-replace';

import {
  DEFAULT_LIST_VIEW_TITLE_COLUMN_WIDTH,
  LIST_VIEW_TITLE_COLUMN_KEY,
  PROPERTY_TYPE,
  URL_REGEX,
} from '@/libs/const';

import { DayjsUtil } from '@/libs/dayjs';
import { classNames } from '@/libs/styleUtils';
import {
  commarize,
  formatNumberProperty,
  reduceProperties,
} from '@/libs/utils';

import AvatarIcon from '@/components/Common/AvatarIcon';
import EllipsisNode from '@/components/Common/EllipsisNode';
import EllipsisText from '@/components/Common/EllipsisText';
import FileLoader from '@/components/Common/FileLoader';
import Checkbox from '@/components/Common/Forms/Checkbox';
import { SelectBadge } from '@/components/Common/SelectBadge';
import DueDateButton from '@/components/Items/DueDateButton';
import {
  isSelectPublishItemModeContext,
  itemContext,
} from '@/components/Share/SelectPublishItemsContext';

import ItemDropDown from '@/components/View/ItemDropDown';
import ListItemTd from '@/components/View/List/ListItemTd';
import ViewLiked from '@/components/View/ViewLiked';

import useAccessSettings from '@/hooks/useAccessSettings';
import useMentions from '@/hooks/useMentions';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';
import useViewItems from '@/hooks/useViewItems';
import useViews from '@/hooks/useViews';

export interface IListItemProps {
  properties: Property[];
  viewItem: MemberGuestViewItem;
}

/**
 * リストビューのアイテム
 * @param propo
 * @returns
 */
export default function ListItem(props: IListItemProps) {
  const { properties, viewItem } = props;
  const { t } = useTranslation();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const { currentView } = useViews();
  const { getHighlightClass } = useViewItems();
  const { isDisplayComment, isDisplayLiked } = useAccessSettings();
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  const { membersDic, guestsDic } = useMentions();
  const { removedMembersDic } = useRemovedMembers();
  const { removedGuestsDic } = useRemovedGuests();
  const { isMember } = useMyWorkspaces();

  const memoProperties = useMemo(
    () =>
      reduceProperties(
        currentMyWorkspace?.workspaceId || '',
        currentMyProject?.projectId || '',
        viewItem.id || '',
        properties,
        viewItem,
        currentView?.propertyOrderList || [],
        isMember,
      ),
    [
      properties,
      currentView,
      currentMyWorkspace,
      currentMyProject,
      viewItem.propertyValues,
      properties,
      isMember,
    ],
  );

  const membersAndGuestsDic = useMemo(() => {
    const currentDic = {
      ...guestsDic,
      ...membersDic,
      ...removedGuestsDic,
      ...removedMembersDic,
    };
    return currentDic;
  }, [guestsDic, membersDic, removedGuestsDic, removedMembersDic]);

  // プロパティ種別ごとにフォーム出力
  const propertySelector = useCallback(
    (pr: PropertyTypes) => {
      // シングルセレクト、ステータス
      if (pr.type === PROPERTY_TYPE.SINGLE_SELECT && pr.value) {
        return (
          pr.value && (
            <SelectBadge
              className="text-sm"
              color={pr.color}
              content={pr.value}
            />
          )
        );
      }
      // マルチセレクト
      if (pr.type === PROPERTY_TYPE.MULTI_SELECT && pr.value) {
        return (
          <div key={pr.id} className="flex flex-wrap gap-x-2 gap-y-2">
            {pr.value?.map((o, idx) => (
              <SelectBadge
                key={`multiSelectItem-${idx + 1}`}
                className="text-sm"
                color={pr.color[idx]}
                content={o}
              />
            ))}
          </div>
        );
      }
      // ファイル添付
      if (pr.type === PROPERTY_TYPE.FILE && pr.value) {
        return (
          <div className="flex flex-wrap gap-x-2 gap-y-2">
            {pr.value?.map((o, idx) => (
              <FileLoader
                key={`${o.fileName}-${idx + 1}`}
                src={o}
                size="xs"
                clickable
              />
            ))}
          </div>
        );
      }
      // チェックボックス
      if (pr.type === PROPERTY_TYPE.CHECKBOX) {
        return <Checkbox name={pr.id} checked={pr.value} readOnly />;
      }
      // 担当者
      if (pr.type === PROPERTY_TYPE.INCHARGE && pr.value) {
        const inChargeList = pr.value.map((uid) => membersAndGuestsDic[uid]);
        return (
          inChargeList && (
            <div className="flex flex-wrap w-full gap-x-2 gap-y-2">
              {inChargeList.map((o, idx) => (
                <div
                  className="relative flex items-center w-full"
                  key={`inchargeItem-${idx + 1}`}
                >
                  <div className="flex-shrink-0">
                    <AvatarIcon
                      size="sm"
                      src={o?.photoURL ? o.photoURL : undefined}
                      avatarName={o?.displayName ? o.displayName : ''}
                    />
                  </div>
                  <EllipsisText className="w-full ml-2">
                    {o?.displayName ? o.displayName : ''}
                  </EllipsisText>
                </div>
              ))}
            </div>
          )
        );
      }
      // 日付
      if (pr.type === PROPERTY_TYPE.DATE && pr.value) {
        const v = new DayjsUtil(new Date(pr.value));
        return (
          <div className="flex items-center">
            <EllipsisText className="w-full">
              {v.format(t('YYYY-MM-DD'))}
            </EllipsisText>
            {pr.isDueDate && (
              <DueDateButton
                itemId={viewItem.id}
                propertyId={pr.id}
                className="ml-1"
              />
            )}
          </div>
        );
      }
      // 数値
      if (pr.type === PROPERTY_TYPE.NUMBER) {
        return (
          <EllipsisText className="w-full">
            {formatNumberProperty(pr.value, pr.format)?.toString()}
          </EllipsisText>
        );
      }
      // テキスト
      if (pr.type === PROPERTY_TYPE.TEXT && pr.value) {
        return (
          <EllipsisNode tooltipText={pr.value}>
            {reactStringReplace(pr.value, URL_REGEX, (match, i) => (
              <a
                key={i}
                href={match}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {match}
              </a>
            ))}
          </EllipsisNode>
        );
      }
      return null;
    },
    [membersAndGuestsDic],
  );

  const { checkedItemIds, checkItemId } = useContext(itemContext);

  const highlightClass = useMemo(
    () =>
      getHighlightClass(
        viewItem,
        'dark:bg-originalDueWarning bg-originalDueWarning-light',
        'dark:bg-originalDueDanger bg-originalDueDanger-light',
      ),
    [getHighlightClass, viewItem],
  );
  return (
    <>
      <ListItemTd
        sizingKey={LIST_VIEW_TITLE_COLUMN_KEY}
        defaultWidth={DEFAULT_LIST_VIEW_TITLE_COLUMN_WIDTH}
        className={classNames(
          'relative px-6 py-2 border-b border-gray-200 dark:border-gray-700 group-hover:bg-gray-50 dark:group-hover:bg-gray-700 w-full',
        )}
      >
        {highlightClass && (
          <div
            className={classNames(
              'absolute top-0 left-0 w-1 bottom-0',
              highlightClass,
            )}
          />
        )}
        <div className="flex items-center justify-between space-x-4">
          <div className="flex items-center justify-start">
            {/** アイテム選択モードの場合は選択チェックボックスを表示 */}
            {isSelectPublishItemMode && viewItem.id && (
              <div className="mr-3">
                <Checkbox
                  name="selected"
                  checked={checkedItemIds.includes(viewItem.id)}
                  onChange={() => checkItemId(viewItem.id as string)}
                />
              </div>
            )}
            <div className="w-full font-semibold break-all">
              {viewItem.title}
            </div>
          </div>
          <div className="flex items-center justify-end flex-shrink-0 font-medium text-gray-400 flex-nowrap space-x-3">
            <div className="flex flex-wrap items-center justify-end grow">
              {isDisplayLiked && (
                <div className="flex items-center mr-4">
                  <ViewLiked viewItem={viewItem} />
                  <span className="-ml-1 font-mono text-sm leading-none">
                    {commarize(viewItem.likeCount ?? 0)}
                  </span>
                </div>
              )}
              {isDisplayComment && (
                <div className="flex items-center">
                  <ChatBubbleBottomCenterTextIcon
                    className="w-4 h-4"
                    aria-hidden="true"
                  />
                  <span className="ml-1 font-mono text-sm leading-none">
                    {commarize(viewItem.commentCount ?? 0)}
                  </span>
                </div>
              )}
              {!isSelectPublishItemMode &&
                isMember &&
                viewItem.isShare === false && (
                  <div className="flex items-center ml-4">
                    <EyeSlashIcon className="w-4 h-4" aria-hidden="true" />
                  </div>
                )}
            </div>
            {!isSelectPublishItemMode && (
              <div>
                <ItemDropDown viewItem={viewItem} />
              </div>
            )}
          </div>
        </div>
      </ListItemTd>
      {/* 固定部分 ここまで */}
      {memoProperties.map((pr) => (
        <ListItemTd
          propertyTypes={pr}
          key={pr.id}
          className="px-6 py-2 break-words border-b border-l border-gray-200 dark:border-gray-700"
        >
          {propertySelector(pr)}
        </ListItemTd>
      ))}
      <td className="w-auto px-6 py-2 break-words border-b border-gray-200 dark:border-gray-700" />
    </>
  );
}
