import * as React from 'react';

import { classNames } from '@/libs/styleUtils';

export interface ITooltipProps {
  className?: string;
  message: string | null | undefined;
  showBellow?: boolean;
}

/**
 * バリデーションメッセージ用ツールチップ
 * @param props
 * @returns
 */
export default function ValidationTooltip(props: ITooltipProps) {
  const { className, message, showBellow } = props;

  return (
    <div
      className={classNames(
        'absolute right-0 flex-col items-end group-hover:flex',
        message ? 'flex' : 'hidden',
        className,
        showBellow ? 'top-10' : 'bottom-0 mb-10',
      )}
    >
      <span className="relative z-10 px-2 py-1 text-sm leading-normal text-white whitespace-no-wrap bg-red-400 dark:text-red-50 dark:bg-red-500 rounded-md">
        {message}
      </span>
      <div
        className={classNames(
          'w-3 h-3 mr-4  bg-red-400 dark:bg-red-500 rotate-45',
          showBellow ? '-mt-8' : '-mt-2',
        )}
      />
    </div>
  );
}
