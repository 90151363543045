import { IPropertyFormatType } from '@/@types/models';

import React, { useMemo } from 'react';

import { NUMBER_PROPERTY_FORMAT_TYPE } from '@/libs/const';

import Input from '@/components/Common/Forms/Input';

export interface IPropertyNumberProps {
  idx: number;
  format?: IPropertyFormatType | null;
}

/**
 * 数値プロパティ入力フォーム
 * @param props
 * @returns
 */
export default function PropertyNumber(props: IPropertyNumberProps) {
  const { format, idx } = props;

  const formatPart = useMemo(() => {
    if (format === NUMBER_PROPERTY_FORMAT_TYPE.PERCENT) {
      return <div className="w-5 text-center">%</div>;
    }
    if (format === NUMBER_PROPERTY_FORMAT_TYPE.YEN) {
      return <div className="w-5 text-center">¥</div>;
    }
    return <div className="w-5 text-xs text-center">123</div>;
  }, [format]);

  return (
    <Input
      name="properties"
      idx={idx}
      property="numberValue"
      addOn={{ right: formatPart }}
      number
    />
  );
}
