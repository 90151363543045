import { View } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import Alert from '@/components/Common/Alert';
import { Badge } from '@/components/Common/Badge';
import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import Toggle from '@/components/Common/Forms/Toggle';
import Tab, { TabItem } from '@/components/Common/Tab';

import { setIsSelectPublishItemModeContext } from '@/components/Share/SelectPublishItemsContext';

import useAccessSettings from '@/hooks/useAccessSettings';
import useProjects from '@/hooks/useProjects';
import useProperties from '@/hooks/useProperties';
import useSubmitState from '@/hooks/useSubmitState';
import useViews from '@/hooks/useViews';

/**
 * 公開設定ダイアログ
 * @param props
 * @returns
 */
export default function ShareSettingDialog(props: IModalDialogProps) {
  const { t } = useTranslation();
  const { onClose } = props;

  const { views, updateIsGuestAccessible } = useViews();
  const { currentMyProject } = useProjects();

  const { accessSetting, updateAccessSetting } = useAccessSettings();

  const [currentProjectViews, setCurrentProjectViews] = useState<View[]>([]);

  // 公開設定のローカルステート
  const [isBodyVisible, setIsBodyVisible] = useState(
    accessSetting?.isBodyVisible,
  );
  const [isCommentVisible, setIsCommentVisible] = useState(
    accessSetting?.isCommentVisible,
  );
  const [isLikeVisible, setIsLikeVisible] = useState(
    accessSetting?.isLikeVisible,
  );
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(
    accessSetting?.isDescriptionVisible,
  );
  const [isGuestAccessible, setIsGuestAccessible] = useState(
    currentProjectViews?.map((x) => x.isGuestAccessible),
  );

  useEffect(() => {
    if (isBodyVisible === undefined) {
      setIsBodyVisible(accessSetting?.isBodyVisible);
    }
  }, [accessSetting?.isBodyVisible]);

  useEffect(() => {
    if (isCommentVisible === undefined) {
      setIsCommentVisible(accessSetting?.isCommentVisible);
    }
  }, [accessSetting?.isBodyVisible]);

  useEffect(() => {
    if (isLikeVisible === undefined) {
      setIsLikeVisible(accessSetting?.isLikeVisible);
    }
  }, [accessSetting?.isBodyVisible]);

  useEffect(() => {
    if (isDescriptionVisible === undefined) {
      setIsDescriptionVisible(accessSetting?.isDescriptionVisible);
    }
  }, [accessSetting?.isBodyVisible]);

  useEffect(() => {
    if (isGuestAccessible === undefined || isGuestAccessible.length === 0) {
      setIsGuestAccessible(
        currentProjectViews?.map((x) => x.isGuestAccessible),
      );
    }
  }, [currentProjectViews]);

  const { properties, updatePropertyIsShare } = useProperties();

  const setIsSelectPublishItemMode = useContext(
    setIsSelectPublishItemModeContext,
  );
  const changeSelectPublishItemMode = useCallback((mode) => {
    setIsSelectPublishItemMode(() => mode);
  }, []);

  // 公開アイテム選択モードON
  const onPublishItemMode = () => {
    changeSelectPublishItemMode(true);
    onClose();
  };

  // タブの定数をメモ化しないと再レンダリング時にタブ未選択になる
  const tabs: TabItem[] = useMemo(
    () => [
      { id: 0, label: t('公開する内容') },
      { id: 1, label: t('公開するビューを選択') },
    ],
    [t],
  );

  // ===== zod設定 ======
  // ↓↓↓
  // Zod schema定義
  const schema = z.object({
    permitViews: z
      .array(
        z.object({
          isGuestAccessible: z.boolean(),
        }),
      )
      .refine((arr) => arr.some((d) => d.isGuestAccessible), {
        message: t('全てのビューを非公開にすることはできません'),
      }),
    permitProperties: z.array(
      z.object({
        isShare: z.boolean(),
      }),
    ),
    isBodyVisible: z.boolean(),
    isCommentVisible: z.boolean(),
    isLikeVisible: z.boolean(),
    isDescriptionVisible: z.boolean(),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  // ↑↑↑
  // ===== zod設定 ======

  // 送信時処理
  const formSubmit = methods.handleSubmit((data) => {
    const {
      permitViews,
      permitProperties,
      isBodyVisible: bodyVisible,
      isCommentVisible: commentVisible,
      isLikeVisible: likeVisible,
      isDescriptionVisible: descriptionVisible,
    } = data;

    // 各ビューの公開状態を更新
    const composedViewsData = currentProjectViews.map((view, index) => ({
      view,
      isGuestAccessible: permitViews[index].isGuestAccessible,
    }));
    updateIsGuestAccessible(composedViewsData, currentMyProject?.id as string);

    // 各プロパティの公開状態を更新
    const composedPropertyData = properties.map((property, index) => ({
      property,
      isShare: permitProperties[index].isShare,
    }));
    updatePropertyIsShare(composedPropertyData);

    // 各表示領域の状態を更新
    updateAccessSetting(
      accessSetting?.id as string,
      bodyVisible,
      commentVisible,
      likeVisible,
      descriptionVisible,
    );

    onClose();
  });

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  // バリデーションエラーメッセージ
  const errorMessage = useMemo(() => {
    // refineでバリデーションするとエラーの型に表示されないためいったんanyにする
    const permitViewsError = methods.formState.errors.permitViews as any;
    return permitViewsError ? permitViewsError.message : undefined;
  }, [methods.formState.errors]);

  // mount時の設定を保持する（更新ボタンで更新するため）
  useEffect(() => {
    const filtered = views.filter((v) => v._projectId === currentMyProject?.id);
    setCurrentProjectViews(filtered);
    const permitViews = filtered.map((d) => ({
      isGuestAccessible: !!d.isGuestAccessible,
    }));
    const permitProperties = properties.map((d) => ({
      isShare: !!d.isShare,
    }));

    methods.reset({
      permitViews,
      permitProperties,
      isBodyVisible: accessSetting?.isBodyVisible,
      isCommentVisible: accessSetting?.isCommentVisible,
      isLikeVisible: accessSetting?.isLikeVisible,
      isDescriptionVisible: accessSetting?.isDescriptionVisible,
    });

    return () => {
      methods.reset();
    };
  }, []);

  return (
    <FormDialog
      onClose={onClose}
      title={t('公開設定')}
      onSubmit={formSubmit}
      top
    >
      <FormProvider {...methods}>
        <Tab items={tabs} placeholder={t('共有方法を選択')}>
          <div>
            <div className="flex items-center justify-between mb-4">
              <div className="block font-medium text-gray-700 dark:text-gray-400">
                {t('公開するアイテム')}
              </div>
              <MenuButton
                id="showPublishItemSelect"
                className="w-auto"
                type="text"
                onClick={onPublishItemMode}
              >
                {t('アイテムを選択')}
              </MenuButton>
            </div>
            <div className="mt-6 overflow-auto grid grid-cols-1 gap-6 max-h-60">
              <Toggle
                key="isBodyVisible"
                label={t('本文を表示')}
                name="isBodyVisible"
                defaultValue={isBodyVisible}
                onChange={({ value }) => {
                  setIsBodyVisible(value);
                }}
              />
              <Toggle
                key="isCommentVisible"
                label={t('コメントを表示')}
                name="isCommentVisible"
                defaultValue={isCommentVisible}
                onChange={({ value }) => {
                  setIsCommentVisible(value);
                }}
              />
              <Toggle
                key="isLikeVisible"
                label={t('いいねを表示')}
                name="isLikeVisible"
                defaultValue={isLikeVisible}
                onChange={({ value }) => {
                  setIsLikeVisible(value);
                }}
              />
              <Toggle
                key="isDescriptionVisible"
                label={t('プロジェクト説明欄を表示')}
                name="isDescriptionVisible"
                defaultValue={isDescriptionVisible}
                onChange={({ value }) => {
                  setIsDescriptionVisible(value);
                }}
              />
            </div>
            <div className="mt-6 font-medium text-gray-500">
              <small>{t('公開するプロパティ')}</small>
            </div>
            {properties.length === 0 && (
              <Badge
                color="gray"
                className="mt-6 text-xs text-gray-700 dark:text-gray-400"
                content={t('設定可能なプロパティがありません。')}
              />
            )}
            <div className="mt-6 overflow-auto grid grid-cols-1 gap-6 max-h-60">
              {properties.map((p, index) => (
                <Toggle
                  key={p.id}
                  label={p.propertyName}
                  name="permitProperties"
                  idx={index}
                  property="isShare"
                  defaultValue={p.isShare}
                />
              ))}
            </div>
          </div>
          <div className="overflow-auto grid grid-cols-1 gap-6 max-h-80">
            {errorMessage && (
              <Alert color="red" icon={ExclamationTriangleIcon}>
                <h3 className="font-medium">{errorMessage}</h3>
              </Alert>
            )}
            {currentProjectViews.map((v, index) => (
              <Toggle
                key={v.id}
                label={v.viewName}
                name="permitViews"
                idx={index}
                property="isGuestAccessible"
                defaultValue={isGuestAccessible[index]}
                onChange={({ value }) => {
                  const values = isGuestAccessible;
                  values[index] = value;
                  setIsGuestAccessible(values);
                }}
              />
            ))}
          </div>
        </Tab>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="applyPublishSetting"
          disabled={isDisabledApply}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('適用')}
        </FormButton>
        <FormButton
          id="cancelPublishSetting"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
