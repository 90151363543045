import { OriginalColorType, TailwindColorType } from '@/@types/common';

import { ORIGINAL_COLORS, TAILWIND_COLORS } from '@/libs/const';

/**
 * Style classの配列を連結する
 * @param classes
 * @returns
 */
export const classNames = (...classes: (string | undefined | null)[]): string =>
  classes.filter(Boolean).join(' ');

/**
 * Tailwindカラーのオブジェクトを取得する
 *
 * @param {ColorType} colorType
 * @returns
 */
export const getTailwindColorClass = (
  colorType: TailwindColorType | undefined,
) => Object.values(TAILWIND_COLORS).find((d) => d.label === colorType);

/**
 * ステータス用の色クラスを取得する
 * @param colorType
 * @returns
 */
export const getOriginalColorClass = (
  colorType: OriginalColorType | undefined,
) => Object.values(ORIGINAL_COLORS).find((d) => d.label === colorType);

export default {};
