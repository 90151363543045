import { IAccountDictionary, IMention } from '@/@types/viewItem';
import { useMemo } from 'react';

import { JOIN_TYPE } from '@/libs/const';

import useGuests from '@/hooks/useGuests';
import useMembers from '@/hooks/useMembers';

/**
 * Mentionsの集約に関するHooks
 * @returns { members }
 */
export default function useMentions() {
  const { members } = useMembers();
  const { guests } = useGuests();

  /**
   * メンバー一覧の表示名 画像リンクを取得するための辞書
   * [uid]: { photoURL, displayName }
   * @returns {IAccountDictionary}
   */
  const membersDic = useMemo(
    () =>
      members?.reduce<IAccountDictionary>((acc, cur) => {
        const value: IAccountDictionary['uid'] = {
          photoURL: cur.photoURL || null,
          displayName: '',
          joinType: 'member',
        };
        if (cur.uid && cur.displayName) {
          value.displayName = cur.displayName;
          acc[cur.uid] = value;
        }
        return acc;
      }, {}),
    [members],
  );

  /**
   * ゲストー一覧の表示名 画像リンクを取得するための辞書
   * [uid]: { photoURL, displayName }
   * @returns {IAccountDictionary}
   */
  const guestsDic = useMemo(
    () =>
      guests.reduce<IAccountDictionary>((acc, cur) => {
        const value: IAccountDictionary['uid'] = {
          photoURL: cur.photoURL || null,
          displayName: '',
          joinType: 'guest',
        };
        if (cur.uid && cur.displayName) {
          value.displayName = cur.displayName;
          acc[cur.uid] = value;
        }
        return acc;
      }, {}),
    [guests],
  );

  // メンションリスト
  const memberMentions = useMemo(() => {
    const mentions = members?.map((o) => {
      const mention: IMention = {
        id: o.uid,
        photoURL: o.photoURL || null,
        value: o.uid,
        name: o.displayName || '',
        joinType: JOIN_TYPE.MEMBER,
      };
      return mention;
    });
    return mentions;
  }, [members]);

  const guestMentions = useMemo(() => {
    const mentions = guests.map((o) => {
      const name = o.displayName || '**unknown**';
      const mention: IMention = {
        id: o.uid,
        photoURL: o.photoURL || null,
        value: o.uid,
        name,
        joinType: JOIN_TYPE.GUEST,
      };
      return mention;
    });
    return mentions;
  }, [guests]);

  return {
    membersDic,
    memberMentions,
    guestsDic,
    guestMentions,
  };
}
