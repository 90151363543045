import { FaceSmileIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

import BaseButton from '@/components/Items/Comments/Reaction/BaseButton';

export interface IAddReactionProps {
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * リアクションアイコン表示
 * @param props
 * @returns
 */
export default function AddReaction(props: IAddReactionProps) {
  const { className, onClick } = props;
  const { t } = useTranslation();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <BaseButton
      dataTip={t('リアクション')}
      onClick={onClick}
      className={classNames(className)}
    >
      <FaceSmileIcon className="w-4 h-4" />
      <span className="text-xs">+</span>
    </BaseButton>
  );
}
