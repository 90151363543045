import { MyProject, RootState } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import { getTSLBaseUrl, openPopupWindow } from '@/libs/utils';

import ConfirmDialog from '@/components/Common/ConfirmDialog';
import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import Toggle from '@/components/Common/Forms/Toggle';
import Tab, { TabItem } from '@/components/Common/Tab';

import SlackLogoImg from '@/assets/slack_logo.png';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useSlackIntegrations from '@/hooks/useSlackIntegrations';
import useSubmitState from '@/hooks/useSubmitState';

import Alert from '../Common/Alert';

interface IAppLinkageDialogProps extends IModalDialogProps {
  myProject: MyProject;
}

export default function AppLinkageDialog(props: IAppLinkageDialogProps) {
  const { onClose, myProject } = props;
  const { t } = useTranslation();
  const { currentMyWorkspace } = useMyWorkspaces();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // Slack 接続管理hooks
  const { slackIntegrations, updateSlackIntegration, deleteSlackIntegration } =
    useSlackIntegrations();
  // Slack 接続解除確認ダイアログ
  const [unlinkSlackConfirmDialogOpen, setUnlinkSlackConfirmDialogOpen] =
    useState<boolean>(false);

  // タブの定数をメモ化しないと再レンダリング時にタブ未選択になる
  const tabs: TabItem[] = useMemo(
    () => [{ id: 0, label: t('Slack'), icon: SlackLogoImg }],
    [t],
  );

  // 当該プロジェクトのSlack 接続管理情報
  const slackIntegration = useMemo(
    () => slackIntegrations.find((o) => o.id === myProject.id),
    [slackIntegrations],
  );

  // Zod schema定義
  const schema = z.object({
    onCreateComment: z.boolean(),
    onCreateLike: z.boolean(),
    onCreateItem: z.boolean(),
    onUpdateItem: z.boolean(),
    onDeleteItem: z.boolean(),
    onDueDate: z.boolean(),
  });

  type SchemaType = z.infer<typeof schema>;

  // Zod Form
  const methods = useForm<SchemaType>({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      onCreateComment: slackIntegration?.onCreateComment,
      onCreateLike: slackIntegration?.onCreateLike,
      onCreateItem: slackIntegration?.onCreateItem,
      onUpdateItem: slackIntegration?.onUpdateItem,
      onDeleteItem: slackIntegration?.onDeleteItem,
      onDueDate: slackIntegration?.onDueDate,
    },
  });

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    updateSlackIntegration(
      myProject.projectId,
      data.onCreateComment,
      data.onCreateLike,
      data.onCreateItem,
      data.onUpdateItem,
      data.onDeleteItem,
      data.onDueDate,
    );
    onClose();
  });

  // Slack 接続
  const handleLinkSlack = useCallback(() => {
    // SlackのOAuthページにアクセス
    openPopupWindow(
      `https://slack.com/oauth/v2/authorize?client_id=${
        import.meta.env.VITE_SLACK_CLIENT_ID
      }&scope=incoming-webhook&user_scope=&state=${userId}&redirect_uri=${getTSLBaseUrl()}/${
        currentMyWorkspace?.originalWorkspaceId
      }/${myProject?.id}/slack/callback/`,
      'slackOAuth',
      500,
      680,
    );
  }, []);

  // Slack 接続解除
  const handleUnlinkSlack = useCallback((acceptFlg: boolean | undefined) => {
    if (acceptFlg) {
      deleteSlackIntegration(myProject.id);
    }
    setUnlinkSlackConfirmDialogOpen(false);
  }, []);

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <FormDialog
      onClose={onClose}
      title={t('アプリ連携')}
      onSubmit={formSubmit}
      top
    >
      <FormProvider {...methods}>
        <Tab items={tabs} placeholder={t('共有方法を選択')}>
          {slackIntegration ? (
            <div>
              <div className="flex items-center justify-between mb-4 space-x-3">
                <div className="block font-medium text-gray-700 dark:text-gray-400">
                  {t('<workspaceName> - <channelName> と接続しています', {
                    workspaceName: slackIntegration.slackWorkspaceName,
                    channelName: slackIntegration.channelName,
                  })}
                </div>
                <MenuButton
                  id="showPublishItemSelect"
                  className="flex-shrink-0 w-auto"
                  type="text"
                  onClick={() => {
                    setUnlinkSlackConfirmDialogOpen(true);
                  }}
                >
                  {t('連携を解除')}
                </MenuButton>
              </div>
              <div className="mt-6 font-medium text-gray-500">
                <small>{t('通知する内容をカスタマイズ')}</small>
              </div>
              <div className="mt-5">
                <Toggle
                  key="onCreateComment"
                  label={t('コメントが追加されたとき')}
                  name="onCreateComment"
                  defaultValue={slackIntegration.onCreateComment}
                />
              </div>
              <div className="mt-5">
                <Toggle
                  key="onCreateLike"
                  label={t('いいねされたとき')}
                  name="onCreateLike"
                  defaultValue={slackIntegration.onCreateLike}
                />
              </div>
              <div className="mt-5">
                <Toggle
                  key="onCreateItem"
                  label={t('アイテムを新規作成したとき')}
                  name="onCreateItem"
                  defaultValue={slackIntegration.onCreateItem}
                />
              </div>
              <div className="mt-5">
                <Toggle
                  key="onUpdateItem"
                  label={t('アイテムを更新したとき')}
                  name="onUpdateItem"
                  defaultValue={slackIntegration.onUpdateItem}
                />
              </div>
              <div className="mt-5">
                <Toggle
                  key="onDeleteItem"
                  label={t('アイテムを削除したとき')}
                  name="onDeleteItem"
                  defaultValue={slackIntegration.onDeleteItem}
                />
              </div>
              <div className="mt-5">
                <Toggle
                  key="onDueDate"
                  label={t('期日が到来したとき')}
                  name="onDueDate"
                  defaultValue={slackIntegration.onDueDate}
                />
                <Alert color="gray" className="mt-3 text-xs">
                  {t(
                    '日付プロパティで「期日として扱う」がONの場合のみ、期日前日と当日の9<colon>00に通知されます。',
                    {
                      colon: ':',
                    },
                  )}
                </Alert>
              </div>
            </div>
          ) : (
            <div>
              <div className="mb-3">
                {t(
                  'Slackアプリ連携は、プロジェクト上でのコメント、いいね、アイテムの作成などの各種イベント発生時に、指定したSlackチャンネルに通知することが可能です。<br>Slackで即座に更新通知を受け取り、コラボレーションをより一層活性化させましょう。',
                  { br: <br /> },
                )}
              </div>
              <div className="flex justify-center flex-1">
                <MenuButton
                  id="createProject"
                  disabled={false}
                  type="text"
                  className="w-full"
                  onClick={() => {
                    handleLinkSlack();
                  }}
                >
                  {t('連携を設定')}
                </MenuButton>
              </div>
            </div>
          )}
        </Tab>

        {/* ステータス削除確認ダイアログ */}
        <ConfirmDialog
          key="item-discard-confirm"
          open={unlinkSlackConfirmDialogOpen}
          onClose={handleUnlinkSlack}
          title={t('連携を解除')}
          positive={t('解除')}
          warning
        >
          <p className="mb-2">
            {t(
              '<workspaceName> - <channelName> との接続を解除しようとしています。',
              {
                workspaceName: slackIntegration?.slackWorkspaceName,
                channelName: slackIntegration?.channelName,
              },
            )}
          </p>
          <p>{t('本当によろしいですか？')}</p>
        </ConfirmDialog>
      </FormProvider>
      <FormProvider {...methods}>
        {slackIntegration && (
          <FormButton
            id="createProject"
            disabled={isDisabledApply}
            submit
            variant="primary"
            className="sm:w-auto sm:ml-3"
          >
            {t('適用')}
          </FormButton>
        )}
        <FormButton
          id="cancelCreateProject"
          className="py-1 mr-0 sm:w-auto sm:py-2"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
