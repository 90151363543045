import { Switch } from '@headlessui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { classNames } from '@/libs/styleUtils';

export interface ToggleValue {
  property?: string;
  idx?: number;
  value: boolean;
}
export interface IToggleProps {
  idx?: number;
  property?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  ref?: React.LegacyRef<HTMLButtonElement>;
  defaultValue?: boolean;
  center?: boolean;
  onChange?: (t: ToggleValue) => void;
}

export default function Toggle(props: IToggleProps) {
  const {
    idx,
    property,
    label,
    name,
    className,
    disabled,
    ref,
    defaultValue,
    center,
    onChange,
  } = props;
  const { setValue, trigger } = useFormContext();

  // defaultValueをもとにsetValueを使い自前で操作する
  const defValue = useMemo(() => defaultValue ?? false, [defaultValue]);
  const [enabled, setEnabled] = useState(defValue);

  const fieldName = useMemo(() => {
    if (idx === undefined) {
      return name;
    }
    if (!property) {
      return `${name}[${idx}]`;
    }

    return `${name}[${idx}].${property}`;
  }, [name, idx, property]);

  // マウント時に初期化
  useEffect(() => {
    setValue(fieldName, defValue);
    setEnabled(defValue);
  }, [fieldName, defValue]);

  return (
    <div className={classNames(className)}>
      <Switch.Group>
        <div
          className={classNames(
            'flex items-center',
            center ? 'justify-center' : 'justify-between',
          )}
        >
          {label && (
            <Switch.Label className="block font-medium text-gray-700 dark:text-gray-400">
              {label}
            </Switch.Label>
          )}
          <Switch
            id={fieldName}
            ref={ref}
            checked={enabled}
            disabled={disabled || false}
            onChange={(e) => {
              if (onChange && typeof onChange === 'function') {
                onChange({ property, idx, value: e });
              }
              setValue(fieldName, e, { shouldDirty: true });
              setEnabled(e);
              trigger();
            }}
            className={classNames(
              enabled
                ? 'bg-primary-500 dark:bg-primary-600'
                : 'bg-gray-400 dark:bg-gray-500',
              'ml-2 relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              )}
            />
          </Switch>
        </div>
      </Switch.Group>
    </div>
  );
}
