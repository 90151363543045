// import { View } from '@/@types/models';
import { View } from '@/@types/models';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RelativePortal from 'react-relative-portal';
import { Link } from 'react-router-dom';

import { classNames } from '@/libs/styleUtils';

import { getNavigatePath } from '@/libs/utils';

import ConfirmDialog from '@/components/Common/ConfirmDialog';

import EllipsisText from '@/components/Common/EllipsisText';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';
import EditViewNameDialog from '@/components/View/EditViewNameDialog';

import NewViewDialog from '@/components/View/NewViewDialog';

import useJoinType from '@/hooks/useJoinType';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useViews from '@/hooks/useViews';

export default function ViewSelect() {
  const { t } = useTranslation();
  const [newViewDialogOpen, setNewViewDialogOpen] = useState<boolean>(false);
  const [editViewNameDialogOpen, setEditViewNameDialogOpen] =
    useState<boolean>(false);
  const [deleteViewDialogOpen, setDeleteViewDialogOpen] =
    useState<boolean>(false);
  const { currentView, deleteView } = useViews();
  const { isMember, currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();

  const { projectsByJoinType, viewsByJoinType } = useJoinType();

  const projects = useMemo(() => projectsByJoinType, [projectsByJoinType]);
  const currentMyProjectData = useMemo(
    () => projects.find((p) => p.id === currentMyProject?.id) || null,
    [projects, currentMyProject?.id],
  );

  const views = useMemo(() => viewsByJoinType, [viewsByJoinType]);

  // ビュー削除確認ダイアログ
  const handleDeleteViewConfirm = useCallback(
    async (deleteFlg: boolean | undefined) => {
      if (deleteFlg && currentView && currentMyProjectData) {
        await deleteView(currentView?.id, currentMyProjectData?.id);
      }
      setDeleteViewDialogOpen(false);
    },
    [setDeleteViewDialogOpen, currentView, currentMyProjectData],
  );

  // カレントプロジェクトのviews
  const currentProjectViews = useMemo(
    () => views.filter((v) => v._projectId === currentMyProjectData?.id),
    [views, currentMyProjectData?.id],
  );

  // ビュー削除可否のフラグ
  const canDeleteView = useMemo(
    () => currentProjectViews.length > 1,
    [currentProjectViews],
  );

  // ビュー選択プルダウンメニュー
  const viewMenu = useMemo(
    () => (
      <Menu.Items
        static
        className="sticky left-0 z-10 w-64 mt-2 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none"
      >
        <div className="py-1 overflow-auto max-h-36 md:max-h-48">
          {currentProjectViews.map((v) => (
            <Menu.Item key={v.id}>
              {({ active }) => (
                <Link
                  to={getNavigatePath(
                    currentMyWorkspace?.originalWorkspaceId as string,
                    currentMyProjectData?.id,
                    v.id,
                  )}
                  className={classNames(
                    active || currentView?.id === v.id
                      ? 'bg-primary-500 dark:bg-primary-600 text-white'
                      : '',
                    'block px-4 py-2',
                  )}
                >
                  <div className="truncate">{v.viewName}</div>
                </Link>
              )}
            </Menu.Item>
          ))}
        </div>
        {/* 以下はメンバーのみ操作可能 */}
        {isMember && (
          <>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setNewViewDialogOpen(true);
                    }}
                    className={classNames(
                      active
                        ? 'bg-primary-500 dark:bg-primary-600 text-white'
                        : '',
                      'px-4 py-2 flex items-center justify-start w-full text-left',
                    )}
                  >
                    <PlusIcon className="w-4 h-4" aria-hidden="true" />
                    <div className="ml-2">{t('ビューを追加')}</div>
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditViewNameDialogOpen(true);
                    }}
                    className={classNames(
                      active
                        ? 'bg-primary-500 dark:bg-primary-600 text-white'
                        : '',
                      'block px-4 py-2 w-full text-left',
                    )}
                  >
                    {t('選択中のビュー名を変更')}
                  </button>
                )}
              </Menu.Item>
              {canDeleteView && (
                <Menu.Item>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        // ビューが削除可能の場合はダイアログを表示する
                        setDeleteViewDialogOpen(canDeleteView);
                      }}
                      className={classNames(
                        active
                          ? 'bg-red-400 dark:bg-red-500 text-white dark:text-white'
                          : '',
                        'w-full block px-4 py-2 text-left',
                      )}
                    >
                      {t('選択中のビューを削除')}
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
          </>
        )}
      </Menu.Items>
    ),
    [
      currentProjectViews,
      currentMyWorkspace,
      currentMyProjectData,
      isMember,
      canDeleteView,
      currentView,
    ],
  );

  return (
    <>
      {/** プルダウン表示 */}
      <Menu as="div" className="relative inline-block text-left lg:hidden">
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="inline-flex items-center justify-center w-full max-w-md px-4 py-2 font-medium text-gray-700 bg-white border border-gray-300 rounded-md dark:border-gray-600 shadow-sm dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600">
                <div className="flex-none hidden mr-2 text-sm font-bold text-gray-400 sm:block dark:text-gray-500">
                  {t('ビュー')} :
                </div>
                <div className="flex-1 truncate">{currentView?.viewName}</div>
                <ChevronDownIcon
                  className="w-4 h-4 ml-2 -mr-1"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <RelativePortal component="div" left={0}>
              <PopupTransition show={open}>{viewMenu}</PopupTransition>
            </RelativePortal>
          </>
        )}
      </Menu>
      {/** タブ表示 */}
      <div className="hidden w-full lg:block">
        <div className="relative w-full border-b-2 border-gray-100 dark:border-gray-700">
          <nav className="flex -mb-px min-w-max space-x-6" aria-label="Tabs">
            {currentProjectViews.map((v) => (
              <Link
                key={v.id}
                to={getNavigatePath(
                  currentMyWorkspace?.originalWorkspaceId as string,
                  currentMyProjectData?.id,
                  v.id,
                )}
                className={classNames(
                  currentView?.id === v.id
                    ? 'border-primary-500 dark:border-primary-600'
                    : 'border-transparent text-gray-500 hover:text-gray-400 dark:hover:border-primary-600 hover:border-primary-500',
                  'whitespace-nowrap pb-2 px-1 border-b-2 flex items-center',
                )}
                aria-current={currentView?.id === v.id ? 'page' : undefined}
              >
                <EllipsisText className="flex-1 max-w-[15rem]">
                  {v.viewName}
                </EllipsisText>
                {isMember && currentView?.id === v.id && (
                  <Menu
                    as="div"
                    className={classNames(
                      'relative inline-block text-left ml-1',
                    )}
                  >
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button
                            as="div"
                            className={classNames(
                              'flex items-center p-1 text-gray-400 rounded-md hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500',
                            )}
                          >
                            <EllipsisVerticalIcon
                              className="w-4 h-4"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <RelativePortal component="div" right={0}>
                          <PopupTransition show={open}>
                            <Menu.Items
                              static
                              className="absolute right-0 z-20 w-40 mt-2 text-gray-800 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 dark:divide-gray-600 dark:text-gray-100"
                            >
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setEditViewNameDialogOpen(true);
                                      }}
                                      className={classNames(
                                        active
                                          ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                          : '',
                                        'w-full block px-4 py-2 text-left',
                                      )}
                                    >
                                      {t('ビュー名を変更')}
                                    </button>
                                  )}
                                </Menu.Item>
                                {canDeleteView && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        type="button"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          // ビューが削除可能の場合はダイアログを表示する
                                          setDeleteViewDialogOpen(
                                            canDeleteView,
                                          );
                                        }}
                                        className={classNames(
                                          active
                                            ? 'bg-red-400 dark:bg-red-500 text-white dark:text-white'
                                            : '',
                                          'w-full block px-4 py-2 text-left',
                                        )}
                                      >
                                        {t('ビューを削除')}
                                      </button>
                                    )}
                                  </Menu.Item>
                                )}
                              </div>
                            </Menu.Items>
                          </PopupTransition>
                        </RelativePortal>
                      </>
                    )}
                  </Menu>
                )}
              </Link>
            ))}
          </nav>
          <div className="absolute top-0 right-0 flex pl-2 bg-white/90 dark:bg-gray-800/90">
            <Menu as="div" className=" mr-1">
              {({ open }) => (
                <>
                  <Menu.Button
                    className={classNames(
                      'text-gray-400 hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600 ',
                      'inline-flex justify-center p-1',
                      'items-center border border-transparent rounded-md block',
                    )}
                  >
                    <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
                  </Menu.Button>
                  <RelativePortal component="div" right={0}>
                    <PopupTransition show={open}>{viewMenu}</PopupTransition>
                  </RelativePortal>
                </>
              )}
            </Menu>
          </div>
        </div>
      </div>

      {currentMyProjectData && (
        <Transition.Root show={newViewDialogOpen} unmount>
          <NewViewDialog
            myProject={currentMyProjectData}
            onClose={() => setNewViewDialogOpen(false)}
          />
        </Transition.Root>
      )}
      {currentMyProjectData && currentView && (
        <Transition.Root show={editViewNameDialogOpen} unmount>
          <EditViewNameDialog
            project={currentMyProjectData}
            view={currentView as View}
            onClose={() => setEditViewNameDialogOpen(false)}
          />
        </Transition.Root>
      )}
      <ConfirmDialog
        open={deleteViewDialogOpen}
        onClose={handleDeleteViewConfirm}
        title={t('ビューを削除')}
        positive={t('削除')}
        warning
      >
        <p className="mb-2">
          {t('<name> を削除しようとしています。', {
            name: (
              <span className="mr-1 font-bold">{currentView?.viewName}</span>
            ),
          })}
        </p>
        <p>
          {t('削除されたビューは復元できません。<br> 本当によろしいですか？', {
            br: <br />,
          })}
        </p>
      </ConfirmDialog>
    </>
  );
}
