import { MemberGuestViewItem, Status } from '@/@types/models';
import { IViewRow } from '@/@types/viewItem';

import lodash from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IMoveResult } from '@/libs/dndUtils';

import useItemOrders from './useItemOrders';

export interface IUseViewRowsArgs {
  viewItems: MemberGuestViewItem[];
  orderList: string[];
  statuses: Status[];
}

export default function useViewRows(args: IUseViewRowsArgs) {
  const { viewItems, orderList, statuses } = args;
  const { getNewItemOrders } = useItemOrders();

  // DNDでのステータス更新時に同期的に更新できるよう items,itemOrdersのスナップショットを取得する
  const [itemsSnapshot, setItemsSnapshot] = useState<MemberGuestViewItem[]>([]);
  const [itemsOrderSnapshot, setItemsOrderSnapshot] = useState<string[]>([]);

  useEffect(() => {
    setItemsOrderSnapshot(lodash.cloneDeep(orderList));
  }, [orderList]);

  useEffect(() => {
    setItemsSnapshot(lodash.cloneDeep(viewItems));
  }, [viewItems]);

  // ステータスごとの単位(row)にグループ化（ソート込み）
  const viewRows: IViewRow[] = useMemo(() => {
    const ret = Object.values(statuses).reduce<IViewRow[]>((acc, s) => {
      const sortedItems = itemsOrderSnapshot.reduce<MemberGuestViewItem[]>(
        (acc2, cur) => {
          const val = itemsSnapshot.find(
            (v) => v.id === cur && v.statusId === s.id,
          );
          if (val) {
            acc2.push(val);
          }
          return acc2;
        },
        [],
      );

      acc.push({
        status: s,
        viewItems: sortedItems,
      });

      return acc;
    }, []);

    return ret;
  }, [statuses, itemsOrderSnapshot, itemsSnapshot]);

  // D&Dの結果からローカルで管理するitemsのスナップショットを更新する処理
  const onChangeRowItem = useCallback(
    (moveResult: IMoveResult) => {
      const { statusId, item: targetItem, position } = moveResult;

      // itemsOrderのsnapshot更新
      setItemsOrderSnapshot(() => {
        const orders = getNewItemOrders(targetItem.id, position);
        return orders;
      });

      // itemsのsnapshotを更新
      setItemsSnapshot((oldItems) => {
        const _items: MemberGuestViewItem[] = lodash.cloneDeep(oldItems);
        const targetIndex = _items.findIndex(
          (item) => item.id === targetItem.id,
        );
        _items.splice(targetIndex, 1, { ...targetItem, statusId });
        return _items;
      });
    },
    [setItemsSnapshot, setItemsOrderSnapshot, getNewItemOrders],
  );

  return {
    viewRows,
    onChangeRowItem,
  };
}
