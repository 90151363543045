import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DayjsUtil, FormatType } from '@/libs/dayjs';

import { SpinnerCircleIcon } from '@/components/Common/Icon/Icons';
import Logo from '@/components/Common/Logo';
import LogoTitle from '@/components/Common/LogoTitle';

export interface IUpdateInProgressProps {
  workCompletionDate?: Date;
}

/**
 * アップデート中画面
 * @param props
 * @returns
 */
export default function UpdateInProgress(props: IUpdateInProgressProps) {
  const { t } = useTranslation();
  const { workCompletionDate } = props;

  const formatWorkCompletionDate = useMemo(() => {
    if (!workCompletionDate) return null;
    const d = new DayjsUtil(workCompletionDate);
    return d.format(t('YYYY-MM-DD HH-mm') as FormatType);
  }, [workCompletionDate]);

  // アップデート表示中にアップデートが完了したらリロードする
  useEffect(
    () => () => {
      window.location.reload();
    },
    [],
  );

  return (
    <div className="flex flex-col min-h-full pt-16 pb-12 bg-white dark:bg-gray-800">
      <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex items-center justify-center sm:mx-auto sm:w-full sm:max-w-md">
          <Logo className="w-auto login-logo h-[4.125rem] mr-[1.1875rem]" />
          <LogoTitle className="w-auto h-8 " />
        </div>
        <div className="py-20">
          <div className="text-center">
            <h1 className="flex flex-wrap items-center justify-center text-xl font-bold tracking-tight text-gray-500 space-y-6 sm:space-y-0 sm:space-x-4 sm:flex-nowrap dark:text-gray-400 sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl">
              <div className="w-full sm:w-auto">
                {t('ただいまシステムのアップデート中です<dots>', {
                  dots: '...',
                })}
              </div>
              <div className="rounded-full bg-gray-50 ring-gray-50 ring-2 dark:ring-0 dark:bg-transparent">
                <SpinnerCircleIcon className="w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7  lg:w-8 lg:h-8  xl:w-10 xl:h-10" />
              </div>
            </h1>
            <p className="mt-6 text-sm text-gray-500 dark:text-gray-400 sm:mt-8 md:text-base">
              {t(
                '大変ご迷惑をおかけしております。<br>アップデート終了は <workCompletionDate> を予定しています。',
                {
                  br: <br />,
                  workCompletionDate: <b>{formatWorkCompletionDate}</b>,
                },
              )}{' '}
            </p>
            <p className="mt-6 text-xs text-gray-400 dark:text-gray-500">
              {t('この画面はアップデート後に自動的に再読み込みされます')}
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
