import { Nullish } from '@/@types/common';
import { EV, InputInterface } from '@/@types/events';
import {
  Property,
  SystemFields,
  Auth,
  MyWorkspace,
  RRFUploadedFile,
  MultiSelectArrayValue,
  NumberPropertyValue,
  IPropertyFormatType,
  MemberGuestViewItem,
} from '@/@types/models';
import { PropertyTypes } from '@/@types/viewItem';
import axios, { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import firebase from 'firebase/compat/app';
import {
  QueryDocumentSnapshot,
  serverTimestamp,
  Timestamp,
} from 'firebase/firestore';
import { getDownloadURL } from 'firebase/storage';

import { DefaultTFuncReturn } from 'i18next';
import { intersection } from 'lodash';
import MaskData from 'maskdata';
import { UseFormReturn } from 'react-hook-form';
import { ExtendedFirebaseInstance } from 'react-redux-firebase';

import SecureLS from 'secure-ls';

import { z } from 'zod';

import { getPropertyValueFilesPath } from '@/libs/docPathUtils';

import { BODY_IMAGE_FILES } from '@/libs/validations';

import { auth } from '@/firebase';
import { generateDocId } from '@/firestore';

import {
  ENV,
  FILE_UPLOAD_RETRY,
  MASKING_OPTIONS,
  NUMBER_PROPERTY_FORMAT_TYPE,
  PROPERTY_TYPE,
  TEMP_FILE_EXPIRED_DAYS,
} from './const';
import { DayjsUtil } from './dayjs';

const ls = new SecureLS({ isCompression: false });

/**
 * localStorageから保存値を取得する
 * @param key
 * @returns
 */
export const getLocalStorageInfo = <T>(key: string): T => ls.get(key);

/**
 * localStorageに値を保存する
 * @param key
 * @returns
 */
export const setLocalStorageInfo = <T>(key: string, value: T) => {
  ls.set(key, value);
};

/**
 * Select, Inputの入力値 | 空文字を取得する
 */
export const unwrapEV = (event: EV<InputInterface>) =>
  event?.target?.value ?? '';

/**
 * NaNにパースされたら0を返す
 */
export const safeParseInt = (value: string): number => {
  const intVal = parseInt(value, 10);
  if (Number.isNaN(intVal)) return 0;
  return intVal;
};

/**
 * 実装漏れを型エラーで検知
 * @param  {never} _x
 * @param  {string} errorMessage
 * @returns any
 * @link https://qiita.com/sonishimura/items/b7b36b95686b83ec36f4
 */
export const assertNever = (
  _x: never,
  errorMessage: string = 'Type Error',
): any => {
  throw new Error(errorMessage);
};

/**
 * 数値を省略表示へ変換
 * @param {number} value
 * @param {number} minInput
 * @returns string
 */
export const commarize = (value: number, minInput?: number): string => {
  const min = minInput || 1e3;
  // 最小値（デフォルトで1000）より大きければ省略表記に変換
  if (value < min) {
    return value.toString();
  }
  const units = ['k+', 'M+', 'B+', 'T+'];
  const order = Math.floor(Math.log(value) / Math.log(1000));
  const unitName = units[order - 1];
  const num = Math.floor(value / 1000 ** order);

  return num + unitName;
};

/**
 * t()でPostprocessorを使う際に返り値を文字列にする
 * @param o
 * @returns
 */
export const t2s = (o: DefaultTFuncReturn) =>
  Array.isArray(o) ? o.join('') : o?.toString();

/**
 * フォームの送信可否を取得する
 * @param formMethods
 * @returns true:送信可, false: 送信不可
 */
export const getSubmitState = (formMethods: UseFormReturn<any>) =>
  formMethods.formState.isValid &&
  formMethods.formState.isDirty &&
  !formMethods.formState.isSubmitting;

/**
 * Repository用エラーハンドリング
 * @param name:関数名
 * @param err:エラーオブジェクト
 */
export const repositoryError = (name: string, err: any) => {
  if ([ENV.DEV, ENV.STG].includes(import.meta.env.VITE_NODE_ENV)) {
    // eslint-disable-next-line no-console
    console.error(name, err?.message);
  }
};

/**
 * グループ化関数
 * @param  {V[]} array
 * @param  {(cur:V) => K} getKey
 * @returns Array<[K, V[]]>
 * @example
 * const array = [
 *  {name: 'pottos', subject: 'Math', score: 10},
 *  {name: 'pottos', subject: 'English', score: 40},
 *  {name: 'ND', subject: 'Math', score: 30},
 *  {name: 'ND', subject: 'English', score: 50},
 * ];
 *
 * const grouped = groupBy(array, row => row.name);
 * // [["pottos",[{"name":"pottos","subject":"Math","score":10},{"name":"pottos","subject":"English","score":40}]],["ND",[{"name":"ND","subject":"Math","score":30},{"name":"ND","subject":"English","score":50}]]]
 */
export const groupBy = <K, V>(
  array: readonly V[],
  getKey: (cur: V) => K,
): Array<[K, V[]]> =>
  Array.from(
    array.reduce((map, cur) => {
      const key = getKey(cur);
      const list = map.get(key);
      if (typeof list !== 'undefined') {
        list.push(cur);
      } else {
        map.set(key, [cur]);
      }
      return map;
    }, new Map<K, V[]>()),
  );

/**
 * zod文字列共通ルール(nullはnullのまま返す)
 * @param zodRule
 * @returns
 */
export const zodString = (zodRule: z.ZodTypeAny) =>
  z.preprocess((val) => {
    if (val === null) return val;
    return String(val).trim();
  }, zodRule);

/**
 * Emailアドレスのマスキング
 * @param email
 * @returns
 */
export const maskEmail = (email: string | null) => {
  if (!email) return email;
  return MaskData.maskEmail2(email, MASKING_OPTIONS.EMAIL);
};

/**
 * Firestore converter
 * @returns
 */
export const fsConverter = <T>() => ({
  // NOTE:toFirestore: (data: Partial<T>) => data,で曖昧にすることもできる
  toFirestore: (data: T) => data,
  fromFirestore: (snap: QueryDocumentSnapshot) => snap.data() as T,
});

/**
 * firestore timestamp -> Dateへの変換関数
 * @returns Date
 */
export const parseFsTimestamp = (timestamp: Nullish<Timestamp>): Date => {
  if (!timestamp) return new Date();
  return timestamp.toDate();
};

/**
 * Eメールアドレスのアカウント部分を取得
 * @param email
 * @returns
 */
export const getEmailAccount = (email: string): string => email.split('@')[0];

/**
 * Ctrl || Commandキー押下の判定
 * キーバインドの判定がもし増えてくるようなら別ファイルなどに切り出す
 */
export const isHoldingCtrlOrCmd = (event: KeyboardEvent) => {
  const isHoldingCtrl = event.ctrlKey;
  const isHoldingCommand = event.metaKey;
  return isHoldingCtrl || isHoldingCommand;
};

/**
 * ワークスペース/プロジェクト/ビューのナビゲーション用パス生成関数
 * @param wId
 * @param pId
 * @param vId
 * @param iId
 * @returns string
 */
export const getNavigatePath = (
  wId: string,
  pId?: string,
  vId?: string,
  iId?: string,
) => {
  let p = `/${wId}`;
  if (pId) p += `/p/${pId}`;
  if (vId) p += `/v/${vId}`;
  if (iId) p += `/i/${iId}`;
  return p;
};

/**
 * Created, Updated のシステムフィールドを取得
 * @param uid
 * @returns
 */
export const getFullSystemFields = (uid: string): SystemFields => ({
  sysCreatedAt: serverTimestamp(),
  sysUpdatedAt: serverTimestamp(),
  sysCreatedBy: uid,
  sysUpdatedBy: uid,
});

/**
 * Updated のシステムフィールドを取得
 * @param uid
 * @returns
 */
export const getUpdateSystemFields = (uid: string): SystemFields => {
  const f = getFullSystemFields(uid);
  delete f.sysCreatedAt;
  delete f.sysCreatedBy;
  return f;
};

/**
 * オブジェクトのnull|undefinedなキーを除外して返す
 * @param target
 * @returns
 */
export const objectDropNullish = <T extends Record<keyof any, keyof any>>(
  target: T,
) =>
  Object.entries(target).reduce((acc, [key, value]) => {
    if (value === undefined || value === null) return acc;
    acc[key] = value;
    return acc;
  }, {} as { [key: string]: any });

/**
 * ポップアップウィンドウを開く
 * @param url
 */
export const openPopupWindow = (
  url: string,
  windowName?: string,
  width?: number,
  height?: number,
) => {
  const w = width || 800;
  const h = height || 600;
  const posX = (window.outerWidth - w) / 2 + window.screenX;
  const posY = (window.outerHeight - h) / 2 + window.screenY;
  window.open(
    url,
    windowName || 'mediaPreview',
    `width=${w},height=${h},top=${posY},left=${posX},scrollbars=1,resizable=1,status=1`,
  );
};

/**
 * ファイルをダウンロードする
 * @param url
 * @param name
 */
export const downloadUrl = (url: string, name: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.download = name;
  a.href = url;
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
};

/**
 * タグ付けデータ型から個別のタグ型を取り出す型
 */
export type Individual<
  T extends Record<'propertyType', any>,
  Tag extends T['propertyType'],
> = Extract<T, Record<'propertyType', Tag>>;

/**
 * プロパティ等パターン網羅関数
 * @param value
 * @returns
 */
export function match<T extends Record<'propertyType', any>, TOut = T>(
  value: T,
) {
  return (patterns: {
    [K in T['propertyType']]: (param: Individual<T, K>) => TOut;
  }): TOut => {
    const tag: T['propertyType'] = value.propertyType;
    return patterns[tag](value as any);
  };
}

/**
 * プロパティ等パターン網羅関数
 * @param value
 * @returns
 */
export function revMatch<
  T extends Record<'propertyType', any>, // 第一引数の型
  U extends Record<'propertyType', any>, // 第二引数でパターンマッチする型
  TOut = U, // 出力結果として推論したい方(デフォルトは第二引数の型)
>(value: T) {
  return (patterns: {
    [K in U['propertyType']]: (
      param: T & Record<'propertyType', K>,
    ) => TOut extends never ? Individual<U, K> : TOut;
  }): TOut extends never ? U : TOut => {
    const tag: T['propertyType'] = value.propertyType;
    return patterns[tag](value as any);
  };
}

/**
 * ビューに表示するプロパティを整形する
 * @param properties
 * @param propertyValues
 * @param limit
 * @returns
 */
export const reduceProperties = (
  workspaceId: string,
  projectId: string,
  itemId: string,
  properties: Property[],
  viewItem: MemberGuestViewItem,
  propertyOrderList: string[],
  isMember: boolean,
): PropertyTypes[] => {
  // ゲスト権限は公開されているプロパティのみ}
  const viewPropertyOrderList = isMember
    ? propertyOrderList
    : (intersection(
        propertyOrderList,
        properties.filter((p) => p.isShare).map((v) => v.id),
      ) as string[]);
  const viewProps = viewPropertyOrderList.flatMap((o) => {
    const pv = viewItem.propertyValues[o];
    const pr = properties.find((d) => d.id === o);

    if (!pr) {
      return {
        id: '',
        type: null,
        value: null,
      };
    }

    // ※ プロパティパターンマッチ
    return match<Property, PropertyTypes | []>(pr as Property)({
      text: () => {
        if (pv && pv.propertyType === PROPERTY_TYPE.TEXT) {
          return {
            id: o,
            type: PROPERTY_TYPE.TEXT,
            value: pv.value,
          };
        }
        return {
          // ブランク
          id: o,
          type: PROPERTY_TYPE.TEXT,
          value: '',
        };
      },
      file: () => {
        if (pv && pv.propertyType === PROPERTY_TYPE.FILE) {
          return {
            id: o,
            type: PROPERTY_TYPE.FILE,
            value: (pv.value || []).map((value) => ({
              ...value,
              path: `${getPropertyValueFilesPath(
                workspaceId,
                projectId,
                itemId,
                o,
              )}/${value.path}`,
            })),
          };
        }
        return {
          // ブランク
          id: o,
          type: PROPERTY_TYPE.FILE,
          value: [],
        };
      },
      singleSelect: (data) => {
        const selectOption = data.options.find(
          (d: any) =>
            pv?.propertyType === PROPERTY_TYPE.SINGLE_SELECT &&
            d.id === pv.value,
        );
        if (selectOption) {
          return {
            id: pr?.id as string,
            type: PROPERTY_TYPE.SINGLE_SELECT,
            value: selectOption.text,
            color: selectOption.color,
          };
        }
        return {
          // ブランク
          id: pr?.id as string,
          type: PROPERTY_TYPE.SINGLE_SELECT,
          value: '',
          color: 'originalLightGray',
        };
      },
      multiSelect: (data) => {
        if (pv && pv.propertyType === PROPERTY_TYPE.MULTI_SELECT) {
          const selectOptions = (pv.value as MultiSelectArrayValue[]).flatMap(
            (p) => data.options.filter((option) => option.id === p.stringValue),
          );
          const result = {
            id: pr?.id as string,
            type: PROPERTY_TYPE.MULTI_SELECT,
            value: selectOptions.map((x) => x.text),
            color: selectOptions.map((x) => x.color),
          };
          return result;
        }
        return {
          // ブランク
          id: pr?.id as string,
          type: PROPERTY_TYPE.MULTI_SELECT,
          value: [],
          color: [],
        };
      },
      checkbox: () => {
        if (pv && pv.propertyType === PROPERTY_TYPE.CHECKBOX) {
          return {
            id: o,
            type: PROPERTY_TYPE.CHECKBOX,
            value: pv.value || false,
            propertyName: pr.propertyName,
          };
        }
        return {
          // ブランク
          id: o,
          type: PROPERTY_TYPE.CHECKBOX,
          value: false,
          propertyName: pr.propertyName,
        };
      },
      incharge: () => {
        if (pv && pv.propertyType === PROPERTY_TYPE.INCHARGE) {
          return {
            id: o,
            type: PROPERTY_TYPE.INCHARGE,
            value: pv.value || [],
          };
        }
        return {
          id: o,
          type: PROPERTY_TYPE.INCHARGE,
          value: [],
        };
      },
      date: () => {
        if (pv && pv.propertyType === PROPERTY_TYPE.DATE) {
          return {
            id: o,
            type: PROPERTY_TYPE.DATE,
            value: pv.value || null,
            isDueDate: !!pv.isDueDate,
          };
        }
        return {
          // ブランク
          id: o,
          type: PROPERTY_TYPE.DATE,
          value: null,
          isDueDate: false,
        };
      },
      number: (data) => {
        if (pv && pv.propertyType === PROPERTY_TYPE.NUMBER) {
          return {
            id: o,
            type: PROPERTY_TYPE.NUMBER,
            value: pv.value || null,
            format: data.format,
          };
        }
        return {
          // ブランク
          id: o,
          type: PROPERTY_TYPE.NUMBER,
          value: null,
          format: 'float',
        };
      },
    });
  });
  return viewProps;
};

/**
 * Promiseベースのタイムアウト処理
 * @param timeout
 * @returns
 */
export const promisedSleep = (timeout = 1000): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });

// IDはRRFの戻り値のFileオブジェクトには存在しない
type RRFUploadFileResult = Record<'File', Omit<RRFUploadedFile, 'id'>>;

/**
 * ファイルアップロード処理
 * @param files
 * @param filesPath
 * @param rrfAuth
 * @param firebase
 * @returns
 */
export const uploadSelectFiles = async (
  files: File[],
  filesPath: string,
  rrfAuth: Auth,
  firebaseInstance: ExtendedFirebaseInstance,
): Promise<RRFUploadFileResult[]> => {
  // ※ 日本語ファイル名だとFirebaseがクラッシュするのでファイル名を変更
  const tmpMap: { [key: string]: string } = {};
  const uploadFiles: File[] = [];
  files.forEach((file: File) => {
    // functionで処理する際に余計なファイルを参照しないようにuidをprefixにする
    const tmpName = `${rrfAuth.uid}-${generateDocId(filesPath)}`;
    const newFile = new File([file], tmpName, { type: file.type });
    tmpMap[tmpName] = file.name;
    uploadFiles.push(newFile);
  });

  let retryCount = 0;
  const fn = async (): Promise<RRFUploadFileResult[]> => {
    try {
      const res = await firebaseInstance.uploadFiles(
        filesPath,
        uploadFiles,
        filesPath,
        {
          // ※ 型としては`StorageTypes.UploadTaskSnapshot`が正しいようだが実際に取得できるデータと合致しないのでanyにしている
          metadataFactory: (
            uploadRes: any,
            _1: any,
            _2: any,
            downloadURL: string,
          ) => {
            const {
              metadata: { name },
            } = uploadRes;
            const fileName = tmpMap[name]; // 元のファイル名を復元してメタデータに保持
            const dayjsUtil = new DayjsUtil();
            return {
              ...objectDropNullish(uploadRes.metadata),
              name: fileName,
              downloadURL,
              expiredDate: dayjsUtil.shiftDay(TEMP_FILE_EXPIRED_DAYS), // TTL用期限日
            };
          },
        },
      );

      return res as unknown as RRFUploadFileResult[];
    } catch (error) {
      if (retryCount >= FILE_UPLOAD_RETRY.COUNT) throw error;

      await promisedSleep(FILE_UPLOAD_RETRY.DELAY * retryCount);
      await auth.currentUser?.getIdToken(true);
      retryCount += 1;
      return fn();
    }
  };

  const results = await fn();
  return results;
};

/**
 * メディアタイプを取得する
 * @param contentType
 * @returns
 */
export const getMediaType = (contentType: string) => {
  if (contentType.startsWith('image/')) return 'image';
  if (contentType.startsWith('video/')) return 'video';
  return null;
};

/**
 * 第二引数の文字列で区切った最後の要素を返す(ファイル名の取得等)
 * @param target
 * @returns
 */
export const getLastSplittedElement = (target: string, by: string) =>
  target.split(by).slice(-1)[0];

/**
 * member以外の場合、myWorkspaceをisShare=trueのもののみフィルタリング
 * @param timeout
 * @returns
 */
export const filterMyWorkspaces = (mws: MyWorkspace[] | undefined) => {
  if (!mws) return [];
  return mws.filter(
    (o) => !(o.joinType !== 'member' && !o.isShare), // joinTypeがmember以外ではisShareがfalseのデータは省く
  );
};

/**
 * 数値プロパティ値をフォーマット
 * @param value
 * @param format
 * @returns
 */
export const formatNumberProperty = (
  value: NumberPropertyValue['numberValue'] | null,
  format: IPropertyFormatType | null,
) => {
  // nullの場合は非表示
  if (!value) return null;
  // パーセント
  if (format === NUMBER_PROPERTY_FORMAT_TYPE.PERCENT) {
    return `${value}%`;
  }
  // 円（通貨）
  if (format === NUMBER_PROPERTY_FORMAT_TYPE.YEN) {
    return `¥${value.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    })}`;
  }
  // 数値
  return value;
};

/**
 * 全角英数字を半角英数字へ変換
 * @param str
 * @returns
 */
export const zenkakuEisu2HankakuEisu = (str: any) =>
  str
    .toString()
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: any) =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0),
    );

/**
 * ドメインまでのベースURLを取得する
 * @returns
 */
export const getBaseURL = () =>
  `${window.location.protocol}//${document.domain}${
    import.meta.env.VITE_NODE_ENV === ENV.DEV ? `:${window.location.port}` : ''
  }`;

/**
 * ドメインまでのベースURLをhttps(SSL)プロトコルで取得
 * @returns
 */
export const getTSLBaseUrl = () => `https://${document.domain}`;

/**
 * http functionのprefixを取得
 */
export const getFunctionPrefix = () => {
  const REGION = 'asia-northeast1';
  const FUNCTION = 'cloudfunctions.net';
  if (import.meta.env.VITE_NODE_ENV === ENV.DEV) {
    // 開発環境の場合
    return `http://localhost:5001/${
      import.meta.env.VITE_FIREBASE_PROJECT_ID
    }/${REGION}/`;
  }
  // 検証・本番環境（URLはGCPで確認したが動作未確認）
  return `https://${REGION}-${
    import.meta.env.VITE_FIREBASE_PROJECT_ID
  }.${FUNCTION}/`;
};

/**
 * headerからファイル名取得
 * @param contentDisposition
 * @returns
 */
const getFileName = (contentDisposition: string) => {
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/; // 正規表現でfilenameを抜き出す
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    const fileName = matches[1].replace(/['"]/g, '');
    return decodeURI(fileName); // 日本語対応
  }
  return '';
};

/**
 * axiosで出力系のfunction呼び出し＋ダウンロード
 */
export const onDownloadFromFunction = async (
  funcName: string,
  body: any,
  token: string,
) => {
  axios.defaults.withCredentials = true;
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Bearer ${token}`,
  };

  const url = `${getFunctionPrefix()}${funcName}`;
  await axios
    .post(url, body, { headers, responseType: 'blob' })
    .then((res: AxiosResponse) => {
      if (res.status === 201) {
        throw res;
      }
      const mineType = res.headers['content-type'];
      const contentDisposition = res.headers['content-disposition'];
      const fileName = getFileName(contentDisposition as string);
      const blob = new Blob([res.data], { type: mineType });
      saveAs(blob, fileName);
    })
    .catch((e) => {
      // 多言語対応の関係でエラー処理は呼び出し元で行う
      throw e;
    });
};

/**
 * Storage のパスをURL変換する
 */
export const convertPath2URL = async (path: string) => {
  if (!path) return '';
  const storage = firebase.storage();
  const refs = storage.ref(path);
  const URL = await getDownloadURL(refs);
  return URL;
};

/**
 * 画像ファイルチェック
 */
export const isImageFile = async (f: File) => {
  // 拡張子チェック
  const ext = f.name.split('.').pop() || '';
  if (!f.type.startsWith('image') || !BODY_IMAGE_FILES.ext.test(ext))
    return false;
  // サイズチェックはアップロード時に行う
  // ファイルフォーマットチェック
  const arrayBuffer = await f.arrayBuffer();
  const uint8array = new Uint8Array(arrayBuffer).subarray(0, 4);
  const header = uint8array.reduce((prev, cur) => prev + cur.toString(16), '');
  if (!BODY_IMAGE_FILES.magicNumber.test(header)) return false;
  return true;
};

/**
 * バージョン表記を10進数へ変換
 * @param ver
 * @returns
 */
export const version2Num = (ver: string) =>
  parseInt(ver.replace(/[.-]/g, ''), 10);
