import { IJoinType } from '@/@types/models';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { Fragment } from 'react';
import { Link, useLocation, Location } from 'react-router-dom';

import { classNames } from '@/libs/styleUtils';

import PopupTransition from '@/components/Common/Transitions/PopupTransition';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

function getPathname(location: Location): string {
  const n = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1,
    location.pathname.length,
  );
  return n || 'account';
}

export interface INavigationItem {
  name: string;
  href: string;
  key: string;
  auths: IJoinType[];
}

export interface ISettingSelectProps {
  items: INavigationItem[];
}

export default function SettingSelect(props: ISettingSelectProps) {
  const { items } = props;
  const location = useLocation();
  const { currentMyWorkspace } = useMyWorkspaces();

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button className="inline-flex items-center justify-center w-full px-4 py-2 font-medium text-gray-700 bg-white border border-gray-300 rounded-md dark:border-gray-600 shadow-sm dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600">
            <div className="flex-none mr-2 text-sm font-bold text-gray-400 dark:text-gray-500">
              設定:
            </div>
            <div className="flex-1">
              {
                items.find((element) => getPathname(location) === element.key)
                  ?.name
              }
            </div>
            <ChevronDownIcon
              className="w-4 h-4 ml-2 -mr-1"
              aria-hidden="true"
            />
          </Menu.Button>

          <PopupTransition show={open}>
            <Menu.Items
              static
              className="absolute left-0  w-56 mt-2 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none"
            >
              <div className="z-50 py-1">
                {items.map((item) => (
                  <Menu.Item key={item.key}>
                    {({ active }) => (
                      <Link
                        to={`/${currentMyWorkspace?.originalWorkspaceId}/settings${item.href}`}
                        className={classNames(
                          active
                            ? 'bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-gray-200'
                            : 'text-gray-700 dark:text-gray-300',
                          'block px-4 py-2',
                        )}
                      >
                        {item.name}
                        {active}
                      </Link>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </PopupTransition>
        </>
      )}
    </Menu>
  );
}
