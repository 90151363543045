import * as React from 'react';

import { classNames } from '@/libs/styleUtils';

export interface ILoadingProps {
  className?: string;
}

/**
 * ローディング表示
 * @param props
 * @returns
 */
function Loading(props: ILoadingProps) {
  const { className } = props;

  return (
    <div
      className={classNames(
        className,
        'flex items-center justify-center dark:bg-gray-800',
      )}
      style={{ zIndex: 10000 }}
    >
      <div className="w-2 h-2 rounded-full bg-primary-500 dark:primary-600 animate-ping" />
      <div className="w-2 h-2 mx-6 rounded-full bg-primary-500 dark:primary-600 animate-ping" />
      <div className="w-2 h-2 rounded-full bg-primary-500 dark:primary-600 animate-ping" />
    </div>
  );
}
export default React.memo(Loading);
