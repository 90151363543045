import { MyProject } from '@/@types/models';
import { Menu, Transition } from '@headlessui/react';
import {
  SunIcon,
  MoonIcon,
  UsersIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import {
  LockClosedIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/24/solid';
import React, {
  memo,
  useState,
  useRef,
  useMemo,
  useEffect,
  Fragment,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import RelativePortal from 'react-relative-portal';
import ReactTooltip from 'react-tooltip';

import { JOIN_TYPE, MAX_PRESENCE_DISPLAY_COUNT, PLANS } from '@/libs/const';

import ModifyUnknownGuestNameDialog from '@/components/Accounts/ModifyUnknownGuestNameDialog';
import AppLinkageDialog from '@/components/AppLinkage/AppLinkageDialog';
import AvatarIcon from '@/components/Common/AvatarIcon';
import ContactDialog from '@/components/Common/ContactDialog';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import HeaderLogo from '@/components/Common/HeaderLogo';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';
import UpgradeTooltip from '@/components/GlobalHeader/UpgradeTooltip';
import ProjectDropdown from '@/components/GlobalNavigation/ProjectDropdown';

import AllCommentButton from '@/components/Items/Comments/All/AllComment';
import Description, {
  IDescriptionHandler,
} from '@/components/Project/Description';
import ShareDialog from '@/components/Share/ShareDialog';

import ShareSettingDialog from '@/components/Share/ShareSettingDialog';

import useFirebaseAuth from '@/hooks/useFirebaseAuth';
import useMentions from '@/hooks/useMentions';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePlans from '@/hooks/usePlans';
import usePresences from '@/hooks/usePresences';
import useProfiles from '@/hooks/useProfiles';
import useProjectDescriptions from '@/hooks/useProjectDescriptions';
import useProjects from '@/hooks/useProjects';
import useToggleTheme from '@/hooks/useToggleTheme';

export interface IProjectHeaderProps {
  name: string;
}

/**
 * ビュー画面上部のプロジェクトヘッダー
 * @param props
 * @returns ProjectHeader
 */
function ProjectHeader(props: IProjectHeaderProps) {
  const { t } = useTranslation();
  const { isDarkTheme } = useToggleTheme();
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const [shareSettingDialogOpen, setShareSettingDialogOpen] =
    useState<boolean>(false);
  const [appLinkageDialogOpen, setAppLinkageDialogOpen] =
    useState<boolean>(false);

  const { currentMyProject } = useProjects();
  const { currentMyWorkspace, isMember, isGuest, isUnknownGuest } =
    useMyWorkspaces();
  const { plan } = usePlans();
  const { profile } = useProfiles();
  const { logOut } = useFirebaseAuth();
  const { isEmptyDescription } = useProjectDescriptions();

  const [contactDialogOpen, setContactDialogOpen] = useState<boolean>(false);

  // UIテーマ切替用カスタムフック
  const { toggleTheme } = useToggleTheme();

  const { name } = props;

  const { presences } = usePresences();
  const { membersDic, guestsDic } = useMentions();

  const [changeNameOpen, setChangeNameOpen] = React.useState(false);

  const [isNameRegisteredUG, setIsNameRegisteredUG] = React.useState(false);
  React.useEffect(() => {
    if (currentMyWorkspace?.joinType !== JOIN_TYPE.UNKNOWN_GUEST) return;
    setIsNameRegisteredUG(profile?.isConfigured || false);
    if (!profile?.isConfigured) {
      setChangeNameOpen(true);
    }
  }, []);

  const onCloseModifyUnknownGuestNameDialog = () => {
    setChangeNameOpen(false);
    setTimeout(() => {
      setIsNameRegisteredUG(true);
    }, 300); // トランジションを待機
  };
  const publishSettingButtonRef = useRef<HTMLButtonElement>(null);
  const descriptionRef = useRef({} as IDescriptionHandler);

  useEffect(() => {
    if (shareSettingDialogOpen && publishSettingButtonRef.current) {
      publishSettingButtonRef.current.blur();
    }
  }, [shareSettingDialogOpen]);

  // プレゼンスアイコン用アカウント辞書
  const accountDic = useMemo(
    () => ({ ...membersDic, ...guestsDic }),
    [membersDic, guestsDic],
  );

  // プレゼンスのツールチップ更新
  useEffect(() => {
    if (!presences) return;
    ReactTooltip.rebuild();
  }, [presences]);

  const dropdownMenu = useMemo(
    () => (
      <Menu as="div" className="relative inline-block ml-4 text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                as="div"
                role="button"
                className="flex items-center p-1 text-gray-400 rounded-md hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
              >
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="w-4 h-4" aria-hidden="true" />
              </Menu.Button>
            </div>
            <RelativePortal component="div" right={0}>
              <PopupTransition show={open}>
                <Menu.Items className="absolute right-0 z-20 w-56 py-1 mt-2 text-gray-800 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 dark:divide-gray-600 dark:text-gray-100">
                  <Menu.Item
                    as="button"
                    key="menu-display-name"
                    className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                    onClick={() => setChangeNameOpen(true)}
                  >
                    <span className="flex-1">{t('表示名変更')}</span>
                  </Menu.Item>
                  <Menu.Item
                    as="a"
                    key="menu-user-help"
                    href="https://lp.collabble.jp/user/help"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                  >
                    <span className="flex-1">{t('操作ガイド')}</span>
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    key="menu-contact"
                    className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                    onClick={() => {
                      setContactDialogOpen(true);
                    }}
                  >
                    <span className="flex-1">{t('問い合わせ')}</span>
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    key="menu-theme"
                    className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                    onClick={toggleTheme}
                  >
                    {isDarkTheme ? (
                      <>
                        <span className="flex-1">{t('ライトモード')}</span>
                        <SunIcon
                          className="w-4 h-4 text-gray-300 dark:text-gray-400 text-middle group-hover:text-white"
                          aria-hidden="true"
                        />
                      </>
                    ) : (
                      <>
                        <span className="flex-1">{t('ダークモード')}</span>
                        <MoonIcon
                          className="w-4 h-4 text-gray-300 dark:text-gray-400 text-middle group-hover:text-white"
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Menu.Item>
                  <Menu.Item
                    as="button"
                    key="menu-logout"
                    className="flex items-center w-full px-4 py-2 text-left group hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white"
                    onClick={logOut}
                  >
                    <span className="flex-1">{t('ログイン画面に戻る')}</span>
                  </Menu.Item>
                </Menu.Items>
              </PopupTransition>
            </RelativePortal>
          </>
        )}
      </Menu>
    ),
    [toggleTheme],
  );

  return (
    <div className="pb-4 border-b border-gray-200 dark:border-gray-700">
      <div className="flex flex-wrap justify-between w-full lg:flex-nowrap space-y-3 lg:space-y-0 lg:space-x-3">
        <div className="flex items-center justify-between w-64 basis-full lg:basis-8/12">
          {isUnknownGuest && (
            <div className="mr-6">
              <a
                href="https://lp.collabble.jp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <HeaderLogo size="xs" />
              </a>
            </div>
          )}
          <h1 className="flex items-center flex-1 text-lg font-semibold text-gray-900 truncate sm:whitespace-normal md:mb-0 md:text-xl justify-content-center dark:text-gray-200">
            <span className="w-auto truncate">{name}</span>
            {isMember && isEmptyDescription && (
              <MenuButton
                id="aa"
                type="text"
                className="flex-shrink-0 w-auto ml-2 text-xs font-normal border-0 shadow-none !px-2 focus:ring-offset-0 ring-0 !ring-transparent dark:!text-gray-400"
                onClick={() => {
                  descriptionRef.current.setFocus();
                }}
              >
                <PlusIcon className="inline-block w-3 h-3" aria-hidden="true" />
                {t('説明を追加')}
              </MenuButton>
            )}
          </h1>
          {currentMyWorkspace?.joinType === 'unknownGuest' && (
            <>
              <div className="flex-shrink-0 block sm:hidden">
                {dropdownMenu}
              </div>
              <RelativePortal component="div">
                <Transition.Root show={contactDialogOpen} unmount>
                  <ContactDialog onClose={() => setContactDialogOpen(false)} />
                </Transition.Root>
              </RelativePortal>
            </>
          )}
        </div>
        {presences && (
          <div className="flex items-center order-last basis-1 lg:basis-auto min-w-fit space-x-1">
            <div className="relative z-10 inline-flex flex-row-reverse pl-2 ml-auto lg:ml-0 lg:flex h-7">
              {presences.map((presence, index) => {
                const account = accountDic[presence.uid as string];
                if (!account || index + 1 > MAX_PRESENCE_DISPLAY_COUNT)
                  return null;
                return (
                  <div
                    key={presence.uid}
                    data-tip={account.displayName}
                    className="leading-none"
                  >
                    <AvatarIcon
                      className="relative z-0 inline-block -ml-3 rounded-full shadow-md md:-ml-2 lg:-ml-1 hover:shadow-lg ring-2 ring-white dark:ring-gray-800 hover:z-30"
                      src={account.photoURL}
                      avatarName={account.displayName}
                      size={7}
                    />
                  </div>
                );
              })}
            </div>
            {presences.length > MAX_PRESENCE_DISPLAY_COUNT && (
              <Menu as="div">
                {({ open }) => (
                  <>
                    <Menu.Button
                      type="button"
                      className="inline-flex items-center text-xs font-medium leading-none text-gray-400 rounded-full px-2.5 h-7 bg-black/5 dark:text-gray-400 dark:bg-white/10"
                    >
                      +{presences.length - MAX_PRESENCE_DISPLAY_COUNT}
                    </Menu.Button>
                    <RelativePortal component="div" right={0}>
                      <PopupTransition show={open}>
                        <Menu.Items
                          static
                          className="absolute right-0 z-10 mt-2 overflow-y-auto bg-white shadow-lg max-w-min min-w-max origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none max-h-56"
                        >
                          <div className="py-1">
                            {presences.map((presence, index) => {
                              const account =
                                accountDic[presence.uid as string];
                              if (index + 1 <= MAX_PRESENCE_DISPLAY_COUNT)
                                return null;
                              return (
                                <div
                                  key={presence.uid}
                                  className="flex items-center px-3 py-2 space-x-2"
                                >
                                  <AvatarIcon
                                    className="relative inline-block rounded-full"
                                    src={account.photoURL}
                                    avatarName={account.displayName}
                                    size={7}
                                  />
                                  <span className="text-xs">
                                    {account.displayName}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </Menu.Items>
                      </PopupTransition>
                    </RelativePortal>
                  </>
                )}
              </Menu>
            )}
          </div>
        )}
        {/** 現在はゲスト用メニューとしてプロジェクト複製しかないので、ゲストかつ複製が許可されている、という条件になる */}
        {(isMember || (isGuest && currentMyProject?.allowGuestCopy)) && (
          <>
            <div className="basis-3/4 lg:basis-auto min-w-fit lg:order-last">
              <div className="flex items-center justify-start lg:justify-end flex-nowrap space-x-4">
                {isMember && (
                  <>
                    <AllCommentButton />
                    <div>
                      <MenuButton
                        id="showAppLinkage"
                        type="text"
                        onClick={() => {
                          setAppLinkageDialogOpen(true);
                        }}
                        className="whitespace-nowrap"
                      >
                        {t('アプリ連携')}
                      </MenuButton>
                    </div>
                    <div data-tip data-for="needUpdateInfo">
                      <MenuButton
                        id="showPublishSetting"
                        disabled={plan?.type === PLANS.FREE}
                        type="text"
                        ref={publishSettingButtonRef}
                        onClick={() => {
                          setShareSettingDialogOpen(true);
                        }}
                      >
                        {t('公開設定')}
                      </MenuButton>
                    </div>
                    <UpgradeTooltip id="needUpdateInfo" />
                    <div>
                      <MenuButton
                        id="showShareSetting"
                        type="text"
                        variant="primary"
                        onClick={() => setShareDialogOpen(true)}
                      >
                        {currentMyProject?.isShare ? (
                          <UsersIcon
                            className="w-4 h-4 mt-0.5"
                            aria-hidden="true"
                          />
                        ) : (
                          <LockClosedIcon
                            className="w-4 h-4 mt-0.5"
                            aria-hidden="true"
                          />
                        )}
                        <div className="ml-1">{t('共有')}</div>
                      </MenuButton>
                    </div>
                  </>
                )}
                <div>
                  {currentMyProject && (
                    <ProjectDropdown
                      className="flex-none cursor-pointer"
                      myProject={currentMyProject}
                    />
                  )}
                </div>
              </div>
            </div>
            {isMember && (
              <>
                <RelativePortal component="div">
                  <Transition.Root show={shareDialogOpen} unmount>
                    <ShareDialog onClose={() => setShareDialogOpen(false)} />
                  </Transition.Root>
                </RelativePortal>
                <RelativePortal component="div">
                  <Transition.Root show={shareSettingDialogOpen} unmount>
                    <ShareSettingDialog
                      onClose={() => setShareSettingDialogOpen(false)}
                    />
                  </Transition.Root>
                </RelativePortal>
                <RelativePortal component="div">
                  <Transition.Root show={appLinkageDialogOpen} unmount>
                    <AppLinkageDialog
                      myProject={currentMyProject as MyProject}
                      onClose={() => setAppLinkageDialogOpen(false)}
                    />
                  </Transition.Root>
                </RelativePortal>
              </>
            )}
          </>
        )}
        {isUnknownGuest && (
          <div className="basis-1/2 lg:basis-auto min-w-fit lg:order-last">
            <div className="flex flex-no-wrap items-center justify-start lg:justify-end">
              <div>
                {profile ? (
                  <AvatarIcon
                    src={profile?.photoURL}
                    avatarName={profile?.displayName}
                    size="md"
                  />
                ) : (
                  <Skeleton circle className="!w-9 !h-9" />
                )}
              </div>
              <div className="ml-2">
                {profile?.displayName && (
                  <p className="font-medium text-left text-gray-500 truncate dark:text-gray-300 group-hover:text-white">
                    {profile ? profile?.displayName : <Skeleton />}
                  </p>
                )}
              </div>
              <div className="flex-shrink-0 hidden sm:block">
                {dropdownMenu}
              </div>
            </div>
            <Transition.Root show={changeNameOpen} unmount>
              <ModifyUnknownGuestNameDialog
                isNameRegistered={isNameRegisteredUG}
                onClose={onCloseModifyUnknownGuestNameDialog}
              />
            </Transition.Root>
          </div>
        )}
      </div>
      <Description ref={descriptionRef} />
    </div>
  );
}

export default memo(ProjectHeader);
