import {
  CreateUpdateItemProperty,
  CreateUpdateItemPropertyValue,
  CreateUpdateItemStatus,
} from '@/@types/common';
import { Property, RootState, Status } from '@/@types/models';
import { IProperty } from '@/@types/viewItem';
import { Dialog } from '@headlessui/react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

import { INSERT_ITEM_POS, PROPERTY_TYPE } from '@/libs/const';

import ua from '@/libs/ua';

import ItemRepository from '@/repositories/ItemRepository';

import { closeNewItemDialog } from '@/reducers/newItemDialogReducer';

import Divider from '@/components/Common/Divider';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import ItemEditor, {
  IItemEditorHandler,
} from '@/components/Common/Lexical/editors/itemText/Editor';
import ItemProperties from '@/components/Items/ItemProperties';

import useEditItem from '@/hooks/useEditItem';
import useItems from '@/hooks/useItems';
import useKeyBind from '@/hooks/useKeyBind';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useOrderedProperties from '@/hooks/useOrderedProperties';
import useOrderedStatuses from '@/hooks/useOrderedStatuses';
import useProjects from '@/hooks/useProjects';
import useProperties from '@/hooks/useProperties';
import usePropertyOrders from '@/hooks/usePropertyOrders';
import useStatuses from '@/hooks/useStatuses';
import useStatusOrders from '@/hooks/useStatusOrders';

import useViews from '@/hooks/useViews';

import BaseDialog from '../Common/BaseDialog';

// アイテム追加画面
export default function NewItemDialog() {
  const editorRef = useRef({} as IItemEditorHandler);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { addingStatus, isAddingLast } = useSelector(
    (state: RootState) => state.newItemDialogState,
  );
  const { /* createItem, */ getCreateItem, generateItemId } = useItems();
  const { properties } = useProperties();
  const { propertyOrder } = usePropertyOrders();
  const orderedProperties = useOrderedProperties(properties, propertyOrder);
  const { statuses } = useStatuses();
  const { statusOrder } = useStatusOrders();
  const orderedStatuses = useOrderedStatuses(statuses, statusOrder);
  const { isReadOnly } = useProjects();
  const { isMember } = useMyWorkspaces();

  const [isEditorDirty, setIsEditorDirty] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const { currentView } = useViews();

  // useAgentによるOS判定
  const isIos = ua.isIOS();

  // アイテムの挿入位置
  const insertItemPosition = useMemo(() => {
    // statusヘッダーからアイテムを追加した場合、statusの先頭に
    if (addingStatus === undefined) {
      return INSERT_ITEM_POS.TOP;
    }
    // statusのアイテムの最下部から挿入した場合、最後尾
    // ヘッダーメニューから挿入した場合、先頭
    return isAddingLast ? INSERT_ITEM_POS.LAST : INSERT_ITEM_POS.TOP;
  }, [addingStatus, isAddingLast]);

  // プロパティ共通化処理
  const {
    methods,
    defaultValues,
    composeProperties,
    // updateAndComposePropertyValues,
    // updateStatuses,
    reduceFuncPropertyValues,
    getUpdateStatuses,
    getUpdateAndComposePropertyValues,
  } = useEditItem();

  // Editor.jsの変更判定
  const canSubmit = useMemo(
    () =>
      methods.formState.isValid && (methods.formState.isDirty || isEditorDirty),
    [methods.formState.isValid, methods.formState.isDirty, isEditorDirty],
  );

  // items新規作成処理
  const formSubmit = methods.handleSubmit((formData) => {
    if (!canSubmit) {
      return;
    }
    const { isDirty } = methods.formState;
    const fn = async (data: typeof formData) => {
      const status = data.properties.find(
        (p) => p.propertyType === PROPERTY_TYPE.STATUS,
      );
      try {
        // ステータスがセットされていないデータは不正
        if (!status) throw new Error('status is not set');

        // ステータス更新時にiconデータを追加
        const optionsWithIcons = status.options.map((s) => {
          const result = s;
          const org = orderedStatuses.find((x) => x.id === s.id);
          result.icon = org?.icon ?? '';
          return result;
        });
        status.options = optionsWithIcons;
        // ステータスとステータスオーダーデータ
        const statusesData = isMember ? getUpdateStatuses(status) : undefined;

        const newItemId = generateItemId();

        // Bodyの取得処理&保存処理: 型付けする
        const editorBody = editorRef.current.save();

        // アイテムデータ
        const itemData = getCreateItem(
          newItemId,
          data.itemTitle,
          status.stringValue,
          insertItemPosition,
          editorBody.jsonState,
          editorBody.textState,
        );

        let propsData: {
          createProperties?: CreateUpdateItemProperty[];
          updateProperties?: CreateUpdateItemProperty[];
          deleteProperties?: (string | undefined)[];
          propertyOrderList?: string[] | undefined;
          viewPropertyOrderList?: string[] | undefined;
          createPropertyValues?: CreateUpdateItemPropertyValue[];
          updatePropertyValues?: CreateUpdateItemPropertyValue[];
        } = {};

        if (isDirty) {
          if (isMember) {
            // プロパティ作成・更新・削除処理
            const propertiesData = getUpdateAndComposePropertyValues(
              data.properties,
              data.deletedProperties,
            );

            propsData = {
              ...propertiesData,
            };
          } else {
            // 編集権限は新規プロパティ追加なし
            const currentProperties = data.properties.filter((ip) =>
              properties.find((ps) => ps.id === ip.docId),
            );
            const newPropertyValues: CreateUpdateItemPropertyValue[] =
              currentProperties.reduce(reduceFuncPropertyValues, []);
            propsData = {
              createPropertyValues: newPropertyValues,
            };
          }
        }

        // アイテム保存
        const itemRepository = new ItemRepository(
          currentMyWorkspace?.id,
          currentMyProject?.id as string,
        );

        if (isMember) {
          await itemRepository.memberCreateItem(
            currentView?.id,
            statusesData?.createStatuses as CreateUpdateItemStatus[],
            statusesData?.updateStatuses as CreateUpdateItemStatus[],
            statusesData?.deleteStatuses as Status['id'][],
            statusesData?.statusOrder,
            itemData.item,
            itemData.body,
            itemData.itemOrderList,
            propsData?.createProperties as CreateUpdateItemProperty[],
            propsData?.updateProperties as CreateUpdateItemProperty[],
            propsData?.deleteProperties as Property['id'][],
            propsData?.propertyOrderList,
            propsData?.viewPropertyOrderList,
            propsData.createPropertyValues as CreateUpdateItemPropertyValue[],
            propsData.updatePropertyValues as CreateUpdateItemPropertyValue[],
          );
        } else {
          await itemRepository.guestCreateItem(
            itemData.item,
            itemData.body,
            itemData.itemOrderList,
            propsData.createPropertyValues as CreateUpdateItemPropertyValue[],
          );
        }

        // Reset form state
        methods.reset({ ...data });
      } catch (e) {
        toast.error(t('アイテムの作成に失敗しました'));
        throw e;
      }
    };
    fn(formData);
    methods.reset({ ...formData });
    // ダイアログを閉じる
    dispatch(closeNewItemDialog());
  });

  // Editor.jsの変更ハンドリング
  const onEditorChange = React.useCallback(() => {
    setIsEditorDirty(true);
  }, [setIsEditorDirty]);

  const close = () => dispatch(closeNewItemDialog());

  // 初期化処理
  const initialize = useCallback(
    (prs: Property[], sts: Status[]) => {
      const propertyList = composeProperties(prs, sts, addingStatus, []);

      methods.reset({
        ...defaultValues,
        properties: propertyList,
        deletedProperties: [] as IProperty[],
      });
    },
    [addingStatus],
  );

  const formSubmitByShortCutKey = useCallback(async () => {
    if (!canSubmit) return;
    await formSubmit();
  }, [canSubmit]);

  // リセット処理
  const reset = useCallback(() => {
    methods.reset(defaultValues);
  }, [methods]);

  // モーダルマウント時処理
  useEffect(() => {
    initialize(orderedProperties, orderedStatuses);
    setTimeout(() => {
      methods.setFocus('itemTitle');
    }, 1);
    return () => {
      reset();
    };
  }, []);

  // ctrl+enterでsubmit
  useKeyBind({
    key: 'Enter',
    ctrlOrCmdKey: true,
    onKeyDown: () => formSubmitByShortCutKey(),
  });

  // inputでctrl+enterでsubmit
  const enterForm = (keyEvent: KeyboardEvent) => {
    if (keyEvent.key === 'Enter' && (keyEvent.ctrlKey || keyEvent.metaKey)) {
      formSubmitByShortCutKey();
    }
  };

  // アイテム送信のツールチップ
  const itemSubmitTooltip = useMemo(() => {
    const baseMsg = isIos
      ? t('アイテムを登録__br__cmd + Enter')
      : t('アイテムを登録__br__Ctrl + Enter');
    return canSubmit ? baseMsg.replace('__br__', '<br />') : '';
  }, [canSubmit, isIos]);

  // アイテム送信のショートカット案内
  const itemSubmitMemo = useMemo(() => {
    const baseMsg = isIos ? 'cmd + Enter' : 'Ctrl + Enter';
    return t(`${baseMsg} で 登録`);
  }, [isIos]);

  const bodySubmit = useCallback(async () => {
    if (!(submitButtonRef.current && !canSubmit)) return;
    submitButtonRef.current.click();
  }, [canSubmit]);

  return (
    <BaseDialog
      onClose={close}
      className="pb-0 md:max-w-3xl sm:max-w-xl sm:align-middle sm:w-full !mb-0"
    >
      <div className="overflow-y-auto max-h-[calc(100vh-6rem)] sm:max-h-[calc(100vh-11rem)]">
        <div className="sticky top-0 z-10 flex items-center justify-between px-4 pt-5 pb-4 bg-white/80 dark:bg-gray-800/80 sm:p-6 sm:pb-6 backdrop-blur-sm space-x-4">
          <Dialog.Title
            as="h3"
            className="flex-1 text-lg font-medium text-gray-900 truncate dark:text-gray-300 leading-6"
          >
            {t('アイテムを追加')}
          </Dialog.Title>
        </div>
        <div className="px-4 pb-4 -mt-4 sm:px-6 sm:pb-6">
          <div className="mt-5 space-y-5">
            <FormProvider {...methods}>
              <FormCol>
                <Input
                  className="text-lg font-bold"
                  name="itemTitle"
                  placeholder={t('タイトル')}
                  autoFocus
                  onKeyDown={enterForm}
                />
              </FormCol>
              <Divider />
              <FormCol>
                {/* プロパティ設定 */}
                <ItemProperties
                  isValueEditPermission={!isMember && !isReadOnly}
                  onKeyDown={enterForm}
                />
              </FormCol>
              <FormCol className="pt-2">
                <div className="min-h-[50rem]">
                  <ItemEditor
                    ref={editorRef}
                    onChange={onEditorChange}
                    send={bodySubmit}
                  />
                </div>
              </FormCol>
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="bottom-0 z-10 flex flex-row-reverse px-4 py-3 dark:border-t bg-gray-50/80 dark:bg-gray-800/80 dark:border-gray-700 sm:px-6 backdrop-blur-sm">
        <FormProvider {...methods}>
          <FormButton
            ref={submitButtonRef}
            id="addItem"
            variant="primary"
            className="sm:w-auto sm:ml-3"
            disabled={!canSubmit}
            onClick={formSubmit}
            toolTip={itemSubmitTooltip}
          >
            {t('追加')}
          </FormButton>
          <FormButton
            id="cancelAddItem"
            className="mr-3 sm:mr-0 sm:w-auto"
            onClick={close}
          >
            {t('キャンセル')}
          </FormButton>
          <div className="hidden sm:flex sm:items-center sm:justify-end sm:pr-3 sm:text-gray-400 sm:grow sm:text-xxs sm:dark:text-gray-600">
            {itemSubmitMemo}
          </div>
        </FormProvider>
      </div>
    </BaseDialog>
  );
}
