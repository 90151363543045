import * as React from 'react';

export interface ILogoProps {
  className?: string;
}

/**
 * ロゴ
 * @param props
 * @returns
 */
export default function Logo(props: ILogoProps) {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 117.91 127.65"
      className={className}
    >
      <defs />
      <g>
        <g>
          <path
            className="fill-logoYellow"
            d="M76.87,13.21a10.41,10.41,0,0,1,0,14.73L66.71,38.1A10.41,10.41,0,0,1,52,38.1L41.82,27.94a10.43,10.43,0,0,1,0-14.73L52,3.05a10.41,10.41,0,0,1,14.73,0Z"
          />
          <path
            className="fill-logoRed"
            d="M13.21,24.86a10.41,10.41,0,0,1,14.73,0L38.1,35a10.42,10.42,0,0,1,0,14.72L27.94,59.91a10.41,10.41,0,0,1-14.73,0L3.05,49.74A10.4,10.4,0,0,1,3.05,35Z"
          />
          <path
            className="fill-logoYellow"
            d="M5.57,75.27a10.41,10.41,0,0,1,12.75-7.36l13.89,3.72a10.42,10.42,0,0,1,7.36,12.75L35.85,98.27a10.41,10.41,0,0,1-12.75,7.35L9.21,101.9A10.41,10.41,0,0,1,1.85,89.16Z"
          />
          <path
            className="fill-logoRed"
            d="M47.85,123.57a10.4,10.4,0,0,1-7.36-12.75l3.72-13.88A10.41,10.41,0,0,1,57,89.58L70.84,93.3a10.42,10.42,0,0,1,7.37,12.75l-3.73,13.89a10.39,10.39,0,0,1-12.74,7.35Z"
          />
          <path
            className="fill-logoYellow"
            d="M96.31,105.08a10.41,10.41,0,0,1-12.75-7.36L79.84,83.84A10.4,10.4,0,0,1,87.2,71.09l13.88-3.73a10.41,10.41,0,0,1,12.75,7.37l3.72,13.88a10.41,10.41,0,0,1-7.36,12.75Z"
          />
          <path
            className="fill-logoRed"
            d="M115.85,45.27A10.42,10.42,0,0,1,108.49,58L94.6,61.74a10.42,10.42,0,0,1-12.75-7.37L78.13,40.49A10.4,10.4,0,0,1,85.5,27.75L99.38,24a10.41,10.41,0,0,1,12.75,7.36Z"
          />
        </g>
      </g>
    </svg>
  );
}
