import { FileArrayValue } from '@/@types/models';
import _ from 'lodash';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { FileSource } from '@/components/Common/FileLoader';
import FileInput from '@/components/Common/Forms/FileInput';

import { IItemFormDefaultValues } from '@/hooks/useEditItem';

export interface IPropertyFileProps {
  idx: number;
}

/**
 * ファイル添付型の入力コンポーネント
 * @param props
 * @returns
 */
export default function PropertyFile(props: IPropertyFileProps) {
  const { idx } = props;

  const { getValues, setValue, trigger } =
    useFormContext<IItemFormDefaultValues>();

  // プロパティ値該当データ取得
  const fields = getValues('properties');
  const property = useMemo(() => fields[idx], [fields, idx]);

  const handleSelectFiles = async (uploadFile: FileSource[]) => {
    // 登録時のレスポンスデータをZodの構造体に保持
    const oldValues = _.cloneDeep(fields);
    oldValues[idx].arrayValue = [
      ...(property.arrayValue as FileArrayValue[]),
      ...uploadFile,
    ];
    setValue(
      `properties.${idx}.arrayValue`,
      oldValues[idx].arrayValue as FileArrayValue[],
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
    trigger();
  };

  // ファイル削除
  const handleDeleteFile = (file: FileSource) => {
    const newFiles = (fields[idx].arrayValue as FileArrayValue[]).filter(
      (f) => f.path !== file.path,
    );
    const oldValues = _.cloneDeep(fields);
    oldValues[idx].arrayValue = newFiles.map((f) => ({
      path: f.path,
      contentType: f.contentType,
      fileName: f.fileName,
    }));
    setValue(`properties.${idx}.arrayValue`, oldValues[idx].arrayValue, {
      shouldDirty: true,
      shouldTouch: true,
    });
    trigger();
  };

  return (
    <FileInput
      files={property.arrayValue as FileArrayValue[]}
      onDelete={handleDeleteFile}
      onSelect={handleSelectFiles}
    />
  );
}
