import { FunctionsResult, IFuncCreateSlackTokenRequest } from '@/@types/common';
import { httpsCallable } from 'firebase/functions';

import { functions } from '@/firebase';

export interface IIntegrationRepository {
  createSlackToken: (
    data: IFuncCreateSlackTokenRequest,
  ) => Promise<FunctionsResult | null>;
}

const funcCreateSlackToken = httpsCallable<
  IFuncCreateSlackTokenRequest,
  FunctionsResult
>(functions, 'createSlackToken');

export default class IntegrationRepository implements IIntegrationRepository {
  /**
   * Slack Access Token 保存
   * @param data
   * @returns
   */
  async createSlackToken(data: IFuncCreateSlackTokenRequest) {
    try {
      const res = await funcCreateSlackToken(data);
      return res.data;
    } catch (error) {
      throw new Error('Failed to create slack token.');
    }
  }
}
