import { IJoinedMember, RootState } from '@/@types/models';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import { t2s } from '@/libs/utils';

import { INVITE_EMAIL } from '@/libs/validations';

import InvitingMemberRepository from '@/repositories/InvitingMemberRepository';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import MemberAccountList, {
  IChengeAuth,
  IInviteEmail,
} from '@/components/Member/MemberAccountList';

import { auth } from '@/firebase';
import useAdminMembers from '@/hooks/useAdminMembers';
import useInvitingMembers from '@/hooks/useInvitingMembers';
import useMembers from '@/hooks/useMembers';
import useMonitorSubscription from '@/hooks/useMonitorSubscription';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useSubmitState from '@/hooks/useSubmitState';
import useXMembers from '@/hooks/useXMembers';

export default function Members() {
  const { t } = useTranslation();
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const { invitingMembers } = useInvitingMembers();
  const { members } = useMembers();
  const { _members } = useXMembers();
  const { adminMember } = useAdminMembers();
  const [joinedMembers, setJoinedMembers] = useState<IJoinedMember[]>([]);
  const { currentMyWorkspace } = useMyWorkspaces();

  const invitingMemberRepository = new InvitingMemberRepository();

  // メールアドレス重複チェック（mock）
  let checkDuplicateEmail = (v: string) => typeof v === 'string';
  let checkAuthRule = (v: IChengeAuth) => typeof v === 'object';

  // Zod schema定義
  const schema = z.object({
    removeIds: z.array(z.string()),
    inviteRemoveIds: z.array(z.string()),
    inviteEmails: z.array(
      z.object({
        value: z.preprocess(
          (val) => String(val).trim(),
          z
            .string()
            .min(1, { message: t('入力してください') })
            .email(t2s(t('正しいメールアドレスを入力してください')))
            .max(
              INVITE_EMAIL.max,
              t2s(t('<max>文字以内で入力してください', INVITE_EMAIL)),
            )
            .refine(
              (v) => checkDuplicateEmail(v),
              t2s(t('<label>が重複しています', { label: t('メールアドレス') })),
            ),
        ),
      }),
    ),
    changeAuths: z.array(
      z
        .any()
        .refine(
          (v) => checkAuthRule(v),
          t2s(t('最低1人は管理者権限を設定してください')),
        ),
    ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      removeIds: [] as string[],
      inviteRemoveIds: [] as string[],
      inviteEmails: [] as IInviteEmail[],
      changeAuths: [] as IChengeAuth[],
    },
  });

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  /**
   * 管理者ユーザー判定
   */
  const isAdmin = useMemo(() => {
    if (!adminMember.memberList) return false;
    return adminMember.memberList.includes(userId);
  }, [userId, adminMember.memberList]);

  // joinedMembersを生成
  useEffect(() => {
    const m = members.map<IJoinedMember>((o) => ({
      ...o,
      email: _members.find((p) => p.id === o.id)?.email,
      authType: adminMember.memberList?.find((id) => id === o.id)
        ? 'administrator'
        : 'member',
      joined: true,
    }));
    const im = invitingMembers.map<IJoinedMember>((o) => ({
      id: o.id,
      email: o.email,
      joined: false,
    }));
    setJoinedMembers([...m, ...im]);
    setTimeout(() => {
      // snapshot監視でデータが変化した場合、バリデーションを実施する
      methods.trigger();
    });
  }, [members, _members, invitingMembers, adminMember]);

  // メールアドレス重複チェック
  checkDuplicateEmail = (v: string) => {
    const { removeIds, inviteRemoveIds, inviteEmails } = methods.getValues();

    const tgt = joinedMembers
      .filter((o) => !removeIds.includes(o.id as string))
      .filter((o) => !inviteRemoveIds.includes(o.id as string));
    const res = tgt.find((o) => o.email === v);
    if (res || inviteEmails.filter((f) => f.value === v).length > 1)
      return false;
    return true;
  };

  // 現在の管理者IDリストを取得
  const getAdmins = useCallback(() => {
    const { changeAuths, removeIds } = methods.getValues();
    const mIds = changeAuths
      .filter((co) => co.value === 'member')
      .map((m) => m.id);
    const aIds = changeAuths
      .filter((co) => co.value === 'administrator')
      .map((m) => m.id);
    const admins = _.difference(
      _.union(_.difference(adminMember.memberList, mIds), aIds),
      removeIds,
    );
    return admins || [];
  }, [
    methods.getValues().changeAuths,
    methods.getValues().removeIds,
    adminMember.memberList,
  ]);

  // 権限ドロップダウンの選択制限用
  const isOnlyOneAdmin = useMemo(() => getAdmins().length <= 1, [getAdmins]);

  // 更新後のメンバーに管理者が存在するかチェック
  checkAuthRule = (v: IChengeAuth) => {
    if (v.value === 'administrator') return true;
    return getAdmins().length > 0;
  };

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    try {
      if (!auth.currentUser || !currentMyWorkspace?.id) {
        throw new Error();
      }
      const { removeIds, inviteRemoveIds, inviteEmails } = methods.getValues();
      await invitingMemberRepository.applyMemberSetting({
        workspaceId: currentMyWorkspace?.id as string,
        removeMembers: removeIds,
        inviteMembers: inviteEmails.map((o) => o.value),
        removeInvitingMembers: inviteRemoveIds,
        adminMembers: getAdmins(),
      });
      methods.reset({ ...data, inviteEmails: [] });
    } catch (e) {
      // Reset form state
      methods.reset({ ...data });
      toast.error(t('メンバー設定の適用に失敗しました'));
      throw e;
    }
  });

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  // 必要なデータのサブスクリプション状況をキーの有無で検知
  // 動的にストア名を振っているものはanyで対応
  const { isSubscriptionPrepared } = useMonitorSubscription([
    'invitingMembers',
    'members',
    '_members',
    'adminMembers',
  ]);

  // 必要な処理終了後はサブスクリプション & レンダリング
  if (!currentMyWorkspace || !isSubscriptionPrepared) return <div />;

  return (
    <div className="px-4 pt-4">
      <h1 className="flex items-center mb-6 text-xl font-semibold xs:text-l justify-content-center">
        {t('ワークスペースのメンバー')}
      </h1>
      <FormProvider {...methods}>
        <form onSubmit={formSubmit}>
          <MemberAccountList
            disabledAuthMember={isOnlyOneAdmin}
            joinedMembers={joinedMembers}
          />
          <div className="sticky bottom-0 z-10 max-w-3xl py-5 bg-white dark:bg-gray-800 sm:py-4 sm:flex sm:flex-row-reverse">
            {isAdmin && (
              <FormButton
                id="applyMembers"
                disabled={isDisabledApply}
                submit
                variant="primary"
                className="sm:w-auto sm:ml-3"
              >
                {t('適用')}
              </FormButton>
            )}
          </div>
        </form>
      </FormProvider>
      <div ref={scrollBottomRef} />
    </div>
  );
}
