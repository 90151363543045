import { IModalDialogProps } from '@/@types/ui';

import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import ConfirmDialog from './ConfirmDialog';

export interface IPlanContactDialogProps extends IModalDialogProps {}

/**
 * プラン変更問い合わせダイアログ
 * @param props
 * @returns
 */
export default function PlanContactDialog(props: IPlanContactDialogProps) {
  const { t } = useTranslation();
  const { onClose } = props;

  const { currentMyWorkspace } = useMyWorkspaces();

  const originalWorkspaceId = useMemo(
    () => currentMyWorkspace?.originalWorkspaceId as string,
    [currentMyWorkspace],
  );

  // Zod schema定義
  const originalWorkspaceIdSchema = z.object({
    originalWorkspaceId: z.string(),
  });

  // Zod Form
  const originalWidMethods = useForm({
    resolver: zodResolver(originalWorkspaceIdSchema),
    mode: VALIDATION_MODE,
    defaultValues: {
      originalWorkspaceId: '',
    },
  });

  useEffect(() => {
    originalWidMethods.setValue('originalWorkspaceId', originalWorkspaceId);
  }, []);

  return (
    <ConfirmDialog
      open
      key="contact-dialog"
      title={t('プラン変更に関する問い合わせ')}
      positive={t('OK')}
      positiveOnly
      onClose={() => {
        onClose();
      }}
    >
      <p className="mb-5">
        {t(
          'アップグレードやダウングレード、メンバー数の追加/削減をご希望される場合は、以下の連絡先までお問い合わせください。',
        )}
      </p>
      <p>{t('[問い合わせ先]')}</p>
      <p className="mb-5">
        {t('collabbleサポート担当')}
        <a href="mailto:information@collabble.jp" className="ml-2 link">
          information@collabble.jp
        </a>
      </p>
      <p>{t('[問い合わせ内容]')}</p>
      <p className="mb-2">
        {t('以下の内容をメール本文に記載し、お問い合わせください。')}
      </p>
      <ul className="list-disc list-inside">
        <li className="mb-2">
          {t('ワークスペースID')}
          <FormProvider {...originalWidMethods}>
            <div className="items-end max-w-2xl sm:flex gap-2">
              <FormCol className="flex-1">
                <Input name="originalWorkspaceId" readOnly />
              </FormCol>
              <div className="self-center mt-4 sm:mt-0">
                <CopyToClipboard
                  text={originalWorkspaceId}
                  onCopy={() => {
                    toast.success(
                      t('ワークスペースIDをクリップボードへコピーしました'),
                    );
                  }}
                >
                  <FormButton
                    id="originalWorkspaceId"
                    className="sm:w-auto"
                    icon
                    noBorder
                    toolTip={t('ワークスペースIDをコピー')}
                  >
                    <DocumentDuplicateIcon
                      className="w-4 h-4 m-0.5"
                      aria-hidden="true"
                    />
                  </FormButton>
                </CopyToClipboard>
              </div>
            </div>
          </FormProvider>
        </li>
        <li className="mb-2">
          {t(
            'アップグレードやダウングレード、メンバー数の追加/削減などの契約変更に関する詳細内容',
          )}
        </li>
      </ul>
    </ConfirmDialog>
  );
}
