import { MyProject, RootState } from '@/@types/models';
import { IXGuestDictionary } from '@/@types/viewItem';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { COL_X_GUESTS, getXGuestsPath } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * _Guestsの集約に関するHooks
 * @returns { _guests }
 */
export default function useGuests() {
  const { currentMyWorkspace } = useMyWorkspaces();

  const { currentMyProject, myProjects } = useProjects();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const _guests = useSelector(
    (state: RootState) => state.firestore.ordered._guests || [],
  );

  useFirestoreConnect(() =>
    workspaceId && projectId
      ? [
          {
            collection: getXGuestsPath(workspaceId, projectId),
            storeAs: COL_X_GUESTS,
          },
          ...myProjects.map(
            (p: MyProject) =>
              ({
                collection: getXGuestsPath(workspaceId, p.id),
                orderBy: ['email', 'asc'],
                storeAs: `${COL_X_GUESTS}/${p.id}`,
              } as ReduxFirestoreQuerySetting),
          ),
        ]
      : [],
  );

  const ordered = useSelector((state: any) => state.firestore.ordered);

  const myProjectsXGuestsDic = useMemo(
    () =>
      myProjects.reduce<IXGuestDictionary>((acc, cur) => {
        const values = ordered[`${COL_X_GUESTS}/${cur.id}`];
        if (!values) return acc;
        values.forEach((v: any) => {
          const projects: string[] = acc[v.id]?.projectId || [];
          acc[v.id] = {
            ...v,
            projectId: [cur.id]
              .concat(projects)
              .filter((s): s is string => Boolean(s)),
          };
        });
        return acc;
      }, {}),
    [ordered],
  );

  return { _guests, myProjectsXGuestsDic };
}
