import { IFirestoreQuery } from '@/@types/common';
import { Workspace, MyProject, View, MyWorkspace, Item } from '@/@types/models';

import { QueryConstraint, where, orderBy, limit } from 'firebase/firestore';

import ItemRepository from '@/repositories/ItemRepository';
import MyProjectRepository from '@/repositories/MyProjectRepository';
import MyWorkspaceRepository from '@/repositories/MyWorkspaceRepository';
import ViewRepository from '@/repositories/ViewRepository';

// repository用 Start
/**
 * Firestoreのクエリ追加用
 * @param params
 * @returns
 */
export const composeAppendQuery = <T extends { [key: string]: any }>(
  params?: IFirestoreQuery<T>,
): QueryConstraint[] => {
  const appends = [];
  if (params) {
    const { where: _where, limit: _limit, orderBy: _orderBy } = params;
    if (_where) {
      appends.push(where(_where[0], _where[1], _where[2]));
    }
    if (_limit) {
      appends.push(limit(_limit));
    }
    if (_orderBy) {
      appends.push(orderBy(_orderBy as string));
    }
  }
  return appends;
};
// repository用 End

// client用 Start
/**
 * myWorkspaceのlist取得
 * @param userId
 * @returns
 */
export const getMyWorkspaceList = (
  userId: string,
  query?: IFirestoreQuery<MyWorkspace>,
) => {
  const myWorkspaceRepo = new MyWorkspaceRepository(userId);
  return myWorkspaceRepo.list(query);
};

/**
 * ルーティング処理用のMyWorkspace絞り込みクエリ
 * @param
 * @returns
 */
export const routeHandleMyWorkspaceQuery = (
  workspaceId: string, // URLのworkspaceId
): IFirestoreQuery<MyWorkspace> => ({
  where: ['originalWorkspaceId', '==', workspaceId],
  limit: 1,
});

/**
 * workspaceIdと一致するmyWorkspaceを取得
 * @param userId
 * @param workspaceId
 * @returns
 */
export const findMyWorkspace = async (
  userId: string,
  workspaceId: Workspace['id'],
) => {
  const myWorkspaceList = await getMyWorkspaceList(userId);
  return myWorkspaceList.find((mw) => mw.workspaceId === workspaceId);
};

/**
 * myProjectのlist取得
 * @param userId
 * @param myWorkspaceId
 * @param query
 * @returns
 */
export const getMyProjectList = (
  userId: string,
  myWorkspaceId: string,
  query?: IFirestoreQuery<MyProject>,
) => {
  const myProjectRepository = new MyProjectRepository(userId, myWorkspaceId);
  return myProjectRepository.list(query);
};

/**
 * ルーティング処理用のMyProject絞り込みクエリ
 * @param joinType
 * @returns
 */
export const routeHandleMyProjectQuery = (
  joinType: MyWorkspace['joinType'],
): IFirestoreQuery<MyProject> => {
  let projectQuery = {
    where: null as any,
    orderBy: 'projectName' as const,
  };
  if (joinType !== 'member') {
    // メンバー以外なら共有設定を加味
    projectQuery = {
      ...projectQuery,
      where: ['isShare', '==', true],
    };
  } else {
    // メンバーであればアーカイブ設定を加味
    projectQuery = {
      ...projectQuery,
      where: ['isArchive', '==', false],
    };
  }
  return projectQuery;
};

/**
 * 該当のprojectIdがmyProjectList内に存在するか
 * @param userId
 * @param myWorkspaceId
 * @param joinType
 * @param projectId
 * @returns
 */
export const hasExpectedMyProject = async (
  userId: string,
  myWorkspaceId: string,
  projectId: string,
  query?: IFirestoreQuery<MyProject>,
) => {
  const myProjectList = await getMyProjectList(userId, myWorkspaceId, query);
  return (
    myProjectList.length > 0 &&
    !!myProjectList.find((pj) => pj.projectId === projectId)
  );
};

/**
 * viewのlist取得
 * @param userId
 * @param myWorkspaceId
 * @param query
 * @returns
 */
export const getViewList = (
  workspaceId: string,
  projectId: string,
  query?: IFirestoreQuery<View>,
) => {
  const viewRepository = new ViewRepository(workspaceId, projectId);
  return viewRepository.list(query);
};

/**
 * ルーティング処理用のview絞り込みクエリ
 * @param joinType
 * @returns
 */
export const routeHandleViewQuery = (joinType: MyWorkspace['joinType']) => {
  let viewQuery = {
    where: null as any,
    orderBy: 'viewName' as const,
  };
  // メンバー以外なら共有設定を加味
  if (joinType !== 'member') {
    viewQuery = {
      ...viewQuery,
      where: ['isGuestAccessible', '==', true],
    };
  }
  return viewQuery;
};

/**
 * 該当のviewがviewList内に存在するか
 * @param workspaceId
 * @param projectId
 * @param viewId
 * @param query
 * @returns
 */
export const hasExpectedView = async (
  workspaceId: string,
  projectId: string,
  viewId: string,
  query?: IFirestoreQuery<View>,
) => {
  const viewList = await getViewList(workspaceId, projectId, query);
  return viewList.length > 0 && !!viewList.find((vw) => vw.id === viewId);
};

/**
 * itemのlist取得
 * @param workspaceId
 * @param projectId
 * @param query
 * @returns
 */
export const getItemList = (
  workspaceId: string,
  projectId: string,
  query?: IFirestoreQuery<View>,
) => {
  const itemRepository = new ItemRepository(workspaceId, projectId);
  return itemRepository.list(query);
};

/**
 * ルーティング処理用のitem絞り込みクエリ
 * @param joinType
 * @returns
 */
export const routeHandleItemQuery = (joinType: MyWorkspace['joinType']) => {
  let itemQuery = {
    where: null as any,
    orderBy: 'title' as const,
  };
  // メンバー以外なら共有設定を加味
  if (joinType !== 'member') {
    itemQuery = {
      ...itemQuery,
      where: ['isShare', '==', true],
    };
  }
  return itemQuery;
};

/**
 * 該当のitemがitemList内に存在するか
 * @param workspaceId
 * @param projectId
 * @param itemId
 * @param query
 * @returns
 */
export const hasExpectedItem = async (
  workspaceId: string,
  projectId: string,
  itemId: string,
  query?: IFirestoreQuery<Item>,
) => {
  const itemList = await getItemList(workspaceId, projectId, query);
  return itemList.length > 0 && !!itemList.find((it) => it.id === itemId);
};
// client用 End
