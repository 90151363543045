import React from 'react';

import { classNames } from '@/libs/styleUtils';

export interface ILandscapeColProps {
  className?: string;
  children: React.ReactNode;
  label: React.ReactElement | string;
}

/**
 * 横分割カラム
 * @param props
 * @returns
 */
export default function LandscapeCol(props: ILandscapeColProps) {
  const { className, children, label } = props;

  return (
    <div
      className={classNames(
        'items-center grid grid-cols-12 gap-1 md:gap-3',
        className,
      )}
    >
      <div className="text-left col-span-12 md:col-span-3">
        {label && <span>{label}</span>}
      </div>
      <div className="col-span-12 md:col-span-9">{children}</div>
    </div>
  );
}
