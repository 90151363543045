import { IProperty } from '@/@types/viewItem';
import { Menu } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, {
  useState,
  useRef,
  useEffect,
  Fragment,
  memo,
  useMemo,
} from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RelativePortal from 'react-relative-portal';
import { z } from 'zod';

import { PROPERTY_TYPE, VALIDATION_MODE } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';
import { t2s } from '@/libs/utils';

import { PROPERTY_NAME } from '@/libs/validations';

import ConfirmDialog from '@/components/Common/ConfirmDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import Input from '@/components/Common/Forms/Input';

import MenuDropdownItem from '@/components/Common/MenuDropdownItem';

import PopupTransition from '@/components/Common/Transitions/PopupTransition';

import useSubmitState from '@/hooks/useSubmitState';

interface IItemPropertyNameProps {
  idx: number;
  property: IProperty;
  onEditClick: (idx: number) => void;
  onDelete: (idx: number) => void;
  onUpdate: (idx: number, value: string) => void;
  textBreak: string; // 改行:break-all 省略:truncate
  isEdit: boolean;
}

// プロパティラベル ドロップダウンコンポーネント
function ItemPropertyNameInput(props: IItemPropertyNameProps) {
  const { t } = useTranslation();
  const { idx, property, onEditClick, onUpdate, onDelete, textBreak, isEdit } =
    props;
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);
  // ドロップダウン表示時にフォーカス状態にする → TODO: レンダリング時にはすでにマウントしているので不発
  useEffect(() => {
    ref?.current?.focus();
  }, []);

  // Zod schema
  const schema = z.object({
    propertyName: z
      .string()
      .trim()
      .min(1, { message: t('入力してください') })
      .max(
        PROPERTY_NAME.max,
        t2s(t('<max>文字以内で入力してください', PROPERTY_NAME)),
      ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      propertyName: property.propertyName as string,
    },
  });

  // ラベル名変更時
  const menuRef = useRef<HTMLButtonElement>(null);
  const formSubmit = methods.handleSubmit((data) => {
    // メニューボタンを再クリックして強制的にメニューを閉じる
    menuRef?.current?.click();
    onUpdate(idx, data.propertyName);
    methods.reset({ propertyName: data.propertyName });
  });

  // 削除確認ダイアログハンドリング
  // TODO: 必要か確認する 文言等検討(ないと唐突だしあっても即時削除ではない)
  const handleDeleteConfirm = (deleteFlg: boolean | undefined) => {
    if (deleteFlg) {
      onDelete(idx);
    }
    setDeleteDialogOpen(false);
  };
  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  const editOption = useMemo(
    () => property?.editOption || null,
    [property.editOption],
  );

  if (!editOption) return null;

  return (
    <>
      {(!editOption.name && !editOption.content) || !isEdit ? (
        // 変更不可の場合
        <p className="w-full p-2 text-left break-all">
          {property.propertyName}
        </p>
      ) : (
        // 変更可の場合
        <Menu as="div" className="relative inline-block w-full ml-auto ">
          {({ open }) => (
            <>
              <Menu.Button
                ref={menuRef}
                className="relative block w-full p-2 sm:col-span-5 hover:gb-gray-100 dark:hover:bg-gray-700 rounded-md hover:bg-gray-100 dark:shadow-sm dark:border-gray-600"
              >
                <p className={classNames('p-px text-left', textBreak)}>
                  {property.propertyName}
                </p>
              </Menu.Button>

              <RelativePortal component="div" top={0}>
                <PopupTransition show={open}>
                  <Menu.Items
                    static
                    className="absolute left-0 z-50 p-px mt-2 bg-white shadow-lg w-80 'absolute origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none"
                  >
                    <div className="py-1 divide-y divide-gray-100 dark:divide-gray-600">
                      {/* プロパティ名変更可能の場合 */}
                      {editOption?.name && (
                        <FormProvider {...methods}>
                          <div className="flex px-3 py-2 text-left gap-x-4">
                            <div className="flex-1">
                              <Input name="propertyName" />
                            </div>
                            <FormButton
                              id="changePropertyName"
                              variant="primary"
                              shrink
                              disabled={isDisabledApply}
                              className="mt-0 mt-2 sm:w-full sm:w-auto sm:ml-auto sm:mt-0"
                              onClick={formSubmit}
                            >
                              {t('更新')}
                            </FormButton>
                          </div>
                        </FormProvider>
                      )}
                      <div>
                        {/* プロパティ種別 ステータス、セレクト、数値の場合 */}
                        {[
                          PROPERTY_TYPE.STATUS,
                          PROPERTY_TYPE.SINGLE_SELECT,
                          PROPERTY_TYPE.MULTI_SELECT,
                          PROPERTY_TYPE.NUMBER,
                        ].includes(property.propertyType as any) && (
                          <MenuDropdownItem
                            type="primary"
                            label={t('詳細を編集')}
                            onClick={() => onEditClick(idx)}
                          />
                        )}
                        {property.editOption.delete && (
                          <MenuDropdownItem
                            type="danger"
                            label={t('削除')}
                            onClick={() => setDeleteDialogOpen(true)}
                          />
                        )}
                      </div>
                    </div>
                  </Menu.Items>
                </PopupTransition>
              </RelativePortal>
            </>
          )}
        </Menu>
      )}
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={handleDeleteConfirm}
        title={t('プロパティを削除')}
        positive={t('削除')}
        warning
      >
        <p className="mb-2">
          {t('<name> を削除しようとしています。', {
            name: (
              <span className="mr-1 font-bold">{property.propertyName}</span>
            ),
          })}
        </p>
        <p>
          {t(
            'プロパティはアイテムの更新時に削除されます。<br> 本当によろしいですか？',
            { br: <br /> },
          )}
        </p>
      </ConfirmDialog>
    </>
  );
}
export default memo(ItemPropertyNameInput);
