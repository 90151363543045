import { Combobox } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import { classNames } from '@/libs/styleUtils';
import { unwrapEV } from '@/libs/utils';

import AvatarIcon from '@/components/Common/AvatarIcon';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';

/**
 * 親コンポーネントから命令的に実行可能なインターフェース
 */
export interface ISelectOptionRequired {
  id: string;
  name: string;
  value: string;
}

export interface ISelectOptionNotRequired {
  unavailable?: boolean;
  optionName?: string; // 選択肢表示時のディスプレイ
  photoURL?: string;
  isAnonymous?: boolean;
}

export interface IMultiSelectHandler {
  reset: () => void;
  setInitialSelect: <T extends ISelectOptionRequired>(initList: T[]) => void;
}

export type ISelectOption = ISelectOptionRequired & ISelectOptionNotRequired;

export type IMultiSelectProps = ComponentPropsWithoutRef<'div'> & {
  optionList: ISelectOption[];
  selectedOptions: ISelectOption[];
  placeholder?: string;
  optionDirection?: 'top' | 'bottom';
  showIcon?: boolean;
  onChangeSelected: (selectedOptions: ISelectOption[]) => void;
};

/**
 * 宛先用インクリメンタルサーチ付きマルチセレクト
 * @param props
 * @returns
 */
const MultiSelect = forwardRef<IMultiSelectHandler, IMultiSelectProps>(
  (props, ref) => {
    const {
      optionList,
      selectedOptions,
      placeholder,
      optionDirection: _optionDirection,
      showIcon,
      onChangeSelected,
    } = props;

    // デフォルト選択値
    const defaultSelected = useMemo(() => selectedOptions, []);

    // ローカルステート
    const [query, setQuery] = useState('');

    /**
     * 選択肢表示位置
     */
    const optionDirection = useMemo(
      () => _optionDirection || 'bottom',
      [_optionDirection],
    );

    /**
     * 該当のIDを選択済みリストから除外する
     */
    const removeSingle = useCallback(
      (id: string | number) => {
        onChangeSelected(selectedOptions.filter((o) => o.id !== id));
      },
      [selectedOptions],
    );

    /**
     * 選択済みリストリセット
     */
    const reset = useCallback(() => {
      onChangeSelected(defaultSelected ?? []);
    }, []);

    /**
     * 初期選択リストセット
     */
    const setInitialSelect = useCallback((initList: ISelectOption[]) => {
      onChangeSelected(initList);
    }, []);

    /**
     * 選択済み 検索文字列に引っかからないものをフィルタ
     */
    const filteredOptions = useMemo(() => {
      const selectedIds = selectedOptions.map((o) => o.id);
      return optionList.filter(
        (option) =>
          option.name.toLowerCase().includes(query.toLowerCase()) &&
          !selectedIds.includes(option.id),
      );
    }, [query, optionList, selectedOptions]);

    /**
     * ※ パッケージのonChange型定義と実装が合致してないためanyで対応
     * @param selected ISelectedOption
     */
    const handleSelect = (selected: any) => {
      setQuery('');
      onChangeSelected([...selectedOptions, selected]);
    };

    /**
     * 親コンポーネントから命令的に実行できるハンドラ
     */
    useImperativeHandle(ref, () => ({
      reset: () => {
        reset();
      },
      setInitialSelect: (initList) => {
        setInitialSelect(initList);
      },
    }));

    return (
      <Combobox value={selectedOptions} onChange={handleSelect}>
        {({ open }) => (
          <div className="relative w-full">
            <Combobox.Button
              as="div"
              className="flex flex-wrap w-full h-full text-left text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-700 focus:outline-none dark:border-gray-600 shadow-sm dark:text-gray-300 hover:bg-gray-50 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600"
              role="button"
            >
              {/* 選択済みタグ表示 */}
              {selectedOptions.length > 0 &&
                selectedOptions.map((o) => (
                  <span
                    className="flex px-2 py-1 m-1 bg-gray-200 dark:bg-gray-500 dark:text-gray-300 rounded-md overflow-ellipsis"
                    key={o.id}
                  >
                    <div className="flex space-x-1">
                      {showIcon && (
                        <AvatarIcon
                          size="sm"
                          src={o.photoURL}
                          avatarName={o.name}
                          isAnonymous={o.isAnonymous ?? false}
                        />
                      )}
                      <span className="my-auto">{o.name}</span>
                    </div>
                    <button
                      type="button"
                      className="ml-1"
                      onClick={() => removeSingle(o.id)}
                    >
                      <XMarkIcon className="w-3" />
                    </button>
                  </span>
                ))}

              {/* 入力エリア */}
              <Combobox.Input
                onChange={(e) => setQuery(unwrapEV(e))}
                className="block w-full font-normal bg-transparent border-none focus:ring-transparent focus:border-transparent rounded-md placeholder:text-gray-300 dark:placeholder:text-gray-500 "
                style={{ minWidth: '150px' }}
                placeholder={placeholder || ''}
                value={query}
              />
            </Combobox.Button>

            {/* 選択肢 */}
            {filteredOptions.length > 0 && (
              <PopupTransition show={open}>
                <Combobox.Options
                  className={classNames(
                    optionDirection === 'bottom' ? 'mt-1' : 'bottom-12',
                    'absolute z-30 w-full overflow-auto text-base bg-white shadow-lg dark:bg-gray-700 rounded-md max-h-44 ring-1 ring-black ring-opacity-5 focus:outline-none',
                  )}
                >
                  {filteredOptions.map((option) => (
                    <Combobox.Option
                      key={option.id}
                      value={option}
                      disabled={option.unavailable}
                      className={({ active }) =>
                        `cursor-pointer select-none relative py-2 pl-2 pr-4 hover:bg-primary-500 hover:dark:bg-primary-600 hover:text-white ${
                          active
                            ? 'bg-primary-500 dark:bg-primary-600 text-white'
                            : ''
                        }`
                      }
                    >
                      <div className="flex space-x-2">
                        {showIcon && (
                          <AvatarIcon
                            size="sm"
                            src={option.photoURL}
                            avatarName={option.name}
                          />
                        )}
                        <span>{option.optionName || option.name}</span>
                      </div>
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </PopupTransition>
            )}
          </div>
        )}
      </Combobox>
    );
  },
);
export default MultiSelect;
