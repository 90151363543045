import { RootState } from '@/@types/models';
import {
  getRedirectResult,
  signInAnonymously,
  updateProfile,
  User,
  UserCredential,
  UserInfo,
} from 'firebase/auth';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Credentials, useFirebase } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';

import { FIREBASE_ERROR_CODES } from '@/libs/const';

import { getEmailAccount } from '@/libs/utils';

import { auth } from '@/firebase';
import useHandleApi from '@/hooks/useHandleApi';

export interface IInvitedUserCredential {
  uid: string;
  displayName: string | null;
  email: string | null;
  photoURL: string | null;
}

/**
 * Firebase Auth Hooks
 * @returns { loginWithGoogle }
 */
export default function useFirebaseAuth() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { exec } = useHandleApi();
  const firebase = useFirebase();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);

  // RRFでの認証判定
  const isAuthenticated = useMemo(
    () => rrfAuth.isLoaded && !rrfAuth.isEmpty,
    [rrfAuth],
  );

  const isAnonymous = useMemo(() => rrfAuth.isAnonymous, [rrfAuth]);

  // firebase/auth認証系API返り値のハンドラ
  // ※ 各種ステート初期化のため画面はリロード
  const handleAuthCredentialApi = async (
    apiResult: Promise<UserCredential | null>,
  ) => {
    await exec(
      async () => {
        await apiResult;
      },
      t('認証に失敗しました'),
      [
        FIREBASE_ERROR_CODES.POPUP_CLOSED_BY_USER,
        FIREBASE_ERROR_CODES.CANCELLED_POPUP_REQUEST,
      ],
    );
  };

  const _login = async (credentials: Credentials): Promise<UserInfo | null> => {
    const { user } = await firebase.login(credentials);
    return user;
  };

  const createUserWithEmailPassword = async (
    email: string,
    password: string,
  ): Promise<UserInfo> => {
    const user = await firebase.createUser(
      { email, password },
      {
        email,
      },
    );
    await updateProfile(auth.currentUser as User, {
      displayName: getEmailAccount(email),
    });
    return user;
  };

  // Email / Passwordでのログイン
  const loginWithEmailPassword = async (email: string, password: string) => {
    await _login({
      email,
      password,
    });
  };

  // Googleアカウントでのログイン
  const loginWithGoogle = async () => {
    await _login({ provider: 'google', type: 'popup' });
  };

  // 匿名認証
  const loginAsUnknownGuest = async () => {
    await signInAnonymously(auth);
  };

  // ログアウト
  const logOut = async () => {
    navigate('/wait');
    await firebase.logout();

    // 各種ステート初期化のため画面をリロード
    window.location.href = '/login';
  };

  // ログアウト（メンバー招待時などの強制再ログイン用）
  const logOutWithoutNavigation = async () => {
    await firebase.logout();
  };

  // リダイレクトによるログインのサブスクリプション
  // ※ リダイレクトの方式をとらなくなったので2022-04-07時点では不要になった
  const subscribeRedirectResult = async () => {
    await handleAuthCredentialApi(getRedirectResult(auth));
  };

  // token取得（期限切れの場合再発行）
  const getFirebaseToken = async () => {
    const now = Date.now();
    if (now > rrfAuth.stsTokenManager.expirationTime) {
      const idToken = await auth.currentUser?.getIdToken();
      return idToken || '';
    }
    return rrfAuth.stsTokenManager.accessToken;
  };

  return {
    isAnonymous,
    isAuthenticated,
    createUserWithEmailPassword,
    loginWithEmailPassword,
    loginWithGoogle,
    loginAsUnknownGuest,
    logOut,
    logOutWithoutNavigation,
    subscribeRedirectResult,
    getFirebaseToken,
  };
}
