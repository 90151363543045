import React, { ReactNode, useCallback } from 'react';

import { useFormContext, useFormState } from 'react-hook-form';

import { classNames } from '@/libs/styleUtils';

import { zenkakuEisu2HankakuEisu } from '@/libs/utils';

import InvalidIcon from '@/components/Common/Forms/Validation/Icon/FormInvalidIcon';
import ValidIcon from '@/components/Common/Forms/Validation/Icon/FormValidIcon';

import ValidationTooltip from '@/components/Common/Forms/Validation/ValidationTooltip';

export interface IInputProps {
  idx?: number;
  property?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  disableAutoComplete?: boolean;
  className?: string;
  readOnly?: boolean;
  showTooltipBellow?: boolean;
  addOn?: {
    left?: string | ReactNode;
    right?: string | ReactNode;
  };
  password?: boolean;
  error?: string | null;
  number?: boolean;
  noValidationIcon?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
}

/**
 * テキスト入力フォーム
 * @param props
 * @returns
 */
export default function Input(props: IInputProps) {
  const {
    idx,
    property,
    label,
    name,
    placeholder,
    autoFocus,
    disabled,
    disableAutoComplete,
    className,
    readOnly,
    showTooltipBellow,
    addOn,
    password,
    number,
    error,
    noValidationIcon,
    onKeyDown,
  } = props;

  type IProp = {
    [key in typeof property as string]: {
      message: string;
    };
  };

  const typeCheckProp = (obj: unknown, key: string): obj is IProp => {
    const prop = obj as IProp;
    return key in prop;
  };

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const { dirtyFields } = useFormState({
    control,
  });
  const isIndexable = () => property && idx !== undefined;

  const getRegister = () => {
    if (register) {
      const opt = number
        ? {
            // valueAsNumber: true だと未入力が許されないので下記のように強制的に変換する(カンマも除去)
            setValueAs: (v: any) =>
              !v
                ? null
                : parseFloat(zenkakuEisu2HankakuEisu(v).replaceAll(',', '')),
          }
        : {};
      if (isIndexable()) {
        return property
          ? register(`${name}[${idx}].${property}`, opt)
          : register(`${name}[${idx}]`, opt);
      }
      return register(name, opt);
    }
    return null;
  };

  const isDirty = () => {
    if (idx !== undefined) {
      let dirtyFlg = false;
      const dKey = dirtyFields[name];
      if (dKey && dKey[idx] && (!property || dKey[idx][property])) {
        dirtyFlg = true;
      }
      return dirtyFlg;
    }
    return dirtyFields[name] !== undefined;
  };

  const getError = () => {
    if (idx !== undefined && property !== undefined) {
      let e = '';
      const key = errors[name];
      if (key && Array.isArray(key) && key[idx]) {
        const form = key[idx];
        if (typeCheckProp(form, property)) {
          e = form[property].message;
        }
      }
      return e;
    }
    return errors[name]?.message;
  };
  const validationError = getError();

  const getAddOnClass = () => {
    if (!addOn) return 'rounded-md';

    if (addOn.left && addOn.right) {
      return 'rounded-none';
    }

    if (addOn.left) {
      return 'rounded-none rounded-r-md';
    }

    if (addOn.right) {
      return 'rounded-none rounded-l-md';
    }

    return '';
  };

  const handleOnKeyDown = useCallback(
    (e) => {
      // eslint-disable-next-line no-unused-expressions
      onKeyDown === undefined ? e.stopPropagation() : onKeyDown(e);
    },
    [onKeyDown],
  );

  return (
    <>
      {' '}
      <div>
        {label && (
          <label
            htmlFor={name}
            className="block font-medium text-gray-700 dark:text-gray-400"
          >
            {label}
          </label>
        )}
        <div className={label ? 'mt-1' : ''}>
          <div className="flex">
            {addOn?.left && (
              <span className="relative inline-flex items-center px-4 py-2 -mr-px border border-gray-300 dark:text-gray-300 rounded-l-md bg-gray-50 dark:border-gray-600 dark:bg-gray-800">
                {addOn.left}
              </span>
            )}
            <div
              className={classNames(
                'relative flex-grow rounded-md shadow-sm',
                addOn && 'z-10',
              )}
            >
              <input
                autoFocus={autoFocus}
                id={isIndexable() ? `${name}-${idx}-${property}` : name}
                type={password ? 'password' : 'text'}
                autoComplete={disableAutoComplete ? 'off' : name}
                className={classNames(
                  disabled
                    ? 'bg-gray-200 text-gray-500 dark:bg-gray-800 dark:text-gray-400 opacity-50'
                    : 'bg-white dark:bg-gray-700',
                  className,
                  (validationError || error) &&
                    'border-red-400 dark:border-red-500',
                  getAddOnClass(),
                  'block w-full border-gray-300 dark:border-gray-600  focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 placeholder:text-gray-300 dark:placeholder:text-gray-500 font-normal pr-9',
                )}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                {...getRegister()}
                // onChange={(e) => {
                //   e.currentTarget.value = e.currentTarget.value.replace(
                //     /[^0-9]/gi,
                //     '',
                //   );
                // }}
                onKeyDown={handleOnKeyDown}
              />
              {!noValidationIcon && (
                <div>
                  {((validationError || error) && <InvalidIcon />) ||
                    (isDirty() && <ValidIcon />)}
                </div>
              )}
              <ValidationTooltip
                className="!max-w-md min-w-max"
                message={validationError?.toString() || error}
                showBellow={showTooltipBellow}
              />
            </div>
            {addOn?.right && (
              <span className="relative inline-flex items-center px-4 py-2 -ml-px border border-gray-300 dark:text-gray-300 rounded-r-md bg-gray-50 dark:border-gray-600 dark:bg-gray-800">
                {addOn.right}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
