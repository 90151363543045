import { Transition } from '@headlessui/react';
import React, { Fragment, ReactNode } from 'react';

export interface IPopupTransitionProps {
  show: boolean;
  children?: ReactNode;
}

/**
 * メニュー用Transition
 * @param props
 * @returns
 */
export default function PopupTransition(props: IPopupTransitionProps) {
  const { show, children } = props;

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {children}
    </Transition>
  );
}
