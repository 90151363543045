import { zodResolver } from '@hookform/resolvers/zod';
import { ja, enUS as en } from 'date-fns/locale';
import i18next, { t } from 'i18next';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import DatePicker, {
  CalendarContainer,
  registerLocale,
} from 'react-datepicker';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';

import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';
import { t2s } from '@/libs/utils';

import Alert from '@/components/Common/Alert';
import Toggle, { ToggleValue } from '@/components/Common/Forms/Toggle';

import { IItemFormDefaultValues } from '@/hooks/useEditItem';

export interface IPropertyDateProps {
  idx: number;
}

export interface ICalContainerProps {
  children: ReactNode;
}

/**
 * プロパティ選択肢用DateInput
 * @param props
 * @returns
 */
export default function PropertyDate(props: IPropertyDateProps) {
  const { idx } = props;
  registerLocale('ja', ja);
  registerLocale('en', en);

  const { getValues, setValue, trigger } =
    useFormContext<IItemFormDefaultValues>();

  // Zod schema定義
  const optionsSchema = z.object({
    isDueDate: z.boolean(),
  });

  const fields = getValues('properties');
  const isDueDate = useMemo(
    () => fields[idx].isDueDate,
    [getValues('properties'), idx],
  );

  // Zod Form
  const optionsMethods = useForm({
    resolver: zodResolver(optionsSchema),
    mode: VALIDATION_MODE,
    defaultValues: {
      isDueDate,
    },
  });

  useEffect(() => {
    const values = getValues('properties');
    optionsMethods.setValue('isDueDate', values[idx].isDueDate);
  }, []);

  // プロパティ値該当データ取得
  const property = useMemo(() => {
    const v = fields[idx].numberValue;
    return v ? new Date(v) : null;
  }, [fields[idx].numberValue, idx]);

  // 日付更新
  const handleChange = useCallback(
    (value: Date | null) => {
      const values = getValues('properties');
      const input = value?.getTime() ?? null;
      // キー指定してvalue値を更新
      values[idx].numberValue = input;
      setValue(`properties.${idx}.numberValue`, input, {
        shouldDirty: true,
        shouldTouch: true,
      });
      trigger();
    },
    [idx],
  );

  // トグル更新
  const handleToggleChange = useCallback((value: ToggleValue) => {
    const values = getValues('properties');
    values[idx].isDueDate = value.value;
    setValue('properties', values, { shouldDirty: true, shouldTouch: true });
  }, []);

  const calContainer = useCallback(
    (calProps: ICalContainerProps) => {
      const { children } = calProps;

      return (
        <CalendarContainer>
          <div className="pt-2 overflow-hidden bg-white shadow-lg max-w-min dark:bg-gray-700 rounded-md ring-1 ring-black ring-opacity-5">
            <div className="relative">
              <div className="">{children}</div>
            </div>

            <div className="flex px-4 pt-4 pb-4">
              <FormProvider {...optionsMethods}>
                <Toggle
                  label={t('設定日付を期日として扱う')}
                  name="isDueDate"
                  defaultValue={optionsMethods.getValues().isDueDate}
                  className="w-full"
                  onChange={handleToggleChange}
                />
              </FormProvider>
            </div>
            {optionsMethods.getValues().isDueDate && (
              <div className="px-4 pb-4 text-xs">
                <Alert color="gray">
                  {t(
                    '期日として扱うことで、設定日付の前日または当日以降にアイテムが強調表示されるようになります。',
                  )}
                </Alert>
              </div>
            )}
          </div>
        </CalendarContainer>
      );
    },
    [optionsMethods],
  );

  return (
    <div className="w-full h-full">
      <DatePicker
        closeOnScroll={(e) => e.target === document}
        locale={i18next.resolvedLanguage}
        dateFormat={t2s(t('yyyy-MM-dd'))}
        selected={property || undefined}
        // showTimeInput
        onChange={handleChange}
        calendarContainer={calContainer}
        isClearable
        highlightDates={[new Date()]}
        className="w-full h-full px-3 py-2 text-left text-gray-700 bg-white border border-gray-300 dark:bg-gray-700 focus:outline-none rounded-md dark:border-gray-600 shadow-sm dark:text-gray-200 hover:bg-gray-50 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 min-h-[2.62rem]"
      />
    </div>
  );
}
