import {
  IFuncGetStripeCustomerPortalUrlResult,
  IFuncGetStripeCustomerPortalUrlRequest,
  IFuncGetStripeCheckoutUrlRequest,
  IFuncGetStripeCheckoutUrlResult,
  IFuncCreateStripeSessionRequest,
  FunctionsResult,
  IFuncRemoveStripeSessionRequest,
} from '@/@types/common';
import { httpsCallable } from 'firebase/functions';

import { functions } from '@/firebase';

export interface IPlanRepository {
  getStripeCustomerPortalUrl: (
    data: IFuncGetStripeCustomerPortalUrlRequest,
  ) => Promise<IFuncGetStripeCustomerPortalUrlResult | null>;
  getStripeCheckoutUrl: (
    data: IFuncGetStripeCheckoutUrlRequest,
  ) => Promise<IFuncGetStripeCheckoutUrlResult | null>;
  createStripeSession: (
    data: IFuncCreateStripeSessionRequest,
  ) => Promise<FunctionsResult | null>;
  removeStripeSession: (
    data: IFuncRemoveStripeSessionRequest,
  ) => Promise<FunctionsResult | null>;
}

const funcGetStripeCustomerPortalUrl = httpsCallable<
  IFuncGetStripeCustomerPortalUrlRequest,
  IFuncGetStripeCustomerPortalUrlResult
>(functions, 'getStripeCustomerPortalUrl');

const funcGetStripeCheckoutUrl = httpsCallable<
  IFuncGetStripeCheckoutUrlRequest,
  IFuncGetStripeCheckoutUrlResult
>(functions, 'getStripeCheckoutUrl');

const funcCreateStripeSession = httpsCallable<
  IFuncCreateStripeSessionRequest,
  FunctionsResult
>(functions, 'createStripeSession');

const funcRemoveStripeSession = httpsCallable<
  IFuncRemoveStripeSessionRequest,
  FunctionsResult
>(functions, 'removeStripeSession');

export default class PlanRepository implements IPlanRepository {
  /**
   * StripeカスタマーポータルURL取得
   * @param data
   * @returns
   */
  async getStripeCustomerPortalUrl(
    data: IFuncGetStripeCustomerPortalUrlRequest,
  ) {
    try {
      const res = await funcGetStripeCustomerPortalUrl(data);
      return res.data;
    } catch (error) {
      throw new Error('Failed to create customer portal session.');
    }
  }

  /**
   * StripeチェックアウトURL取得
   * @param data
   * @returns
   */
  async getStripeCheckoutUrl(data: IFuncGetStripeCheckoutUrlRequest) {
    try {
      const res = await funcGetStripeCheckoutUrl(data);
      return res.data;
    } catch (error) {
      throw new Error('Failed to create customer portal session.');
    }
  }

  /**
   * Stripe Session ID 保存
   * @param data
   * @returns
   */
  async createStripeSession(data: IFuncCreateStripeSessionRequest) {
    try {
      const res = await funcCreateStripeSession(data);
      return res.data;
    } catch (error) {
      throw new Error('Failed to create session ID.');
    }
  }

  /**
   * Stripe Session ID 削除
   * @param data
   * @returns
   */
  async removeStripeSession(data: IFuncRemoveStripeSessionRequest) {
    try {
      const res = await funcRemoveStripeSession(data);
      return res.data;
    } catch (error) {
      throw new Error('Failed to remove session ID.');
    }
  }
}
