import { RootState } from '@/@types/models';
import { IAccountDictionary } from '@/@types/viewItem';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestoreConnect } from 'react-redux-firebase';

import { JOIN_TYPE } from '@/libs/const';

import { COL_UNKNOWN_GUESTS, getUnknownGuestsPath } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * unknownGuestsの集約に関するHooks
 * @returns { unknownGuests }
 */
export default function useUnknownGuests() {
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const unknownGuests = useSelector(
    (state: RootState) => state.firestore.ordered.unknownGuests || [],
  );

  useFirestoreConnect(() =>
    workspaceId && projectId
      ? [
          {
            collection: getUnknownGuestsPath(workspaceId, projectId),
            storeAs: COL_UNKNOWN_GUESTS,
          },
        ]
      : [],
  );

  /**
   * メンバー一覧の表示名 画像リンクを取得するための辞書
   * [uid]: { photoURL, displayName }
   * @returns {IMemberDictionary}
   */
  const unknownGuestsDic = useMemo(
    () =>
      unknownGuests.reduce<IAccountDictionary>((acc, cur) => {
        const value: IAccountDictionary['uid'] = {
          photoURL: null,
          displayName: cur.displayName ?? '',
          joinType: JOIN_TYPE.UNKNOWN_GUEST,
        };
        acc[cur.id as string] = value;

        return acc;
      }, {}),
    [unknownGuests],
  );

  return { unknownGuests, unknownGuestsDic };
}
