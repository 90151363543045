import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export interface IHeadProps {
  title?: string;
}

export default function Head(props: IHeadProps) {
  const { title } = props;
  return (
    <Helmet>
      <title>{title ? `${title} - ` : ''}collabble</title>
      <meta name="description" content="" />
      {/* OGP start */}
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={title ? `${title} - collabble` : 'collabble'}
      />
      <meta property="og:site_name" content="collabble" />
      {/* <meta property="og:description" content="OGPに掲載する説明文" />
      <meta property="og:image" content="OGPに掲載する画像" /> */}
      {/* OGP end */}
    </Helmet>
  );
}
