import {
  Account,
  Auth,
  Body,
  CheckboxProperty,
  DateProperty,
  Doc,
  FileProperty,
  ILanguageType,
  InchargeProperty,
  InvitingGuest,
  InvitingMember,
  IPropertyType,
  Item,
  ItemOrder,
  MultiSelectProperty,
  MyWorkspace,
  NumberProperty,
  Plan,
  Profile,
  Project,
  Property,
  PropertyOrder,
  PropertyValue,
  SingleSelectProperty,
  Status,
  StatusOrder,
  TextProperty,
  View,
  Workspace,
} from '@/@types/models';

import { FieldPath, WhereFilterOp } from 'firebase/firestore';

import { TAILWIND_COLORS, ORIGINAL_COLORS, LOGIN_MODE } from '@/libs/const';

// keyofのValue版
export type ValueOf<T> = T[keyof T];

export const TailWindColors = Object.values(TAILWIND_COLORS).map(
  (d) => d.label,
);

export type TailwindColorType = typeof TailWindColors[number];

export const originalColors = Object.values(ORIGINAL_COLORS).map(
  (d) => d.label,
);

export type OriginalColorType = typeof originalColors[number];

export type Nullable<T> = T | null;

export type Nullish<T> = Nullable<T> | undefined;

// 代数的データ型(タグ付データ型) typeを識別子としたユニオンを生成
export type ADT<T> = {
  [K in keyof T]: Record<'type', K> & T[K];
}[keyof T];

// Redux用のAction型定義;
export type ActionType<Actions, T extends Record<keyof Actions, any>> = ADT<T>;

export interface FunctionsResult {
  message: 'success';
  status: 'ok';
}

export interface IFuncCheckReCaptcha {
  token: string;
}

export interface IFuncCheckEmailRequest extends IFuncCheckReCaptcha {
  email: string;
}

export interface IFuncCheckEmailResult {
  email?: string;
  displayName?: string;
  photoURL?: string;
  providerId: string;
}

export interface IFirestoreQuery<T extends Doc> {
  orderBy?: keyof T;
  where?: [string | FieldPath, WhereFilterOp, unknown];
  limit?: number;
}

export interface IFuncCheckUnauthorizedAccountAccessRequest {
  originalWorkspaceId: string;
  projectId: string;
  viewId?: string;
  language: ILanguageType;
}

export interface IFuncCheckUnauthorizedAccountAccessResult {
  originalWorkspaceId: string;
  workspaceId: string;
  projectId: string;
  viewId: string;
}

export interface IFuncCreateWorkspace {
  workspaceName: Workspace['workspaceName'];
}

export interface IFuncCreateWorkspaceResult {
  workspaceId: Workspace['id'];
}

export interface IFuncUpdateOriginalWorkspaceId {
  workspaceId: Workspace['id'];
  originalWorkspaceId: Workspace['originalWorkspaceId'];
}

export interface IFuncCreateProject {
  projectName: Project['projectName'];
  viewName: View['viewName'];
  viewType: View['viewType'];
}

export interface IFbFuncCreateProject extends IFuncCreateProject {
  workspaceId: MyWorkspace['workspaceId'];
}

export interface IFuncCreateProjectResult {
  projectId: Project['id'];
  viewId: View['id'];
}

export interface IFuncCopyProject {
  toWorkspaceId: Workspace['id'];
  projectId: Project['id'];
}

export interface IFbFuncCopyProject {
  fromWorkspaceId: Workspace['id'];
  toWorkspaceId: Workspace['id'];
  projectId: Project['id'];
}

export interface IFuncCopyProjectResult {
  projectId: Project['id'];
}

export interface IFuncManageMembers {
  workspaceId: string;
  removeMembers: string[];
  inviteMembers: string[];
  removeInvitingMembers: string[];
  adminMembers: string[];
  language: string;
}

export interface IFuncExitWorkspace {
  workspaceId: MyWorkspace['workspaceId'];
}

export interface IFuncCheckInvitingMemberExistenceRequest {
  originalWorkspaceId: Workspace['originalWorkspaceId'];
  invitingMemberId: InvitingMember['id'];
}

export interface IFuncCheckInvitingMemberExistenceResult {
  workspaceName: Workspace['workspaceName'];
}

export interface IFuncCheckInvitingGuestExistenceRequest {
  originalWorkspaceId: Workspace['originalWorkspaceId'];
  invitingGuestId: InvitingGuest['id'];
}
export interface IFuncInviteGuestRequest {
  originalWorkspaceId: Workspace['id'];
  invitingGuestId: InvitingGuest['id'];
}

export interface IFuncCheckInvitingGuestExistenceResult {
  workspaceName: Workspace['workspaceName'];
  projectName: Project['projectName'];
}

export interface IFuncGetStripeCustomerPortalUrlRequest {
  workspaceId: Workspace['id'];
}

export interface IFuncGetStripeCustomerPortalUrlResult {
  customerPortalUrl: string;
}

export interface IFuncGetStripeCheckoutUrlRequest {
  workspaceId: Workspace['id'];
}

export interface IFuncGetStripeCheckoutUrlResult {
  checkoutUrl: string;
}

export interface IFuncCreateStripeSessionRequest {
  workspaceId: Workspace['id'];
  sessionId: string;
}

export interface IFuncRemoveStripeSessionRequest {
  workspaceId: Workspace['id'];
  sessionId: string;
}

export interface IFuncCheckAccountDeletableResult {
  workspaceIds: string[];
}

export type CreateUpdateItemStatus = Omit<
  Status,
  | 'shareItemCount'
  | 'nonShareItemCount'
  | 'sysCreatedAt'
  | 'sysCreatedBy'
  | 'sysUpdatedAt'
  | 'sysUpdatedBy'
>;

export type CreateUpdateItemItem = Omit<
  Item,
  | 'likeCount'
  | 'commentCount'
  | 'isShare'
  | 'itemCreatedAt'
  | 'itemCreatedBy'
  | 'itemUpdatedAt'
  | 'itemUpdatedBy'
  | 'sysCreatedAt'
  | 'sysCreatedBy'
  | 'sysUpdatedAt'
  | 'sysUpdatedBy'
>;

export type CreateUpdateItemBody = Omit<
  Body,
  'sysCreatedAt' | 'sysCreatedBy' | 'sysUpdatedAt' | 'sysUpdatedBy'
>;

export type CreateUpdateItemProperty =
  | Omit<
      TextProperty,
      | 'isShare'
      | 'sysCreatedAt'
      | 'sysCreatedBy'
      | 'sysUpdatedAt'
      | 'sysUpdatedBy'
    >
  | Omit<
      SingleSelectProperty,
      | 'isShare'
      | 'sysCreatedAt'
      | 'sysCreatedBy'
      | 'sysUpdatedAt'
      | 'sysUpdatedBy'
    >
  | Omit<
      FileProperty,
      | 'isShare'
      | 'sysCreatedAt'
      | 'sysCreatedBy'
      | 'sysUpdatedAt'
      | 'sysUpdatedBy'
    >
  | Omit<
      DateProperty,
      | 'isShare'
      | 'sysCreatedAt'
      | 'sysCreatedBy'
      | 'sysUpdatedAt'
      | 'sysUpdatedBy'
    >
  | Omit<
      NumberProperty,
      | 'isShare'
      | 'sysCreatedAt'
      | 'sysCreatedBy'
      | 'sysUpdatedAt'
      | 'sysUpdatedBy'
    >
  | Omit<
      CheckboxProperty,
      | 'isShare'
      | 'sysCreatedAt'
      | 'sysCreatedBy'
      | 'sysUpdatedAt'
      | 'sysUpdatedBy'
    >
  | Omit<
      MultiSelectProperty,
      | 'isShare'
      | 'sysCreatedAt'
      | 'sysCreatedBy'
      | 'sysUpdatedAt'
      | 'sysUpdatedBy'
    >
  | Omit<
      InchargeProperty,
      | 'isShare'
      | 'sysCreatedAt'
      | 'sysCreatedBy'
      | 'sysUpdatedAt'
      | 'sysUpdatedBy'
    >;

export type CreateUpdateItemPropertyValue = Omit<
  PropertyValue,
  'isShare' | 'sysCreatedAt' | 'sysCreatedBy' | 'sysUpdatedAt' | 'sysUpdatedBy'
>;

export interface IFuncMemberCreateItem {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
  viewId: View['id'];
  createStatuses: CreateUpdateItemStatus[];
  updateStatuses: CreateUpdateItemStatus[];
  deleteStatuses: Status['id'][];
  statusOrder: StatusOrder['orderList'];
  item: CreateUpdateItemItem;
  body: CreateUpdateItemBody;
  itemOrder: ItemOrder['orderList'];
  createProperties: CreateUpdateItemProperty[];
  updateProperties: CreateUpdateItemProperty[];
  deleteProperties: Property['id'][];
  propertyOrderList: PropertyOrder['orderList'];
  viewPropertyOrderList: View['propertyOrderList'];
  createPropertyValues: CreateUpdateItemPropertyValue[];
  updatePropertyValues: CreateUpdateItemPropertyValue[];
}

export interface IFuncMemberUpdateItem {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
  viewId: View['id'];
  createStatuses: CreateUpdateItemStatus[];
  updateStatuses: CreateUpdateItemStatus[];
  deleteStatuses: Status['id'][];
  statusOrder: StatusOrder['orderList'];
  item: CreateUpdateItemItem;
  body: CreateUpdateItemBody;
  createProperties: CreateUpdateItemProperty[];
  updateProperties: CreateUpdateItemProperty[];
  deleteProperties: Property['id'][];
  propertyOrderList: PropertyOrder['orderList'];
  viewPropertyOrderList: View['propertyOrderList'];
  createPropertyValues: CreateUpdateItemPropertyValue[];
  updatePropertyValues: CreateUpdateItemPropertyValue[];
}

export interface IFuncGuestCreateItem {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
  item: CreateUpdateItemItem;
  body: CreateUpdateItemBody;
  itemOrder: ItemOrder['orderList'];
  createPropertyValues: CreateUpdateItemPropertyValue[];
}

export interface IFuncGuestUpdateItem {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
  item: CreateUpdateItemItem;
  body: CreateUpdateItemBody;
  updatePropertyValues: CreateUpdateItemPropertyValue[];
}

export interface IFuncCreateUpdateItemResult {
  itemId: Item['id'];
}

export interface IFuncCopyItem {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
  itemId: Item['id'];
}

export interface IFuncCopyItemResult {
  itemId: Item['id'];
}

export interface IFuncCreateSlackTokenRequest {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
  code: string;
  state: string;
}

export interface IFuncGetSlackTeamInfoRequest {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
}

export interface IFbFuncInviteGuest {
  workspaceId: MyWorkspace['workspaceId'];
  projectIds: Array<Project['id']>;
  email: Auth['email'];
  language: ILanguageType;
}

export type ILoginMode = ValueOf<typeof LOGIN_MODE>;

/**
 * Google Tag Manager の ページアクセスdataLayer 用
 */
export interface ITagManagerDataLayer {
  dataLayer: {
    event: string;
    workspaceName: MyWorkspace['workspaceName'];
    workspaceId: MyWorkspace['id'];
    displayName: Profile['displayName'];
    accountId: Account['uid'];
    email: Account['email'];
    planName?: Plan['type'];
  };
  dataLayerName?: string;
}

interface BaseSearchProperty {
  propertyId: string;
  propertyType: IPropertyType;
}

interface StringSearchProperty extends BaseSearchProperty {
  propertyType: 'text' | 'singleSelect' | 'checkbox';
  stringValue?: string;
}
interface ArraySearchProperty extends BaseSearchProperty {
  propertyType: 'multiSelect' | 'incharge';
  arrayValue?: string[];
}
interface NumberSearchProperty extends BaseSearchProperty {
  propertyType: 'number';
  numberFrom?: number;
  numberTo?: number;
}
interface DateSearchProperty extends BaseSearchProperty {
  propertyType: 'date';
  numberFrom?: number;
  numberTo?: number;
  isDueDate?: boolean;
}
interface FileSearchProperty extends BaseSearchProperty {
  propertyType: 'file';
  isNotEmptyFile?: boolean;
}
export type SearchProperty =
  | StringSearchProperty
  | ArraySearchProperty
  | NumberSearchProperty
  | DateSearchProperty
  | FileSearchProperty;

export interface BasicSearchFilter {
  likeCountFrom?: number;
  likeCountTo?: number;
  commentCountFrom?: number;
  commentCountTo?: number;
  createdBys?: string[];
  updatedBys?: string[];
  createdAtFrom?: number;
  createdAtTo?: number;
  updatedAtFrom?: number;
  updatedAtTo?: number;
  statusIds?: string[];
}

export interface SearchFilter {
  basicFilter: BasicSearchFilter;
  searchProperties: SearchProperty[];
}

export interface IFuncSearchItemsRequest {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
  keyword: string | null | undefined;
  basicFilter?: BasicSearchFilter;
  searchProperties?: SearchProperty[];
}

export interface IFuncSearchItemsResult {
  itemIds: string[];
}

export interface IEmoji {
  [key: string]: {
    id: string;
    name: string;
    keywords: string[];
    skins: {
      unified: string;
      native: string;
    }[];
    version: number;
  };
}

export interface CommentReactionGroupUser {
  reactionId: string;
  uid: string;
}

export interface CommentReactionGroup {
  emojiId: string;
  row: CommentReactionGroupUser[];
}
