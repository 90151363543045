import * as React from 'react';

import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

export interface IIconBtnProps {
  children: React.ReactChild;
  tooltipMessage: string;
  primary?: boolean;
  active?: boolean;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

/**
 * 主にエディターで使う用のアイコンボタン
 * @param props
 * @returns
 */
export default function IconBtn(props: IIconBtnProps) {
  const { children, tooltipMessage, active, primary, onClick, disabled } =
    props;

  const className = React.useMemo(() => {
    const colorClass = (() => {
      switch (primary) {
        case true: {
          if (active) {
            return 'bg-primary-400 dark:bg-primary-500';
          }
          return '';
        }

        default: {
          if (active) {
            return 'bg-gray-100 dark:bg-gray-700';
          }
          return '';
        }
      }
    })();
    return classNames(
      colorClass,
      'border border-transparent px-1 py-1 rounded-md dark:text-gray-300 dark:bg-gray-800',
      disabled
        ? 'cursor-not-allowed opacity-40'
        : 'dark:hover:bg-gray-700 hover:bg-gray-100',
    );
  }, [active, disabled]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [tooltipMessage]);

  return (
    <div data-tip={tooltipMessage} className="inline-block">
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={className}
      >
        {children}
      </button>
    </div>
  );
}
