import { RefObject, useEffect, useRef } from 'react';

// ショートカットキー用の検知hooks
type KeyBindProps = {
  ctrlOrCmdKey?: boolean;
  key: KeyboardEvent['key'];
  onKeyDown?: (event: KeyboardEvent) => void;
  targetRef?: RefObject<HTMLElement>;
};

function useLatest<T>(value: T) {
  const ref = useRef(value);
  ref.current = value;
  return ref;
}

export default function useKeyBind({
  ctrlOrCmdKey,
  key,
  onKeyDown,
  targetRef,
}: KeyBindProps) {
  const onKeyDownLatest = useLatest(onKeyDown);

  // 一応どんなキーでも対応できるように定義
  useEffect(() => {
    const eventListener = (event: KeyboardEvent) => {
      // lexicalにfocusが当たっているときはのぞく
      const elem = document.activeElement;
      if (
        !(
          ctrlOrCmdKey &&
          (event.metaKey || event.ctrlKey) &&
          event.key === key &&
          elem?.hasAttribute('data-lexical-editor') === false
        )
      )
        return;

      event.preventDefault();
      onKeyDownLatest.current?.(event);
    };

    if (targetRef?.current) {
      const target = targetRef.current;

      target.addEventListener('keydown', eventListener);
      return () => target.removeEventListener('keydown', eventListener);
    }
    window.addEventListener('keydown', eventListener);
    return () => window.removeEventListener('keydown', eventListener);
  }, [ctrlOrCmdKey, key, onKeyDownLatest, targetRef]);
}
