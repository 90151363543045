import { EV } from '@/@types/events';
import React, { forwardRef, LegacyRef } from 'react';

import { classNames } from '@/libs/styleUtils';

export interface ISelectOption {
  value: string;
  label: string;
}

export interface ISelectProps {
  name: string;
  options: ISelectOption[];
  value?: string;
  label?: string;
  disabled?: boolean;
  onChange?: (event: EV<HTMLSelectElement>) => void;
}

export default forwardRef(
  (props: ISelectProps, ref: LegacyRef<HTMLSelectElement>) => {
    const { name, options, value, label, disabled, onChange } = props;
    return (
      <div className="sm:col-span-6">
        {label && (
          <label
            htmlFor={name}
            className="block font-medium text-gray-700 dark:text-gray-400"
          >
            {label}
          </label>
        )}
        <div className={label ? 'w-full mt-1' : ''}>
          <select
            id={name}
            ref={ref}
            autoComplete={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            className={classNames(
              disabled
                ? 'bg-gray-200 text-gray-500 dark:bg-gray-800 dark:text-gray-400 opacity-50'
                : 'bg-white dark:bg-gray-700',
              'block w-full text-gray-700 bg-white border border-gray-300 rounded-md dark:border-gray-600 shadow-sm dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 placeholder:text-gray-300 dark:placeholder:text-gray-500 font-normal',
            )}
          >
            {options.map((opt) => (
              <option value={opt.value} key={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  },
);
