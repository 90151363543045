import { IPlanType, Plan, RootState } from '@/@types/models';

import { Transition } from '@headlessui/react';
import { MinusIcon, CheckIcon } from '@heroicons/react/24/outline';

import { DefaultTFuncReturn } from 'i18next';
import React, { Fragment, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { COLLABBLE_OFFICIAL, PLANS } from '@/libs/const';

import { classNames } from '@/libs/styleUtils';

import { Badge } from '@/components/Common/Badge';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import PlanContactDialog from '@/components/Common/PlanContactDialog';

import useAccounts from '@/hooks/useAccounts';
import useAdminMembers from '@/hooks/useAdminMembers';
import useCheckoutSessions from '@/hooks/useCheckoutSessions';
import useMentions from '@/hooks/useMentions';
import usePlans from '@/hooks/usePlans';

export interface IAccountProps {}

export interface IPlanTier {
  name: string | DefaultTFuncReturn;
  type: IPlanType;
  priceMonthly: string | DefaultTFuncReturn;
  priceSub?: string | DefaultTFuncReturn;
  description: string | DefaultTFuncReturn;
  descriptionBadge?: string | DefaultTFuncReturn;
}

export interface IPlanFeatureTier {
  free?: boolean | string | DefaultTFuncReturn;
  standard?: boolean | string | DefaultTFuncReturn | undefined;
}

export interface IPlanFeature {
  name: string | DefaultTFuncReturn | undefined;
  tiers: IPlanFeatureTier;
}

export interface IPlanSection {
  name: string | DefaultTFuncReturn | undefined;
  features: IPlanFeature[];
}

interface IBuyButtonProps {
  className?: string;
  tier: IPlanTier;
  plan: Plan;
}

/**
 * 購入ボタン
 * @param props
 * @returns
 */
function BuyButton(props: IBuyButtonProps) {
  const { t } = useTranslation();
  const { className, tier, plan } = props;

  const { checkoutSession } = useCheckoutSessions();
  const { account } = useAccounts();
  const { membersDic } = useMentions();

  const checkoutMember = useMemo(() => {
    if (!checkoutSession || !membersDic) return null;
    return membersDic[checkoutSession?.uid as string];
  }, [membersDic, checkoutSession?.uid]);

  const [planContactDialogOpen, setPlanContactDialogOpen] =
    useState<boolean>(false);
  const { adminMember } = useAdminMembers();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  /**
   * 管理者ユーザー判定
   */
  const isAdmin = useMemo(() => {
    if (!adminMember.memberList) return false;
    return adminMember.memberList.includes(userId);
  }, [userId, adminMember.memberList]);

  // 手続き中、別のユーザーが契約済みの場合はボタンを非表示
  if (!plan || plan?.isWaitingInvoicePaid || !isAdmin) return null;

  // 現在フリープランでフリープランのボタンを表示
  if (plan.type === PLANS.FREE && tier.type === plan.type) {
    return (
      <MenuButton
        id="currentPlan"
        type="text"
        disabled
        className={classNames('shadow !py-2 !font-semibold', className)}
      >
        {t('現在のプラン')}
      </MenuButton>
    );
  }

  if (checkoutSession && checkoutSession.uid !== account?.uid) {
    return (
      <MenuButton
        id="buyPlan"
        type="text"
        disabled
        className={classNames('shadow !py-2 !font-semibold', className)}
      >
        {t('<displayName>によってチェックアウト中です', {
          displayName: checkoutMember?.displayName,
        })}
      </MenuButton>
    );
  }

  // 現在フリープランでスタンダードプランのボタンを表示
  if (plan.type === PLANS.FREE && tier.type === PLANS.STANDARD) {
    return (
      <div>
        <MenuButton
          id="buyPlan"
          onClick={() => setPlanContactDialogOpen(true)}
          type="text"
          variant="primary"
          className={classNames('shadow !py-2 !font-semibold', className)}
        >
          {t('アップグレードに関する問い合わせ')}
        </MenuButton>
        <Transition.Root show={planContactDialogOpen} unmount>
          <PlanContactDialog onClose={() => setPlanContactDialogOpen(false)} />
        </Transition.Root>
      </div>
    );
  }
  // 現在フリープランでフリープランのボタンを表示
  if (plan.type === PLANS.STANDARD && tier.type === plan.type) {
    return (
      <div className="text-center">
        <MenuButton
          id="cancelPlan"
          onClick={() => setPlanContactDialogOpen(true)}
          type="text"
          variant="primary"
          className={classNames('shadow !py-2 !font-semibold', className)}
        >
          {t('メンバー数変更に関する問い合わせ')}
        </MenuButton>
        <MenuButton
          id="cancelPlan"
          onClick={() => setPlanContactDialogOpen(true)}
          type="text"
          className="w-full border-0 shadow-none !py-2 !mt-5"
        >
          {t('解約に関する問い合わせ')}
        </MenuButton>
        <Transition.Root show={planContactDialogOpen} unmount>
          <PlanContactDialog onClose={() => setPlanContactDialogOpen(false)} />
        </Transition.Root>
      </div>
    );
  }
  // 現在フリープランでチームプランのボタンを表示
  if (plan.type === PLANS.STANDARD && tier.type === PLANS.FREE) {
    return (
      <div>
        <MenuButton
          id="downGrade"
          type="text"
          className={classNames('shadow !py-2 !font-semibold', className)}
          onClick={() => setPlanContactDialogOpen(true)}
        >
          {t('ダウングレードに関する問い合わせ')}
        </MenuButton>
        <div className="hidden mt-9 lg:block">&nbsp;</div>
        <Transition.Root show={planContactDialogOpen} unmount>
          <PlanContactDialog onClose={() => setPlanContactDialogOpen(false)} />
        </Transition.Root>
      </div>
    );
  }
  return null;
}

/**
 * プラン
 * @param props
 * @returns
 */
export default function Plans() {
  const { t } = useTranslation();
  const { plan } = usePlans();
  const { account } = useAccounts();
  const { membersDic } = useMentions();

  const customerMember = useMemo(() => {
    if (!plan?.customerId || !membersDic) return null;
    return {
      displayName:
        plan.customerId === COLLABBLE_OFFICIAL.CUSTOMER_ID
          ? COLLABBLE_OFFICIAL.DISPLAY_NAME
          : membersDic[plan.customerId as string].displayName,
    };
  }, [membersDic, plan?.customerId]);

  const tiers: IPlanTier[] = useMemo(
    () => [
      {
        name: t('フリープラン'),
        type: PLANS.FREE,
        priceMonthly: t('無料'),
        description: t(
          '基本的な機能が利用可能。コラボレーションを始めてみたい個人や小規模チーム向け。',
        ),
      },
      {
        name: t('スタンダードプラン'),
        type: PLANS.STANDARD,
        priceMonthly: t('￥1,480'),
        priceSub: t('メンバー1名あたりの月額*'),
        description: t(
          '柔軟な公開設定が可能。組織外の関係者ともコラボレーションを行うチーム向け。',
        ),
        descriptionBadge: t('*5名（¥7,400/月）から開始'),
      },
    ],
    [],
  );

  const sections: IPlanSection[] = useMemo(
    () => [
      {
        name: t('メイン'),
        features: [
          {
            name: t('メンバー数'),
            tiers: { free: t('~5'), standard: t('5~') },
          },
          {
            name: t('ゲスト招待数'),
            tiers: { free: t('無制限'), standard: t('無制限') },
          },
          {
            name: t('ゲスト権限'),
            tiers: {
              free: t('コメント'),
              standard: t('編集 / コメント / 閲覧'),
            },
          },
          {
            name: t('リンク招待'),
            tiers: { free: true, standard: true },
          },
          // TODO:埋め込みリンク実装時に追加
          // {
          //   name: t('埋め込みリンク'),
          //   tiers: { standard: 'Coming soon' },
          // },
          {
            name: t('プロジェクト数'),
            tiers: { free: '5', standard: '100' },
          },
          {
            name: t('添付ファイル容量'),
            tiers: {
              free: t('10MB / 1ファイル'),
              standard: t('100MB / 1ファイル'),
            },
          },
        ],
      },
      {
        name: t('ビュー'),
        features: [
          {
            name: t('リスト'),
            tiers: { free: true, standard: true },
          },
          {
            name: t('カンバン'),
            tiers: { free: true, standard: true },
          },
          {
            name: t('掲示板'),
            tiers: { standard: true },
          },
          // {
          //   name: t('タイムライン'),
          //   tiers: { team: true },
          // },
        ],
      },
      {
        name: t('アイテム'),
        features: [
          {
            name: t('アイテム数'),
            tiers: { free: t('無制限'), standard: t('無制限') },
          },
          {
            name: t('プロパティ数'),
            tiers: { free: t('無制限'), standard: t('無制限') },
          },
          {
            name: t(
              '基本プロパティ（テキスト、セレクト、日付、数値、チェックボックス、ファイル）',
            ),
            tiers: { free: true, standard: true },
          },
          // {
          //   name: t('期間プロパティ'),
          //   tiers: { team: 'Coming soon' },
          // },
          // {
          //   name: t('依存関係プロパティ'),
          //   tiers: { team: 'Coming soon' },
          // },
          // {
          //   name: t('関数プロパティ'),
          //   tiers: { team: 'Coming soon' },
          // },
          // {
          //   name: t('リストプロパティ'),
          //   tiers: { team: 'Coming soon' },
          // },
          {
            name: t('公開詳細設定'),
            tiers: { standard: true },
          },
          // {
          //   name: t('バージョン履歴'),
          //   tiers: { team: 'Coming soon' },
          // },
        ],
      },
      // {
      //   name: t('管理'),
      //   features: [
      //     {
      //       name: t('エクスポート'),
      //       tiers: { team: 'Coming soon' },
      //     },
      //     {
      //       name: t('Slack連携'),
      //       tiers: { free: 'Coming soon', team: 'Coming soon' },
      //     },
      //     {
      //       name: t('ダッシュボード'),
      //       tiers: { team: 'Coming soon' },
      //     },
      //   ],
      // },
      // {
      //   name: t('ブランド'),
      //   features: [
      //     {
      //       name: t('ロゴ非表示'),
      //       tiers: { team: true },
      //     },
      //   ],
      // },
      // {
      //   name: t('API'),
      //   features: [
      //     {
      //       name: t('REST API'),
      //       tiers: { free: true, team: true },
      //     },
      //     {
      //       name: t('コール数上限'),
      //       tiers: { free: '60回/60秒', team: '60回/60秒' },
      //     },
      //   ],
      // },
      {
        name: t('サポート'),
        features: [
          {
            name: t('問い合わせ方法'),
            tiers: {
              free: t('問い合わせフォーム, 操作ガイド'),
              standard: t('問い合わせフォーム, 操作ガイド'),
            },
          },
          {
            name: t('対応時間'),
            tiers: {
              free: t('３営業日以内'),
              standard: t('１営業日以内'),
            },
          },
        ],
      },
    ],
    [],
  );

  return (
    <div className="p-4">
      {plan?.isWaitingInvoicePaid && (
        <Badge
          color="amber"
          className="w-full mb-4 !py-2 !px-3"
          content={t(
            'ただいまご契約手続き中です。完了までしばらくお待ちください。',
          )}
        />
      )}
      {!plan?.isWaitingInvoicePaid &&
        plan?.customerId &&
        plan?.customerId !== account?.uid && (
          <Badge
            color="sky"
            className="w-full mb-4 !py-2 !px-3"
            content={t('ワークスペースは <displayName> によって契約中です', {
              displayName: customerMember?.displayName,
            })}
          />
        )}
      <h1 className="flex items-center mb-6 text-xl font-semibold xs:text-l justify-content-center">
        {t('プラン')}
      </h1>
      {/* Comparison table */}
      <div className="max-w-2xl mb-16 lg:max-w-7xl">
        {/* xs to lg */}
        <div className="space-y-14 lg:hidden">
          <div className="px-4">
            {/* <a
              href={FAQ_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              {t('よくある質問はこちら')}
            </a> */}
          </div>{' '}
          {tiers.map((tier) => (
            <section key={tier.type}>
              <div className="px-4 mb-8">
                <h2 className="text-lg font-medium leading-6">{tier.name}</h2>
                <p className="mt-4">
                  {tier.priceMonthly ? (
                    <>
                      <span className="text-2xl font-extrabold">
                        {tier.priceMonthly}
                      </span>
                      <span className="ml-2 text-sm text-gray-500 dark:text-gray-400">
                        {tier.priceSub}
                      </span>
                    </>
                  ) : (
                    <div>
                      <MinusIcon
                        className="w-6 h-6 text-gray-400 dark:text-gray-600"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </p>
                <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                  {tier.description}
                </p>
                {tier.descriptionBadge !== undefined && (
                  <p className="mt-4 text-sm text-gray-400 rounded px-2.5 py-0.5 bg-black/5 dark:text-gray-400 dark:bg-white/10">
                    {tier.descriptionBadge}
                  </p>
                )}
                <BuyButton plan={plan as Plan} tier={tier} className="!mt-6" />
              </div>
              {sections.map((section) => (
                <table key={section.name} className="w-full">
                  <caption className="px-4 py-3 text-sm font-medium text-left border-t border-gray-200 dark:border-gray-600 bg-gray-50 dark:bg-gray-700">
                    {section.name}
                  </caption>
                  <thead>
                    <tr>
                      <th className="sr-only" scope="col">
                        Feature
                      </th>
                      <th className="sr-only" scope="col">
                        Included
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                    {section.features.map((feature) => (
                      <tr
                        key={feature.name}
                        className="border-t border-gray-200 dark:border-gray-600"
                      >
                        <th
                          className="px-4 py-5 text-sm font-normal text-left text-gray-500 dark:text-gray-400"
                          scope="row"
                        >
                          {feature.name}
                        </th>
                        <td className="py-5 pr-4">
                          {typeof feature.tiers[
                            tier.type as keyof IPlanFeatureTier
                          ] === 'string' ? (
                            <span className="block text-sm text-right text-gray-700">
                              {
                                feature.tiers[
                                  tier.type as keyof IPlanFeatureTier
                                ]
                              }
                            </span>
                          ) : (
                            <>
                              {feature.tiers[
                                tier.type as keyof IPlanFeatureTier
                              ] === true ? (
                                <CheckIcon
                                  className="w-5 h-5 ml-auto text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="w-5 h-5 ml-auto text-gray-400"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[
                                  tier.type as keyof IPlanFeatureTier
                                ] === true
                                  ? 'Yes'
                                  : 'No'}
                              </span>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}

              <div className="px-4 pt-5 border-t border-gray-200 dark:border-gray-600">
                <BuyButton plan={plan as Plan} tier={tier} />
              </div>
            </section>
          ))}
        </div>
        {/* lg+ */}
        <div className="hidden lg:block">
          <table className="w-full h-px table-fixed">
            <caption className="sr-only">Pricing plan comparison</caption>
            <thead>
              <tr>
                <th
                  className="pb-4 pl-6 pr-6 text-sm font-medium text-left"
                  scope="col"
                >
                  <span className="sr-only">Feature by</span>
                  <span>{t('プラン')}</span>
                </th>
                {tiers.map((tier) => (
                  <th
                    key={tier.type}
                    className="relative w-1/3 px-6 pb-4 text-lg font-medium text-left leading-6"
                    scope="col"
                  >
                    {tier.name}
                    {plan?.type === tier.type && (
                      <Badge
                        content={t('現在のプラン')}
                        color="blue"
                        size="xs"
                        className="absolute left-6 -top-8"
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="border-t border-gray-200 dark:border-gray-600 divide-y divide-gray-200 dark:divide-gray-600">
              <tr>
                <th
                  className="relative py-8 pl-6 pr-6 text-sm font-medium text-left align-top "
                  scope="row"
                >
                  {t('料金')}

                  <div className="absolute bottom-0 left-0 right-0 px-6 pb-10">
                    {/*  <a
                      href={FAQ_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      {t('よくある質問はこちら')}
                    </a> */}
                  </div>
                </th>
                {tiers.map((tier) => (
                  <td key={tier.type} className="h-full px-6 py-8 align-top">
                    <div className="flex flex-col justify-between h-full">
                      <div className="mb-3">
                        <p>
                          {tier.priceMonthly ? (
                            <>
                              <span className="text-3xl font-extrabold">
                                {tier.priceMonthly}
                              </span>
                              <span className="ml-2 text-sm text-gray-500 dark:text-gray-400">
                                {tier.priceSub}
                              </span>
                            </>
                          ) : (
                            <div>
                              <MinusIcon
                                className="text-gray-200 w-9 h-9 dark:text-gray-600"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </p>
                        <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">
                          {tier.description}
                        </p>
                        {tier.descriptionBadge !== undefined && (
                          <p className="mt-4 text-sm text-gray-400 rounded px-2.5 py-0.5 bg-black/5 dark:text-gray-400 dark:bg-white/10">
                            {tier.descriptionBadge}
                          </p>
                        )}
                      </div>
                      <BuyButton
                        plan={plan as Plan}
                        tier={tier}
                        className="mt-6"
                      />
                    </div>
                  </td>
                ))}
              </tr>
              {sections.map((section) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      className="py-3 pl-6 text-sm font-medium text-left bg-gray-50 dark:bg-gray-700"
                      colSpan={tiers.length + 1}
                      scope="colgroup"
                    >
                      {section.name}
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th
                        className="py-5 pl-6 pr-6 text-sm font-normal text-left text-gray-500 dark:text-gray-400"
                        scope="row"
                      >
                        {feature.name}
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.type} className="px-6 py-5">
                          {typeof feature.tiers[
                            tier.type as keyof IPlanFeatureTier
                          ] === 'string' ? (
                            <span className="block text-sm text-gray-700 dark:text-gray-200">
                              {
                                feature.tiers[
                                  tier.type as keyof IPlanFeatureTier
                                ]
                              }
                            </span>
                          ) : (
                            <>
                              {feature.tiers[
                                tier.type as keyof IPlanFeatureTier
                              ] === true ? (
                                <CheckIcon
                                  className="w-5 h-5 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <MinusIcon
                                  className="w-5 h-5 text-gray-200 dark:text-gray-600"
                                  aria-hidden="true"
                                />
                              )}

                              <span className="sr-only">
                                {feature.tiers[
                                  tier.type as keyof IPlanFeatureTier
                                ] === true
                                  ? 'Included'
                                  : 'Not included'}{' '}
                                in {tier.name}
                              </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-200 dark:border-gray-600">
                <th className="sr-only" scope="row">
                  Choose your plan
                </th>
                {tiers.map((tier) => (
                  <td key={tier.type} className="px-6 pt-5">
                    <BuyButton plan={plan as Plan} tier={tier} />
                  </td>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}
