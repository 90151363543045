import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RootNode } from 'lexical';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';

import BaseLexicalEditor, {
  IBaseLexicalEditorHandler,
} from '@/components/Common/Lexical/BaseLexicalEditor';

import editorConfig from '@/components/Common/Lexical/configs/editorConfig';
import Toolbar, {
  IToolbarHandler,
} from '@/components/Common/Lexical/editors/itemText/Toolbar';

// 外部コンポーネントから命令的に実行できるインターフェース
export type IItemEditorHandler = {
  save(): { jsonState: string; textState: string };
  setContent: (content: string) => void;
  getHtmlString: () => string;
  setChildDisabled: (bool: boolean) => void;
};

type IItemEditorProps = {
  initialData?: string;
  className?: string;
  onChange: (htmlString: string) => void;
  send: () => void;
};

/**
 * Lexicalエディタ(詳細用)
 * @param props
 * @returns
 */

const ItemEditor = forwardRef<IItemEditorHandler, IItemEditorProps>(
  (props: IItemEditorProps, ref) => {
    const { t } = useTranslation();
    const [htmlString, setHtmlString] = useState('');
    const toolbarRef = useRef({} as IToolbarHandler);
    const lexicalRef = useRef({} as IBaseLexicalEditorHandler);
    const { className, onChange, initialData, send } = props;

    const saveContent = useCallback(() => {
      const content = toolbarRef.current.save();
      return content;
    }, []);

    const setContent = useCallback(async (content) => {
      if (!lexicalRef.current) return;
      lexicalRef.current.setContent(content);
    }, []);

    const initialConfig = {
      namespace: 'ItemEditor',
      readOnly: false,
      ...editorConfig,
    };

    // 外部コンポーネントから命令的に実行できるメソッド
    useImperativeHandle(ref, () => ({
      save(): any {
        return saveContent();
      },
      async setContent(content: string) {
        if (!lexicalRef.current) return;
        lexicalRef.current.setContent(content);
      },
      getHtmlString(): string {
        return htmlString;
      },
      async setChildDisabled(bool: boolean) {
        if (!lexicalRef.current) return;
        lexicalRef.current.setChildDisabled(bool);
      },
    }));

    useEffect(() => {
      ReactTooltip.rebuild();
    }, []);

    const emitChange = useCallback(
      (rootNode: RootNode, dirtyFlg: boolean, html: string) => {
        setHtmlString(html);
        onChange(html);
      },
      [],
    );

    useEffect(() => {
      if (initialData) {
        // flushSyncエラー回避用のsetTimeout
        setTimeout(() => {
          setContent(initialData);
        }, 100);
      }
    }, [initialData]);

    // `Ctrl + Enter` 押下時ハンドラ
    const onCtrlEnter = () => {
      send();
    };

    return (
      <LexicalComposer initialConfig={initialConfig}>
        <Toolbar ref={toolbarRef} className={className}>
          <BaseLexicalEditor
            onCtrlEnter={onCtrlEnter}
            ref={lexicalRef}
            handleChange={emitChange}
            placeholder={t('本文を入力してください')}
          />
        </Toolbar>
      </LexicalComposer>
    );
  },
);

export default ItemEditor;
