import React from 'react';

import { classNames } from '@/libs/styleUtils';

export interface IRedDotProps {
  className?: string;
}

/**
 * 赤いドット
 * @param props
 * @returns
 */
export default function RedDot(props: IRedDotProps) {
  const { className } = props;

  return (
    <div
      className={classNames(
        'w-2 h-2 overflow-hidden text-xs leading-none bg-red-400 rounded-full dark:bg-red-700',
        className,
      )}
    />
  );
}
