import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartSolidIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

import '@/components/Common/Icon/HeartIconBtn/HeartIconBtn.css';

export interface IHeartIconBtnProps {
  like: boolean;
  onClick: () => void;
  disabled: boolean;
  size?: 'xs' | 'sm';
}

/**
 * いいねボタンアイコン
 * @param props
 * @returns
 */
export default function HeartIconBtn(props: IHeartIconBtnProps) {
  const { like, onClick, disabled, size } = props;
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  // ボタンクリック時
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsActive(true);
    onClick();
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'w-4 h-4';
      case 'sm':
        return 'w-7 h-7';
      default:
        return 'w-7 h-7';
    }
  }, [size]);

  // アイコンのレンダーハンドリング用メモ化関数
  const renderIcon = useMemo(() => {
    // 閲覧権限の場合いいねできない
    if (disabled) {
      if (!like) {
        return (
          <HeartIcon
            className={classNames(
              sizeClass,
              'absolute text-gray-400 hover:text-gray-400 dark:hover:text-gray-400',
            )}
            aria-hidden="true"
          />
        );
      }
      return (
        <HeartSolidIcon
          className={classNames(
            sizeClass,
            'absolute text-gray-400 hover:text-gray-400 dark:hover:text-gray-400',
          )}
          aria-hidden="true"
        />
      );
    }
    // いいねされていない場合
    if (!like) {
      return (
        <HeartIcon
          className={classNames(
            sizeClass,
            'absolute text-gray-400 hover:text-pink-500 dark:hover:text-pink-600',
          )}
          aria-hidden="true"
        />
      );
    }
    // アニメーションなし
    if (!isActive) {
      return (
        <HeartSolidIcon
          className={classNames(
            sizeClass,
            'absolute inset-0 text-pink-500 dark:text-pink-600',
          )}
          aria-hidden="true"
        />
      );
    }
    // アニメーションあり
    return (
      <>
        <div
          className="absolute inset-0 rounded-full opacity-0 bg-primary-400 dark:bg-primary-600 heart-icon-impact2"
          data-testid="heart-icon-anime-1"
        />
        <div
          className="absolute inset-0 rounded-full heart-icon-impact1"
          data-testid="heart-icon-anime-2"
        />
        <HeartSolidIcon
          className={classNames(
            sizeClass,
            'absolute inset-0 text-pink-500 heart-icon dark:text-pink-600',
          )}
          aria-hidden="true"
        />
      </>
    );
  }, [like, isActive, disabled]);

  return (
    <button
      type="button"
      className={
        disabled === true
          ? classNames(
              sizeClass,
              'relative inline-flex items-center justify-center mr-2 rounded-full heart-icon-container bg-transparent hover:text-gray-400',
            )
          : classNames(
              sizeClass,
              'relative inline-flex items-center justify-center mr-2 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-offset-2 heart-icon-container',
            )
      }
      onClick={handleClick}
      data-tip={t('いいね')}
      data-testid="heart-icon-button"
      disabled={disabled}
    >
      {renderIcon}
    </button>
  );
}
