import { View } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { VALIDATION_MODE, ViewTypes } from '@/libs/const';

import { getNavigatePath, t2s } from '@/libs/utils';

import { PROJECT_NAME, VIEW_NAME, VIEW_TYPE } from '@/libs/validations';

import ProjectRepository from '@/repositories/ProjectRepository';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import CreateViewForm from '@/components/View/CreateViewForm';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePlans from '@/hooks/usePlans';
import useSubmitState from '@/hooks/useSubmitState';

export default function NewProjectDialog(props: IModalDialogProps) {
  const { onClose } = props;
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<View['viewType']>(ViewTypes.LIST);
  const navigate = useNavigate();

  const { currentMyWorkspace } = useMyWorkspaces();

  const { isPossibleCreationProject, plan } = usePlans();

  const { workspaceId, originalWorkspaceId } = useMemo(
    () => ({
      workspaceId: currentMyWorkspace?.workspaceId,
      originalWorkspaceId: currentMyWorkspace?.originalWorkspaceId,
    }),
    [currentMyWorkspace],
  );

  const projectRepository = new ProjectRepository(workspaceId as string);

  // プロジェクト作成上限数に達していたらダイアログを強制的に閉じる
  useEffect(() => {
    if (plan && !isPossibleCreationProject) onClose();
  }, [isPossibleCreationProject, plan]);

  // Zod schema定義
  const schema = z.object({
    projectName: z
      .string()
      .min(1, { message: t('入力してください') })
      .max(
        PROJECT_NAME.max,
        t2s(t('<max>文字以内で入力してください', PROJECT_NAME)),
      ),
    viewType: z.enum(VIEW_TYPE),
    viewName: z
      .string()
      .min(1, { message: t('入力してください') })
      .max(VIEW_NAME.max, t2s(t('<max>文字以内で入力してください', VIEW_NAME))),
  });

  type SchemaType = z.infer<typeof schema>;

  // Zod Form
  const methods = useForm<SchemaType>({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      viewType: 'list',
    },
  });

  // mount時処理
  useEffect(() => {
    setViewType('list');
    const s = methods.setFocus;
    setTimeout(() => s('projectName'));
  }, []);
  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    try {
      // Create Project
      if (!originalWorkspaceId || !workspaceId) throw new Error();
      const res = await projectRepository.createProject(data);
      onClose();
      // Reset form state
      methods.reset({ ...data });
      // Go to new Workspace
      setTimeout(
        () =>
          navigate(
            getNavigatePath(originalWorkspaceId, res.projectId, res.viewId),
          ),
        100,
      );
    } catch (e) {
      // Reset form state
      methods.reset({ ...data });
      toast.error(t('プロジェクトの作成に失敗しました'));
      throw e;
    }
  });

  // viewのステート更新(ZODで管理できないためローカルのステートで管理)
  const handleViewSelect = (type: View['viewType']) => {
    setViewType(type);
  };

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <FormDialog
      onClose={onClose}
      title={t('プロジェクトを作成')}
      onSubmit={formSubmit}
    >
      <FormProvider {...methods}>
        <FormCol>
          <Input name="projectName" label={t('プロジェクト名')} />
        </FormCol>
        <CreateViewForm
          selectedView={viewType}
          onViewSelect={handleViewSelect}
          selectViewLabel={t('最初に追加するビュー')}
        />
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="createProject"
          disabled={isDisabledApply}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('追加')}
        </FormButton>
        <FormButton
          id="cancelCreateProject"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
