import { Comment, CommentReaction } from '@/@types/models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_COMMENTS,
  COL_COMMENT_REACTIONS,
  getCommentReactionsPath,
} from '@/libs/docPathUtils';

import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

export interface CommentReactions {
  [key: string]: CommentReaction[];
}
/**
 * commentsReactionsのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useCommentReactions(comments: Comment[]) {
  const { currentMyProject } = useProjects();
  const { currentItemId } = useItems();
  const { currentMyWorkspace } = useMyWorkspaces();
  // 現在サブスクリプション中のキー保持用
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );
  const itemId = useMemo(() => currentItemId ?? undefined, [currentItemId]);

  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !projectId || !itemId || !comments) return [];
    return comments.map((cm) => ({
      collection: getCommentReactionsPath(
        workspaceId,
        projectId,
        itemId,
        cm.id,
      ),
      storeAs: `${COL_COMMENTS}/${cm.id}/${COL_COMMENT_REACTIONS}`,
      orderBy: ['reactedAt', 'asc'],
    }));
  });

  const ordered = useSelector((state: any) => state.firestore.ordered);

  const currentCommentReactions = useMemo(() => {
    const tmpCommentReactions: CommentReactions = {};
    comments.forEach((cm) => {
      if (!cm.id) return;
      tmpCommentReactions[cm.id] = (ordered[
        `${COL_COMMENTS}/${cm.id}/${COL_COMMENT_REACTIONS}`
      ] || []) as CommentReaction[];
    });
    return tmpCommentReactions;
  }, [ordered, comments]);

  return {
    currentCommentReactions,
  };
}
