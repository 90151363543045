import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { classNames } from '@/libs/styleUtils';

export interface RadioItem {
  id: string | number;
  label: string;
  value: string;
  disabled?: boolean;
}

export interface IRadioGroupProps {
  idx?: number;
  property?: string;
  items: RadioItem[];
  label?: string;
  name: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  horizontal?: boolean;
}

/**
 * ラジオボタングループ
 * @param props
 * @returns
 */
export default function RadioGroup(props: IRadioGroupProps) {
  const {
    idx,
    property,
    items,
    label,
    name,
    disabled,
    readOnly,
    className,
    horizontal,
  } = props;

  const { register } = useFormContext();

  const isIndexable = () => property && idx !== undefined;

  const getRegister = () => {
    if (register) {
      if (isIndexable()) {
        return property
          ? register(`${name}[${idx}].${property}`)
          : register(`${name}[${idx}]`);
      }
      return register(name);
    }
    return null;
  };

  return (
    <div className={classNames(className)}>
      {label && (
        <label
          htmlFor={`radio-label-${name}`}
          className="block font-medium text-gray-700 dark:text-gray-400"
        >
          {label}
        </label>
      )}
      <fieldset className={label ? 'mt-4' : ''}>
        <div
          className={classNames(
            horizontal
              ? 'space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10'
              : 'space-y-4',
          )}
        >
          {items.map((item) => (
            <div key={item.id} className="flex items-center flex-1 pl-2">
              <input
                id={`radio-${item.id}`}
                name={name}
                type="radio"
                className={classNames(
                  disabled || item.disabled
                    ? 'bg-gray-200 text-gray-500 dark:bg-gray-800 dark:text-gray-400 opacity-50'
                    : '',
                  'w-4 h-4 bg-white dark:bg-gray-600 border-gray-300 dark:border-gray-500 text-primary-500 focus:ring-primary-500 dark:text-primary-600 dark:focus:ring-primary-600 dark:focus:ring-offset-gray-800 cursor-pointer focus:ring-0 dark:focus:ring-offset-0',
                )}
                disabled={disabled || item.disabled}
                readOnly={readOnly}
                {...getRegister()}
                value={item.value}
                autoFocus={false}
              />
              <label
                htmlFor={`radio-${item.id}`}
                className={classNames(
                  disabled || item.disabled ? 'opacity-40' : '',
                  'block ml-3 font-medium text-gray-700 dark:text-gray-300 cursor-pointer',
                )}
              >
                {item.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
