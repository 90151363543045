import StackdriverErrorReporter from 'stackdriver-errors-js';

import { ENV } from '@/libs/const';

// eslint-disable-next-line import/no-mutable-exports
let errorHandler:
  | {
      report: typeof console['error'];
      setUser: (user?: string) => void;
      start: (options: any) => void;
    }
  | StackdriverErrorReporter;

// ローカル環境はconsole.errorに出力
if (import.meta.env.VITE_NODE_ENV !== ENV.DEV) {
  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: import.meta.env.VITE_FIREBASE_API_KEY,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    version: import.meta.env.VITE_APP_VERSION,
  });
} else {
  // eslint-disable-next-line no-console
  errorHandler = { report: console.error, setUser: () => {}, start: () => {} };
}

export default errorHandler;
