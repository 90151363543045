import { ActionType, Nullable } from '@/@types/common';
import { InvitingGuest, InvitingMember } from '@/@types/models';

import actions from '@/libs/actions';

export interface IInvitingState {
  accessingInvitingGuest: Nullable<InvitingGuest>; // 招待URLアクセス中のゲスト
  accessingInvitingMember: Nullable<InvitingMember>; // 招待URLアクセス中のメンバー
}

type Types = Pick<
  typeof actions,
  'SET_ACCESSING_INVITING_GUEST' | 'SET_ACCESSING_INVITING_MEMBER'
>;

type Action = ActionType<
  Types,
  {
    SET_ACCESSING_INVITING_GUEST: {
      payload: IInvitingState['accessingInvitingGuest'];
    };
    SET_ACCESSING_INVITING_MEMBER: {
      payload: IInvitingState['accessingInvitingMember'];
    };
  }
>;

const initialState: IInvitingState = {
  accessingInvitingMember: null,
  accessingInvitingGuest: null,
};

export const invitingReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_ACCESSING_INVITING_GUEST:
      return { ...state, accessingInvitingGuest: action.payload };
    case actions.SET_ACCESSING_INVITING_MEMBER:
      return { ...state, accessingInvitingMember: action.payload };
    default:
      return state;
  }
};

export const setAccessingInvitingGuest = (
  newInvitingGuest: IInvitingState['accessingInvitingGuest'],
) => ({
  type: actions.SET_ACCESSING_INVITING_GUEST,
  payload: newInvitingGuest,
});

export const setAccessingInvitingMember = (
  newInvitingMember: IInvitingState['accessingInvitingMember'],
) => ({
  type: actions.SET_ACCESSING_INVITING_MEMBER,
  payload: newInvitingMember,
});

export default {};
