import { IJoinedAccount, IJoinedGuest } from '@/@types/models';
import { Combobox } from '@headlessui/react';
import { t } from 'i18next';
import React, { ReactNode, useMemo, useState } from 'react';

import { useFormContext, useFormState } from 'react-hook-form';

import { z } from 'zod';

import { classNames } from '@/libs/styleUtils';

import AvatarIcon from '@/components/Common/AvatarIcon';
import InvalidIcon from '@/components/Common/Forms/Validation/Icon/FormInvalidIcon';
import ValidIcon from '@/components/Common/Forms/Validation/Icon/FormValidIcon';

import ValidationTooltip from '@/components/Common/Forms/Validation/ValidationTooltip';

import PopupTransition from '@/components/Common/Transitions/PopupTransition';

export interface ISelectedPerson {
  id: string;
  value: string;
  name: string;
  email: string;
}

export interface IInputProps {
  idx?: number;
  property?: string;
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  disableAutoComplete?: boolean;
  className?: string;
  readOnly?: boolean;
  showTooltipBellow?: boolean;
  addOn?: {
    left?: string | ReactNode;
    right?: string | ReactNode;
  };
  password?: boolean;
  error?: string | null;
  personOptions: (IJoinedAccount | IJoinedGuest | undefined)[];
  showIcon?: boolean;
}

/**
 * テキスト入力フォーム
 * @param props
 * @returns
 */
export default function PredictionInput(props: IInputProps) {
  const {
    idx,
    property,
    label,
    name,
    placeholder,
    autoFocus,
    disabled,
    disableAutoComplete,
    className,
    readOnly,
    showTooltipBellow,
    addOn,
    password,
    error,
    personOptions,
    showIcon,
  } = props;

  type IProp = {
    [key in typeof property as string]: {
      message: string;
    };
  };

  const typeCheckProp = (obj: unknown, key: string): obj is IProp => {
    const prop = obj as IProp;
    return key in prop;
  };

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const { dirtyFields } = useFormState({
    control,
  });

  const isIndexable = () => property && idx !== undefined;

  const [selectedPerson, setSelectedPerson] = useState<ISelectedPerson | null>(
    null,
  );
  const [query, setQuery] = useState('');

  const isQueryValidEmail = useMemo(() => {
    const querySchema = z.string().email();
    return querySchema.safeParse(query).success;
  }, [query]);

  const filteredPeople = useMemo(
    () =>
      query === ''
        ? personOptions
        : personOptions.filter(
            (person) =>
              person?.displayName
                ?.toLowerCase()
                .includes(query.toLowerCase()) ||
              person?.email?.toLowerCase().includes(query.toLowerCase()),
          ),
    [query, personOptions],
  );

  const isDirty = () => {
    if (idx !== undefined) {
      let dirtyFlg = false;
      const dKey = dirtyFields[name];
      if (dKey && dKey[idx] && (!property || dKey[idx][property])) {
        dirtyFlg = true;
      }
      return dirtyFlg;
    }
    return dirtyFields[name] !== undefined;
  };

  const getError = () => {
    if (idx !== undefined && property !== undefined) {
      let e = '';
      const key = errors[name];
      if (key && Array.isArray(key) && key[idx]) {
        const form = key[idx];
        if (typeCheckProp(form, property)) {
          e = form[property].message;
        }
      }
      return e;
    }
    return errors[name]?.message;
  };
  const validationError = getError();

  const getAddOnClass = () => {
    if (!addOn) return 'rounded-md';

    if (addOn.left && addOn.right) {
      return 'rounded-none';
    }

    if (addOn.left) {
      return 'rounded-none rounded-r-md';
    }

    if (addOn.right) {
      return 'rounded-none rounded-l-md';
    }

    return '';
  };

  return (
    <Combobox
      value={selectedPerson}
      onChange={(value) => {
        if (typeof value === 'string') {
          setQuery(value);
          setValue(`${name}[${idx}].${property}`, value, {
            shouldValidate: true,
          });
        } else if (typeof value === 'object' && value != null) {
          value as ISelectedPerson;
          setQuery(value.email);
          setSelectedPerson(value);
          setValue(`${name}[${idx}].${property}`, value.email, {
            shouldValidate: true,
          });
        }
      }}
    >
      {({ open }) => (
        <div className="relative w-full">
          {label && (
            <label
              htmlFor={name}
              className="block font-medium text-gray-700 dark:text-gray-400"
            >
              {label}
            </label>
          )}
          <div className={label ? 'mt-1' : ''}>
            <div className="flex">
              {addOn?.left && (
                <span className="relative inline-flex items-center px-4 py-2 -mr-px border border-gray-300 dark:text-gray-300 rounded-l-md bg-gray-50 dark:border-gray-600 dark:bg-gray-800">
                  {addOn.left}
                </span>
              )}
              <div
                className={classNames(
                  'relative flex-grow rounded-md shadow-sm',
                  addOn && 'z-10',
                )}
              >
                <Combobox.Input
                  value={query}
                  onChange={(event) => {
                    setQuery(event.target.value);
                    setValue(
                      `${name}[${idx}].${property}`,
                      event.target.value,
                      {
                        shouldValidate: true,
                      },
                    );
                    setSelectedPerson({
                      id: '',
                      name: '',
                      value: '',
                      email: event.target.value,
                    });
                  }}
                  displayValue={(person: ISelectedPerson) =>
                    person?.email ?? ''
                  }
                  autoFocus={autoFocus}
                  id={isIndexable() ? `${name}-${idx}-${property}` : name}
                  type={password ? 'password' : 'text'}
                  autoComplete={disableAutoComplete ? 'off' : name}
                  className={classNames(
                    disabled
                      ? 'bg-gray-200 text-gray-500 dark:bg-gray-800 dark:text-gray-400 opacity-50'
                      : 'bg-white dark:bg-gray-700',
                    className,
                    (validationError || error) &&
                      'border-red-400 dark:border-red-700',
                    getAddOnClass(),
                    'block w-full border-gray-300 dark:border-gray-600  focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 placeholder:text-gray-300 dark:placeholder:text-gray-500 font-normal',
                  )}
                  placeholder={placeholder}
                  disabled={disabled}
                  readOnly={readOnly}
                />
                {((validationError || error) && <InvalidIcon />) ||
                  (isDirty() && <ValidIcon />)}
                <ValidationTooltip
                  className="!max-w-md min-w-max"
                  message={validationError?.toString() || error}
                  showBellow={showTooltipBellow}
                />
              </div>
              {addOn?.right && (
                <span className="relative inline-flex items-center px-4 py-2 -ml-px border border-gray-300 dark:text-gray-300 rounded-r-md bg-gray-50 dark:border-gray-600 dark:bg-gray-800">
                  {addOn.right}
                </span>
              )}
            </div>
            <PopupTransition show={open}>
              <Combobox.Options className="absolute z-30 w-full text-sm bg-white shadow-lg dark:bg-gray-700 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                {filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person?.id}
                    value={person}
                    className={({ active }) =>
                      `cursor-pointer select-none relative py-2 pl-2 pr-4 hover:bg-primary-500 hover:dark:bg-primary-600 hover:text-white ${
                        active
                          ? 'bg-primary-500 dark:bg-primary-600 text-white'
                          : ''
                      }`
                    }
                  >
                    <div className="flex items-center space-x-2">
                      {showIcon && (
                        <AvatarIcon
                          size="sm"
                          src={person?.photoURL}
                          avatarName={person?.displayName || person?.email}
                        />
                      )}
                      <span className="font-bold">
                        {person?.displayName || t('招待中ゲスト')}
                      </span>
                      <span>{person?.email}</span>
                    </div>
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </PopupTransition>
            {query.length > 0 && isQueryValidEmail && (
              <PopupTransition show={open}>
                <div className="absolute z-30 w-full p-3 mt-1 overflow-auto text-gray-400 bg-white shadow-lg dark:text-gray-500 dark:bg-gray-700 rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {t(
                    ' 候補がありません。入力されたメールアドレス宛に招待します。',
                  )}
                </div>
              </PopupTransition>
            )}
          </div>
        </div>
      )}
    </Combobox>
  );
}
