import { XMarkIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';

import React, { useCallback, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { z } from 'zod';

import { FUNCTIONS_ERROR_DETAILS, VALIDATION_MODE } from '@/libs/const';

import { classNames } from '@/libs/styleUtils';

import ItemRepository from '@/repositories/ItemRepository';

import useHandleApi from '@/hooks/useHandleApi';
import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

import FilterDrawer from '../Filter/FilterDrawer';

export interface ISearchFormProps {
  className?: string;
}

/**
 * アイテム検索フォーム
 * @param props
 * @returns
 */
export default function ItemSearch(props: ISearchFormProps) {
  const { t } = useTranslation();
  const { className } = props;
  const { currentMyProject } = useProjects();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentSearchState, setCurrentSearchState } = useItems();
  const [isEmptyKeyword, setIsEmptyKeyword] = useState<boolean>(true);

  // Zod schema定義
  const schema = z.object({
    keyword: z.string().nullable(),
  });

  type SchemaType = z.infer<typeof schema>;

  // Zod Form
  const methods = useForm<SchemaType>({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      keyword: null,
    },
  });

  useEffect(() => {
    methods.setValue('keyword', currentSearchState.keyword);
    setIsEmptyKeyword(currentSearchState.keyword === null);
  }, [currentSearchState]);
  const { exec } = useHandleApi();

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    await exec(async () => {
      let itemIds: string[] = [];
      const keyword: string | null = data.keyword?.trim() as string;
      // キーワードになにか入力されていれば検索実行
      const itemRepository = new ItemRepository(
        currentMyWorkspace?.id as string,
        currentMyProject?.id as string,
      );
      try {
        const res = await itemRepository.searchItems({
          workspaceId: currentMyWorkspace?.id,
          projectId: currentMyProject?.id,
          keyword,
        });
        itemIds = res.itemIds;
        setIsEmptyKeyword(keyword?.trim().length === 0);
        setCurrentSearchState(keyword, null, itemIds);
      } catch (e: any) {
        if (e.details === FUNCTIONS_ERROR_DETAILS.EMPTY_QUERY) {
          setIsEmptyKeyword(true);
          return;
        }
        throw e;
      }
    }, t('検索に失敗しました'));
  });

  // キーワードクリア
  const clearKeyword = useCallback(() => {
    methods.reset();
    setCurrentSearchState(null, null, []);
    setIsEmptyKeyword(true);
    setTimeout(() => {
      methods.setFocus('keyword', { shouldSelect: true });
    });
  }, [methods, currentSearchState]);

  return (
    <div className={classNames(className, 'flex flex-row')}>
      <div className="flex-auto">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // 処理中の場合は中断
            if (methods.formState.isSubmitting) return;
            formSubmit(e);
          }}
        >
          <FormProvider {...methods}>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
            >
              Search
            </label>
            <div className="relative  mr-0.5">
              <input
                onInput={(e) => {
                  setIsEmptyKeyword(!e.currentTarget.value);
                }}
                type="text"
                id="default-search"
                className="block w-full p-2 text-sm bg-gray-100 border-0 pr-14 rounded-md focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder={t('キーワードで絞り込み<dots>', { dots: '...' })}
                {...methods.register('keyword')}
              />
              <div className="absolute flex items-center right-1.5 bottom-1.5 space-x-0">
                {!isEmptyKeyword && (
                  <button
                    type="button"
                    className="p-1 dark:bg-gray-700"
                    onClick={() => {
                      clearKeyword();
                    }}
                  >
                    <XMarkIcon className="w-3 h-3" />
                  </button>
                )}

                <button
                  type="submit"
                  className="px-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-md py-0.5 dark:border-gray-600 hover:bg-gray-100 focus:outline-none ring-0 focus:ring-2 dark:ring-gray-500 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600"
                >
                  {t('検索')}
                </button>
              </div>
            </div>
          </FormProvider>
        </form>
      </div>
      <div className="self-center flex-none px-1 place-items-end">
        <FilterDrawer />
      </div>
    </div>
  );
}
