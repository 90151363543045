import React, { useRef, useState, useEffect, ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

// 要素が親要素より大きい時に省略し、ツールチップを出す（reactNode版）
type INodeProps = Omit<JSX.IntrinsicElements['div'], 'children'> & {
  children?: ReactNode | null;
  className?: string;
  tooltipText?: string;
};

export default function EllipsisNode(props: INodeProps) {
  const { children, className, tooltipText } = props;
  const refOuter = useRef<HTMLDivElement>(null);
  const refInner = useRef<HTMLDivElement>(null);
  const [isEllipsis, setIsEllipsis] = useState<boolean>(false);

  // ツールチップ再構成
  useEffect(() => {
    if (isEllipsis) {
      ReactTooltip.rebuild();
    }
  }, [isEllipsis]);

  // リサイズ監視
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const rectOuter = refOuter.current?.getBoundingClientRect();
      const rectInner = refInner.current?.getBoundingClientRect();
      // 外枠 < 内枠 なら省略表記
      setIsEllipsis((rectOuter?.width ?? 0) < (rectInner?.width ?? 0));
    });
    const el = refOuter.current as Element;
    resizeObserver.observe(el);
    return () => resizeObserver.unobserve(el);
  });

  return (
    <div
      data-tip={isEllipsis ? tooltipText : ''}
      ref={refOuter}
      className={classNames('truncate', className)}
    >
      <span ref={refInner}>{children}</span>
    </div>
  );
}
