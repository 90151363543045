import { Comment } from '@/@types/models';
import { useMemo } from 'react';

import { PAGINATE } from '@/libs/const';
import { COL_COMMENTS } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import usePaginate from '@/hooks/usePaginate';
import useProjects from '@/hooks/useProjects';

/**
 * commentsのコレクショングループ取得に関するHooks
 * @returns { commentsGroup }
 */
export default function useCommentsGroup() {
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const projectId = useMemo(
    () => currentMyProject?.projectId,
    [currentMyProject],
  );

  // ページネーション込みコメントリスト取得
  const {
    dataList: commentsGroup,
    hasNext,
    loadNextPage,
  } = usePaginate<Comment, 'commentsGroup'>(
    COL_COMMENTS,
    [
      ['workspaceId', '==', workspaceId],
      ['projectId', '==', projectId],
    ],
    ['postedAt', 'desc'],
    PAGINATE.COMMENTS,
    'commentsGroup',
    () => !workspaceId || !projectId,
  );

  return {
    commentsGroup,
    hasNext,
    loadNextPage,
  };
}
