// reduxのアクション種別一覧
export default {
  SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
  SET_CURRENT_VIEW_ID: 'SET_CURRENT_VIEW_ID',
  SET_NEW_VIEW_FLAG: 'SET_NEW_VIEW_FLAG',
  OPEN_ITEM_CREATE_DIALOG: 'OPEN_ITEM_CREATE_DIALOG',
  CLOSE_ITEM_CREATE_DIALOG: 'CLOSE_ITEM_CREATE_DIALOG',
  OPEN_ITEM_EDIT_DIALOG: 'OPEN_ITEM_EDIT_DIALOG',
  CLOSE_ITEM_EDIT_DIALOG: 'CLOSE_ITEM_EDIT_DIALOG',
  OPEN_PROPERTY_SETTING_DIALOG: 'OPEN_PROPERTY_SETTING_DIALOG',
  CLOSE_PROPERTY_SETTING_DIALOG: 'CLOSE_PROPERTY_SETTING_DIALOG',
  SET_CURRENT_WORKSPACE: 'SET_CURRENT_WORKSPACE',
  SET_NEW_PROJECT_DIALOG: 'SET_NEW_PROJECT_DIALOG',
  SET_ACCESSING_INVITING_GUEST: 'SET_ACCESSING_INVITING_GUEST',
  SET_ACCESSING_INVITING_MEMBER: 'SET_ACCESSING_INVITING_MEMBER',
  SET_CURRENT_ITEM_ID: 'SET_CURRENT_ITEM_ID',
  SET_STATIC_MY_WORKSPACES: 'SET_STATIC_MY_WORKSPACES',
  SET_THEME: 'SET_THEME',
  SET_IS_LOADED: 'SET_IS_LOADED',
  SET_AFTER_LOGIN: 'SET_AFTER_LOGIN',
  SET_ITEM_SEARCH_STATE: 'SET_ITEM_SEARCH_STATE',
  SET_SIDEBAR_MINIMIZE: 'SET_SIDEBAR_MINIMIZE',
  OPEN_ITEM_EMOJI_POPUP: 'OPEN_ITEM_EMOJI_POPUP',
  CLOSE_ITEM_EMOJI_POPUP: 'CLOSE_ITEM_EMOJI_POPUP',
  OPEN_ITEM_COMMENT_EMOJI_POPUP: 'OPEN_ITEM_COMMENT_EMOJI_POPUP',
  CLOSE_ITEM_COMMENT_EMOJI_POPUP: 'CLOSE_ITEM_COMMENT_EMOJI_POPUP',
  OPEN_ITEM_COMMENT_REACTION_POPUP: 'OPEN_ITEM_COMMENT_REACTION_POPUP',
  CLOSE_ITEM_COMMENT_REACTION_POPUP: 'CLOSE_ITEM_COMMENT_REACTION_POPUP',
  OPEN_PROJECT_DESCRIPTION_EMOJI_POPUP: 'OPEN_PROJECT_DESCRIPTION_EMOJI_POPUP',
  CLOSE_PROJECT_DESCRIPTION_EMOJI_POPUP:
    'CLOSE_PROJECT_DESCRIPTION_EMOJI_POPUP',
  OPEN_IMAGE_DIALOG: 'OPEN_IMAGE_DIALOG',
  CLOSE_IMAGE_DIALOG: 'CLOSE_IMAGE_DIALOG',
} as const;
