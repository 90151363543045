import {
  IFirestoreQuery,
  IFuncSearchItemsRequest,
  IFuncSearchItemsResult,
  IFuncCopyItem,
  IFuncCopyItemResult,
  IFuncCreateUpdateItemResult,
  CreateUpdateItemBody,
  CreateUpdateItemItem,
  CreateUpdateItemProperty,
  CreateUpdateItemPropertyValue,
  CreateUpdateItemStatus,
  IFuncMemberCreateItem,
  IFuncMemberUpdateItem,
  IFuncGuestCreateItem,
  IFuncGuestUpdateItem,
} from '@/@types/common';
import {
  Item,
  ItemOrder,
  Property,
  PropertyOrder,
  Status,
  StatusOrder,
  View,
  Workspace,
} from '@/@types/models';
import { collection, getDocs, query } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';

import { REP_ERROR_MESSAGE } from '@/libs/const';

import { getItemsPath } from '@/libs/docPathUtils';
import { composeAppendQuery } from '@/libs/repositoryUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { functions } from '@/firebase';

import { firestore } from '@/firestore';

export const ItemCollectionName = 'items';

export interface IItemRepository {
  itemPath: string;
  list: (params?: IFirestoreQuery<Item>) => Promise<Item[]>;
  searchItems: (
    data: IFuncSearchItemsRequest,
  ) => Promise<IFuncSearchItemsResult | null>;
  copyItem: (itemId: Item['id']) => Promise<IFuncCopyItemResult | null>;
  memberCreateItem: (
    viewId: View['id'],
    createStatuses: CreateUpdateItemStatus[],
    updateStatuses: CreateUpdateItemStatus[],
    deleteStatuses: Status['id'][],
    statusOrder: StatusOrder['orderList'],
    item: CreateUpdateItemItem,
    body: CreateUpdateItemBody,
    itemOrder: ItemOrder['orderList'],
    createProperties: CreateUpdateItemProperty[],
    updateProperties: CreateUpdateItemProperty[],
    deleteProperties: Property['id'][],
    propertyOrderList: PropertyOrder['orderList'],
    viewPropertyOrderList: View['propertyOrderList'],
    createPropertyValues: CreateUpdateItemPropertyValue[],
    updatePropertyValues: CreateUpdateItemPropertyValue[],
  ) => Promise<IFuncCreateUpdateItemResult | null>;
  memberUpdateItem: (
    viewId: View['id'],
    createStatuses: CreateUpdateItemStatus[],
    updateStatuses: CreateUpdateItemStatus[],
    deleteStatuses: Status['id'][],
    statusOrder: StatusOrder['orderList'],
    item: CreateUpdateItemItem,
    body: CreateUpdateItemBody,
    createProperties: CreateUpdateItemProperty[],
    updateProperties: CreateUpdateItemProperty[],
    deleteProperties: Property['id'][],
    propertyOrderList: PropertyOrder['orderList'],
    viewPropertyOrderList: View['propertyOrderList'],
    createPropertyValues: CreateUpdateItemPropertyValue[],
    updatePropertyValues: CreateUpdateItemPropertyValue[],
  ) => Promise<IFuncCreateUpdateItemResult | null>;
  guestCreateItem: (
    item: CreateUpdateItemItem,
    body: CreateUpdateItemBody,
    itemOrder: ItemOrder['orderList'],
    createPropertyValues: CreateUpdateItemPropertyValue[],
  ) => Promise<IFuncCreateUpdateItemResult | null>;
  guestUpdateItem: (
    item: CreateUpdateItemItem,
    body: CreateUpdateItemBody,
    updatePropertyValues: CreateUpdateItemPropertyValue[],
  ) => Promise<IFuncCreateUpdateItemResult | null>;
}

const funcSearchItems = httpsCallable<
  IFuncSearchItemsRequest,
  IFuncSearchItemsResult
>(functions, 'searchItems');

const funcCopyItem = httpsCallable<IFuncCopyItem, IFuncCopyItemResult>(
  functions,
  'copyItem',
);

const funcMemberCreateItem = httpsCallable<
  IFuncMemberCreateItem,
  IFuncCreateUpdateItemResult
>(functions, 'memberCreateItem');

const funcMemberUpdateItem = httpsCallable<
  IFuncMemberUpdateItem,
  IFuncCreateUpdateItemResult
>(functions, 'memberUpdateItem');

const funcGuestCreateItem = httpsCallable<
  IFuncGuestCreateItem,
  IFuncCreateUpdateItemResult
>(functions, 'guestCreateItem');

const funcGuestUpdateItem = httpsCallable<
  IFuncGuestUpdateItem,
  IFuncCreateUpdateItemResult
>(functions, 'guestUpdateItem');

export default class ItemRepository implements IItemRepository {
  itemPath: string;

  constructor(private workspaceId: Workspace['id'], private projectId: string) {
    this.itemPath = getItemsPath(this.workspaceId, this.projectId);
  }

  /**
   * List item docs
   * @returns Promise<Item[]>
   */
  async list(params?: IFirestoreQuery<Item>): Promise<Item[]> {
    const appends = composeAppendQuery(params);
    try {
      const collectionRef = query(
        collection(firestore, this.itemPath).withConverter(fsConverter<Item>()),
        ...appends,
      );
      return (await getDocs(collectionRef)).docs.map((d) => {
        const item = d.data();
        item.id = d.id;
        return item;
      });
    } catch (err: any) {
      repositoryError(this.list.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * アイテムの検索
   * @param data
   * @returns
   */
  async searchItems(data: IFuncSearchItemsRequest) {
    const res = await funcSearchItems(data);
    return res.data;
  }

  /**
   * アイテム複製
   * @param itemId
   * @returns
   */
  async copyItem(itemId: Item['id']) {
    const res = await funcCopyItem({
      workspaceId: this.workspaceId,
      projectId: this.projectId,
      itemId,
    });
    return res.data;
  }

  /**
   * メンバー用アイテム作成
   * @param itemId
   * @returns
   */
  async memberCreateItem(
    viewId: View['id'],
    createStatuses: CreateUpdateItemStatus[],
    updateStatuses: CreateUpdateItemStatus[],
    deleteStatuses: Status['id'][],
    statusOrder: StatusOrder['orderList'],
    item: CreateUpdateItemItem,
    body: CreateUpdateItemBody,
    itemOrder: ItemOrder['orderList'],
    createProperties: CreateUpdateItemProperty[],
    updateProperties: CreateUpdateItemProperty[],
    deleteProperties: Property['id'][],
    propertyOrderList: PropertyOrder['orderList'],
    viewPropertyOrderList: View['propertyOrderList'],
    createPropertyValues: CreateUpdateItemPropertyValue[],
    updatePropertyValues: CreateUpdateItemPropertyValue[],
  ) {
    const res = await funcMemberCreateItem({
      workspaceId: this.workspaceId,
      projectId: this.projectId,
      viewId,
      createStatuses,
      updateStatuses,
      deleteStatuses,
      statusOrder,
      item,
      body,
      itemOrder,
      propertyOrderList,
      viewPropertyOrderList,
      createProperties,
      updateProperties,
      deleteProperties,
      createPropertyValues,
      updatePropertyValues,
    });
    return res.data;
  }

  /**
   * メンバー用アイテム作成
   * @param itemId
   * @returns
   */
  async memberUpdateItem(
    viewId: View['id'],
    createStatuses: CreateUpdateItemStatus[],
    updateStatuses: CreateUpdateItemStatus[],
    deleteStatuses: Status['id'][],
    statusOrder: StatusOrder['orderList'],
    item: CreateUpdateItemItem,
    body: CreateUpdateItemBody,
    createProperties: CreateUpdateItemProperty[],
    updateProperties: CreateUpdateItemProperty[],
    deleteProperties: Property['id'][],
    propertyOrderList: PropertyOrder['orderList'],
    viewPropertyOrderList: View['propertyOrderList'],
    createPropertyValues: CreateUpdateItemPropertyValue[],
    updatePropertyValues: CreateUpdateItemPropertyValue[],
  ) {
    const res = await funcMemberUpdateItem({
      workspaceId: this.workspaceId,
      projectId: this.projectId,
      viewId,
      createStatuses,
      updateStatuses,
      deleteStatuses,
      statusOrder,
      item,
      body,
      propertyOrderList,
      viewPropertyOrderList,
      createProperties,
      updateProperties,
      deleteProperties,
      createPropertyValues,
      updatePropertyValues,
    });
    return res.data;
  }

  /**
   * メンバー用アイテム作成
   * @param itemId
   * @returns
   */
  async guestCreateItem(
    item: CreateUpdateItemItem,
    body: CreateUpdateItemBody,
    itemOrder: ItemOrder['orderList'],
    createPropertyValues: CreateUpdateItemPropertyValue[],
  ) {
    const res = await funcGuestCreateItem({
      workspaceId: this.workspaceId,
      projectId: this.projectId,
      item,
      body,
      itemOrder,
      createPropertyValues,
    });
    return res.data;
  }

  /**
   * メンバー用アイテム作成
   * @param itemId
   * @returns
   */
  async guestUpdateItem(
    item: CreateUpdateItemItem,
    body: CreateUpdateItemBody,
    updatePropertyValues: CreateUpdateItemPropertyValue[],
  ) {
    const res = await funcGuestUpdateItem({
      workspaceId: this.workspaceId,
      projectId: this.projectId,
      item,
      body,
      updatePropertyValues,
    });
    return res.data;
  }
}
