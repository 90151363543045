import { Item } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestoreConnect } from 'react-redux-firebase';

import { getCommentsPath } from '@/libs/docPathUtils';

import useAccessSettings from '@/hooks/useAccessSettings';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * 閲覧できるコメントがあるかを購読するためのHooks
 * 現在開いているアイテムのコメントのみサブスクリプションする
 * @returns { }
 */
export default function useIsReadableComments(itemId: Item['id']) {
  const { currentMyProject } = useProjects();

  const { currentMyWorkspace } = useMyWorkspaces();

  // 現在サブスクリプション中のキー保持用
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const { isDisplayComment } = useAccessSettings();

  // サブスクリプション
  useFirestoreConnect(() =>
    workspaceId && projectId && itemId && isDisplayComment
      ? [
          {
            collection: getCommentsPath(workspaceId, projectId, itemId),
            storeAs: 'readableComments',
          },
        ]
      : [],
  );

  // 動的に参照先を変更するためanyで取得している
  const isReadableComments = useSelector((state: any) =>
    state.firestore.ordered.readableComments
      ? state.firestore.ordered.readableComments.length !== 0
      : false,
  );

  return {
    isReadableComments,
  };
}
