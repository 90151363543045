import {
  CommentReactionGroupUser,
  CommentReactionGroup,
} from '@/@types/common';
import {
  RootState,
  Comment,
  RRFUploadedFile,
  CommentReaction,
} from '@/@types/models';
import { IAccountDictionary } from '@/@types/viewItem';
import {
  PencilIcon,
  ArrowUturnLeftIcon,
  XMarkIcon,
  FaceSmileIcon,
} from '@heroicons/react/24/outline';
import { t } from 'i18next';
import React, {
  MouseEvent,
  useMemo,
  useCallback,
  useState,
  Fragment,
  useEffect,
} from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ReactTooltip from 'react-tooltip';

import {
  DEFAULT_REACTION_DISPLAY,
  DEFAULT_REACTION_EMOJI_IDS,
} from '@/libs/const';
import { DayjsUtil, FormatType } from '@/libs/dayjs';

import { classNames } from '@/libs/styleUtils';

import { parseFsTimestamp } from '@/libs/utils';

import {
  closeItemCommentReactionPopup,
  openItemCommentReactionPopup,
} from '@/reducers/itemsReducer';

import AvatarIcon from '@/components/Common/AvatarIcon';
import EmojiPicker from '@/components/Common/EmojiPicker';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import LexicalReadOnlyEditor from '@/components/Common/Lexical/LexicalReadOnlyEditor';
import CommentFileItem from '@/components/Items/Comments/CommentFileItem';

import AddReaction from '@/components/Items/Comments/Reaction/AddReaction';
import DefaultReactionButton from '@/components/Items/Comments/Reaction/DefaultReactionButton';
import ReactionIcon from '@/components/Items/Comments/Reaction/ReactionIcon';

import ShowMoreReaction from '@/components/Items/Comments/Reaction/ShowMoreReaction';

import useAdminMembers from '@/hooks/useAdminMembers';
import useCommentReactionWriter from '@/hooks/useCommentReactionWriter';
import useMembers from '@/hooks/useMembers';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useUnknownGuests from '@/hooks/useUnknownGuests';

interface ICommentItemProps {
  membersAndGuestsDic: IAccountDictionary;
  comment: Comment;
  onDelete: (commentId: Comment['id']) => void;
  onEdit: (
    commentId: Comment['id'],
    editorState: Comment['editorState'],
  ) => void;
  onReply: (
    commentId: Comment['id'],
    editorState: Comment['editorState'],
    postedBy: Comment['postedBy'],
  ) => void;
  editCommentId: string | null;
  commentFiles: RRFUploadedFile[];
  commentReactions: CommentReaction[];
  isReadOnly: boolean;
}

interface _CommentReactionGroup {
  [key: string]: CommentReactionGroupUser[];
}

/**
 * コメント
 * @param props
 * @returns
 */
export default function CommentItem(props: ICommentItemProps) {
  const { t: tr } = useTranslation();
  const dispatch = useDispatch();
  const {
    membersAndGuestsDic,
    comment,
    onDelete,
    onEdit,
    onReply,
    editCommentId,
    commentFiles,
    commentReactions,
    isReadOnly,
  } = props;
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const { adminMember } = useAdminMembers();
  const { unknownGuests } = useUnknownGuests();
  const { members } = useMembers();
  const { isMember } = useMyWorkspaces();
  const { createReaction } = useCommentReactionWriter();

  // コメント編集オプション押下時アクション
  const onCommentEditClick = useCallback(() => {
    onEdit(comment.id, comment.editorState);
  }, [comment, onEdit]);

  // 削除クリック時
  const onCommentDeleteClick = useCallback(() => {
    onDelete(comment.id);
  }, [comment, onDelete]);

  // 返信クリック時
  const onCommentReplyClick = useCallback(() => {
    onReply(comment.id, comment.editorState, comment.postedBy);
  }, [comment, onReply]);

  // 編集モードの判定
  const isEditMode = useMemo(() => !!editCommentId, [editCommentId]);

  // ローカルステート
  const [pickerPos, setPickerPos] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const { reactionOpenCommentId } = useSelector(
    (state: RootState) => state.items,
  );

  /**
   * 管理者ユーザー判定
   */
  const isAdmin = useMemo(() => {
    if (!adminMember.memberList) return false;
    return adminMember.memberList.includes(userId);
  }, [userId, adminMember.memberList]);

  // ゲスト・匿名ゲストのコメント判定
  const isGuestComment = useMemo(() => {
    const o = members.flatMap((p) => p.id === comment.postedBy);
    return !o.includes(true);
  }, []);

  // メモ化したコメントの各表示値
  const commentValue = useMemo(() => {
    const {
      postedAt: __postedAt,
      postedBy,
      lastEditedAt,
      editorState,
    } = comment;

    const user = postedBy ? membersAndGuestsDic[postedBy] : null;

    const displayName = user?.displayName || '';
    const photoURL = user ? user.photoURL : '';
    const isAnonymous = !user;
    const _postedAt = new DayjsUtil(parseFsTimestamp(__postedAt));
    const isEdited = _postedAt.isDiff(parseFsTimestamp(lastEditedAt));
    const postedAt = _postedAt.format(tr('YYYY-MM-DD HH-mm') as FormatType);
    const isMyComment = userId === postedBy;
    const isUnknownGuest =
      unknownGuests.some((ug) => ug.id === postedBy) || false;

    return {
      photoURL,
      displayName,
      postedAt,
      editorState,
      isEdited,
      isAnonymous,
      isMyComment,
      user,
      postedBy,
      isUnknownGuest,
    };
  }, [membersAndGuestsDic, comment]);

  const selected = comment.id === editCommentId;

  const defaultBgColors = 'bg-white dark:bg-gray-800 dark:border-gray-600';

  const defaultTextColors = 'text-gray-500 dark:text-gray-400';

  const colors = selected
    ? 'bg-primary-200 dark:bg-gray-700'
    : `${defaultBgColors} ${defaultTextColors}`;

  const borders = selected
    ? 'ring-1 ring-primary-500 dark:ring-primary-600 border-primary-500 dark:border-primary-600'
    : 'dark:border-gray-600 ring-opacity-100';

  const arrowDirection = 'rounded-tl-none rounded-tr-xl';

  const pickerHeight = 435;

  const closeReactionPicker = useCallback(() => {
    dispatch(closeItemCommentReactionPopup());
  }, []);

  const [isShowMorelReaction, setShowMorelReaction] = useState<boolean>(false);

  /** リアクション */
  const reactions = useMemo<CommentReactionGroup[]>(() => {
    const tmp: _CommentReactionGroup = {};
    commentReactions.forEach((cr) => {
      const row: CommentReactionGroupUser = {
        reactionId: cr.id as string,
        uid: cr.reactedBy as string,
      };
      if (!tmp[cr.emojiId as string]) tmp[cr.emojiId as string] = [];
      tmp[cr.emojiId as string].push(row);
    });
    return Object.keys(tmp).map(
      (k) =>
        ({
          emojiId: k,
          row: tmp[k],
        } as CommentReactionGroup),
    );
  }, [commentReactions]);

  /**
   * 絵文字ピッカーの表示/非表示トグル
   */
  const toggleEmojiPicker = useCallback(
    (event: MouseEvent<HTMLElement>, topOffset: number = 0) => {
      // イベント伝播をキャンセル
      event.stopPropagation();
      const btnRect = event.currentTarget.getBoundingClientRect();
      // 画面からはみ出さないようPickerの位置を調整する
      setPickerPos({
        top:
          btnRect.top + pickerHeight <= window.innerHeight
            ? -100 + topOffset
            : -pickerHeight - btnRect.height + topOffset, // 画面下部の場合は上付きになる
        right: 0,
        bottom: 0,
        left: 0,
      });

      // Pickerの表示切り替え
      if (reactionOpenCommentId) {
        closeReactionPicker();
      } else {
        dispatch(openItemCommentReactionPopup(comment.id as string));
      }
    },
    [reactionOpenCommentId, comment],
  );

  /**
   * リアクション絵文字の保存処理
   */
  const onReactionSelect = useCallback(
    (emoji: any) => {
      const alreadySelected = commentReactions.find(
        (o) => o.reactedBy === userId && o.emojiId === emoji.id,
      );
      // 未リアクションの場合はデータを保存
      if (!alreadySelected) createReaction(emoji.id, comment.id);
      closeReactionPicker();
    },
    [commentReactions, userId],
  );

  const isShowAllReaction = useMemo(
    () => reactions.length <= DEFAULT_REACTION_DISPLAY || isShowMorelReaction,
    [reactions, isShowMorelReaction],
  );

  useEffect(() => {
    if (isShowAllReaction) setTimeout(() => ReactTooltip.rebuild());
  }, [isShowAllReaction]);

  return (
    <div className="relative flex items-start space-x-3 comment__panel group">
      {/* アバターアイコン */}
      <div className="">
        <AvatarIcon
          src={commentValue.photoURL}
          avatarName={commentValue.displayName}
          isAnonymous={commentValue.isAnonymous}
          size="md"
        />
      </div>
      {/* コメント本体 */}
      <div className="static flex-1 min-w-0 group">
        {/* 投稿者 */}
        <div
          className={classNames(
            'min-w-0 font-medium text-gray-500 dark:text-gray-400 flex items-center justify-between',
          )}
        >
          <div className="flex-shrink-0">{commentValue.displayName}</div>
          {commentValue.isUnknownGuest && (
            <div className="pl-1 text-xs text-gray-400 truncate dark:text-gray-500">
              {commentValue.postedBy}
            </div>
          )}
          <div className="flex items-center justify-between flex-shrink-0 px-3 text-xs font-medium text-gray-400 dark:text-gray-500">
            <div className="text-right">{commentValue.postedAt}</div>
            {commentValue.isEdited && (
              <div className="ml-1">{t('編集済み')}</div>
            )}
          </div>
        </div>
        {/* コメント表示欄 */}
        <div
          className={classNames(
            colors,
            borders,
            arrowDirection,
            'flex-1 flex min-w-0  border rounded-md shadow-sm rounded-br-xl rounded-bl-xl mt-1',
          )}
        >
          <div className="w-full">
            <div className="flex-row p-3 space-y-3">
              {/* コメント */}
              {comment.plainText && (
                <div className="break-all">
                  {comment.editorState && (
                    <LexicalReadOnlyEditor initialData={comment.editorState} />
                  )}
                </div>
              )}
              {/* 画像表示欄 */}
              {commentFiles && commentFiles.length !== 0 && (
                <div className="flex flex-col gap-3">
                  {commentFiles.map((file: RRFUploadedFile) => (
                    <CommentFileItem src={file} key={file.id} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* リアクション表示 */}
        {reactions.length > 0 && (
          <div className="static flex flex-wrap items-center px-1 mt-1 font-medium text-gray-400 dark:text-gray-500">
            {(isShowAllReaction
              ? reactions
              : reactions.slice(0, DEFAULT_REACTION_DISPLAY)
            ).map((reaction) => (
              <ReactionIcon
                key={reaction.emojiId}
                emojiId={reaction.emojiId}
                commentId={comment.id}
                reactionGroup={reactions}
                users={reaction.row}
                className="mt-1 mr-2"
                disabled={isReadOnly || isEditMode}
              />
            ))}
            {!isShowAllReaction && (
              <ShowMoreReaction
                count={reactions.length}
                onClick={() => {
                  setShowMorelReaction(true);
                }}
                className="mt-1 mr-2"
              />
            )}
            {!isReadOnly && !isEditMode && (
              <AddReaction
                onClick={(e) => {
                  toggleEmojiPicker(e, 100);
                }}
                className=" mt-1"
              />
            )}
          </div>
        )}
      </div>
      {/* 編集ボタン */}
      {!isReadOnly && !isEditMode && (
        <>
          {reactionOpenCommentId === comment.id && (
            <div className="absolute bottom-0 left-12">
              <EmojiPicker
                onEmojiSelect={onReactionSelect}
                coords={pickerPos}
                onClickOutside={() => {
                  closeReactionPicker();
                }}
                isReaction
                disableSkinTone
              />
            </div>
          )}

          <div className="absolute hidden px-1 py-1 bg-white border shadow-md dark:border-gray-600 dark:bg-gray-800 top-1 right-3 rounded-md group-hover:block">
            <div
              className={classNames(
                'items-center justify-end flex-1 text-right opacity-40 group-hover:opacity-100 space-x-2 flex',
                isEditMode ? '!opacity-0' : '',
              )}
            >
              {DEFAULT_REACTION_EMOJI_IDS.map((o) => (
                <span data-tip={t('リアクション')} key={o}>
                  <DefaultReactionButton
                    emojiId={o}
                    commentId={comment.id}
                    reactionGroup={reactions}
                  />
                </span>
              ))}
              <span data-tip={t('リアクション')}>
                <MenuButton
                  id="editComment"
                  type="icon"
                  onClick={toggleEmojiPicker}
                  disabled={isEditMode}
                >
                  <FaceSmileIcon className="w-4 h-4" />
                </MenuButton>
              </span>
              {commentValue.isMyComment && (
                <span data-tip={t('編集')}>
                  <MenuButton
                    id="editComment"
                    type="icon"
                    onClick={onCommentEditClick}
                    disabled={isEditMode}
                  >
                    <PencilIcon className="w-4 h-4" />
                  </MenuButton>
                </span>
              )}
              {(commentValue.isMyComment ||
                isAdmin ||
                (isMember && isGuestComment)) && (
                <span data-tip={t('削除')}>
                  <MenuButton
                    id="deleteComment"
                    type="icon"
                    onClick={onCommentDeleteClick}
                    disabled={isEditMode}
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </MenuButton>
                </span>
              )}
              {!commentValue.isMyComment && (
                <span data-tip={t('返信')}>
                  <MenuButton
                    id="replyComment"
                    type="icon"
                    onClick={onCommentReplyClick}
                    disabled={isEditMode}
                  >
                    <ArrowUturnLeftIcon className="w-4 h-4" />
                  </MenuButton>
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
