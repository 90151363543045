import { Item, ItemOrder } from '@/@types/models';
import lodash from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { INSERT_ITEM_POS } from '@/libs/const';
import { COL_ITEM_ORDERS, getItemOrdersPath } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * itemOrdersのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useItemOrders() {
  const { currentMyProject, myProjects } = useProjects();
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => {
      const query: ReduxFirestoreQuerySetting = {
        collection: getItemOrdersPath(workspaceId, p.id),
        storeAs: `${COL_ITEM_ORDERS}/${p.id}`,
      };
      return query;
    });
  });

  const ordered = useSelector((state: any) => state.firestore.ordered);

  // currentProjectのitemOrders
  const itemOrders = useMemo(() => {
    if (!currentMyProject?.id) return [];
    return (ordered[`${COL_ITEM_ORDERS}/${currentMyProject.id}`] ||
      []) as ItemOrder[];
  }, [ordered, currentMyProject]);

  // currentProjectのitemOrders.orderList
  const currentItemOrderList = useMemo(() => {
    const obj = itemOrders?.find((p) => p.id === projectId);
    return obj?.orderList || [];
  }, [itemOrders, projectId]);

  /**
   * 挿入するitemIdとpositionを指定して、新しいitemOrdersを生成
   * @param id
   * @param position string TOP(先頭) or LAST(最後尾) or itemIdの後ろ
   * @returns
   */
  const getNewItemOrders = (id: Item['id'], position: string): string[] => {
    const itemId = id as string;
    const currentOrderList = lodash.cloneDeep(currentItemOrderList);
    // すでにordersに存在するitemIdの場合、要素を削除する
    if (currentOrderList.indexOf(itemId) !== -1) {
      // 要素を削除
      const fromIdx = currentOrderList.indexOf(itemId);
      currentOrderList.splice(fromIdx, 1);
    }
    // 先頭の場合
    if (position === INSERT_ITEM_POS.TOP) {
      return [itemId, ...currentOrderList];
    }
    if (position === INSERT_ITEM_POS.LAST) {
      // 最後尾の場合
      return [...currentOrderList, itemId];
    }
    // 指定したitemIdの後ろに挿入する場合
    const toIdx = currentOrderList.indexOf(position);
    currentOrderList.splice(toIdx + 1, 0, itemId);
    return currentOrderList;
  };

  return {
    currentItemOrderList,
    getNewItemOrders,
  };
}
