import { RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestoreConnect } from 'react-redux-firebase';

import { COL_ADMIN_MEMBERS, getAdminMembersPath } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * adminMembersの集約に関するHooks
 * @returns { adminMember }
 */
export default function useAdminMembers() {
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  // ゲストの時はサブスクリプションしない
  const isMember = useMemo(
    () => currentMyWorkspace?.joinType === 'member',
    [currentMyWorkspace],
  );

  useFirestoreConnect(() =>
    workspaceId && isMember
      ? [
          {
            collection: getAdminMembersPath(workspaceId),
            doc: workspaceId,
            storeAs: COL_ADMIN_MEMBERS,
          },
        ]
      : [],
  );

  const adminMember = useSelector((state: RootState) => {
    const d = state.firestore.ordered.adminMembers;
    if (d && d.length > 0) return d[0];
    return {
      memberList: [],
    };
  });

  return { adminMember };
}
