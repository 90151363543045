import { Property, Status } from '@/@types/models';
import { IViewProps } from '@/@types/viewItem';

import { PlusIcon } from '@heroicons/react/24/outline';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { t } from 'i18next';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';

import {
  DEFAULT_LIST_VIEW_TITLE_COLUMN_WIDTH,
  FIXED_COLUMN_LENGTH,
  LIST_VIEW_TITLE_COLUMN_KEY,
  MIN_LIST_VIEW_TITLE_COLUMN_WIDTH,
} from '@/libs/const';

import { onDragEnd } from '@/libs/dndUtils';
import { classNames, getOriginalColorClass } from '@/libs/styleUtils';

import { getNavigatePath } from '@/libs/utils';

import { openItemEditDialog, setCurrentItemId } from '@/reducers/itemsReducer';
import { openNewItemDialog } from '@/reducers/newItemDialogReducer';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import { isSelectPublishItemModeContext } from '@/components/Share/SelectPublishItemsContext';

import BillboardCard from '@/components/View/Billboard/BillboardCard';
import ColumnWidthContext from '@/components/View/List/ColumnWidthContext';
import ListItem from '@/components/View/List/ListItem';

import ResizableTh from '@/components/View/List/ResizableTh';

import useItems from '@/hooks/useItems';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useViews from '@/hooks/useViews';

/**
 * 掲示板ビューのメインエリア表示
 * @param props
 * @returns
 */
export default function BillboardView(props: IViewProps) {
  const { properties, viewRows, isReadonly, onChangeRowItem } = props;
  const { dragItem, isItemLimitOver } = useItems();
  const navigate = useNavigate();
  const { workspaceId, projectId, viewId } = useParams();
  const dispatch = useDispatch();

  const { isMember } = useMyWorkspaces();
  const { currentView } = useViews();

  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  // 現在リスト表示中のステータスID
  const [currentStatusId, setCurrentStatusId] = useState<string | null>(null); //  現在リスト表示中のIdx
  const [currentColumnIdx, setCurrentColumnIdx] = useState<number | null>(null);

  const currentRow = useMemo(
    () => viewRows.find((d) => d.status.id === currentStatusId),
    [viewRows, currentStatusId],
  );

  const borderClass = useCallback(
    () => getOriginalColorClass(currentRow?.status.statusColor),
    [currentRow?.status.statusColor],
  );

  // リスト行クリック時ハンドラ
  const handleRowClick = (itemId: string) => {
    if (isSelectPublishItemMode) return;
    navigate(
      getNavigatePath(workspaceId as string, projectId, viewId, itemId),
      {
        replace: true,
      },
    );
    dispatch(setCurrentItemId(itemId));
    dispatch(openItemEditDialog(itemId));
  };

  // アイテム新規追加
  const handleCreateClick = (statusId: Status['id']) => {
    if (statusId) {
      dispatch(openNewItemDialog(statusId, true));
    }
  };

  // プロパティをpropertyOrderListでソート&フィルタリング
  const filteredProperties = useMemo(() => {
    const result =
      currentView?.propertyOrderList?.reduce<Property[]>((acc, po) => {
        // ゲスト権限は公開されているプロパティのみ
        const found = properties.find((d) =>
          isMember ? d.id === po : d.id === po && d.isShare === true,
        );

        if (!found) return acc;

        acc.push(found);
        return acc;
      }, []) || [];
    return result;
  }, [properties, currentView]);

  // ドロップ時ハンドラ
  const handleDragEnd = (result: DropResult) => {
    const moveResult = onDragEnd(result, viewRows);
    if (moveResult !== null) {
      // DNDの不正挙動を防ぐため ローカルで保持しているstateを更新
      onChangeRowItem(moveResult);
      // ステータス更新処理
      dragItem(moveResult.item.id, moveResult.statusId, moveResult.position);
    }
  };
  return (
    <div
      className={classNames(
        'px-4 pb-4 pt-0 overflow-y-auto',
        isSelectPublishItemMode ? 'mb-28 sm:mb-16' : '',
      )}
    >
      <div>
        {/* 掲示板表示 */}
        {!currentRow && (
          <ul className="h-full grid grid-cols-1 gap-8 sm:grid-cols-2 2xl:grid-cols-3">
            {viewRows.map((column, idx) => (
              <BillboardCard
                key={`column-status-${column.status.id}`}
                row={column}
                isReadonly={isReadonly}
                onClickMore={() => {
                  setCurrentStatusId(column.status.id ?? null);
                  setCurrentColumnIdx(idx);
                }}
                onClickCreateItem={() => {
                  handleCreateClick(column.status.id);
                }}
              />
            ))}
          </ul>
        )}
      </div>
      {/* 各掲示板のリスト表示 */}
      {currentRow && currentColumnIdx !== null && (
        <ColumnWidthContext>
          <div className="w-fit">
            <div className="flex items-center pt-1 ">
              <button
                type="button"
                className="text-primary-600 hover:text-primary-700 dark:text-primary-500 hover:dark:text-primary-600"
                onClick={() => setCurrentStatusId(null)}
              >
                {currentView?.viewName}
              </button>
              <span className=" text-gray-400 dark:text-gray-500">
                <span className="mx-2">&gt;</span>
                <span
                  className={classNames(
                    'inline-flex items-center border-b-4 space-x-2',
                    `border-${borderClass()?.border}`,
                  )}
                >
                  {currentRow.status.icon}
                  {currentRow.status.statusName}
                </span>
              </span>
              <MenuButton
                id="backToBillboardTop"
                type="text"
                className="items-center w-auto ml-auto"
                onClick={() => {
                  setCurrentStatusId(null);
                  setCurrentColumnIdx(null);
                }}
              >
                <ArrowLeftIcon className="w-4 h-4" />
                <span className="ml-1">{t('戻る')}</span>
              </MenuButton>
            </div>

            <div
              className="min-w-full"
              key={`row-status-${currentRow.status.id}`}
            >
              <DragDropContext onDragEnd={handleDragEnd}>
                <div
                  className={classNames(
                    'w-fit-content mt-4 text-gray-800 border-t border-l border-r border-gray-200 rounded-md dark:border-gray-700 dark:bg-gray-800 dark:text-gray-100',
                    !isSelectPublishItemMode && isMember
                      ? 'rounded-b-none'
                      : '',
                  )}
                >
                  <table
                    className="w-full border-separate table-fixed-sticky-l divide-y divide-gray-200 dark:divide-gray-700 dark:border-gray-700"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead>
                      <tr className="bg-gray-100 border-b border-gray-200 dark:border-gray-700 dark:bg-gray-700">
                        <ResizableTh
                          sizingKey={LIST_VIEW_TITLE_COLUMN_KEY}
                          key={LIST_VIEW_TITLE_COLUMN_KEY}
                          className={classNames(
                            'font-medium text-gray-500 dark:text-gray-300 z-10 p-0 tracking-wider text-left  left-0 ',
                            'border-b border-gray-200 dark:border-gray-700',
                          )}
                          label={t('タイトル')}
                          minWidth={MIN_LIST_VIEW_TITLE_COLUMN_WIDTH}
                          defaultWidth={DEFAULT_LIST_VIEW_TITLE_COLUMN_WIDTH}
                        />
                        {/* -- 固定ヘッダ ここまで -- */}
                        {filteredProperties.map((ps) => (
                          <ResizableTh
                            property={ps}
                            key={ps.id}
                            className="p-0 font-medium tracking-wider text-left text-gray-500 break-words border-b border-gray-200 dark:text-gray-300 dark:border-gray-700"
                          />
                        ))}
                        {/* 余白調整用の空白列 */}
                        <th
                          aria-label="empty-th"
                          className="w-auto px-6 py-2 font-medium tracking-wider text-left text-gray-500 border-b border-gray-200 dark:text-gray-300 dark:border-gray-700"
                        />
                      </tr>
                    </thead>
                    <Droppable droppableId={currentColumnIdx?.toString()}>
                      {(providedDroppable) => (
                        <tbody
                          className="divide-y divide-gray-200 dark:divide-gray-700"
                          ref={providedDroppable.innerRef}
                          {...providedDroppable.droppableProps}
                        >
                          {currentRow.viewItems.length === 0 && (
                            <tr>
                              <td
                                colSpan={
                                  FIXED_COLUMN_LENGTH +
                                  filteredProperties.length
                                }
                              />
                            </tr>
                          )}
                          {currentRow.viewItems.map((viewItem, itemIndex) => (
                            <Draggable
                              isDragDisabled={
                                isSelectPublishItemMode || isReadonly
                              }
                              key={`item-${viewItem.id}`}
                              draggableId={viewItem.id as string}
                              index={itemIndex}
                            >
                              {(providedDraggable, snapshotDraggable) => (
                                <tr
                                  ref={providedDraggable.innerRef}
                                  {...providedDraggable.draggableProps}
                                  {...providedDraggable.dragHandleProps}
                                  key={viewItem.id}
                                  className={classNames(
                                    'hover:bg-gray-50 dark:hover:bg-gray-700 group focus-within:z-10',
                                    !isSelectPublishItemMode
                                      ? 'cursor-pointer'
                                      : '',
                                    snapshotDraggable.isDragging
                                      ? 'table border border-collapse bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700'
                                      : '',
                                  )}
                                  onClick={() =>
                                    handleRowClick(viewItem.id as string)
                                  }
                                >
                                  <ListItem
                                    properties={filteredProperties}
                                    viewItem={viewItem}
                                  />
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {providedDroppable.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </div>
              </DragDropContext>
              {!isSelectPublishItemMode && !isReadonly && (
                <div className="overflow-hidden border-b border-l border-r border-gray-200 rounded-t-none rounded-md dark:border-gray-700 border-t-none">
                  <MenuButton
                    id="showNewItem"
                    type="text"
                    align="left"
                    className={classNames(
                      'flex items-center py-1 text-gray-400 border-0 rounded-none shadow-none outline-none ring-0 focus:ring-0 focus:!ring-offset-0 dark:text-gray-500 dark:hover:text-gray-300 hover:text-gray-500',
                      isItemLimitOver
                        ? 'hover:bg-gray-50/0 bg-gray-50/0 dark:bg-gray-800/100 dark:hover:text-gray-700/100 dark:text-gray-700/100 hover:text-gray-200/100 text-gray-200/100'
                        : '',
                    )}
                    onClick={() => handleCreateClick(currentRow.status.id)}
                    disabled={isItemLimitOver}
                    toolTip={
                      isItemLimitOver
                        ? t(
                            'アイテム数上限に達しました。アイテムを作成したい場合は、既存のアイテムを削除してください。',
                          )
                        : ''
                    }
                  >
                    <PlusIcon className="w-4 h-4" aria-hidden="true" />
                    <div className="ml-2">{t('アイテムを追加')}</div>
                  </MenuButton>
                </div>
              )}
            </div>
          </div>
        </ColumnWidthContext>
      )}
    </div>
  );
}
