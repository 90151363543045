import { RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_CHECKOUT_SESSIONS,
  getCheckoutSessionsPath,
} from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * CheckoutSessionsの集約に関するHooks
 */
export default function useCheckoutSessions() {
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  // ゲストの時はサブスクリプションしない
  const isMember = useMemo(
    () => currentMyWorkspace?.joinType === 'member',
    [currentMyWorkspace],
  );

  useFirestoreConnect(() =>
    workspaceId && isMember
      ? [
          {
            collection: getCheckoutSessionsPath(workspaceId),
            doc: workspaceId,
            storeAs: COL_CHECKOUT_SESSIONS,
          },
        ]
      : [],
  );

  const checkoutSession = useSelector((state: RootState) => {
    const d = state.firestore.ordered.checkoutSessions;
    if (d && d.length > 0) return d[0];
    return null;
  });

  return { checkoutSession };
}
