import { IModalDialogProps } from '@/@types/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import { getNavigatePath, t2s } from '@/libs/utils';

import { WORKSPACE_NAME } from '@/libs/validations';

import WorkspaceRepository from '@/repositories/WorkspaceRepository';

import FormDialog from '@/components/Common/FormDialog';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import { auth } from '@/firebase';

import useSubmitState from '@/hooks/useSubmitState';

export default function NewWorkspaceDialog(props: IModalDialogProps) {
  const { t } = useTranslation();
  const { onClose } = props;
  const navigate = useNavigate();
  const workspaceRepository = new WorkspaceRepository();

  // Zod schema定義
  const schema = z.object({
    workspaceName: z
      .string()
      .min(1, { message: t('入力してください') })
      .max(
        WORKSPACE_NAME.max,
        t2s(t('<max>文字以内で入力してください', WORKSPACE_NAME)),
      ),
  });

  type SchemaType = z.infer<typeof schema>;

  // Zod Form
  const methods = useForm<SchemaType>({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    if (!auth.currentUser) {
      return;
    }
    try {
      // Create Workspace
      const res = await workspaceRepository.createWorkspace(data);
      onClose();
      // Reset form state
      methods.reset({ ...data });
      // Go to new Workspace
      navigate(getNavigatePath(res.workspaceId as string));
    } catch (e) {
      // Reset form state
      methods.reset({ ...data });
      toast.error(t('ワークスペースの作成に失敗しました'));
    }
  });
  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <FormDialog
      onClose={onClose}
      title={t('ワークスペースを作成')}
      onSubmit={formSubmit}
    >
      <FormProvider {...methods}>
        <FormCol>
          <Input name="workspaceName" label={t('ワークスペース名')} />
        </FormCol>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="createWorkspace"
          disabled={isDisabledApply}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('作成')}
        </FormButton>
        <FormButton
          id="cancelCreateWorkspace"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
