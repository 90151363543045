import { ActionType } from '@/@types/common';
import { Workspace } from '@/@types/models';

import actions from '@/libs/actions';

type Types = Pick<typeof actions, 'SET_CURRENT_WORKSPACE'>;

type Action = ActionType<
  Types,
  { SET_CURRENT_WORKSPACE: { payload: Workspace['id'] } }
>;

export const currentWorkspaceReducer = (
  // eslint-disable-next-line default-param-last
  state: Workspace['id'] | null = null,
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_CURRENT_WORKSPACE:
      // eslint-disable-next-line no-param-reassign
      state = action.payload;
      break;
    default:
  }
  return state;
};

export const setCurrentMyWorkspaceIdAction = (
  wsid: Workspace['id'] | null | undefined,
) => ({
  type: actions.SET_CURRENT_WORKSPACE,
  payload: wsid,
});

export default {};
