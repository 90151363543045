import { Comment, RRFUploadedFile } from '@/@types/models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_COMMENT_FILES,
  COL_COMMENTS,
  getCommentFilesPath,
} from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

export interface CommentFiles {
  [key: string]: RRFUploadedFile[];
}
/**
 * commentsFilesのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useCommentFiles(comments: Comment[]) {
  const { currentMyProject } = useProjects();
  const { currentMyWorkspace } = useMyWorkspaces();
  // 現在サブスクリプション中のキー保持用
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );
  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !projectId || !comments) return [];
    return comments.map((cm) => ({
      collection: getCommentFilesPath(workspaceId, projectId, cm.itemId, cm.id),
      storeAs: `${COL_COMMENTS}/${cm.id}/${COL_COMMENT_FILES}`,
      orderBy: ['updated', 'asc'],
    }));
  });

  const ordered = useSelector((state: any) => state.firestore.ordered);

  const currentCommentFiles = useMemo(() => {
    const tmpCommentFiles: CommentFiles = {};
    comments.forEach((cm) => {
      if (!cm.id) return;
      tmpCommentFiles[cm.id] = (ordered[
        `${COL_COMMENTS}/${cm.id}/${COL_COMMENT_FILES}`
      ] || []) as RRFUploadedFile[];
    });
    return tmpCommentFiles;
  }, [ordered, comments]);
  return {
    currentCommentFiles,
  };
}
