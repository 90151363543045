import { IAccountDictionary } from '@/@types/viewItem';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestoreConnect } from 'react-redux-firebase';

import { JOIN_TYPE } from '@/libs/const';

import {
  getUnknownGuestsPath,
  COL_MY_PROJECTS_UNKNOWN_GUESTS,
} from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * useMyProjectUnknownGuestsの集約に関するHooks（主に通知用）
 * @returns { unknownGuests }
 */
export default function useMyProjectUnknownGuests() {
  const { currentMyWorkspace } = useMyWorkspaces();
  const { myProjects } = useProjects();
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => ({
      collection: getUnknownGuestsPath(workspaceId, p.id as string),
      storeAs: `${COL_MY_PROJECTS_UNKNOWN_GUESTS}/${p.id}`,
    }));
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered || []);

  const myProjectUnknownGuestsDic = useMemo(
    () =>
      myProjects.reduce<IAccountDictionary>((acc, cur) => {
        const values = ordered[`${COL_MY_PROJECTS_UNKNOWN_GUESTS}/${cur.id}`];
        if (!values) return acc;
        values.forEach((v: any) => {
          const dic: IAccountDictionary['uid'] = {
            photoURL: null,
            displayName: v.displayName ?? '',
            joinType: JOIN_TYPE.UNKNOWN_GUEST,
          };
          acc[v.id] = dic;
        });
        return acc;
      }, {}),
    [ordered],
  );

  return { myProjectUnknownGuestsDic };
}
