import { PropertyOrder, RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_PROPERTY_ORDERS,
  getPropertyOrdersPath,
} from '@/libs/docPathUtils';

import { getUpdateSystemFields } from '@/libs/utils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

const initialPropertyOrder: PropertyOrder = {
  orderList: [],
};

/**
 * propertyOrdersのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function usePropertyOrders() {
  const rrfFirestore = useFirestore();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject, myProjects } = useProjects();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => ({
      collection: getPropertyOrdersPath(workspaceId, p.id as string),
      storeAs: `${COL_PROPERTY_ORDERS}/${p.id}`,
    }));
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);

  // returnするプロパティオーダー
  const propertyOrder: PropertyOrder = useMemo(() => {
    if (!projectId || !ordered[`${COL_PROPERTY_ORDERS}/${projectId}`]) {
      return initialPropertyOrder;
    }

    return (
      ordered[`${COL_PROPERTY_ORDERS}/${projectId}`][0] || initialPropertyOrder
    );
  }, [ordered, projectId]);

  /**
   * プロパティオーダー更新処理
   * @param newOrderList
   */
  const updatePropertyOrders = async (newOrderList: string[]) => {
    const newPropertyOrder: PropertyOrder = {
      orderList: [...newOrderList],
      ...getUpdateSystemFields(userId),
    };
    await rrfFirestore.update<PropertyOrder>(
      `${getPropertyOrdersPath(workspaceId, projectId)}/${projectId}`,
      newPropertyOrder,
    );
  };

  return {
    propertyOrder,
    updatePropertyOrders,
  };
}
