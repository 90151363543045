import { IProperty } from '@/@types/viewItem';
import { Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PROPERTY_TYPE } from '@/libs/const';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import Input from '@/components/Common/Forms/Input';
import LandscapeCol from '@/components/Common/Forms/LandscapeCol';
import PropertyMultiSelect from '@/components/Items//PropertyMultiSelect';
import ItemPropertyNameInput from '@/components/Items/ItemPropertyNameInput';
import PropertyAddDialog from '@/components/Items/PropertyAddDialog';
import PropertyCheckbox from '@/components/Items/PropertyCheckbox';
import PropertyDate from '@/components/Items/PropertyDate';
import PropertyEditDialog from '@/components/Items/PropertyEditDialog';
import PropertyFile from '@/components/Items/PropertyFile';
import PropertyIncharge from '@/components/Items/PropertyIncharge';
import PropertyNumber from '@/components/Items/PropertyNumber';
import PropertySingleSelect from '@/components/Items/PropertySingleSelect';

import { IItemFormDefaultValues } from '@/hooks/useEditItem';

export type EditMode = 'edit' | 'create';

// ショートカットキー用の検知hooks
type ItemPropertyProps = {
  isValueEditPermission?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
};

// コンポーネント本体
function ItemProperties(props: ItemPropertyProps) {
  const { t } = useTranslation();
  const { isValueEditPermission, onKeyDown } = props;
  const [openPropAddModal, setOpenPropAddModal] = useState(false);
  const [openPropEditModal, setOpenPropEditModal] = useState(false);

  const [editTarget, setEditTarget] = useState<{
    idx: number;
    property: IProperty;
  } | null>(null);

  // zod管理のフォームコンテキスト
  const { control, getValues, setValue, trigger } =
    useFormContext<IItemFormDefaultValues>();

  const fields = getValues('properties');

  // 新規・更新プロパティ配列
  const { append, remove } = useFieldArray({
    control,
    name: 'properties',
    keyName: 'key',
  });

  // 削除済みプロパティ配列
  const { append: appendDeletedProperties } = useFieldArray({
    control,
    name: 'deletedProperties',
    keyName: 'key',
  });

  // プロパティ新規追加モーダル
  const openAddPropertyModal = () => {
    setOpenPropAddModal(true);
  };
  const onPropAddModalClose = () => {
    setOpenPropAddModal(false);
  };

  // プロパティ編集モーダル 対象プロパティはidxで管理
  const onEditClick = (idx: number) => {
    const target = fields[idx];
    if (!target) return;
    setEditTarget({ idx, property: target });
    setOpenPropEditModal(true);
  };
  const onPropEditModalClose = () => {
    setOpenPropEditModal(false);
  };

  // プロパティ更新
  const onEditProperty = useCallback(
    (newProperty: IProperty) => {
      if (editTarget === null) return;
      setValue(`properties.${editTarget.idx}`, newProperty, {
        shouldDirty: true,
        shouldTouch: true,
      });
      trigger();
      setEditTarget(null);
    },
    [editTarget],
  );

  // プロパティ名更新
  const onLabelUpdate = useCallback(
    (idx: number, value: string) => {
      const target = fields[idx];
      if (target) {
        setValue(`properties.${idx}.propertyName`, value, {
          shouldDirty: true,
          shouldTouch: true,
        });
        trigger();
      }
    },
    [fields],
  );

  // プロパティ追加
  const onAddProperty = useCallback((property: IProperty) => {
    append(property);
  }, []);

  // プロパティ削除
  const handlePropertyDelete = (idx: number) => {
    const deleteTarget = fields[idx];
    appendDeletedProperties(deleteTarget);
    remove(idx);
  };

  // プロパティ種別ごとにフォーム出力
  const formSelector = useCallback((property: IProperty, idx: number) => {
    // シングルセレクト、ステータス
    if (
      property.propertyType === PROPERTY_TYPE.SINGLE_SELECT ||
      property.propertyType === PROPERTY_TYPE.STATUS
    ) {
      return (
        <PropertySingleSelect
          idx={idx}
          prependEmpty={property.propertyType !== PROPERTY_TYPE.STATUS}
        />
      );
    }
    // マルチセレクト、ステータス
    if (property.propertyType === PROPERTY_TYPE.MULTI_SELECT) {
      return <PropertyMultiSelect idx={idx} />;
    }
    // ファイル添付
    if (property.propertyType === PROPERTY_TYPE.FILE) {
      return <PropertyFile idx={idx} />;
    }
    // チェックボックス
    if (property.propertyType === PROPERTY_TYPE.CHECKBOX) {
      return <PropertyCheckbox idx={idx} />;
    }
    // 担当者
    if (property.propertyType === PROPERTY_TYPE.INCHARGE) {
      return <PropertyIncharge idx={idx} />;
    }
    // 日付
    if (property.propertyType === PROPERTY_TYPE.DATE) {
      return <PropertyDate idx={idx} />;
    }
    // 数値
    if (property.propertyType === PROPERTY_TYPE.NUMBER) {
      return <PropertyNumber idx={idx} format={property.format} />;
    }
    // テキスト
    return (
      <Input
        name="properties"
        idx={idx}
        property="stringValue"
        onKeyDown={onKeyDown}
      />
    );
  }, []);

  return (
    <>
      <div className="mt-4 space-y-4 ">
        {fields.map((property, idx) => (
          <LandscapeCol
            key={property.docId}
            label={
              <div className="col-span-3">
                <ItemPropertyNameInput
                  idx={idx}
                  property={property}
                  onEditClick={onEditClick}
                  onDelete={() => handlePropertyDelete(idx)}
                  onUpdate={onLabelUpdate}
                  textBreak="break-all"
                  isEdit={!isValueEditPermission}
                />
              </div>
            }
          >
            <div className="col-span-9">{formSelector(property, idx)}</div>
          </LandscapeCol>
        ))}
        {!isValueEditPermission && (
          <FormButton
            id="addProperty"
            submit={false}
            onClick={openAddPropertyModal}
          >
            <PlusIcon
              className="inline-block w-4 h-4 my-auto"
              aria-hidden="true"
            />
            <div className="inline-block ml-2 align-text-top">
              {t('プロパティを追加')}
            </div>
          </FormButton>
        )}
      </div>
      {/* プロパティ追加ダイアログ */}
      <Transition.Root show={openPropAddModal} unmount>
        <PropertyAddDialog
          onClose={onPropAddModalClose}
          zIndex={20}
          onAddProperty={onAddProperty}
        />
      </Transition.Root>
      {/* プロパティ編集ダイアログ */}
      <Transition.Root show={openPropEditModal} unmount>
        {editTarget && (
          <PropertyEditDialog
            zIndex={30}
            property={editTarget.property}
            onClose={onPropEditModalClose}
            onEditProperty={onEditProperty}
          />
        )}
      </Transition.Root>
    </>
  );
}
export default ItemProperties;
