import React, { ReactNode } from 'react';

import { classNames } from '@/libs/styleUtils';

interface IPlaceholderProps {
  className?: string;
  children?: ReactNode;
}

// プレースホルダ用コンポーネント
export default function Placeholder(props: IPlaceholderProps) {
  const { children, className } = props;

  return (
    <div className={className ? classNames(className) : 'editor-placeholder'}>
      {children}
    </div>
  );
}
