import { MemberGuestViewItem, Property } from '@/@types/models';
import { PropertyTypes } from '@/@types/viewItem';
import {
  ChatBubbleLeftEllipsisIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';
import React, { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import reactStringReplace from 'react-string-replace';

import { PROPERTY_TYPE, URL_REGEX } from '@/libs/const';

import { DayjsUtil } from '@/libs/dayjs';
import { classNames } from '@/libs/styleUtils';
import {
  commarize,
  formatNumberProperty,
  reduceProperties,
} from '@/libs/utils';

import AvatarIcon from '@/components/Common/AvatarIcon';
import EllipsisNode from '@/components/Common/EllipsisNode';
import FileLoader from '@/components/Common/FileLoader';
import Checkbox from '@/components/Common/Forms/Checkbox';
import { SelectBadge } from '@/components/Common/SelectBadge';
import DueDateButton from '@/components/Items/DueDateButton';
import {
  isSelectPublishItemModeContext,
  itemContext,
} from '@/components/Share/SelectPublishItemsContext';
import ItemDropDown from '@/components/View/ItemDropDown';
import ViewLiked from '@/components/View/ViewLiked';

import useAccessSettings from '@/hooks/useAccessSettings';
import useMentions from '@/hooks/useMentions';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useProjects from '@/hooks/useProjects';

import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';
import useViews from '@/hooks/useViews';

export interface IKanbanItemProps {
  properties: Property[];
  viewItem: MemberGuestViewItem;
  onClick: (itemId: string) => void;
}

/**
 * カンバンビューのアイテム（カード表示）
 * @param props
 * @returns
 */
export default function KanbanItem(props: IKanbanItemProps) {
  const { t } = useTranslation();
  const { properties, viewItem, onClick } = props;
  const { currentMyWorkspace, isMember } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const { currentView } = useViews();
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  const { membersDic, guestsDic } = useMentions();
  const { removedMembersDic } = useRemovedMembers();
  const { removedGuestsDic } = useRemovedGuests();

  const { isDisplayComment, isDisplayLiked } = useAccessSettings();
  const { checkedItemIds, checkItemId } = useContext(itemContext);
  const membersAndGuestsDic = useMemo(() => {
    const currentDic = {
      ...guestsDic,
      ...membersDic,
      ...removedGuestsDic,
      ...removedMembersDic,
    };
    return currentDic;
  }, [guestsDic, membersDic, removedGuestsDic, removedMembersDic]);

  const memoProperties = useMemo(
    () =>
      reduceProperties(
        currentMyWorkspace?.workspaceId || '',
        currentMyProject?.projectId || '',
        viewItem.id || '',
        properties,
        viewItem,
        currentView?.propertyOrderList || [],
        isMember,
      ),
    [
      properties,
      currentView,
      currentMyWorkspace,
      currentMyProject,
      viewItem.propertyValues,
      isMember,
    ],
  );

  // プロパティ種別ごとにフォーム出力
  const propertySelector = useCallback(
    (pr: PropertyTypes) => {
      // シングルセレクト、ステータス
      if (pr.type === PROPERTY_TYPE.SINGLE_SELECT && pr.value) {
        return (
          pr.value && (
            <div key={pr.id} className="flex flex-wrap content-start">
              <SelectBadge className="" color={pr.color} content={pr.value} />
            </div>
          )
        );
      }
      // マルチセレクト
      if (
        pr.type === PROPERTY_TYPE.MULTI_SELECT &&
        pr.value &&
        pr.value.length
      ) {
        return (
          <div key={pr.id} className="flex flex-wrap gap-x-1 gap-y-1">
            {pr.value?.map((o, idx) => (
              <SelectBadge
                key={`multiSelectItem-${idx + 1}`}
                className="text-sm"
                color={pr.color[idx]}
                content={o}
              />
            ))}
          </div>
        );
      }
      // ファイル添付
      if (pr.type === PROPERTY_TYPE.FILE && pr.value && pr.value.length) {
        return (
          <div key={pr.id} className="flex flex-wrap gap-x-1 gap-y-1">
            {pr.value?.map((o, idx) => (
              <FileLoader
                key={`${o.fileName}-${idx + 1}`}
                src={o}
                size="sm"
                clickable
              />
            ))}
          </div>
        );
      }
      // チェックボックス
      if (pr.type === PROPERTY_TYPE.CHECKBOX) {
        return (
          <div key={pr.id} className="flex items-center space-x-2">
            <Checkbox name={pr.id} checked={pr.value} readOnly />{' '}
            <div>{pr.propertyName}</div>
          </div>
        );
      }
      // 担当者
      if (pr.type === PROPERTY_TYPE.INCHARGE && pr.value && pr.value.length) {
        const inChargeList = pr.value.map((uid) => membersAndGuestsDic[uid]);
        return (
          <div key={pr.id} className="flex flex-wrap gap-x-1 gap-y-1">
            {inChargeList.map((o, idx) => (
              <div
                className="relative flex items-center"
                key={`inchargeItem-${idx + 1}`}
              >
                <AvatarIcon
                  size="sm"
                  src={o?.photoURL ? o.photoURL : undefined}
                  avatarName={o?.displayName ? o.displayName : ''}
                />
                <div className="flex-1 min-w-0 ml-2">
                  <span>{o?.displayName ? o.displayName : ''}</span>
                </div>
              </div>
            ))}
          </div>
        );
      }
      // 日付
      if (pr.type === PROPERTY_TYPE.DATE && pr.value !== null) {
        const v = new DayjsUtil(new Date(pr.value));
        return (
          <div key={pr.id} className="flex items-center">
            {v.format(t('YYYY-MM-DD'))}
            {pr.isDueDate && (
              <DueDateButton
                itemId={viewItem.id}
                propertyId={pr.id}
                className="ml-1"
              />
            )}
          </div>
        );
      }
      // 数値
      if (pr.type === PROPERTY_TYPE.NUMBER && pr.value !== null) {
        return (
          <div key={pr.id}>{formatNumberProperty(pr.value, pr.format)}</div>
        );
      }
      // テキスト
      if (pr.type === PROPERTY_TYPE.TEXT && pr.value) {
        return (
          <EllipsisNode tooltipText={pr.value}>
            {reactStringReplace(pr.value, URL_REGEX, (match) => (
              <a
                href={match}
                target="_blank"
                rel="noopener noreferrer"
                className="link"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {match}
              </a>
            ))}
          </EllipsisNode>
        );
      }
      return null;
    },
    [membersAndGuestsDic],
  );

  return (
    /* eslint-disable jsx-a11y/interactive-supports-focus */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <div
      className={classNames(
        'relative w-full p-4',
        isSelectPublishItemMode ? 'cursor-default' : '',
      )}
      role="button"
      onClick={() => onClick(viewItem.id as string)}
    >
      <div className="flex justify-between">
        <div className="w-full">
          <h4 className="font-semibold break-words">{viewItem.title}</h4>
        </div>
        {!isSelectPublishItemMode && isMember && viewItem.isShare === false && (
          <div className="flex items-center text-gray-400 ">
            <EyeSlashIcon className="w-4 h-4" aria-hidden="true" />
          </div>
        )}
      </div>
      <div className="flex flex-col mt-3 break-words space-y-3">
        {memoProperties.map((pr) => propertySelector(pr))}
      </div>
      <div className="items-center w-full h-6 mt-3 font-medium text-gray-400 grid grid-cols-12">
        {isDisplayLiked && (
          <div className="flex items-center h-6 col-span-2">
            <ViewLiked viewItem={viewItem} />
            <span className="-ml-1 font-mono text-sm leading-none">
              {commarize(viewItem.likeCount ?? 0)}
            </span>
          </div>
        )}
        {isDisplayComment && (
          <div className="flex items-center col-span-2">
            <ChatBubbleLeftEllipsisIcon
              className="w-4 h-4"
              aria-hidden="true"
            />
            <span className="ml-1 font-mono text-sm leading-none">
              {commarize(viewItem.commentCount ?? 0)}
            </span>
          </div>
        )}
        {!isSelectPublishItemMode && (
          <div className="flex items-center col-start-12 col-span-1">
            <ItemDropDown viewItem={viewItem} />
          </div>
        )}
      </div>
      {/** アイテム選択モードの場合は選択チェックボックスを表示 */}
      {isSelectPublishItemMode && viewItem.id && (
        <div className="absolute right-4 top-4">
          <Checkbox
            name="selected"
            checked={checkedItemIds.includes(viewItem.id)}
            onChange={() => checkItemId(viewItem.id as string)}
          />
        </div>
      )}
    </div>
  );
}
