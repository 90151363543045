import * as React from 'react';

export interface BodyLoadingProps {
  className?: string;
}

/**
 * 本文のロード中の画像
 * @param props
 * @returns
 */
export default function BodyLoading(props: BodyLoadingProps) {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 595.28 226.77"
      className={className}
    >
      <defs />
      <g className="body-loading-1">
        <polygon
          className="body-loading-3"
          points="-4.17 -2.04 599.75 -2.04 599.75 229.97 -4.17 229.97 -4.17 -2.04 -4.17 -2.04"
        />
      </g>
      <g>
        <path
          className="body-loading-2"
          d="M295.2,73.1h5.15c2.06,0,3.73,1.67,3.73,3.73v5.15c0,2.06-1.67,3.73-3.73,3.73h-5.15c-2.06,0-3.73-1.67-3.73-3.73v-5.15c0-2.06,1.67-3.73,3.73-3.73Z"
          transform="translate(31.07 233.82) rotate(-45)"
        />
        <path
          className="body-loading-4"
          d="M281.3,80.92h5.15c2.06,0,3.73,1.67,3.73,3.73v5.15c0,2.06-1.67,3.73-3.73,3.73h-5.15c-2.06,0-3.73-1.67-3.73-3.73v-5.15c0-2.06,1.67-3.73,3.73-3.73Z"
          transform="translate(21.45 226.25) rotate(-44.99)"
        />
        <path
          className="body-loading-2"
          d="M281.35,96.83h5.15c2.06,0,3.73,1.67,3.73,3.73v5.15c0,2.06-1.67,3.73-3.73,3.73h-5.15c-2.06,0-3.73-1.67-3.73-3.73v-5.15c0-2.06,1.67-3.73,3.73-3.73Z"
          transform="translate(110.8 350.68) rotate(-75)"
        />
        <path
          className="body-loading-4"
          d="M293.66,116.33c-1.99-.53-3.17-2.58-2.64-4.57l1.33-4.98c.54-1.99,2.58-3.17,4.57-2.64l4.98,1.33c1.99,.54,3.17,2.58,2.64,4.57l-1.33,4.98c-.53,1.99-2.58,3.17-4.57,2.64l-4.98-1.33Z"
        />
        <path
          className="body-loading-2"
          d="M311.03,109.7c-1.99,.53-4.04-.65-4.57-2.64l-1.34-4.98c-.53-1.99,.65-4.04,2.64-4.57l4.98-1.33c1.99-.53,4.04,.65,4.57,2.64l1.33,4.98c.53,1.99-.65,4.04-2.64,4.57l-4.98,1.33Z"
        />
        <path
          className="body-loading-4"
          d="M318.03,88.26c.53,1.99-.65,4.03-2.64,4.57l-4.98,1.33c-1.99,.53-4.04-.65-4.57-2.64l-1.33-4.98c-.53-1.99,.65-4.04,2.64-4.57l4.98-1.34c1.99-.53,4.04,.65,4.57,2.64l1.33,4.98Z"
        />
      </g>
      <text className="body-loading-5" transform="translate(241.64 152.08)">
        <tspan x="26" y="0">
          loading…
        </tspan>
      </text>
    </svg>
  );
}
