import { RootState } from '@/@types/models';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import { getNavigatePath, t2s } from '@/libs/utils';

import {
  ORIGINAL_WORKSPACE_ID,
  ORIGINAL_WORKSPACE_ID_REGEXP,
  WORKSPACE_NAME,
} from '@/libs/validations';

import WorkspaceRepository from '@/repositories/WorkspaceRepository';

import Alert from '@/components/Common/Alert';
import ConfirmDialog from '@/components/Common/ConfirmDialog';
import Divider from '@/components/Common/Divider';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import Form from '@/components/Common/Forms/Form';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import useAdminMembers from '@/hooks/useAdminMembers';
import useHandleApi from '@/hooks/useHandleApi';
import useMembers from '@/hooks/useMembers';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePlans from '@/hooks/usePlans';
import useSubmitState from '@/hooks/useSubmitState';

export default function Workspace() {
  const { t } = useTranslation();
  const { plan } = usePlans();
  const [changeWsIdDialogOpen, setChangeWsIdDialogOpen] =
    useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [exitDialogOpen, setExitDialogOpen] = useState<boolean>(false);
  const { members } = useMembers();
  const { updateWorkspaceName, deleteCurrentWorkspace } = useMyWorkspaces();
  const navigate = useNavigate();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { updateOriginalWorkspaceId, exitWorkspace } =
    new WorkspaceRepository();
  const { adminMember } = useAdminMembers();
  const { exec } = useHandleApi();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const { hostname, protocol } = useMemo(
    () => new URL(window.location.href),
    [],
  );

  // ワークスペース名 Zod schema定義
  const wnSchema = z.object({
    workspaceName: z.preprocess(
      (val) => String(val).trim(),
      z
        .string()
        .min(1, { message: t('入力してください') })
        .max(
          WORKSPACE_NAME.max,
          t2s(t('<max>文字以内で入力してください', WORKSPACE_NAME)),
        ),
    ),
  });

  // ワークスペース名 Zod Form
  const wnFormMethods = useForm({
    resolver: zodResolver(wnSchema),
    mode: VALIDATION_MODE,
    // inputの初期値
    defaultValues: {
      workspaceName: currentMyWorkspace?.workspaceName,
    },
  });

  // ワークスペース名 Form Submit
  const wnFormSubmit = wnFormMethods.handleSubmit((data) => {
    const { workspaceName } = data;
    // Update Workspace
    updateWorkspaceName(workspaceName);
    // Reset form state
    wnFormMethods.reset({ ...data });
  });

  // ワークスペースID Zod Schema定義
  const originalWorkspaceIdSchema = z.object({
    originalWorkspaceId: z.preprocess(
      (val) => String(val).trim(),
      z
        .string()
        .min(1, { message: t('入力してください') })
        .max(
          ORIGINAL_WORKSPACE_ID.max,
          t2s(t('<max>文字以内で入力してください', ORIGINAL_WORKSPACE_ID)),
        )
        .regex(
          ORIGINAL_WORKSPACE_ID_REGEXP,
          t2s(t('使用できない文字が含まれています')),
        ),
    ),
  });

  // ワークスペースID Zod Form
  const widFormMethods = useForm({
    resolver: zodResolver(originalWorkspaceIdSchema),
    mode: VALIDATION_MODE,
    // inputの初期値
    defaultValues: {
      originalWorkspaceId: currentMyWorkspace?.originalWorkspaceId,
    },
  });

  // ワークスペースID Form Submit
  const originalWorkspaceIdFormSubmit = widFormMethods.handleSubmit((data) => {
    const { originalWorkspaceId } = data;
    if (originalWorkspaceId) {
      setChangeWsIdDialogOpen(true);
    }
  });

  // ワークスペースID変更
  const changeWsIdCloseHandle = async (feedback?: boolean | undefined) => {
    if (feedback) {
      const { originalWorkspaceId } = widFormMethods.getValues();
      // 変更処理
      try {
        await updateOriginalWorkspaceId({
          workspaceId: currentMyWorkspace?.id as string,
          originalWorkspaceId,
        });

        // Reset form state
        widFormMethods.reset({
          originalWorkspaceId,
        });
        // 新しいワークスペースIDのURLにアクセス
        navigate(
          `${getNavigatePath(
            originalWorkspaceId as string,
          )}/settings/workspace`,
        );
      } catch (e: any) {
        if (e.code === 'functions/already-exists') {
          // 重複エラー
          widFormMethods.setError(
            'originalWorkspaceId',
            {
              type: 'manual',
              message: t('このワークスペースIDはすでに使われています'),
            },
            {
              shouldFocus: true,
            },
          );
        } else {
          toast.error(t('ワークスペースIDの更新に失敗しました'));
        }
        throw e;
      } finally {
        setChangeWsIdDialogOpen(false);
      }
    } else {
      setChangeWsIdDialogOpen(false);
    }
  };

  // currentMyWorkspaceがsubscriptionされていない状態だとデフォルト表示できない対策
  useEffect(() => {
    if (!wnFormMethods.formState.isDirty && currentMyWorkspace) {
      wnFormMethods.setValue('workspaceName', currentMyWorkspace.workspaceName);
    }
    if (!widFormMethods.formState.isDirty && currentMyWorkspace) {
      widFormMethods.setValue(
        'originalWorkspaceId',
        currentMyWorkspace.originalWorkspaceId,
      );
    }
  }, [currentMyWorkspace]);

  // Workspace削除 Zod schema定義
  const dlSchema = z.object({
    deleteWorkspaceName: z.preprocess(
      (val) => String(val).trim(),
      z.string().refine(
        (value: string) => value === currentMyWorkspace?.workspaceName,
        t2s(
          t('<workspaceName>と入力してください。', {
            workspaceName: currentMyWorkspace?.workspaceName,
          }),
        ),
      ),
    ),
  });

  // Workspace削除 Zod Form
  const dlMethods = useForm({
    resolver: zodResolver(dlSchema),
    mode: VALIDATION_MODE,
  });

  // ワークスペースから退出
  const [isProcessingExitWorkspace, setIsProcessingExitWorkspace] =
    useState<boolean>(false);
  const exitCloseHandle = async (feedback?: boolean | undefined) => {
    if (isProcessingExitWorkspace) return;
    try {
      if (feedback) {
        setIsProcessingExitWorkspace(true);
        await exec(async () => {
          await exitWorkspace({
            workspaceId: currentMyWorkspace?.workspaceId,
          });
          setExitDialogOpen(false);
          navigate('/');
        }, t('ワークスペースからの退会に失敗しました'));
      }
    } finally {
      setIsProcessingExitWorkspace(false);
      setExitDialogOpen(false);
    }
  };

  // ワークスペースを削除
  const [isProcessingDeleteWorkspace, setIsProcessingDeleteWorkspace] =
    useState<boolean>(false);
  const deleteCloseHandle = async (feedback?: boolean | undefined) => {
    if (isProcessingDeleteWorkspace) return;
    if (feedback) {
      setIsProcessingDeleteWorkspace(true);
      await deleteCurrentWorkspace();
    }
    setDeleteDialogOpen(false);
    setIsProcessingDeleteWorkspace(false);
  };

  // ワークスペースの契約者判定
  const isContractor = useMemo(
    () => userId === plan?.customerId,
    [plan?.customerId],
  );

  // ワークスペース退会不可判定
  const isDisabledExitWorkspace = useMemo<boolean>(() => {
    if (isContractor) return true;
    if (currentMyWorkspace?.joinType === 'guest') {
      return false;
    }
    if (
      currentMyWorkspace?.joinType === 'member' &&
      members.length > 1 &&
      (adminMember.memberList?.filter((o) => o !== userId).length || 0) >= 1
    ) {
      return false;
    }
    return true;
  }, [members, adminMember.memberList, currentMyWorkspace, isContractor]);

  // 管理者ユーザー判定
  const isAdmin = useMemo(() => {
    if (!adminMember.memberList) return false;
    return adminMember.memberList.includes(userId);
  }, [userId, adminMember.memberList]);

  // Submitボタン状態
  const isWnDisabledApply = useSubmitState(wnFormMethods);
  const isOriginalWsIdDisabledApply = useSubmitState(widFormMethods);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [isContractor, isDisabledExitWorkspace, deleteDialogOpen, dlMethods]);

  return (
    <div className="p-4">
      <h1 className="flex items-center mb-6 text-xl font-semibold xs:text-l justify-content-center">
        {t('ワークスペース')}
      </h1>
      {isAdmin && (
        <>
          <FormProvider {...wnFormMethods}>
            <Form
              onSubmit={wnFormSubmit}
              className="items-end w-full pb-4"
              flex
            >
              <FormCol className="flex-1 max-w-lg">
                <Input name="workspaceName" label={t('ワークスペース名')} />
              </FormCol>
              <div className="self-end">
                <FormButton
                  id="applyWorkspaceName"
                  disabled={isWnDisabledApply}
                  submit
                  variant="primary"
                  className="sm:w-auto"
                >
                  {t('適用')}
                </FormButton>
              </div>
            </Form>
          </FormProvider>
          <Divider />
          <FormProvider {...widFormMethods}>
            <Form
              onSubmit={originalWorkspaceIdFormSubmit}
              className="items-start w-full my-4"
              flex
            >
              <FormCol className="flex-1 max-w-lg">
                <Input
                  name="originalWorkspaceId"
                  label={t('ワークスペースID')}
                  addOn={{ left: `${protocol}//${hostname}/` }}
                />
              </FormCol>
              <div className="self-end">
                <FormButton
                  id="applyOriginalWorkspaceId"
                  disabled={isOriginalWsIdDisabledApply}
                  submit
                  variant="primary"
                  className="sm:w-auto"
                >
                  {t('適用')}
                </FormButton>
              </div>
            </Form>
            <div className="mb-4">
              <p className="mt-2 text-xs text-gray-500" id="email-description">
                {t(
                  'アルファベット、数字、ハイフンのみで入力してください。ただし、先頭と末尾にハイフンは使用できません。',
                )}
              </p>
            </div>
          </FormProvider>
          <Alert color="gray" icon={InformationCircleIcon}>
            <h3 className="font-medium">
              {t(
                '現在、ワークスペースのプロジェクトは <workspaceUrl> 以下に配置されています。',
                {
                  workspaceUrl: (
                    <span className="mr-1 font-bold">
                      {`${protocol}//${hostname}/${currentMyWorkspace?.originalWorkspaceId}/`}{' '}
                    </span>
                  ),
                },
              )}
            </h3>
          </Alert>
          <Divider className="my-4" />
        </>
      )}
      <Alert color="gray" icon={ExclamationTriangleIcon}>
        <h3 className="font-medium">
          {t('以下の操作には十分注意してください！')}
        </h3>
      </Alert>
      <div className="mt-5 sm:mt-4 sm:flex">
        <MenuButton
          id="exitWorkspace"
          type="text"
          className="!px-4 !py-2 sm:w-auto sm:mr-3"
          onClick={() => {
            setExitDialogOpen(true);
          }}
          disabled={isDisabledExitWorkspace}
          toolTip={
            isContractor
              ? t(
                  'このワークスペースの契約者のため退出できません。このワークスペースの有償プランを解約した後に再度操作してください。',
                )
              : ''
          }
        >
          {t('ワークスペースから退出')}
        </MenuButton>
        {isAdmin && (
          <MenuButton
            id="deleteWorkspace"
            type="text"
            variant="warning"
            className="mt-4 !px-4 !py-2 sm:mt-0 sm:w-auto sm:mr-3"
            onClick={() => {
              setDeleteDialogOpen(true);
            }}
            disabled={isContractor}
            toolTip={
              isContractor
                ? t(
                    '有償プランを契約中のため削除できません。先に有償プランの解約をしてください。',
                  )
                : ''
            }
          >
            {t('ワークスペースを削除')}
          </MenuButton>
        )}
      </div>
      <ConfirmDialog
        key="change-originalWorkspaceId-confirm"
        open={changeWsIdDialogOpen}
        onClose={changeWsIdCloseHandle}
        title={t('ワークスペースIDの変更')}
        positive={t('変更')}
        warning
      >
        <p className="mb-2">
          {t(
            'ワークスペースIDが変更されると、今まで共有されたURLが使用できなくなります。<br>本当によろしいですか？',
            { br: <br /> },
          )}
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        key="workspace-delete-confirm"
        open={deleteDialogOpen}
        onClose={deleteCloseHandle}
        title={t('ワークスペースを削除')}
        positive={t('削除')}
        warning
        disabled={!dlMethods.formState.isValid}
      >
        <p className="mb-2">
          {t(
            '<workspaceName> を削除するには以下に「<workspaceName>」と入力してください。',
            {
              workspaceName: (
                <span className="mr-1 font-bold">
                  {currentMyWorkspace?.workspaceName}
                </span>
              ),
            },
          )}
        </p>
        <FormProvider {...dlMethods}>
          <FormCol className="my-4">
            <Input
              name="deleteWorkspaceName"
              placeholder={currentMyWorkspace?.workspaceName || ''}
              disableAutoComplete
            />
          </FormCol>
        </FormProvider>
        <p>
          {t(
            '削除されたワークスペースは復元できません。<br> 本当によろしいですか？',
            { br: <br /> },
          )}
        </p>
      </ConfirmDialog>

      <ConfirmDialog
        key="exit-workspace-confirm"
        open={exitDialogOpen}
        onClose={exitCloseHandle}
        title={t('ワークスペースから退出')}
        positive={t('退出')}
        warning
      >
        <p className="mb-2">
          {t('<workspaceName> から退出しようとしています。', {
            workspaceName: (
              <span className="mr-1 font-bold">
                {currentMyWorkspace?.workspaceName}
              </span>
            ),
          })}
        </p>
        <p>{t('本当によろしいですか？', { br: <br /> })}</p>
      </ConfirmDialog>
    </div>
  );
}
