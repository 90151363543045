import { TRANSFORMERS } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin as LexicalLinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin as LexicalMarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TablePlugin } from '@lexical/react/LexicalTablePlugin';
import { $createQuoteNode, $isQuoteNode } from '@lexical/rich-text';
import { $selectAll, $wrapNodes } from '@lexical/selection';
import {
  $getRoot,
  $isRangeSelection,
  $createRangeSelection,
  $createParagraphNode,
  RootNode,
} from 'lexical';
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '@/libs/styleUtils';
import {} from '@lexical/link';

import lexicalInitialState from '@/components/Common/Lexical/configs/initialState';

import { $createMentionNode } from '@/components/Common/Lexical/nodes/MentionNode';
import MyCustomAutoFocusPlugin from '@/components/Common/Lexical/plugins/AutoFocusPlugin';
import AutoLinkPlugin from '@/components/Common/Lexical/plugins/AutoLinkPlugin';
import ClickableLinkPlugin from '@/components/Common/Lexical/plugins/ClickableLinkPlugin';
import CustomKeyBindingsPlugin from '@/components/Common/Lexical/plugins/CustomKeyBindingsPlugin';
import FloatingMenuPlugin from '@/components/Common/Lexical/plugins/FloatingMenuPlugin';
import HorizontalRulePlugin from '@/components/Common/Lexical/plugins/HorizontalRulePlugin';
import ImagesPlugin from '@/components/Common/Lexical/plugins/ImagesPlugin';
import LinkEditorPlugin from '@/components/Common/Lexical/plugins/LinkEditorPlugin';
import Placeholder from '@/components/Common/Lexical/plugins/Placeholder';
import UpdateStatePlugin, {
  IUpdateStatePluginHandler,
} from '@/components/Common/Lexical/plugins/UpdatePlugin';

// 外部コンポーネントから命令的に実行できるインターフェース
export type IBaseLexicalEditorHandler = {
  setChildDisabled: (bool: boolean) => void;
  resetContent: () => void;
  setFocus: () => void;
  setContent: (content: string) => void;
  setQuoteContent: (content: string) => void;
  setMentionContent: (id: string, name: string) => void;
};

type IBaseLexicalEditorProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  placeholder?: string;
  showFloatingToolbar?: boolean;
  handleChange: (
    lexicalRootNode: RootNode,
    isDirty: boolean,
    htmlString: string,
  ) => void;
  onCtrlEnter?: () => void;
};

const BaseLexicalEditor = forwardRef<
  IBaseLexicalEditorHandler,
  IBaseLexicalEditorProps
>((props: IBaseLexicalEditorProps, ref) => {
  const { t } = useTranslation();
  const {
    className,
    placeholder,
    showFloatingToolbar,
    handleChange,
    onCtrlEnter,
  } = props;

  const updateRef = useRef({} as IUpdateStatePluginHandler);
  const [, setIsDirty] = useState(false);
  const [editor] = useLexicalComposerContext();
  const [isDisabledChild, setIsDisabledChild] = useState(false);

  // 外部コンポーネントから命令的に実行できるメソッド
  useImperativeHandle(ref, () => ({
    setFocus() {
      editor.focus();
    },
    async setChildDisabled(bool: boolean) {
      if (!updateRef.current) return;
      setIsDisabledChild(bool);
    },
    async setContent(content: string) {
      if (!updateRef.current) return;
      updateRef.current.updateState(content);
    },
    async resetContent() {
      updateRef.current.updateState(lexicalInitialState);
    },
    async setQuoteContent(content: string) {
      if (!updateRef.current) return;
      updateRef.current.updateState(content);
      editor.focus();
      editor.update(() => {
        // plainTextを取得
        const childNodes = $getRoot().getChildren();
        const textArray: string[] = [];
        childNodes.forEach((node) => {
          const text = node.getTextContent();
          if ($isQuoteNode(node)) {
            const quoteText = `＞ ${text}`;
            textArray.push(quoteText);
          } else {
            textArray.push(text);
          }
        });

        // そのままだと改行が一行余計に入るのでtrimで削除
        const formatText = textArray.join('\n').trim();

        // テキストが存在する場合は引用処理
        if (formatText) {
          const root = $getRoot().clear();
          const selection = $createRangeSelection();
          selection.insertText(formatText);
          if ($isRangeSelection(selection)) {
            $selectAll(selection);
            $wrapNodes(selection, () => $createQuoteNode());
          }
          // 改行を入れる
          const paragraphNode = $createParagraphNode();
          paragraphNode.setDirection('ltr');
          root.append(paragraphNode);
          root.selectEnd();
        }
      });
    },
    setMentionContent(id: string, name: string) {
      editor.focus();
      editor.update(() => {
        const mentionNode = $createMentionNode(id, `@${name}`);
        const firstChild = $getRoot().getFirstChild();
        const paragraphNode = $createParagraphNode();
        paragraphNode.append(mentionNode);
        if (firstChild) {
          firstChild.insertBefore(paragraphNode);
        }
      });
    },
  }));

  // 入力時ハンドラ
  const onChange = () => {
    editor.update(() => {
      const root = $getRoot();
      const dirtyNodes = root.getAllTextNodes().filter((node) => node.isDirty);
      const editorState = editor.getEditorState();
      const html = JSON.stringify(editorState);
      const dirtyFlg =
        dirtyNodes.length > 0 || editor._dirtyElements.values.length > 0;
      setIsDirty(dirtyFlg);
      handleChange(root, dirtyFlg, html);
    });
  };

  return (
    // ※ 汎用性の都合からLexicalComposerでラップするのは各利用コンポーネントにゆだねる
    <div className="flex-col h-full editor-inner">
      <RichTextPlugin
        ErrorBoundary={LexicalErrorBoundary}
        contentEditable={
          <ContentEditable
            className={classNames('flex-col h-full editor-input', className)}
          />
        }
        placeholder={
          <Placeholder>
            {placeholder ? t('テキストを入力してください') : ''}
          </Placeholder>
        }
      />
      <OnChangePlugin onChange={onChange} />
      <UpdateStatePlugin ref={updateRef} />
      <MyCustomAutoFocusPlugin />
      <LexicalLinkPlugin />
      <AutoLinkPlugin />
      <FloatingMenuPlugin
        show={isDisabledChild !== true && showFloatingToolbar !== false}
      />
      <LexicalMarkdownShortcutPlugin transformers={TRANSFORMERS} />
      <LinkEditorPlugin show={isDisabledChild !== true} />
      <CustomKeyBindingsPlugin onCtrlEnter={onCtrlEnter} />
      <HorizontalRulePlugin />
      <ListPlugin />
      <TablePlugin />
      <HistoryPlugin />
      <ClickableLinkPlugin />
      <ImagesPlugin />
    </div>
  );
});
export default BaseLexicalEditor;
