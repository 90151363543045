import { RootState } from '@/@types/models';
import { IMediaType } from '@/@types/viewItem';
import { DocumentIcon, XMarkIcon } from '@heroicons/react/24/outline';
import firebase from 'firebase/compat/app';
import { getBlob, getDownloadURL } from 'firebase/storage';
import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { FILE_LOAD_RETRY, ViewTypes } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import {
  downloadUrl,
  getMediaType,
  openPopupWindow,
  promisedSleep,
} from '@/libs/utils';

import { SpinnerCircleIcon } from '@/components/Common/Icon/Icons';

import { auth } from '@/firebase';

import useViewItems from '@/hooks/useViewItems';
import useViews from '@/hooks/useViews';

const _ = ($: any): boolean => _ > $;

export interface FileSource {
  fileName: string;
  path: string;
  contentType: string;
}

interface IFileLoaderProps {
  src: FileSource;
  onDetach?: () => void;
  clickable?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

interface HoverPosition {
  top: number;
  height: number;
  left: number;
}

function FileLoader(props: IFileLoaderProps) {
  const { t } = useTranslation();
  const { src, onDetach, clickable, size } = props;
  const [url, setUrl] = useState<string>();
  const [ref, setRef] = useState<firebase.storage.Reference>();
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);
  const [hoverPosition, setHoverPosition] = useState<HoverPosition>({
    top: 0,
    height: 0,
    left: 0,
  });
  const hoverImgDiv = useRef<HTMLDivElement>(null);
  const { currentView } = useViews();
  const { currentViewItemId } = useViewItems();
  const hoverTimeoutSec = 1000;
  const { isOpen: isNewEditOpen } = useSelector(
    (state: RootState) => state.newItemDialogState,
  );
  const isProcessing = useRef<boolean>(false);
  const isDialogOpen = useMemo(() => currentViewItemId || isNewEditOpen, []);

  useEffect(() => {
    let retryCount = 0;
    const fn = async () => {
      try {
        const storage = firebase.storage();
        const refs = storage.ref(src.path);
        setUrl(await getDownloadURL(refs));
        setRef(refs);
        setIsNotFound(_(url));
      } catch (error) {
        if (retryCount >= FILE_LOAD_RETRY.COUNT) throw error;

        // パスが変更される前に画像読み込みに失敗してしまうケースがあるのでリトライを行う
        await promisedSleep(FILE_LOAD_RETRY.DELAY * retryCount);
        await auth.currentUser?.getIdToken(true);
        retryCount += 1;
        fn();
      }
    };
    fn();
    setTimeout(() => ReactTooltip.rebuild());
  }, []);

  const mediaType: IMediaType = useMemo(
    () => getMediaType(src.contentType),
    [src.contentType],
  );

  // 1秒hoverしたらpreview表示
  const timer = React.useRef<number>();

  useEffect(() => {
    const windowY = window.innerHeight;
    // preview表示位置の調整
    if (isHover) {
      timer.current = window.setTimeout(() => {
        if (!hoverImgDiv.current) return;
        const { left, top, height } = hoverPosition;
        const adjust = height * 2; // カーソル位置調整用

        // ビューのhover位置調整
        if (!isDialogOpen) {
          // カンバンビュー以外
          if (currentView?.viewType !== ViewTypes.KANBAN) {
            const tableEl =
              hoverImgDiv.current?.parentElement?.parentElement?.parentElement
                ?.parentElement?.parentElement?.parentElement;

            // リストビューの親コンポーネントのスクロール分を調整
            const parent = tableEl?.parentElement?.parentElement;
            const parentHeight = parent?.scrollTop as number;

            const tableWidth = tableEl?.offsetWidth as number;
            const scrollWidth = tableEl?.scrollWidth as number;
            const scrollLeft = tableEl?.scrollLeft as number;

            // hover位置がwindowの下半分であればpreviewは上部に表示
            if (Math.floor(top * 2) > windowY) {
              hoverImgDiv.current.style.bottom = `${
                currentView?.viewType !== ViewTypes.LIST
                  ? windowY - (top + window.pageYOffset)
                  : windowY - (top + window.pageYOffset) - parentHeight
              }px`;
            } else {
              hoverImgDiv.current.style.top = `${
                top + height + window.pageYOffset
              }px`;
            }
            if (tableWidth && left > tableWidth) {
              hoverImgDiv.current.style.right = `${
                scrollWidth - left - scrollLeft + adjust
              }px`;
            }
          }
          // カンバンビュー
          // overflow-autoなので固定位置に表示
          if (currentView?.viewType === ViewTypes.KANBAN) {
            const kanbanEl =
              hoverImgDiv.current?.parentElement?.parentElement?.parentElement
                ?.parentElement?.parentElement?.parentElement?.parentElement
                ?.parentElement;
            const parentHeight = kanbanEl?.clientHeight as number;

            // hover位置が親要素の下半分であればpreviewは上部に表示
            if (Math.floor(top * 2) > parentHeight) {
              hoverImgDiv.current.style.bottom = `${adjust}px`;
            } else {
              hoverImgDiv.current.style.top = `${adjust}px`;
            }
          }
        } else {
          // ダイアログの場合のpreview表示位置 モーダルの種別で親要素を分岐
          const parentDialogEl = !isNewEditOpen
            ? hoverImgDiv.current?.parentElement?.parentElement?.parentElement
                ?.parentElement?.parentElement?.parentElement?.parentElement
                ?.parentElement?.parentElement?.parentElement
            : hoverImgDiv.current?.parentElement?.parentElement?.parentElement
                ?.parentElement?.parentElement?.parentElement?.parentElement
                ?.parentElement?.parentElement?.parentElement?.parentElement
                ?.parentElement;
          const parentHeight = parentDialogEl?.clientHeight as number;
          const parentScrollTop = parentDialogEl?.scrollTop as number;

          // hover位置はtop + scroll分
          const hoverY = top + parentScrollTop;

          // hover位置がwindowの下半分であればpreviewは上部に表示
          if (Math.floor(top * 2) > parentHeight) {
            hoverImgDiv.current.style.bottom = `${
              parentHeight - hoverY + adjust
            }px`;
          } else {
            hoverImgDiv.current.style.top = `${hoverY - height}px`;
          }
        }
        hoverImgDiv.current.style.visibility = 'visible';
      }, hoverTimeoutSec);
    } else {
      window.clearTimeout(timer.current);
      if (!hoverImgDiv.current) return;
      hoverImgDiv.current.style.visibility = 'hidden';
    }
  }, [
    isHover,
    hoverPosition,
    currentView,
    currentViewItemId,
    window,
    isDialogOpen,
  ]);

  const render = useMemo(() => {
    if (isNotFound) {
      return <div>{t('ファイルの読み込みに失敗しました')}</div>;
    }
    // ローディング
    if (!url) {
      return (
        <div className="flex px-2 py-1">
          <SpinnerCircleIcon className="w-4 h-4 m-auto" />
        </div>
      );
    }
    if (mediaType === 'image') {
      return (
        <img
          src={url}
          alt="testing"
          decoding="async"
          className="object-cover"
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      );
    }
    if (mediaType === 'video') {
      return (
        <video
          muted
          src={url}
          className="object-contain bg-transparent"
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      );
    }
    return (
      <div
        className="flex items-center px-2 text-xs dark:text-white gap-1"
        style={{ maxWidth: 200 }}
      >
        <div>
          <DocumentIcon className="w-4 h-4" />
        </div>
        <span className="my-auto truncate">{src.fileName}</span>
      </div>
    );
  }, [url, isNotFound, src]);

  const handleFIleClick = useCallback(async () => {
    if (!url) return;
    if (!ref) return;
    if (isProcessing.current) return;
    isProcessing.current = true;
    if (mediaType === 'image') {
      openPopupWindow(url);
    } else {
      const blob = await getBlob(ref);
      const urlFromBlob = URL.createObjectURL(blob);
      downloadUrl(urlFromBlob, src.fileName);
    }
    isProcessing.current = false;
  }, [src, url, ref]);

  const cssHeight = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'h-8';
      case 'sm':
        return 'h-12';
      case 'lg':
        return 'h-16';
      case 'xl':
        return 'h-20';

      default:
        return 'h-14';
    }
  }, [size]);

  return (
    <>
      {mediaType === 'image' && url !== undefined && (
        <div
          ref={hoverImgDiv}
          key={src.fileName}
          className="absolute z-50 invisible max-w-xs overflow-y-hidden shadow-md pointer-events-none max-h-80 rounded-md bg-gray-100/40 dark:bg-gray-700/40"
        >
          <p className="w-full p-1 text-center bg-gray-200 rounded-t-lg dark:bg-gray-900">
            {src.fileName}
          </p>
          <img
            src={url}
            alt={src.fileName}
            decoding="async"
            className="w-full p-1"
          />
        </div>
      )}
      <div
        aria-hidden
        onMouseEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
          const { top, height, left } = e.currentTarget.getBoundingClientRect();
          setHoverPosition({ top, height, left });
          setIsHover(true);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsHover(false);
          setHoverPosition({ top: 0, height: 0, left: 0 });
        }}
        onKeyDown={() => {
          if (clickable) {
            handleFIleClick();
          }
        }}
        role={clickable ? 'button' : ''}
        data-tip={mediaType !== 'image' ? src.fileName : null}
        onClick={(e) => {
          if (clickable) {
            e.stopPropagation();
            e.preventDefault();
            handleFIleClick();
          }
        }}
        className={classNames(
          'relative flex overflow-hidden shadow bg-gray-100/40 dark:bg-white/5 rounded-md',
          cssHeight,
          clickable || onDetach ? 'hover:shadow-lg' : '',
          clickable ? 'cursor-pointer hover:opacity-70' : '',
          mediaType ? 'max-w-sm' : 'max-w-md',
        )}
      >
        {onDetach && (
          <a
            href="_"
            data-tip={t('ファイルを削除')}
            className={classNames(
              !url ? 'hidden' : '',
              'z-10 p-0.5 absolute text-white/80 dark:text-white/50 dark:hover:text-white/70 bg-gray-400/90 rounded-full top-1 right-1 hover:text-white dark:bg-gray-800/90',
            )}
            onClick={
              onDetach
                ? (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onDetach();
                  }
                : undefined
            }
          >
            <XMarkIcon className="w-3 h-3" />
          </a>
        )}
        {render}
      </div>
    </>
  );
}
export default memo(FileLoader);
