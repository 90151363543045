import React from 'react';

import Loading from '@/components/Common/Loading';

import useFirebaseAuth from '@/hooks/useFirebaseAuth';

export default function LoadingPage() {
  const { subscribeRedirectResult } = useFirebaseAuth();

  // 認証後のリダイレクトを待ち受け(成功時はApp.tsxのonAuthStateChangedで処理)
  subscribeRedirectResult();

  // ローディング表示
  return <Loading className="w-screen h-screen" />;
}
