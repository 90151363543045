import * as React from 'react';

import { classNames } from '@/libs/styleUtils';

export interface TabItem {
  id: string | number;
  label: string;
  icon?: string;
}

export interface ITabProps {
  items: TabItem[];
  className?: string;
  children: React.ReactNode[] | React.ReactNode;
  placeholder: string;
  onChange?: (item: TabItem) => void;
}

/**
 * タブ
 * @param props
 * @returns
 */
export default function Tab(props: ITabProps) {
  const { items, onChange, children, placeholder, className } = props;
  const [selectedTab, setSelectedTab] = React.useState<TabItem>(items[0]);

  return (
    <div className={className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          {placeholder}
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full py-2 pl-3 pr-10 mb-4 text-base text-gray-700 bg-white border-gray-300 shadow-sm dark:border-gray-600 focus:outline-none focus:ring-primary-500 dark:focus:ring-primary-600 focus:border-primary-500 dark:focus:border-primary-600 sm:text-sm rounded-md dark:bg-gray-700 dark:text-gray-300"
          defaultValue={items.find((tab) => tab === selectedTab)?.id}
          onChange={(e) => {
            const sel = items.find(
              (tab) => `${tab.id}` === e.currentTarget.value,
            );
            if (sel) {
              setSelectedTab(sel);
              if (onChange) onChange(sel);
            }
          }}
        >
          {items.map((tab) => (
            <option key={tab.id} value={tab.id}>
              {tab.label}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <nav className="flex -mb-px space-x-8" aria-label="Tabs">
            {items.map((tab: TabItem) => (
              <button
                type="button"
                key={tab.label}
                onClick={() => {
                  setSelectedTab(tab);
                  if (onChange) onChange(tab);
                }}
                className={classNames(
                  selectedTab === tab
                    ? 'border-primary-500 text-primary-600 dark:border-primary-600 dark:text-primary-600'
                    : 'border-transparent text-gray-400 hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 dark:hover:border-gray-600',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium inline-flex items-center space-x-2',
                )}
                aria-current={tab === selectedTab ? 'page' : undefined}
              >
                {tab.icon && (
                  <img src={tab.icon} className="w-4 h-4 shrink-0" alt="logo" />
                )}

                <span>{tab.label}</span>
              </button>
            ))}
          </nav>
        </div>
      </div>
      {Array.isArray(children)
        ? children.map(
            (child, index) => index === items.indexOf(selectedTab) && child,
          )
        : children}
    </div>
  );
}
