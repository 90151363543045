import {
  CreateUpdateItemProperty,
  CreateUpdateItemPropertyValue,
  CreateUpdateItemStatus,
  OriginalColorType,
} from '@/@types/common';
import {
  Property,
  Status,
  PropertyValue,
  Item,
  FileArrayValue,
  MultiSelectPropertyValue,
  StatusOrder,
  View,
  PropertyOrder,
  IPropertyType,
} from '@/@types/models';
import { IProperty } from '@/@types/viewItem';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import _ from 'lodash';
import { useMemo, useCallback } from 'react';

import { useForm, useFieldArray } from 'react-hook-form';

import { z } from 'zod';

import {
  NUMBER_PROPERTY_FORMAT_TYPE,
  PROPERTY_TYPE,
  VALIDATION_MODE,
} from '@/libs/const';
import { getPropertyValueFilesPath } from '@/libs/docPathUtils';
import { getLastSplittedElement, match, revMatch, t2s } from '@/libs/utils';

import { PROPERTY_STRING, TITLE } from '@/libs/validations';

import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useProperties from '@/hooks/useProperties';
import useStatuses from '@/hooks/useStatuses';
import useStatusOrders from '@/hooks/useStatusOrders';
import useViews from '@/hooks/useViews';

export interface IItemFormDefaultValues {
  itemTitle: string;
  properties: IProperty[];
  deletedProperties: IProperty[];
}
const isMultiSelectPropertyValue = (
  data: any,
): data is MultiSelectPropertyValue => data.propertyType === 'multiSelect';

/**
 * アイテム作成・更新時のプロパティ作成・更新用Hook
 */
export default function useEditItem() {
  const { properties, generatePropertyId } = useProperties();
  const { currentView } = useViews();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const { currentItem } = useItems();
  const { statuses, createStatus, updateStatus, deleteStatus } = useStatuses();
  const { statusOrder, updateStatusOrder } = useStatusOrders();

  /**
   * プロパティの編集設定を取得
   * @param propertyType
   * @returns
   */
  const getEditOption = (
    propertyType: IPropertyType | 'status',
  ): IProperty['editOption'] => {
    // 詳細画面が必要なプロパティを判定
    const isContentEditable = (
      [
        PROPERTY_TYPE.SINGLE_SELECT,
        PROPERTY_TYPE.MULTI_SELECT,
        PROPERTY_TYPE.NUMBER,
      ] as any
    ).includes(propertyType);

    const editOption: IProperty['editOption'] =
      propertyType === 'status'
        ? {
            name: false,
            content: true,
            delete: false,
          }
        : {
            name: true,
            content: isContentEditable,
            delete: true,
          };
    return editOption;
  };

  /**
   * 画面表示用のプロパティリストを生成する
   * @returns {IProperty[]}
   */
  const composeProperties = (
    prs: Property[],
    sts: Status[],
    stv: Item['statusId'],
    prv: PropertyValue[] = [],
  ): IProperty[] => {
    const propertyList = prs.flatMap<IProperty>((op) => {
      if (!op.id || !op.propertyName || !op.propertyType) return [];
      const propertyValue = prv.find((pv) => pv.propertyId === op?.id);

      // IPropertyのベース
      const propertyBase = {
        docId: op.id,
        stringValue: '',
        arrayValue: [],
        booleanValue: false,
        numberValue: null,
        propertyName: op.propertyName,
        propertyType: op.propertyType,
        options: [],
        isShare: op.isShare,
        isDueDate: false,
        format: null,
        editOption: getEditOption(op.propertyType),
      };

      // property, propertyValue種別ごとの分岐処理
      // 各々の差分を画面上で扱うIPropertyに統合する
      // ※ プロパティパターンマッチ
      const propertyProps = match<Property, IProperty>(op)({
        text: () => propertyBase,
        file: () => propertyBase,
        singleSelect: (property) => ({
          ...propertyBase,
          options: property.options,
        }),
        multiSelect: (property) => ({
          ...propertyBase,
          options: property.options,
        }),
        checkbox: () => propertyBase,
        incharge: () => propertyBase,
        date: () => propertyBase,
        number: (property) => ({
          ...propertyBase,
          format: property.format,
        }),
      });

      // 既存の入力値がない場合はデフォルトの構造で返す(新規作成等)
      if (
        !propertyValue ||
        !currentMyWorkspace ||
        !currentMyProject ||
        !currentItem
      )
        return propertyProps;

      // ※ プロパティパターンマッチ
      const propertyValueProps = match<PropertyValue, IProperty>(propertyValue)(
        {
          text: (property) => ({
            ...propertyProps,
            stringValue: property.stringValue,
          }),
          file: (property) => ({
            ...propertyProps,
            // ※ FSに保存しているデータはファイルIDのみのため 以下で参照パスを生成する
            arrayValue: property.arrayValue.map((value) => {
              const basePropertyValueFilesPath = getPropertyValueFilesPath(
                currentMyWorkspace.workspaceId,
                currentMyProject.projectId,
                currentItem.id,
                property.propertyId,
              );
              return {
                ...value,
                path: `${basePropertyValueFilesPath}/${value.path}`,
              };
            }),
          }),
          singleSelect: (property) => ({
            ...propertyProps,
            stringValue: property.stringValue,
          }),
          multiSelect: (property) => ({
            ...propertyProps,
            arrayValue: property.arrayValue,
          }),
          date: (property) => ({
            ...propertyProps,
            numberValue: property.numberValue || null,
            isDueDate: property.isDueDate,
          }),
          checkbox: (property) => ({
            ...propertyProps,
            booleanValue: property.booleanValue as boolean,
          }),
          incharge: (property) => ({
            ...propertyProps,
            arrayValue: property.arrayValue,
          }),
          number: (property) => ({
            ...propertyProps,
            numberValue: property.numberValue as number,
          }),
        },
      );
      return propertyValueProps;
    });

    const status: IProperty = {
      docId: 'system__status', // TODO: システム固定プロパティのIDをどうするか
      stringValue: stv || sts[0]?.id || '', // デフォルトは0番目のステータス
      arrayValue: [],
      numberValue: null,
      booleanValue: false,
      propertyName: t('ステータス'),
      propertyType: PROPERTY_TYPE.STATUS,
      options: sts.map((st) => ({
        id: st.id as string,
        text: st.statusName as string,
        color: st.statusColor as OriginalColorType,
        icon: st.icon as string,
      })),
      format: null,
      isShare: true,
      editOption: getEditOption('status'),
    };

    return [
      status, // ステータスは最上部固定
      ...propertyList,
    ];
  };

  // propertyValuesの整形（編集権限で単体で使うので外だし）
  const reduceFuncPropertyValues = (acc: PropertyValue[], cur: IProperty) => {
    // システム値のステータスは空値へ変更できない
    const allowEmpty = cur.propertyType !== PROPERTY_TYPE.STATUS;
    const denyUpdate = allowEmpty ? !cur.docId : !cur.docId;
    if (denyUpdate) return acc;

    // 更新判定
    // ※ プロパティパターンマッチ
    const propertyValue = revMatch<
      IProperty,
      PropertyValue,
      PropertyValue | undefined
    >(cur)({
      text: (data) => {
        if (data.stringValue === undefined) return undefined;
        return {
          propertyId: data.docId,
          propertyType: data.propertyType,
          stringValue: data.stringValue,
          isShare: data.isShare,
        };
      },
      file: (data) => {
        if (data.arrayValue === undefined) return undefined;
        const arrayValue = data.arrayValue as FileArrayValue[];
        return {
          propertyId: data.docId,
          propertyType: data.propertyType,
          arrayValue: arrayValue.map((value) => ({
            contentType: value.contentType,
            fileName: value.fileName,
            path: getLastSplittedElement(value.path, '/'),
          })),
          isShare: data.isShare,
        };
      },
      singleSelect: (data) => {
        if (data.stringValue === undefined) return undefined;
        return {
          propertyId: data.docId,
          propertyType: data.propertyType,
          stringValue: data.stringValue,
          isShare: data.isShare,
        };
      },
      multiSelect: (data) => {
        if (data.arrayValue === undefined || !isMultiSelectPropertyValue(data))
          return undefined;
        return {
          propertyId: data.docId,
          propertyType: data.propertyType,
          arrayValue: data.arrayValue.map((value) => ({
            stringValue: value.stringValue,
          })),
          isShare: data.isShare,
        };
      },
      date: (data) => {
        if (data.numberValue === undefined) return undefined;
        return {
          propertyId: data.docId,
          propertyType: data.propertyType,
          numberValue: data.numberValue,
          isShare: data.isShare,
          isDueDate: data.isDueDate,
        };
      },
      checkbox: (data) => {
        if (data.booleanValue === undefined) return undefined;
        return {
          propertyId: data.docId,
          propertyType: data.propertyType,
          booleanValue: data.booleanValue,
          isShare: data.isShare,
        };
      },
      incharge: (data) => {
        if (data.arrayValue === undefined) return undefined;
        const arrayValue = data.arrayValue as string[];
        return {
          propertyId: data.docId,
          propertyType: data.propertyType,
          arrayValue,
          isShare: data.isShare,
        };
      },
      number: (data) => {
        if (data.numberValue === undefined) return undefined;
        return {
          propertyId: data.docId,
          propertyType: data.propertyType,
          numberValue: data.numberValue as number,
          isShare: data.isShare,
        };
      },
    });

    if (!propertyValue) return acc;

    acc.push(propertyValue);

    return acc;
  };

  /**
   * プロパティの作成・更新・削除オブジェクト
   * @param {IProperty[]} inputProperties 既存または追加されたプロパティの配列
   * @returns propertyValues作成・更新・削除用の値
   */
  const getUpdateAndComposePropertyValues = useCallback(
    (
      allProperties: IProperty[], // 全てのプロパティ
      deletedProperties: IProperty[],
      dirtyProperties?: IProperty[], // dirty判定されたプロパティ
    ): {
      createProperties: CreateUpdateItemProperty[];
      updateProperties: CreateUpdateItemProperty[];
      deleteProperties: Property['id'][];
      propertyOrderList: PropertyOrder['orderList'];
      viewPropertyOrderList: View['propertyOrderList'];
      createPropertyValues: CreateUpdateItemPropertyValue[];
      updatePropertyValues: CreateUpdateItemPropertyValue[];
    } => {
      // 1. 新規作成するプロパティをフィルタリング（__から始まるもの）
      const inputProperties = dirtyProperties || allProperties;

      const newProperties = inputProperties
        .filter((p) => p.docId.startsWith('__'))
        .map((p) => {
          const docId = generatePropertyId();
          return { ...p, docId };
        });

      // 2. 既存のプロパティと新規作成するプロパティをマージ
      // dirtyValueのプロパティのみ
      const currentProperties = inputProperties.filter((ip) =>
        properties.find((ps) => ps.id === ip.docId),
      );

      // // 全てのプロパティ(orders用)
      const allCurrentProperties = allProperties.filter((ip) =>
        properties.find((ps) => ps.id === ip.docId),
      );

      const ordersProperties = [...allCurrentProperties, ...newProperties];

      // 更新対象のプロパティ（value,設定いずれか更新）
      const existProperties = [...currentProperties, ...newProperties];

      // 3. プロパティ設定更新データ
      const updatePropertiesObj = existProperties.reduce<
        CreateUpdateItemProperty[]
      >((acc, cur) => {
        const fbStoredProperty = properties.find((p) => p.id === cur.docId);
        if (!fbStoredProperty) return acc;
        const { docId } = cur;

        const withoutIdProperty = {
          ...fbStoredProperty,
        };
        delete withoutIdProperty.id;
        // 更新判定
        // ※ プロパティパターンマッチ
        match<Property, void>(withoutIdProperty)({
          text: (property) => {
            if (cur.propertyName !== property.propertyName) {
              acc.push({
                id: docId,
                propertyName: cur.propertyName,
                propertyType: 'text',
              });
            }
          },
          file: (property) => {
            if (cur.propertyName !== property.propertyName) {
              acc.push({
                id: docId,
                propertyName: cur.propertyName,
                propertyType: 'file',
              });
            }
          },
          singleSelect: (property) => {
            if (
              cur.propertyName !== property.propertyName ||
              !_.isEqual(cur.options, property.options)
            ) {
              acc.push({
                id: docId,
                propertyName: cur.propertyName,
                propertyType: 'singleSelect',
                options: cur.options,
              });
            }
          },
          multiSelect: (property) => {
            if (
              cur.propertyName !== property.propertyName ||
              !_.isEqual(cur.options, property.options)
            ) {
              acc.push({
                id: docId,
                propertyName: cur.propertyName,
                propertyType: 'multiSelect',
                options: cur.options,
              });
            }
          },
          date: (property) => {
            if (cur.propertyName !== property.propertyName) {
              acc.push({
                id: docId,
                propertyName: cur.propertyName,
                propertyType: 'date',
              });
            }
          },
          checkbox: (property) => {
            if (cur.propertyName !== property.propertyName) {
              acc.push({
                id: docId,
                propertyName: cur.propertyName,
                propertyType: 'checkbox',
              });
            }
          },
          number: (property) => {
            if (
              cur.propertyName !== property.propertyName ||
              cur.format !== property.format
            ) {
              acc.push({
                id: docId,
                propertyName: cur.propertyName,
                propertyType: 'number',
                format: cur.format,
              });
            }
          },
          incharge: (property) => {
            if (cur.propertyName !== property.propertyName) {
              acc.push({
                id: docId,
                propertyName: cur.propertyName,
                propertyType: 'incharge',
              });
            }
          },
        });
        return acc;
      }, []);

      // 4. プロパティ設定新規作成データ
      const createPropertiesObj = newProperties.map((p) => {
        // ※ プロパティパターンマッチ
        const newProperty = revMatch<IProperty, CreateUpdateItemProperty>(p)({
          text: (data) => ({
            id: p.docId,
            propertyType: data.propertyType,
            propertyName: data.propertyName,
          }),
          file: (data) => ({
            id: p.docId,
            propertyType: data.propertyType,
            propertyName: data.propertyName,
          }),
          singleSelect: (data) => ({
            id: p.docId,
            propertyType: data.propertyType,
            propertyName: data.propertyName,
            options: data.options,
          }),
          multiSelect: (data) => ({
            id: p.docId,
            propertyType: data.propertyType,
            propertyName: data.propertyName,
            options: data.options,
          }),
          date: (data) => ({
            id: p.docId,
            propertyType: data.propertyType,
            propertyName: data.propertyName,
          }),
          checkbox: (data) => ({
            id: p.docId,
            propertyType: data.propertyType,
            propertyName: data.propertyName,
          }),
          incharge: (data) => ({
            id: p.docId,
            propertyType: data.propertyType,
            propertyName: data.propertyName,
          }),
          number: (data) => ({
            id: p.docId,
            propertyType: data.propertyType,
            propertyName: data.propertyName,
            format: data.format,
          }),
        });
        return newProperty;
      });

      // 5. プロパティ設定削除API（既存プロパティの削除）
      const deletedPropertyIds = deletedProperties
        .filter((dp) => properties.find((ps) => ps.id === dp.docId))
        .map((dp) => dp.docId);

      const deletePropertiesObj: Property['id'][] = deletedPropertyIds.map(
        (prId) => prId,
      );

      // 7. propertyOrdersの更新
      const newPropertyOrderList: PropertyOrder['orderList'] =
        ordersProperties.map((p) => p.docId);

      let newViewPropertyOrderList: View['propertyOrderList'];

      // 8. propertyValuesを作成・更新するためのデータを整形

      // 9. 新規追加したプロパティはビューに追加(順番は追加順)
      if (currentView?.id && currentMyProject?.id) {
        const newOrderList: View['propertyOrderList'] = ordersProperties.map(
          (p) => p.docId,
        );

        const currentViewOrders = currentView?.propertyOrderList || [];
        const newPropertyIds = newProperties.map((p) => p.docId);
        const updateViewOrders = currentViewOrders
          ? currentViewOrders.filter((p) => newOrderList.includes(p))
          : newOrderList;
        newViewPropertyOrderList = [
          ...(updateViewOrders || []),
          ...newPropertyIds,
        ];
      }

      const newPropertyValues: CreateUpdateItemPropertyValue[] =
        newProperties.reduce(reduceFuncPropertyValues, []);
      const updatingPropertyValues: CreateUpdateItemPropertyValue[] =
        currentProperties.reduce(reduceFuncPropertyValues, []);

      return {
        createProperties: createPropertiesObj || [],
        updateProperties: updatePropertiesObj || [],
        deleteProperties: deletePropertiesObj || [],
        propertyOrderList: newPropertyOrderList || [],
        viewPropertyOrderList: newViewPropertyOrderList || [],
        createPropertyValues: newPropertyValues || [],
        updatePropertyValues: updatingPropertyValues || [],
      };
    },
    [properties, currentView],
  );

  /**
   * statuses+statusOrderの更新
   */
  const updateStatuses = useCallback(
    async (status: IProperty) => {
      const statusOptions = status.options || [];
      const newStatuses = statusOptions.filter(
        (so) => !statuses.find((s) => s.id === so.id),
      );
      const [existing, deleted] = _.partition(statuses, (so) =>
        statusOptions.find((st) => st.id === so.id),
      );
      const createApis = newStatuses.map((ns) =>
        createStatus(ns.text, ns.color, 0, 0, ns.id, '💬'),
      );
      const updateApis = existing.map((ex) => {
        const newStatus = statusOptions.find((s) => s.id === ex.id);
        return updateStatus(
          newStatus?.text,
          newStatus?.icon ?? '',
          newStatus?.color,
          ex.id,
        );
      });
      const deleteApis = deleted.map((dl) => deleteStatus(dl.id));
      // status作成/更新/削除API
      await Promise.all([...createApis, ...updateApis, ...deleteApis]);

      const newStatusOrder = statusOptions.map((so) => so.id);
      // statusOrder更新API
      await updateStatusOrder(newStatusOrder, statusOrder.id);
    },
    [statuses, statusOrder],
  );

  /**
   * statuses+statusOrderの更新
   */
  const getUpdateStatuses = useCallback(
    (status: IProperty) => {
      const statusOptions = status.options || [];
      const newStatuses = statusOptions.filter(
        (so) => !statuses.find((s) => s.id === so.id),
      );
      const [existing, deleted] = _.partition(statuses, (so) =>
        statusOptions.find((st) => st.id === so.id),
      );
      const createStatusesObj: CreateUpdateItemStatus[] = newStatuses.map(
        (ns) => ({
          id: ns.id,
          icon: '💬',
          statusColor: ns.color,
          statusName: ns.text,
        }),
      );
      const updateStatusesObj: CreateUpdateItemStatus[] = existing.map((ex) => {
        const ns = statusOptions.find((s) => s.id === ex.id);
        return {
          id: ex.id,
          icon: ns?.icon,
          statusColor: ns?.color,
          statusName: ns?.text,
        };
      });
      const deleteStatusesArr: Status['id'][] = deleted.map((dl) => dl.id);

      const newStatusOrder: StatusOrder['orderList'] = statusOptions.map(
        (so) => so.id,
      );
      return {
        createStatuses: createStatusesObj,
        updateStatuses: updateStatusesObj,
        deleteStatuses: deleteStatusesArr,
        statusOrder: newStatusOrder,
      };
    },
    [statuses, statusOrder],
  );

  // ===== アイテム作成・更新共通 zod設定 ======
  // ↓↓↓
  //  入力項目デフォルト値
  const defaultValues: IItemFormDefaultValues = useMemo(
    () => ({
      itemTitle: '',
      properties: [] as IProperty[],
      deletedProperties: [] as IProperty[],
    }),
    [],
  );

  const schema = z.object({
    itemTitle: z
      .string()
      .trim()
      .min(1, { message: t('入力してください') })
      .max(TITLE.max, t2s(t('<max>文字以内で入力してください', TITLE))),
    properties: z.array(
      z
        .object({
          docId: z.string(),
          propertyType: z.nativeEnum(PROPERTY_TYPE),
          propertyName: z
            .string()
            .trim()
            .min(1, { message: t('入力してください') }),
          stringValue: z
            .string()
            .trim()
            .max(
              PROPERTY_STRING.max,
              t2s(t('<max>文字以内で入力してください', PROPERTY_STRING)),
            )
            .nullable(),
          arrayValue: z.array(z.any()), // zodのデータ上はanyとしておく
          numberValue: z
            .number({
              invalid_type_error: t('数値で入力してください'),
            })
            .nullable(),
          booleanValue: z.boolean(),
          options: z.array(
            z.object({
              id: z.string(),
              text: z.string(),
              color: z.string(),
            }),
          ),
          format: z.nativeEnum(NUMBER_PROPERTY_FORMAT_TYPE).nullable(),
          isShare: z.boolean(),
          isDueDate: z.boolean().optional(),
        })
        .superRefine(() => {
          // https://github.com/colinhacks/zod#superRefine
          // TODO: プロパティ種別によるカスタムバリデーションがある場合は以下に記載
        }),
    ),
    // 削除対象プロパティ 最低限のルールを記述 バリデーションでは使用されない
    deletedProperties: z.array(
      z.object({
        docId: z.string(),
        propertyType: z.string(),
        propertyName: z.string(),
        stringValue: z.string(),
        arrayValue: z.array(z.any()),
        numberValue: z.number().nullable(),
        booleanValue: z.boolean(),
        options: z.array(
          z.object({
            id: z.string(),
            text: z.string(),
            color: z.string(),
          }),
        ),
        format: z.nativeEnum(NUMBER_PROPERTY_FORMAT_TYPE).nullable(),
      }),
    ), // 削除するデータ
  });

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues,
  });

  const zodPropertySetting = useFieldArray({
    control: methods.control,
    name: 'properties',
  });
  // ↑↑↑
  // ===== アイテム作成・更新共通 zod設定 ======

  /**
   * dirtyのプロパティだけフィルター
   * @param ps
   * @returns
   */
  const filterDirtyProperties = useCallback(
    async (ps: IProperty[], dirtyFields) => {
      const dirtyProperties = [];
      // 消去されたプロパティがない時だけdirtyプロパティをフィルタ
      if (!dirtyFields.deletedProperties) {
        for (let i = 0; i < dirtyFields.properties.length; i += 1) {
          if (dirtyFields.properties[i]) {
            dirtyProperties.push(ps[i]);
          }
        }
        return dirtyProperties;
      }
      return ps;
    },
    [methods.formState.defaultValues],
  );

  return {
    // プロパティの整形と保存処理
    composeProperties,
    // ステータスの保存処理
    updateStatuses,
    // Zod関連
    methods,
    zodPropertySetting,
    defaultValues,
    reduceFuncPropertyValues,
    filterDirtyProperties,
    getUpdateStatuses,
    getUpdateAndComposePropertyValues,
    getEditOption,
  };
}
