import { FunctionsResult, IFbFuncInviteGuest } from '@/@types/common';
import {
  Auth,
  ILanguageType,
  InvitingGuest,
  Project,
  Workspace,
} from '@/@types/models';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';

import { REP_ERROR_MESSAGE } from '@/libs/const';
import { getInvitingGuestsPath } from '@/libs/docPathUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { functions } from '@/firebase';

import { firestore } from '@/firestore';

export interface IInvitingGuestRepository {
  findById: (
    workspaceId: Workspace['id'],
    projectId: Project['id'],
    invitingGuestId: InvitingGuest['id'],
  ) => Promise<InvitingGuest | null>;
  list: (
    workspaceId: Workspace['id'],
    projectId: Project['id'],
  ) => Promise<InvitingGuest[]>;
  inviteGuest: (
    workspaceId: Workspace['id'],
    projectIds: Array<Project['id']>,
    email: Auth['email'],
    language: ILanguageType,
  ) => Promise<FunctionsResult>;
}

const funcInviteGuest = httpsCallable<IFbFuncInviteGuest, FunctionsResult>(
  functions,
  'inviteGuest',
);

export default class InvitingGuestRepository
  implements IInvitingGuestRepository
{
  /**
   * ワークスペースID プロジェクトID ゲストIDを指定してレコード単体取得
   * @param workspaceId
   * @param projectId
   * @param invitingGuestId
   */
  async findById(
    workspaceId: Workspace['id'],
    projectId: Project['id'],
    invitingGuestId: InvitingGuest['id'],
  ) {
    try {
      const path = `${getInvitingGuestsPath(
        workspaceId,
        projectId,
      )}/${invitingGuestId}`;
      const docRef = doc(firestore, path).withConverter(
        fsConverter<InvitingGuest>(),
      );
      const d = await getDoc(docRef);
      const invitingGuest = d.data();
      if (invitingGuest === undefined) {
        return null;
      }
      invitingGuest.id = d.id;
      return invitingGuest;
    } catch (err) {
      repositoryError(this.findById.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * ワークスペースID プロジェクトIDでゲスト招待を絞り込んでリスト取得
   * @param workspaceId
   * @param projectId
   */
  async list(workspaceId: Workspace['id'], projectId: Project['id']) {
    try {
      const collectionRef = collection(
        firestore,
        getInvitingGuestsPath(workspaceId, projectId),
      ).withConverter(fsConverter<InvitingGuest>());
      return (await getDocs(collectionRef)).docs.map((d) => {
        const invitingGuest = d.data();
        invitingGuest.id = d.id;
        return invitingGuest;
      });
    } catch (err) {
      repositoryError(this.list.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * ゲスト招待
   * @param workspaceId
   * @param projectIds
   * @param email
   * @param language
   * @returns
   */
  async inviteGuest(
    workspaceId: Workspace['id'],
    projectIds: Array<Project['id']>,
    email: Auth['email'],
    language: ILanguageType,
  ) {
    const res = await funcInviteGuest({
      workspaceId,
      projectIds,
      email,
      language,
    });
    return res.data;
  }
}
