import { EV } from '@/@types/events';
import { RootState } from '@/@types/models';
import { Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { zodResolver } from '@hookform/resolvers/zod';
import { EmailAuthProvider } from 'firebase/auth';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import * as z from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import { classNames } from '@/libs/styleUtils';

import { t2s } from '@/libs/utils';

import { DISPLAY_NAME } from '@/libs/validations';

import AccountRepository from '@/repositories/AccountRepository';

import ModifyEmailDialog from '@/components/Accounts/ModifyEmailDialog';
import ModifyPasswordConfirmDialog from '@/components/Accounts/ModifyPasswordConfirmDialog';
import Alert from '@/components/Common/Alert';
import AvatarIcon from '@/components/Common/AvatarIcon';
import ConfirmDialog from '@/components/Common/ConfirmDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import Form from '@/components/Common/Forms/Form';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import { auth } from '@/firebase';
import useAccounts from '@/hooks/useAccounts';
import useAvatarFiles from '@/hooks/useAvatarFiles';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProfiles from '@/hooks/useProfiles';
import useSubmitState from '@/hooks/useSubmitState';

export default function Account() {
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const { deleteAccount, account, getMe } = useAccounts();

  const { updateDisplayName: updateProfileDisplayName, profile } =
    useProfiles();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const providerList = useMemo(() => rrfAuth.providerData, [rrfAuth]);
  const emailProviderData = useMemo(
    () =>
      providerList.find((o) => o.providerId === EmailAuthProvider.PROVIDER_ID),
    [providerList],
  );
  const { myWorkspaces } = useMyWorkspaces();

  const accountRepository = new AccountRepository();

  const { uploadAvatarFile } = useAvatarFiles();

  // Zod schema定義
  const schema = z.object({
    displayName: z.preprocess(
      (val) => String(val).trim(),
      z
        .string()
        .max(
          DISPLAY_NAME.max,
          t2s(t('<max>文字以内で入力してください', DISPLAY_NAME)),
        )
        .optional(),
    ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    // inputの初期値
    defaultValues: {
      displayName: '',
    },
  });

  const me = useMemo(() => getMe, [account]);

  // memberがsubscriptionされていない状態だとデフォルト表示できない対策
  useEffect(() => {
    if (!methods.formState.isDirty) {
      if (profile?.displayName)
        methods.setValue('displayName', profile.displayName);
    }
  }, [profile]);

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data: any) => {
    const { displayName } = data;
    // Update Profile MEMO: membersの更新はFunctionsで行う
    updateProfileDisplayName(displayName);
    // Reset form state
    methods.reset({ ...data });
  });

  // Workspace Name Zod schema定義
  const dlSchema = z.object({
    deleteAccountName: z.preprocess(
      (val) => String(val).trim(),
      z
        .string()
        .refine(
          (value: string) => value === (me?.displayName || me?.email),
          `"${me?.displayName || me?.email}"と入力してください`,
        ),
    ),
  });

  // Workspace Name Zod Form
  const dlMethods = useForm({
    resolver: zodResolver(dlSchema),
    mode: VALIDATION_MODE,
  });

  useEffect(() => {
    dlMethods.reset();
  }, [deleteDialogOpen, dlMethods]);

  // Handle delete user
  const [isProcessingDeleteAccount, setIsProcessingDeleteAccount] =
    useState<boolean>(false);
  const handleDeleteUserOnClose = async (feedback?: boolean) => {
    if (isProcessingDeleteAccount) return;
    if (feedback && auth.currentUser) {
      await deleteAccount(auth.currentUser);
    }
    setIsProcessingDeleteAccount(false);
    setDeleteDialogOpen(false);
  };

  // Handle modify email
  const handleModifyEmailOnClose = async () => {
    setEmailDialogOpen(false);
  };

  // Handle modify email
  const handleModifyPasswordOnClose = async (feedback?: boolean) => {
    if (feedback) {
      // TODO: パスワード変更ドキュメント追加処理
    }
    setPasswordDialogOpen(false);
  };

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  /**
   * ファイル読み込み時ハンドラ
   * @param e
   */
  const fileLoadHandler = (e: EV<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      uploadAvatarFile(Array.from(files));
      e.target.value = ''; // inputの値は不要なのでクリア
    }
  };

  /**
   * 添付ファイル選択
   * ※ fileInputRefのクリックイベント発火のみ
   *    ファイル読み込み時処理は`fileLoadHandler`に続く
   */
  const selectLocalFile = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const [contractWorkspaceIds, setContractWorkspaceIds] = useState<string[]>(
    [],
  );

  const [isDisabledDelete, setIsDisabledDelete] = useState<boolean>(true);

  useEffect(() => {
    accountRepository.checkAccountDeletable().then((res) => {
      setContractWorkspaceIds(res.workspaceIds);
      setIsDisabledDelete(res.workspaceIds.length > 0);
    });
  }, []);

  const contractWorkspaceNames = useMemo(
    () =>
      myWorkspaces
        .filter((w) => contractWorkspaceIds.includes(w.id as string))
        .map((w) => w.workspaceName),
    [contractWorkspaceIds],
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [contractWorkspaceNames]);

  return (
    <div className="p-4">
      <h1 className="flex items-center mb-6 text-xl font-semibold xs:text-l justify-content-center">
        {t('アカウント')}
      </h1>
      <Form
        onSubmit={() => {}}
        className="items-end justify-center w-full mb-7"
        flex
      >
        <FormCol>
          <div>
            <div className="flex items-center mt-1">
              <AvatarIcon
                size="2xl"
                src={profile?.photoURL}
                avatarName={me?.displayName || me?.email}
              />
              <div className="flex-1 ml-4">
                <div className="mb-4 font-bold break-all">{me?.email}</div>
                <input
                  type="file"
                  ref={fileRef}
                  className="hidden"
                  accept="image/*"
                  onChange={fileLoadHandler}
                />
                <MenuButton
                  id="changeAvatarIcon"
                  type="text"
                  className="mb-3 mr-3 text-xs sm:w-auto sm:mb-0"
                  onClick={selectLocalFile}
                >
                  {t('画像を変更')}
                </MenuButton>
                {emailProviderData && (
                  <>
                    {/* TODO:リリースまで機能制限のためコメント化 */}
                    {/* <MenuButton
                      type="text"
                      className="mb-3 mr-3 text-xs sm:w-auto sm:mb-0"
                      onClick={() => {
                        setEmailDialogOpen(true);
                      }}
                    >
                      {t('メールアドレスを変更')}
                    </MenuButton> */}
                    <MenuButton
                      id="requestChangePassword"
                      type="text"
                      className="text-xs sm:w-auto"
                      onClick={() => {
                        setPasswordDialogOpen(true);
                      }}
                    >
                      {t('パスワードを変更')}
                    </MenuButton>
                  </>
                )}
              </div>
            </div>
          </div>
        </FormCol>
      </Form>
      <FormProvider {...methods}>
        <Form
          onSubmit={formSubmit}
          className="items-end w-full max-w-md pb-4"
          flex
        >
          <FormCol className="flex-1">
            <Input name="displayName" label={t('表示名')} />
          </FormCol>
          <div className="self-end">
            <FormButton
              id="applyAccountDisplayName"
              disabled={isDisabledApply}
              submit
              variant="primary"
              className="sm:w-auto"
            >
              {t('適用')}
            </FormButton>
          </div>
        </Form>
      </FormProvider>
      <div className="relative">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-100 dark:border-gray-700" />
        </div>
      </div>
      <Alert color="gray" className="mt-4" icon={ExclamationTriangleIcon}>
        <h3 className="font-medium">
          {t('以下の操作には十分注意してください！')}
        </h3>
      </Alert>
      <div className="mt-5 sm:mt-4 sm:flex">
        {false && <>{t('<workspaceName> 他<num>件')}</>}
        <MenuButton
          id="deleteAccount"
          type="text"
          variant="warning"
          className="sm:w-auto sm:mr-3"
          onClick={() => {
            setDeleteDialogOpen(true);
          }}
          toolTip={
            isDisabledDelete && contractWorkspaceNames.length > 0
              ? t2s(
                  t(
                    '<workspaceNames>のワークスペースの契約者のため削除できません。該当するワークスペースの有償プランを解約した後に再度操作してください。',
                    {
                      workspaceNames:
                        contractWorkspaceNames.length > 1
                          ? t2s(
                              t('<workspaceName> 他<num>件', {
                                workspaceName: contractWorkspaceNames[0],
                                num: contractWorkspaceNames.length - 1,
                              }),
                            )
                          : contractWorkspaceNames[0],
                    },
                  ),
                )
              : ''
          }
          disabled={isDisabledDelete}
        >
          {t('アカウントを削除')}
        </MenuButton>
      </div>
      <Transition.Root show={emailDialogOpen} unmount>
        <ModifyEmailDialog onClose={handleModifyEmailOnClose} />
      </Transition.Root>
      <Transition.Root show={passwordDialogOpen} unmount>
        <ModifyPasswordConfirmDialog
          onClose={handleModifyPasswordOnClose}
          toEmail={me?.email}
        />
      </Transition.Root>
      <ConfirmDialog
        key="user-delete-confirm"
        open={deleteDialogOpen}
        onClose={handleDeleteUserOnClose}
        title={t('アカウントを削除')}
        positive={t('削除')}
        disabled={!dlMethods.formState.isValid}
        warning
      >
        <p className="mb-2">
          {t('以下のアカウントを削除しようとしています。')}
        </p>
        <div className="flex-shrink-0 block w-full my-4">
          <div className="flex items-center p-4 border border-gray-200 dark:border-gray-700 rounded-md">
            <div>
              <AvatarIcon
                src={profile?.photoURL}
                avatarName={me?.displayName || me?.email}
                size="md"
              />
            </div>
            <div className="ml-3 w-80">
              {me?.displayName && (
                <p className="font-medium text-left text-gray-500 dark:text-gray-300 group-hover:text-white">
                  {me.displayName}
                </p>
              )}
              <p
                className={classNames(
                  me?.displayName
                    ? 'text-gray-500 dark:text-gray-400 text-sm'
                    : 'text-gray-400 dark:text-gray-300',
                  'font-medium text-left truncate group-hover:text-white',
                )}
              >
                {me?.email}
              </p>
            </div>
          </div>
        </div>

        <p className="mb-2">
          {t('削除するには以下に「<accountName>」と入力してください。', {
            accountName: (
              <span className="mr-1 font-bold">
                {me?.displayName || me?.email}
              </span>
            ),
          })}
        </p>
        <FormProvider {...dlMethods}>
          <FormCol className="my-4">
            <Input
              name="deleteAccountName"
              placeholder={me?.displayName || me?.email || ''}
              disableAutoComplete
            />
          </FormCol>
        </FormProvider>

        <p>
          {t(
            '削除されたアカウントは復元できません。<br>また、他にメンバーのいないワークスペースは削除され、それ以外のワークスペースからは退室します。<br> 本当によろしいですか？',
            { br: <br /> },
          )}
        </p>
      </ConfirmDialog>
    </div>
  );
}
