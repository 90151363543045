import { BasicSearchFilter, SearchProperty } from '@/@types/common';
import { Dialog, Transition } from '@headlessui/react';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  FunnelIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import lodash, { isEqual } from 'lodash';
import React, {
  Fragment,
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';

import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MAX_FILTER_DETAIL_LENGTH } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import ItemRepository from '@/repositories/ItemRepository';

import { Badge } from '@/components/Common/Badge';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import FilterBasicList from '@/components/Filter/FIlterBasicList';

import FilterDetailList from '@/components/Filter/FilterDetailList';

import useFilters, { ISearchProperty } from '@/hooks/useFilters';

import useHandleApi from '@/hooks/useHandleApi';
import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useOrderedProperties from '@/hooks/useOrderedProperties';
import useProjects from '@/hooks/useProjects';
import useProperties from '@/hooks/useProperties';
import usePropertyOrders from '@/hooks/usePropertyOrders';

export default function FilterDrawer() {
  const { t } = useTranslation();
  const { methods, defaultValues, composeProperties, formatQueryProperties } =
    useFilters();
  const [open, setOpen] = useState(false);
  const [isBasicInfoOmit, setIsBasicInfoOmit] = useState(false);
  const [isDetailOmit, setIsDetailOmit] = useState(true);
  const { currentMyProject } = useProjects();
  const { currentMyWorkspace, isMember } = useMyWorkspaces();
  const { exec } = useHandleApi();
  const { properties } = useProperties();
  const { propertyOrder } = usePropertyOrders();
  const orderedProperties = useOrderedProperties(properties, propertyOrder);
  const { currentSearchState, setCurrentSearchState } = useItems();
  const handleClickBasicInfoButton = () => {
    setIsBasicInfoOmit((val) => !val);
  };
  const [filterProperties, setFilterProperties] = useState<ISearchProperty[]>(
    [],
  );
  // consoleエラー回避用のhidden要素
  const filterDrawerRef = useRef(null);

  const handleClickDetailView = () => {
    setIsDetailOmit((val) => !val);
  };

  const targetProperties = useMemo(
    () =>
      isMember
        ? orderedProperties
        : orderedProperties.filter((v) => v.isShare === true),
    [isMember, orderedProperties],
  );

  const reset = useCallback(() => {
    const formatProperties = composeProperties(targetProperties);
    setFilterProperties(formatProperties);
    methods.reset({ ...defaultValues, searchProperties: formatProperties });
    setCurrentSearchState(null, null, []);
  }, [methods, targetProperties]);

  // プロジェクト切り替え時、プロパティ変更時に初期化
  useEffect(() => {
    if (currentSearchState.filter === null) {
      reset();
    } else {
      const formatProperties = composeProperties(targetProperties);
      const mergeSearchProperty = lodash.merge(
        formatProperties,
        currentSearchState.filter?.searchProperties,
      );
      setFilterProperties(mergeSearchProperty);
      const filter = {
        basicFilter: {
          ...defaultValues.basicFilter,
          ...currentSearchState.filter?.basicFilter,
        },
        searchProperties: mergeSearchProperty,
      };
      methods.reset({ ...filter }, { keepDirty: true, keepDirtyValues: true });
    }
  }, [
    targetProperties,
    currentSearchState.workspaceId,
    currentSearchState.projectId,
  ]);

  // 検索上限
  const isLimitSearch = useMemo(() => {
    if (!methods?.formState?.dirtyFields?.searchProperties) return false;
    const dirtyProperty = methods.formState.dirtyFields.searchProperties.filter(
      (v) => v,
    );
    if (dirtyProperty.length > MAX_FILTER_DETAIL_LENGTH) {
      return true;
    }
    return false;
  }, [methods.formState, methods.formState.dirtyFields?.searchProperties]);

  const wBasicFilter = methods.watch('basicFilter');
  const wSearchProperties = methods.watch('searchProperties');

  // 空送信を制御
  const isEmpty = useMemo(() => {
    const formatProperties = composeProperties(targetProperties);
    const res =
      isEqual(wBasicFilter, defaultValues.basicFilter) &&
      isEqual(wSearchProperties, formatProperties);
    return res;
  }, [
    wBasicFilter,
    wSearchProperties,
    targetProperties,
    defaultValues.basicFilter,
  ]);

  // 送信可否判定
  const canSubmit = useMemo(
    () => methods.formState.isValid && !isLimitSearch,
    [methods.formState.isValid, isLimitSearch],
  );

  const filterDirtyProperties = useCallback(
    (ps: ISearchProperty[], dirtyFields) => {
      const dirtyProperties: ISearchProperty[] = [];
      for (let i = 0; i < dirtyFields.searchProperties.length; i += 1) {
        if (dirtyFields.searchProperties[i]) {
          dirtyProperties.push(ps[i]);
        }
      }
      return dirtyProperties;
    },
    [],
  );

  const formSubmit = methods.handleSubmit(async (data) => {
    // 入力値が空の場合はリセット
    if (isEmpty) {
      reset();
      return;
    }
    await exec(async () => {
      const mapBasicData = new Map(Object.entries(data.basicFilter));

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of mapBasicData) {
        if (value === null || value.length === 0) {
          mapBasicData.delete(key);
        }
      }
      const basicFilter: BasicSearchFilter = Object.fromEntries(mapBasicData);
      let searchProperties: SearchProperty[] = [];
      if (
        methods.formState.dirtyFields &&
        methods.formState.dirtyFields.searchProperties
      ) {
        const dirtyProperties = filterDirtyProperties(
          data.searchProperties,
          methods.formState.dirtyFields,
        );
        searchProperties = formatQueryProperties(
          dirtyProperties,
        ) as SearchProperty[];
      }
      let itemIds: string[] = [];
      const filter = { basicFilter, searchProperties };
      const query = {
        workspaceId: currentMyWorkspace?.id,
        projectId: currentMyProject?.id,
        keyword: currentSearchState.keyword,
        ...filter,
      };

      const itemRepository = new ItemRepository(
        currentMyWorkspace?.id as string,
        currentMyProject?.id as string,
      );
      const res = await itemRepository.searchItems({
        ...query,
      });

      itemIds = res.itemIds;
      setCurrentSearchState(currentSearchState.keyword, { ...filter }, itemIds);
    }, t('検索に失敗しました'));
  });

  return (
    <>
      <div className={classNames('flex w-full')}>
        <MenuButton
          id="filterButton"
          type="icon"
          variant="warning"
          onClick={() => setOpen(true)}
        >
          <FunnelIcon className="w-4 h-4" aria-hidden="true" />
        </MenuButton>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          initialFocus={filterDrawerRef}
          as="div"
          className="fixed inset-0 z-40 h-screen overflow-hidden"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 flex max-w-full">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-400"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-400"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md md:max-w-md lg:max-w-lg xl:max-w-xl">
                  <div className="flex flex-col h-full pt-6 border-l border-gray-200 shadow-xl dark:border-gray-800 bg-gray-50 dark:bg-gray-700">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="flex font-medium text-gray-800 text-md dark:text-gray-300">
                          <div className="flex-1 mr-4">
                            {t('アイテムの絞り込み')}
                          </div>
                        </Dialog.Title>
                        <div className="flex items-center ml-3 h-7">
                          <MenuButton
                            id="closeFilter"
                            type="icon"
                            tabIndex={-1}
                            onClick={() => setOpen(false)}
                          >
                            <XMarkIcon className="w-4 h-4" />
                          </MenuButton>
                        </div>
                      </div>
                    </div>
                    <div className="sticky flex-1 h-screen pt-1 pb-0 mt-2 overflow-y-auto">
                      <div className="sticky flex-row min-h-full pb-2 pr-5 pl-7">
                        <div className="flex  items-center pb-2">
                          <div className="text-gray-500 dark:text-gray-400 col-start-1 col-span-3">
                            <small>{t('基本情報')}</small>
                          </div>
                          <div className="ml-2">
                            <MenuButton
                              id="basicInfoOmit"
                              type="icon"
                              variant="warning"
                              onClick={handleClickBasicInfoButton}
                            >
                              {isBasicInfoOmit ? (
                                <ChevronDoubleDownIcon
                                  className="w-3 h-3"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ChevronDoubleUpIcon
                                  className="w-3 h-3"
                                  aria-hidden="true"
                                />
                              )}
                            </MenuButton>
                          </div>
                        </div>
                        <FormProvider {...methods}>
                          {!isBasicInfoOmit && (
                            <FilterBasicList methods={methods} />
                          )}
                          <div className="flex items-center pt-3">
                            <div className="text-gray-500 dark:text-gray-400 col-start-1 col-span-3">
                              <small>{t('詳細な絞り込み')}</small>
                            </div>
                            <div className="ml-2">
                              <MenuButton
                                id="detailOmit"
                                type="icon"
                                variant="warning"
                                onClick={handleClickDetailView}
                              >
                                {isDetailOmit ? (
                                  <ChevronDoubleDownIcon
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ChevronDoubleUpIcon
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                  />
                                )}
                              </MenuButton>
                            </div>
                          </div>
                          {!isDetailOmit && (
                            <FilterDetailList
                              filterProperties={filterProperties}
                            />
                          )}
                        </FormProvider>
                      </div>
                      <div className="sticky  bottom-0 z-10 max-w-3xl px-4 py-3 dark:border-t dark:border-gray-500 sm:py-4 bg-gray-100/80 dark:bg-gray-700/80 backdrop-blur-sm">
                        <FormProvider {...methods}>
                          <div className="flex flex-col">
                            {isLimitSearch && (
                              <Badge
                                className="mb-2"
                                color="red"
                                size="xs"
                                content={t(
                                  '同時に設定できる「詳細な絞り込み」の条件数は5件までです',
                                )}
                              />
                            )}

                            <div className="items-end mt-1 sm:flex sm:flex-row-reverse">
                              <FormButton
                                id="submitFilter"
                                submit
                                variant="primary"
                                className="sm:w-auto sm:ml-3"
                                disabled={!canSubmit}
                                onClick={formSubmit}
                              >
                                {t('絞り込み')}
                              </FormButton>
                              <FormButton
                                id="clearFilter"
                                className="mt-4 md-mt-0 sm:w-auto sm:ml-3"
                                onClick={reset}
                              >
                                {t('クリア')}
                              </FormButton>
                              <FormButton
                                id="cancelFilter"
                                className="mt-4 md-mt-0 sm:w-auto sm:ml-3"
                                onClick={() => setOpen(false)}
                              >
                                {t('キャンセル')}
                              </FormButton>
                            </div>
                          </div>
                          <div ref={filterDrawerRef} hidden />
                        </FormProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
