import { MinusCircleIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import LinkButton from '@/components/Common/Forms/Buttons/LinkButton';
import Notice from '@/components/Common/Notice';

import usePlanProtections from '@/hooks/usePlanProtections';

/**
 * メンバー超過バナー
 * @returns
 */
export default function MemberOverflowBanner() {
  const { t } = useTranslation();
  const { workspaceId: urlWorkspaceId } = useParams();
  const { isOverflowMaxMember } = usePlanProtections();

  return isOverflowMaxMember === true ? (
    <Notice
      color="red"
      className="w-full p-0 bg-red-400 rounded-none dark:bg-red-600/70"
    >
      <div className="flex items-center justify-between w-full flex-nowrap">
        <div className="p-4 pr-2 text-white dark:text-gray-300">
          {t(
            'メンバー登録数がプラン購入数を超過しています。超過状態が解消されない場合、<icon>の付いているメンバーは翌0時(JST)に自動的に削除されます。',
            {
              icon: (
                <MinusCircleIcon className="inline-block w-4 h-4 mx-1 align-text-bottom" />
              ),
            },
          )}
        </div>
        <div className="px-2 text-xs text-white dark:text-gray-300 whitespace-nowrap">
          <LinkButton
            id="increaseMemberLimits"
            to={`/${urlWorkspaceId}/settings/plans`}
            primary
          >
            <UserPlusIcon
              className="w-4 h-4 text-white sm:mr-1 dark:text-white"
              aria-hidden="true"
            />
            <div className="hidden sm:block">{t('メンバーの上限を増やす')}</div>
          </LinkButton>
        </div>
      </div>
    </Notice>
  ) : null;
}
