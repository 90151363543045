import { originalColors, OriginalColorType } from '@/@types/common';
import { IPropertyOptionType, RootState } from '@/@types/models';

import {
  Bars2Icon,
  PlusIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import RelativePortal from 'react-relative-portal';

import { getStatusesPath } from '@/libs/docPathUtils';
import { classNames } from '@/libs/styleUtils';

import Alert from '@/components/Common/Alert';
import ConfirmDialog from '@/components/Common/ConfirmDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import PropertyOptionInput from '@/components/Items/PropertyOptionInput';

import { generateDocId } from '@/firestore';

import useStatuses from '@/hooks/useStatuses';

export interface IPropertyDefaultValues {
  propertyTypeOptions: IPropertyOptionType[];
}

export interface ISingleSelectFormProps {
  className?: string;
}

/**
 * ステータス型プロパティ編集フォーム
 * ※ シングルセレクトと制御は近いが要所要所で差があるので今後の仕様変更にも耐えられるよう別コンポーネントとしている
 * @param props
 * @returns
 */
export default function SingleSelectForm(props: ISingleSelectFormProps) {
  const { t } = useTranslation();
  const { className } = props;
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const { statuses } = useStatuses();
  const [optionId, setOptionId] = useState<number>(0); // 自動選択色解決用の連番
  const [deleteTarget, setDeleteTarget] = useState<{
    idx: number;
    target: IPropertyOptionType;
  } | null>(null);
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] =
    useState<boolean>(false);

  // 親コンポーネントのフォームコンテキスト
  const { control, trigger, getValues } =
    useFormContext<IPropertyDefaultValues>();

  // プロパティ選択肢フィールド
  const { fields, append, remove, update, move } = useFieldArray({
    control,
    name: 'propertyTypeOptions',
    keyName: 'key',
  });

  // カレントワークスペース&プロジェクトID
  // (サブスクリプションの実行は必要ないのでReduxストア値のみを参照)
  const currentWorkspaceId = useSelector(
    (state: RootState) => state.currentWorkspaceId,
  );
  const currentProjectId = useSelector(
    (state: RootState) => state.projectState?.currentProjectId,
  );

  // 削除対象ステータス情報
  const deleteStatusInfo = useMemo(() => {
    if (!deleteTarget || !statuses) return null;
    return statuses.find((st) => st.id === deleteTarget.target.id);
  }, [statuses, deleteTarget]);

  // 削除対象ステータスにより削除確認アラートの表示を分岐
  const deleteConfirmAlert = useMemo(() => {
    if (!deleteStatusInfo) return null;
    if (
      (deleteStatusInfo.shareItemCount &&
        deleteStatusInfo.shareItemCount > 0) ||
      (deleteStatusInfo.nonShareItemCount &&
        deleteStatusInfo.nonShareItemCount > 0)
    ) {
      return (
        <Alert color="gray" icon={ExclamationTriangleIcon} className="mt-4">
          {t('指定ステータスのアイテムも全て削除されます。')}
        </Alert>
      );
    }
    return null;
  }, [deleteStatusInfo]);

  // バッジ表示時の色を解決する
  const resolveColor = (): OriginalColorType => {
    const colorKey = optionId % originalColors.length;
    return originalColors[colorKey];
  };

  // ステータス削除確認
  const onRemoveOption = (idx: number) => {
    const values = getValues('propertyTypeOptions');
    const target = values[idx];
    setDeleteTarget({ idx, target });
    setDeleteConfirmDialogOpen(true);
  };

  // ステータス削除
  const handleDelete = useCallback(
    (acceptFlg: boolean | undefined) => {
      if (acceptFlg && deleteTarget) {
        remove(deleteTarget.idx);
      }
      setDeleteTarget(null);
      setDeleteConfirmDialogOpen(false);
    },
    [deleteTarget],
  );

  // ステータス追加
  const onAppendOption = (silent = false) => {
    if (!currentWorkspaceId || !currentProjectId) return;
    append({
      id: generateDocId(getStatusesPath(currentWorkspaceId, currentProjectId)),
      text: '',
      color: resolveColor(),
    });
    if (!silent) trigger(); // バリデーション発火
    setTimeout(() => {
      scrollBottomRef!.current!.scrollIntoView({ behavior: 'smooth' });
    });
    setOptionId(optionId + 1);
  };

  // オプションカラー更新
  const onUpdateOption = (idx: number, value: OriginalColorType) => {
    const values = getValues();
    if (
      fields[idx] === undefined ||
      values.propertyTypeOptions[idx] === undefined
    )
      return;
    const target = fields[idx];
    const { text } = values.propertyTypeOptions[idx];
    // ※ text値が""のままなのでgetValues()で取得した値で上書き
    if (target) {
      const newOption = {
        ...target,
        text,
        color: value,
      };
      update(idx, newOption);
    }
  };

  // ドラッグ終了時ハンドラ
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // リストの外にD&Dした場合は何もしない
    if (!destination) return null;
    const sIndex = source.index;
    const dIndex = destination.index;

    // 別の列にD&Dした場合は移動
    if (sIndex === dIndex) return null;
    return move(sIndex, dIndex);
  };

  // マウント時処理
  useEffect(() => {
    const options = getValues('propertyTypeOptions');
    if (options.length === 0) {
      onAppendOption(false);
    }
  }, []);

  return (
    <>
      <div
        className={classNames(
          className,
          'overflow-y-scroll border border-t border-gray-200 flow-root rounded-md dark:border-gray-700 max-h-96',
        )}
      >
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
          <li className="block px-4 py-3 font-medium text-gray-700 dark:text-gray-400">
            {t('選択肢')}
          </li>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="status-setting">
              {(providedDroppable) => (
                <div
                  ref={providedDroppable.innerRef}
                  {...providedDroppable.droppableProps}
                  className="flex flex-wrap py-3 space-y-3"
                >
                  {fields.map((option, idx) => (
                    <li key={option.id} className="w-full">
                      <Draggable
                        isDragDisabled={false}
                        draggableId={option.id as string}
                        index={idx}
                      >
                        {(providedDraggable, snapshot) => {
                          const elem = (
                            <div
                              className="flex pl-3 pr-2 space-x-3"
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              <div className="flex-shrink-0 m-auto hover:dark:text-gray-600 dark:text-gray-500">
                                <Bars2Icon className="w-4 h-4 cursor-grab" />
                              </div>
                              <div className="flex-grow">
                                <PropertyOptionInput
                                  option={option}
                                  idx={idx}
                                  name="propertyTypeOptions"
                                  property="text"
                                  onDelete={() => onRemoveOption(idx)}
                                  onChangeColor={onUpdateOption}
                                />
                              </div>
                            </div>
                          );
                          return snapshot.isDragging ? (
                            <RelativePortal component="div">
                              {elem}
                            </RelativePortal>
                          ) : (
                            elem
                          );
                        }}
                      </Draggable>
                    </li>
                  ))}
                  {providedDroppable.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <li
            key="property-add-btn"
            className="sticky bottom-0 px-0 py-0 text-center"
          >
            <FormButton
              id="addSelectItem"
              className="flex items-center justify-center border-0 rounded-none outline-none py-3.5 ring-0 focus:ring-0 focus:!ring-offset-0"
              onClick={() => onAppendOption(false)}
            >
              <PlusIcon
                className="inline-block w-4 h-4 my-auto"
                aria-hidden="true"
              />
              <div className="inline-block ml-2 align-text-top">
                {t('選択肢を追加')}
              </div>
            </FormButton>
          </li>
        </ul>
        <div ref={scrollBottomRef} />
      </div>
      {/* ステータス削除確認ダイアログ */}
      <ConfirmDialog
        key="item-discard-confirm"
        open={deleteConfirmDialogOpen}
        onClose={handleDelete}
        title={t('ステータスを削除')}
        positive={t('削除')}
        warning
      >
        <p className="mb-2">
          {t('<title> のステータスを削除しようとしています。', {
            title: (
              <span className="mr-1 font-bold break-all">
                {deleteTarget?.target.text}
              </span>
            ),
          })}
        </p>
        <p>
          {t(
            'ステータスはアイテムの更新時に削除されます。<br> 本当によろしいですか？',
            { br: <br /> },
          )}
        </p>
        {deleteConfirmAlert}
      </ConfirmDialog>
    </>
  );
}
