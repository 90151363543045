import { ILoginMode } from '@/@types/common';
import { RootState } from '@/@types/models';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { DefaultTFuncReturn } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import {
  FUNCTIONS_ERROR_DETAILS,
  LOGIN_MODE,
  RE_CAPTCHA_ACTIONS,
  VALIDATION_MODE,
} from '@/libs/const';
import { t2s } from '@/libs/utils';

import { INVITE_EMAIL } from '@/libs/validations';

import AccountRepository from '@/repositories/AccountRepository';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import Form from '@/components/Common/Forms/Form';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';
import HeaderLogo from '@/components/Common/HeaderLogo';
import Loading from '@/components/Common/Loading';

import GoogleLogin from '@/components/Login/GoogleLogin';
import LoginModeContext from '@/components/Login/LoginModeContext';
import SignUpForm from '@/components/Login/SignUpForm';

import InvitingGuest from '@/pages/Login/InvitingGuest';
import InvitingMember from '@/pages/Login/InvitingMember';

import useInviteInfo from '@/hooks/useInviteInfo';
import useReCaptcha from '@/hooks/useReCaptcha';
import useSubmitState from '@/hooks/useSubmitState';

type IScreenState = 'checkEmail' | 'login' | 'signUp';

interface ILoginProps {
  mode?: ILoginMode;
}

/**
 * 使い方リンクブロック
 * @returns
 */
export function HowToUse() {
  const { t } = useTranslation();
  return (
    <div className="px-2 mt-6 text-center">
      {t('collabbleの詳しい使い方やお知らせは<userLink>をご覧ください', {
        userLink: (
          <a
            href="https://lp.collabble.jp/user"
            className="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('こちら')}
          </a>
        ),
      })}
    </div>
  );
}

/**
 * サインアップ
 * @param props
 * @returns
 */
export default function SignUp(props: ILoginProps) {
  const { mode } = props;
  const { t } = useTranslation();

  const { isLoaded } = useSelector((state: RootState) => state.uiState);
  const [screenState, setScreenState] = useState<IScreenState>('checkEmail');
  const [error, setError] = useState<string | DefaultTFuncReturn | null>(null);
  const { refreshToken, reCaptchaToken } = useReCaptcha(
    RE_CAPTCHA_ACTIONS.EMAIL_EXISTENCE_CHECK,
  );
  const { isInviteDataLoaded, invitingWorkspaceName, loginUrl } =
    useInviteInfo(mode);

  const accountRepository = new AccountRepository();

  // Zod schema定義
  const schema = z.object({
    email: z
      .string()
      .min(1, { message: t('入力してください') })
      .email(t2s(t('正しいメールアドレスを入力してください')))
      .max(
        INVITE_EMAIL.max,
        t2s(t('<max>文字以内で入力してください', INVITE_EMAIL)),
      ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    setError(null);
  }, [methods.formState.isValid]);

  // Submitボタン状態
  const isDisabledCheckEmail = useSubmitState(methods) || !reCaptchaToken;

  // Form Submit
  const checkEmailFormSubmit = methods.handleSubmit(async (data) => {
    setError(null);
    try {
      const res = await accountRepository.checkEmailExistence(
        data.email,
        reCaptchaToken || '',
      );
      if (res.data) {
        if (res.data?.providerId) {
          setError(
            t(
              '入力したメールアドレスは登録済みです。<br>再度確認いただくか、<loginLink>よりお試しください',
              {
                br: <br />,
                loginLink: (
                  <Link to={loginUrl} className="ml-2 link">
                    {t('ログイン画面')}
                  </Link>
                ),
              },
            ),
          );
        } else {
          refreshToken();
          setError(
            t(
              '異なる認証方法で登録済みのメールアドレスです。別の認証方法でログインしてください。',
            ),
          );
        }
      } else {
        setScreenState('signUp');
      }
    } catch (e: any) {
      if (e.details === FUNCTIONS_ERROR_DETAILS.RECAPTCHA_ERROR) {
        toast.error(
          t(
            '一定時間経過などを理由にBOTとして判断されたためログインできませんでした。ページを再読み込みの上、再度ログインまたはアカウント登録をお試しください。',
          ),
        );
        return;
      }
      toast.error(t('メールアドレスの確認に失敗しました'));
      throw e;
    }
    methods.reset({ ...data });
  });

  useEffect(() => {
    if (screenState === 'checkEmail') {
      methods.reset({ email: '' });
      refreshToken();
    }
  }, [screenState]);

  const backToCHeckEmail = useCallback(() => {
    setScreenState('checkEmail');
  }, []);

  // Loading表示
  if (!isLoaded) return <Loading className="w-full h-screen" />;

  if (mode && !isInviteDataLoaded)
    return <Loading className="w-full h-screen" />;

  return (
    <LoginModeContext mode={mode}>
      <div className="flex flex-col h-full min-h-full px-6 py-12 overflow-auto lg:px-8">
        <HeaderLogo />
        {screenState === 'checkEmail' && (
          <div>
            {mode ? (
              <div className="mt-6 text-xl text-center">
                {t(
                  '共感・共創を生み出すコラボレーションツール<br>「collabble」に招待されました',
                  { br: <br /> },
                )}
              </div>
            ) : (
              <>
                <div className="mt-6 text-xl text-center">
                  {t(
                    '共感・共創を生み出すコラボレーションツール<br>「collabble」にアカウント登録しましょう',
                    { br: <br /> },
                  )}
                </div>
                <HowToUse />
              </>
            )}
          </div>
        )}
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="px-4 py-8 border shadow sm:rounded-lg sm:px-10 dark:border-gray-700">
            {/** メンバー招待時の表示 */}
            {mode === LOGIN_MODE.INVITING_MEMBER && (
              <InvitingMember workspaceName={invitingWorkspaceName as string} />
            )}
            {/** ゲスト招待時の表示 */}
            {mode === LOGIN_MODE.INVITING_GUEST && (
              <InvitingGuest workspaceName={invitingWorkspaceName as string} />
            )}
            {/** 共通の表示 */}
            {screenState === 'checkEmail' && (
              <>
                <FormProvider {...methods}>
                  <Form onSubmit={checkEmailFormSubmit}>
                    <FormCol>
                      <Input
                        name="email"
                        label={t('メールアドレス')}
                        error={error}
                      />
                    </FormCol>
                    <FormCol>
                      <FormButton
                        id="signupEmail"
                        className="sm:w-full"
                        submit
                        variant="primary"
                        disabled={isDisabledCheckEmail}
                      >
                        <EnvelopeIcon className="w-4 h-4 mr-1" />
                        {t('メールで登録')}
                      </FormButton>
                    </FormCol>
                  </Form>
                </FormProvider>
                <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300 dark:border-gray-700" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-white dark:bg-gray-800">
                        {t('またはお持ちのアカウントで')}
                      </span>
                    </div>
                  </div>

                  <div className="mt-6 ">
                    <GoogleLogin label={t('Googleで登録')} />
                    {/*
                    <FormButton
                      submit={false}
                      className="cursor-not-allowed"
                      disabled
                    >
                      <span className="sr-only">Sign in with Facebook</span>
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </FormButton>

                    <FormButton submit={false} disabled>
                      <span className="sr-only">Sign in with Twitter</span>
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </FormButton>

                    <FormButton
                      submit={false}
                      className="cursor-not-allowed"
                      disabled
                    >
                      <span className="sr-only">Sign in with GitHub</span>
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </FormButton> */}
                  </div>
                </div>
              </>
            )}
            {screenState === 'signUp' && (
              <SignUpForm
                email={methods.getValues().email}
                onBack={backToCHeckEmail}
              />
            )}
          </div>
          <div className="px-2 mt-6 text-center">
            {t('すでにアカウントをお持ちですか？<loginLink>', {
              loginLink: (
                <Link to={loginUrl} className="ml-2 link">
                  {t('ログイン')}
                </Link>
              ),
            })}
          </div>
          <div className="px-2 mt-6">
            {false && ( // i18nの自動生成のために必要
              <span>
                {t('プライバシーポリシー')}
                {t('collabble利用規約')}
              </span>
            )}
            {t(
              '登録を完了（Googleアカウントでの登録を含む）することにより、当社の<privacyPolicy>及び<termsOfUse>を確認し、これらに同意するものといたします。',
              {
                privacyPolicy: (
                  <a
                    href="https://pottos.jp/privacy_policy.html"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {t('プライバシーポリシー')}
                  </a>
                ),
                termsOfUse: (
                  <a
                    href="https://lp.collabble.jp/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link"
                  >
                    {t('collabble利用規約')}
                  </a>
                ),
              },
            )}
          </div>
        </div>
        {/** i18next-extract用静的記述 */}
        {false && (
          <>
            <div>{t('こちら')}</div>
            <div>{t('ログイン')}</div>
            <div>{t('ログイン画面')}</div>
          </>
        )}
      </div>
    </LoginModeContext>
  );
}
