import { MyProject } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import { t2s } from '@/libs/utils';

import { PROJECT_NAME } from '@/libs/validations';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import useProjects from '@/hooks/useProjects';
import useSubmitState from '@/hooks/useSubmitState';

interface IEditProjectNameDialogProps extends IModalDialogProps {
  myProject: MyProject;
}

export default function EditProjectNameDialog(
  props: IEditProjectNameDialogProps,
) {
  const { myProject, onClose } = props;
  const { t } = useTranslation();
  const { updateProjectName } = useProjects();

  // Zod schema定義
  const schema = z.object({
    projectName: z
      .string()
      .min(1, { message: t('入力してください') })
      .max(
        PROJECT_NAME.max,
        t2s(t('<max>文字以内で入力してください', PROJECT_NAME)),
      ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  // Form Submit
  const formSubmit = methods.handleSubmit((data) => {
    const { projectName } = data;
    updateProjectName(myProject.id, myProject.projectId, projectName);
    // Reset form state
    methods.reset({ ...data });
    onClose();
  });

  // mount時処理
  useEffect(() => {
    methods.reset({ projectName: myProject.projectName });
  }, []);

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <FormDialog
      onClose={onClose}
      title={t('プロジェクト名を変更')}
      onSubmit={formSubmit}
    >
      <FormProvider {...methods}>
        <FormCol>
          <Input name="projectName" label={t('プロジェクト名')} />
        </FormCol>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="changeProjectName"
          disabled={isDisabledApply}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('更新')}
        </FormButton>
        <FormButton
          id="cancelChangeProjectName"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
