import { RootState, IJoinedAccount, IJoinedGuest } from '@/@types/models';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { classNames } from '@/libs/styleUtils';

import AvatarIcon from '@/components/Common/AvatarIcon';
import { Badge } from '@/components/Common/Badge';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import PredictionInput from '@/components/Common/Forms/PredictionInput';

import useGuests from '@/hooks/useGuests';

import useInvitingGuests from '@/hooks/useInvitingGuests';

import useXGuests from '@/hooks/useXGuests';

export type AccountListChangeEvent =
  | 'invite'
  | 'uninvite'
  | 'remove'
  | 'unremove';

export interface InviteDestination {
  id: number;
  email: string | null;
}

export interface IAccountListProps {
  accounts: IJoinedAccount[] | IJoinedGuest[];
  className?: string;
  onChange?: (event?: AccountListChangeEvent) => void;
}

export interface IInviteEmail {
  id: string;
  value: string;
  invitedAt: Date;
}

/**
 * ゲストアカウントリスト（共有メンバーリスト兼）
 *     scheme: 削除対象IDs: removeIds: string[], 招待Eメール配列: inviteEmails {id:string, value:string}
 * @param props
 * @returns
 */
export default function GuestAccountList(props: IAccountListProps) {
  const { t } = useTranslation();
  const { accounts, className, onChange } = props;
  const { control, setValue, getValues, trigger } = useFormContext();
  const { myProjectsXGuestsDic } = useXGuests();
  const { myProjectsGuestsDic } = useGuests();
  const { myProjectsInvitingGuestsDic } = useInvitingGuests();
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const [joinedGuests, setJoinedGuests] = useState<IJoinedGuest[]>([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'inviteEmails',
  });

  // 横断検索：招待中ゲストの重複チェック
  const uniqueEmailList = Array.from(
    new Set(joinedGuests.map((a) => a.email)),
  ).map((email) => joinedGuests.find((a) => a.email === email));

  // 横断検索：プロジェクトリスト内のゲストを選択オプションへ非表示
  const accountsToFilter = useMemo(
    () =>
      uniqueEmailList.filter(
        (x) =>
          !(accounts as any).find(
            (y: { email: string }) => y.email === x?.email,
          ),
      ),
    [accounts, uniqueEmailList],
  );

  useEffect(() => {
    const g = _.toPairs(myProjectsGuestsDic).map<IJoinedGuest>(
      ([userId_, o]) => ({
        ...o,
        id: userId_,
        email: myProjectsXGuestsDic[userId_]?.email,
        joined: true,
      }),
    );
    const ig = _.values(myProjectsInvitingGuestsDic).map<IJoinedGuest>((o) => ({
      ...o,
      id: o.id,
      email: o.email,
      joined: false,
      projectId: o.projectId,
    }));

    // 横断検索：inputで選択されたゲストを選択オプションへ非表示
    const emailsToFilter = new Set<string>(
      _.dropRight(fields).map((y) => (y as any).value),
    );
    setJoinedGuests(
      _.uniqBy([...g, ...ig], 'id').filter(
        (x) => !x.email || !emailsToFilter.has(x.email),
      ),
    );
  }, [
    myProjectsGuestsDic,
    myProjectsInvitingGuestsDic,
    myProjectsXGuestsDic,
    fields,
  ]);

  /** 変更イベント Emit */
  function emitChange(event: AccountListChangeEvent) {
    if (onChange) onChange(event);
  }

  /**
   * 招待追加
   */
  function handleInvite() {
    append({
      id: new Date().getTime().toString(),
      value: '',
      invitedAt: new Date(),
    } as IInviteEmail);
    trigger();
    setTimeout(() => {
      scrollBottomRef!.current!.scrollIntoView({ behavior: 'smooth' });
    });
    emitChange('invite');
  }

  /**
   * 招待削除
   */
  function handleUnInvite(index: number) {
    remove(index);
    emitChange('uninvite');
  }

  /**
   * 除外/キャンセル トグル
   */
  function toggleRemove(id: string) {
    const arr = [...getValues('removeIds')];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      emitChange('unremove');
    } else {
      arr.push(id);
      emitChange('remove');
    }
    setValue('removeIds', arr, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }

  return (
    <div
      className={classNames(
        className,
        'flow-root rounded-md border dark:border-gray-700',
      )}
    >
      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
        <li className="block px-4 py-3 font-medium text-gray-700 dark:text-gray-400">
          {t('ゲスト')}
        </li>
        {/** Substance */}
        {accounts.map((item) => {
          const ex = getValues('removeIds')?.includes(item.id);
          return (
            <li
              key={item.id}
              className={classNames(
                ex ? 'bg-gray-50 dark:bg-gray-900' : '',
                'px-4 py-4',
              )}
            >
              <div className="items-center md:flex grid grid-cols-1">
                <div className="flex items-center flex-1 space-x-4">
                  <div
                    className={classNames(
                      ex ? 'opacity-40' : '',
                      'flex-shrink-0',
                    )}
                  >
                    <AvatarIcon
                      src={item.photoURL}
                      avatarName={item.displayName || item.email}
                      size="md"
                    />
                  </div>
                  <div
                    className={classNames(
                      ex ? 'opacity-40' : '',
                      'flex-1 min-w-0',
                    )}
                  >
                    <p className="mr-0 font-medium truncate md:mr-4">
                      {item.displayName}
                    </p>
                    <p className="mr-0 text-sm text-gray-500 truncate dark:text-gray-400 md:mr-4">
                      {item.email}
                    </p>
                  </div>
                </div>

                <div className="flex items-center mt-4 md:mt-0 space-x-0 md:space-x-4">
                  {ex ? (
                    <Badge
                      className="mr-4 md:mr-0"
                      color="red"
                      content={t('除外対象')}
                    />
                  ) : (
                    !item.joined && (
                      <Badge
                        className="mr-4 md:mr-0"
                        color="blue"
                        content={t('参加待ち')}
                      />
                    )
                  )}
                  {userId !== item.id && (
                    <div>
                      <FormButton
                        id={ex ? 'cancelRemoveGuest' : 'removeGuest'}
                        onClick={() => {
                          if (item.id) toggleRemove(item.id);
                        }}
                        className="py-1"
                      >
                        {ex ? t('キャンセル') : t('除外')}
                      </FormButton>
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
        {fields.map((item, index) => (
          <li key={`invite-email-${item.id}`} className="px-4 py-4">
            <div className="flex items-center space-x-4">
              <div className="flex-1 min-w-0">
                <PredictionInput
                  idx={index}
                  property="value"
                  key={item.id}
                  name="inviteEmails"
                  placeholder={t('招待先のEメールアドレス')}
                  autoFocus
                  personOptions={accountsToFilter}
                  showIcon
                />
              </div>
              <div>
                <MenuButton
                  id="removeGuestEmailInput"
                  type="icon"
                  onClick={() => handleUnInvite(index)}
                >
                  <XMarkIcon className="w-4 h-4" />
                </MenuButton>
              </div>
            </div>
          </li>
        ))}
        <li
          key="inviting-member"
          className="sticky bottom-0 px-0 py-0 text-center"
        >
          <FormButton
            id="addInviteGuest"
            onClick={() => handleInvite()}
            className="flex items-center justify-center border-0 rounded-none outline-none py-3.5 ring-0 focus:ring-0 focus:!ring-offset-0"
          >
            <PlusIcon className="w-4 h-4" aria-hidden="true" />
            <div className="ml-2">{t('ゲストを招待')}</div>
          </FormButton>
        </li>
      </ul>
      <div ref={scrollBottomRef} />
    </div>
  );
}
