import { RootState, Guest, Account, Project } from '@/@types/models';
import { IAccountDictionary } from '@/@types/viewItem';
import { serverTimestamp } from 'firebase/firestore';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { COL_GUESTS, getGuestsPath } from '@/libs/docPathUtils';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * Guestsの集約に関するHooks
 * @returns { guests }
 */
export default function useGuests() {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();

  const { currentMyProject, myProjects } = useProjects();

  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => ({
      collection: getGuestsPath(workspaceId, p.id),
      orderBy: ['displayName', 'asc'],
      storeAs: `${COL_GUESTS}/${p.id}`,
    }));
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);
  // useProjectsにも同様のロジックがあるため、変更の際には併せて確認のこと
  const guests: Guest[] = useMemo(() => {
    if (projectId && ordered[`${COL_GUESTS}/${projectId}`])
      return ordered[`${COL_GUESTS}/${projectId}`];
    return [];
  }, [ordered, projectId]);

  const myProjectsGuestsDic = useMemo(
    () =>
      myProjects.reduce<IAccountDictionary>((acc, cur) => {
        const values = ordered[`${COL_GUESTS}/${cur.id}`];
        if (!values) return acc;
        values.forEach((v: any) => {
          const projects: string[] = acc[v.id]?.projectId || [];
          const dic: IAccountDictionary['uid'] = {
            displayName: v.displayName,
            photoURL: v.photoURL,
            joinType: 'guest',
            projectId: [cur.id]
              .concat(projects)
              .filter((s): s is string => Boolean(s)),
          };
          acc[v.id] = dic;
        });
        return acc;
      }, {}),
    [ordered],
  );
  /**
   * DisplayNameの更新
   * @param {string} uid
   * @param {string} displayName
   */
  const updateGuest = async (
    uid: Account['uid'],
    displayName: Account['displayName'] | null | undefined,
  ) => {
    exec(async () => {
      if (!uid || !workspaceId) throw new Error();
      const currentGuest = guests.find((o) => o.uid === uid);
      if (!currentGuest) throw new Error();
      const newGuest: Guest = {
        displayName: displayName || null,
        sysUpdatedAt: serverTimestamp(),
      };
      rrfFirestore.update<Guest>(
        `${getGuestsPath(workspaceId, projectId)}/${currentGuest.uid}`,
        newGuest,
      );
    }, t('アカウントの更新に失敗しました'));
  };

  /**
   *ゲストをプロジェクトへ更新
   * @param uid
   * @param displayName
   * @param projectIdToUse
   */
  const addGuestForProject = async (
    uid: Account['uid'],
    displayName: Account['displayName'] | null | undefined,
    projectIdToUse: Project['id'] | null = projectId,
  ) => {
    exec(async () => {
      if (!uid || !workspaceId || !projectIdToUse) throw new Error();
      const newGuest: Guest = {
        displayName: displayName || null,
        sysUpdatedAt: serverTimestamp(),
      };
      rrfFirestore.update<Guest>(
        `${getGuestsPath(workspaceId, projectIdToUse)}/${uid}`,
        newGuest,
      );
    }, t('アカウントの更新に失敗しました'));
  };

  /**
   * ゲストをプロジェクトから削除
   * @param uid
   * @param projectIdToUse
   */
  const removeGuestForProject = async (
    uid: Account['uid'],
    projectIdToUse: Project['id'] | null = projectId,
  ) => {
    exec(async () => {
      if (!uid || !workspaceId || !projectIdToUse) throw new Error();
      const currentGuest = myProjectsGuestsDic[uid];
      if (!currentGuest) throw new Error();
      rrfFirestore.delete<Guest>(
        `${getGuestsPath(workspaceId, projectIdToUse)}/${uid}`,
      );
    }, t('アカウントの更新に失敗しました'));
  };

  /**
   * ログイン中のゲスト情報を取得する
   * @returns {Guest | undefined}
   */
  const getMe = useCallback(
    () => guests.find((o) => o.uid === userId),
    [guests, userId],
  );

  return {
    guests,
    getMe,
    updateGuest,
    myProjectsGuestsDic,
    addGuestForProject,
    removeGuestForProject,
  };
}
