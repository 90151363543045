import { IAuthType, RootState } from '@/@types/models';
import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import RelativePortal from 'react-relative-portal';

import { classNames } from '@/libs/styleUtils';

import { Badge } from '@/components/Common/Badge';
import ValidationTooltip from '@/components/Common/Forms/Validation/ValidationTooltip';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';

import { IChengeAuth } from '@/components/Member/MemberAccountList';

import useAdminMembers from '@/hooks/useAdminMembers';

interface IChangeAuthError {
  message: string;
}

const typeCheckIChangeAuthError = (obj: unknown): obj is IChangeAuthError => {
  const changeAuthError = obj as IChangeAuthError;
  return 'message' in changeAuthError;
};

export type IDropDownProps = {
  disabledAuthMember?: boolean;
  defaultValue: IAuthType;
  uid: string;
};

// TODO ジェネリックに利用できるコンポーネントにする
export default function AuthDropDown(props: IDropDownProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<IAuthType>();
  const [showError, setShowError] = useState<boolean>(false);
  const { disabledAuthMember, defaultValue, uid } = props;
  const { setValue, getValues, trigger, formState } = useFormContext();
  const { adminMember } = useAdminMembers();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const publishingOptions = useMemo(
    () => [
      {
        title: t('一般'),
        description: t(
          'プロジェクトに関するほぼすべての権限を持ちますが、支払い管理、ワークスペースに関する権限は持ちません。',
        ),
        waring: t('管理者権限のアカウントが最低1つ必要です'),
        current: true,
        auth: 'member',
        disabled: disabledAuthMember,
      },
      {
        title: t('管理者'),
        description: t(
          '一般権限に加え、支払い管理、ワークスペース管理を含むすべての操作の権限を持ちます。',
        ),
        current: false,
        auth: 'administrator',
        disabled: false,
      },
    ],
    [disabledAuthMember],
  );

  useEffect(() => {
    setSelected(defaultValue);
  }, [defaultValue]);

  /**
   * 管理者ユーザー判定
   */
  const isAdmin = useMemo(() => {
    if (!adminMember.memberList) return false;
    return adminMember.memberList.includes(userId);
  }, [userId, adminMember.memberList]);

  const changeAuth = (selectedAuth: IAuthType) => {
    setSelected(selectedAuth);
    const list = getValues('changeAuths') as IChengeAuth[];
    const foundAdminMember = list.find((o) => o.id === uid);
    if (foundAdminMember) {
      foundAdminMember.value = selectedAuth;
    } else {
      list.push({
        id: uid,
        value: selectedAuth,
      });
    }
    setValue('changeAuths', list, { shouldDirty: true, shouldTouch: true });
    trigger();
  };

  const selectedItem = useMemo(
    () => publishingOptions.find((o) => o.auth === selected),
    [selected],
  );

  const error = useMemo(() => {
    if (!formState.isValidating && formState.errors?.changeAuths) {
      if (
        Array.isArray(formState.errors?.changeAuths) &&
        formState.errors?.changeAuths.length > 0 &&
        typeCheckIChangeAuthError(formState.errors?.changeAuths[0])
      )
        return formState.errors?.changeAuths[0].message;
    }
    return null;
  }, [formState.errors?.changeAuths, formState.isValidating]);

  // HACK: 除外切替時にドロップダウンでエラーポップアップが一瞬表示される現象を回避
  useEffect(() => {
    setShowError(false);
    setTimeout(() => setShowError(error !== null));
  }, [error]);

  return (
    <div>
      {!isAdmin ? (
        <div>
          <div className="relative">
            <div>
              <div className="inline-flex border border-gray-300 rounded-md dark:border-gray-600 focus:ring-primary-500">
                <div className="relative z-0 inline-flex rounded-md">
                  <div className="relative inline-flex items-center text-sm font-medium text-gray-500 rounded-md focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2  dark:ring-gray-500 dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600">
                    <div className="relative inline-flex items-center px-1 py-2 text-sm font-medium text-gray-500 border border-transparent bg-white-100 rounded-l-md shadow-sm mx-2.5 dark:text-gray-300">
                      {selectedItem?.title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Listbox value={defaultValue} onChange={changeAuth}>
          {({ open }) => (
            <div className="relative">
              <div>
                <div
                  className={classNames(
                    'inline-flex border border-gray-300 rounded-md dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-primary-500',
                    error && 'border-red-400 dark:border-red-700',
                  )}
                >
                  <div className="relative z-0 inline-flex rounded-md">
                    <Listbox.Button className="relative inline-flex items-center text-sm font-medium text-gray-500 rounded-md focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2  dark:ring-gray-500 dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600">
                      <div className="relative inline-flex items-center px-1 py-2 text-sm font-medium text-gray-500 border border-transparent bg-white-100 rounded-l-md ml-2.5 dark:text-gray-300">
                        {selectedItem?.title}
                      </div>
                      <ChevronDownIcon
                        className="w-4 h-4 ml-2 mr-2"
                        aria-hidden="true"
                      />
                    </Listbox.Button>
                  </div>
                </div>
                {showError && error && (
                  <ValidationTooltip message={error} className="min-w-max" />
                )}
              </div>
              <RelativePortal component="div" right={0}>
                <PopupTransition show={open}>
                  <Listbox.Options className="absolute z-10 mt-2 overflow-hidden bg-white shadow-lg origin-top-right right-100 md:sm:right-0 w-72 rounded-md dark:bg-gray-700 divide-y divide-gray-100 dark:divide-gray-600 ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {publishingOptions.map((option) => {
                      const disabled =
                        option.disabled && selected === 'administrator';
                      return (
                        <Listbox.Option
                          disabled={disabled}
                          key={option.title}
                          className={classNames(
                            disabled
                              ? 'bg-gray-50 cursor-not-allowed'
                              : 'hover:text-white bg-white hover:bg-primary-500 dark:hover:bg-primary-600 cursor-pointer',
                            'text-gray-600 dark:text-gray-200 dark:bg-gray-700 relative p-4 text-sm',
                          )}
                          value={option.auth}
                        >
                          {({ active }) => (
                            <div className="flex flex-col">
                              <div className="flex items-center justify-between">
                                <div
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    disabled ? 'opacity-40' : '',
                                  )}
                                >
                                  {option.title}
                                </div>
                                {selected === option.auth ? (
                                  <span
                                    className={
                                      active
                                        ? 'text-gray-500 dark:text-gray-200'
                                        : 'text-gray-200'
                                    }
                                  >
                                    <CheckIcon
                                      className="w-5 h-5 dark:text-primary-600 text-primary-500"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </div>
                              <div className="mt-3">
                                <span className={disabled ? 'opacity-40' : ''}>
                                  {option.description}
                                </span>
                                {disabled && (
                                  <Badge
                                    color="red"
                                    className="mt-3 text-xs text-red-500 dark:text-red-700"
                                    content={option.waring as string}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </Listbox.Option>
                      );
                    })}
                  </Listbox.Options>
                </PopupTransition>
              </RelativePortal>
            </div>
          )}
        </Listbox>
      )}
    </div>
  );
}
