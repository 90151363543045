import { Item } from '@/@types/models';
import { groupBy, intersectionWith, isEqual } from 'lodash';

import { useEffect, useState } from 'react';

// 公開アイテム選択モードで使用するhooks
export default function useItemContext() {
  const [checkedItemIds, setCheckedItemIds] = useState<string[]>([]);
  const [contextItems, setContextItems] = useState<Item[]>([]);
  const [checkedStatusIds, setCheckedStatusIds] = useState<string[]>([]);

  // 現在のステータス
  const statuses = contextItems.map((i) => i.statusId);
  const uniqueStatuses = Array.from(new Set(statuses));
  const allStatuses = uniqueStatuses.filter(
    (status): status is string => typeof status === 'string',
  );

  // 現在のアイテム
  const allItems = contextItems.map((i) => i.id);
  const allItemId = allItems.filter(
    (id): id is string => typeof id === 'string',
  );

  // ステータスごとにグループ化したアイテム
  const groupedStatusItem = groupBy(contextItems, 'statusId');
  const groupedStatusItemIds: { [statusId: string]: string[] } = {};
  Object.keys(groupedStatusItem).forEach((key) => {
    const items = groupedStatusItem[key];
    const itemIds = items.map((i) => i.id);
    const filterd = itemIds.filter(
      (id): id is string => typeof id === 'string',
    );
    groupedStatusItemIds[key] = [...filterd];
  });

  // 全て選択/解除
  const checkAllItem = (allItemsFlg: boolean) => {
    if (allItemsFlg) {
      setCheckedStatusIds([]);
      setCheckedStatusIds([...allStatuses]);
      setCheckedItemIds([...allItemId]);
    } else {
      setCheckedStatusIds([]);
      setCheckedItemIds([]);
    }
  };

  // アイテムをチェック
  const checkItemId = (targetId: string) => {
    if (!checkedItemIds.includes(targetId)) {
      setCheckedItemIds((old) => [...old, targetId]);
    } else {
      setCheckedItemIds((old) => old.filter((i) => i !== targetId));
    }
  };

  // ステータスをチェック
  const checkStatusId = (targetId: string) => {
    const targetIds = groupedStatusItemIds[targetId];
    const updateIds = [...checkedItemIds, ...targetIds];
    if (!checkedStatusIds.includes(targetId)) {
      setCheckedStatusIds((old) => [...old, targetId]);
      setCheckedItemIds(Array.from(new Set(updateIds)));
    } else {
      setCheckedStatusIds((old) => old.filter((i) => i !== targetId));
      setCheckedItemIds((old) => old.filter((i) => !targetIds.includes(i)));
    }
  };

  useEffect(() => {
    // ステータスチェックの判定
    Object.keys(groupedStatusItemIds).forEach((statusId) => {
      const intersectValues = intersectionWith(
        groupedStatusItemIds[statusId].sort(),
        checkedItemIds.sort(),
      );
      const isStatusAllChecked = isEqual(
        groupedStatusItemIds[statusId].sort(),
        intersectValues.sort(),
      );
      if (isStatusAllChecked) {
        setCheckedStatusIds((old) => [...old, statusId]);
      } else {
        setCheckedStatusIds((old) => old.filter((i) => i !== statusId));
      }
    });
  }, [checkedItemIds]);

  return {
    // item
    contextItems,
    setContextItems,
    // 全て選択
    checkAllItem,
    // アイテムのチェックボックス
    checkedItemIds,
    setCheckedItemIds,
    checkItemId,
    // ステータスのチェックボックス
    checkedStatusIds,
    checkStatusId,
  };
}
