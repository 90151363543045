import { originalColors, OriginalColorType } from '@/@types/common';
import { Menu } from '@headlessui/react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import RelativePortal from 'react-relative-portal';

import { PickerBadge } from '@/components/Common/PickerBadge';

interface Props {
  id: number;
  selected: OriginalColorType;
  openerClass?: string;
  onChange: (color: OriginalColorType, id: number) => void;
}

export default function ColorPicker(props: Props) {
  const { id, selected, openerClass, onChange } = props;
  const { t } = useTranslation();

  return (
    <Menu
      as="div"
      className="relative inline-block w-full ml-auto text-gray-400"
    >
      {({ open }) => (
        <>
          <Menu.Button className="relative block w-full">
            <PickerBadge className={openerClass} color={selected} />
          </Menu.Button>
          {open && (
            <RelativePortal component="div" left={0}>
              <Menu.Items
                static
                className="absolute left-0 z-50 w-56 p-px mt-2 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none"
              >
                <div className="py-1 bg-white dark:bg-gray-700 rounded-md shadow-xs divide-y divide-gray-100 dark:divide-gray-600">
                  <div className="block w-full px-2 py-2 text-left">
                    <div className="">{t('カラーを選択')}</div>
                  </div>
                  <div className="flex flex-wrap px-2 pt-4 pb-2">
                    {originalColors.map((color) =>
                      selected === color ? (
                        <div className="px-2" key={color}>
                          <PickerBadge color={color} active />
                        </div>
                      ) : (
                        <Menu.Item
                          as="button"
                          key={color}
                          onClick={() => onChange(color, id)}
                        >
                          <div className="px-2">
                            <PickerBadge color={color} />
                          </div>
                        </Menu.Item>
                      ),
                    )}
                  </div>
                </div>
              </Menu.Items>
            </RelativePortal>
          )}
        </>
      )}
    </Menu>
  );
}
