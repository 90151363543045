import {
  RootState,
  MemberGuestViewItem,
  ViewPropertyValues,
} from '@/@types/models';
import { useCallback, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { DATE_PROPERTY_EXPIRATION_THRESHOLD } from '@/libs/const';
import { DayjsUtil } from '@/libs/dayjs';
import {
  COL_GUEST_VIEW_ITEMS,
  COL_MEMBER_VIEW_ITEMS,
  getGuestViewItemsPath,
  getMemberViewItemsPath,
} from '@/libs/docPathUtils';

import { IItemSearchState } from '@/reducers/itemsReducer';

import { isSelectPublishItemModeContext } from '@/components/Share/SelectPublishItemsContext';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useProjects from '@/hooks/useProjects';

/**
 * viewItemsのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useViewItems() {
  const { currentItemId: currentViewItemId } = useSelector(
    (state: RootState) => state.items,
  );
  const { currentMyProject, myProjects } = useProjects();
  const { currentMyWorkspace, isMember } = useMyWorkspaces();
  const itemsState = useSelector((state: RootState) => state.items);

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const getDocPath = useMemo(
    () => (isMember ? getMemberViewItemsPath : getGuestViewItemsPath),
    [isMember],
  );

  const docName = useMemo(
    () => (isMember ? COL_MEMBER_VIEW_ITEMS : COL_GUEST_VIEW_ITEMS),
    [isMember],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  // アイテムID切替ごとにストアが洗い替えされないためprojectId毎にサブスクリプションしている
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => {
      const query: ReduxFirestoreQuerySetting = {
        collection: getDocPath(workspaceId, p.id as string),
        storeAs: `${docName}/${p.id}`,
        orderBy: [['title', 'asc']],
      };
      return query;
    });
  });

  const ordered = useSelector((state: any) => state.firestore.ordered);
  const currentSearchState: IItemSearchState = useMemo(() => {
    const fr = itemsState.itemSearchStates.find(
      (o) =>
        o.workspaceId === currentMyWorkspace?.id &&
        o.projectId === currentMyProject?.id,
    );
    return (
      fr ||
      ({
        workspaceId: currentMyWorkspace?.id,
        projectId: currentMyProject?.id,
        keyword: null,
        filter: null,
        itemIds: [],
      } as IItemSearchState)
    );
  }, [itemsState.itemSearchStates, currentMyWorkspace, currentMyProject]);

  const viewItems = useMemo(() => {
    if (!currentMyProject?.id) return [];
    const currentAllItems = (ordered[`${docName}/${currentMyProject.id}`] ||
      []) as MemberGuestViewItem[];
    // 検索結果が存在する、かつ高愛アイテム選択モードでない場合はitemIdをフィルタリングする
    return (currentSearchState.keyword ||
      currentSearchState.filter !== null ||
      currentSearchState.itemIds.length > 0) &&
      !isSelectPublishItemMode
      ? currentAllItems.filter((i) =>
          currentSearchState.itemIds.includes(i.id as string),
        )
      : currentAllItems;
  }, [
    ordered,
    currentMyProject,
    currentSearchState,
    isSelectPublishItemMode,
    docName,
  ]);

  const currentViewItem = useMemo(
    () => viewItems.find((i) => i.id === currentViewItemId),
    [viewItems, currentViewItemId],
  );

  // 自身が作成したアイテムか
  const getIsOwnItem = useCallback(
    (itemId: MemberGuestViewItem['id']) => {
      const targetItem = viewItems.find((i) => i.id === itemId);
      return targetItem?.itemCreatedBy === userId;
    },
    [viewItems],
  );

  const getItem = useCallback(
    (id: string) => viewItems.filter((p) => p.id === id),
    [viewItems],
  );

  /**
   * 期限までの日数を取得
   */
  const getDaysToGo = useCallback((v: ViewPropertyValues) => {
    if (v.propertyType !== 'date') return 0;
    if (!v.value || !v.isDueDate) return 0;
    const target = new DayjsUtil(v.value);
    const now = new DayjsUtil().clearTime().dayjsObj.toDate();
    return target.diff(now, 'day');
  }, []);

  /**
   * アイテムの期日ハイライト判定
   * @param item アイテム
   * @param warningClassName 期限間近のclassName
   * @param dangerClassName 超過時のclassName
   * @returns className
   */
  const getHighlightClass = useCallback(
    (
      item: MemberGuestViewItem,
      warningClassName: string,
      dangerClassName: string,
    ) => {
      let maxHighlightLevel = DATE_PROPERTY_EXPIRATION_THRESHOLD.WARNING + 1;
      Object.keys(item.propertyValues).forEach((k) => {
        const pv = item.propertyValues[k];
        // 期限設定された日付プロパティの値が設定されている
        if (pv.propertyType === 'date') {
          if (pv.value && pv.isDueDate) {
            const daysToGo = getDaysToGo(pv);
            // レベルが高い期限を優先的に採用する
            maxHighlightLevel =
              maxHighlightLevel > daysToGo ? daysToGo : maxHighlightLevel;
          }
        }
      });
      if (maxHighlightLevel <= DATE_PROPERTY_EXPIRATION_THRESHOLD.DANGER)
        return dangerClassName; // 当日以降
      if (maxHighlightLevel <= DATE_PROPERTY_EXPIRATION_THRESHOLD.WARNING)
        return warningClassName; // N日前
      return '';
    },
    [],
  );

  /**
   * アイテムの期日ハイライト有無
   * @param item アイテム
   * @returns ハイライト有無
   */
  const isExistsHighlight = useCallback(
    (viewItem: MemberGuestViewItem) =>
      !!Object.keys(viewItem.propertyValues).find((k) => {
        const pv = viewItem.propertyValues[k];
        if (pv.propertyType === 'date') {
          if (pv.value && pv.isDueDate) {
            const daysToGo = getDaysToGo(pv);
            return (
              daysToGo <= DATE_PROPERTY_EXPIRATION_THRESHOLD.DANGER ||
              daysToGo <= DATE_PROPERTY_EXPIRATION_THRESHOLD.WARNING
            );
          }
        }
        return false;
      }),
    [],
  );

  return {
    viewItems,
    currentViewItemId,
    currentViewItem,
    getItem,
    getIsOwnItem,
    currentSearchState,
    docName,
    getHighlightClass,
    isExistsHighlight,
  };
}
