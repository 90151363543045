import { IModalDialogProps } from '@/@types/ui';

import { Transition, Dialog } from '@headlessui/react';
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';

import React, { Children, Fragment, ReactNode, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { classNames } from '@/libs/styleUtils';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

export interface IConfirmDialogProps extends IModalDialogProps {
  /** アイコンやボタンが警告表示になります */
  warning?: boolean;
  children?: ReactNode[] | ReactNode;
  /** ダイアログタイトル */
  title: string;
  /** ネガティブボタンラベル（）デフォルト:「キャンセル」 */
  negative?: string;
  /** ポジティブボタンラベル */
  positive: string;
  /** ポジティブボタンが無効になる */
  disabled?: boolean;
  open: boolean;
  /** ポジティブボタンのみ */
  positiveOnly?: boolean;

  spinner?: boolean;
  className?: string;
}

/**
 * 確認ダイアログ
 *
 * onCloseの引数（boolean）でnegative/positiveが取得できます
 * negative: false, positive: true
 *
 * @param props
 * @returns
 */
export default function ConfirmDialog(props: IConfirmDialogProps) {
  const {
    open,
    onClose,
    warning,
    title,
    positive,
    negative,
    children,
    disabled,
    positiveOnly,
    spinner,
    className,
  } = props;
  const { t } = useTranslation();
  const negativeButtonRef = useRef(null);
  const positiveButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={warning ? negativeButtonRef : positiveButtonRef}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen px-4 py-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 dark:bg-black bg-opacity-75 dark:bg-opacity-40 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                className,
                'inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6 dark:bg-gray-800',
              )}
            >
              <div className="sm:flex sm:items-start">
                {warning ? (
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full dark:bg-red-800 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="w-6 h-6 text-red-600 dark:text-red-300"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto rounded-full bg-primary-100 dark:bg-primary-700 sm:mx-0 sm:h-10 sm:w-10">
                    <InformationCircleIcon
                      className="w-6 h-6 text-primary-600 dark:text-primary-400"
                      aria-hidden="true"
                    />
                  </div>
                )}
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium text-gray-900 dark:text-gray-400 leading-6"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-4">{Children.map(children, (e) => e)}</div>
                </div>
              </div>
              <div className="flex flex-row-reverse mt-5 sm:mt-4">
                <MenuButton
                  id="confirmPositive"
                  ref={positiveButtonRef}
                  type="text"
                  variant={warning ? 'warning' : 'primary'}
                  className="!py-2 sm:w-auto"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose(true);
                  }}
                  disabled={disabled || spinner}
                  spinner={spinner}
                >
                  {positive}
                </MenuButton>
                {!positiveOnly && (
                  <MenuButton
                    id="confirmNegative"
                    ref={negativeButtonRef}
                    type="text"
                    className="mr-3 !py-2 md-mt-0 sm:w-auto !min-w-fit"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClose(false);
                    }}
                  >
                    {negative || t('キャンセル')}
                  </MenuButton>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
