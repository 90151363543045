import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';

import { DEFAULT_REACTION_DISPLAY, REACTION_ABB_THRESHOLD } from '@/libs/const';

import BaseButton from '@/components/Items/Comments/Reaction/BaseButton';

export interface IShowMoreReactionProps {
  count: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
}

/**
 * 他のリアクションを表示ボタン
 * @param props
 * @returns
 */
export default function ShowMoreReaction(props: IShowMoreReactionProps) {
  const { t } = useTranslation();
  const { count, onClick, className } = props;

  /** カウント */
  const moreCount = useMemo(
    () =>
      count > REACTION_ABB_THRESHOLD
        ? `${REACTION_ABB_THRESHOLD}+`
        : count - DEFAULT_REACTION_DISPLAY,
    [count],
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <BaseButton
      dataTip={t('すべてのリアクションを表示')}
      onClick={onClick}
      className={className}
    >
      <span className="text-xs">
        {t('他<moreCount>件', {
          moreCount,
        })}
      </span>
    </BaseButton>
  );
}
