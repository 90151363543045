import { Comment, RootState, Item } from '@/@types/models';
import { Timestamp, serverTimestamp } from 'firebase/firestore';
import { t } from 'i18next';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestore } from 'react-redux-firebase';

import { PAGINATE } from '@/libs/const';
import { COL_COMMENTS, getCommentsPath } from '@/libs/docPathUtils';

import { getFullSystemFields, getUpdateSystemFields } from '@/libs/utils';

import useAccessSettings from '@/hooks/useAccessSettings';
import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePaginate from '@/hooks/usePaginate';
import useProjects from '@/hooks/useProjects';

/**
 * commentsのリアルタイムアップデートを購読するためのHooks
 * 現在開いているアイテムのコメントのみサブスクリプションする
 * @returns { }
 */
export default function useComments(itemId: Item['id']) {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();
  const { currentMyProject } = useProjects();

  const { currentMyWorkspace } = useMyWorkspaces();

  // 現在サブスクリプション中のキー保持用
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // 一旦全公開とする。近い将来、ゲストへの表示可否を条件とする
  // const where = useMemo<WhereOptions | WhereOptions[] | undefined>(
  //   () =>
  //     isMember ? undefined : ['to', 'array-contains-any', [userId, '@all']],
  //   [isMember],
  // );

  const { isDisplayComment } = useAccessSettings();

  // ページネーション込みコメントリスト取得
  const {
    dataList: comments,
    hasNext,
    loadNextPage,
  } = usePaginate<Comment, 'comments'>(
    getCommentsPath(workspaceId, projectId, itemId),
    undefined,
    ['postedAt', 'desc'],
    PAGINATE.COMMENTS,
    COL_COMMENTS,
    () => isDisplayComment === false || !workspaceId || !projectId || !itemId,
  );

  // コメント新規作成
  const createComment = async (
    editorState: Comment['editorState'],
    plainText: Comment['plainText'],
    fileUrlList: Comment['fileUrlList'],
    targetItemId: Item['id'],
  ) => {
    exec(async () => {
      if (!userId || !workspaceId || !projectId || !targetItemId)
        throw new Error('invalid user');
      const comment: Comment = {
        editorState,
        plainText,
        fileUrlList,
        postedAt: serverTimestamp() as Timestamp,
        postedBy: userId,
        lastEditedAt: serverTimestamp() as Timestamp,
        workspaceId,
        projectId,
        itemId: targetItemId,
        ...getFullSystemFields(userId),
      };
      await rrfFirestore.add<Comment>(
        getCommentsPath(workspaceId, projectId, targetItemId),
        comment,
      );
    }, t('コメントの投稿に失敗しました'));
  };

  // コメント更新
  const updateComment = async (
    commentId: Comment['id'],
    editorState: Comment['editorState'],
    plainText: Comment['plainText'],
    fileUrlList: Comment['fileUrlList'],
    targetItemId: Item['id'],
  ) => {
    exec(async () => {
      if (!userId || !workspaceId || !projectId || !targetItemId)
        throw new Error('invalid user');
      rrfFirestore.update<Comment>(
        `${getCommentsPath(workspaceId, projectId, targetItemId)}/${commentId}`,
        {
          editorState,
          plainText,
          fileUrlList,
          lastEditedAt: serverTimestamp() as Timestamp,
          ...getUpdateSystemFields(userId),
        },
      );
    }, t('コメントの更新に失敗しました'));
  };

  // コメント削除
  const deleteComment = async (
    commentId: Comment['id'],
    targetItemId: Item['id'],
  ) => {
    exec(async () => {
      if (!userId || !workspaceId || !projectId || !targetItemId)
        throw new Error('invalid user');
      await rrfFirestore.delete(
        `${getCommentsPath(workspaceId, projectId, targetItemId)}/${commentId}`,
      );
    }, t('コメントの削除に失敗しました'));
  };

  return {
    comments,
    hasNext,
    loadNextPage,
    createComment,
    deleteComment,
    updateComment,
  };
}
