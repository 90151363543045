import { OriginalColorType } from '@/@types/common';
import { IPropertyOptionType } from '@/@types/models';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { classNames } from '@/libs/styleUtils';

import ColorPicker from '@/components/Common/ColorPicker';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import InvalidIcon from '@/components/Common/Forms/Validation/Icon/FormInvalidIcon';
import ValidIcon from '@/components/Common/Forms/Validation/Icon/FormValidIcon';
import ValidationTooltip from '@/components/Common/Forms/Validation/ValidationTooltip';

export interface IPropertyOptionInputProps {
  name: string;
  property: string;
  idx: number;
  option: IPropertyOptionType;
  onDelete: (idx: number) => void;
  onChangeColor: (idx: number, color: OriginalColorType) => void;
  disabled?: boolean;
  className?: string;
}

/**
 * TODO: プロパティ選択肢用Input 特殊なUIだが共通化するか?
 * @param props
 * @returns
 */
export default function PropertyOptionInput(props: IPropertyOptionInputProps) {
  const {
    name,
    disabled,
    className,
    idx,
    option,
    property,
    onDelete,
    onChangeColor,
  } = props;

  type IProp = {
    [key in typeof property as string]: {
      message: string;
    };
  };

  const typeCheckProp = (obj: unknown, key: string): obj is IProp => {
    const prop = obj as IProp;
    return key in prop;
  };

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  const { dirtyFields } = useFormState({
    control,
  });

  // 該当プロパティ名&該当インデックスのメッセージを探索
  let error = '';
  const key = errors[name];
  if (
    key &&
    Array.isArray(key) &&
    key[idx] &&
    typeCheckProp(key[idx], property)
  ) {
    const form = key[idx];
    if (typeCheckProp(form, property)) {
      error = form[property].message;
    }
  }

  let dirtyFlg = false;
  const dKey = dirtyFields[name];
  if (dKey && dKey[idx] && dKey[idx][property]) {
    dirtyFlg = true;
  }

  const handleChangeColor = (color: OriginalColorType, index: number) => {
    onChangeColor(index, color);
  };

  return (
    <div key={option.id} className="flex items-center space-x-4">
      <div className="flex-1 min-w-0">
        <div className="relative sm:col-span-6">
          <div className="absolute inset-y-0 left-0 flex items-center mx-auto my-auto">
            <ColorPicker
              id={idx}
              selected={option.color}
              openerClass="ml-2 -mt-1 align-middle"
              onChange={handleChangeColor}
            />
          </div>
          <input
            autoFocus
            data-tip={error}
            type="text"
            className={classNames(
              disabled
                ? 'bg-gray-200 text-gray-500 dark:bg-gray-800 dark:text-gray-400 opacity-50'
                : 'bg-white dark:bg-gray-700',
              className,
              error && 'border-red-400 dark:border-red-700',
              'block  pl-10 w-full border-gray-300 dark:border-gray-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 rounded-md',
            )}
            placeholder={`${t('選択肢')} ${idx + 1}`}
            // 専用コンポーネントなので参照するプロパティは固定にしている
            {...register(`${name}[${idx}].${property}`)}
          />
          {(error && <InvalidIcon />) || (dirtyFlg && <ValidIcon />)}
          <ValidationTooltip message={error} />
        </div>
      </div>

      <div>
        <MenuButton
          id="removeSelectItem"
          type="icon"
          onClick={() => onDelete(idx)}
        >
          <XMarkIcon className="w-4 h-4" />
        </MenuButton>
      </div>
    </div>
  );
}
