import { OriginalColorType } from '@/@types/common';
import { CheckIcon } from '@heroicons/react/24/outline';
import React, { useMemo } from 'react';

import { classNames, getOriginalColorClass } from '@/libs/styleUtils';

export interface IPickerBadgeProps {
  color?: OriginalColorType;
  className?: string;
  active?: boolean;
  size?: 'xs' | 'sm';
}

export function PickerBadge(props: IPickerBadgeProps) {
  const { color, className, active, size } = props;

  const originalColor = useMemo(() => getOriginalColorClass(color), [color]);

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'w-4 h-4';
      case 'sm':
        return 'w-5 h-5';
      default:
        return 'w-5 h-5';
    }
  }, [size]);

  return (
    <span
      className={classNames(
        className,
        sizeClass,
        originalColor?.bg,
        originalColor?.text,
        'inline-flex rounded-md cursor-pointer focus:outline-none hover:ring-2 hover:ring-primary-500 dark:hover:ring-primary-600',
      )}
    >
      {active && <CheckIcon className="w-4 h-4 m-auto" />}
    </span>
  );
}
