import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * URL Queryパラメータを取得
 * @returns queries
 */
export default function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
