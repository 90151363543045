import { IJoinType } from '@/@types/models';
import { PlusIcon } from '@heroicons/react/24/outline';
import { LexicalComposer } from '@lexical/react/LexicalComposer';

import { t } from 'i18next';
import { RootNode } from 'lexical';
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useDrop, DropTargetMonitor } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useFirestore } from 'react-redux-firebase';

import { FILE_COUNT_LIMIT } from '@/libs/const';
import { getTempFilesPath } from '@/libs/docPathUtils';
import { classNames } from '@/libs/styleUtils';

import FileLoader, { FileSource } from '@/components/Common/FileLoader';
import BaseLexicalEditor, {
  IBaseLexicalEditorHandler,
} from '@/components/Common/Lexical/BaseLexicalEditor';
import CommentToolbar, {
  ICommentToolbarHandler,
} from '@/components/Common/Lexical/CommentToolbar';

import editorConfig from '@/components/Common/Lexical/configs/editorConfig';
import MentionsPlugin from '@/components/Common/Lexical/plugins/MentionsPlugin';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

export interface IMentionList {
  id: string;
  value: string;
  name: string;
  optionName?: string;
  photoURL?: string;
  isAnonymous: boolean;
  type?: IJoinType;
}

// 外部コンポーネントから命令的に実行できるインターフェース
export type ICommentEditorHandler = {
  setContent: (content: string) => void;
  setQuoteContent: (content: string) => void;
  setShouldAlert: (shouldAlert: boolean) => void;
  setMentionContent: (id: string, name: string) => void;
  setChildDisabled: (bool: boolean) => void;
};

type ICommentEditorProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  isEditMode: boolean;
  uploadedFiles: FileSource[];
  onSend: (jsonState: string, textState: string) => void;
  onEditCancel: () => void;
  onSelectFiles: (files: File[]) => void;
  onDetachFile: (file: FileSource, docPath: string) => void;
  isSelectFileLoading?: boolean;
  initialData?: string;
  // onChange: (editorState: string) => void;
};

/**
 * Lexicalエディタ(コメント用)
 * @param props
 * @returns
 */
const CommentEditor = forwardRef<ICommentEditorHandler, ICommentEditorProps>(
  (props: ICommentEditorProps, ref) => {
    const toolbarRef = useRef({} as ICommentToolbarHandler);
    const lexicalRef = useRef({} as IBaseLexicalEditorHandler);
    const {
      className,
      isEditMode,
      uploadedFiles,
      onSend,
      onEditCancel,
      onSelectFiles,
      onDetachFile,
      isSelectFileLoading,
      initialData,
    } = props;

    const rrfFirestore = useFirestore();

    const { currentMyWorkspace } = useMyWorkspaces();

    const [isRichMode, setIsRichMode] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [isFileDirty, setIsFileDirty] = useState(false);

    // カレントワークスペースID
    const currentWorkspaceId = useMemo(() => {
      if (!currentMyWorkspace || !currentMyWorkspace.workspaceId) return null;
      return currentMyWorkspace.workspaceId;
    }, [currentMyWorkspace]);

    // ファイル削除
    const onDeleteFile = async (file: FileSource) => {
      if (!currentWorkspaceId) throw new Error('current workspace not loaded.');

      // firestore(メタデータ)のパスはfile.pathと異なるので別途取得する
      const res = await rrfFirestore
        .collection(getTempFilesPath(currentWorkspaceId))
        .where('fullPath', '==', file.path)
        .get();
      const docPath = res.docs.length > 0 ? res.docs[0].ref.path : '';

      onDetachFile(file, docPath);
    };

    // 選択中の宛先管理用のローカルステート
    /**
     * HACK: stateだと更新時に最新データが取得できないので、値参照用にはrefを用意する.
     * 逆にrefを更新しても宛先コンポーネントで更新内容が反映されないのでstateを使用する.
     * 不格好な組み方だが一旦問題は解決できた.
     */
    // 外部コンポーネントから命令的に実行できるメソッド
    useImperativeHandle(ref, () => ({
      setContent(content: string) {
        if (!lexicalRef.current) return;
        lexicalRef.current.setContent(content);
      },
      setQuoteContent(content: string) {
        if (!lexicalRef.current) return;
        lexicalRef.current.setQuoteContent(content);
      },
      setMentionContent(id: string, name: string) {
        if (!lexicalRef.current) return;
        lexicalRef.current.setMentionContent(id, name);
      },
      setShouldAlert(st: boolean) {
        toolbarRef.current.setShouldAlert(st);
      },
      setChildDisabled(bool: boolean) {
        if (!lexicalRef.current) return;
        lexicalRef.current.setChildDisabled(bool);
      },
    }));

    // 入力時ハンドラ
    const onChange = useCallback(
      (rootNode: RootNode, dirtyFlg: boolean, html: string) => {
        const isUpdate = initialData !== html;
        setIsDirty(initialData ? isUpdate && dirtyFlg : dirtyFlg);
      },
      [initialData],
    );

    useEffect(() => {
      setIsFileDirty(uploadedFiles.length > 0);
    }, [uploadedFiles]);

    // キャンセル時ハンドラ
    const handleEditCancel = useCallback(() => {
      onEditCancel();
      lexicalRef.current.resetContent();
    }, []);

    // 送信時ハンドラ
    const handleFormSend = useCallback(
      (jsonState: string, textState: string) => {
        onSend(jsonState, textState);
        lexicalRef.current.resetContent();
      },
      [onSend, lexicalRef],
    );

    // Focus等によるクラスの付け替え
    const inputAreaClass = useMemo(
      () => (isEditMode ? 'focus' : 'default'),
      [isEditMode],
    );

    // `Ctrl + Enter` 押下時ハンドラ
    const onCtrlEnter = () => {
      toolbarRef.current.send();
    };

    // 書式表示のトグル
    const onToggleRichMode = useCallback((mode: boolean) => {
      setIsRichMode(mode);
    }, []);

    const initialConfig = {
      namespace: 'CommentEditor',
      ...editorConfig,
    };

    const isDisabledFileUpload = useMemo(
      () => isSelectFileLoading || uploadedFiles.length >= FILE_COUNT_LIMIT,
      [isSelectFileLoading, uploadedFiles],
    );

    /**
     * ドロップハンドラー
     */
    const handleDrop = useCallback((item: { files: File[] }) => {
      toolbarRef.current.uploadFiles(item.files);
    }, []);

    /**
     * ドロップ可能性判定
     */
    const handleCanDrop = useCallback(
      (item: any) =>
        !isSelectFileLoading &&
        uploadedFiles.length + item.items.length <= FILE_COUNT_LIMIT,
      [isSelectFileLoading, uploadedFiles],
    );

    /** D&D ドロップHooks */
    const [{ canDrop, isOver }, drop] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        drop: handleDrop,
        canDrop: handleCanDrop,
        collect: (monitor: DropTargetMonitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      }),
      [handleDrop, handleCanDrop],
    );

    /** D&Dがアクティブ */
    const isDaDActive = useMemo(() => canDrop && isOver, [canDrop, isOver]);
    /** D&Dでアクティブだがファイル数オーバー */
    const isDaDLimit = useMemo(() => !canDrop && isOver, [canDrop, isOver]);

    return (
      <LexicalComposer initialConfig={initialConfig}>
        <div
          ref={drop}
          className={classNames(
            'editor-container rounded-b-md',
            inputAreaClass,
            isDaDActive ? 'relative' : '',
          )}
        >
          {isDaDActive && !isDaDLimit && (
            <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center px-3 py-2 text-primary-600  dark:text-primary-600 gap-1 bg-dad-active">
              <PlusIcon className="w-4 h-4" aria-hidden="true" />
              {t('ファイルをドロップして追加')}
            </div>
          )}
          {isDaDLimit && (
            <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center px-3 py-2 text-gray-500 dark:text-gray-300 bg-dad-disabled">
              {t('ファイルを追加できません')}
            </div>
          )}
          <CommentToolbar
            ref={toolbarRef}
            canSend={isFileDirty || isDirty}
            isEditMode={isEditMode}
            onSend={handleFormSend}
            onCancel={handleEditCancel}
            onToggleRichMode={onToggleRichMode}
            onSelectFiles={onSelectFiles}
            disabledFileUpload={isDisabledFileUpload}
          >
            <>
              {/* エディタ本体 */}
              <BaseLexicalEditor
                ref={lexicalRef}
                showFloatingToolbar={!isRichMode}
                handleChange={onChange}
                onCtrlEnter={onCtrlEnter}
                className={className}
              />
              <MentionsPlugin />
              {uploadedFiles && uploadedFiles.length > 0 && (
                <div className="flex flex-wrap px-4 pb-2 gap-x-2 gap-y-1">
                  {uploadedFiles.map((file) => (
                    <FileLoader
                      key={file.path}
                      src={file}
                      onDetach={() => onDeleteFile(file)}
                    />
                  ))}
                </div>
              )}
            </>
          </CommentToolbar>
        </div>
      </LexicalComposer>
    );
  },
);
export default CommentEditor;
