import { OriginalColorType } from '@/@types/common';
import React, { useMemo } from 'react';

import { classNames, getOriginalColorClass } from '@/libs/styleUtils';

import EllipsisText from '@/components/Common/EllipsisText';

export interface ISelectBadgeProps {
  color: OriginalColorType;
  content: string | null;
  className?: string;
  size?: 'xs' | 'sm';
}

/**
 * セレクト系の表示に使用するバッジ
 * @param props
 * @returns
 */
export function SelectBadge(props: ISelectBadgeProps) {
  const { color, content, className, size } = props;

  const originalColor = useMemo(() => getOriginalColorClass(color), [color]);

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'text-xs px-2 py-0.5';
      case 'sm':
        return 'text-sm px-2.5 py-0.5';
      default:
        return 'px-2.5 py-0.5';
    }
  }, [size]);
  return (
    <span
      className={classNames(
        className,
        originalColor?.bg,
        originalColor?.text,
        sizeClass,
        'inline-flex items-center rounded-md w-full max-w-min',
      )}
    >
      <EllipsisText>{content}</EllipsisText>
    </span>
  );
}
