import { Item, MemberGuestViewItem, RootState } from '@/@types/models';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { SHARING_PERMISSION_TYPES } from '@/libs/const';

import HeartIconBtn from '@/components/Common/Icon/HeartIconBtn/HeartIconBtn';

import useLikedActions from '@/hooks/useLikedActions';
import useLikedItems from '@/hooks/useLikedItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

export interface IViewLiked {
  viewItem: MemberGuestViewItem;
}

/**
 * ビューに表示するいいね
 * @param props
 * @returns
 */
export default function ViewLiked(props: IViewLiked) {
  const { viewItem } = props;
  const { likedItems } = useLikedItems();
  const { isMember } = useMyWorkspaces();

  const { sharingPermission } = useProjects();
  const { createLikedUser, deleteLikedUser } = useLikedActions();

  const [localIsLiked, setLocalIsLiked] = useState<boolean>(false);
  // ローカルで同期的に扱うカウント数のstate
  const [localCountState, setLocalCountState] = useState<
    Pick<Item, 'likeCount'>
  >({
    likeCount: 0,
  });

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const isLiked = useMemo(
    () => likedItems.find((i) => i.itemId === viewItem.id) !== undefined,
    [likedItems, viewItem],
  );

  // いいね状態監視
  useEffect(() => {
    setLocalIsLiked(isLiked);
  }, [isLiked]);

  // いいねクリック時
  const onClickLike = useCallback(async () => {
    if (userId) {
      let likeCount = localCountState.likeCount || 0;
      if (localIsLiked) {
        // いいね削除
        likeCount -= 1;
        setLocalIsLiked(false);
        deleteLikedUser(userId, viewItem.id);
      } else {
        // いいね新規作成
        likeCount += 1;
        setLocalIsLiked(true);
        createLikedUser(userId, viewItem.id);
      }
      // // ローカルのカウント用ステートの更新
      setLocalCountState((old) => ({
        ...old,
        likeCount,
      }));
    }
  }, [
    localIsLiked,
    deleteLikedUser,
    createLikedUser,
    userId,
    localCountState,
    setLocalCountState,
  ]);

  return (
    /* eslint-disable jsx-a11y/interactive-supports-focus */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <HeartIconBtn
      onClick={onClickLike}
      like={localIsLiked}
      disabled={
        !isMember && sharingPermission === SHARING_PERMISSION_TYPES.READ
      }
      size="xs"
    />
  );
}
