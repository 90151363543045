import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { getSubmitState } from '@/libs/utils';

// localStorageに値を保持するイベントを付与する
export default (methods: UseFormReturn<any, any>) => {
  const isDisabledApply = useMemo(
    () => !getSubmitState(methods),
    [
      methods.formState.isDirty,
      methods.formState.isSubmitting,
      methods.formState.isValid,
    ],
  );

  return isDisabledApply;
};
