import { RootState } from '@/@types/models';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';

import {
  routeHandleMyWorkspaceQuery,
  getMyWorkspaceList,
} from '@/libs/repositoryUtils';

import { setCurrentMyWorkspaceIdAction } from '@/reducers/currentWorkspaceReducer';

import { setIsLoaded } from '@/reducers/uiStateReducer';

import SettingSelect, {
  INavigationItem,
} from '@/components/Setting/SettingSelect';

import BasicLayout from '@/pages/layouts/BasicLayout';

import useMembers from '@/hooks/useMembers';

import useMonitorSubscription from '@/hooks/useMonitorSubscription';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

import useViews from '@/hooks/useViews';

/**
 * `/:workspaceId/settings`アクセスのルーティング制御
 * @returns void
 */
function useRouteNavigate() {
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const urlParams = useParams();
  const { workspaceId: originalWorkspaceId } = urlParams;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const resolve = useCallback(async () => {
    if (originalWorkspaceId) {
      // 要求されたWSの存在検証
      const workspaceQuery = routeHandleMyWorkspaceQuery(originalWorkspaceId);
      const myWorkspaceList = await getMyWorkspaceList(userId, workspaceQuery);
      if (myWorkspaceList.length > 0 && myWorkspaceList[0].id) {
        const { workspaceId } = myWorkspaceList[0];
        if (workspaceId) {
          dispatch(setCurrentMyWorkspaceIdAction(workspaceId));
          return dispatch(setIsLoaded(true));
        }
        return navigate('/404');
      }
    }
    return null;
  }, []);

  return {
    resolve,
  };
}

/**
 * `/:workspaceId/settings`アクセスの場合
 */
export default function SettingsIndex() {
  const { t } = useTranslation();
  const { resolve } = useRouteNavigate();
  const { isLoaded } = useSelector((state: RootState) => state.uiState);

  const settingNavigation: INavigationItem[] = [
    {
      key: 'account',
      name: t('アカウント'),
      href: '/',
      auths: ['member', 'guest'],
    },
    {
      key: 'workspace',
      name: t('ワークスペース'),
      href: '/workspace',
      auths: ['member', 'guest'],
    },
    {
      key: 'members',
      name: t('メンバー'),
      href: '/members',
      auths: ['member'],
    },
    {
      key: 'guests',
      name: t('ゲスト'),
      href: '/guests',
      auths: ['member'],
    },
    {
      key: 'plans',
      name: t('プラン'),
      href: '/plans',
      auths: ['member'],
    },
  ];

  useEffect(() => {
    resolve();
  }, []);

  // 必要なSubscriptionの開始をチェック
  const { currentMyWorkspace } = useMyWorkspaces();
  useProjects();
  useViews();
  useMembers();

  const monitorTargets: (keyof RootState['firestore']['ordered'])[] = [
    'myWorkspaces',
    'myProjects',
    'members',
  ];

  // 必要なデータのサブスクリプション状況をキーの有無で検知
  // 動的にストア名を振っているものはanyで対応
  const { isSubscriptionPrepared } = useMonitorSubscription([
    ...monitorTargets,
  ]);

  // 権限によってメニューの可視状態を設定
  const navigation = useMemo(
    () =>
      currentMyWorkspace?.joinType
        ? settingNavigation.filter((o) =>
            o.auths.includes(currentMyWorkspace.joinType || 'unknownGuest'),
          )
        : [],
    [currentMyWorkspace?.joinType],
  );

  // 必要な処理終了後はサブスクリプション & レンダリング
  if (!isLoaded || !currentMyWorkspace || !isSubscriptionPrepared)
    return <div />;
  return (
    <BasicLayout>
      <div className="flex">
        <div className="hidden w-64 px-2 py-5 border-r border-gray-200 dark:border-gray-700 lg:block space-y-8 md:h-screen bg-gray-50 dark:bg-gray-800">
          <nav className="flex-1 px-2 space-y-8" aria-label="Sidebar">
            <div className="space-y-1">
              <h3
                className="px-3 mb-2 text-sm font-semibold tracking-wider text-gray-400 uppercase dark:text-gray-500"
                id="projects-headline"
              >
                {t('設定')}
              </h3>
              <div
                className="space-y-1"
                role="group"
                aria-labelledby="projects-headline"
              >
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={`/${currentMyWorkspace?.originalWorkspaceId}/settings${item.href}`}
                    className="flex items-center px-3 py-2 font-medium text-gray-600 dark:text-gray-300 group rounded-md hover:text-white hover:bg-primary-500 dark:hover:text-white dark:hover:bg-primary-600"
                  >
                    <span className="truncate">{item.name}</span>
                  </Link>
                ))}
              </div>
            </div>
          </nav>
          {/** バージョン表記 */}
          <div className="absolute pl-3 text-xs text-gray-500 select-none bottom-3">
            v{import.meta.env.VITE_APP_VERSION}
          </div>
        </div>
        <div className="flex-1 h-screen pb-16 overflow-y-auto sm:pb-0">
          <div className="sticky top-0 z-10 px-4 mx-auto bg-gray-100 border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700 lg:hidden max-w-7xl">
            <div className="py-4">
              <SettingSelect items={navigation} />
            </div>
          </div>
          <Outlet />
        </div>
      </div>
    </BasicLayout>
  );
}
