import { zodResolver } from '@hookform/resolvers/zod';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import useProfiles from '@/hooks/useProfiles';
import useProjects from '@/hooks/useProjects';

export interface IModifyUnknownGuestNameDialogProps {
  isNameRegistered: boolean;
  onClose: () => void;
}

/**
 * 匿名ゲスト表示名変更ダイアログ
 * @param props
 * @returns
 */
export default function ModifyUnknownGuestNameDialog(
  props: IModifyUnknownGuestNameDialogProps,
) {
  const { t } = useTranslation();
  const { isNameRegistered, onClose } = props;

  const { currentMyProject } = useProjects();
  const { updateDisplayName, profile, updateIsConfigured } = useProfiles();

  // Zod schema定義
  const schema = z.object({
    name: z.string(),
  });

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      name: profile?.displayName,
    },
  });

  // Form Submit
  const onSubmit = methods.handleSubmit(async (data) => {
    const { name } = data;
    updateDisplayName(name);

    // Reset form state
    onClose();
    setTimeout(() => {
      methods.reset({ name: '' });
    }, 300); // トランジションを待機
  });

  // Submitボタン状態
  const isDisabled = !methods.formState.isValid;

  const title = React.useMemo(() => {
    if (!isNameRegistered) {
      return t('<projectName> にようこそ！', {
        projectName: currentMyProject?.projectName,
      });
    }

    return t('表示名変更');
  }, [isNameRegistered]);

  React.useEffect(() => {
    if (!methods.formState.isDirty) {
      if (profile?.displayName) methods.setValue('name', profile.displayName);
    }
  }, [profile]);

  React.useEffect(
    () => () => {
      updateIsConfigured(true);
    },
    [],
  );

  return (
    <FormDialog
      onClose={onClose}
      title={title}
      onSubmit={onSubmit}
      buttonsAlign="left"
    >
      <FormProvider {...methods}>
        <FormCol>
          <Input name="name" label={t('表示名')} addOn={{ right: t('さん') }} />
        </FormCol>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id={
            !isNameRegistered
              ? 'skipAccountDisplayName'
              : 'applyAccountDisplayName'
          }
          disabled={isDisabled}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-auto"
        >
          {!isNameRegistered ? t('さっそく参加する') : t('更新')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
