import { LockClosedIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import { PLANS } from '@/libs/const';

import LinkButton from '@/components/Common/Forms/Buttons/LinkButton';

import usePlans from '@/hooks/usePlans';

export interface IUpgradeTooltipProps {
  id: string;
}

/**
 * アップグレードを促すツールチップ
 * @param props
 * @returns
 */
export default function UpgradeTooltip(props: IUpgradeTooltipProps) {
  const { t } = useTranslation();
  const { id } = props;
  const { plan } = usePlans();
  const { workspaceId: urlWorkspaceId } = useParams();

  return (
    <ReactTooltip
      disable={plan?.type !== PLANS.FREE}
      id={id}
      place="bottom"
      effect="solid"
      clickable
      className="break-all !px-3 !py-1 max-w-[20rem]"
      backgroundColor="#4b5563"
      delayShow={500}
      delayHide={500}
    >
      <div className="py-2">
        {t('プランのアップグレードが必要')}
        <br />
        <div className="mt-3">
          <LinkButton id="gotoPlan" to={`/${urlWorkspaceId}/settings/plans`}>
            <LockClosedIcon className="w-4 h-4" aria-hidden="false" />
            <div className="ml-1 text-right">
              {t('プランをアップグレードする')}
            </div>
          </LinkButton>
        </div>
      </div>
    </ReactTooltip>
  );
}
