import { PropertyTypes } from '@/@types/viewItem';

import React, { ReactNode, useMemo, useContext } from 'react';

import { DEFAULT_LIST_VIEW_COLUMN_WIDTH } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import { columnWidthContext } from '@/components/View/List/ColumnWidthContext';

export interface IListItemTdProps {
  propertyTypes?: PropertyTypes;
  children: ReactNode;
  className?: string;
  sizingKey?: string;
  defaultWidth?: number;
}

/**
 * リストのTD(リサイズ時のレンダリングパフォーマンス最適化のため)
 * @param props
 * @returns
 */
export default function ListItemTd(props: IListItemTdProps) {
  const { sizingKey, defaultWidth, propertyTypes, children, className } = props;
  const columnWidth = useContext(columnWidthContext);

  const propertyId = useMemo(
    () => sizingKey || (propertyTypes?.id as string),
    [sizingKey, propertyTypes],
  );
  const colWidth = useMemo(
    () =>
      columnWidth[propertyId]?.columnWidth ||
      defaultWidth ||
      DEFAULT_LIST_VIEW_COLUMN_WIDTH,
    [columnWidth, propertyId, defaultWidth],
  );

  return (
    <td
      className={classNames(className)}
      style={{ width: colWidth, maxWidth: colWidth, minWidth: colWidth }}
    >
      {children}
    </td>
  );
}
