import React from 'react';

import ErrorPage from '@/pages/layouts/ErrorPage';

export default function Error403() {
  return (
    <ErrorPage
      code={403}
      title="Forbidden"
      description="You don't have permission to access on this page."
    />
  );
}
