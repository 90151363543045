import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
// eslint-disable-next-line camelcase
import { unstable_convertLegacyJSONEditorState } from '@lexical/utils';

import * as React from 'react';

import {} from '@lexical/link';

/**
 * LexicalのEditorStateを更新するためだけのPlugin
 * Contextにアクセスできるよう 本体とは別の子コンポーネントとしている
 */
export type IUpdateStatePluginHandler = {
  updateState: (state: string) => void;
};

type IUpdateStatePluginProps = React.ComponentPropsWithoutRef<'div'> & {};

interface IState {
  _nodeMap: any;
}

const typeCheckState = (obj: unknown): obj is IState => {
  const objState = obj as IState;
  return '_nodeMap' in objState;
};

const UpdateStatePlugin = React.forwardRef<
  IUpdateStatePluginHandler,
  IUpdateStatePluginProps
>((props, ref) => {
  const [editor] = useLexicalComposerContext();

  React.useImperativeHandle(ref, () => ({
    updateState: async (state: string) => {
      const objState = JSON.parse(state);
      let st;
      if (typeCheckState(objState)) {
        // 旧バージョンのデータ置換→パース処理
        st = unstable_convertLegacyJSONEditorState(editor, state);
      } else {
        st = editor.parseEditorState(state);
      }
      editor.setEditorState(st);
    },
  }));
  return <div />;
});
export default UpdateStatePlugin;
