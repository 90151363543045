import { IModalDialogProps } from '@/@types/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmDialog from './ConfirmDialog';

export interface IContactDialogProps extends IModalDialogProps {}

/**
 * 問い合わせダイアログ
 * @param props
 * @returns
 */
export default function ContactDialog(props: IContactDialogProps) {
  const { t } = useTranslation();
  const { onClose } = props;

  return (
    <ConfirmDialog
      open
      key="contact-dialog"
      title={t('問い合わせ')}
      positive={t('OK')}
      positiveOnly
      onClose={() => {
        onClose();
      }}
    >
      <p className="mb-5">
        {t(
          'collabbleに関してご質問やお困りごとなどお問い合わせが必要な場合は、以下の連絡先までお問い合わせください。',
        )}
      </p>
      <p>{t('[問い合わせ先]')}</p>
      <p className="mb-5">
        {t('collabbleサポート担当')}
        <a href="mailto:information@collabble.jp" className="ml-2 link">
          information@collabble.jp
        </a>
      </p>
      <p>
        {t(
          '操作に関するお問い合わせについては、<link>のご用意もありますので、合わせてご確認ください。',
          {
            link: (
              <a
                href="https://lp.collabble.jp/user/help"
                target="_blank"
                rel="noopener noreferrer"
                className="link"
              >
                {t('操作ガイド')}
              </a>
            ),
          },
        )}
      </p>
    </ConfirmDialog>
  );
}
