import { RootState } from '@/@types/models';
import { Transition } from '@headlessui/react';
import {
  CodeBracketIcon,
  CommandLineIcon,
  Bars3CenterLeftIcon,
  PhotoIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import { $getRoot, FORMAT_TEXT_COMMAND } from 'lexical';
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useMemo,
  useRef,
  useImperativeHandle,
  useEffect,
  useState,
  useCallback,
} from 'react';

import { useDrop, DropTargetMonitor } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { FILE_COUNT_LIMIT } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import { convertPath2URL } from '@/libs/utils';

import { closeImageDialog, openImageDialog } from '@/reducers/itemsReducer';

import IconBtn from '@/components/Common/Icon/IconBtn';
import {
  BoldOutlineIcon,
  ItalicOutlineIcon,
  MarkerOutlineIcon,
  StrikethroughOutlineIcon,
  UnderlineOutlineIcon,
  HorizontalIcon,
  BulletListIcon,
  NumberedListIcon,
} from '@/components/Common/Icon/Icons';
import ImageEditorDialog from '@/components/Common/Lexical/plugins/ImageEditorDialog';
import useLexicalMonitor from '@/components/Common/Lexical/utils/useLexicalMonitor';

import useTempFiles from '@/hooks/useTempFiles';

import { getNodeByType } from '../../utils/lexicalUtils';

interface IDividerProps {
  className: string;
}
function Divider(props: IDividerProps) {
  const { className } = props;
  const composedClass = useMemo(
    () => classNames(className, 'w-px bg-gray-200 dark:bg-gray-600'),
    [className],
  );
  return <div className={composedClass} />;
}

export interface IToolbarHandler {
  save(): { jsonState: string; textState: string };
}

type IToolbarProps = ComponentPropsWithoutRef<'div'> & {
  children: React.ReactChild;
};

const Toolbar = forwardRef<IToolbarHandler, IToolbarProps>(
  (props: IToolbarProps, ref) => {
    const { t } = useTranslation();
    const toolbarRef = useRef(null);
    const { children } = props;
    const [editor] = useLexicalComposerContext();
    const dispatch = useDispatch();
    const [isImageUploadLimit, setIsUploadImageLimit] = useState(false);
    const { isImageOpen } = useSelector((state: RootState) => state.items);

    // Lexicalの状態監視用
    const {
      isBold,
      isItalic,
      isUnderline,
      isStrikethrough,
      isQuote,
      isCodeBlock,
      isMarker,
      formatQuote,
      formatCodeBlock,
      formatMarker,
      blockType,
      formatParagraph,
      formatHeading,
      formatBulletList,
      formatNumberedList,
      insertImage,
    } = useLexicalMonitor(editor);

    const handleSave = React.useCallback(() => {
      const editorState = editor.getEditorState();
      const jsonState = JSON.stringify(editorState.toJSON());

      let textState;
      editorState.read(() => {
        const root = $getRoot();
        textState = root.getTextContent();
      });
      return { jsonState, textState };
    }, []);

    /**
     * 画像枚数の上限チェック
     */
    const checkImageLength = () => {
      const es = editor.getEditorState();
      const str = JSON.stringify(es);
      const imageNodes = getNodeByType(str, 'image');
      if (imageNodes.length >= FILE_COUNT_LIMIT) {
        setIsUploadImageLimit(true);
      } else {
        setIsUploadImageLimit(false);
      }
    };

    /**
     * マウント時に画像の上限判定をしておく
     */
    useEffect(() => {
      checkImageLength();
    }, []);

    /**
     * 画像挿入ダイアログを開く
     */
    const openImagePicker = React.useCallback(() => {
      checkImageLength();
      dispatch(openImageDialog());
      editor.focus(); // NOTE:エディタにフォーカスを当てないと、画像挿入時にエラーとなる
    }, []);

    /**
     * 画像挿入処理
     */
    const handleInsertImage = React.useCallback(({ altText, src, path }) => {
      insertImage({ altText, src, path });
    }, []);

    // 外部コンポーネントから命令的に実行できるメソッド
    useImperativeHandle(ref, () => ({
      save(): any {
        return handleSave();
      },
    }));

    const iconCommonClass = 'text-gray-400 w-5 h-5';

    const { handleSelectFiles, isSelectFileLoading, uploadedFiles } =
      useTempFiles();

    /**
     * ドロップハンドラー
     */
    const handleDrop = useCallback(
      async (item: { files: File[] }) => {
        const response = await handleSelectFiles(Array.from(item.files));
        for (let i = 0; i < response.length; i += 1) {
          const { path } = response[i];
          // eslint-disable-next-line no-await-in-loop
          const src = await convertPath2URL(path);
          insertImage({ altText: '', src, path });
        }
      },
      [handleSelectFiles],
    );

    /**
     * ドロップ可能性判定
     */
    const handleCanDrop = useCallback(
      (item: any) => {
        const IMG_TYPES = ['image/gif', 'image/jpeg', 'image/png'] as const;
        const es = editor.getEditorState();
        const str = JSON.stringify(es);
        const imageNodes = getNodeByType(str, 'image');

        const itemLength = item.items.length;

        // item.itemsの各ファイル情報はkey-valueのオブジェクト形式で格納されている
        const fileTypes = Object.values(item.items).map((o: any) => o.type);
        const isImage = fileTypes.every((o) => IMG_TYPES.includes(o));

        return (
          itemLength !== 0 &&
          isImage &&
          !isSelectFileLoading &&
          imageNodes.length + itemLength <= FILE_COUNT_LIMIT
        );
      },
      [uploadedFiles, isSelectFileLoading, editor],
    );

    /** D&D ドロップHooks */
    const [{ canDrop, isOver }, drop] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        drop: handleDrop,
        canDrop: handleCanDrop,
        collect: (monitor: DropTargetMonitor) => ({
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }),
      }),
      [handleDrop, handleCanDrop],
    );

    /** D&Dがアクティブ */
    const isDaDActive = useMemo(() => canDrop && isOver, [canDrop, isOver]);
    /** D&Dでアクティブだがファイル数オーバー */
    const isDaDLimit = useMemo(() => !canDrop && isOver, [canDrop, isOver]);

    return (
      <div ref={drop} className="relative h-full">
        {isDaDActive && (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center px-3 py-2 border rounded-md text-primary-600 bg-dad-active dark:text-primary-600 gap-1 dark:ring-primary-600 ring-primary-500 border-primary-500 dark:border-primary-600">
            <PlusIcon className="w-4 h-4" aria-hidden="true" />
            {t('画像ファイルをドロップして追加')}
          </div>
        )}
        {isDaDLimit && (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center px-3 py-2 border border-red-400 gap-1 bg-dad-disabled rounded-md dark:border-red-800">
            {t('画像ファイルを追加できません')}
          </div>
        )}
        <div ref={toolbarRef} className="flex-col h-full">
          {/* ツールバー上部 */}
          <div className="sticky top-0 z-10 flex flex-wrap w-full p-2 mr-auto bg-white space-x-1 dark:bg-gray-800">
            {/* ボールド */}
            <IconBtn
              onClick={() =>
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
              }
              tooltipMessage={t('太字')}
              active={isBold}
            >
              <BoldOutlineIcon className={iconCommonClass} />
            </IconBtn>

            {/* イタリック */}
            <IconBtn
              onClick={() =>
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
              }
              tooltipMessage={t('斜体')}
              active={isItalic}
            >
              <ItalicOutlineIcon className={iconCommonClass} />
            </IconBtn>

            {/* アンダーライン */}
            <IconBtn
              onClick={() =>
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
              }
              tooltipMessage={t('下線')}
              active={isUnderline}
            >
              <UnderlineOutlineIcon className={iconCommonClass} />
            </IconBtn>

            {/* 打消し */}
            <IconBtn
              onClick={() =>
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
              }
              tooltipMessage={t('打消し線')}
              active={isStrikethrough}
            >
              <StrikethroughOutlineIcon className={iconCommonClass} />
            </IconBtn>

            <Divider className="mx-2 h-7" />

            {/* 引用 他に引用らしいアイコンが見つからない */}
            <IconBtn
              onClick={formatQuote}
              tooltipMessage={t('引用')}
              active={isQuote}
            >
              <CommandLineIcon className={iconCommonClass} />
            </IconBtn>

            {/* コードブロック */}
            <IconBtn
              onClick={formatCodeBlock}
              tooltipMessage={t('コードブロック')}
              active={isCodeBlock}
            >
              <CodeBracketIcon className={iconCommonClass} />
            </IconBtn>

            {/* マーカー */}
            <IconBtn
              onClick={formatMarker}
              tooltipMessage={t('マーカー')}
              active={isMarker}
            >
              <MarkerOutlineIcon className={iconCommonClass} />
            </IconBtn>

            <Divider className="mx-2 h-7" />

            {/* 水平線 */}
            <IconBtn
              onClick={() => {
                editor.dispatchCommand(
                  INSERT_HORIZONTAL_RULE_COMMAND,
                  undefined,
                );
              }}
              tooltipMessage={t('水平線')}
            >
              <HorizontalIcon className={iconCommonClass} />
            </IconBtn>

            <Divider className="mx-2 h-7" />

            {/* 通常フォント */}
            <IconBtn
              onClick={formatParagraph}
              tooltipMessage={t('Normal')}
              active={blockType === 'paragraph'}
            >
              <Bars3CenterLeftIcon className={iconCommonClass} />
            </IconBtn>

            {/* Heading */}
            {/* TODO:アイコン要検討 */}
            <IconBtn
              onClick={() => formatHeading('h1')}
              tooltipMessage={t('H1')}
              active={blockType === 'h1'}
            >
              <span className={classNames(iconCommonClass)}>H1</span>
            </IconBtn>
            <IconBtn
              onClick={() => formatHeading('h2')}
              tooltipMessage={t('H2')}
              active={blockType === 'h2'}
            >
              <span className={classNames(iconCommonClass)}>H2</span>
            </IconBtn>
            <IconBtn
              onClick={() => formatHeading('h3')}
              tooltipMessage={t('H3')}
              active={blockType === 'h3'}
            >
              <span className={classNames(iconCommonClass)}>H3</span>
            </IconBtn>
            <IconBtn
              onClick={() => formatHeading('h4')}
              tooltipMessage={t('H4')}
              active={blockType === 'h4'}
            >
              <span className={classNames(iconCommonClass)}>H4</span>
            </IconBtn>
            <IconBtn
              onClick={() => formatHeading('h5')}
              tooltipMessage={t('H5')}
              active={blockType === 'h5'}
            >
              <span className={classNames(iconCommonClass)}>H5</span>
            </IconBtn>

            <Divider className="mx-2 h-7" />

            {/* リスト */}
            {/* 箇条書きリスト */}
            <IconBtn
              onClick={() => formatBulletList()}
              tooltipMessage={t('箇条書きリスト')}
              active={blockType === 'bullet'}
            >
              <BulletListIcon className={iconCommonClass} />
            </IconBtn>

            {/* 数字付きリスト */}
            <IconBtn
              onClick={() => formatNumberedList()}
              tooltipMessage={t('数字付きリスト')}
              active={blockType === 'number'}
            >
              <NumberedListIcon className={iconCommonClass} />
            </IconBtn>
            <Divider className="mx-2 h-7" />

            {/* 画像 */}
            <IconBtn onClick={openImagePicker} tooltipMessage={t('画像')}>
              <PhotoIcon className={iconCommonClass} />
            </IconBtn>
          </div>

          {/* エディターコンテンツ */}
          {children}
          {/* 画像編集ダイアログ */}
          <Transition.Root show={isImageOpen} unmount>
            <ImageEditorDialog
              isUploadLimit={isImageUploadLimit}
              onSubmit={handleInsertImage}
              onClose={() => dispatch(closeImageDialog())}
            />
          </Transition.Root>
        </div>
      </div>
    );
  },
);
export default Toolbar;
