import {
  IFuncCheckEmailRequest,
  IFuncCheckEmailResult,
  IFuncCheckUnauthorizedAccountAccessRequest,
  IFuncCheckUnauthorizedAccountAccessResult,
  FunctionsResult,
  IFuncCheckReCaptcha,
  IFuncCheckInvitingMemberExistenceRequest,
  IFuncCheckInvitingMemberExistenceResult,
  IFuncCheckInvitingGuestExistenceRequest,
  IFuncCheckInvitingGuestExistenceResult,
  IFuncCheckAccountDeletableResult,
  IFuncInviteGuestRequest,
} from '@/@types/common';
import { Account } from '@/@types/models';
import { deleteUser, signInAnonymously } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';

import i18next from 'i18next';

import { z } from 'zod';

import { AVAILABLE_LANGUAGES, REP_ERROR_MESSAGE } from '@/libs/const';
import { getAccountsPath } from '@/libs/docPathUtils';
import { repositoryError } from '@/libs/utils';

import { functions, auth } from '@/firebase';
import { firestore } from '@/firestore';

export interface IAccountRepository {
  findById: (uid: Account['uid']) => Promise<Account | null>;
}

const funcCheckEmailExistence = httpsCallable<
  IFuncCheckEmailRequest,
  IFuncCheckEmailResult
>(functions, 'checkEmailExistence');

const funcCheckRecaptcha = httpsCallable<IFuncCheckReCaptcha, FunctionsResult>(
  functions,
  'checkReCaptcha',
);

const funcCheckUnauthorizedAccountAccess = httpsCallable<
  IFuncCheckUnauthorizedAccountAccessRequest,
  IFuncCheckUnauthorizedAccountAccessResult
>(functions, 'checkUnauthorizedAccountAccess');

const funcCheckInvitingMemberExistence = httpsCallable<
  IFuncCheckInvitingMemberExistenceRequest,
  IFuncCheckInvitingMemberExistenceResult
>(functions, 'checkInvitingMemberExistence');

const funcJoinAsMember = httpsCallable<
  IFuncCheckInvitingMemberExistenceRequest,
  FunctionsResult
>(functions, 'joinAsMember');

const funcCheckInvitingGuestExistence = httpsCallable<
  IFuncCheckInvitingGuestExistenceRequest,
  IFuncCheckInvitingGuestExistenceResult
>(functions, 'checkInvitingGuestExistence');

const funcJoinAsGuest = httpsCallable<IFuncInviteGuestRequest, FunctionsResult>(
  functions,
  'joinAsGuest',
);
const funcDeleteAccount = httpsCallable<undefined, FunctionsResult>(
  functions,
  'deleteAccount',
);

const funcCheckAccountDeletable = httpsCallable<
  undefined,
  IFuncCheckAccountDeletableResult
>(functions, 'checkAccountDeletable');

export default class AccountRepository implements IAccountRepository {
  /**
   * Find an account by uid
   * @param {Account['uid']} uid
   * @returns Promise<Account | null>
   */
  async findById(uid: Account['uid']): Promise<Account | null> {
    try {
      const docRef = doc(firestore, getAccountsPath(), uid as string);
      const data = (await getDoc(docRef)).data();
      if (data === undefined) {
        return null;
      }

      const account: Account = {
        uid,
        displayName: data.displayName,
        email: data.email,
        photoURL: data.photoURL,
      };

      return account;
    } catch (err: any) {
      repositoryError(this.findById.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * Eメールアドレスの存在確認
   * @param email
   * @returns CheckEmailResult
   */
  async checkEmailExistence(email: string, token: string) {
    const res = await funcCheckEmailExistence({
      email,
      token,
    });
    return res;
  }

  /**
   * reCAPTCHA正当性チェック
   * @param token
   * @returns
   */
  async checkReCaptcha(token: string) {
    const res = await funcCheckRecaptcha({
      token,
    });
    return res;
  }

  /**
   * 非認証ユーザーの正当性検証
   * @param workspaceId
   * @param projectId
   * @param viewId
   * @returns
   */
  async checkUnauthorizedAccountAccess(
    originalWorkspaceId: string,
    projectId: string,
    viewId?: string,
  ) {
    const schema = z.object({
      originalWorkspaceId: z.string().trim().min(1),
      projectId: z.string().trim().min(1),
      viewId: z.string().trim().min(10).optional(),
      language: z.nativeEnum(AVAILABLE_LANGUAGES),
    });

    const anonymousUser = await signInAnonymously(auth);
    try {
      const { uid } = anonymousUser.user;
      const parsed = schema.parse({
        uid,
        originalWorkspaceId,
        projectId,
        viewId,
        language: i18next.resolvedLanguage,
      });
      const res = await funcCheckUnauthorizedAccountAccess(parsed);
      const { data } = res;
      return data;
    } catch (e) {
      // onCall実行エラーの場合 匿名ユーザーを削除する
      await deleteUser(anonymousUser.user);
      return false;
    }
  }

  /**
   * メンバー招待データの存在確認
   * @param originalWorkspaceId
   * @param invitingMemberId
   * @returns FunctionsResult
   */
  async checkInvitingMemberExistence(
    originalWorkspaceId: string,
    invitingMemberId: string,
  ) {
    const res = await funcCheckInvitingMemberExistence({
      originalWorkspaceId,
      invitingMemberId,
    });
    const { data } = res;
    return data;
  }

  /**
   * メンバー参加処理
   * @param originalWorkspaceId
   * @param invitingMemberId
   * @returns FunctionsResult
   */
  async joinAsMember(originalWorkspaceId: string, invitingMemberId: string) {
    const res = await funcJoinAsMember({
      originalWorkspaceId,
      invitingMemberId,
    });
    const { data } = res;
    return data;
  }

  /**
   * ゲスト招待データの存在確認
   * @param originalWorkspaceId
   * @param invitingGuestId
   * @returns FunctionsResult
   */
  async checkInvitingGuestExistence(
    originalWorkspaceId: string,
    invitingGuestId: string,
  ) {
    const res = await funcCheckInvitingGuestExistence({
      originalWorkspaceId,
      invitingGuestId,
    });
    const { data } = res;
    return data;
  }

  /**
   * ゲスト参加処理
   * @param originalWorkspaceId
   * @param invitingGuestId
   * @returns FunctionsResult
   */
  async joinAsGuest(originalWorkspaceId: string, invitingGuestId: string) {
    const res = await funcJoinAsGuest({
      originalWorkspaceId,
      invitingGuestId,
    });
    const { data } = res;
    return data;
  }

  /**
   * アカウント削除処理
   * @returns FunctionsResult
   */
  async deleteAccount() {
    const res = await funcDeleteAccount();
    const { data } = res;
    return data;
  }

  /**
   * 契約中のワークスペースIDを取得
   * @returns IFuncCheckAccountDeletableResult
   */
  async checkAccountDeletable() {
    const res = await funcCheckAccountDeletable();
    const { data } = res;
    return data;
  }
}
