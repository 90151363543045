import { MemberGuestViewItem } from '@/@types/models';
import {
  ChatBubbleLeftEllipsisIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';
import React, { useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';

import { classNames } from '@/libs/styleUtils';

import { commarize, getNavigatePath } from '@/libs/utils';

import { openItemEditDialog, setCurrentItemId } from '@/reducers/itemsReducer';

import EllipsisText from '@/components/Common/EllipsisText';
import { isSelectPublishItemModeContext } from '@/components/Share/SelectPublishItemsContext';
import ViewLiked from '@/components/View/ViewLiked';

import useAccessSettings from '@/hooks/useAccessSettings';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useViewItems from '@/hooks/useViewItems';

export interface IBillboardCardRowProps {
  viewItem: MemberGuestViewItem;
}

/**
 * 掲示板ビューのカードのリスト
 * @param props
 * @returns
 */
export default function BillboardCardRow(props: IBillboardCardRowProps) {
  const { viewItem } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { workspaceId, projectId, viewId } = useParams();
  const { isMember } = useMyWorkspaces();
  const { isDisplayComment, isDisplayLiked } = useAccessSettings();
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);
  const { getHighlightClass } = useViewItems();

  /**
   * アイテムクリック時ハンドラ
   */
  const handleItemClick = (itemId: string) => {
    if (isSelectPublishItemMode) return;
    navigate(
      getNavigatePath(workspaceId as string, projectId, viewId, itemId),
      {
        replace: true,
      },
    );
    dispatch(setCurrentItemId(itemId));
    dispatch(openItemEditDialog(itemId));
  };

  const highlightClass = useMemo(
    () =>
      getHighlightClass(
        viewItem,
        'dark:bg-originalDueWarning bg-originalDueWarning-light',
        'dark:bg-originalDueDanger bg-originalDueDanger-light',
      ),
    [getHighlightClass, viewItem],
  );

  return (
    /* eslint-disable jsx-a11y/interactive-supports-focus */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <div
      key={viewItem.id}
      className="hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md"
    >
      <div className="relative flex flex-row items-center px-1">
        <div
          className={classNames(
            'absolute bottom-0 top-0 -left-1.5 w-1 rounded-full',
            highlightClass,
          )}
        />
        <div
          className={classNames(
            'w-full p-2 text-left',
            isSelectPublishItemMode ? 'cursor-default' : '',
          )}
          role="button"
          onClick={() => handleItemClick(viewItem.id as string)}
        >
          <div className="grid 2xl:grid-cols-12 grid-cols-12">
            <EllipsisText className="dark:opacity-70 dark:text-white 2xl:col-span-10 col-span-9">
              {viewItem.title}
            </EllipsisText>
            <div className="flex justify-end font-medium text-gray-400 2xl:col-span-2 space-x-3 col-span-3">
              {isDisplayLiked && (
                <div className="flex items-center">
                  <ViewLiked viewItem={viewItem} />
                  <span className="-ml-1 font-mono text-sm leading-none">
                    {commarize(viewItem.likeCount ?? 0)}
                  </span>
                </div>
              )}
              {isDisplayComment && (
                <div className="flex items-center grow">
                  <ChatBubbleLeftEllipsisIcon
                    className="w-4 h-4"
                    aria-hidden="true"
                  />
                  <span className="ml-1 font-mono text-sm leading-none">
                    {commarize(viewItem.commentCount ?? 0)}
                  </span>
                </div>
              )}
              {!isSelectPublishItemMode &&
                isMember &&
                viewItem.isShare === false && (
                  <div className="flex items-center mr-1">
                    <EyeSlashIcon className="w-4 h-4" aria-hidden="true" />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
