import { zodResolver } from '@hookform/resolvers/zod';
import { DefaultTFuncReturn } from 'i18next';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import {
  FUNCTIONS_ERROR_DETAILS,
  RE_CAPTCHA_ACTIONS,
  VALIDATION_MODE,
} from '@/libs/const';

import { t2s } from '@/libs/utils';

import AccountRepository from '@/repositories/AccountRepository';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import Form from '@/components/Common/Forms/Form';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import useFirebaseAuth from '@/hooks/useFirebaseAuth';
import useLogin from '@/hooks/useLogin';
import useReCaptcha from '@/hooks/useReCaptcha';
import useSubmitState from '@/hooks/useSubmitState';

export interface IEmailPasswordLoginFormProps {
  email: string;
}

/**
 * Eメール / パスワード ログインフォーム
 * @param props
 * @returns
 */
export default function EmailPasswordLoginForm(
  props: IEmailPasswordLoginFormProps,
) {
  const { t } = useTranslation();
  const { email } = props;
  const { loginWithEmailPassword } = useFirebaseAuth();
  const login = useLogin();
  const [error, setError] = useState<string | DefaultTFuncReturn | null>(null);
  const { refreshToken, reCaptchaToken } = useReCaptcha(
    RE_CAPTCHA_ACTIONS.PASSWORD_LOGIN,
  );
  const accountRepository = new AccountRepository();

  // Zod schema定義
  const schema = z.object({
    email: z
      .string()
      .min(1, { message: t('入力してください') })
      .email(t2s(t('正しいメールアドレスを入力してください'))),
    password: z.string().min(1, { message: t('入力してください') }),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    setError(null);
  }, [methods.formState.isValid]);

  useEffect(() => {
    methods.setValue('email', email);
  }, [email]);

  const onPasswordLoginClick = async (
    loginEmail: string,
    loginPassword: string,
  ) => {
    await login(async () => {
      await loginWithEmailPassword(loginEmail, loginPassword);
    });
  };

  const formLoginSubmit = methods.handleSubmit(async (data) => {
    setError(null);
    try {
      await accountRepository.checkReCaptcha(reCaptchaToken || '');
    } catch (e: any) {
      if (e.details === FUNCTIONS_ERROR_DETAILS.RECAPTCHA_ERROR) {
        toast.error(
          t(
            '一定時間経過などを理由にBOTとして判断されたためログインできませんでした。ページを再読み込みの上、再度ログインまたはアカウント登録をお試しください。',
          ),
        );
        return;
      }
      toast.error(t('Bot判定に失敗しました'));
      throw e;
    }
    try {
      await onPasswordLoginClick(methods.getValues().email, data.password);
    } catch (e: any) {
      if (e.code === 'auth/wrong-password') {
        // パスワード誤り
        setError(t('パスワードに誤りがあります'));
        refreshToken();
      } else {
        // その他のエラー
        toast.error(t('認証に失敗しました'));
        refreshToken();
        throw e;
      }
    }
  });

  // Submitボタン状態
  const isDisabledSubmit = useSubmitState(methods) || !reCaptchaToken;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={formLoginSubmit}>
        <FormCol>
          <Input name="email" label={t('メールアドレス')} />
        </FormCol>
        <FormCol>
          <Input
            autoFocus
            password
            name="password"
            label={t('パスワード')}
            error={error}
          />
        </FormCol>
        <FormCol className="text-center">
          <NavLink
            className="text-primary-600 hover:text-primary-700 dark:text-primary-500 hover:dark:text-primary-600"
            to={`/login/passwordReset?m=${email}`}
          >
            {t('パスワードをお忘れですか？')}
          </NavLink>
        </FormCol>
        <div className="relative sm:col-span-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-100 dark:border-gray-700" />
          </div>
        </div>
        <FormCol>
          <FormButton
            id="loginEmail"
            className="sm:w-full"
            submit
            variant="primary"
            disabled={isDisabledSubmit}
          >
            {t('ログイン')}
          </FormButton>
        </FormCol>
      </Form>
    </FormProvider>
  );
}
