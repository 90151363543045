import { TailwindColorType } from '@/@types/common';
import React, { useMemo } from 'react';

import { classNames } from '@/libs/styleUtils';

export interface IAlertProps {
  color: TailwindColorType;
  children: React.ReactNode;
  className?: string;
  icon?: any;
}

export interface IAlertColor {
  bg: string;
  iconText: string;
  text: string;
}

/**
 * アラート
 * @param props
 * @returns
 */
export default function Alert(props: IAlertProps) {
  const { color, children, className, icon } = props;
  const Icon = icon;

  const colorClass: IAlertColor = useMemo<IAlertColor>((): IAlertColor => {
    switch (color) {
      case 'primary':
        return {
          bg: 'bg-primary-100 dark:bg-primary-900',
          iconText: 'text-primary-400 dark:text-primary-300',
          text: 'text-primary-800 dark:text-primary-100',
        };

      case 'slate':
        return {
          bg: 'bg-slate-100 dark:bg-slate-900',
          iconText: 'text-slate-400 dark:text-slate-300',
          text: 'text-slate-800 dark:text-slate-100',
        };

      case 'gray':
        return {
          bg: 'bg-gray-100 dark:bg-gray-900',
          iconText: 'text-gray-400 dark:text-gray-300',
          text: 'text-gray-800 dark:text-gray-100',
        };

      case 'zinc':
        return {
          bg: 'bg-zinc-100 dark:bg-zinc-900',
          iconText: 'text-zinc-400 dark:text-zinc-300',
          text: 'text-zinc-800 dark:text-zinc-100',
        };

      case 'neutral':
        return {
          bg: 'bg-neutral-100 dark:bg-neutral-900',
          iconText: 'text-neutral-400 dark:text-neutral-300',
          text: 'text-neutral-800 dark:text-neutral-100',
        };

      case 'stone':
        return {
          bg: 'bg-stone-100 dark:bg-stone-900',
          iconText: 'text-stone-400 dark:text-stone-300',
          text: 'text-stone-800 dark:text-stone-100',
        };

      case 'red':
        return {
          bg: 'bg-red-100 dark:bg-red-900',
          iconText: 'text-red-400 dark:text-red-300',
          text: 'text-red-800 dark:text-red-100',
        };

      case 'orange':
        return {
          bg: 'bg-orange-100 dark:bg-orange-900',
          iconText: 'text-orange-400 dark:text-orange-300',
          text: 'text-orange-800 dark:text-orange-100',
        };

      case 'amber':
        return {
          bg: 'bg-amber-100 dark:bg-amber-900',
          iconText: 'text-amber-400 dark:text-amber-300',
          text: 'text-amber-800 dark:text-amber-100',
        };

      case 'yellow':
        return {
          bg: 'bg-yellow-100 dark:bg-yellow-900',
          iconText: 'text-yellow-400 dark:text-yellow-300',
          text: 'text-yellow-800 dark:text-yellow-100',
        };

      case 'lime':
        return {
          bg: 'bg-lime-100 dark:bg-lime-900',
          iconText: 'text-lime-400 dark:text-lime-300',
          text: 'text-lime-800 dark:text-lime-100',
        };

      case 'green':
        return {
          bg: 'bg-green-100 dark:bg-green-900',
          iconText: 'text-green-400 dark:text-green-300',
          text: 'text-green-800 dark:text-green-100',
        };

      case 'emerald':
        return {
          bg: 'bg-emerald-100 dark:bg-emerald-900',
          iconText: 'text-emerald-400 dark:text-emerald-300',
          text: 'text-emerald-800 dark:text-emerald-100',
        };

      case 'teal':
        return {
          bg: 'bg-teal-100 dark:bg-teal-900',
          iconText: 'text-teal-400 dark:text-teal-300',
          text: 'text-teal-800 dark:text-teal-100',
        };

      case 'cyan':
        return {
          bg: 'bg-cyan-100 dark:bg-cyan-900',
          iconText: 'text-cyan-400 dark:text-cyan-300',
          text: 'text-cyan-800 dark:text-cyan-100',
        };

      case 'sky':
        return {
          bg: 'bg-sky-100 dark:bg-sky-900',
          iconText: 'text-sky-400 dark:text-sky-300',
          text: 'text-sky-800 dark:text-sky-100',
        };

      case 'blue':
        return {
          bg: 'bg-blue-100 dark:bg-blue-900',
          iconText: 'text-blue-400 dark:text-blue-300',
          text: 'text-blue-800 dark:text-blue-100',
        };

      case 'indigo':
        return {
          bg: 'bg-indigo-100 dark:bg-indigo-900',
          iconText: 'text-indigo-400 dark:text-indigo-300',
          text: 'text-indigo-800 dark:text-indigo-100',
        };

      case 'violet':
        return {
          bg: 'bg-violet-100 dark:bg-violet-900',
          iconText: 'text-violet-400 dark:text-violet-300',
          text: 'text-violet-800 dark:text-violet-100',
        };

      case 'purple':
        return {
          bg: 'bg-purple-100 dark:bg-purple-900',
          iconText: 'text-purple-400 dark:text-purple-300',
          text: 'text-purple-800 dark:text-purple-100',
        };

      case 'fuchsia':
        return {
          bg: 'bg-fuchsia-100 dark:bg-fuchsia-900',
          iconText: 'text-fuchsia-400 dark:text-fuchsia-300',
          text: 'text-fuchsia-800 dark:text-fuchsia-100',
        };

      case 'pink':
        return {
          bg: 'bg-pink-100 dark:bg-pink-900',
          iconText: 'text-pink-400 dark:text-pink-300',
          text: 'text-pink-800 dark:text-pink-100',
        };

      case 'rose':
        return {
          bg: 'bg-rose-100 dark:bg-rose-900',
          iconText: 'text-rose-400 dark:text-rose-300',
          text: 'text-rose-800 dark:text-rose-100',
        };
      default:
        return {
          bg: '',
          iconText: '',
          text: '',
        };
    }
  }, [color]);

  return (
    <div className={classNames(className, colorClass.bg, 'p-4 rounded-md')}>
      <div className="flex">
        {icon && (
          <div className="flex-shrink-0">
            <Icon
              className={classNames(colorClass.iconText, 'w-4 h-4 mt-0.5 mr-3')}
              aria-hidden="true"
            />
          </div>
        )}
        <div className={classNames(colorClass.text)}>{children}</div>
      </div>
    </div>
  );
}
