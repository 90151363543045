import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import ColorSingleSelect from '@/components/Common/Forms/ColorSingleSelect';

import { IItemFormDefaultValues } from '@/hooks/useEditItem';

export interface IPropertySingleSelectProps {
  idx: number;
  prependEmpty?: boolean;
}

/**
 * プロパティシングルセレクト型の入力コンポーネント
 * @param props
 * @returns
 */
function PropertySingleSelect(props: IPropertySingleSelectProps) {
  const { idx, prependEmpty } = props;

  // 提供されるFormProviderのコンテキストを参照
  const { getValues, setValue, trigger } =
    useFormContext<IItemFormDefaultValues>();

  // プロパティ値
  const fields = getValues('properties');
  const property = useMemo(() => fields[idx], [fields[idx], idx]);

  // 選択肢一覧
  const options = useMemo(() => property?.options || [], [property]);

  // 選択済み
  const selected = useMemo(
    () => options.find((option) => property.stringValue === option.id),
    [property.stringValue, options],
  );

  // 値更新(配列の場合などで処理を分ける必要あり)
  const onSelect = useCallback(
    (value: string) => {
      const values = getValues('properties');
      const input = value ?? '';

      // キー指定してvalue値を更新
      values[idx].stringValue = input;
      setValue(`properties.${idx}`, values[idx], {
        shouldDirty: true,
        shouldTouch: true,
      });
      trigger();
    },
    [idx],
  );

  return (
    <ColorSingleSelect
      selected={selected}
      options={options}
      prependEmpty={prependEmpty}
      onSelect={onSelect}
    />
  );
}
export default PropertySingleSelect;
