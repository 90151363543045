import { Item, PropertyValue, RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import {
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';

import {
  COL_PROPERTY_VALUES,
  getPropertyValuesPath,
} from '@/libs/docPathUtils';

import { getUpdateSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';

import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * propertyValuesのリアルタイムアップデートを購読するためのHooks
 * @returns { propertyValues, createPropertyValue }
 */
export default function usePropertyValues() {
  const rrfFirestore = useFirestore();
  const { t } = useTranslation();
  const { exec } = useHandleApi();

  const { currentMyWorkspace, isMember } = useMyWorkspaces();
  const { currentMyProject, isReadOnly } = useProjects();
  const { currentItemId, items } = useItems();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !projectId || items.length === 0) return [];
    return items.map((it) => {
      const query: ReduxFirestoreQuerySetting = {
        collection: getPropertyValuesPath(workspaceId, projectId, it.id),
        storeAs: `${COL_PROPERTY_VALUES}/${it.id}`,
      };
      // ゲストの場合は絞り込みを追加
      if (
        ['unknownGuest', 'guest'].includes(
          currentMyWorkspace?.joinType as string,
        )
      ) {
        query.where = [['isShare', '==', true]];
      }
      return query;
    });
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);

  // 表示中のアイテムのpropertyValues 主にアイテム詳細の直URL対策
  const currentPropertyValues = useMemo(
    () =>
      (ordered[`${COL_PROPERTY_VALUES}/${currentItemId}`] ||
        []) as PropertyValue[],

    [currentItemId, ordered],
  );

  /**
   * 期日設定を解除する
   * @param item アイテム
   * @param propertyId プロパティID
   */
  const releaseDueFlg = async (
    itemId: Item['id'],
    propertyId: PropertyValue['id'],
  ) => {
    exec(async () => {
      if (!isMember && isReadOnly) return;
      if (!workspaceId || !projectId) throw new Error('dueDate update failed.');

      const docPath = `${getPropertyValuesPath(
        workspaceId,
        projectId,
        itemId,
      )}/${propertyId}`;

      await rrfFirestore.update<PropertyValue>(docPath, {
        isDueDate: false,
        ...getUpdateSystemFields(userId),
      });
    }, t('期日フラグの更新に失敗しました'));
  };

  return {
    currentPropertyValues,
    releaseDueFlg,
  };
}
