import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { Timestamp } from 'firebase/firestore';
import i18next from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RelativePortal from 'react-relative-portal';

import {
  DOWNGRADE_PAST_DAYS,
  MEMBER_LIMIT,
  PROJECT_CREATION_LIMIT,
} from '@/libs/const';

import { parseFsTimestamp } from '@/libs/utils';

import ConfirmDialog from '@/components/Common/ConfirmDialog';

import Notice from '@/components/Common/Notice';

import usePlanProtections from '@/hooks/usePlanProtections';

/**
 * ダウングレードバナー
 * @returns
 */
export default function DowngradeBanner() {
  const { t } = useTranslation();

  const {
    planProtections,
    addCurrentUserToPlanProtections,
    isShowDowngradeBanner,
    isConfirmedDowngrade,
  } = usePlanProtections();
  const [downgradeConfirmDialogOpen, setDowngradeConfirmDialogOpen] =
    useState<boolean>(false);

  // TODO: 現状スタンダードプランしかないので定数 増えたら変数化すること
  const currentPlanName = t('スタンダード');
  // const currentPlanName = useMemo(() => {
  //   if (plan?.type === PLANS.TEAM) {
  //     return t('スタンダード');
  //   }
  //   return t('フリー');
  // }, [plan]);

  // 移行期日の計算（とりあえず７日で計算）と表記変換
  const transitionDate = useMemo(() => {
    const td = parseFsTimestamp(planProtections.churnedAt as Timestamp);
    td.setDate(td.getDate() + DOWNGRADE_PAST_DAYS);
    td.setHours(23, 59, 0, 0);
    return td;
  }, [planProtections.churnedAt]);

  const options: Intl.DateTimeFormatOptions = {
    timeStyle: 'short',
    dateStyle: 'long',
  };

  const transitionDateAsStr = useMemo(
    () =>
      new Intl.DateTimeFormat(i18next.resolvedLanguage, options).format(
        transitionDate,
      ),
    [transitionDate],
  );

  useEffect(() => {
    if (isConfirmedDowngrade == null || !isShowDowngradeBanner) {
      return;
    }

    setDowngradeConfirmDialogOpen(
      downgradeConfirmDialogOpen || !isConfirmedDowngrade,
    );
  }, [isConfirmedDowngrade, isShowDowngradeBanner]);

  return isShowDowngradeBanner === true ? (
    <>
      <Notice color="red" className="w-full p-4">
        <div className="flex flex-wrap justify-between w-full sm:flex-nowrap sm:space-x-3 sm:space-y-0 space-y-2">
          <div className="text-white">
            {t(
              '<currentPlanName>プランが解約されました。移行期日を過ぎると<icon>の付いているプロジェクトやメンバー等が削除されます。',
              {
                currentPlanName,
                icon: (
                  <MinusCircleIcon className="inline-block w-4 h-4 mx-1 align-text-bottom" />
                ),
              },
            )}
            <button
              type="button"
              className="mx-3 text-blue-600 align-middle dark:text-blue-900 hover:underline gap-1"
              onClick={() => setDowngradeConfirmDialogOpen(true)}
            >
              {t('詳細')}
            </button>
          </div>
          <div className="text-white whitespace-nowrap">
            {t('移行期日：<transitionDateAsStr> (JST)', {
              transitionDateAsStr: (
                <span className="font-bold">{transitionDateAsStr}</span>
              ),
            })}
          </div>
        </div>
      </Notice>

      <RelativePortal component="div">
        <ConfirmDialog
          className="sm:max-w-2xl"
          key="plan-protection-dialog"
          open={downgradeConfirmDialogOpen}
          onClose={(feedback) => {
            if (feedback) {
              addCurrentUserToPlanProtections();
              setDowngradeConfirmDialogOpen(false);
            } else if (isConfirmedDowngrade) {
              setDowngradeConfirmDialogOpen(false);
            }
          }}
          title={t('<currentPlanName>プランを解約しました', {
            currentPlanName,
          })}
          positive={isConfirmedDowngrade ? t('閉じる') : t('確認しました')}
          warning
          positiveOnly
        >
          <p className="mb-5">
            {t(
              '移行期日の<deadline>までに、以下注意事項を確認の上、必要に応じて対応してください。',
              {
                deadline: (
                  <span className="px-1 mx-1 bg-red-200 dark:text-red-700">
                    {transitionDateAsStr}
                  </span>
                ),
              },
            )}
          </p>
          <p className="mb-1 font-bold">{t('①メンバー登録上限')}</p>
          <p>
            {t('フリープランのメンバー登録上限数は<memberLimit>名です。', {
              memberLimit: MEMBER_LIMIT.FREE,
            })}
            <span className="underline">
              {t(
                '管理者を最低1名含め、<memberLimit>名に収まるようメンバーを削除してください。',
                { memberLimit: MEMBER_LIMIT.FREE },
              )}
            </span>
          </p>
          <p className="mb-5">
            {t(
              'なお、移行期日後に登録上限を超過していた場合、管理者を優先の上、登録日が古いメンバーを残し、超過分が自動的に削除されます。',
            )}
          </p>
          <p className="mb-1 font-bold">{t('②プロジェクトの登録上限')}</p>
          <p>
            {t('フリープランのプロジェクト登録上限は<projectLimit>件です。', {
              projectLimit: PROJECT_CREATION_LIMIT.FREE,
            })}
            <span className="underline">
              {t(
                '<projectLimit>件以内に収まるようプロジェクトを削除してください。（ビューの数は問いません）',
                { projectLimit: PROJECT_CREATION_LIMIT.FREE },
              )}
            </span>
          </p>
          <p className="mb-5">
            {t(
              'なお、移行期日後に登録上限を超過していた場合、プロジェクト一覧の表示順の上から5件を残し、超過分が自動的に削除されます。',
            )}
          </p>
          <p className="mb-1 font-bold">{t('③ビュー及びプロパティ')}</p>
          <p className="mb-5">
            {t('掲示板ビューなど')}
            <span className="underline">
              {t(
                '<currentPlanName>プランのみで設定可能なビュー及びプロパティは、移行期日後に自動的に削除されます。',
                {
                  currentPlanName,
                },
              )}
            </span>
            {t(
              '該当ビューのみで構成されたプロジェクトについては、プロジェクトごと削除されます。',
            )}
          </p>
          <p className="mb-1 font-bold">{t('④公開設定')}</p>
          <p className="mb-5">
            <span className="underline">
              {t('公開設定は、移行期日後にすべて解除されます。')}
            </span>
            {t(
              'フリープランでは、すべての項目がゲストへの公開対象となるため、引き続きゲストへの共有を行う場合はご注意ください。',
            )}
          </p>
          <p className="mb-1 font-bold">{t('⑤共有設定')}</p>
          <p>
            <span className="underline">
              {t(
                'プロジェクト共有時のゲストの権限は、移行期日後「コメント権限」に自動的に変更されます。',
              )}
            </span>
            {t('引き続きゲストへの共有を行う場合はご注意ください。')}
          </p>
        </ConfirmDialog>
      </RelativePortal>
    </>
  ) : null;
}
