import { RootState } from '@/@types/models';
import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { THEMES } from '@/libs/const';

import { setTheme } from '@/reducers/uiStateReducer';

// localStorageに保存されたUIテーマを取得して切り替える
export default () => {
  const dispatch = useDispatch();
  const uiState = useSelector((state: RootState) => state.uiState);
  const isDarkTheme = useMemo(
    () => uiState.theme === THEMES.DARK,
    [uiState.theme],
  );

  // テーマ切替用関数
  const toggleTheme = useCallback(() => {
    const nextTheme = isDarkTheme ? THEMES.DEFAULT : THEMES.DARK;
    dispatch(setTheme(nextTheme));
  }, [setTheme, isDarkTheme]);

  useEffect(() => {
    // HTMLタグのクラスを変更
    const html = document.documentElement;
    if (isDarkTheme) {
      html.classList.add(THEMES.DARK);
    } else {
      html.classList.remove(THEMES.DARK);
    }
  }, [isDarkTheme]);

  return { toggleTheme, isDarkTheme, currentTheme: uiState.theme };
};
