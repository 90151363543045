import React from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from '@/pages/layouts/ErrorPage';

export default function ErrorExpired() {
  const { t } = useTranslation();

  return (
    <ErrorPage
      code={401}
      title={t('URLの有効期限が切れています。')}
      description={t(
        '招待URLの有効期限は3日間です。<br>有効期限が過ぎてしまった場合は再度招待を依頼してください。',
        { br: <br /> },
      )} // TODO: メッセージ検討
    />
  );
}
