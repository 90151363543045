import { RootState } from '@/@types/models';

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  getMyWorkspaceList,
  getViewList,
  hasExpectedMyProject,
  routeHandleMyProjectQuery,
  routeHandleMyWorkspaceQuery,
  routeHandleViewQuery,
} from '@/libs/repositoryUtils';

import { getNavigatePath } from '@/libs/utils';

import AccountRepository from '@/repositories/AccountRepository';

import AccountStatusesRepository from '@/repositories/AccountStatusesRepository';

import { setIsLoaded } from '@/reducers/uiStateReducer';

/**
 * `/workspaceId/p/projectId` アクセスのルーティング制御
 * @returns void
 */
function useRouteNavigate() {
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const urlParams = useParams();
  const { workspaceId: originalWorkspaceId, projectId } = urlParams;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const accountStatusesRepository = new AccountStatusesRepository();

  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const resolveUnauthorizedAccountAccess = useCallback(async () => {
    // ※ 非認証ユーザーの場合
    const accountRepository = new AccountRepository();
    const response = await accountRepository.checkUnauthorizedAccountAccess(
      originalWorkspaceId as string,
      projectId as string,
    );
    if (response === false) {
      toast.error('アクセスが許可されていません');
      return navigate('/404');
    }
    const {
      originalWorkspaceId: wsId,
      projectId: pjId,
      viewId: vwId,
    } = response;
    if (userId) {
      // ユーザーログイン済みの場合はログアウトする
      await firebase.logout();
    }
    return navigate(getNavigatePath(wsId, pjId, vwId));
  }, []);

  const resolve = useCallback(async () => {
    dispatch(setIsLoaded(false));
    if (!userId && originalWorkspaceId && projectId) {
      return resolveUnauthorizedAccountAccess();
    }

    // ※ 認証ユーザーの場合
    if (originalWorkspaceId && projectId) {
      // 要求されたWSの存在検証
      const workspaceQuery = routeHandleMyWorkspaceQuery(originalWorkspaceId);
      const myWorkspaceList = await getMyWorkspaceList(userId, workspaceQuery);
      if (myWorkspaceList.length > 0 && myWorkspaceList[0].id) {
        const { joinType, id: myWorkspaceId, workspaceId } = myWorkspaceList[0];

        // URLに指定されたmyProjectの存在検証
        const projectQuery = routeHandleMyProjectQuery(joinType);
        const isExpectedMyProject = await hasExpectedMyProject(
          userId,
          myWorkspaceId,
          projectId,
          projectQuery,
        );
        if (workspaceId && projectId && isExpectedMyProject) {
          const viewQuery = routeHandleViewQuery(joinType);
          const viewList = await getViewList(workspaceId, projectId, viewQuery);
          const accountStatus = await accountStatusesRepository.findById(
            userId,
            rrfAuth.isAnonymous,
          );

          if (viewList.length > 0) {
            // 最終アクセスビューIDが保管されていればそちらを優先する
            const { id: viewId } =
              viewList.find((o) => o.id === accountStatus?.lastViewId) ||
              viewList[0];
            return navigate(
              getNavigatePath(originalWorkspaceId, projectId, viewId),
            );
          }
        }
      }
      // リンク共有モードでのアクセスを試みる
      if (userId && originalWorkspaceId && projectId) {
        return resolveUnauthorizedAccountAccess();
      }
    }
    return navigate('/404');
  }, []);

  return {
    resolve,
  };
}

/**
 * `/workspaceId/p/projectId` アクセスの場合
 */
export default function ProjectIndex() {
  const { resolve } = useRouteNavigate();

  useEffect(() => {
    resolve();
  }, []);

  // レンダリングは行わない
  return <div />;
}
