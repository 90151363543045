import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RelativePortal from 'react-relative-portal';

import { version2Num } from '@/libs/utils';

import ConfirmDialog from '@/components/Common/ConfirmDialog';

import useAdmin from '@/hooks/useAdmin';

/**
 * アップデート後のリロード誘導ダイアログ
 * @param
 * @returns
 */
export default function UpdateConfirmDialog() {
  const { t } = useTranslation();

  const [openUpdateConfirmDialog, setOpenUpdateConfirmDialog] =
    useState<boolean>(false);

  const { versionControl } = useAdmin();
  useEffect(() => {
    if (
      versionControl &&
      version2Num(versionControl.ver) >
        version2Num(import.meta.env.VITE_APP_VERSION)
    )
      setOpenUpdateConfirmDialog(true);
  }, [versionControl]);

  return (
    <RelativePortal component="div">
      <ConfirmDialog
        key="new-project-limit"
        open={openUpdateConfirmDialog}
        onClose={(isPositive) => {
          if (isPositive) window.location.reload();
        }}
        title={t(`collabbleはアップデートされました`)}
        positive={t('OK')}
        positiveOnly
      >
        <p className="mb-2">
          {t(
            '続けてご利用いただくには再読み込みが必要です。OKをクリックするとページが再読み込みされます。',
          )}
        </p>
      </ConfirmDialog>
    </RelativePortal>
  );
}
