import { IViewRow } from '@/@types/viewItem';

import { PlusIcon } from '@heroicons/react/24/solid';
import React, {
  useMemo,
  useCallback,
  useState,
  MouseEvent,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { classNames, getOriginalColorClass } from '@/libs/styleUtils';

import EmojiPicker from '@/components/Common/EmojiPicker';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import Checkbox from '@/components/Common/Forms/Checkbox';
import IconBtn from '@/components/Common/Icon/IconBtn';
import {
  isSelectPublishItemModeContext,
  itemContext,
} from '@/components/Share/SelectPublishItemsContext';

import BillboardCardRow from '@/components/View/Billboard/BillboardCardRow';

import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useStatuses from '@/hooks/useStatuses';

export interface IBillboardCardProps {
  row: IViewRow;
  isReadonly: boolean;
  onClickMore: () => void; // もっと見るボタンの処理
  onClickCreateItem: () => void; // ステータス横の新規作成ボタンの処理
}

const pickerHeight = 435;
const MAX_ITEM_ROWS = 5; // アイテムの最大行数

/**
 * 掲示板ビューのカード
 * @param props
 * @returns
 */
export default function BillboardCard(props: IBillboardCardProps) {
  const { row, onClickMore, onClickCreateItem, isReadonly } = props;

  const { t } = useTranslation();
  const { updateStatus } = useStatuses();
  const { isMember } = useMyWorkspaces();

  const { isItemLimitOver } = useItems();

  // ローカルステート
  const [pickerPos, setPickerPos] = useState({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });
  const [showPicker, setShowPicker] = useState(false);
  // アイコンはストアから取得
  const icons = useMemo(() => row.status.icon, [row.status.icon]);

  const viewItems = useMemo(
    () => row.viewItems.slice(0, MAX_ITEM_ROWS),
    [row.viewItems],
  );

  const borderClass = useCallback(
    () => getOriginalColorClass(row.status.statusColor),
    [row.status.statusColor],
  );

  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  /**
   * 絵文字ピッカーの表示/非表示トグル
   */
  const toggleEmojiPicker = (event: MouseEvent<HTMLElement>) => {
    // イベント伝播をキャンセル
    event.stopPropagation();
    const adjustTop = 50; // 上向き表示の場合の調整
    const btnRect = event.currentTarget.getBoundingClientRect();
    // 画面からはみ出さないようPickerの位置を調整する
    // 掲示板の表示域の高さが絵文字モーダル以下の場合
    const isShortScreen = pickerHeight <= window.innerHeight;
    const shortScreenPos =
      btnRect.top < Math.floor(window.innerHeight / 2)
        ? 50
        : -pickerHeight - btnRect.height + adjustTop;
    const screenPos =
      btnRect.top + pickerHeight <= window.innerHeight
        ? 50
        : -pickerHeight - btnRect.height;
    setPickerPos({
      top: isShortScreen ? shortScreenPos : screenPos, // 画面下部の場合は上付きになる
      right: 0,
      bottom: 0,
      left: -5,
    });
    // Pickerの表示切り替え
    setShowPicker((isShow) => !isShow);
  };

  /**
   * 絵文字選択時の処理
   */
  const onEmojiSelect = (emoji: any) => {
    // アイコン更新処理（DB）
    try {
      updateStatus(
        row.status.statusName,
        emoji.native as string,
        row.status.statusColor,
        row.status.id,
      );
    } catch (e) {
      toast.error(t('アイコンの変更に失敗しました'));
      throw e;
    } finally {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  // 表示できていない件数
  const remainItemsNum = useMemo(() => {
    const num = row.viewItems.length - MAX_ITEM_ROWS;
    if (num < 0) return 0;

    return num;
  }, [row.viewItems, MAX_ITEM_ROWS]);

  const { checkedStatusIds, checkStatusId } = useContext(itemContext);

  return (
    <div
      className="overflow-hidden bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700"
      style={{ height: '21rem' }}
    >
      <div className="flex flex-col h-full px-4 pt-2 pb-4">
        <div
          className={classNames(
            'inline-flex items-center border-b-4 space-x-2',
            `border-${borderClass()?.border}`,
          )}
        >
          <span className="text-2xl">
            <IconBtn
              disabled={isSelectPublishItemMode}
              tooltipMessage={
                !isSelectPublishItemMode && isMember ? t('アイコンを変更') : ''
              }
              onClick={toggleEmojiPicker}
            >
              {icons ?? ''}
            </IconBtn>
            {showPicker && isMember && (
              <EmojiPicker
                onEmojiSelect={onEmojiSelect}
                coords={pickerPos}
                onClickOutside={() => {
                  setShowPicker(false);
                }}
              />
            )}
          </span>
          <button
            type="button"
            className="text-base font-semibold tracking-wider"
            onClick={onClickMore}
          >
            {row.status.statusName}
          </button>
          {!isSelectPublishItemMode && !isReadonly && (
            <div className="text-right grow">
              <MenuButton
                id="showNewItem"
                type="icon"
                onClick={onClickCreateItem}
                className={classNames(
                  'w-auto',
                  isItemLimitOver
                    ? 'hover:bg-white/100 bg-white/100 dark:bg-gray-800/100 dark:hover:text-gray-700/100 dark:text-gray-700/100 hover:text-gray-200/100 text-gray-200/100'
                    : '',
                )}
                disabled={isItemLimitOver}
                toolTip={
                  isItemLimitOver
                    ? t(
                        'アイテム数上限に達しました。アイテムを作成したい場合は、既存のアイテムを削除してください。',
                      )
                    : ''
                }
              >
                <PlusIcon className="w-4 h-4" />
              </MenuButton>
            </div>
          )}
          {/** アイテム選択モードの場合は選択チェックボックスを表示 */}
          {isSelectPublishItemMode && (
            <div className="flex justify-end pr-2 grow">
              <Checkbox
                name="selected"
                checked={checkedStatusIds.includes(row.status.id as string)}
                onChange={() => checkStatusId(row.status.id as string)}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col pt-3 gap-2 grow">
          {viewItems.map((viewItem) => (
            <BillboardCardRow viewItem={viewItem} key={viewItem.id} />
          ))}
        </div>
        <div className="items-center text-right">
          <MenuButton
            id="showBillboardList"
            type="text"
            onClick={onClickMore}
            className="w-auto"
          >
            {remainItemsNum > 0
              ? t('+<remainItemsNum>件を表示', { remainItemsNum })
              : t('リストで表示')}
          </MenuButton>
        </div>
      </div>
    </div>
  );
}
