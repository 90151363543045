import { Dialog } from '@headlessui/react';
import { ChatBubbleLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import RelativePortal from 'react-relative-portal';

import BaseDrawer from '@/components/Common/BaseDrawer';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import RedDot from '@/components/Common/RedDot';

import AllCommentList from '@/components/Items/Comments/All//AllCommentList';

import useProjects from '@/hooks/useProjects';

export interface IAllCommentButtonProps {
  className?: string;
}

/**
 * 全てのコメント
 * @param props
 * @returns
 */
export default function AllCommentButton(props: IAllCommentButtonProps) {
  const { t } = useTranslation();
  const { className } = props;
  const [open, setOpen] = useState<boolean>(false);
  const { currentMyProject } = useProjects();

  // consoleエラー回避用のhidden要素
  const allCommentFocusDiv = useRef(null);

  const closeDrawerHandle = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <div className={className}>
      {/** ボタン */}
      <MenuButton
        id="showCommentList"
        type="icon"
        className="relative"
        onClick={() => {
          setOpen(true);
        }}
      >
        <ChatBubbleLeftIcon className="w-5 h-5" />
        {currentMyProject?.isCommentListUnRead && (
          <RedDot className="absolute right-0.5 top-0.5" />
        )}
      </MenuButton>
      {/** ドロワー */}
      <RelativePortal component="div">
        <BaseDrawer show={open} onClose={setOpen} undefinedInitialFocus>
          <div className="w-screen max-w-md md::max-w-md lg:max-w-lg xl:max-w-xl">
            <div className="flex flex-col h-full pt-6 border-l border-gray-200 shadow-xl dark:border-gray-800 bg-gray-50 dark:bg-gray-700">
              <div className="px-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <Dialog.Title className="flex items-center font-medium text-gray-800 text-md dark:text-gray-300">
                    <>
                      <div className="flex-1 mr-4">{t('コメント')}</div>
                      {currentMyProject?.isCommentListUnRead && <RedDot />}
                    </>
                  </Dialog.Title>
                  <div className="flex items-center ml-3 h-7">
                    <MenuButton
                      id="closeNotification"
                      type="icon"
                      tabIndex={-1}
                      onClick={() => setOpen(false)}
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </MenuButton>
                    <div ref={allCommentFocusDiv} hidden />
                  </div>
                </div>
              </div>
              <AllCommentList
                className="mt-3"
                closeDrawer={closeDrawerHandle}
              />
            </div>
          </div>
        </BaseDrawer>
      </RelativePortal>
    </div>
  );
}
