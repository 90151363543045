import { Item } from '@/@types/models';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import useItemContext from '@/hooks/useItemContext';

export interface ISelectPublishItemsContextProps {
  children: ReactNode | ReactNode[];
}

export const isSelectPublishItemModeContext = createContext<boolean>(false);
export const setIsSelectPublishItemModeContext = createContext<
  Dispatch<SetStateAction<boolean>>
>(() => undefined);

// 公開アイテムのcontext
interface contextInterface {
  contextItems: Item[];
  setContextItems: Dispatch<SetStateAction<Item[]>>;
  checkAllItem: (value: boolean) => void;
  checkedItemIds: string[];
  setCheckedItemIds: Dispatch<SetStateAction<string[]>>;
  checkItemId: (value: string) => void;
  checkedStatusIds: string[];
  checkStatusId: (value: string) => void;
}

export const itemContext = createContext<contextInterface>({
  contextItems: [],
  setContextItems: (value) => value,
  checkAllItem: () => {},
  checkedItemIds: [],
  setCheckedItemIds: (value) => value,
  checkedStatusIds: [],
  checkItemId: () => {},
  checkStatusId: () => {},
});

/**
 * 公開アイテム選択モード Context
 * @param props
 * @returns
 */
export default function SelectPublishItemsContext(
  props: ISelectPublishItemsContextProps,
) {
  const { children } = props;
  const [mode, setMode] = useState<boolean>(false);
  const itemContextHook = useItemContext();
  const tabKeyMonitoring = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Tab') {
      e.preventDefault();
    }
  }, []);

  // モード実行中はタブキーを無効にする
  useEffect(() => {
    if (mode) {
      window.addEventListener('keydown', tabKeyMonitoring, false);
    } else {
      window.removeEventListener('keydown', tabKeyMonitoring);
    }
  }, [mode]);

  return (
    <isSelectPublishItemModeContext.Provider value={mode}>
      <setIsSelectPublishItemModeContext.Provider value={setMode}>
        <itemContext.Provider value={itemContextHook}>
          {children}
        </itemContext.Provider>
      </setIsSelectPublishItemModeContext.Provider>
    </isSelectPublishItemModeContext.Provider>
  );
}
