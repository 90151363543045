import { RootState, Guest, IJoinedGuestWithProjects } from '@/@types/models';
import { PlusIcon } from '@heroicons/react/24/outline';
import lodash from 'lodash';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { useFirestore } from 'react-redux-firebase';

import { getGuestsPath, getInvitingGuestsPath } from '@/libs/docPathUtils';
import { classNames } from '@/libs/styleUtils';

import AvatarIcon from '@/components/Common/AvatarIcon';
import { Badge } from '@/components/Common/Badge';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import GuestProjectDrawer from '@/components/Guest/GuestProjectDrawer';

import useInvitingGuests from '@/hooks/useInvitingGuests';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useGuests from '@/hooks/useXGuests';

export type AccountListChangeEvent =
  | 'invite'
  | 'uninvite'
  | 'remove'
  | 'unremove';

export interface InviteDestination {
  id: number;
  email: string | null;
}

export interface IAccountListProps {
  accounts: IJoinedGuestWithProjects[];
  className?: string;
  onChange?: (event?: AccountListChangeEvent) => void;
}

export interface IInviteEmail {
  id: string;
  value: string;
  invitedAt: Date;
}

/**
 * ゲストアカウントリスト（共有メンバーリスト兼）
 *     scheme: 削除対象IDs: removeIds: string[], 招待Eメール配列: inviteEmails {id:string, value:string}
 * @param props
 * @returns
 */
export default function GuestProjectAccountList(props: IAccountListProps) {
  const rrfFirestore = useFirestore();
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const { t } = useTranslation();
  const { accounts } = props;
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [openEventually, setOpenEventually] = useState(false);
  const [newDrawerId, setNewDrawerId] = useState('');

  const [selectedAccount, setSelectedAccount] =
    useState<IJoinedGuestWithProjects | null>(null);

  const { myProjectsXGuestsDic } = useGuests();
  const { myProjectsInvitingGuestsDic } = useInvitingGuests();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // ドロワーの開閉用
  useEffect(() => {
    if (openEventually) {
      setOpen(true);
      setOpenEventually(false);
    }
  }, [openEventually]);

  useEffect(() => {
    const accountId = selectedAccount?.id;
    if (accountId) {
      const newAccount = accounts.find((a) => a.id === accountId);
      if (newAccount && !lodash.isEqual(newAccount, selectedAccount)) {
        setSelectedAccount(newAccount);
      }
    }
  }, [accounts]);

  // Zod Form
  const methods = useForm<{
    removeIds: { guestId: string; guestEmail: string }[];
  }>({
    defaultValues: {
      removeIds: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'removeIds',
  });

  const removeIdObjectArray = methods.watch('removeIds');
  const removeIdSet = useMemo(
    () => new Set(removeIdObjectArray.map((x) => x.guestId)),
    [removeIdObjectArray],
  );

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    data.removeIds.forEach((guest) => {
      myProjectsXGuestsDic[guest.guestId]?.projectId?.forEach((projectId) => {
        rrfFirestore.delete<Guest>(
          `${getGuestsPath(workspaceId, projectId)}/${guest.guestId}`,
        ); // 参加済みゲスト削除
      });
      myProjectsInvitingGuestsDic[guest.guestId]?.projectId?.forEach(
        (projectId) => {
          rrfFirestore.delete(
            `${getInvitingGuestsPath(workspaceId, projectId)}/${guest.guestId}`,
          ); // 招待中ゲスト削除
        },
      );
    });
    lodash.rangeRight(data.removeIds.length).forEach((i) => {
      remove(i);
    });
  });

  // Submitボタン状態
  const isDisabledApply = useMemo(
    () => fields.length === 0,
    [methods.formState],
  );

  const onDrawerClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => {
      setSelectedAccount(null);
    }, 500);
  }, [open]);

  const addInviteGuestClick = useCallback(() => {
    setSelectedAccount(null);
    setOpenEventually(true);
    setNewDrawerId(lodash.uniqueId());
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={formSubmit}>
          {fields.map((item, index) => (
            <>
              <input
                type="hidden"
                {...methods.register(`removeIds.${index}.guestId`)}
              />
              <input
                type="hidden"
                {...methods.register(`removeIds.${index}.guestEmail`)}
              />
            </>
          ))}
          <div className="max-w-3xl">
            <ul className="w-full">
              {/** Substance */}
              {accounts.map((item) => (
                <li
                  key={item.id}
                  className={classNames(
                    removeIdSet.has(item.id || '')
                      ? 'bg-gray-50 dark:bg-gray-900'
                      : '',
                    'mb-1 dark:divide-gray-700 rounded-md border dark:border-gray-700 px-4 py-4',
                  )}
                >
                  <div className="items-center md:flex grid grid-cols-1">
                    <div className="flex items-center flex-1 space-x-4">
                      <div
                        className={classNames(
                          removeIdSet.has(item.id || '') ? 'opacity-40' : '',
                          'relative flex-shrink-0',
                        )}
                      >
                        <AvatarIcon
                          src={item.photoURL}
                          avatarName={item.displayName || item.email}
                          size="md"
                        />
                      </div>
                      <div
                        className={classNames(
                          removeIdSet.has(item.id || '') ? 'opacity-40' : '',
                          'flex-1 min-w-0',
                        )}
                      >
                        <p className="mr-0 font-medium truncate md:mr-4">
                          {item.displayName}
                        </p>
                        <p className="mr-0 text-sm text-gray-500 truncate dark:text-gray-400 md:mr-4">
                          {item.email}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center mt-4 md:mt-0 md:space-x-4">
                      <div className="mr-4 md:mr-0">
                        {!removeIdSet.has(item.id || '') ? (
                          <FormButton
                            id="editProjectForGuest"
                            onClick={() => {
                              setSelectedAccount(item);
                              setOpenEventually(true);
                            }}
                            className="py-1"
                          >
                            {t('参加プロジェクト')}
                          </FormButton>
                        ) : (
                          ''
                        )}
                      </div>
                      {removeIdSet.has(item.id || '') ? (
                        <Badge
                          className="mr-4 md:mr-0"
                          color="red"
                          content={t('除外対象')}
                        />
                      ) : (
                        !removeIdSet.has(item.id || '') &&
                        !item.joined && (
                          <Badge
                            className="mr-4 md:mr-0"
                            color="blue"
                            content={t('参加待ち')}
                          />
                        )
                      )}
                      {userId !== item.id && (
                        <div>
                          {!removeIdSet.has(item.id || '') && (
                            <FormButton
                              id="addRemoveGuest"
                              onClick={() => {
                                append({
                                  guestId: item.id as string,
                                  guestEmail: item.email || '',
                                });
                              }}
                              className="py-1"
                            >
                              {t('除外')}
                            </FormButton>
                          )}
                          {removeIdSet.has(item.id || '') && (
                            <FormButton
                              id="cancelRemoveGuest"
                              onClick={() => {
                                remove(
                                  removeIdObjectArray.findIndex(
                                    (x) => x.guestId === item.id,
                                  ),
                                );
                              }}
                              className="py-1"
                            >
                              {t('キャンセル')}
                            </FormButton>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div className="sticky bottom-0 mt-2 overflow-hidden border rounded-md dark:border-gray-700">
              <FormButton
                id="addInviteGuest"
                onClick={addInviteGuestClick}
                className="flex items-center justify-center border-0 rounded-none outline-none ring-0 focus:ring-0 focus:!ring-offset-0"
              >
                <PlusIcon className="w-4 h-4" aria-hidden="true" />
                <div className="ml-2">{t('ゲストを招待')}</div>
              </FormButton>
            </div>
            <div ref={scrollBottomRef} />
          </div>
          <div className="sticky bottom-0 max-w-3xl py-5 bg-white dark:bg-gray-800 sm:py-4 sm:flex sm:flex-row-reverse">
            <FormButton
              id="applyGuests"
              disabled={isDisabledApply}
              submit
              variant="primary"
              className="sm:w-auto sm:ml-3"
            >
              {t('適用')}
            </FormButton>
          </div>
        </form>
      </FormProvider>
      <GuestProjectDrawer
        key={`guestProjectDrawer${selectedAccount?.id || newDrawerId}`}
        selectedAccount={selectedAccount}
        accounts={accounts}
        show={open}
        onClose={onDrawerClose}
      />
    </>
  );
}
