import { EV } from '@/@types/events';

import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useMemo, useRef } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import { useFirestore } from 'react-redux-firebase';

import { FILE_COUNT_LIMIT } from '@/libs/const';

import { getTempFilesPath } from '@/libs/docPathUtils';

import { classNames } from '@/libs/styleUtils';

import FileLoader, { FileSource } from '@/components/Common/FileLoader';

import { SpinnerCircleIcon } from '@/components/Common/Icon/Icons';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useTempFiles from '@/hooks/useTempFiles';

interface IFileInputProps {
  files: FileSource[];
  onDelete: (file: FileSource) => void;
  onSelect: (files: FileSource[]) => void;
}

/**
 * ファイルアップロード用のInputコンポーネント
 * @param props
 * @returns
 */
export default function FileInput(props: IFileInputProps) {
  const { files, onDelete, onSelect } = props;
  const { t } = useTranslation();

  const rrfFirestore = useFirestore();

  const { currentMyWorkspace } = useMyWorkspaces();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { handleDeletePreFile, isSelectFileLoading, handleSelectFiles } =
    useTempFiles();

  // カレントワークスペースID
  const currentWorkspaceId = useMemo(() => {
    if (!currentMyWorkspace || !currentMyWorkspace.workspaceId) return null;
    return currentMyWorkspace.workspaceId;
  }, [currentMyWorkspace]);

  /**
   * ファイル選択時処理
   * @param files
   */
  const onSelectFile = async (uploaded: File[]) => {
    const uploadedFiles = await handleSelectFiles(uploaded);
    onSelect(uploadedFiles);
  };

  /**
   * ファイル削除
   */
  const onDeleteFile = async (file: FileSource) => {
    if (!currentWorkspaceId) throw new Error('current workspace not loaded.');

    // firestore(メタデータ)のパスはfile.pathと異なるので別途取得する
    const res = await rrfFirestore
      .collection(getTempFilesPath(currentWorkspaceId))
      .where('fullPath', '==', file.path)
      .get();
    const docPath = res.docs.length > 0 ? res.docs[0].ref.path : '';

    handleDeletePreFile(file, docPath);
    onDelete(file);
  };

  /**
   * 添付ファイル選択
   * ※ fileInputRefのクリックイベント発火のみ
   *    ファイル読み込み時処理は`handleFileLoad`に続く
   */
  const selectLocalFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  /**
   * ファイル読み込み時ハンドラ
   * @param e
   */
  const handleFileLoad = (e: EV<HTMLInputElement>) => {
    const { files: uploadedFiles } = e.target;
    if (uploadedFiles) {
      onSelectFile(Array.from(uploadedFiles));
      e.target.value = ''; // inputの値は不要なのでクリア
    }
  };

  /**
   * ドロップハンドラー
   */
  const handleDrop = useCallback(
    (item: { files: File[] }) => {
      if (
        !isSelectFileLoading &&
        files.length + item.files.length <= FILE_COUNT_LIMIT
      )
        onSelectFile(Array.from(item.files));
    },
    [isSelectFileLoading, files, onSelectFile],
  );

  /**
   * ドロップ可能性判定
   */
  const handleCanDrop = useCallback(
    (item: any) =>
      !isSelectFileLoading &&
      files.length + item.items.length <= FILE_COUNT_LIMIT,

    [files, isSelectFileLoading],
  );

  /** D&D ドロップHooks */
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop: handleDrop,
      canDrop: handleCanDrop,
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [handleDrop, handleCanDrop],
  );

  /** D&Dがアクティブ */
  const isDaDActive = useMemo(() => canDrop && isOver, [canDrop, isOver]);
  /** D&Dでアクティブだがファイル数オーバー */
  const isDaDLimit = useMemo(() => !canDrop && isOver, [canDrop, isOver]);
  return (
    <div className="w-full h-full">
      <div
        ref={drop}
        className={classNames(
          'w-full h-full p-0 text-left text-gray-700 bg-white border border-gray-300 dark:bg-gray-700 focus:outline-none rounded-md dark:border-gray-600 shadow-sm dark:text-gray-300 hover:bg-gray-50 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 min-h-[2.4rem]',
          isDaDActive
            ? 'relative overflow-hidden dark:ring-primary-600 ring-primary-500 border-primary-500 dark:border-primary-600'
            : '',
          isDaDLimit
            ? 'relative border-red-400 dark:border-red-800 dark:ring-red-700 ring-red-400'
            : '',
        )}
      >
        {isDaDActive && (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center px-3 py-2 text-primary-600 bg-dad-active dark:text-primary-600 gap-1">
            <PlusIcon className="w-4 h-4" aria-hidden="true" />
            {t('ファイルをドロップして追加')}
          </div>
        )}
        {isDaDLimit && (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center px-3 py-2 bg-dad-disabled">
            {t('ファイルを追加できません')}
          </div>
        )}
        {files.length >= FILE_COUNT_LIMIT ? (
          <div className="px-3 py-2 text-gray-400">
            {t('これ以上ファイルを追加できません')}
          </div>
        ) : (
          <button
            disabled={isSelectFileLoading}
            type="button"
            className="flex items-center w-full px-3 py-2 text-left text-gray-500 dark:text-gray-300 dark:hover:text-primary-600 hover:text-primary-600 gap-1"
            onClick={selectLocalFile}
          >
            <PlusIcon className="w-4 h-4" aria-hidden="true" />
            <div className="inline-block align-text-top">
              {t('ファイルまたは画像を追加')}
            </div>
            {isSelectFileLoading && (
              <div>
                <SpinnerCircleIcon className="w-4 h-4 ml-2" />
              </div>
            )}
          </button>
        )}

        {files && files.length > 0 && (
          <div className="flex flex-wrap p-2 gap-x-2 gap-y-2">
            {files.map((file) => (
              <FileLoader
                key={file.path}
                src={file}
                clickable
                onDetach={() => {
                  onDeleteFile(file);
                }}
              />
            ))}
          </div>
        )}

        {/* ファイル読み込み処理時利用する<input> */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileLoad}
        />
      </div>
    </div>
  );
}
