import { EV } from '@/@types/events';

import { IPropertyFormatType } from '@/@types/models';
import React, { useCallback, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NUMBER_PROPERTY_FORMAT_TYPE } from '@/libs/const';

import { classNames } from '@/libs/styleUtils';

import FormCol from '@/components/Common/Forms/FormCol';
import Select from '@/components/Common/Forms/Select';

export interface IPropertyDefaultValues {
  propertyTypeFormat: IPropertyFormatType;
}

export interface INumberFormProps {
  className?: string;
}

/**
 * 数値型プロパティ編集フォーム
 * @param props
 * @returns
 */
export default function NumberForm(props: INumberFormProps) {
  const { className } = props;
  const { t } = useTranslation();

  const { setValue, trigger, getValues } =
    useFormContext<IPropertyDefaultValues>();

  // 選択可能データ型
  const options = useMemo(
    () => [
      {
        value: NUMBER_PROPERTY_FORMAT_TYPE.FLOAT,
        label: t('数値（小数点あり）'),
      },
      {
        value: NUMBER_PROPERTY_FORMAT_TYPE.PERCENT,
        label: t('パーセント（%）'),
      },
      { value: NUMBER_PROPERTY_FORMAT_TYPE.YEN, label: t('円（¥）') },
    ],
    [],
  );

  const handleChange = useCallback((event: EV<HTMLSelectElement>) => {
    setValue(
      'propertyTypeFormat',
      event.currentTarget.value as IPropertyFormatType,
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    );
    trigger('propertyTypeFormat');
  }, []);

  return (
    <div className={classNames('space-y-2', className)}>
      <FormCol>
        <Select
          value={getValues('propertyTypeFormat')}
          label={t('数値の形式')}
          name="propertyTypeFormat"
          options={options}
          onChange={handleChange}
        />
      </FormCol>
    </div>
  );
}
