/* eslint-disable camelcase */
import {
  $isCodeHighlightNode,
  $createCodeNode,
  $isCodeNode,
} from '@lexical/code';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';

import {
  $isListNode,
  ListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list';
import {
  $wrapSelectionInMarkNode,
  $unwrapMarkNode,
  $isMarkNode,
} from '@lexical/mark';
import {
  $createQuoteNode,
  $isHeadingNode,
  $createHeadingNode,
  $isQuoteNode,
} from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';

import {
  SELECTION_CHANGE_COMMAND,
  $getSelection,
  $isRangeSelection,
  RangeSelection,
  LexicalEditor,
  $isTextNode,
  $createParagraphNode,
  DEPRECATED_$isGridSelection,
  $getNodeByKey,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';

import { FILE_COUNT_LIMIT } from '@/libs/const';

import {
  INSERT_IMAGE_COMMAND,
  InsertImagePayload,
} from '@/components/Common/Lexical/plugins/ImagesPlugin';
import {
  getNodeByType,
  getSelectedNode,
  LowPriority,
} from '@/components/Common/Lexical/utils/lexicalUtils';

import { ImageNode } from '../nodes/ImageNode';

const HEADING_BLOCK_TYPES = ['h1', 'h2', 'h3', 'h4', 'h5'];

/**
 * Lexical共通 ステートモニタリング用のフック
 * @returns
 */
export default function useLexicalMonitor(editor: LexicalEditor) {
  // フォーマットの状態保持
  const [blockType, setBlockType] = useState('paragraph');
  const [isText, setIsText] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);
  const [isQuote, setIsQuote] = useState(false);
  const [isCodeBlock, setIsCodeBlock] = useState(false);
  const [isMarker, setIsMarker] = useState(false);
  const [markerIndex, setMarkerIndex] = useState(0);
  const [imageCount, setImageCount] = useState(0);

  // ローカルのステート
  const [currentSelection, setCurrentSelection] =
    useState<RangeSelection | null>(null);
  const [selectedText, setSelectedText] = useState<string>('');
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(
    null,
  );

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    const nativeSelection = window.getSelection();
    const rootElement = editor.getRootElement();
    if (
      nativeSelection !== null &&
      (!$isRangeSelection(selection) ||
        rootElement === null ||
        !rootElement.contains(nativeSelection.anchorNode))
    ) {
      setIsText(false);
      return;
    }

    if (!$isRangeSelection(selection)) {
      return;
    }
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode,
          );
          const type = parentList ? parentList.getListType() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          setIsQuote(type === 'quote');
          setIsCodeBlock(type === 'code');
        }
      }
      // フォーマット系の状態を更新
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsCode(selection.hasFormat('code'));

      const node = getSelectedNode(selection);
      const parent = node.getParent();

      // 現在フォーカスされているノード情報を保持
      setSelectedText(node.getTextContent());
      setCurrentSelection(selection);
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if ($isMarkNode(parent) || $isMarkNode(node)) {
        setIsMarker(true);
      } else {
        setIsMarker(false);
      }

      if (
        !$isCodeHighlightNode(selection.anchor.getNode()) &&
        selection.getTextContent() !== ''
      ) {
        setIsText($isTextNode(node));
      } else {
        setIsText(false);
      }
    }
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar();
            return false;
          },
          LowPriority,
        ),
      ),
    [editor, updateToolbar],
  );

  useEffect(() => {
    const onResize = () => {
      editor.getEditorState().read(() => {
        updateToolbar();
      });
    };
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [editor, updateToolbar]);

  useEffect(
    () =>
      editor.registerMutationListener(ImageNode, (imageNodes) => {
        // コピペ対策
        let plusCount = 0; // 追加枚数をカウント
        // eslint-disable-next-line no-restricted-syntax
        for (const [, mutation] of imageNodes) {
          if (mutation === 'created') {
            plusCount += 1;
            setImageCount((val) => val + 1);
          } else if (mutation === 'destroyed') {
            setImageCount((val) => val - 1);
          }
        }
        if (imageCount + plusCount > FILE_COUNT_LIMIT) {
          editor.update(() => {
            const deleteNodeIdx = imageCount + plusCount - FILE_COUNT_LIMIT; // 複数枚コピペ時に上限を超過してしまった場合、上限枚数以降を削除
            let idx = 0;
            // eslint-disable-next-line no-restricted-syntax
            for (const [key, mutation] of imageNodes) {
              if (mutation === 'created' && idx >= plusCount - deleteNodeIdx) {
                // 超過分の画像は削除
                const targetImageNode = $getNodeByKey(key);
                targetImageNode?.remove();
              }
              idx += 1;
            }
          });
        }
      }),
    [editor, imageCount],
  );

  // マウント時の画像枚数セット
  useEffect(() => {
    const es = editor.getEditorState();
    const str = JSON.stringify(es);
    const imageNodes = getNodeByType(str, 'image');
    setImageCount(imageNodes.length);
  }, []);

  /**
   * リンクの挿入
   */
  const toggleLinkMode = useCallback(() => {
    if (!currentSelection || !selectedText) return;
    const { anchor, focus } = currentSelection;
    let targetText = selectedText.slice(anchor.offset, focus.offset);
    if (!targetText) {
      // 後ろから選択した場合
      targetText = selectedText.slice(focus.offset, anchor.offset);
    }
    if (!isLink) {
      // URLは空白
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
        url: '',
        target: targetText,
      });
    }
  }, [editor, isLink, selectedText, currentSelection]);

  /**
   * 引用の挿入
   * 参考：https://codesandbox.io/s/ib7rcf?file=/components/lexical/plugins/ToolbarPlugin.tsx:17902-17917
   */
  const formatQuote = useCallback(() => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const allNodes = selection.getNodes();
          // 要素が空の引用の場合は配列の最初の要素としてQuoteNodeが取れる
          const firstNode = allNodes[0];
          // 選択範囲のすべてのNodeを取得 先頭のNodeの親を取得して親がQuoteかどうか判定する
          const parentNode = firstNode.getParent();
          let isWrapped = false;
          // 引用中にマーカーが引かれていることを考慮
          if (parentNode) {
            const childNode = parentNode.getParent();
            isWrapped = $isQuoteNode(childNode);
          }
          if (
            $isQuoteNode(firstNode) ||
            $isQuoteNode(parentNode) ||
            isWrapped
          ) {
            $wrapNodes(selection, () => $createParagraphNode());
          } else {
            $wrapNodes(selection, () => $createQuoteNode());
          }
        }
      });
    }
  }, []);

  const formatCodeBlock = useCallback(() => {
    if (blockType !== 'code') {
      editor.update(() => {
        const selection = $getSelection();
        if (
          $isRangeSelection(selection) ||
          DEPRECATED_$isGridSelection(selection)
        ) {
          const allNodes = selection.getNodes();
          // 要素が空の引用の場合は配列の最初の要素としてQuoteNodeが取れる
          const firstNode = allNodes[0];
          const parentNode = firstNode.getParent();
          let isWrapped = false;
          if (parentNode) {
            const childNode = parentNode.getParent();
            isWrapped = $isCodeNode(childNode);
          }
          if ($isCodeNode(firstNode) || $isCodeNode(parentNode) || isWrapped) {
            $wrapNodes(selection, () => $createParagraphNode());
            return;
          }
          if (selection.isCollapsed()) {
            $wrapNodes(selection, () => $createCodeNode());
          } else {
            const textContent = selection.getTextContent();
            const codeNode = $createCodeNode();
            selection.insertNodes([codeNode]);
            selection.insertRawText(textContent);
          }
        }
      });
    }
  }, []);

  /**
   * マーカーの適用
   */
  const formatMarker = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const allNodes = selection.getNodes();
        const firstNode = allNodes[0];
        const parentNode = firstNode.getParent();
        const isBackward = selection.isBackward();
        if ($isMarkNode(parentNode)) {
          const parentIds = parentNode.getIDs();
          const childNodes = selection.getNodes();
          if (childNodes.length > 1) {
            childNodes.forEach((child) => {
              if ($isMarkNode(child)) {
                parentIds.forEach((parentId) => {
                  child.deleteID(parentId);
                  if (child.getIDs().length === 0) {
                    $unwrapMarkNode(child);
                  }
                });
              }
            });
          }
          $unwrapMarkNode(parentNode);
        } else {
          $wrapSelectionInMarkNode(
            selection,
            isBackward,
            `mark_${markerIndex}`,
          );
          setMarkerIndex(markerIndex + 1);
        }
      }
    });
  }, [editor, markerIndex]);

  /**
   * 通常段落の適用
   */
  const formatParagraph = useCallback(() => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  }, [blockType]);

  /**
   * Headingの適用
   */
  const formatHeading = useCallback(
    (headingSize) => {
      if (blockType !== headingSize) {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => $createHeadingNode(headingSize));
          }
        });
      } else if (HEADING_BLOCK_TYPES.includes(blockType)) {
        formatParagraph();
      }
    },
    [blockType],
  );

  const formatBulletList = useCallback(() => {
    if (blockType !== 'bullet') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const allNodes = selection.getNodes();
          // 要素が空の引用の場合は配列の最初の要素としてQuoteNodeが取れる
          const firstNode = allNodes[0];
          // 選択範囲のすべてのNodeを取得 先頭のNodeの親を取得して親がQuoteかどうか判定する
          const parentNode = firstNode.getParent();
          if (
            ($isListNode(firstNode) && firstNode.listType !== 'bullet') ||
            ($isListNode(parentNode) && parentNode.__listType !== 'bullet')
          ) {
            $wrapNodes(selection, () => $createParagraphNode());
          } else {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
          }
        }
      });
    } else {
      formatParagraph();
    }
  }, [blockType]);

  const formatNumberedList = useCallback(() => {
    if (blockType !== 'number') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          const allNodes = selection.getNodes();
          // 要素が空の引用の場合は配列の最初の要素としてQuoteNodeが取れる
          const firstNode = allNodes[0];
          // 選択範囲のすべてのNodeを取得 先頭のNodeの親を取得して親がQuoteかどうか判定する
          const parentNode = firstNode.getParent();

          if (
            ($isListNode(firstNode) && firstNode.listType !== 'number') ||
            ($isListNode(parentNode) && parentNode.__listType !== 'number')
          ) {
            $wrapNodes(selection, () => $createParagraphNode());
          } else {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
          }
        }
      });
    } else {
      formatParagraph();
    }
  }, [blockType]);

  const insertImage = useCallback((payload: InsertImagePayload) => {
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
  }, []);

  return {
    isLink,
    isText,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    isCode,
    isQuote,
    isCodeBlock,
    isMarker,
    blockType,
    currentSelection,
    selectedText,
    selectedElementKey,
    setIsLink,
    setIsText,
    setIsBold,
    setIsItalic,
    setIsUnderline,
    setIsStrikethrough,
    setIsCodeBlock,
    setIsCode,
    setIsQuote,
    setIsMarker,
    setBlockType,
    setCurrentSelection,
    setSelectedText,
    setSelectedElementKey,
    toggleLinkMode,
    formatQuote,
    formatCodeBlock,
    formatMarker,
    formatParagraph,
    formatHeading,
    formatBulletList,
    formatNumberedList,
    insertImage,
    setImageCount,
  };
}
