import { toast } from 'react-toastify';

import { ENV } from '@/libs/const';

/**
 * API Handling Hooks
 * @returns { exec }
 */
export default function useHandleApi() {
  /**
   * API実行（エラーハンドリング付き）
   * @param func
   * @param errorMessage
   * @param ignoreCases
   */
  const exec = async (
    func: () => Promise<void>,
    errorMessage: string,
    ignoreCases?: string[],
  ) => {
    try {
      await func();
    } catch (err: any) {
      if (ignoreCases && ignoreCases.includes(err?.code)) return;
      if ([ENV.DEV, ENV.STG].includes(import.meta.env.VITE_NODE_ENV)) {
        // eslint-disable-next-line no-console
        console.error(errorMessage, err?.message);
      }
      toast.error(errorMessage);
      throw err;
    }
  };
  return { exec };
}
