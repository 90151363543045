import { Plan, RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestoreConnect } from 'react-redux-firebase';

import { PLANS, PROJECT_CREATION_LIMIT } from '@/libs/const';

import {
  COL_PLANS,
  getPlansPath,
  getWorkspacesPath,
} from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * Plansの集約に関するHooks
 */
export default function usePlans() {
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  // ゲストの時はサブスクリプションしない
  const isMember = useMemo(
    () => currentMyWorkspace?.joinType === 'member',
    [currentMyWorkspace],
  );

  useFirestoreConnect(() =>
    workspaceId && isMember
      ? [
          {
            collection: getWorkspacesPath(),
            doc: workspaceId,
            storeAs: `${COL_PLANS}/${workspaceId}`,
            subcollections: [{ collection: 'plans', doc: workspaceId }],
          },
          {
            collection: getPlansPath(workspaceId),
            doc: workspaceId,
            storeAs: COL_PLANS,
          },
        ]
      : [],
  );

  const plan = useSelector((state: RootState) => {
    const key = `${COL_PLANS}/${workspaceId}`;
    const result = (state?.firestore?.data &&
      state?.firestore?.data[key]) as Plan;
    return result || null;
  });

  /** プランにおけるプロジェクト作成数制限 */
  const maxProjectCreationCount = useMemo(
    () =>
      plan?.type === PLANS.STANDARD
        ? PROJECT_CREATION_LIMIT.STANDARD
        : PROJECT_CREATION_LIMIT.FREE,
    [plan],
  );

  /** 現在のプランにおけるプロジェクトの作成可否 */
  const isPossibleCreationProject = useMemo(
    () =>
      plan?.projectCount !== undefined &&
      plan?.projectCount < maxProjectCreationCount,
    [plan, maxProjectCreationCount],
  );

  return { plan, isPossibleCreationProject, maxProjectCreationCount };
}
