import { RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import { ADMIN_DOC_TYPE } from '@/libs/const';
import { COL_ADMIN, getAdminPath } from '@/libs/docPathUtils';

/**
 * プロダクト管理ドキュメントに関するHooks
 */
export default function useAdmin() {
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  useFirestoreConnect(() =>
    userId
      ? [
          {
            collection: getAdminPath(),
            storeAs: COL_ADMIN,
          },
        ]
      : [],
  );

  const admins = useSelector(
    (state: RootState) => state.firestore.ordered.admin,
  );

  const versionControl = useMemo(() => {
    if (!admins) return null;
    return admins.find((o) => o.id === ADMIN_DOC_TYPE.VERSION_CONTROL);
  }, [admins]);

  return { versionControl };
}
