import * as React from 'react';

import { classNames } from '@/libs/styleUtils';

export interface IFormColProps {
  className?: string;
  children: React.ReactNode;
}

export default function FormCol(props: IFormColProps) {
  const { className, children } = props;

  return (
    <div
      className={classNames(
        className,
        !className?.includes('sm:col-span') ? 'sm:col-span-6' : '',
      )}
    >
      {children}
    </div>
  );
}
