import { PlanProtections, RootState } from '@/@types/models';
import firebase from 'firebase/compat';

import { t } from 'i18next';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_PLAN_PROTECTIONS,
  getPlanProtectionsPath,
  getWorkspacesPath,
} from '@/libs/docPathUtils';
import { getUpdateSystemFields } from '@/libs/utils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useHandleApi from './useHandleApi';
import useMembers from './useMembers';
import usePlans from './usePlans';

/**
 * planProtectionsの集約に関するHooks
 * @returns { planProtections }
 */
export default function usePlanProtections() {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();
  const { currentMyWorkspace, isMember } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const { plan } = usePlans();
  const { members } = useMembers();

  useFirestoreConnect(() =>
    workspaceId && isMember
      ? [
          {
            collection: getWorkspacesPath(),
            doc: workspaceId,
            storeAs: `${COL_PLAN_PROTECTIONS}/${workspaceId}`,
            subcollections: [
              { collection: COL_PLAN_PROTECTIONS, doc: workspaceId },
            ],
          },
          {
            collection: getPlanProtectionsPath(workspaceId),
            doc: workspaceId,
            storeAs: COL_PLAN_PROTECTIONS,
          },
        ]
      : [],
  );

  const planProtections = useSelector((state: RootState) => {
    const key = `${COL_PLAN_PROTECTIONS}/${workspaceId}`;
    const result = (state?.firestore?.data &&
      state?.firestore?.data[key]) as PlanProtections;
    if (result) {
      return result;
    }
    return {
      churnedAt: null,
      confirmedMembers: null,
    };
  });

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // ダウングレード時のデータ更新
  const addCurrentUserToPlanProtections = async () => {
    exec(async () => {
      if (!userId || !workspaceId) throw new Error();
      const doc = await rrfFirestore.get<PlanProtections>(
        `${getPlanProtectionsPath(workspaceId)}/${workspaceId}`,
      );
      if (!doc.exists) throw new Error();

      rrfFirestore.update(
        `${getPlanProtectionsPath(workspaceId)}/${workspaceId}`,
        {
          confirmedMembers: firebase.firestore.FieldValue.arrayUnion(userId),
          ...getUpdateSystemFields(userId),
        },
      );
    }, t('ダウングレードの確認に失敗しました'));
  };

  /**
   * ダウングレードバナーの表示判定
   */
  const isShowDowngradeBanner = useMemo(() => {
    // planProtectionsが存在していてchurnedAtが現在以前
    if (planProtections.churnedAt) return true;
    return false;
  }, [planProtections, planProtections?.churnedAt]);

  /**
   * ログイン中のユーザーがダウングレード内容を確認したか判定
   */
  const isConfirmedDowngrade = useMemo(() => {
    const userIDs = planProtections.confirmedMembers;
    if (userIDs === null) return null;
    if (userIDs && userIDs.find((x) => x === userId)) return true;
    return false;
  }, [planProtections, planProtections?.confirmedMembers, userId]);

  /**
   * 最大メンバー数を実際のメンバー数が上回っているか判定
   */
  const isOverflowMaxMember = useMemo(() => {
    if (!plan?.memberMaxCount || !members) return false;
    return plan?.memberMaxCount < members.length;
  }, [plan, members]);

  return {
    addCurrentUserToPlanProtections,
    planProtections,
    isShowDowngradeBanner,
    isConfirmedDowngrade,
    isOverflowMaxMember,
  };
}
