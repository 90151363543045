import React, { forwardRef, LegacyRef, ReactNode, useEffect } from 'react';

import { useFormContext, useFormState } from 'react-hook-form';

import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

import { SpinnerCircleIcon } from '@/components/Common/Icon/Icons';

export type IFormButtonProps = {
  id: string;
  className?: string;
  children: ReactNode;
  variant?: 'primary' | 'warning';
  submit?: boolean;
  disabled?: boolean;
  icon?: boolean;
  align?: 'left' | 'center' | 'right';
  noBorder?: boolean;
  shrink?: boolean;
  toolTip?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default forwardRef(
  (props: IFormButtonProps, ref: LegacyRef<HTMLButtonElement>) => {
    const {
      id,
      className,
      children,
      variant,
      submit,
      onClick,
      disabled,
      align,
      icon,
      noBorder,
      shrink,
      toolTip,
    } = props;

    const formContext = useFormContext();
    const { isSubmitting } = useFormState({
      control: formContext?.control,
    });

    useEffect(() => {
      ReactTooltip.rebuild();
    }, [toolTip]);

    useEffect(
      () => () => {
        // unmountされるタイミングでツールチップを消す
        ReactTooltip.hide();
      },
      [],
    );

    function getColorTheme() {
      if (disabled || isSubmitting) {
        return `cursor-not-allowed text-gray-50 bg-gray-400/40 dark:text-gray-500 dark:bg-gray-700/40 border-transparent ${
          !variant ? 'sm:mt-0' : ''
        }`;
      }
      if (variant === 'warning') {
        return 'text-white border-transparent dark:text-white bg-red-400 dark:bg-red-500 hover:bg-red-500 dark:hover:bg-red-600 focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-red-400 dark:focus:ring-red-500';
      }
      if (variant === 'primary') {
        return 'text-white border-transparent dark:text-white bg-primary-500 dark:bg-primary-600 hover:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600';
      }
      return 'text-gray-500 bg-white  border-gray-300 sm:mt-0 dark:border-gray-600 hover:bg-gray-100 focus:outline-none ring-0 focus:ring-2 dark:ring-gray-500 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600';
    }
    function getAlign() {
      if (align === 'left') {
        return '';
      }
      if (align === 'right') {
        return 'justify-end';
      }
      return 'justify-center';
    }
    return (
      <button
        id={id}
        ref={ref}
        type={submit ? 'submit' : 'button'}
        onClick={onClick}
        disabled={disabled || isSubmitting}
        className={classNames(
          className,
          'inline-flex font-medium rounded-md text-left items-center',
          icon ? 'p-1' : 'px-4 py-2',
          getAlign(),
          !noBorder ? 'border shadow-sm' : '',
          getColorTheme(),
          shrink ? '' : 'w-full',
        )}
      >
        <span
          data-tip={toolTip}
          data-tip-disable={false}
          className="inline-flex items-center"
        >
          {submit && isSubmitting && (
            <SpinnerCircleIcon className="w-4 h-4 mr-3" />
          )}
          {children}
        </span>
      </button>
    );
  },
);
