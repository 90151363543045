import { RootState, StatusOrder } from '@/@types/models';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { COL_STATUS_ORDERS, getStatusOrdersPath } from '@/libs/docPathUtils';
import { getUpdateSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

const initialStatusOrder: StatusOrder = {
  orderList: [],
};

/**
 * statusOrdersのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useStatusOrders() {
  const rrfFirestore = useFirestore();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject, myProjects } = useProjects();
  const { t } = useTranslation();
  const { exec } = useHandleApi();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => ({
      collection: getStatusOrdersPath(workspaceId, p.id as string),
      storeAs: `${COL_STATUS_ORDERS}/${p.id}`,
    }));
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);

  // returnするステータスオーダー
  const statusOrder: StatusOrder = useMemo(() => {
    if (!projectId || !ordered[`${COL_STATUS_ORDERS}/${projectId}`])
      return initialStatusOrder;
    return (
      ordered[`${COL_STATUS_ORDERS}/${projectId}`][0] || initialStatusOrder
    );
  }, [ordered, projectId]);

  // ステータスオーダー更新処理の実装
  const updateStatusOrder = async (
    orderList: StatusOrder['orderList'],
    docId: StatusOrder['id'],
  ) => {
    exec(async () => {
      if (!workspaceId || !projectId || !userId || !docId)
        throw new Error('status order update failed');
      rrfFirestore.update(
        `${getStatusOrdersPath(workspaceId, projectId)}/${docId}`,
        {
          orderList,
          ...getUpdateSystemFields(userId),
        },
      );
    }, t('ステータス順の更新に失敗しました'));
  };

  return {
    statusOrder,
    updateStatusOrder,
  };
}
