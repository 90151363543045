import { Item, LikedUser, RootState } from '@/@types/models';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { t } from 'i18next';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestore } from 'react-redux-firebase';

import { getLikedUsersPath } from '@/libs/docPathUtils';

import { getFullSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * サブスクの重複でpaginationが動作しなくなるので、メソッドのみlikedUsersから独立
 * @returns { }
 */
export default function useLikedActions() {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();
  const { currentMyProject } = useProjects();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  // いいね新規作成
  const createLikedUser = async (
    likedBy: LikedUser['id'],
    targetItemId: Item['id'],
  ) => {
    if (!userId || !workspaceId || !projectId || !targetItemId || !likedBy)
      throw new Error('invalid user');
    exec(async () => {
      const newLikedUser: LikedUser = {
        likedAt: serverTimestamp() as Timestamp,
        likedBy,
        ...getFullSystemFields(likedBy),
      };
      await rrfFirestore.set<LikedUser>(
        `${getLikedUsersPath(workspaceId, projectId, targetItemId)}/${likedBy}`,
        newLikedUser,
      );
    }, t('いいねの投稿に失敗しました'));
  };

  // いいね削除
  const deleteLikedUser = async (
    likedUserId: LikedUser['id'],
    targetItemId: Item['id'],
  ) => {
    if (!userId || !workspaceId || !projectId || !targetItemId)
      throw new Error('invalid user');
    exec(async () => {
      await rrfFirestore.delete(
        `${getLikedUsersPath(
          workspaceId,
          projectId,
          targetItemId,
        )}/${likedUserId}`,
      );
    }, t('いいねの削除に失敗しました'));
  };

  return {
    createLikedUser,
    deleteLikedUser,
  };
}
