import { MyProject, View } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { z } from 'zod';

import { VALIDATION_MODE, ViewTypes } from '@/libs/const';

import { t2s } from '@/libs/utils';

import { VIEW_NAME } from '@/libs/validations';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import CreateViewForm from '@/components/View/CreateViewForm';

import useViews from '@/hooks/useViews';

interface INewViewDialogProps extends IModalDialogProps {
  myProject: MyProject;
}

export default function NewViewDialog(props: INewViewDialogProps) {
  const { myProject, onClose } = props;
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<View['viewType']>(ViewTypes.LIST);
  const { createView } = useViews();

  // Zod schema定義
  const schema = z.object({
    viewName: z
      .string()
      .min(1, { message: t('入力してください') })
      .max(VIEW_NAME.max, t2s(t('<max>文字以内で入力してください', VIEW_NAME))),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  // フォームリセット処理
  const formReset = () => {
    setViewType(ViewTypes.LIST);
    methods.reset({ viewName: '' });
  };

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    const { viewName } = data;
    await createView(viewName, viewType, myProject.projectId as string);
    // Reset form state
    formReset();
    onClose();
  });

  // viewのステート更新(ZODで管理できないためローカルのステートで管理)
  const handleViewSelect = (view: View['viewType']) => {
    setViewType(view);
  };

  // モーダルクローズ時
  const handleClose = () => {
    // Reset form state
    formReset();
    onClose();
  };

  return (
    <FormDialog
      onClose={onClose}
      title={t('ビューを追加')}
      onSubmit={formSubmit}
    >
      <FormProvider {...methods}>
        <CreateViewForm
          selectViewLabel={t('追加するビュー')}
          selectedView={viewType}
          onViewSelect={handleViewSelect}
        />
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="addView"
          disabled={!methods.formState.isValid}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('追加')}
        </FormButton>
        <FormButton
          id="cancelAddView"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={handleClose}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
