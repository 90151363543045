import { View } from '@/@types/models';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Timestamp } from 'firebase/firestore';
import { t } from 'i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DayjsUtil, FormatType } from '@/libs/dayjs';
import { getNavigatePath, parseFsTimestamp } from '@/libs/utils';

import { openItemEditDialog } from '@/reducers/itemsReducer';

import AvatarIcon from '@/components/Common/AvatarIcon';
import Divider from '@/components/Common/Divider';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import useGuests from '@/hooks/useGuests';
import useMentions from '@/hooks/useMentions';

import useMyProjectUnknownGuests from '@/hooks/useMyProjectUnknownGuests';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';
import useToComments from '@/hooks/useToComments';
import useViews from '@/hooks/useViews';

type People = {
  id: string;
  name: string;
  date: string;
  description: string;
  imageUrl: string | null;
  postedName: string;
  workspaceId: string;
  projectId: string;
  itemId: string;
  isUnreadLine: boolean;
};

const defaultUser = {
  displayName: '****',
  photoURL: null,
  joinType: '',
};

const loadingUser = {
  displayName: '…',
  photoURL: null,
  joinType: '',
};

const defaultProjectName = 'アクセス権のないプロジェクト';

export default function NotificationList(props: {
  closeDrawer: (value: boolean) => void;
}) {
  const { closeDrawer } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getMyProject } = useProjects();
  const { loadNextPage, hasNext, toComments, deleteToComment } =
    useToComments();
  const { currentMyWorkspace, updateNotificationComment } = useMyWorkspaces();
  const { getProjectTopView } = useViews();
  const { membersDic } = useMentions();
  const { myProjectUnknownGuestsDic } = useMyProjectUnknownGuests();
  const { removedMembersDic } = useRemovedMembers();
  const { myProjectsRemovedGuestsDic } = useRemovedGuests();
  const { myProjectsGuestsDic } = useGuests();

  // ユーザの突合はmyProjectsのすべてのユーザから検索
  const membersAndGuestsDic = useMemo(
    () => ({
      ...membersDic,
      ...myProjectUnknownGuestsDic,
      ...myProjectsRemovedGuestsDic,
      ...removedMembersDic,
      ...myProjectsGuestsDic,
    }),
    [
      membersDic,
      myProjectUnknownGuestsDic,
      myProjectsRemovedGuestsDic,
      removedMembersDic,
      myProjectsGuestsDic,
    ],
  );

  // ローカルステート
  const initialUnreadAtRef = useRef<DayjsUtil | null>(null);
  const initialIsNotificationRef = useRef<boolean>(false);
  const [isDelayToCommentList, setIsDelayToCommentList] = useState(false);
  const scrollBottomRef = useRef<HTMLDivElement>(null);

  // 初期化処理
  useEffect(() => {
    // 最終閲覧日時と未読状態か保持しておく
    if (toComments && currentMyWorkspace?.toCommentsLastViewedAt) {
      const unreadAt = new DayjsUtil(
        parseFsTimestamp(currentMyWorkspace?.toCommentsLastViewedAt),
      );
      initialUnreadAtRef.current = unreadAt;
      initialIsNotificationRef.current =
        !!currentMyWorkspace.toCommentsUnReadCount;
    }
    setTimeout(() => {
      setIsDelayToCommentList(true);
    });
    // マウント時、終了後に最終閲覧日時を更新、未読件数をリセット
    updateNotificationComment();
    return () => {
      updateNotificationComment();
    };
  }, []);

  // 通知データを生成
  const people = useMemo(() => {
    const updatePeople: People[] = [];
    let isUnread = false;

    toComments.forEach((c) => {
      if (
        c &&
        c.postedBy &&
        c.projectId &&
        c.itemId &&
        c.id &&
        c.workspaceId &&
        initialUnreadAtRef.current
      ) {
        // 投稿ユーザを突合
        let user = membersAndGuestsDic[c.postedBy] || loadingUser;

        // myProjectと突合できなかったらデフォルト名
        const project = getMyProject(c.projectId);
        const projectName = project ? project.projectName : defaultProjectName;
        user = project === null ? defaultUser : user;
        const message = `${user.displayName} が ${projectName} の ${c.title} にコメントしました。`;

        // 未読位置の判定
        let isAfter = false;
        if (!isUnread) {
          const commentDate = new DayjsUtil(
            parseFsTimestamp(c.sysCreatedAt as Timestamp),
          );
          isAfter = initialUnreadAtRef.current.isAfter(
            commentDate,
            'second',
            true,
          );
          isUnread = !!isAfter;
        }

        const format = t('YYYY-MM-DD HH-mm');
        const newPeople: People = {
          id: c.id,
          name: message,
          description: c.plainText || '',
          date: new DayjsUtil(
            parseFsTimestamp(c.sysCreatedAt as Timestamp),
          ).format(format as FormatType),
          imageUrl: user.photoURL || null,
          postedName: user.displayName || '',
          workspaceId: c.workspaceId,
          projectId: c.projectId,
          itemId: c.itemId,
          isUnreadLine: isAfter,
        };
        updatePeople.push(newPeople);
      }
    });
    return updatePeople;
  }, [toComments, membersAndGuestsDic]);

  const handleClick = (person: People) => {
    // クリック時ハンドラ
    const view = getProjectTopView(person.projectId) as View;
    navigate(
      getNavigatePath(
        person.workspaceId as string,
        person.projectId as string,
        view.id,
        person.itemId as string,
      ),
      {
        replace: true,
      },
    );
    dispatch(openItemEditDialog(person.itemId));
    closeDrawer(false);
  };

  // 通知をrender
  const render = useMemo(
    () => (
      <>
        {people.map((person) => (
          <>
            {person.isUnreadLine && initialIsNotificationRef.current && (
              <li>
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-red-300 dark:border-red-700" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 font-bold text-red-400 dark:text-red-700 bg-gray-50 dark:bg-gray-700">
                      {t('ここまで未読')}
                    </span>
                  </div>
                </div>
              </li>
            )}
            <li
              key={person.id}
              className="relative mx-1 my-1 bg-white rounded-lg shadow group dark:bg-gray-800 col-span-1 hover:ring-2 hover:ring-offset-0 hover:ring-primary-500 dark:hover:ring-primary-600"
            >
              <div
                role="button"
                className="w-full text-left"
                tabIndex={0}
                onClick={() => {
                  handleClick(person);
                }}
                onKeyDown={() => {
                  handleClick(person);
                }}
              >
                <div className="flex items-center w-full p-5 space-x-6">
                  <AvatarIcon
                    src={person.imageUrl}
                    avatarName={person.postedName}
                    size="md"
                  />

                  <div className="flex-1">
                    <div className="flex items-center space-x-3">
                      <h3 className="text-sm font-bold text-gray-900 break-all dark:text-gray-300">
                        {person.name}
                      </h3>
                    </div>
                    {person.description && (
                      <p className="mt-2 text-gray-600 dark:text-gray-400">
                        {person.description}
                      </p>
                    )}
                    <p className="mt-2 text-xs text-gray-500 truncate">
                      {person.date}
                    </p>
                  </div>
                  <div className="flex items-end md:opacity-0 md:group-hover:opacity-100">
                    <MenuButton
                      id="removeNotification"
                      tabIndex={-1}
                      type="icon"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        deleteToComment(person.id);
                      }}
                    >
                      <XMarkIcon className="w-4 h-4" />
                    </MenuButton>
                  </div>
                </div>
              </div>
            </li>
          </>
        ))}
      </>
    ),
    [people, currentMyWorkspace?.toCommentsUnReadCount],
  );

  return (
    <div
      id="notifyScroll"
      className="relative flex-1 h-screen px-4 pt-1 pb-6 mt-3 overflow-y-auto sm:px-6"
    >
      {isDelayToCommentList && (
        <InfiniteScroll
          scrollThreshold="100px"
          dataLength={people.length}
          next={loadNextPage}
          hasMore={hasNext}
          loader={<Divider label={t('読込中')} className="mx-4 mb-3" />}
          scrollableTarget="notifyScroll"
        >
          <ul className="grid grid-cols-1 gap-1">{render}</ul>
          <div ref={scrollBottomRef} />
        </InfiniteScroll>
      )}
    </div>
  );
}
