import { Project, PropertyOrder, Workspace } from '@/@types/models';
import { doc, getDoc } from 'firebase/firestore';

import { REP_ERROR_MESSAGE } from '@/libs/const';

import { getPropertyOrdersPath } from '@/libs/docPathUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { firestore } from '@/firestore';

export interface IPropertyOrderRepository {
  workspaceId: Workspace['id'];
  projectId: Project['id'];
  findOne: () => Promise<PropertyOrder | null>;
}

export default class PropertyOrderRepository
  implements IPropertyOrderRepository
{
  workspaceId: Workspace['id'];

  projectId: Project['id'];

  constructor(private wsid: string, private pjid: string) {
    this.workspaceId = wsid;
    this.projectId = pjid;
  }

  // 単体PropertyOrder取得
  async findOne(): Promise<PropertyOrder | null> {
    try {
      const path = `${getPropertyOrdersPath(
        this.workspaceId,
        this.projectId,
      )}/${this.projectId}`;
      const docRef = doc(firestore, path).withConverter(
        fsConverter<PropertyOrder>(),
      );
      const data = await getDoc(docRef);
      const propertyOrder = data.data();
      if (propertyOrder === undefined) {
        return null;
      }
      return propertyOrder;
    } catch (err: any) {
      repositoryError(this.findOne.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }
}
