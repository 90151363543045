import { RootState } from '@/@types/models';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  OrderByOptions,
  useFirestoreConnect,
  WhereOptions,
} from 'react-redux-firebase';

const PER_PAGE = 10; // TODO: 仮定数

interface Query {
  limit: number;
  orderBy: OrderByOptions | OrderByOptions[] | undefined;
  where?: WhereOptions | WhereOptions[] | undefined;
}

/**
 * ページネーション機能付きのサブスクリプション & リスト取得
 * @param collectionPath 該当ドキュメントへのパス
 * @param orderBy 初期ソート
 * @param storeAs ストアでの識別ID
 * @param guardCb useFirestoreConnectでの早期リターン用関数
 * @returns
 */
export default function usePaginate<
  T,
  K extends keyof RootState['firestore']['ordered'],
>(
  collectionPath: string,
  where: WhereOptions | WhereOptions[] | undefined,
  orderBy: OrderByOptions | OrderByOptions[] | undefined,
  limit: number,
  storeAs: K,
  guardCb: (...arg: any[]) => boolean,
) {
  interface QueryOption {
    collection?: string;
    collectionGroup?: string;
    orderBy: Query['orderBy'];
    limit: Query['limit'];
    storeAs: K;
    where: Query['where'];
  }

  const [query, setQuery] = useState<Query>({
    limit: limit || PER_PAGE,
    orderBy,
    where,
  });

  // subscriptionの実行
  useFirestoreConnect(() => {
    if (guardCb()) return [];
    const baseOpt: QueryOption = {
      orderBy: query.orderBy,
      limit: query.limit + 1,
      storeAs,
      where: query.where,
    };
    if (collectionPath.indexOf('/') > 0) {
      baseOpt.collection = collectionPath;
    } else {
      baseOpt.collectionGroup = collectionPath;
    }
    return [baseOpt];
  });

  // 該当ストアのデータを取得
  const data = useSelector(
    (state: RootState) => state.firestore.ordered[storeAs] || [],
  ) as T[];

  // データリスト本体の取得と
  const { dataList, hasNext } = useMemo(() => {
    if (!data) return { dataList: [], hasNext: false };
    return {
      dataList: data?.slice(0, query.limit) || [],
      hasNext: data?.length > query.limit,
    };
  }, [data, query]);

  // 次のページを読み込む
  const loadNextPage = useCallback(() => {
    setQuery((current) => ({ ...current, limit: current.limit + PER_PAGE }));
  }, [setQuery]);

  return {
    dataList,
    hasNext,
    loadNextPage,
  };
}
