import { RootState } from '@/@types/models';

import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { INDETERMINATE_VIEW_ID } from '@/libs/const';
import {
  getMyWorkspaceList,
  hasExpectedItem,
  hasExpectedMyProject,
  hasExpectedView,
  routeHandleItemQuery,
  routeHandleMyProjectQuery,
  routeHandleMyWorkspaceQuery,
  routeHandleViewQuery,
} from '@/libs/repositoryUtils';

import { getNavigatePath } from '@/libs/utils';

import AccountRepository from '@/repositories/AccountRepository';

import ViewRepository from '@/repositories/ViewRepository';

import { setCurrentProjectIdAction } from '@/reducers/currentProjectReducer';
import { setCurrentViewId as setCurrentViewIdAction } from '@/reducers/currentViewIdReducer';
import { setCurrentMyWorkspaceIdAction } from '@/reducers/currentWorkspaceReducer';
import { openItemEditDialog } from '@/reducers/itemsReducer';

import { setIsLoaded } from '@/reducers/uiStateReducer';

import AuthorizedIndex from '@/pages/AuthorizedIndex';

import UnauthorizedIndex from '@/pages/UnauthorizedIndex';

import useFirebaseAuth from '@/hooks/useFirebaseAuth';
import useMonitorApp from '@/hooks/useMonitorApp';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePresences from '@/hooks/usePresences';

/**
 * `/workspaceId/p/projectId/v/viewId` アクセスのルーティング制御
 * @returns void
 */
function useRouteNavigate() {
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const urlParams = useParams();
  const {
    workspaceId: originalWorkspaceId,
    projectId,
    viewId,
    itemId,
  } = urlParams;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  // プレゼンス
  usePresences();

  const resolve = useCallback(async () => {
    dispatch(setIsLoaded(false));
    if (!userId && originalWorkspaceId && projectId && viewId) {
      // ※ 非認証ユーザーの場合
      const accountRepository = new AccountRepository();
      const response = await accountRepository.checkUnauthorizedAccountAccess(
        originalWorkspaceId,
        projectId,
        viewId,
      );
      if (response === false) {
        toast.error('アクセスが許可されていません');
        return navigate('/404');
      }
      dispatch(setCurrentMyWorkspaceIdAction(response.workspaceId));
      dispatch(setCurrentProjectIdAction(projectId));

      // 確定ViewID
      let fixedViewId = viewId;
      if (viewId === INDETERMINATE_VIEW_ID) {
        // 不確定viewIDの場合
        const viewRepository = new ViewRepository(
          response.workspaceId,
          projectId,
        );
        const topView = await viewRepository.topView();
        navigate(getNavigatePath(originalWorkspaceId, projectId, topView?.id), {
          replace: true,
        });
        fixedViewId = topView?.id as string;
      }

      dispatch(setCurrentViewIdAction(fixedViewId));
      return dispatch(setIsLoaded(true));
    }

    // ※ 認証ユーザーの場合
    if (originalWorkspaceId && projectId && viewId) {
      // 要求されたWSの存在検証
      const workspaceQuery = routeHandleMyWorkspaceQuery(originalWorkspaceId);
      const myWorkspaceList = await getMyWorkspaceList(userId, workspaceQuery);
      if (myWorkspaceList.length > 0 && myWorkspaceList[0].id) {
        const { joinType, id: myWorkspaceId, workspaceId } = myWorkspaceList[0];

        // URLに指定されたmyProjectの存在検証
        const projectQuery = routeHandleMyProjectQuery(joinType);
        const isExpectedMyProject = await hasExpectedMyProject(
          userId,
          myWorkspaceId,
          projectId,
          projectQuery,
        );
        if (workspaceId && projectId && isExpectedMyProject) {
          // URLに指定されたviewの存在検証
          const viewQuery = routeHandleViewQuery(joinType);

          // 確定ViewID
          let fixedViewId = viewId;
          if (viewId === INDETERMINATE_VIEW_ID) {
            // 不確定viewIDの場合
            const viewRepository = new ViewRepository(
              myWorkspaceList[0].id,
              projectId,
            );
            const topView = await viewRepository.topView();
            navigate(
              getNavigatePath(originalWorkspaceId, projectId, topView?.id),
              {
                replace: true,
              },
            );
            fixedViewId = topView?.id as string;
          }

          const isExpectedView = await hasExpectedView(
            workspaceId,
            projectId,
            fixedViewId as string,
            viewQuery,
          );
          if (isExpectedView) {
            if (itemId) {
              // URLに指定されたitemの存在検証
              const itemQuery = routeHandleItemQuery(joinType);
              const isExpectedItem = await hasExpectedItem(
                workspaceId,
                projectId,
                itemId as string,
                itemQuery,
              );
              if (isExpectedItem) {
                dispatch(openItemEditDialog(itemId));
                navigate(
                  getNavigatePath(
                    originalWorkspaceId,
                    projectId,
                    fixedViewId,
                    itemId,
                  ),
                  {
                    replace: true,
                  },
                );
              } else {
                // 存在しないアイテムが指定された場合
                navigate(
                  getNavigatePath(originalWorkspaceId, projectId, fixedViewId),
                  {
                    replace: true,
                  },
                );
              }
            }

            dispatch(setCurrentMyWorkspaceIdAction(workspaceId));
            dispatch(setCurrentProjectIdAction(projectId));
            dispatch(setCurrentViewIdAction(fixedViewId));
            return dispatch(setIsLoaded(true));
          }
        }
      }
    }
    return navigate('/404');
  }, []);

  return {
    resolve,
  };
}

/**
 * `/workspaceId/p/projectId/v/viewId` アクセスの場合
 */
export default function ViewIndex() {
  useMonitorApp();
  const navigate = useNavigate();
  const { resolve } = useRouteNavigate();
  const { currentMyWorkspace, myWorkspaces } = useMyWorkspaces();
  const { isAuthenticated } = useFirebaseAuth();
  const { isLoaded } = useSelector((state: RootState) => state.uiState);
  const { workspaceId: originalWorkspaceId } = useParams();

  // ビューを閲覧中にメンバーから除外された対策
  useEffect(() => {
    if (
      isLoaded &&
      !myWorkspaces.find((o) => o.originalWorkspaceId === originalWorkspaceId)
    )
      navigate('/');
  }, [isLoaded, myWorkspaces, originalWorkspaceId]);

  // マウント時初期化処理
  useEffect(() => {
    resolve();
  }, []);

  // 画面レンダリング(isLoaded=true)後に非認証状態になった場合(他画面でのログアウト等)
  useEffect(() => {
    if (isLoaded && !isAuthenticated) navigate('/');
  }, [isLoaded, isAuthenticated]);

  if (!isLoaded || !currentMyWorkspace) return <div />;
  // 非認証ユーザーアクセスの場合
  if (currentMyWorkspace.joinType === 'unknownGuest') {
    return <UnauthorizedIndex />;
  }
  // 認証ユーザーアクセスの場合
  return <AuthorizedIndex />;
}
