import { Menu } from '@headlessui/react';
import React, { memo, useMemo } from 'react';

import { classNames } from '@/libs/styleUtils';

interface IMenuDropdownItemProps {
  label: string;
  type: 'primary' | 'danger';
  className?: string;
  onClick: (...args: any[]) => void;
}

function MenuDropdownItem(props: IMenuDropdownItemProps) {
  const { label, type, className, onClick } = props;

  const activeClass = useMemo(() => {
    switch (type) {
      case 'primary':
        return 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white';
      case 'danger':
        return 'bg-red-400 dark:bg-red-500 text-white dark:text-white';
      default:
        return '';
    }
  }, [type]);

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type="button"
          className={classNames(
            active ? activeClass : '',
            'w-full block px-4 py-2 text-left',
            className,
          )}
          onClick={onClick}
        >
          <div className="inline-block align-text-top">{label}</div>
        </button>
      )}
    </Menu.Item>
  );
}

export default memo(MenuDropdownItem);
