import { Item, Body, RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { COL_BODY, getBodiesPath } from '@/libs/docPathUtils';

import { getFullSystemFields, getUpdateSystemFields } from '@/libs/utils';

import useAccessSettings from '@/hooks/useAccessSettings';
import useHandleApi from '@/hooks/useHandleApi';

import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * Bodiesのリアルタイムアップデートを購読するためのHooks
 * @returns { bodies, createBodies }
 */
export default function useBodies() {
  const rrfFirestore = useFirestore();
  const { t } = useTranslation();
  const { exec } = useHandleApi();

  const { currentMyWorkspace, isMember } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const { currentItemId } = useItems();

  const { accessSetting } = useAccessSettings();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const itemId = useMemo(() => currentItemId ?? undefined, [currentItemId]);

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // サブスクリプション
  useFirestoreConnect(() =>
    workspaceId &&
    projectId &&
    itemId &&
    (isMember || (!isMember && accessSetting?.isBodyVisible === true))
      ? [
          {
            collection: getBodiesPath(workspaceId, projectId, itemId),
            storeAs: `${COL_BODY}/${itemId}`,
            doc: itemId,
          },
        ]
      : [],
  );

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);

  // 指定したアイテムIDのbodyを返す
  // TODO: サブコレクションで書きたい
  const currentBody = useMemo(
    () =>
      (ordered[`${COL_BODY}/${itemId}`]
        ? ordered[`${COL_BODY}/${itemId}`][0]
        : {}) as Body,
    [ordered[`${COL_BODY}/${itemId}`]],
  );

  /**
   * 本文更新
   * @param targetItemId
   * @param editorState
   * @param plainText
   */
  const updateBody = async (
    targetItemId: Item['id'],
    editorState: Body['editorState'],
    plainText: Body['plainText'],
  ) => {
    if (!workspaceId || !projectId || !targetItemId)
      throw new Error('update body failed.');
    exec(async () => {
      const docPath = `${getBodiesPath(
        workspaceId,
        projectId,
        targetItemId,
      )}/${targetItemId}`;

      const isExists = (await rrfFirestore.doc(docPath).get()).exists;

      // bodiesが存在する場合はupdate、無ければset
      if (isExists) {
        await rrfFirestore.update<Body>(docPath, {
          editorState,
          plainText,
          ...getUpdateSystemFields(userId),
        });
      } else {
        await rrfFirestore.set<Body>(docPath, {
          editorState,
          plainText,
          ...getFullSystemFields(userId),
        });
      }
    }, t('本文の更新に失敗しました'));
  };

  return {
    updateBody,
    currentBody,
  };
}
