import { RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestoreConnect } from 'react-redux-firebase';

import { COL_X_MEMBERS, getXMembersPath } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * _Membersの集約に関するHooks
 * @returns { _members }
 */
export default function useXMembers() {
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  useFirestoreConnect(() =>
    workspaceId
      ? [
          {
            collection: getXMembersPath(workspaceId),
            storeAs: COL_X_MEMBERS,
          },
        ]
      : [],
  );

  const _members = useSelector(
    (state: RootState) => state.firestore.ordered._members || [],
  );

  return { _members };
}
