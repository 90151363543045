import { RootState } from '@/@types/models';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * firestoreの該当サブスクリプションが全て実行されているかをキーの存在により判定する
 */
export default function useMonitorSubscription(
  keys: (keyof RootState['firestore']['ordered'])[],
) {
  const store = useSelector((state: RootState) => state.firestore.ordered);
  const isSubscriptionPrepared = useMemo(
    () => keys.every((k) => store[k] !== undefined),
    [store],
  );

  return {
    isSubscriptionPrepared,
  };
}
