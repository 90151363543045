import { ValidationMode } from 'react-hook-form';

/** 環境 */
export const ENV = {
  DEV: 'development',
  STG: 'staging',
  PRD: 'production',
};

/** domain */
export const BASE_DOMAIN = 'collabble.jp';

/** Repository エラーメッセージ */
export const REP_ERROR_MESSAGE = 'Repository error.';

/** UIテーマ */
export const THEMES = {
  DEFAULT: 'default',
  DARK: 'dark',
} as const;

/** LocalStorage保存用のキー */
export const LOCAL_STORAGE_KEYS = {
  LOGIN_INFO: 'loginInfo',
  THEME: 'theme',
  SIDEBAR_MINIMIZE: 'isSidebarMinimize',
} as const;

/** 指定可能なカラーとクラスの対応リスト */
export const ORIGINAL_COLORS = {
  ORIGINAL_RED: {
    label: 'originalRed',
    bg: 'dark:!bg-originalRed !bg-originalRed-light',
    text: 'dark:!text-originalRed-text !text-originalRed-light-text',
    bgHover:
      'dark:hover:!bg-originalRed-hover hover:!bg-originalRed-light-hover',
    textHover:
      'dark:hover:!text-originalRed-text_hover hover:!text-originalRed-text_hover',
    border: 'dark:!border-originalRed !border-originalRed-light',
  },
  ORIGINAL_PINK: {
    label: 'originalPink',
    bg: 'dark:!bg-originalPink !bg-originalPink-light',
    text: 'dark:!text-originalPink-text !text-originalPink-light-text',
    bgHover:
      'dark:hover:!bg-originalPink-hover hover:!bg-originalPink-light-hover',
    textHover:
      'dark:hover:!text-originalPink-text_hover hover:!text-originalPink-text_hover',
    border: 'dark:!border-originalPink !border-originalPink-light',
  },
  ORIGINAL_LIGHT_PINK: {
    label: 'originalLightPink',
    bg: 'dark:!bg-originalLightPink !bg-originalLightPink-light',
    text: 'dark:!text-originalLightPink-text !text-originalLightPink-light-text',
    bgHover:
      'dark:hover:!bg-originalLightPink-hover hover:!bg-originalLightPink-light-hover',
    textHover:
      'dark:hover:!text-originalLightPink-text_hover hover:!text-originalLightPink-text_hover',
    border: 'dark:!border-originalLightPink !border-originalLightPink-light',
  },
  ORIGINAL_YELLOW: {
    label: 'originalYellow',
    bg: 'dark:!bg-originalYellow !bg-originalYellow-light',
    text: 'dark:!text-originalYellow-text !text-originalYellow-light-text',
    bgHover:
      'dark:hover:!bg-originalYellow-hover hover:!bg-originalYellow-light-hover',
    textHover:
      'dark:hover:!text-originalYellow-text_hover hover:!text-originalYellow-text_hover',
    border: 'dark:!border-originalYellow !border-originalYellow-light',
  },
  ORIGINAL_LIGHT_YELLOW: {
    label: 'originalLightYellow',
    bg: 'dark:!bg-originalLightYellow !bg-originalLightYellow-light',
    text: 'dark:!text-originalLightYellow-text !text-originalLightYellow-light-text',
    bgHover:
      'dark:hover:!bg-originalLightYellow-hover hover:!bg-originalLightYellow-light-hover',
    textHover:
      'dark:hover:!text-originalLightYellow-text_hover hover:!text-originalLightYellow-text_hover',
    border:
      'dark:!border-originalLightYellow !border-originalLightYellow-light',
  },
  ORIGINAL_GREEN: {
    label: 'originalGreen',
    bg: 'dark:!bg-originalGreen !bg-originalGreen-light',
    text: 'dark:!text-originalGreen-text !text-originalGreen-light-text',
    bgHover:
      'dark:hover:!bg-originalGreen-hover hover:!bg-originalGreen-light-hover',
    textHover:
      'dark:hover:!text-originalGreen-text_hover hover:!text-originalGreen-text_hover',
    border: 'dark:!border-originalGreen !border-originalGreen-light',
  },
  ORIGINAL_LIME: {
    label: 'originalLime',
    bg: 'dark:!bg-originalLime !bg-originalLime-light',
    text: 'dark:!text-originalLime-text !text-originalLime-light-text',
    bgHover:
      'dark:hover:!bg-originalLime-hover hover:!bg-originalLime-light-hover',
    textHover:
      'dark:hover:!text-originalLime-text_hover hover:!text-originalLime-text_hover',
    border: 'dark:!border-originalLime !border-originalLime-light',
  },
  ORIGINAL_LIGHT_GREEN: {
    label: 'originalLightGreen',
    bg: 'dark:!bg-originalLightGreen !bg-originalLightGreen-light',
    text: 'dark:!text-originalLightGreen-text !text-originalLightGreen-light-text',
    bgHover:
      'dark:hover:!bg-originalLightGreen-hover hover:!bg-originalLightGreen-light-hover',
    textHover:
      'dark:hover:!text-originalLightGreen-text_hover hover:!text-originalLightGreen-text_hover',
    border: 'dark:!border-originalLightGreen !border-originalLightGreen-light',
  },
  ORIGINAL_PURPLE: {
    label: 'originalPurple',
    bg: 'dark:!bg-originalPurple !bg-originalPurple-light',
    text: 'dark:!text-originalPurple-text !text-originalPurple-light-text',
    bgHover:
      'dark:hover:!bg-originalPurple-hover hover:!bg-originalPurple-light-hover ',
    textHover:
      'dark:hover:!text-originalPurple-text_hover hover:!text-originalPurple-text_hover',
    border: 'dark:!border-originalPurple !border-originalPurple-light',
  },
  ORIGINAL_LIGHT_PURPLE: {
    label: 'originalLightPurple',
    bg: 'dark:!bg-originalLightPurple !bg-originalLightPurple-light',
    text: 'dark:!text-originalLightPurple-text !text-originalLightPurple-light-text',
    bgHover:
      'dark:hover:!bg-originalLightPurple-hover hover:!bg-originalLightPurple-light-hover',
    textHover:
      'dark:hover:!text-originalLightPurple-text_hover hover:!text-originalLightPurple-text_hover',
    border:
      'dark:!border-originalLightPurple !border-originalLightPurple-light',
  },
  ORIGINAL_BLUE: {
    label: 'originalBlue',
    bg: 'dark:!bg-originalBlue !bg-originalBlue-light ',
    text: 'dark:!text-originalBlue-text !text-originalBlue-light-text',
    bgHover:
      'dark:hover:!bg-originalBlue-hover hover:!bg-originalBlue-light-hover',
    textHover:
      'dark:hover:!text-originalBlue-text_hover hover:!text-originalBlue-text_hover',
    border: 'dark:!border-originalBlue !border-originalBlue-light',
  },
  ORIGINAL_SKY: {
    label: 'originalSky',
    bg: 'dark:!bg-originalSky !bg-originalSky-light',
    text: 'dark:!text-originalSky-text !text-originalSky-light-text',
    bgHover:
      'dark:hover:!bg-originalSky-hover hover:!bg-originalSky-light-hover',
    textHover:
      'dark:hover:!text-originalSky-text_hover hover:!text-originalSky-text_hover',
    border: 'dark:!border-originalSky !border-originalSky-light',
  },
  ORIGINAL_LIGHT_BLUE: {
    label: 'originalLightBlue',
    bg: 'dark:!bg-originalLightBlue !bg-originalLightBlue-light',
    text: 'dark:!text-originalLightBlue-text !text-originalLightBlue-light-text',
    bgHover:
      'dark:hover:!bg-originalLightBlue-hover hover:!bg-originalLightBlue-light-hover',
    textHover:
      'dark:hover:!text-originalLightBlue-text_hover hover:!text-originalLightBlue-text_hover',
    border: 'dark:!border-originalLightBlue !border-originalLightBlue-light',
  },
  ORIGINAL_GRAY: {
    label: 'originalGray',
    bg: 'dark:!bg-originalGray !bg-originalGray-light',
    text: 'dark:!text-originalGray-text !text-originalGray-light-text',
    bgHover:
      'dark:hover:!bg-originalGray-hover hover:!bg-originalGray-light-hover',
    textHover:
      'dark:hover:!text-originalGray-text_hover hover:!text-originalGray-text_hover',
    border: 'dark:!border-originalGray !border-originalGray-light',
  },
  ORIGINAL_LIGHT_GRAY: {
    label: 'originalLightGray',
    bg: 'dark:!bg-originalLightGray !bg-originalLightGray-light',
    text: 'dark:!text-originalLightGray-text !text-originalLightGray-light-text',
    bgHover:
      'dark:hover:!bg-originalLightGray-hover hover:!bg-originalLightGray-light-hover',
    textHover:
      'dark:hover:!text-originalLightGray-text_hover hover:!text-originalLightGray-text_hover',
    border: 'dark:!border-originalLightGray !border-originalLightGray-light',
  },
} as const;

/** AlertやBadge用のカラーリスト */
export const TAILWIND_COLORS = {
  PRIMARY: { label: 'primary', bg: 'bg-primary-200 dark:bg-primary-800' },
  SLATE: { label: 'slate', bg: 'bg-slate-200 dark:bg-slate-800' },
  GRAY: { label: 'gray', bg: 'bg-gray-200 dark:bg-gray-800' },
  ZINC: { label: 'zinc', bg: 'bg-zinc-200 dark:bg-zinc-800' },
  NEUTRAL: { label: 'neutral', bg: 'bg-neutral-200 dark:bg-neutral-800' },
  STONE: { label: 'stone', bg: 'bg-stone-200 dark:bg-stone-800' },
  RED: { label: 'red', bg: 'bg-red-200 dark:bg-red-800' },
  ORANGE: { label: 'orange', bg: 'bg-orange-200 dark:bg-orange-800' },
  AMBER: { label: 'amber', bg: 'bg-amber-200 dark:bg-amber-800' },
  YELLOW: { label: 'yellow', bg: 'bg-yellow-200 dark:bg-yellow-800' },
  LIME: { label: 'lime', bg: 'bg-lime-200 dark:bg-lime-800' },
  GREEN: { label: 'green', bg: 'bg-green-200 dark:bg-green-800' },
  EMERALD: { label: 'emerald', bg: 'bg-emerald-200 dark:bg-emerald-800' },
  TEAL: { label: 'teal', bg: 'bg-teal-200 dark:bg-teal-800' },
  CYAN: { label: 'cyan', bg: 'bg-cyan-200 dark:bg-cyan-800' },
  SKY: { label: 'sky', bg: 'bg-sky-200 dark:bg-sky-800' },
  BLUE: { label: 'blue', bg: 'bg-blue-200 dark:bg-blue-800' },
  INDIGO: { label: 'indigo', bg: 'bg-indigo-200 dark:bg-indigo-800' },
  VIOLET: { label: 'violet', bg: 'bg-violet-200 dark:bg-violet-800' },
  PURPLE: { label: 'purple', bg: 'bg-purple-200 dark:bg-purple-800' },
  FUCHSIA: { label: 'fuchsia', bg: 'bg-fuchsia-200 dark:bg-fuchsia-800' },
  PINK: { label: 'pink', bg: 'bg-pink-200 dark:bg-pink-800' },
  ROSE: { label: 'rose', bg: 'bg-rose-200 dark:bg-rose-800' },
} as const;

/** メンションリスト用正規表現 */
export const MENTION_ALLOWED_CHARS = /^[A-Za-z\sÅÄÖåäöァ-ンヴーぁ-んー一-龠]*$/;

/** React Hook Form バリデーションモード */
export const VALIDATION_MODE: keyof ValidationMode | undefined = 'onChange';

/**
 * zod.email()と同様の正規表現
 * https://github.com/colinhacks/zod/blob/master/src/types.ts#L443
 * */
export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /(https?:\/\/[\w\-\\.\\/\\?\\,%&=\\#\\:\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+)/g;

/** 選択可能なビュー種別 */
export const ViewTypes = {
  LIST: 'list',
  KANBAN: 'kanban',
  BILLBOARD: 'billboard',
  SCHEDULE: 'schedule',
} as const;

/** firebaseエラーコード */
export const FIREBASE_ERROR_CODES = {
  POPUP_CLOSED_BY_USER: 'auth/popup-closed-by-user',
  CANCELLED_POPUP_REQUEST: 'auth/cancelled-popup-request',
} as const;

/** マスキング設定 */
export const MASKING_OPTIONS = {
  EMAIL: {
    label: 'EMAIL',
    maskWith: '*',
    unmaskedStartCharactersBeforeAt: 3,
    unmaskedEndCharactersAfterAt: 2,
    maskAtTheRate: false,
  },
} as const;

/** プロパティ設定 種別 */
export const PROPERTY_TYPE = {
  TEXT: 'text',
  SINGLE_SELECT: 'singleSelect',
  FILE: 'file',
  DATE: 'date',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  MULTI_SELECT: 'multiSelect',
  STATUS: 'status',
  INCHARGE: 'incharge',
  CREATED_BY: 'createdBy',
  UPDATED_BY: 'updatedBy',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
} as const;

/** 数値プロパティ フォーマット種別 */
export const NUMBER_PROPERTY_FORMAT_TYPE = {
  FLOAT: 'float',
  PERCENT: 'percent',
  YEN: 'yen',
} as const;

/** ビューの共有設定のデフォルト値 */
export const IS_GUEST_ACCESSIBLE_DEFAULT = true as const;

/** 使用可能な言語 */
export const AVAILABLE_LANGUAGES = {
  JA: 'ja',
  EN: 'en',
};

/** OAuthプロバイダアイコンのOption */
export const OAUTH_PROVIDER_ICON_OPTION = { size: '20px' };

/** reCAPTCHAアクション */
export const RE_CAPTCHA_ACTIONS = {
  EMAIL_EXISTENCE_CHECK: 'EMAIL_EXISTENCE_CHECK',
  PASSWORD_LOGIN: 'PASSWORD_LOGIN',
  PASSWORD_SIGN_IN: 'PASSWORD_SIGN_IN',
  PASSWORD_RESET: 'PASSWORD_RESET',
};

/** プロジェクトの共有種別 */
export const SHARING_TYPES = {
  GUEST: 'guest',
  LINK: 'link',
};

/** プラン */
export const PLANS = {
  FREE: 'free',
  STANDARD: 'standard',
};

/** joinType */
export const JOIN_TYPE = {
  MEMBER: 'member',
  GUEST: 'guest',
  UNKNOWN_GUEST: 'unknownGuest',
};

/** 共有許可タイプ */
export const SHARING_PERMISSION_TYPES = {
  EDIT: 'edit',
  COMMENT: 'comment',
  READ: 'read',
};

/** コメントのデフォルト通知設定 */
export const DEFAULT_COMMENT_NOTIFY = false;

/** カンバン時のプロパティ表示数 */
export const KANBAN_PROPERTY_VIEW_COUNT = 3;

/**
 * プラン別メンバー数上限
 */
export const MEMBER_LIMIT = {
  FREE: 5,
};

/**
 * プラン別プロジェクト作成数上限
 */
export const PROJECT_CREATION_LIMIT = {
  FREE: 5,
  STANDARD: 100,
};

/**
 * プラン別ファイルサイズ上限(Byte)
 */
export const FILE_SIZE_LIMIT = {
  FREE: 10000000,
  STANDARD: 100000000,
};

/**
 * ファイル添付プロパティ、コメントのアップロード制限数
 */
export const FILE_COUNT_LIMIT = 20;

/**
 * FAQへのURL
 */
export const FAQ_URL = '___FAQ___';

/**
 * アップロード済ファイル読み込みのリトライ設定
 */
export const FILE_LOAD_RETRY = {
  COUNT: 10,
  DELAY: 300,
};

/**
 * アップロード済ファイル読み込みのリトライ設定
 */
export const FILE_UPLOAD_RETRY = {
  COUNT: 10,
  DELAY: 300,
};

/**
 * 本文のアップロード済ファイル読み込みのリトライ設定
 */
export const BODY_FILE_LOAD_RETRY = {
  COUNT: 3,
  DELAY: 100,
};

/**
 * アイテムコピーのリトライ設定
 */
export const COPY_ITEM_RETRY = {
  COUNT: 5,
  DELAY: 800,
};

/**
 * 通知件数を省略するしきい値
 */
export const MAX_NOTIFICATION_DISPLAY_COUNT = 99;

/**
 * プレゼンスアイコンを省略するしきい値
 */
export const MAX_PRESENCE_DISPLAY_COUNT = 10;

/** ダウングレード対象の猶予期間 */
export const DOWNGRADE_PAST_DAYS = import.meta.env.VITE_DOWNGRADE_PAST_DAYS
  ? parseInt(import.meta.env.VITE_DOWNGRADE_PAST_DAYS, 10)
  : 5;

/**
 * Functionsのerror.detailsで返却される区分文字列
 */
export const FUNCTIONS_ERROR_DETAILS = {
  /** 契約中のワークスペースが存在 */
  WORKSPACE_UNDER_CONTRACT_EXISTS: 'workspaceUnderContractExists',
  /** ワークスペースに管理者が必要 */
  WORKSPACE_ADMINISTRATOR_NEEDED: 'workspaceAdministratorNeeded',
  /** reCaptcha照合エラー */
  RECAPTCHA_ERROR: 'reCaptchaError',
  /** メンバー数超過 */
  EXCESS_NUMBER_OF_MEMBERS: 'excessNumberOfMembers',
  /** 既に参加済み */
  ALREADY_PARTICIPATED: 'alreadyParticipated',
  /** ワークスペース参加制限 */
  WORKSPACE_PARTICIPATION_RESTRICTIONS: 'workspaceParticipationRestrictions',
  /** プロジェクト作成上限超過 */
  PROJECT_CREATION_LIMIT_EXCEEDED: 'projectCreationLimitExceeded',
  /** ワークスペースがフリープラン */
  WORKSPACE_IS_FREE_PLAN: 'workspaceIsFreePlan',
  /** Token 認証エラー */
  TOKEN_ERROR: 'tokenError',
  /** 出力対象がない */
  NO_EXPORTS_DATA: 'noExportData',
  /** クエリが空 */
  EMPTY_QUERY: 'emptyQuery',
  /** アイテムが更新途中 */
  ITEM_UPDATE_LOADING: 'itemUpdateLoading',
};

/**
 * collabble公式
 */
export const COLLABBLE_OFFICIAL = {
  CUSTOMER_ID: 'admin',
  DISPLAY_NAME: 'collabble運営企業', // TODO 多言語対応
};

/** プロジェクト複製先 区分 */
export const PROJECT_COPY_TARGET_TYPE = {
  CURRENT: 'current',
  OTHER: 'other',
} as const;

/**
 * プロジェクト複製時のサンプルアイテム数
 */
export const PROJECT_COPY_ITEM_COUNT = 3;

/**
 * ログインモード
 */
export const LOGIN_MODE = {
  INVITING_MEMBER: 'invitingMember',
  INVITING_GUEST: 'invitingGuest',
} as const;

/**
 * リストビューでの初期表示件数
 */
export const LIST_VIEW_ITEM_LENGTH = 7;

/**
 * リストビューでの固定ヘッダー行数
 */
export const FIXED_COLUMN_LENGTH = 2;

/** エラー時にポップアップウィンドウを自動的に閉じるまでの時間(ms) */
export const AUTO_ERROR_WINDOW_CLOSE_DELAY = 2000;

/** URL内の不確定を示すVIEW ID文字列 */
export const INDETERMINATE_VIEW_ID = '_';

export const NOTIFICATION = {
  /** 特殊な宛先指定 */
  SPECIAL_MENTION: {
    /** 全員（メンバー + ゲスト） */
    ALL: 'all',
    /** メンバー全員 */
    MEMBER: 'member',
    /** ゲスト全員 */
    GUEST: 'guest',
  },
} as const;

/** ADMINコレクションのドキュメントタイプ */
export const ADMIN_DOC_TYPE = {
  VERSION_CONTROL: 'version-control',
};

/** 検索に指定できる最大プロパティ数 */
export const MAX_SEARCH_PROPERTY_COUNT = 8;
/** itemの挿入位置 */
export const INSERT_ITEM_POS = {
  TOP: 'top',
  LAST: 'last',
};

/** paginateの単位 */
export const PAGINATE = {
  TO_COMMENTS: 20,
  COMMENTS: 10,
  LIKED_USERS: 5,
};

/** 日付プロパティ期限閾値 N日前 */
export const DATE_PROPERTY_EXPIRATION_THRESHOLD = {
  WARNING: 1,
  DANGER: 0,
};

/** リアクションの初期表示数 */
export const DEFAULT_REACTION_DISPLAY = 5;

/** デフォルトリアクション候補のID */
export const DEFAULT_REACTION_EMOJI_IDS = ['+1', 'bow', 'eyes'];

/** リアクションカウントを省略する閾値 */
export const REACTION_ABB_THRESHOLD = 99;

/** リアクションユーザー名を省略する閾値 */
export const REACTION_DISPLAY_NAME_ABB_THRESHOLD = 10;

/** itemsOrderの上限 */
export const ITEMS_ORDER_MAX = 1000;

/** 一時ファイルの期限 */
export const TEMP_FILE_EXPIRED_DAYS = 1;

/** リストビューのタイトル カラムキー */
export const LIST_VIEW_TITLE_COLUMN_KEY = 'title';
/** リストビューのタイトル デフォルトカラム幅 */
export const DEFAULT_LIST_VIEW_TITLE_COLUMN_WIDTH = 450;
/** リストビューのタイトル 最小カラム幅 */
export const MIN_LIST_VIEW_TITLE_COLUMN_WIDTH = 300;
/** リストビューのデフォルトカラム幅 */
export const DEFAULT_LIST_VIEW_COLUMN_WIDTH = 200;
/** リストビューの最小カラム幅 */
export const MIN_LIST_VIEW_COLUMN_WIDTH = 100;

/** http function名 */
export const HTTP_FUNCTIONS = {
  EXPORT_ITEMS: 'exportItems',
};

/** アイテム詳細の検索上限数 */
export const MAX_FILTER_DETAIL_LENGTH = 5;

/** editorStateの初期値 */
export const INITIAL_EDITOR_STATE = JSON.stringify({
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
      },
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
});
