import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import useViews from '@/hooks/useViews';

export interface IColumnWidthContextProps {
  children: ReactNode | ReactNode[];
}

interface IPropertyOption {
  [key: string]: {
    columnWidth?: number;
  };
}

export const columnWidthContext = createContext<IPropertyOption>({});
export const setColumnWidthContext = createContext<
  (propertyId: string, width: number) => void
>(() => undefined);

/**
 * カラム幅 Context
 * @param props
 * @returns
 */
export default function ColumnWidthContext(props: IColumnWidthContextProps) {
  const { children } = props;
  const { currentView } = useViews();

  const [writablePropertyOption, setWritablePropertyOption] =
    useState<IPropertyOption>(currentView?.propertyOptions as IPropertyOption);

  useEffect(() => {
    setWritablePropertyOption(currentView?.propertyOptions as IPropertyOption);
  }, [currentView?.propertyOptions]);

  const updateWidth = useCallback(
    (propertyId: string, width: number) => {
      setWritablePropertyOption({
        ...writablePropertyOption,
        [propertyId]: { columnWidth: width },
      });
    },
    [writablePropertyOption],
  );

  return (
    <columnWidthContext.Provider value={writablePropertyOption}>
      <setColumnWidthContext.Provider value={updateWidth}>
        {children}
      </setColumnWidthContext.Provider>
    </columnWidthContext.Provider>
  );
}
