import { IJoinedGuest } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import {
  ExclamationTriangleIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { PLANS, VALIDATION_MODE } from '@/libs/const';

import { getBaseURL, getNavigatePath, t2s } from '@/libs/utils';

import { INVITE_EMAIL } from '@/libs/validations';

import Alert from '@/components/Common/Alert';
import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import LinkButton from '@/components/Common/Forms/Buttons/LinkButton';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import Toggle from '@/components/Common/Forms/Toggle';
import GuestAccountList, {
  IInviteEmail,
} from '@/components/Common/GuestAccountList';

import ShareDropDown from '@/components/Share/ShareDropDown';

import useGuests from '@/hooks/useGuests';
import useInvitingGuests from '@/hooks/useInvitingGuests';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePlans from '@/hooks/usePlans';
import useProjects from '@/hooks/useProjects';

import useXGuests from '@/hooks/useXGuests';

export interface IPublishingSettingDialogProps {}

/**
 * 共有ダイアログshareLink
 * @param props
 * @returns
 */
export default function ShareDialog(props: IModalDialogProps) {
  const { t } = useTranslation();

  // freeとstandardプランによって権限変更
  const { plan } = usePlans();
  const disabledAuthMember = useMemo(() => {
    if (plan?.type === PLANS.STANDARD) {
      return false;
    }
    return true;
  }, [plan]);

  const { onClose } = props;
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject, updateProjectSharing } = useProjects();
  const [joinedGuests, setJoinedGuests] = useState<IJoinedGuest[]>([]);

  const { guests } = useGuests();
  const { _guests, myProjectsXGuestsDic } = useXGuests();

  const { invitingGuests } = useInvitingGuests();
  const { workspaceId: urlWorkspaceId } = useParams();

  const originalWorkspaceId = useMemo(
    () => currentMyWorkspace?.originalWorkspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const linkUrl = useMemo(
    () =>
      `${getBaseURL()}${getNavigatePath(
        originalWorkspaceId as string,
        projectId,
      )}`,
    [originalWorkspaceId, projectId],
  );

  // メールアドレス重複チェック（mock）
  let checkDuplicateEmail = (v: string) => typeof v === 'string';

  // Zod schema定義
  const guestSchema = z.object({
    removeIds: z.array(z.string()),
    inviteEmails: z.array(
      z.object({
        value: z
          .string()
          .min(1, { message: t('入力してください') })
          .email(t2s(t('正しいメールアドレスを入力してください')))
          .max(
            INVITE_EMAIL.max,
            t2s(t('<max>文字以内で入力してください', INVITE_EMAIL)),
          )
          .refine(
            (v) => checkDuplicateEmail(v),
            t2s(t('<label>が重複しています', { label: t('メールアドレス') })),
          ),
      }), // カスタムバリデーション
    ),
  });

  // Zod Form
  const guestMethods = useForm({
    resolver: zodResolver(guestSchema),
    mode: VALIDATION_MODE,
    defaultValues: {
      removeIds: [] as string[],
      inviteRemoveIds: [] as string[],
      inviteEmails: [] as IInviteEmail[],
      // tempGuests: [] as IInviteEmail[],
    },
  });

  // joinedMembersを生成
  useEffect(() => {
    const g = guests.map<IJoinedGuest>((o) => ({
      ...o,
      email: _guests.find((p) => p.id === o.id)?.email,
      joined: true,
    }));
    const ig = invitingGuests.map<IJoinedGuest>((o) => ({
      id: o.id,
      email: o.email,
      joined: false,
    }));
    setJoinedGuests([...g, ...ig]);
    setTimeout(() => {
      // snapshot監視でデータが変化した場合、バリデーションを実施する
      guestMethods.trigger();
    });
  }, [guests, _guests, invitingGuests]);

  // メールアドレス重複チェック
  checkDuplicateEmail = (v: string) => {
    const { removeIds, inviteRemoveIds, inviteEmails } =
      guestMethods.getValues();

    const tgt = joinedGuests
      .filter((o) => !removeIds.includes(o.id as string))
      .filter((o) => !inviteRemoveIds.includes(o.id as string));
    const res = tgt.find((o) => o.email === v);
    if (res || inviteEmails.filter((f) => f.value === v).length > 1)
      return false;
    return true;
  };

  // Zod schema定義
  const shareTypeSchema = z.object({
    enableShare: z.boolean(),
    enableGuestCopy: z.boolean(),
    enableShareLink: z.boolean(),
    sharingPermission: z.string(),
  });

  // Zod Form
  const shareTypeMethods = useForm({
    resolver: zodResolver(shareTypeSchema),
    mode: VALIDATION_MODE,
    defaultValues: {
      enableShare: currentMyProject?.isShare,
      enableGuestCopy: currentMyProject?.allowGuestCopy,
      enableShareLink: currentMyProject?.allowLinkShare,
      sharingPermission: currentMyProject?.sharingPermission,
    },
  });
  shareTypeMethods.watch();

  // Zod schema定義
  const linkSchema = z.object({
    shareUrl: z.string(),
  });

  // Zod Form
  const linkMethods = useForm({
    resolver: zodResolver(linkSchema),
    mode: VALIDATION_MODE,
    defaultValues: {
      shareUrl: '',
    },
  });

  // Form Submit
  const formSubmit = shareTypeMethods.handleSubmit(() => {
    const tempGuestSet: Record<string, string> = {};
    _.forEach(myProjectsXGuestsDic, (v) => {
      if (v.email && v.id) {
        tempGuestSet[v.email] = v.id;
      }
    });
    const x = guestMethods
      .getValues()
      .inviteEmails.filter((g1) => tempGuestSet[g1.value])
      .map((g2) => ({ ...g2, id: tempGuestSet[g2.value] }));
    updateProjectSharing(
      currentMyProject?.id as string,
      shareTypeMethods.getValues().enableShare as boolean,
      shareTypeMethods.getValues().enableGuestCopy as boolean,
      shareTypeMethods.getValues().enableShareLink as boolean,
      shareTypeMethods.getValues().sharingPermission,
      guestMethods
        .getValues()
        .inviteEmails.filter((g) => !tempGuestSet[g.value]),
      guestMethods.getValues().removeIds,
      x,
    );
    onClose();
  });

  // mount時デフォルト値設定
  useEffect(() => {
    // 追加/除外ゲスト初期化
    guestMethods.setValue('inviteEmails', [] as IInviteEmail[]);
    guestMethods.setValue('removeIds', [] as string[]);
    if (currentMyProject) {
      shareTypeMethods.setValue(
        'enableShare',
        currentMyProject?.isShare || false,
      );
      shareTypeMethods.setValue(
        'enableGuestCopy',
        currentMyProject?.allowGuestCopy || false,
      );
      shareTypeMethods.setValue(
        'enableShareLink',
        currentMyProject?.allowLinkShare || false,
      );
      shareTypeMethods.trigger();
      linkMethods.setValue('shareUrl', linkUrl);
    }
  }, []);

  // Submitボタン状態判定
  // TODO: 「適用」ボタン判定修正
  const enableApply = useMemo(
    () =>
      guestMethods.formState.isValid &&
      shareTypeMethods.formState.isValid &&
      (guestMethods.formState.isDirty || shareTypeMethods.formState.isDirty),
    [guestMethods.formState, linkMethods.formState],
  );

  // URLコピーボタンに初期フォーカスがあたるのを防ぐ
  const urlCopyButtonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    urlCopyButtonRef?.current?.blur();
  }, []);

  return (
    <FormDialog
      onClose={onClose}
      title={t('プロジェクトを共有')}
      onSubmit={formSubmit}
      top
      className="!max-w-2xl"
      headerContent={
        <CopyToClipboard
          text={linkUrl}
          onCopy={() => {
            toast.success(t('URLをクリップボードへコピーしました'));
          }}
        >
          <MenuButton
            ref={urlCopyButtonRef}
            tabIndex={0}
            type="text"
            id="copyShareLink"
            className="sm:w-auto"
          >
            {t('リンクをコピー')}
          </MenuButton>
        </CopyToClipboard>
      }
    >
      <>
        <FormProvider {...shareTypeMethods}>
          <div className="mt-5">
            <Toggle
              label={t('<projectName>を共有する', {
                projectName: currentMyProject?.projectName,
              })}
              name="enableShare"
              defaultValue={currentMyProject?.isShare}
            />
          </div>
        </FormProvider>
        {/* 「〇〇〇を共有する」toggleボタン押下表示内容 */}
        {shareTypeMethods.getValues().enableShare && (
          <div className="min-h-[25rem]">
            <div className="my-5">
              <FormProvider {...shareTypeMethods}>
                <Toggle
                  label={t('ゲストにプロジェクトの複製を許可する')}
                  name="enableGuestCopy"
                  defaultValue={currentMyProject?.allowGuestCopy}
                />
              </FormProvider>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FormProvider {...shareTypeMethods}>
                <div className="flex items-center">
                  <div className="mr-4">{t('共有時の権限')}</div>
                  {currentMyProject?.sharingPermission && (
                    <ShareDropDown
                      disabledAuthMember={disabledAuthMember}
                      sharingPermission={currentMyProject.sharingPermission}
                    />
                  )}
                </div>
              </FormProvider>
              {disabledAuthMember === true && (
                <div className="flex">
                  <LinkButton
                    id="gotoPlan"
                    to={`/${urlWorkspaceId}/settings/plans`}
                    className="sm:ml-auto"
                  >
                    <LockClosedIcon
                      className="w-4 h-4 mt-0.5"
                      aria-hidden="false"
                    />
                    <div className="ml-1">
                      {t('プランをアップグレードする')}
                    </div>
                  </LinkButton>
                </div>
              )}
            </div>
            <div className="mt-5">
              <FormProvider {...shareTypeMethods}>
                <Toggle
                  label={t('匿名ゲストにリンク共有を許可する')}
                  name="enableShareLink"
                  defaultValue={currentMyProject?.allowLinkShare}
                />
              </FormProvider>
            </div>
            {/* 「匿名ゲストにリンク共有を許可する」toggleボタン押下表示内容 */}
            {shareTypeMethods.getValues().enableShareLink && (
              <Alert
                color="gray"
                className="mt-5 mb-4"
                icon={ExclamationTriangleIcon}
              >
                <h3 className="font-medium">{t('注意')}</h3>
                <p className="mt-2 text-sm">
                  {t(
                    '「匿名ゲストにリンク共有」を許可した場合、URLを知っている不特定多数の方が認証不要でアクセスできます。URLの取り扱いには注意してください。',
                  )}
                </p>
              </Alert>
            )}
            <FormProvider {...guestMethods}>
              <GuestAccountList className="mt-5" accounts={joinedGuests} />
            </FormProvider>
          </div>
        )}
      </>
      <FormProvider {...linkMethods}>
        <FormButton
          id="applyShareSetting"
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
          disabled={!enableApply}
        >
          {t('適用')}
        </FormButton>
        <FormButton
          id="cancelShareSetting"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
