import { ActionType, SearchFilter } from '@/@types/common';

import { Comment, Item } from '@/@types/models';

import actions from '@/libs/actions';

export interface IItemSearchState {
  workspaceId: string;
  projectId: string;
  keyword: string | null;
  filter: SearchFilter | null;
  itemIds: string[];
}

export interface IItemState {
  currentItemId: Item['id'];
  isEditOpen: boolean;
  itemSearchStates: IItemSearchState[];
  isEmojiOpen: boolean;
  isCommentEmojiOpen: boolean;
  reactionOpenCommentId: Comment['id'];
  isImageOpen: boolean;
}

type Types = Pick<
  typeof actions,
  | 'OPEN_ITEM_EDIT_DIALOG'
  | 'CLOSE_ITEM_EDIT_DIALOG'
  | 'SET_CURRENT_ITEM_ID'
  | 'SET_ITEM_SEARCH_STATE'
  | 'OPEN_ITEM_COMMENT_EMOJI_POPUP'
  | 'CLOSE_ITEM_COMMENT_EMOJI_POPUP'
  | 'OPEN_ITEM_COMMENT_REACTION_POPUP'
  | 'CLOSE_ITEM_COMMENT_REACTION_POPUP'
  | 'OPEN_IMAGE_DIALOG'
  | 'CLOSE_IMAGE_DIALOG'
>;

type Action = ActionType<
  Types,
  {
    OPEN_ITEM_EDIT_DIALOG: { payload: string };
    CLOSE_ITEM_EDIT_DIALOG: {};
    SET_CURRENT_ITEM_ID: { payload: Item['id'] };
    SET_ITEM_SEARCH_STATE: {
      payload: {
        workspaceId: string;
        projectId: string;
        keyword: string | null;
        filter: SearchFilter | null;
        itemIds: string[];
      };
    };
    OPEN_ITEM_COMMENT_EMOJI_POPUP: {};
    CLOSE_ITEM_COMMENT_EMOJI_POPUP: {};
    OPEN_ITEM_COMMENT_REACTION_POPUP: { payload: Comment['id'] };
    CLOSE_ITEM_COMMENT_REACTION_POPUP: {};
    OPEN_IMAGE_DIALOG: {};
    CLOSE_IMAGE_DIALOG: {};
  }
>;

const initialState: IItemState = {
  currentItemId: '',
  isEditOpen: false,
  itemSearchStates: [],
  isEmojiOpen: false,
  isCommentEmojiOpen: false,
  reactionOpenCommentId: undefined,
  isImageOpen: false,
};

export const itemsReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_CURRENT_ITEM_ID:
      return { ...state, currentItemId: action.payload };
    case actions.OPEN_ITEM_EDIT_DIALOG:
      return {
        ...state,
        isEditOpen: true,
        currentItemId: action.payload,
      };
    case actions.CLOSE_ITEM_EDIT_DIALOG:
      return {
        ...state,
        currentItemId: undefined,
        isEditOpen: false,
      };
    case actions.SET_ITEM_SEARCH_STATE: {
      const iss = [...state.itemSearchStates];
      const pl = action.payload;
      const idx = iss.findIndex(
        (o) => o.workspaceId === pl.workspaceId && o.projectId === pl.projectId,
      );
      const plData = {
        workspaceId: pl.workspaceId,
        projectId: pl.projectId,
        keyword: pl.keyword,
        filter: pl.filter,
        itemIds: pl.itemIds,
      };

      if (idx >= 0) {
        iss[idx] = plData;
      } else {
        iss.push(plData);
      }
      return {
        ...state,
        itemSearchStates: iss,
      };
    }

    case actions.OPEN_ITEM_COMMENT_EMOJI_POPUP:
      return {
        ...state,
        isCommentEmojiOpen: true,
      };
    case actions.CLOSE_ITEM_COMMENT_EMOJI_POPUP:
      return {
        ...state,
        isCommentEmojiOpen: false,
      };
    case actions.OPEN_ITEM_COMMENT_REACTION_POPUP:
      return {
        ...state,
        reactionOpenCommentId: action.payload,
      };
    case actions.CLOSE_ITEM_COMMENT_REACTION_POPUP:
      return {
        ...state,
        reactionOpenCommentId: undefined,
      };
    case actions.OPEN_IMAGE_DIALOG:
      return {
        ...state,
        isImageOpen: true,
      };
    case actions.CLOSE_IMAGE_DIALOG:
      return {
        ...state,
        isImageOpen: false,
      };
    default:
      return state;
  }
};

export const setCurrentItemId = (itemId: Item['id']) => ({
  type: actions.SET_CURRENT_ITEM_ID,
  payload: itemId,
});

export const openItemEditDialog = (itemId: string) => ({
  type: actions.OPEN_ITEM_EDIT_DIALOG,
  payload: itemId,
});

export const closeItemEditDialog = () => ({
  type: actions.CLOSE_ITEM_EDIT_DIALOG,
});

export const setItemSearchState = (
  workspaceId: string,
  projectId: string,
  keyword: string | null,
  filter: SearchFilter | null,
  itemIds: string[],
) => ({
  type: actions.SET_ITEM_SEARCH_STATE,
  payload: {
    workspaceId,
    projectId,
    keyword,
    filter,
    itemIds,
  },
});

export const openItemCommentEmojiPopup = () => ({
  type: actions.OPEN_ITEM_COMMENT_EMOJI_POPUP,
});

export const closeItemCommentEmojiPopup = () => ({
  type: actions.CLOSE_ITEM_COMMENT_EMOJI_POPUP,
});

export const openItemCommentReactionPopup = (commentId: string) => ({
  type: actions.OPEN_ITEM_COMMENT_REACTION_POPUP,
  payload: commentId,
});

export const closeItemCommentReactionPopup = () => ({
  type: actions.CLOSE_ITEM_COMMENT_REACTION_POPUP,
});
export const openImageDialog = () => ({
  type: actions.OPEN_IMAGE_DIALOG,
});

export const closeImageDialog = () => ({
  type: actions.CLOSE_IMAGE_DIALOG,
});

export default {};
