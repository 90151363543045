import React, { useMemo } from 'react';

import { classNames } from '@/libs/styleUtils';

import Logo from '@/components/Common/Logo';
import LogoTitle from '@/components/Common/LogoTitle';

export interface IHeaderLogoProps {
  size?: 'xs' | 'md';
  className?: string;
}

/**
 * 横向きロゴ
 * @param props
 * @returns
 */
export default function HeaderLogo(props: IHeaderLogoProps) {
  const { size, className } = props;

  const sizeClasses = useMemo(() => {
    if (size === 'xs') {
      return ['h-[2.025rem] mr-[0.6rem]', 'h-4'];
    }
    return ['h-[4.125rem] mr-[1.1875rem]', 'h-8'];
  }, [size]);

  return (
    <div
      className={classNames(
        'flex items-center justify-center sm:mx-auto sm:w-full sm:max-w-md',
        className,
      )}
    >
      <Logo className={classNames('w-auto login-logo', sizeClasses[0])} />
      <LogoTitle className={classNames('w-auto', sizeClasses[1])} />
    </div>
  );
}
