import {
  IPropertyFormatType,
  IPropertyOptionType,
  IPropertyType,
  Property,
} from '@/@types/models';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { z } from 'zod';

import {
  NUMBER_PROPERTY_FORMAT_TYPE,
  PROPERTY_TYPE,
  VALIDATION_MODE,
} from '@/libs/const';
import { match, t2s } from '@/libs/utils';

import { PROPERTY_STRING } from '@/libs/validations';

// 基本検索
export interface IBasicFilter {
  likeCountFrom: number | null;
  likeCountTo: number | null;
  commentCountFrom: number | null;
  commentCountTo: number | null;
  createdBys: string[];
  updatedBys: string[];
  createdAtFrom: number | null;
  createdAtTo: number | null;
  updatedAtFrom: number | null;
  updatedAtTo: number | null;
}

// 詳細（プロパティ）検索
export interface ISearchProperty {
  docId: string;
  propertyName: Property['propertyName'];
  stringValue?: string;
  arrayValue?: string[];
  booleanValue?: boolean;
  numberValue?: number | null;
  propertyType: IPropertyType;
  options?: IPropertyOptionType[];
  format?: IPropertyFormatType | null;
  isShare: boolean;
  numberFrom?: number | null;
  numberTo?: number | null;
  isNotEmptyFile?: boolean;
  isDueDate?: boolean;
}
export interface ISearchFilter {
  basicFilter: IBasicFilter;
  searchProperties: ISearchProperty[];
}

type NumberOptionalQuery = {
  isDueDate?: boolean;
  numberFrom?: number;
  numberTo?: number;
};

// API用リクエスト用のSearchProperty型
export type QuerySearchProperty = { propertyId: string } & Pick<
  ISearchProperty,
  'propertyType'
> &
  Partial<
    Pick<ISearchProperty, 'arrayValue' | 'stringValue' | 'isNotEmptyFile'>
  > &
  NumberOptionalQuery;

export default function useFilters() {
  /**
   * query用のpropertyを作成
   * @param prs
   * @returns
   */
  const formatQueryProperties = (
    prs: ISearchProperty[],
  ): QuerySearchProperty[] => {
    const propertyList = prs.flatMap<QuerySearchProperty>((op) => {
      if (!op.docId || !op.propertyName || !op.propertyType) return [];

      // 検索に不要な項目を排除
      const propertyBase = {
        propertyId: op.docId,
        propertyType: op.propertyType,
      };

      return match<Property, QuerySearchProperty>(op as Property)({
        text: () => ({ ...propertyBase, stringValue: op.stringValue }),
        file: () => ({ ...propertyBase, isNotEmptyFile: op.isNotEmptyFile }),
        singleSelect: () => ({
          ...propertyBase,
          arrayValue: op.arrayValue,
        }),
        multiSelect: () => ({
          ...propertyBase,
          arrayValue: op.arrayValue,
        }),
        checkbox: () => ({
          ...propertyBase,
          stringValue: String(op.booleanValue),
        }),
        incharge: () => ({ ...propertyBase, arrayValue: op.arrayValue }),
        date: () => {
          const dateProperty: NumberOptionalQuery = {};
          if (op.numberTo !== null) {
            dateProperty.numberTo = op.numberTo;
          }
          if (op.numberFrom !== null) {
            dateProperty.numberFrom = op.numberFrom;
          }
          if (op.isDueDate === true) {
            dateProperty.isDueDate = op.isDueDate;
          }
          return {
            ...propertyBase,
            ...dateProperty,
          };
        },
        number: () => {
          const numberProperty: NumberOptionalQuery = {};
          if (op.numberFrom !== null) {
            numberProperty.numberFrom = op.numberFrom;
          }
          if (op.numberTo !== null) {
            numberProperty.numberTo = op.numberTo;
          }
          return {
            ...propertyBase,
            ...numberProperty,
          };
        },
      });
    });
    return propertyList;
  };
  /**
   * 画面表示用のプロパティリストを生成する
   * @returns {IProperty[]}
   */
  const composeProperties = (prs: Property[]): ISearchProperty[] => {
    const propertyList = prs.flatMap<ISearchProperty>((op) => {
      if (!op.id || !op.propertyName || !op.propertyType) return [];

      // IPropertyのベース
      const propertyBase = {
        docId: op.id,
        propertyName: op.propertyName,
        propertyType: op.propertyType,
        isShare: op.isShare,
      };
      return match<Property, ISearchProperty>(op)({
        text: () => ({ ...propertyBase, stringValue: '' }),
        file: () => ({ ...propertyBase, isNotEmptyFile: false }),
        singleSelect: (property) => ({
          ...propertyBase,
          options: property.options,
          arrayValue: [],
        }),
        multiSelect: (property) => ({
          ...propertyBase,
          options: property.options,
          arrayValue: [],
        }),
        checkbox: () => ({ ...propertyBase, booleanValue: false }),
        incharge: () => ({ ...propertyBase, arrayValue: [] }),
        date: () => ({
          ...propertyBase,
          isDueDate: false,
          numberFrom: null,
          numberTo: null,
        }),
        number: (property) => ({
          ...propertyBase,
          format: property.format,
          numberFrom: null,
          numberTo: null,
        }),
      });
    });
    return propertyList;
  };

  // =====  zod設定 ======
  // ↓↓↓
  //  入力項目デフォルト値
  const defaultValues: ISearchFilter = {
    basicFilter: {
      likeCountFrom: null,
      likeCountTo: null,
      commentCountFrom: null,
      commentCountTo: null,
      createdBys: [],
      updatedBys: [],
      createdAtFrom: null,
      createdAtTo: null,
      updatedAtFrom: null,
      updatedAtTo: null,
    },
    searchProperties: [] as ISearchProperty[],
  };
  const schema = z.object({
    basicFilter: z.object({
      likeCountFrom: z
        .number({
          invalid_type_error: t('数値で入力してください'),
        })
        .nullable(),
      likeCountTo: z
        .number({
          invalid_type_error: t('数値で入力してください'),
        })
        .nullable(),
      commentCountFrom: z
        .number({
          invalid_type_error: t('数値で入力してください'),
        })
        .nullable(),
      commentCountTo: z
        .number({
          invalid_type_error: t('数値で入力してください'),
        })
        .nullable(),
      createdBys: z.array(z.string()),
      updatedBys: z.array(z.string()),
      createdAtFrom: z
        .number({
          invalid_type_error: t('数値で入力してください'),
        })
        .nullable(),
      createdAtTo: z
        .number({
          invalid_type_error: t('数値で入力してください'),
        })
        .nullable(),
      updatedAtFrom: z
        .number({
          invalid_type_error: t('数値で入力してください'),
        })
        .nullable(),
      updatedAtTo: z
        .number({
          invalid_type_error: t('数値で入力してください'),
        })
        .nullable(),
    }),
    searchProperties: z.array(
      z.object({
        docId: z.string(),
        propertyType: z.nativeEnum(PROPERTY_TYPE),
        propertyName: z
          .string()
          .trim()
          .min(1, { message: t('入力してください') }),
        stringValue: z
          .string()
          .trim()
          .max(
            PROPERTY_STRING.max,
            t2s(t('<max>文字以内で入力してください', PROPERTY_STRING)),
          )
          .nullable()
          .optional(),
        isShare: z.boolean(),
        arrayValue: z.array(z.any()).optional(),
        numberValue: z
          .number({
            invalid_type_error: t('数値で入力してください'),
          })
          .nullable()
          .optional(),
        booleanValue: z.boolean().optional(),
        options: z
          .array(
            z.object({
              id: z.string(),
              text: z.string(),
              color: z.string(),
            }),
          )
          .optional(),
        format: z.nativeEnum(NUMBER_PROPERTY_FORMAT_TYPE).nullable().optional(),
        isDueDate: z.boolean().optional(),
        numberFrom: z
          .number({
            invalid_type_error: t('数値で入力してください'),
          })
          .nullable()
          .optional(),
        numberTo: z
          .number({
            invalid_type_error: t('数値で入力してください'),
          })
          .nullable()
          .optional(),
        isNotEmptyFile: z.boolean().optional(),
      }),
    ),
  });

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues,
  });

  const zodFilterSetting = useFieldArray({
    control: methods.control,
    name: 'searchProperties',
  });
  return {
    // Zod関連
    methods,
    zodFilterSetting,
    defaultValues,
    filterSchema: schema,
    composeProperties,
    formatQueryProperties,
  };
}
