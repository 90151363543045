import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setIsLoaded } from '@/reducers/uiStateReducer';

import ErrorPage from '@/pages/layouts/ErrorPage';

export default function Error404() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsLoaded(true));
  }, []);

  return (
    <ErrorPage
      code={404}
      title={`${t('お探しのページが見つかりません')} 😢`}
      description={t(
        'URLが異なっているかプロジェクトの共有が停止された可能性があります。<br>再度お試しいただくか、ワークスペース管理者にお問い合わせください。',
        { br: <br /> },
      )}
    />
  );
}
