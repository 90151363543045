import { RootState } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import React, { useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { z } from 'zod';

import {
  FUNCTIONS_ERROR_DETAILS,
  HTTP_FUNCTIONS,
  VALIDATION_MODE,
} from '@/libs/const';

import { onDownloadFromFunction } from '@/libs/utils';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import useFirebaseAuth from '@/hooks/useFirebaseAuth';

interface CsvExportDialogProps extends IModalDialogProps {
  workspaceId?: string;
  projectId?: string;
}

export default function CsvExportDialog(props: CsvExportDialogProps) {
  const { onClose, workspaceId, projectId } = props;
  const { t } = useTranslation();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const uid = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const { getFirebaseToken } = useFirebaseAuth();
  // // Zod schema定義
  const schema = z.object({});

  type SchemaType = z.infer<typeof schema>;

  // Zod Form
  const methods = useForm<SchemaType>({
    // resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  // Form Submit
  const formSubmit = methods.handleSubmit(async () => {
    if (workspaceId && projectId) {
      const body = {
        workspaceId,
        projectId,
        uid,
      };

      // token取得
      const token = await getFirebaseToken();

      // function呼び出し
      try {
        await onDownloadFromFunction(HTTP_FUNCTIONS.EXPORT_ITEMS, body, token);
      } catch (e: any) {
        // 文字化け防止のためblobで返却されるため、エラーメッセージをテキスト化
        const errCode = await e.response.data.text();
        if (
          e.status === 201 &&
          errCode === FUNCTIONS_ERROR_DETAILS.NO_EXPORTS_DATA
        ) {
          toast.error(t('出力するアイテムがありません。'));
        }
        toast.error(t('ＣＳＶエクスポートに失敗しました。'));
      }
    }
    onClose();
  });

  return (
    <FormDialog
      onClose={onClose}
      title={t('CSVエクスポート')}
      onSubmit={formSubmit}
      top
    >
      <FormProvider {...methods}>
        <div className="flex items-center justify-between mb-4 space-x-3">
          <div className="block font-medium">
            <ul className="max-w-md list-disc list-inside space-y-1">
              <li>
                {t('ファイル添付プロパティは、ファイル名のみ出力します。')}
              </li>
              <li>{t('本文はプレーンテキストで出力します。')}</li>
              <li>{t('いいね、コメントは件数を出力します。')}</li>
            </ul>
          </div>
        </div>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="csvExport"
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('出力')}
        </FormButton>
        <FormButton
          id="cancelCsvExport"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
