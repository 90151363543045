import {
  RootState,
  Member,
  MyWorkspace,
  Workspace,
  Auth,
} from '@/@types/models';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_MY_WORKSPACES,
  getMyWorkspacesPath,
  getWorkspacesPath,
} from '@/libs/docPathUtils';

import { filterMyWorkspaces, getUpdateSystemFields } from '@/libs/utils';

import { setCurrentMyWorkspaceIdAction } from '@/reducers/currentWorkspaceReducer';

import { setStaticMyWorkspacesAction } from '@/reducers/staticMyWorkspacesReducer';

import useHandleApi from '@/hooks/useHandleApi';

/**
 * ワークスペースに関するHooks
 * @returns { workspaces }
 */
export default function useMyWorkspaces() {
  const rrfFirestore = useFirestore();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { exec } = useHandleApi();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  useFirestoreConnect(() =>
    userId
      ? [
          {
            collection: getMyWorkspacesPath(userId),
            orderBy: ['workspaceName', 'asc'],
            storeAs: COL_MY_WORKSPACES,
          },
        ]
      : [],
  );

  // Firestoreからリアルタイムに取得するmyWorkspaces
  const firestoreMyWorkspaces = useSelector((state: RootState) =>
    filterMyWorkspaces(state.firestore.ordered.myWorkspaces),
  );
  // 匿名認証の場合に使用する静的なmyWorkspaces
  const staticMyWorkspaces = useSelector(
    (state: RootState) => state.staticMyWorkspaces,
  );

  // export用のmyWorkspaces
  const myWorkspaces = useMemo(
    () => firestoreMyWorkspaces,
    [rrfAuth, staticMyWorkspaces, firestoreMyWorkspaces],
  );

  // カレントMyワークスペース
  const currentMyWorkspaceId = useSelector(
    (state: RootState) => state.currentWorkspaceId,
  );
  const currentMyWorkspace = useMemo(
    () =>
      myWorkspaces.find((o) => o.workspaceId === currentMyWorkspaceId) || null,
    [currentMyWorkspaceId, myWorkspaces],
  );

  /**
   * ワークスペース名を更新
   * @param workspaceName
   */
  const updateWorkspaceName = async (
    workspaceName: MyWorkspace['workspaceName'],
  ): Promise<void> => {
    await exec(async () => {
      if (!userId || !currentMyWorkspace || !currentMyWorkspaceId)
        throw new Error();

      const newWorkspace: Workspace = {
        workspaceName,
        ...getUpdateSystemFields(userId),
      };
      await rrfFirestore.update<Member>(
        `${getWorkspacesPath()}/${currentMyWorkspaceId}`,
        newWorkspace,
      );

      const newMyWorkspace: Workspace = {
        workspaceName,
        ...getUpdateSystemFields(userId),
      };
      await rrfFirestore.update<Member>(
        `${getMyWorkspacesPath(userId)}/${currentMyWorkspaceId}`,
        newMyWorkspace,
      );
    }, t('ワークスペース名の更新に失敗しました'));
  };

  /**
   * カレントワークスペースを削除
   */
  const deleteCurrentWorkspace = async (): Promise<void> => {
    await exec(async () => {
      // workspace IDが不明な場合はエラー
      if (!userId || !currentMyWorkspaceId) throw new Error();

      await rrfFirestore.delete<Workspace>(
        `${getWorkspacesPath()}/${currentMyWorkspaceId}`,
      ); // ワークスペース削除
      // 削除後のリダイレクト対策として直接自分のmyWorkspaceから削除
      await rrfFirestore.delete(
        `${getMyWorkspacesPath(userId)}/${currentMyWorkspaceId}`,
      );

      window.location.href = '/';
    }, t('ワークスペースの削除に失敗しました'));
  };

  /**
   * カレントワークスペースにメンバーとして参加しているか判定
   * @returns
   */
  const isMember = useMemo(
    () => currentMyWorkspace?.joinType === 'member',
    [currentMyWorkspace],
  );

  /**
   * カレントワークスペースにゲストとして参加しているか判定
   * @returns
   */
  const isGuest = useMemo(
    () => currentMyWorkspace?.joinType === 'guest',
    [currentMyWorkspace],
  );

  /**
   * カレントワークスペースに匿名として参加しているか判定
   * @returns
   */
  const isUnknownGuest = useMemo(
    () => currentMyWorkspace?.joinType === 'unknownGuest',
    [currentMyWorkspace],
  );

  const setCurrentMyWorkspaceId = (wid: MyWorkspace['id']) => {
    dispatch(setCurrentMyWorkspaceIdAction(wid));
  };

  const setStaticMyWorkspaces = (wss: MyWorkspace[]) => {
    dispatch(setStaticMyWorkspacesAction(wss));
  };

  /**
   *
   * @param uid
   */
  const unsetListener = (uid: Auth['uid']) => {
    rrfFirestore.unsetListener({
      collection: getMyWorkspacesPath(uid),
      storeAs: COL_MY_WORKSPACES,
    });
  };

  const setListener = (uid: Auth['uid']) => {
    rrfFirestore.setListener({
      collection: getMyWorkspacesPath(uid),
      storeAs: COL_MY_WORKSPACES,
    });
  };

  /**
   * 閲覧のみ
   */
  const { readOnly } = useMemo(() => {
    const _joinType = currentMyWorkspace?.joinType;
    return { joinType: _joinType, readOnly: _joinType !== 'member' };
  }, [currentMyWorkspace]);

  /** 通知を既読にする */
  const updateNotificationComment = useCallback(async () => {
    if (!currentMyWorkspaceId) return;
    const updateMyWorkspace: MyWorkspace = {
      toCommentsLastViewedAt: serverTimestamp() as Timestamp,
      toCommentsUnReadCount: 0,
      ...getUpdateSystemFields(userId),
    };
    rrfFirestore.update<MyWorkspace>(
      `${getMyWorkspacesPath(userId)}/${currentMyWorkspaceId}`,
      updateMyWorkspace,
    );
  }, [userId]);

  return {
    unsetListener,
    setListener,
    currentMyWorkspace,
    setCurrentMyWorkspaceId,
    setStaticMyWorkspaces,
    isMember,
    isGuest,
    isUnknownGuest,
    myWorkspaces,
    updateWorkspaceName,
    deleteCurrentWorkspace,
    updateNotificationComment,
    readOnly,
  };
}
