import { Status } from '@/@types/models';
import { IViewProps } from '@/@types/viewItem';
import { PlusIcon } from '@heroicons/react/24/solid';
import React, { useContext, useState } from 'react';
import {
  Draggable,
  DragDropContext,
  Droppable,
  DropResult,
  DragUpdate,
} from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';

import { onDragEnd } from '@/libs/dndUtils';

import { classNames } from '@/libs/styleUtils';

import { getNavigatePath } from '@/libs/utils';

import { openItemEditDialog } from '@/reducers/itemsReducer';
import { openNewItemDialog } from '@/reducers/newItemDialogReducer';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import { isSelectPublishItemModeContext } from '@/components/Share/SelectPublishItemsContext';
import KanbanItem from '@/components/View/Kanban/KanbanItem';

import ViewStatusHeader from '@/components/View/ViewStatusHeader';

import useItems from '@/hooks/useItems';
import useViewItems from '@/hooks/useViewItems';

interface PlaceholderState {
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
  droppableId: string;
}

/**
 * カンバンビューのメインエリア表示
 * @param props
 * @returns
 */
export default function KanbanView(props: IViewProps) {
  const { properties, viewRows, isReadonly, onChangeRowItem } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dragItem, isItemLimitOver } = useItems();
  const navigate = useNavigate();
  const { workspaceId, projectId, viewId } = useParams();
  const { getHighlightClass } = useViewItems();
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);
  const [placeholderProps, setPlaceholderProps] =
    useState<PlaceholderState | null>(null);

  // ドロップ時ハンドラ
  const handleDragEnd = (result: DropResult) => {
    const moveResult = onDragEnd(result, viewRows);

    if (moveResult !== null) {
      // DNDの不正挙動を防ぐため ローカルで保持しているstateを更新
      onChangeRowItem(moveResult);
      // ステータス更新処理
      dragItem(moveResult.item.id, moveResult.statusId, moveResult.position);
    }
    setPlaceholderProps(null);
  };
  // アイテムクリック時ハンドラ
  const handleItemClick = (itemId: string) => {
    if (isSelectPublishItemMode) return;
    navigate(
      getNavigatePath(workspaceId as string, projectId, viewId, itemId),
      {
        replace: true,
      },
    );
    dispatch(openItemEditDialog(itemId));
  };

  // アイテム新規追加
  const handleCreateClick = (statusId: Status['id']) => {
    if (statusId) {
      dispatch(openNewItemDialog(statusId, true));
    }
  };

  // D&Dの影用
  // カスタマイズの参考URL(https://codesandbox.io/embed/github/Utkarshbhimte/beatiful-dnd-custom-placeholder/tree/master/?fontsize=14)
  const queryAttr = 'data-rbd-drag-handle-draggable-id';
  const onDragUpdate = (update: DragUpdate) => {
    if (!update.destination) {
      return;
    }
    const { draggableId } = update;
    const destinationIndex = update.destination.index;
    const { droppableId } = update.destination;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    // アイテムIDを取得
    const domQueryDroppable = `[data-rbd-droppable-id='${droppableId}']`;
    const droppableDOM = document.querySelector(domQueryDroppable);

    // アイテムのIDのチェック
    if (
      !draggedDOM ||
      !draggedDOM.parentNode ||
      !droppableDOM ||
      !droppableDOM.parentNode
    ) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;

    // アイテム移動後の位置調整（移動後は他のアイテムが上方に移動する）
    const childrenWithoutDragged = [...droppableDOM.children]
      .filter(
        (x) =>
          x.getAttribute('data-rbd-drag-handle-draggable-id') !== draggableId,
      )
      .slice(0, destinationIndex);

    // アイテムの高さの合計値を計算
    const clientY = childrenWithoutDragged.reduce((total, curr) => {
      const style = window.getComputedStyle(curr);
      // スタイル調節のためにmarginBottomを追加
      const marginBottom = parseFloat(style.marginBottom);
      const marginTop = parseFloat(style.marginTop);

      return total + curr.clientHeight + marginBottom + marginTop;
    }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft,
      ),
      droppableId: update.destination.droppableId,
    });
  };

  return (
    <div
      className={classNames(
        ' h-full px-4 pb-4 pt-0 overflow-auto box-border',
        isSelectPublishItemMode ? 'mb-28 sm:mb-16' : '',
      )}
    >
      <div className="flex space-x-2">
        <DragDropContext onDragEnd={handleDragEnd} onDragUpdate={onDragUpdate}>
          {viewRows.map((column, columnIndex) => (
            <div
              className="flex flex-col flex-shrink-0 pr-4 w-72 md:w-64 lg:w-72 xl:w-80"
              key={`column-status-${column.status.id}`}
            >
              <div className="sticky top-0 z-10 flex flex-row items-center flex-shrink-0">
                <ViewStatusHeader row={column} />
              </div>
              <Droppable droppableId={columnIndex.toString()}>
                {(providedDroppable) => (
                  <div
                    className="relative flex flex-col min-h-full"
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                  >
                    {column.viewItems.map((viewItem, itemIndex) => {
                      const highlightClass = getHighlightClass(
                        viewItem,
                        'dark:outline-originalDueWarning outline-originalDueWarning-light outline outline-offset-0 outline-2',
                        'dark:outline-originalDueDanger outline-originalDueDanger-light outline outline-offset-0 outline-2',
                      );

                      return (
                        <Draggable
                          isDragDisabled={isSelectPublishItemMode || isReadonly}
                          key={`item-${viewItem.id}`}
                          draggableId={viewItem.id as string}
                          index={itemIndex}
                        >
                          {(providedDraggable, snapshot) => (
                            <div
                              className={classNames(
                                'flex flex-col items-start p-0 mt-4 text-gray-800 bg-white border border-gray-200 shadow-md rounded-md dark:text-gray-100 dark:border-gray-700 dark:bg-gray-800 space-y-4 hover:bg-gray-50 dark:hover:bg-gray-700 hover:shadow-lg group',
                                !isSelectPublishItemMode
                                  ? 'cursor-pointer'
                                  : '',
                                // アニメーション時間を限りなく短くしてアニメーションを視認させない
                                snapshot.isDropAnimating
                                  ? '!duration-[0.001s]'
                                  : '',
                                highlightClass,
                              )}
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              <KanbanItem
                                key={viewItem.id}
                                properties={properties}
                                viewItem={viewItem}
                                onClick={handleItemClick}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {/* D&Dの影用 */}
                    {placeholderProps &&
                      placeholderProps.droppableId ===
                        columnIndex.toString() && (
                        <div
                          className="absolute p-0 mt-4 bg-gray-100 dark:bg-gray-700 rounded-md"
                          style={{
                            top: placeholderProps.clientY,
                            left: placeholderProps.clientX,
                            height: placeholderProps.clientHeight,
                            width: placeholderProps.clientWidth,
                          }}
                        />
                      )}
                    {providedDroppable.placeholder}
                    {!isSelectPublishItemMode && !isReadonly && (
                      <div className="flex flex-col mt-4 pb-14">
                        <MenuButton
                          id="showNewItem"
                          type="text"
                          className={classNames(
                            'flex items-center py-1 pl-2 border-0 shadow-none ',
                            isItemLimitOver
                              ? 'hover:bg-gray-50/0 bg-gray-50/0 dark:bg-gray-800/100 dark:hover:text-gray-700/100 dark:text-gray-700/100 hover:text-gray-200/100 text-gray-200/100'
                              : 'text-gray-400 dark:text-gray-500 dark:hover:text-gray-300 hover:text-gray-500',
                          )}
                          align="left"
                          onClick={() => handleCreateClick(column.status.id)}
                          disabled={isItemLimitOver}
                          toolTip={
                            isItemLimitOver
                              ? t(
                                  'アイテム数上限に達しました。アイテムを作成したい場合は、既存のアイテムを削除してください。',
                                )
                              : ''
                          }
                        >
                          <PlusIcon className="w-4 h-4" aria-hidden="true" />
                          <span className="ml-2">{t('アイテムを追加')}</span>
                        </MenuButton>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
}
