import { Comment, CommentReaction, RootState } from '@/@types/models';
import { serverTimestamp, Timestamp } from 'firebase/firestore';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import { getCommentReactionsPath } from '@/libs/docPathUtils';

import { getFullSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';
import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * commentsReactionsのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useCommentReactionWriter() {
  const { exec } = useHandleApi();
  const rrfFirestore = useFirestore();

  const { currentMyProject } = useProjects();
  const { currentItemId } = useItems();
  const { currentMyWorkspace } = useMyWorkspaces();
  // 現在サブスクリプション中のキー保持用
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );
  const itemId = useMemo(() => currentItemId ?? undefined, [currentItemId]);
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // リアクション新規作成
  const createReaction = async (emojiId: string, commentId: Comment['id']) => {
    exec(async () => {
      if (!userId || !workspaceId || !projectId || !itemId)
        throw new Error('invalid user');
      const reaction: CommentReaction = {
        emojiId,
        reactedAt: serverTimestamp() as Timestamp,
        reactedBy: userId,
        ...getFullSystemFields(userId),
      };
      await rrfFirestore.add<CommentReaction>(
        `${getCommentReactionsPath(workspaceId, projectId, itemId, commentId)}`,
        reaction,
      );
    }, t('リアクションに失敗しました'));
  };

  // リアクション削除
  const deleteReaction = async (
    commentId: Comment['id'],
    reactionId: CommentReaction['id'],
  ) => {
    exec(async () => {
      if (!userId || !workspaceId || !projectId || !itemId)
        throw new Error('invalid user');
      await rrfFirestore.delete<CommentReaction>(
        `${getCommentReactionsPath(
          workspaceId,
          projectId,
          itemId,
          commentId,
        )}/${reactionId}`,
      );
    }, t('リアクション削除に失敗しました'));
  };

  return {
    createReaction,
    deleteReaction,
  };
}
