import { RootState } from '@/@types/models';

import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import {
  FUNCTIONS_ERROR_DETAILS,
  LOGIN_MODE,
  RE_CAPTCHA_ACTIONS,
} from '@/libs/const';

import { getNavigatePath } from '@/libs/utils';

import AccountRepository from '@/repositories/AccountRepository';

import { setAfterLogin } from '@/reducers/uiStateReducer';

import { loginModeContext } from '@/components/Login/LoginModeContext';

import useReCaptcha from '@/hooks/useReCaptcha';
/**
 * ログイン処理を外部注入するログイン手続き
 * @returns
 */
export default function useLogin() {
  const {
    workspaceId: invitingOriginalWorkspaceId,
    invitingMemberId,
    projectId: invitingProjectId,
    invitingGuestId,
  } = useParams();
  const { refreshToken } = useReCaptcha(RE_CAPTCHA_ACTIONS.PASSWORD_SIGN_IN);
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const loginMode = useContext(loginModeContext);
  const accountRepository = new AccountRepository();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const login = useCallback(
    async (onDefaultLogin: () => Promise<void>) => {
      try {
        // ログイン直後にlocation.hrefが実行される前にget_startedを表示しないようにする
        dispatch(setAfterLogin(true));

        await onDefaultLogin();
        // メンバー招待からの参加
        if (loginMode === LOGIN_MODE.INVITING_MEMBER) {
          await accountRepository.joinAsMember(
            invitingOriginalWorkspaceId as string,
            invitingMemberId as string,
          );
          // 参加先のワークスペースに移動
          window.location.href = getNavigatePath(
            invitingOriginalWorkspaceId as string,
          );
        }
        // ゲスト招待からの参加
        else if (loginMode === LOGIN_MODE.INVITING_GUEST) {
          await accountRepository.joinAsGuest(
            invitingOriginalWorkspaceId as string,
            invitingGuestId as string,
          );
          // 参加先のプロジェクトに移動
          window.location.href = getNavigatePath(
            invitingOriginalWorkspaceId as string,
            invitingProjectId as string,
          );
        } else {
          window.location.href = '/';
        }
      } catch (error: any) {
        if (
          error.details === FUNCTIONS_ERROR_DETAILS.EXCESS_NUMBER_OF_MEMBERS
        ) {
          toast.error(
            t('ワークスペースへ参加できるメンバー数を超過しています'),
          );
        } else if (
          error.details === FUNCTIONS_ERROR_DETAILS.ALREADY_PARTICIPATED
        ) {
          toast.error(t('アカウントは既に参加済みです'));
        } else if (
          error.details ===
          FUNCTIONS_ERROR_DETAILS.WORKSPACE_PARTICIPATION_RESTRICTIONS
        ) {
          toast.error(t('アカウントはこれ以上ワークスペースへ参加できません'));
        } else if (error.code === 'auth/email-already-in-use') {
          // 既にメールアドレスが登録済みの場合
          toast.error(
            t(
              '入力したメールアドレスは登録済みです。再度確認いただくか、ログイン画面よりお試しください',
            ),
          );
        } else if (
          error.code !== 'auth/popup-closed-by-user' &&
          error.code !== 'auth/cancelled-popup-request'
        ) {
          toast.error(t('認証に失敗しました'));
        } else {
          // 上記以外は例外とする
          refreshToken();
          throw error;
        }
        refreshToken();
      }
    },
    [
      rrfAuth,
      invitingOriginalWorkspaceId,
      invitingProjectId,
      invitingGuestId,
      invitingMemberId,
    ],
  );

  return login;
}
