import { TailwindColorType } from '@/@types/common';
import React, { useMemo } from 'react';

import { classNames, getTailwindColorClass } from '@/libs/styleUtils';

export interface IBadgeProps {
  color: TailwindColorType;
  content: string | null;
  className?: string;
  size?: 'xs' | 'sm';
}

/**
 * UIとして使用するTailwindカラーのバッジ
 * @param props
 * @returns
 */
export function Badge(props: IBadgeProps) {
  const { color, content, className, size } = props;

  const tailwindColor = useMemo(() => getTailwindColorClass(color), [color]);

  const sizeClass = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'text-xs px-2 py-0.5';
      case 'sm':
        return 'text-sm px-2.5 py-0.5';
      default:
        return 'px-2.5 py-0.5';
    }
  }, [size]);
  return (
    <span
      className={classNames(
        className,
        tailwindColor?.bg,
        sizeClass,
        'inline-flex items-center font-medium rounded-md',
      )}
    >
      <span className="dark:opacity-70 dark:text-white">{content}</span>
    </span>
  );
}
