import { Menu, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import RelativePortal from 'react-relative-portal';

import { classNames } from '@/libs/styleUtils';

import { getNavigatePath } from '@/libs/utils';

import EllipsisText from '@/components/Common//EllipsisText';
import PopupTransition from '@/components/Common//Transitions/PopupTransition';
import NewWorkspaceDialog from '@/components/Workspace/NewWorkspaceDialog';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import NotificationBadge from '../Notification/NotificationBadge';
import RedDot from '../RedDot';

export interface IWorkspaceSelectProps {
  className?: string;
  popupClassName?: string;
}

interface ICreateWorkspaceItemProps {
  onClick: () => void;
}

/**
 * ワークスペース作成メニューアイテム
 * @param props
 * @returns
 */
function CreateWorkspaceItem(props: ICreateWorkspaceItemProps) {
  const { t } = useTranslation();
  const { onClick } = props;

  return (
    <Menu.Item>
      {({ active }) => (
        <button
          type="button"
          className={classNames(
            active
              ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
              : '',
            'w-full px-4 py-2 text-left flex items-center justify-start',
          )}
          onClick={onClick}
        >
          <PlusIcon className="w-4 h-4" aria-hidden="true" />
          <div className="ml-2">{t('ワークスペースを作成')}</div>
        </button>
      )}
    </Menu.Item>
  );
}

/**
 * ワークスペース選択
 * @param props:IWorkspaceSelectProps
 * @returns
 */
export default function WorkspaceSelect(props: IWorkspaceSelectProps) {
  const { t } = useTranslation();
  const { className, popupClassName } = props;
  const [newDialogOpen, setNewDialogOpen] = useState(false);

  const { myWorkspaces, currentMyWorkspace } = useMyWorkspaces();

  const totalNotificationCount = useMemo(
    () =>
      myWorkspaces.reduce(
        (sum, ws) => sum + (ws?.toCommentsUnReadCount || 0),
        0,
      ),
    [myWorkspaces],
  );
  return (
    <>
      <Menu
        as="div"
        className={classNames(
          className,
          'relative inline-block w-full text-left',
        )}
      >
        {({ open }) => (
          <>
            <div>
              <Menu.Button className="inline-flex items-center justify-center w-full py-3 font-medium text-gray-700 hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-300">
                <div className="flex-1 overflow-hidden text-base font-bold leading-none text-left">
                  {currentMyWorkspace ? (
                    <EllipsisText className="w-40">
                      {
                        myWorkspaces?.find(
                          (o) => o?.id === currentMyWorkspace.id,
                        )?.workspaceName
                      }
                    </EllipsisText>
                  ) : (
                    <Skeleton />
                  )}
                </div>
                <div className="relative">
                  <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
                  {totalNotificationCount > 0 && (
                    <RedDot className="absolute ml-1 -top-1 -right-1" />
                  )}
                </div>
              </Menu.Button>
            </div>
            <RelativePortal component="div" left={0}>
              <PopupTransition show={open}>
                <Menu.Items
                  static
                  className={classNames(
                    popupClassName,
                    'absolute left-0 z-40 w-72 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none',
                  )}
                >
                  <div className="py-1 overflow-auto max-h-64">
                    {myWorkspaces !== null &&
                      myWorkspaces.map((item) => (
                        <Menu.Item key={item?.workspaceId}>
                          {({ active }) => {
                            const isCurrent =
                              currentMyWorkspace?.id === item?.workspaceId;
                            const unreadCount =
                              item?.toCommentsUnReadCount || 0;
                            return (
                              <a
                                key={item?.workspaceId}
                                onClick={(e) => {
                                  if (isCurrent) {
                                    e.preventDefault();
                                  }
                                }}
                                href={getNavigatePath(
                                  item?.originalWorkspaceId as string,
                                )}
                                className={classNames(
                                  active || isCurrent
                                    ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                    : '',
                                  'w-full block px-4 py-2 text-left',
                                  isCurrent ? 'cursor-default' : '',
                                )}
                              >
                                <div className="flex items-center justify-between w-full">
                                  <EllipsisText className="w-full font-bold">
                                    {item?.workspaceName}
                                  </EllipsisText>
                                  {unreadCount > 0 && (
                                    <NotificationBadge
                                      unread
                                      noDot
                                      total={unreadCount}
                                    />
                                  )}
                                </div>

                                <div className="text-xs opacity-70 space-x-3">
                                  {item?.joinType === 'member' && (
                                    <span>{t('メンバーとして参加中')}</span>
                                  )}
                                  {item?.joinType === 'guest' && (
                                    <span>{t('ゲストとして参加中')}</span>
                                  )}
                                  {item?.joinType === 'unknownGuest' && (
                                    <span>{t('匿名ゲストとして参加中')}</span>
                                  )}
                                </div>
                              </a>
                            );
                          }}
                        </Menu.Item>
                      ))}
                  </div>
                  <div className="py-1">
                    <CreateWorkspaceItem
                      onClick={() => setNewDialogOpen(true)}
                    />
                  </div>
                </Menu.Items>
              </PopupTransition>
            </RelativePortal>
          </>
        )}
      </Menu>
      <Transition.Root show={newDialogOpen} unmount>
        <NewWorkspaceDialog onClose={() => setNewDialogOpen(false)} />
      </Transition.Root>
    </>
  );
}
