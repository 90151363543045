import React from 'react';

import ErrorPage from '@/pages/layouts/ErrorPage';

export default function Error500() {
  return (
    <ErrorPage
      code={500}
      title="Internal server error"
      description="Is currently unable to handle this request."
    />
  );
}
