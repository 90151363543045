import { IModalDialogProps } from '@/@types/ui';
import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFirebase } from 'react-redux-firebase';
import { toast } from 'react-toastify';

import ConfirmDialog from '@/components/Common/ConfirmDialog';

export interface IModifyPasswordConfirmDialogProps extends IModalDialogProps {
  toEmail?: string | null | undefined;
}

/**
 * パスワード変更確認ダイアログ
 * @param props
 * @returns
 */
export default function ModifyPasswordConfirmDialog(
  props: IModifyPasswordConfirmDialogProps,
) {
  const { t } = useTranslation();
  const { onClose, toEmail } = props;
  const rrfFirebase = useFirebase();
  const [isSendingEmail, setIsSendingEmail] = React.useState<boolean>(false);
  const [hasSentMail, setSentMail] = React.useState<boolean>(false);

  const onSendPasswordChangeEmailClick = useCallback(
    async (confirmed) => {
      if (!confirmed) {
        setIsSendingEmail(false);
        onClose();
        return;
      }
      try {
        setIsSendingEmail(true);
        await rrfFirebase.auth().sendPasswordResetEmail(toEmail as string);
        setSentMail(true);
      } catch (e) {
        toast.error(t('メールの送信に失敗しました'));
        onClose();
        throw e;
      } finally {
        setIsSendingEmail(false);
      }
    },
    [toEmail],
  );

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="px-4 py-8 border shadow sm:rounded-lg sm:px-10 dark:border-gray-700">
        {!hasSentMail ? (
          <ConfirmDialog
            open
            key="user-delete-confirm"
            onClose={onSendPasswordChangeEmailClick}
            title={t('パスワードを変更')}
            positive={t('送信')}
            spinner={isSendingEmail}
          >
            <p className="mb-2">
              {t('<toEmail> 宛にパスワード変更手続きのご案内を送信します。', {
                toEmail,
              })}
            </p>
          </ConfirmDialog>
        ) : (
          <ConfirmDialog
            open
            key="mail-sent-confirm"
            title={t('メール送信完了')}
            positive={t('OK')}
            onClose={() => {
              onClose();
            }}
            positiveOnly
          >
            <div className="mb-5">
              {t('<toEmail> 宛にパスワード変更手続きのご案内を送信しました。', {
                toEmail,
              })}
            </div>
          </ConfirmDialog>
        )}
      </div>
    </div>
  );
}
