import * as React from 'react';

export interface ILogoTitleProps {
  className?: string;
}

/**
 * ロゴタイトル
 * @param props
 * @returns
 */
export default function LogoTitle(props: ILogoTitleProps) {
  const { className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 356.85 61.51"
      className={className}
    >
      <defs />
      <g>
        <g>
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M69,16a21.52,21.52,0,0,1,15.79,6.56A21.52,21.52,0,0,1,91.3,38.32a21.49,21.49,0,0,1-6.56,15.8,22.27,22.27,0,0,1-31.59,0,21.53,21.53,0,0,1-6.56-15.8,21.55,21.55,0,0,1,6.56-15.79A21.54,21.54,0,0,1,69,16Zm0,9.59A12.79,12.79,0,0,0,56.1,38.45,12.85,12.85,0,0,0,78,47.53a12.38,12.38,0,0,0,3.76-9.08A12.84,12.84,0,0,0,69,25.56Z"
          />
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M104.3,0a4.57,4.57,0,0,1,3.3,1.36A4.56,4.56,0,0,1,109,4.7V55.92a4.33,4.33,0,0,1-1.4,3.3,4.49,4.49,0,0,1-3.3,1.4h-.12a4.49,4.49,0,0,1-3.3-1.4,4.32,4.32,0,0,1-1.39-3.3V4.7a4.55,4.55,0,0,1,1.39-3.34A4.57,4.57,0,0,1,104.18,0Z"
          />
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M124.67,0A4.55,4.55,0,0,1,128,1.36a4.56,4.56,0,0,1,1.4,3.34V55.92a4.33,4.33,0,0,1-1.4,3.3,4.47,4.47,0,0,1-3.29,1.4h-.12a4.49,4.49,0,0,1-3.3-1.4,4.33,4.33,0,0,1-1.4-3.3V4.7a4.56,4.56,0,0,1,1.4-3.34A4.57,4.57,0,0,1,124.55,0Z"
          />
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M176.84,22.53a21.55,21.55,0,0,1,6.56,15.79V56a4.34,4.34,0,0,1-1.4,3.29,4.48,4.48,0,0,1-3.29,1.41h-.13a4.48,4.48,0,0,1-3.29-1.41,4.34,4.34,0,0,1-1.4-3.29q-4.42,4.69-12.84,4.7a21.51,21.51,0,0,1-15.8-6.57,21.53,21.53,0,0,1-6.56-15.8,21.55,21.55,0,0,1,6.56-15.79,22.3,22.3,0,0,1,31.59,0Zm-24.92,6.71a12.47,12.47,0,0,0-3.72,9.08,12.7,12.7,0,0,0,12.85,12.85,12.77,12.77,0,0,0,12.84-12.85,12.88,12.88,0,0,0-22-9.08Z"
          />
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M196,0a4.55,4.55,0,0,1,3.29,1.36,4.56,4.56,0,0,1,1.4,3.34V19.79q4.38-3.87,12.85-3.88a21.54,21.54,0,0,1,15.79,6.56,21.54,21.54,0,0,1,6.56,15.8,21.54,21.54,0,0,1-6.56,15.79,22.3,22.3,0,0,1-31.55,0,21.36,21.36,0,0,1-6.6-15.72V4.7a4.52,4.52,0,0,1,1.4-3.34A4.56,4.56,0,0,1,195.84,0ZM213.5,25.5a12.77,12.77,0,0,0-12.85,12.88,12.85,12.85,0,1,0,25.69,0A12.84,12.84,0,0,0,213.5,25.5Z"
          />
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M247.24,0a4.57,4.57,0,0,1,3.3,1.36,4.56,4.56,0,0,1,1.4,3.34V19.79q4.38-3.87,12.84-3.88a21.56,21.56,0,0,1,15.8,6.56,21.53,21.53,0,0,1,6.55,15.8,21.53,21.53,0,0,1-6.55,15.79,22.3,22.3,0,0,1-31.55,0,21.36,21.36,0,0,1-6.6-15.72V4.7a4.55,4.55,0,0,1,1.39-3.34A4.57,4.57,0,0,1,247.12,0Zm17.54,25.5a12.77,12.77,0,0,0-12.84,12.88,12.85,12.85,0,0,0,25.69,0A12.85,12.85,0,0,0,264.78,25.5Z"
          />
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M300.05,0a4.57,4.57,0,0,1,3.3,1.36,4.56,4.56,0,0,1,1.4,3.34V55.92a4.33,4.33,0,0,1-1.4,3.3,4.49,4.49,0,0,1-3.3,1.4h-.11a4.49,4.49,0,0,1-3.3-1.4,4.29,4.29,0,0,1-1.4-3.3V4.7a4.52,4.52,0,0,1,1.4-3.34A4.57,4.57,0,0,1,299.94,0Z"
          />
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M356.85,38.29a4.58,4.58,0,0,1-.38,2,4.77,4.77,0,0,1-4.29,2.76l-15.67.06c-8,0-12.75.07-14.16.1q1.18,4.6,6.89,7.15a12.85,12.85,0,0,0,14.61-3,4.41,4.41,0,0,1,5.41-1.08,4.47,4.47,0,0,1,2.68,3.42,4.24,4.24,0,0,1-.24,2.74,4.82,4.82,0,0,1-1,1.39A21.39,21.39,0,0,1,339,60.39a22.27,22.27,0,0,1-25.38-13.88,21.54,21.54,0,0,1,.44-17.1,22.33,22.33,0,0,1,39.18-3A21.87,21.87,0,0,1,356.85,38.29ZM339.72,26.86a12.78,12.78,0,0,0-17,6.53l-.11.26,23.78-.06Q345.18,29.3,339.72,26.86Z"
          />
          <path
            className="fill-logoTitle dark:fill-logoTitle-light"
            d="M36.1,46.33a4.78,4.78,0,0,0-3.53,1.55l0,0a13.16,13.16,0,1,1,.1-17.92h0a4.79,4.79,0,0,0,8.27-3.25,4.71,4.71,0,0,0-1.37-3.33l0,0A22.78,22.78,0,1,0,22.89,61.51a22.89,22.89,0,0,0,16.5-7l.16-.17.18-.2a4.65,4.65,0,0,0,1.16-3.07A4.77,4.77,0,0,0,36.1,46.33Z"
          />
        </g>
      </g>
    </svg>
  );
}
