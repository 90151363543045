import { View } from '@/@types/models';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PLANS } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import ImgBillboard from '@/assets/t_billboard.svg';
import ImgKanban from '@/assets/t_kanban.svg';
import ImgList from '@/assets/t_list.svg';
// TODO:スケジュール実装時に追加
// import ImgSchedule from '@/assets/t_schedule.svg';

import usePlans from '@/hooks/usePlans';

import PlanUpgradeBadge from '../Common/PlanUpgradeBadge';

interface Thumbnail {
  src: any;
  label: string;
  value: View['viewType'];
  isInAvailable?: boolean;
}

interface ICreateViewFormProps {
  selectViewLabel: string;
  selectedView: View['viewType'];
  onViewSelect: (value: View['viewType']) => void;
}

/**
 * ビュー追加フォーム
 * @param props
 * @returns
 */
export default function CreateViewForm(props: ICreateViewFormProps) {
  const { selectViewLabel, selectedView, onViewSelect } = props;
  const { t } = useTranslation();
  const { setValue, formState, trigger, setFocus, getFieldState } =
    useFormContext();

  // standardとfreeプランによって権限変更
  const { plan } = usePlans();
  const isInAvailable = useMemo(() => {
    if (plan?.type === PLANS.STANDARD) {
      return false;
    }
    return true;
  }, [plan]);

  /** 選択可能ビュー種別 */
  const views: Thumbnail[] = useMemo(
    () => [
      {
        src: ImgList,
        label: t('リスト'),
        value: 'list',
      },
      {
        src: ImgKanban,
        label: t('カンバン'),
        value: 'kanban',
      },
      {
        src: ImgBillboard,
        label: t('掲示板'),
        value: 'billboard',
        isInAvailable,
      },
      // TODO:スケジュール実装時に追加
      // {
      //   src: ImgSchedule,
      //   label: t('スケジュール'),
      //   value: 'schedule',
      //   isInAvailable: true,
      // },
    ],
    [t],
  );

  // ビューのvalueとlabelを対応づけるためのマップ
  const viewTypeMap = useMemo(
    () =>
      views.reduce((acc, cur) => {
        if (cur.value) {
          acc[cur.value] = cur.label;
        }
        return acc;
      }, {} as { [key: string]: string }),
    [views],
  );

  /**
   * 選択されたビューを返す
   */
  const selectedViewLists = useMemo(
    () => views.find((view) => view.value === selectedView),
    [selectedView],
  );

  /**
   * ビューが選択されたらビュー名にフォーカスして文字を選択状態にする
   */
  useEffect(() => {
    if (getFieldState('viewType').isTouched)
      setTimeout(() => {
        setFocus('viewName', { shouldSelect: true });
      });
    // ビュー選択時にビュー名の初期値を自動入力
    const isViewNameDirty = !!formState.dirtyFields.viewName;
    if (!isViewNameDirty && selectedView) {
      const defaultViewValue = viewTypeMap[selectedView];
      setValue('viewName', defaultViewValue, {
        shouldValidate: true,
        shouldDirty: false,
        shouldTouch: true,
      });
    }
  }, [selectedView]);

  useEffect(() => {
    setValue('viewType', selectedView, {
      shouldTouch: true,
    });
  }, [formState]);

  /**
   * Change View
   * @param item
   */
  const handleViewChange = (item: Thumbnail) => {
    setValue('viewType', item.value, {
      shouldDirty: true,
      shouldTouch: true,
    });
    onViewSelect(item.value);
    trigger();
  };

  return (
    <>
      <FormCol>
        <RadioGroup value={selectedViewLists} onChange={handleViewChange}>
          <RadioGroup.Label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
            {selectViewLabel}
          </RadioGroup.Label>
          <div className="hidden">
            {/** i18next-extract用静的記述 */}:{t('リスト')}:{t('カンバン')}:
            {t('掲示板')}:{t('スケジュール')}
          </div>
          <div className="mt-4 grid grid-cols-2 gap-4 ">
            {views.map((item) => (
              <RadioGroup.Option
                key={item.value}
                value={item}
                disabled={item.value === 'schedule'}
                className={({ checked, active }) =>
                  classNames(
                    checked
                      ? 'border-transparent'
                      : 'border-gray-300 dark:border-gray-600',
                    active
                      ? 'ring-1 ring-primary-500 dark:ring-primary-600'
                      : '',
                    item.isInAvailable ? 'pointer-events-none' : '',
                    'relative border rounded-lg shadow-sm p-2 flex cursor-pointer focus:outline-none',
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <div
                      className={classNames(
                        item.isInAvailable ? 'opacity-40' : '',
                        'w-full',
                      )}
                    >
                      <div className="flex flex-1 w-full">
                        <div className="flex flex-wrap w-full">
                          <div className="p-1 text-sm sm:w-1/2">
                            {t(item.label)}
                          </div>
                          <img
                            src={item.src}
                            alt={t(item.label)}
                            className="w-full sm:w-1/2"
                          />
                        </div>
                      </div>
                      <CheckCircleIcon
                        className={classNames(
                          !checked ? 'invisible' : '',
                          'h-5 w-5 text-primary-500 dark:text-primary-600',
                        )}
                        aria-hidden="true"
                      />
                      <div
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked
                            ? 'border-primary-500 dark:border-primary-600'
                            : 'border-transparent',
                          'absolute -inset-px rounded-lg pointer-events-none',
                        )}
                        aria-hidden="true"
                      />
                    </div>

                    {item.isInAvailable && (
                      <div className="absolute left-0 right-0 px-3 text-xs text-left text-gray-400 bottom-3 dark:text-gray-500">
                        <PlanUpgradeBadge className="mt-3" />
                      </div>
                    )}
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </FormCol>
      <FormCol>
        <Input name="viewName" label={t('ビュー名')} autoFocus />
      </FormCol>
    </>
  );
}
