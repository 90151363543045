import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

// import { useDispatch } from 'react-redux';
import { useDispatch } from 'react-redux';
import RelativePortal from 'react-relative-portal';
import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

import { openNewItemDialog } from '@/reducers/newItemDialogReducer';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';
import ItemSearch from '@/components/Project/ItemSearch';
import PropertySettingDialog from '@/components/View/PropertySettingDialog';

import ViewTab from '@/components/View/ViewTab';

import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useProjects from '@/hooks/useProjects';

export default function ViewHeader() {
  const dispatch = useDispatch();
  const { isMember } = useMyWorkspaces();
  const [isPropertySettingDialogOpen, setPropertySettingDialogOpen] =
    useState(false);
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const { isReadOnly } = useProjects();

  const { isItemLimitOver } = useItems();

  return (
    <div className="w-full py-4 border-gray-200 dark:border-gray-700">
      <div className="flex items-center max-w-full flex-nowrap">
        <div className="flex items-center flex-grow py-1 mr-4 overflow-hidden flex-0 space-x-0">
          <ViewTab />
          <ItemSearch className="pl-3 basis-auto md:basis-2/3 lg:basis-2/3 xl:basis-1/3 min-w-[15rem]" />
        </div>

        {(isMember || !isReadOnly) && (
          <>
            <div className="items-center hidden lg:flex lg:ml-auto sm:justify-end sm:flex-no-wrap space-x-4">
              {isMember && (
                <MenuButton
                  id="showPropertySetting"
                  type="text"
                  onClick={() => setPropertySettingDialogOpen(true)}
                  className="whitespace-nowrap"
                >
                  {t('プロパティ設定')}
                </MenuButton>
              )}
              {/* <ViewSearchAndFilter /> */}
              {/* TODO: アイテム新規作成 */}
              <MenuButton
                id="showNewItem"
                type="text"
                variant="primary"
                className="whitespace-nowrap"
                onClick={() => dispatch(openNewItemDialog())}
                disabled={isItemLimitOver}
                toolTip={
                  isItemLimitOver
                    ? t(
                        'アイテム数上限に達しました。アイテムを作成したい場合は、既存のアイテムを削除してください。',
                      )
                    : ''
                }
              >
                <PlusIcon className="w-4 h-4 mt-0.5" aria-hidden="true" />
                <div className="ml-1">{t('新規作成')}</div>
              </MenuButton>
            </div>

            <Menu as="div" className="lg:hidden">
              {({ open }) => (
                <>
                  <Menu.Button
                    className={classNames(
                      'text-gray-400 hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600 ',
                      'inline-flex justify-center p-1',
                      'items-center border border-transparent rounded-md block',
                    )}
                  >
                    <EllipsisVerticalIcon
                      className="w-4 h-4"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                  <RelativePortal component="div" right={0}>
                    <PopupTransition show={open}>
                      <Menu.Items
                        static
                        className="absolute right-0 z-20 w-40 mt-2 text-gray-800 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 dark:divide-gray-600 dark:text-gray-100"
                      >
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                id="showPropertySetting"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPropertySettingDialogOpen(true);
                                }}
                                className={classNames(
                                  active
                                    ? 'bg-red-400 dark:bg-primary-600 text-white dark:text-white'
                                    : '',
                                  'w-full block px-4 py-2 text-left',
                                )}
                              >
                                {t('プロパティ設定')}
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                id="showNewItem"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(openNewItemDialog());
                                }}
                                className={classNames(
                                  active
                                    ? 'bg-red-400 dark:bg-primary-600 text-white dark:text-white'
                                    : '',
                                  'w-full block px-4 py-2 text-left',
                                )}
                              >
                                {t('新規作成')}
                              </button>
                            )}
                          </Menu.Item>
                        </div>{' '}
                      </Menu.Items>
                    </PopupTransition>
                  </RelativePortal>
                </>
              )}
            </Menu>
          </>
        )}
      </div>
      {isMember && (
        // プロパティ設定ダイアログ
        <Transition.Root show={isPropertySettingDialogOpen} unmount>
          <PropertySettingDialog
            onClose={() => setPropertySettingDialogOpen(false)}
          />
        </Transition.Root>
      )}
    </div>
  );
}
