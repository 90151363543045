import { MyProject, RootState } from '@/@types/models';
import { Menu, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import RelativePortal from 'react-relative-portal';

import ReactTooltip from 'react-tooltip';

import { PLANS } from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import AppLinkageDialog from '@/components/AppLinkage/AppLinkageDialog';
import ConfirmDialog from '@/components/Common/ConfirmDialog';

import PopupTransition from '@/components/Common/Transitions/PopupTransition';
import CopyProjectDialog from '@/components/Project/CopyProjectDialog';
import EditProjectNameDialog from '@/components/Project/EditProjectNameDialog';

import NewViewDialog from '@/components/View/NewViewDialog';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePlans from '@/hooks/usePlans';
import useProjects from '@/hooks/useProjects';

import PlanUpgradeBadge from '../Common/PlanUpgradeBadge';
import CsvExportDialog from '../CsvExport/CsvExportDialog';

interface IProjectDropdownProps {
  className?: string;
  myProject: MyProject;
}

export default function ProjectDropdown(props: IProjectDropdownProps) {
  const { t } = useTranslation();
  const { className, myProject } = props;
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [newViewDialogOpen, setNewViewDialogOpen] = useState<boolean>(false);
  const [appLinkageDialogOpen, setAppLinkageDialogOpen] =
    useState<boolean>(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState<boolean>(false);
  const [exportItemDialogOpen, setExportItemDialogOpen] =
    useState<boolean>(false);
  const { newViewFlg } = useSelector((state: RootState) => state.views);
  const { deleteProject } = useProjects();
  const { isMember, currentMyWorkspace } = useMyWorkspaces();
  const { plan } = usePlans();

  const handleDeleteConfirm = useCallback(
    async (deleteFlg: boolean | undefined) => {
      if (deleteFlg && myProject) {
        await deleteProject(myProject.id, myProject.projectId);
      }
      setDeleteDialogOpen(false);
    },
    [deleteProject, deleteDialogOpen],
  );
  // 画面初期化時に新規プロジェクトのフラグがtrueの場合
  useEffect(() => {
    if (newViewFlg === true && newViewDialogOpen === false) {
      setNewViewDialogOpen(true);
    }
  }, [newViewFlg]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  // メンバーでもなく複製も許可されていない場合はメニュー非表示
  if (!isMember && !myProject.allowGuestCopy) return null;

  return (
    <>
      {' '}
      <Menu
        as="div"
        className={classNames(className, 'relative inline-block text-left')}
      >
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                as="div"
                role="button"
                className="flex items-center p-1 text-gray-400 rounded-md hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500"
              >
                <span className="sr-only">Open options</span>
                <EllipsisVerticalIcon className="w-4 h-4" aria-hidden="true" />
              </Menu.Button>
            </div>
            <RelativePortal component="div" right={0}>
              <PopupTransition show={open}>
                <Menu.Items className="absolute right-0 z-10 w-56 mt-2 text-gray-800 bg-white shadow-lg origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100 dark:divide-gray-600 dark:text-gray-100">
                  {isMember && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => {
                              setNewViewDialogOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                : '',
                              'w-full px-4 py-2 text-left flex items-center justify-start',
                            )}
                          >
                            <PlusIcon className="w-4 h-4" aria-hidden="true" />
                            <div className="ml-2">{t('ビューを追加')}</div>
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  )}
                  <div className="py-1">
                    {isMember && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => {
                              setEditDialogOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                : '',
                              'w-full block px-4 py-2 text-left',
                            )}
                          >
                            {t('プロジェクト名を変更')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {(isMember || myProject.allowGuestCopy) && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => {
                              setCopyDialogOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                : '',
                              'w-full block px-4 py-2 text-left',
                            )}
                          >
                            {t('プロジェクトを複製')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {isMember && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={() => {
                              setDeleteDialogOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-red-400 dark:bg-red-500 text-white dark:text-white'
                                : '',
                              'w-full block px-4 py-2 text-left',
                            )}
                          >
                            {t('プロジェクトを削除')}
                          </button>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                  {isMember && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAppLinkageDialogOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                                : '',
                              'w-full block px-4 py-2 text-left',
                            )}
                          >
                            {t('アプリ連携')}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  )}
                  {isMember && (
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            disabled={plan.type === PLANS.FREE}
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setExportItemDialogOpen(true);
                            }}
                            className={classNames(
                              active
                                ? 'bg-primary-500 dark:bg-primary-600 '
                                : '',
                              plan.type === PLANS.FREE
                                ? 'cursor-not-allowed text-gray-500 dark:text-gray-500'
                                : 'hover:text-white',
                              'w-full block px-4 py-2 text-left',
                            )}
                          >
                            {t('エクスポート（csv形式）')}
                            {plan.type === PLANS.FREE && <PlanUpgradeBadge />}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  )}
                </Menu.Items>
              </PopupTransition>
            </RelativePortal>
          </>
        )}
      </Menu>
      <div>
        {isMember && (
          <>
            <RelativePortal component="div">
              <Transition.Root show={newViewDialogOpen} unmount>
                <NewViewDialog
                  myProject={myProject}
                  onClose={() => setNewViewDialogOpen(false)}
                />
              </Transition.Root>
            </RelativePortal>
            <RelativePortal component="div">
              <Transition.Root show={editDialogOpen} unmount>
                <EditProjectNameDialog
                  myProject={myProject}
                  onClose={() => setEditDialogOpen(false)}
                />
              </Transition.Root>
            </RelativePortal>
            <ConfirmDialog
              open={deleteDialogOpen}
              onClose={handleDeleteConfirm}
              title={t('プロジェクトを削除')}
              positive={t('削除')}
              warning
            >
              <p className="mb-2">
                {t('<name> を削除しようとしています。', {
                  name: (
                    <span className="mr-1 font-bold">
                      {myProject.projectName}
                    </span>
                  ),
                })}
              </p>
              <p>
                {t(
                  '削除されたプロジェクトは復元できません。<br> 本当によろしいですか？',
                  { br: <br /> },
                )}
              </p>
            </ConfirmDialog>
          </>
        )}
        <RelativePortal component="div">
          <Transition.Root show={copyDialogOpen} unmount>
            <CopyProjectDialog
              myProject={myProject}
              onClose={() => setCopyDialogOpen(false)}
            />
          </Transition.Root>
        </RelativePortal>
        <RelativePortal component="div">
          <Transition.Root show={appLinkageDialogOpen} unmount>
            <AppLinkageDialog
              myProject={myProject}
              onClose={() => setAppLinkageDialogOpen(false)}
            />
          </Transition.Root>
        </RelativePortal>
        <RelativePortal component="div">
          <Transition.Root show={exportItemDialogOpen} unmount>
            <CsvExportDialog
              workspaceId={currentMyWorkspace?.id}
              projectId={myProject?.id}
              onClose={() => setExportItemDialogOpen(false)}
            />
          </Transition.Root>
        </RelativePortal>
      </div>
    </>
  );
}
