import { IJoinType, RootState } from '@/@types/models';
import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { NOTIFICATION } from '@/libs/const';

import MentionToAllImg from '@/assets/mention_to_all.svg';

import useMentions from '@/hooks/useMentions';

const mentionsCache = new Map();

export interface IMentionTo {
  name: string;
  optionName: string;
  id: string;
  photoURL: string;
  joinType?: IJoinType;
}

/**
 * メンション先を生成
 * @param mentionString
 * @returns
 */
export default function useMentionLookupService(mentionString: string | null) {
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const { memberMentions, guestMentions } = useMentions();
  const { t } = useTranslation();

  const [results, setResults] = useState<Array<IMentionTo>>([]);

  const prefix = '@';

  const defaultToAll = useMemo<IMentionTo[]>(
    () => [
      {
        name: NOTIFICATION.SPECIAL_MENTION.ALL,
        optionName: t('プロジェクトの全員に通知'),
        id: prefix.concat(NOTIFICATION.SPECIAL_MENTION.ALL),
        photoURL: MentionToAllImg,
      },
      {
        name: NOTIFICATION.SPECIAL_MENTION.MEMBER,
        optionName: t('プロジェクトのメンバー全員に通知'),
        id: prefix.concat(NOTIFICATION.SPECIAL_MENTION.MEMBER),
        photoURL: MentionToAllImg,
      },
      {
        name: NOTIFICATION.SPECIAL_MENTION.GUEST,
        optionName: t('プロジェクトのゲスト全員に通知'),
        id: prefix.concat(NOTIFICATION.SPECIAL_MENTION.GUEST),
        photoURL: MentionToAllImg,
      },
    ],
    [],
  );

  // メンションリスト
  const mentionList = useMemo<IMentionTo[]>(() => {
    const concatMentions = memberMentions.concat(guestMentions);
    const uniqueMentions = Array.from(
      concatMentions
        .reduce(
          (map, currentItem) => map.set(currentItem.id, currentItem),
          new Map(),
        )
        .values(),
    );
    const mentions: IMentionTo[] = uniqueMentions.filter(
      (o) => o.id !== userId,
    );
    return mentions;
  }, [memberMentions, guestMentions]);

  const mentionSelectList = useMemo(() => {
    const mapped = mentionList.map((m) => ({
      ...m,
      unavailable: false,
    }));
    return [...defaultToAll, ...mapped];
  }, [mentionList]);

  const lookupService = {
    search(
      string: string,
      callback: (filter: Array<IMentionTo>) => void,
    ): void {
      setTimeout(() => {
        if (string.toLowerCase() === prefix) {
          callback(mentionSelectList);
          return;
        }
        const filter = mentionSelectList.filter((mention) =>
          mention.name.toLowerCase().includes(string.toLowerCase()),
        );
        callback(filter);
      }, 500);
    },
  };

  useEffect(() => {
    const cachedResults = mentionsCache.get(mentionString);
    if (mentionString == null) {
      setResults([]);
      return;
    }

    if (cachedResults === null) {
      return;
    }
    if (cachedResults !== undefined) {
      setResults(cachedResults);
      return;
    }

    mentionsCache.set(mentionString, null);
    lookupService.search(mentionString, (newResults) => {
      mentionsCache.set(mentionString, newResults);
      setResults(newResults);
    });
  }, [mentionString]);

  return results;
}
