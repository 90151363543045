import { IFirestoreQuery } from '@/@types/common';
import { MyWorkspace, Workspace } from '@/@types/models';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';

import { REP_ERROR_MESSAGE } from '@/libs/const';
import { getMyWorkspacesPath } from '@/libs/docPathUtils';
import { composeAppendQuery } from '@/libs/repositoryUtils';
import { filterMyWorkspaces, fsConverter, repositoryError } from '@/libs/utils';

import { firestore } from '@/firestore';

export interface IMyWorkspaceRepository {
  list: (params?: IFirestoreQuery<MyWorkspace>) => Promise<MyWorkspace[]>;
  isMyWorkspace: (workspaceId: Workspace['id']) => Promise<boolean>;
}

export default class MyWorkspaceRepository implements IMyWorkspaceRepository {
  accountId: string;

  constructor(accountId: string) {
    this.accountId = accountId;
  }

  /**
   * List myWorkspace docs
   * @returns Promise<MyWorkspace[]>
   */
  async list(params?: IFirestoreQuery<MyWorkspace>): Promise<MyWorkspace[]> {
    const appends = composeAppendQuery(params);
    try {
      const collectionRef = collection(
        firestore,
        getMyWorkspacesPath(this.accountId),
      ).withConverter(fsConverter<MyWorkspace>());
      const collectionQuery = query(
        collectionRef,
        orderBy('workspaceName'),
        ...appends,
      );
      return filterMyWorkspaces(
        (await getDocs(collectionQuery)).docs.map((d) => {
          const myWorkspace = d.data();
          myWorkspace.id = d.id;
          return myWorkspace;
        }),
      );
    } catch (err: any) {
      repositoryError(this.list.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * Check is it myWorkspace
   * @param {string} workspaceId
   * @returns Promise<boolean>
   */
  async isMyWorkspace(workspaceId: MyWorkspace['id']): Promise<boolean> {
    try {
      const q = query(
        collection(
          firestore,
          getMyWorkspacesPath(this.accountId),
        ).withConverter(fsConverter<MyWorkspace>()),
        where('workspaceId', '==', workspaceId),
      );

      return (await getDocs(q)).size > 0;
    } catch (err: any) {
      repositoryError(this.isMyWorkspace.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }
}
