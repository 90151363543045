import { EV } from '@/@types/events';
import { RootState } from '@/@types/models';
import {
  CodeBracketIcon,
  FaceSmileIcon,
  FolderPlusIcon,
  LinkIcon,
  CommandLineIcon,
} from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import {
  $getRoot,
  $getSelection,
  RangeSelection,
  FORMAT_TEXT_COMMAND,
} from 'lexical';
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactChild,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { classNames } from '@/libs/styleUtils';

import ua from '@/libs/ua';

import {
  closeItemCommentEmojiPopup,
  openItemCommentEmojiPopup,
} from '@/reducers/itemsReducer';

import EmojiPicker from '@/components/Common/EmojiPicker';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import IconBtn from '@/components/Common/Icon/IconBtn';
import {
  // AlertBannedOutlineIcon,
  // AlertOutlineIcon,
  BoldOutlineIcon,
  FormatOutlineIcon,
  ItalicOutlineIcon,
  MarkerOutlineIcon,
  StrikethroughOutlineIcon,
  UnderlineOutlineIcon,
} from '@/components/Common/Icon/Icons';
import useLexicalMonitor from '@/components/Common/Lexical/utils/useLexicalMonitor';

// useAgentによるOS判定
const isIos = ua.isIOS();

interface IDividerProps {
  className: string;
}
function Divider(props: IDividerProps) {
  const { className } = props;
  const composedClass = useMemo(
    () => classNames(className, 'w-px bg-gray-200 dark:bg-gray-600'),
    [className],
  );
  return <div className={composedClass} />;
}

export interface ICommentToolbarHandler {
  send: () => void;
  setShouldAlert: (shouldAlert: boolean) => void;
  uploadFiles: (files: File[]) => void;
}

type ICommentToolbarProps = ComponentPropsWithoutRef<'div'> & {
  children: ReactChild;
  isEditMode: boolean;
  canSend: boolean;
  onSend: (jsonState: string, textState: string, shouldAlert: boolean) => void;
  onCancel: () => void;
  onToggleRichMode: (mode: boolean) => void;
  onSelectFiles: (files: File[]) => void;
  disabledFileUpload?: boolean;
};

const CommentToolbar = forwardRef<ICommentToolbarHandler, ICommentToolbarProps>(
  (props: ICommentToolbarProps, ref) => {
    const { t } = useTranslation();
    const toolbarRef = useRef(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const {
      isEditMode,
      canSend,
      children,
      onSend,
      onCancel,
      onToggleRichMode,
      onSelectFiles,
      disabledFileUpload,
    } = props;
    const [editor] = useLexicalComposerContext();
    const dispatch = useDispatch();
    const { isCommentEmojiOpen } = useSelector(
      (state: RootState) => state.items,
    );
    // エディタ用ローカルステート
    const [shouldAlert, setShouldAlert] = useState(false);
    const [isRichTextMode, setIsRichTextMode] = useState(false);
    const [pickerPos, setPickerPos] = useState({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    });

    // Lexicalの状態監視用
    const {
      isLink,
      isBold,
      isItalic,
      isUnderline,
      isStrikethrough,
      isCode,
      isQuote,
      isMarker,
      toggleLinkMode,
      formatQuote,
      formatMarker,
    } = useLexicalMonitor(editor);

    const closeEmojiPicker = useCallback(() => {
      dispatch(closeItemCommentEmojiPopup());
    }, []);

    const openEmojiPicker = useCallback(() => {
      dispatch(openItemCommentEmojiPopup());
    }, []);

    /**
     * 絵文字の挿入処理
     */
    const onEmojiSelect = useCallback((emoji: any) => {
      editor.focus();
      editor.update(() => {
        const selection = $getSelection();
        selection?.insertRawText(emoji.native);
      });
      closeEmojiPicker();
    }, []);

    /**
     * 絵文字ピッカーの表示/非表示トグル
     */
    const toggleEmojiPicker = () => {
      const TOP_OFFSET = -435;
      editor.update(() => {
        const pos = { top: 0, right: 0, bottom: 0, left: 0 };
        const selection = $getSelection() as RangeSelection;
        if (selection && selection?.focus?.key) {
          const el = editor.getElementByKey(selection.focus.key);
          if (el) {
            const coords = el.getBoundingClientRect();
            // 該当ノードの親の親=エディターの入力エリア 取れてこない場合は考慮してない
            const pElement = el.parentNode?.parentNode! as HTMLDivElement;
            const pCoords = pElement.getBoundingClientRect();

            // emoji-pickerの出現座標設定
            if (coords) {
              const { left, top } = coords;
              pos.left = left - pCoords.left;
              pos.top = top - pCoords.top + TOP_OFFSET;
            }
          }
        }
        // topは固定
        pos.top = TOP_OFFSET;
        setPickerPos(pos);
        (isCommentEmojiOpen ? closeEmojiPicker : openEmojiPicker)();
      });
    };

    /**
     * 保存処理
     */
    const save = useCallback(() => {
      if (!canSend) return;
      const editorState = editor.getEditorState();
      const jsonState = JSON.stringify(editorState.toJSON());

      editorState.read(() => {
        const root = $getRoot();
        const textState = root.getTextContent();
        onSend(jsonState, textState, shouldAlert);
      });
    }, [canSend, shouldAlert, onSend]);

    /**
     * 添付ファイル選択
     * ※ fileInputRefのクリックイベント発火のみ
     *    ファイル読み込み時処理は`fileLoadHandler`に続く
     */
    const selectLocalFile = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    /**
     * ファイル読み込み時ハンドラ
     * @param e
     */
    const fileLoadHandler = (e: EV<HTMLInputElement>) => {
      const { files } = e.target;
      if (files) {
        onSelectFiles(Array.from(files));
        e.target.value = ''; // inputの値は不要なのでクリア
      }
    };

    const handleToggleRichMode = useCallback(() => {
      onToggleRichMode(!isRichTextMode);
      setIsRichTextMode((old) => !old);
    }, [isRichTextMode]);

    const iconCommonClass = 'text-gray-400 w-4 h-4';

    /** 親コンポーネントから送信処理を実行 */
    useImperativeHandle(ref, () => ({
      send() {
        save();
      },
      setShouldAlert(st: boolean) {
        setShouldAlert(st);
      },
      uploadFiles(files: File[]) {
        onSelectFiles(Array.from(files));
      },
    }));

    /** i18nの生成とTooltipの都合上下記のような処理にしている */
    const sendComment = useMemo(() => {
      const baseMsg = isIos
        ? t('コメントを投稿__br__cmd + Enter')
        : t('コメントを投稿__br__Ctrl + Enter');
      return canSend ? baseMsg.replace('__br__', '<br />') : '';
    }, [canSend, isIos]);

    /** 送信ボタンの下部に表示する文言 */
    const commentSubmitMemo = useMemo(() => {
      const baseMsg = isIos ? 'cmd + Enter' : 'Ctrl + Enter';
      return `${baseMsg} で 投稿`;
    }, [isIos]);

    return (
      <div ref={toolbarRef} className="flex-col">
        {/* ツールバー上部 */}
        {/* ※ 要素自体を取り除くとツールチップが表示されなくなる ので`hidden`で対応 */}
        <div
          className={classNames(
            isRichTextMode ? '' : 'hidden',
            'flex p-2 mr-auto space-x-1',
          )}
        >
          {/* ボールド */}
          <IconBtn
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
            tooltipMessage={t('太字')}
            active={isBold}
          >
            <BoldOutlineIcon className={iconCommonClass} />
          </IconBtn>

          {/* イタリック */}
          <IconBtn
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
            }
            tooltipMessage={t('斜体')}
            active={isItalic}
          >
            <ItalicOutlineIcon className={iconCommonClass} />
          </IconBtn>

          {/* アンダーライン */}
          <IconBtn
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
            }
            tooltipMessage={t('下線')}
            active={isUnderline}
          >
            <UnderlineOutlineIcon className={iconCommonClass} />
          </IconBtn>

          {/* 打消し */}
          <IconBtn
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
            }
            tooltipMessage={t('打消し線')}
            active={isStrikethrough}
          >
            <StrikethroughOutlineIcon className={iconCommonClass} />
          </IconBtn>

          <Divider className="mx-2 h-7" />

          {/* 引用 他に引用らしいアイコンが見つからない */}
          <IconBtn
            onClick={formatQuote}
            tooltipMessage={t('引用')}
            active={isQuote}
          >
            <CommandLineIcon className={iconCommonClass} />
          </IconBtn>

          {/* コード */}
          <IconBtn
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')}
            tooltipMessage={t('コード')}
            active={isCode}
          >
            <CodeBracketIcon className={iconCommonClass} />
          </IconBtn>

          {/* マーカー */}
          <IconBtn
            onClick={formatMarker}
            tooltipMessage={t('マーカー')}
            active={isMarker}
          >
            <MarkerOutlineIcon className={iconCommonClass} />
          </IconBtn>
        </div>

        {/* エディターコンテンツ */}
        {children}

        {/* ツールバー下部 */}
        <div className="flex items-center px-2 pb-2">
          <div className="mr-auto space-x-1">
            {/* 書式 */}
            <IconBtn onClick={handleToggleRichMode} tooltipMessage={t('書式')}>
              <FormatOutlineIcon className={iconCommonClass} />
            </IconBtn>

            {/* ファイル */}
            <IconBtn
              disabled={disabledFileUpload}
              onClick={selectLocalFile}
              tooltipMessage={
                disabledFileUpload
                  ? t('これ以上ファイルを追加できません')
                  : t('ファイル添付')
              }
            >
              <FolderPlusIcon className={iconCommonClass} />
            </IconBtn>

            {/* リンク */}
            <IconBtn
              onClick={toggleLinkMode}
              tooltipMessage={t('リンク')}
              active={isLink}
            >
              <LinkIcon className={iconCommonClass} />
            </IconBtn>

            {/* 絵文字 */}
            <IconBtn onClick={toggleEmojiPicker} tooltipMessage={t('絵文字')}>
              <FaceSmileIcon className={iconCommonClass} />
            </IconBtn>
          </div>
          {/* ショートカット */}
          <div className="hidden sm:flex sm:items-center sm:justify-end sm:pl-3 sm:text-gray-400 sm:grow sm:text-xxs sm:dark:text-gray-600">
            {commentSubmitMemo}
          </div>
          <div className="flex items-center">
            {isEditMode && (
              <div className="ml-2">
                <MenuButton
                  id="cancelEditComment"
                  type="text"
                  className="w-auto !px-3 !py-1"
                  onClick={onCancel}
                >
                  {t('キャンセル')}
                </MenuButton>
              </div>
            )}

            <div className="ml-2" data-tip={sendComment}>
              <MenuButton
                id="applyEditComment"
                type="text"
                variant="primary"
                className="w-auto !px-3 !py-1"
                disabled={!canSend}
                onClick={save}
              >
                {isEditMode ? t('更新') : t('投稿')}
              </MenuButton>
            </div>
          </div>
        </div>

        {/* ファイル読み込み処理時利用する<input> */}
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={fileLoadHandler}
        />

        {/* ツールバー触れなくなるので 固定でエディタの上に出した方が良いかも */}
        {isCommentEmojiOpen ? (
          <EmojiPicker
            onEmojiSelect={onEmojiSelect}
            coords={pickerPos}
            onClickOutside={() => {
              closeEmojiPicker();
            }}
          />
        ) : (
          <div />
        )}
      </div>
    );
  },
);
export default CommentToolbar;
