import { OriginalColorType } from '@/@types/common';
import {
  IPropertyOptionType,
  IPropertyType,
  IPropertyFormatType,
  Property,
  RootState,
} from '@/@types/models';
import { IProperty } from '@/@types/viewItem';
import { Transition } from '@headlessui/react';
import {
  EyeSlashIcon,
  EyeIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import {
  ExclamationTriangleIcon,
  Bars2Icon,
  PlusIcon,
} from '@heroicons/react/24/solid';

import { zodResolver } from '@hookform/resolvers/zod';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RelativePortal from 'react-relative-portal';
import { z } from 'zod';

import { PROPERTY_TYPE, VALIDATION_MODE } from '@/libs/const';

import { revMatch } from '@/libs/utils';

import Alert from '@/components/Common/Alert';
import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import Toggle, { ToggleValue } from '@/components/Common/Forms/Toggle';

import ItemPropertyNameInput from '@/components/Items/ItemPropertyNameInput';

import PropertyAddDialog from '@/components/Items/PropertyAddDialog';

import PropertyEditDialog from '@/components/Items/PropertyEditDialog';

import useEditItem from '@/hooks/useEditItem';
import useOrderedStatuses from '@/hooks/useOrderedStatuses';
import useProjects from '@/hooks/useProjects';

import useProperties from '@/hooks/useProperties';
import usePropertyOrders from '@/hooks/usePropertyOrders';
import useStatuses from '@/hooks/useStatuses';
import useStatusOrders from '@/hooks/useStatusOrders';
import useViews from '@/hooks/useViews';

export interface IPropertySettingDialogProps {
  onClose: () => void;
}

// 分割した配列用の型
interface VIProperty {
  docId: IProperty['docId'];
  propertyName: IProperty['propertyName'];
  propertyType: IPropertyType;
  stringValue: IProperty['stringValue'];
  arrayValue: IProperty['arrayValue'];
  booleanValue: IProperty['booleanValue'];
  numberValue: IProperty['numberValue'];
  isShare: IProperty['isShare'];
  options: IPropertyOptionType[];
  format: IPropertyFormatType | null;
  oIdx: number; // ローカルプロパティの配列のidx
  editOption: IProperty['editOption'];
  isView: boolean;
}
// ローカルプロパティ
type LIProperty = Omit<VIProperty, 'oIdx'>;

/**
 * プロパティ設定
 * @param props
 * @returns
 */
export default function PropertySettingDialog(
  props: IPropertySettingDialogProps,
) {
  const { t } = useTranslation();
  const { onClose } = props;
  const { properties: currentProperties } = useProperties();
  const { currentView, updatePropertyOrderList } = useViews();
  const { currentMyProject } = useProjects();
  const { deleteProperty, updateProperty, createProperty, generatePropertyId } =
    useProperties();
  const { propertyOrder, updatePropertyOrders } = usePropertyOrders();
  const { statuses } = useStatuses();
  const { statusOrder } = useStatusOrders();
  const orderedStatuses = useOrderedStatuses(statuses, statusOrder);
  const { addingStatus } = useSelector(
    (state: RootState) => state.newItemDialogState,
  );
  const { updateStatuses } = useEditItem();

  // ローカルステート
  const [openPropAddModal, setOpenPropAddModal] = useState(false);
  const [openPropEditModal, setOpenPropEditModal] = useState(false);
  const [localProperties, setLocalProperties] = useState<LIProperty[]>([]);
  const [deleteProperties, setDeleteProperties] = useState<
    IProperty['docId'][]
  >([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [editTarget, setEditTarget] = useState<{
    idx: number;
    property: IProperty;
  } | null>(null);
  const [viewPropertyOrders, setViewPropertyOrders] = useState<
    IProperty['docId'][]
  >([]);

  const disabledPropertyOrders = propertyOrder?.orderList || [];

  /**
   * Zod + React Hook Form
   */
  const schema = z.object({
    displayInView: z.array(
      z.object({
        isView: z.boolean(),
        propertyName: z.string(),
        options: z.array(
          z.object({
            id: z.string(),
            text: z.string(),
            color: z.string(),
          }),
        ),
      }),
    ),
  });
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  const { append, remove, move, replace } = useFieldArray({
    control: methods.control,
    name: 'displayInView',
  });
  // 現在のプロジェクトで設定されているプロパティ
  useEffect(() => {
    const initialViewOrders = currentView?.propertyOrderList || [];
    const iProperties: IProperty[] = currentProperties.map<IProperty>((d) => {
      const property = {
        docId: d.id as string,
        propertyName: d.propertyName as string,
        propertyType: d.propertyType as IPropertyType,
        options: [],
        format: null,
        stringValue: '', // valueは使わないため空
        arrayValue: [],
        booleanValue: false,
        numberValue: null,
        editOption: {
          name: true,
          content:
            d.propertyType === PROPERTY_TYPE.SINGLE_SELECT ||
            d.propertyType === PROPERTY_TYPE.MULTI_SELECT,
          delete: true,
        },
        isShare: d.isShare as boolean,
      };
      // 種別により変更可否のオプションを更新する
      // ※ プロパティパターンマッチ
      return revMatch<Property, Property, IProperty>(d)({
        text: () => property,
        file: () => property,
        singleSelect: (p) => ({
          ...property,
          options: p.options,
        }),
        multiSelect: (p) => ({
          ...property,
          options: p.options,
        }),
        checkbox: () => property,
        incharge: () => property,
        date: () => property,
        number: (p) => ({
          ...property,
          format: p.format,
        }),
      });
    });

    const properties = iProperties.map((i) => ({
      ...i,
      isView: initialViewOrders.find((o) => o === i.docId) !== undefined,
    }));
    const currentStatus: LIProperty = {
      docId: 'system__status', // TODO: システム固定プロパティのIDをどうするか
      stringValue: addingStatus || orderedStatuses[0]?.id || '', // デフォルトは0番目のステータス
      arrayValue: [],
      numberValue: null,
      booleanValue: false,
      propertyName: t('ステータス'),
      propertyType: PROPERTY_TYPE.STATUS,
      options: orderedStatuses.map((st) => ({
        id: st.id as string,
        text: st.statusName as string,
        color: st.statusColor as OriginalColorType,
        icon: st.icon as string,
      })),
      format: null,
      isShare: true,
      editOption: {
        name: false,
        content: true,
        delete: false,
      },
      isView: true,
    };
    setLocalProperties([currentStatus, ...properties]);
    // viewの並び順をセット(isShare=true)
    setViewPropertyOrders(initialViewOrders);

    // 最初からすべて表示/非表示の場合は、ボタンを初期状態を制御しておく
    const isAllDisabled = properties.every((value) => value.isView === false);
    let initialIsAllChecked = false;
    if (isAllDisabled) {
      initialIsAllChecked = true;
    }
    setIsAllChecked(initialIsAllChecked);

    methods.reset({
      displayInView: [currentStatus, ...properties],
      isAllChecked: initialIsAllChecked,
      viewPropertyOrders: initialViewOrders,
    });
  }, []);

  // ビューに表示するプロパティの配列
  const viewProperties = useMemo(() => {
    const tmpArray = localProperties.map<VIProperty>((p, index) => ({
      ...p,
      oIdx: index,
    }));
    const viewArray = tmpArray.filter((p) => p.isView === true);
    const ordersArray = viewPropertyOrders.reduce<VIProperty[]>((acc, po) => {
      const found = viewArray.find((v) => v.docId === po);
      if (!found) return acc;

      acc.push(found);

      return acc;
    }, []);

    let newArray: VIProperty[] = [];
    let systemArray: VIProperty[] = [];
    if (ordersArray.length !== viewArray.length) {
      // ordersの指定がないプロパティは追加順
      newArray = viewArray.filter(
        (v) =>
          !viewPropertyOrders.includes(v.docId) &&
          !v.docId.startsWith('system'),
      );
      // status(システムプロパティ)は先頭
      systemArray = viewArray.filter(
        (v) => v.propertyType === PROPERTY_TYPE.STATUS,
      );
    }
    return [...systemArray, ...ordersArray, ...newArray];
  }, [viewPropertyOrders, localProperties, isAllChecked]);

  // ビューに表示しないプロパティの配列
  const disabledProperties = useMemo(() => {
    const tmpArray = localProperties.map<VIProperty>((p, index) => ({
      ...p,
      oIdx: index,
    }));
    const disabledArray = tmpArray.filter((p) => p.isView === false);
    if (disabledPropertyOrders) {
      const ordersArray = disabledPropertyOrders.reduce<VIProperty[]>(
        (acc, po) => {
          const found = disabledArray.find((v) => v.docId === po);
          if (!found) return acc;

          acc.push(found);

          return acc;
        },
        [],
      );
      let newArray: VIProperty[] = [];
      if (ordersArray.length !== disabledArray.length) {
        // ordersの指定がないプロパティは追加順
        newArray = disabledArray.filter(
          (v) => !disabledPropertyOrders.includes(v.docId),
        );
      }
      return [...ordersArray, ...newArray];
    }
    return [...disabledArray];
  }, [localProperties, isAllChecked]);

  // 更新するプロパティ(システム以外)
  const updateViewProperty = useMemo(
    () => viewProperties.filter((p) => !p.docId.startsWith('system')),
    [viewProperties],
  );

  // すべて表示済か
  const currentIsAllChecked = useMemo(() => {
    const property = localProperties.filter(
      (p) => !p.docId.startsWith('system'),
    );
    return property.every((value) => value.isView === true);
  }, [localProperties, isAllChecked, viewProperties, disabledProperties]);

  // すべて非表示か
  const currentIsAllDisabled = useMemo(() => {
    const property = localProperties.filter(
      (p) => !p.docId.startsWith('system'),
    );
    return property.every((value) => value.isView === false);
  }, [localProperties, isAllChecked, viewProperties, disabledProperties]);

  // プロパティタイプのラベル取得
  const getPropertyLabel = React.useCallback((propertyType) => {
    switch (propertyType) {
      case PROPERTY_TYPE.TEXT:
        return t('テキスト');
      case PROPERTY_TYPE.SINGLE_SELECT:
        return t('シングルセレクト');
      case PROPERTY_TYPE.MULTI_SELECT:
        return t('マルチセレクト');
      case PROPERTY_TYPE.FILE:
        return t('ファイル');
      case PROPERTY_TYPE.DATE:
        return t('日付');
      case PROPERTY_TYPE.CHECKBOX:
        return t('チェックボックス');
      case PROPERTY_TYPE.NUMBER:
        return t('数値');
      case PROPERTY_TYPE.INCHARGE:
        return t('担当者');
      default:
        return t('システム');
    }
  }, []);

  const formReset = () => {
    methods.reset({});
  };
  const formSubmit = methods.handleSubmit(async (data) => {
    const updateStatus = localProperties.filter(
      (p) => p.propertyType === PROPERTY_TYPE.STATUS,
    );

    // ステータス更新時にiconデータを追加
    const optionsWithIcons = updateStatus[0].options.map((s) => {
      const result = s;
      const org = orderedStatuses.find((x) => x.id === s.id);
      result.icon = org?.icon ?? '';
      return result;
    });
    updateStatus[0].options = optionsWithIcons;

    updateStatus.map((u) => updateStatuses(u));
    // orders更新用に生成したdocIdを保持
    const convertDocIds: { [tmpDocId: string]: string } = {};
    const updateProperties = localProperties.filter(
      (p) => p.propertyType !== PROPERTY_TYPE.STATUS,
    );

    // プロパティ設定の更新
    const apis = updateProperties.map((p) => {
      // ※ プロパティパターンマッチ
      const property = revMatch<IProperty, Property>(p)({
        text: (d) => ({
          propertyType: d.propertyType,
          propertyName: d.propertyName,
          isShare: d.isShare,
        }),
        file: (d) => ({
          propertyType: d.propertyType,
          propertyName: d.propertyName,
          isShare: d.isShare,
        }),
        singleSelect: (d) => ({
          propertyType: d.propertyType,
          propertyName: d.propertyName,
          isShare: d.isShare,
          options: d.options,
        }),
        multiSelect: (d) => ({
          propertyType: d.propertyType,
          propertyName: d.propertyName,
          isShare: d.isShare,
          options: d.options,
        }),
        checkbox: (d) => ({
          propertyType: d.propertyType,
          propertyName: d.propertyName,
          isShare: d.isShare,
        }),
        incharge: (d) => ({
          propertyType: d.propertyType,
          propertyName: d.propertyName,
          isShare: d.isShare,
        }),
        date: (d) => ({
          propertyType: d.propertyType,
          propertyName: d.propertyName,
          isShare: d.isShare,
        }),
        number: (d) => ({
          propertyType: d.propertyType,
          propertyName: d.propertyName,
          isShare: d.isShare,
          format: d.format,
        }),
      });
      // 新規作成の場合
      if (p.docId.startsWith('__')) {
        const id = generatePropertyId();
        convertDocIds[p.docId] = id;
        return createProperty(property, id);
      }
      const id = p.docId;
      convertDocIds[p.docId] = id;
      return updateProperty(property, id);
    });

    await Promise.all(apis);

    // viewに表示するプロパティordersの更新
    if (currentView?.id && currentMyProject?.id) {
      const updateViewsOrders: IProperty['docId'][] = updateViewProperty.map(
        (v) => convertDocIds[v.docId],
      );
      await updatePropertyOrderList(
        currentView.id,
        currentMyProject.id,
        updateViewsOrders,
      );
    }

    // アイテム詳細に表示するプロパティordersの更新 新規追加されたプロパティを追加する
    const newPropertyIds: IProperty['docId'][] = [];
    Object.keys(convertDocIds).forEach((key) => {
      if (key.startsWith('__')) {
        newPropertyIds.push(convertDocIds[key]);
      }
    });

    const detailPropertyIds = [...disabledPropertyOrders, ...newPropertyIds];
    await updatePropertyOrders(Object.values(detailPropertyIds));

    // プロパティ削除
    await Promise.all([
      deleteProperties.forEach((docId) => deleteProperty(docId)),
    ]);

    methods.reset({ data });
    // Reset form state
    formReset();
    onClose();
  });
  const errorMessage = React.useMemo(() => {
    // refineでバリデーションするとエラーの型に表示されないためいったんanyにする
    const error = methods.formState.errors.displayInView as any;
    return error ? error.message : undefined;
  }, [methods.formState.errors]);

  // モーダルクローズ時ハンドラ
  const handleClose = () => {
    // Reset form state
    formReset();
    onClose();
  };

  // トグル切り替え時ハンドラ
  const onToggleChange = (cv: ToggleValue) => {
    setLocalProperties((old) =>
      old.map((p, idx) => (idx === cv.idx ? { ...p, isView: cv.value } : p)),
    );
  };

  // ドロップ時ハンドラ
  const handleDragEnd = (result: DropResult) => {
    const { draggableId, source, destination } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    // TODO: システムプロパティの位置は変えられないようにする 完全固定にするべきかも
    if (destination.index === 0) return;
    const dragColumn = localProperties.find((p) => p.docId === draggableId);
    // ドロップした場所に、ドロップした要素を追加する
    if (!dragColumn) return;
    const newOrders = viewProperties.map((p) => p.docId);

    newOrders.splice(source.index, 1);
    newOrders.splice(destination.index, 0, dragColumn.docId);
    setViewPropertyOrders([...newOrders]);
    move(destination.index, source.index);
  };

  // すべて表示/非表示切り替え時ハンドラ
  const handleSwitchAllChecked = (updateChecked: boolean) => {
    // const newValue = !isAllChecked;
    setIsAllChecked(updateChecked);

    setLocalProperties((old) =>
      old.map((pr) =>
        !pr.docId.startsWith('system')
          ? {
              ...pr,
              isView: updateChecked,
            }
          : pr,
      ),
    );
    replace(
      localProperties.map((pr) =>
        !pr.docId.startsWith('system')
          ? {
              ...pr,
              isView: updateChecked,
            }
          : pr,
      ),
    );
  };

  // プロパティ追加
  const onAddProperty = (property: IProperty) => {
    const newData: VIProperty = {
      docId: property.docId as string,
      propertyName: property.propertyName as string,
      propertyType: property.propertyType as IPropertyType,
      options: property.options as IPropertyOptionType[],
      format: property.format as IPropertyFormatType | null,
      stringValue: '', // valueは使わないため空
      arrayValue: [],
      numberValue: null,
      booleanValue: false,
      editOption: property.editOption,
      isShare: property.isShare,
      oIdx: localProperties.length,
      isView: true as boolean,
    };
    append(newData);
    setLocalProperties([...localProperties, newData]);
    setViewPropertyOrders([...viewPropertyOrders, newData.docId]);
  };

  // プロパティ削除
  const handlePropertyDelete = (idx: number) => {
    setLocalProperties(localProperties.filter((p, pIdx) => pIdx !== idx));
    setDeleteProperties([...deleteProperties, localProperties[idx].docId]);
    remove(idx);
  };

  // プロパティリネーム
  const onLabelUpdate = (idx: number, value: string) => {
    methods.setValue(`displayInView[${idx}].propertyName`, value, {
      shouldDirty: true,
    });
    setLocalProperties((old) =>
      old.map((p, pIdx) => (pIdx === idx ? { ...p, propertyName: value } : p)),
    );
  };

  // プロパティ編集モーダルオープン 対象プロパティはidxで管理
  const onEditClick = (idx: number) => {
    const target = localProperties[idx];
    if (!target) return;
    setEditTarget({ idx, property: target });
    setOpenPropEditModal(true);
  };
  // プロパティ編集モーダルクローズ
  const onPropEditModalClose = () => {
    setOpenPropEditModal(false);
  };

  // プロパティ編集 update
  const onUpdateProperty = (idx: number, newProperty: IProperty) => {
    const tmpProperty: LIProperty = {
      ...newProperty,
      isView: localProperties[idx].isView,
    };
    setLocalProperties((old) =>
      old.map((p, pIdx) => (pIdx === idx ? tmpProperty : p)),
    );
    methods.setValue(`displayInView[${idx}]`, newProperty, {
      shouldDirty: true,
    });
  };

  // プロパティ編集
  const onEditProperty = useCallback(
    (newProperty: IProperty) => {
      if (editTarget === null) return;
      onUpdateProperty(editTarget.idx, newProperty);
      setEditTarget(null);
    },
    [editTarget],
  );
  const render = useMemo(
    () => (
      <>
        <div className="-mb-4 grid grid-cols-12 gap-4">
          <div className="text-gray-500 dark:text-gray-400 col-start-1 col-span-3">
            <small>{t('ビューに表示')}</small>
          </div>
          <div className="text-gray-500 dark:text-gray-400 col-start-10 col-span-3">
            <MenuButton
              id="hideAllProperties"
              type="text"
              className="flex items-center px-0 py-0"
              onClick={() => handleSwitchAllChecked(false)}
              disabled={currentIsAllDisabled}
            >
              <EyeSlashIcon className="w-3 h-3 mt-0.5" aria-hidden="true" />
              <div className="ml-1">
                <small>{t('すべて非表示')}</small>
              </div>
            </MenuButton>
          </div>
        </div>
        <div className="overflow-auto max-h-80">
          {/* ビューに表示するプロパティのみ並び替え */}
          {viewPropertyOrders && (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="property-setting">
                {(providedDroppable) => (
                  <div
                    ref={providedDroppable.innerRef}
                    {...providedDroppable.droppableProps}
                  >
                    {viewProperties.map((property, idx) => (
                      <Draggable
                        key={`property-${property.docId}`}
                        draggableId={property.docId as string}
                        index={idx}
                        isDragDisabled={property.docId.startsWith('system')}
                        disableInteractiveElementBlocking={property.docId.startsWith(
                          'system',
                        )}
                      >
                        {(providedDraggable, snapshot) => {
                          const row = (
                            <div
                              className="items-center h-10 grid grid-cols-12 gap-4"
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                            >
                              {!property.docId.startsWith('system') ? (
                                <div className="col-span-1">
                                  <Bars2Icon className="w-4 h-4 mr-1 text-gray-300 dark:text-gray-600" />
                                </div>
                              ) : (
                                <div className="col-span-1">
                                  <LockClosedIcon className="w-4 h-4 mr-1 text-gray-300 dark:text-gray-600" />
                                </div>
                              )}

                              <div className="col-span-5">
                                <ItemPropertyNameInput
                                  idx={property.oIdx}
                                  property={property}
                                  onDelete={() =>
                                    handlePropertyDelete(property.oIdx)
                                  }
                                  onEditClick={onEditClick}
                                  onUpdate={onLabelUpdate}
                                  textBreak="truncate"
                                  isEdit
                                />
                              </div>
                              <div className="text-xs text-gray-500 dark:text-gray-400 col-span-3">
                                {/* select型の場合 */}
                                {getPropertyLabel(property.propertyType)}
                                {/* プロパティ編集 */}
                              </div>
                              <div className="col-span-3">
                                {!property.docId.startsWith('system') && (
                                  <Toggle
                                    onChange={onToggleChange}
                                    center
                                    key={property.docId}
                                    name="displayInView"
                                    idx={property.oIdx}
                                    property="isView"
                                    defaultValue={property.isView}
                                  />
                                )}
                              </div>
                            </div>
                          );
                          if (!snapshot.isDragging) {
                            return row;
                          }
                          // ドラッグ中のみポータルで表示する（表示位置がおかしくなることへの対策）
                          return (
                            <RelativePortal component="div">
                              {row}
                            </RelativePortal>
                          );
                        }}
                      </Draggable>
                    ))}
                    {providedDroppable.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
        <>
          <div className="-mb-4 grid grid-cols-12 gap-4">
            <div className="text-gray-500 dark:text-gray-400 col-start-1 col-span-4">
              <small>{t('ビューに非表示')}</small>
            </div>
            <div className="text-gray-500 dark:text-gray-400 col-start-10 col-span-3">
              <MenuButton
                id="showAllProperties"
                type="text"
                className="flex items-center px-0 py-0"
                onClick={() => handleSwitchAllChecked(true)}
                disabled={currentIsAllChecked}
              >
                <EyeIcon className="w-3 h-3 mt-0.5" aria-hidden="true" />
                <div className="ml-1">
                  <small>{t('すべて表示')}</small>
                </div>
              </MenuButton>
            </div>
          </div>

          <div className="overflow-auto max-h-80">
            {disabledProperties.map((property) => (
              <div
                key={`property-${property.docId}`}
                className="items-center h-10 grid grid-cols-12 gap-4"
              >
                <div className="col-span-1" />
                <div className="col-span-5">
                  <ItemPropertyNameInput
                    idx={property.oIdx}
                    property={property}
                    onDelete={() => handlePropertyDelete(property.oIdx)}
                    onUpdate={onLabelUpdate}
                    onEditClick={onEditClick}
                    textBreak="truncate"
                    isEdit
                  />
                </div>
                <div className="text-xs text-gray-500 dark:text-gray-400 col-span-3">
                  {/* select型の場合 */}
                  {getPropertyLabel(property.propertyType)}
                </div>
                <div className="col-span-3">
                  <Toggle
                    onChange={onToggleChange}
                    center
                    key={property.docId}
                    name="displayInView"
                    idx={property.oIdx}
                    property="isView"
                    defaultValue={property.isView}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      </>
    ),
    [
      viewPropertyOrders,
      localProperties,
      viewProperties,
      disabledProperties,
      isAllChecked,
    ],
  );

  return (
    <FormDialog
      onClose={onClose}
      title={t('プロパティ設定')}
      onSubmit={formSubmit}
      top
    >
      <FormProvider {...methods}>
        {errorMessage && (
          <Alert color="red" icon={ExclamationTriangleIcon}>
            <h3 className="font-medium">{errorMessage}</h3>
          </Alert>
        )}
        {render}
        <FormButton id="addProperty" onClick={() => setOpenPropAddModal(true)}>
          <PlusIcon
            className="inline-block w-4 h-4 my-auto"
            aria-hidden="true"
          />
          <div className="inline-block ml-2 align-text-top">
            {t('プロパティを追加')}
          </div>
        </FormButton>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="applyPropertySetting"
          disabled={!methods.formState.isValid || !methods.formState.isDirty}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('更新')}
        </FormButton>
        <FormButton
          id="cancelPropertySetting"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={handleClose}
        >
          {t('キャンセル')}
        </FormButton>
        {/* プロパティ追加ダイアログ */}
        <Transition.Root show={openPropAddModal} unmount>
          <PropertyAddDialog
            onClose={() => setOpenPropAddModal(false)}
            zIndex={20}
            onAddProperty={onAddProperty}
          />
        </Transition.Root>
        {/* プロパティ編集ダイアログ */}
        <Transition.Root show={openPropEditModal} unmount>
          {editTarget && (
            <PropertyEditDialog
              zIndex={30}
              property={editTarget.property}
              onClose={onPropEditModalClose}
              onEditProperty={onEditProperty}
            />
          )}
        </Transition.Root>
      </FormProvider>
    </FormDialog>
  );
}
