import { RootState } from '@/@types/models';
import {
  CodeBracketIcon,
  LinkIcon,
  CommandLineIcon,
} from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useRef } from 'react';
import * as React from 'react';
import { createPortal } from 'react-dom';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import IconBtn from '@/components/Common/Icon/IconBtn';
import {
  BoldOutlineIcon,
  ItalicOutlineIcon,
  MarkerOutlineIcon,
  StrikethroughOutlineIcon,
  UnderlineOutlineIcon,
} from '@/components/Common/Icon/Icons';

import useLexicalMonitor from '@/components/Common/Lexical/utils/useLexicalMonitor';

const setToolbarPosition = (
  editor: HTMLElement,
  rect: DOMRect,
  rootElementRect: DOMRect,
) => {
  const showTopPos = rect.top - 50 + window.pageYOffset; // 上に表示する場合の値
  let left = rect.right - editor.offsetWidth / 2;
  if (document.body.clientWidth <= left + editor.offsetWidth) {
    left = -1;
  }
  if (left + editor.offsetWidth > rootElementRect.right) {
    left = rect.right - editor.offsetWidth;
  }
  if (left < 0) {
    left = rect.left;
  }
  if (rect.width >= rootElementRect.width - 25) {
    left = rect.left;
  }
  const top = showTopPos;
  // eslint-disable-next-line no-param-reassign
  editor.style.display = 'inline-block';
  // eslint-disable-next-line no-param-reassign
  editor.style.opacity = '1';
  // eslint-disable-next-line no-param-reassign
  editor.style.top = `${top}px`;
  // eslint-disable-next-line no-param-reassign
  editor.style.left = `${left}px`;
};
const useFloatingMenuEditor = (
  editor: LexicalEditor,
  show: boolean,
): JSX.Element | null => {
  const {
    isText,
    isLink,
    isBold,
    isItalic,
    isUnderline,
    isStrikethrough,
    isCode,
    isQuote,
    isMarker,
    toggleLinkMode,
    formatQuote,
    formatMarker,
  } = useLexicalMonitor(editor);

  const { t } = useTranslation();
  const editorRef = useRef<HTMLDivElement | null>(null);

  const { isImageOpen } = useSelector((state: RootState) => state.items);

  // エディタの非表示フラグ
  const forceHide = React.useMemo(
    () => !isText || isLink || show === false || isImageOpen === true,
    [isText, isLink, show, isImageOpen],
  );

  // エディタのスタイル初期化(非表示化)
  // ※ DOM自体は取り除かないことでツールチップ表示が可能
  const hideEditor = () => {
    const element = editorRef.current;
    if (!element || !forceHide) return;
    element.style.display = 'none';
    element.style.opacity = '0';
    element.style.top = '0px';
    element.style.left = '0px';
  };

  // エディタ非表示フラグを監視
  useEffect(() => {
    hideEditor();
  }, [forceHide]);

  const updateEditor = useCallback(() => {
    const selection = $getSelection();

    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();

    if (editorElem === null) return;

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      const rootElementRect = rootElement.getBoundingClientRect();
      let rect: DOMRect | null = null;

      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild as HTMLElement;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (forceHide) {
        hideEditor();
        return;
      }
      setToolbarPosition(editorElem, rect, rootElementRect);
    }
  }, [editor, forceHide]);

  // 画面リサイズ発火用のイベントを登録
  useEffect(() => {
    const onResize = () => {
      editor.getEditorState().read(() => {
        updateEditor();
      });
    };
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [editor, updateEditor]);

  //
  useEffect(() => {
    editor.getEditorState().read(() => {
      updateEditor();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateEditor();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, updateEditor]);

  const iconCommonClass = 'text-gray-400 w-4 h-4';

  return createPortal(
    <div ref={editorRef} className="lexical-float-editor">
      {/* ボールド */}
      <IconBtn
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
        tooltipMessage={t('太字')}
        active={isBold}
      >
        <BoldOutlineIcon className={iconCommonClass} />
      </IconBtn>

      {/* イタリック */}
      <IconBtn
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')}
        tooltipMessage={t('斜体')}
        active={isItalic}
      >
        <ItalicOutlineIcon className={iconCommonClass} />
      </IconBtn>

      {/* アンダーライン */}
      <IconBtn
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')}
        tooltipMessage={t('下線')}
        active={isUnderline}
      >
        <UnderlineOutlineIcon className={iconCommonClass} />
      </IconBtn>

      {/* 打消し */}
      <IconBtn
        onClick={() =>
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        }
        tooltipMessage={t('打消し線')}
        active={isStrikethrough}
      >
        <StrikethroughOutlineIcon className={iconCommonClass} />
      </IconBtn>

      {/* リンク */}
      <IconBtn
        onClick={toggleLinkMode}
        tooltipMessage={t('リンク')}
        active={isLink}
      >
        <LinkIcon className={iconCommonClass} />
      </IconBtn>

      {/* 引用 他に引用らしいアイコンが見つからない */}
      <IconBtn
        onClick={formatQuote}
        tooltipMessage={t('引用')}
        active={isQuote}
      >
        <CommandLineIcon className={iconCommonClass} />
      </IconBtn>

      {/* コード */}
      <IconBtn
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code')}
        tooltipMessage={t('コード')}
        active={isCode}
      >
        <CodeBracketIcon className={iconCommonClass} />
      </IconBtn>

      {/* マーカー */}
      <IconBtn
        onClick={formatMarker}
        tooltipMessage={t('マーカー')}
        active={isMarker}
      >
        <MarkerOutlineIcon className={iconCommonClass} />
      </IconBtn>
    </div>,
    document.body,
  );
};

interface IFloatingMenuPluginProps {
  show: boolean;
}

export default function FloatingMenuPlugin(
  props: IFloatingMenuPluginProps,
): JSX.Element | null {
  const { show } = props;
  const [editor] = useLexicalComposerContext();
  return useFloatingMenuEditor(editor, show);
}
