import React, { ReactNode } from 'react';

import { classNames } from '@/libs/styleUtils';

export interface IFormProps {
  className?: string;
  flex?: boolean;
  children: ReactNode;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

export default function Form(props: IFormProps) {
  const { className, flex, children, onSubmit } = props;

  return (
    <form onSubmit={onSubmit} className={classNames(className)}>
      <div
        className={classNames(
          flex ? 'flex' : 'grid grid-cols-1 sm:grid-cols-6',
          'gap-y-4 gap-x-4',
        )}
      >
        {children}
      </div>
    </form>
  );
}
