import { Property } from '@/@types/models';
import React, { useCallback, useContext, useMemo } from 'react';

import { Resizable } from 'react-resizable';

import {
  DEFAULT_LIST_VIEW_COLUMN_WIDTH,
  MIN_LIST_VIEW_COLUMN_WIDTH,
} from '@/libs/const';
import { classNames } from '@/libs/styleUtils';

import EllipsisText from '@/components/Common/EllipsisText';
import {
  columnWidthContext,
  setColumnWidthContext,
} from '@/components/View/List/ColumnWidthContext';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useViews from '@/hooks/useViews';

export interface IResizableThProps {
  property?: Property;
  className?: string;
  sizingKey?: string;
  label?: string;
  defaultWidth?: number;
  minWidth?: number;
}

/**
 * リサイズできるヘッダカラム
 * @param props
 * @returns
 */
export default function ResizableTh(props: IResizableThProps) {
  const { minWidth, defaultWidth, label, sizingKey, property, className } =
    props;
  const target = React.useRef(null);
  const { isMember } = useMyWorkspaces();
  const columnWidth = useContext(columnWidthContext);
  const setColumnWidth = useContext(setColumnWidthContext);
  const { currentMyProject } = useProjects();
  const { currentView, updateColumnWidth } = useViews();

  const propertyId = useMemo(
    () => sizingKey || (property?.id as string),
    [sizingKey, property],
  );

  const resizeHandle = useCallback(
    (event, { size }) => {
      setColumnWidth(propertyId, size.width);
    },
    [setColumnWidth, propertyId],
  );

  const colWidth = useMemo(
    () =>
      columnWidth[propertyId]?.columnWidth ||
      defaultWidth ||
      DEFAULT_LIST_VIEW_COLUMN_WIDTH,
    [columnWidth, propertyId, defaultWidth],
  );

  const resizeStopHandle = useCallback(
    (e, { size }) => {
      updateColumnWidth(
        currentView?.id,
        currentMyProject?.id,
        propertyId,
        size.width,
      );
    },
    [updateColumnWidth, currentView, currentMyProject, propertyId],
  );

  const thLabel = useMemo(
    () => (label as string) || property?.propertyName,
    [label, property],
  );

  const thMinWidth = useMemo(
    () => minWidth || MIN_LIST_VIEW_COLUMN_WIDTH,
    [minWidth],
  );

  return (
    <th
      scope="col"
      className={classNames('relative group', className)}
      style={{ width: colWidth, maxWidth: colWidth, minWidth: colWidth }}
      key={propertyId}
    >
      <Resizable
        onResize={resizeHandle}
        onResizeStop={resizeStopHandle}
        handle={
          isMember && (
            <div className="absolute top-0 bottom-0 right-0 w-1 bg-gray-300 opacity-0 dark:bg-gray-600 group-hover:opacity-100 cursor-ew-resize" />
          )
        }
        width={colWidth}
        height={0}
        axis="x"
        minConstraints={[thMinWidth, 0]}
      >
        <div className="h-full py-2 overflow-hidden min-h-max" ref={target}>
          <EllipsisText className="w-full px-6">{thLabel}</EllipsisText>
        </div>
      </Resizable>
    </th>
  );
}
