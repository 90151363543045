import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NUMBER_PROPERTY_FORMAT_TYPE, PROPERTY_TYPE } from '@/libs/const';

import Input from '@/components/Common/Forms/Input';
import Toggle from '@/components/Common/Forms/Toggle';
import FilterDate from '@/components/Filter/FilterDate';

import FilterMultiSelect from '@/components/Filter/FilterMultiSelect';
import FilterUser from '@/components/Filter/FilterUser';

import { ISearchFilter, ISearchProperty } from '@/hooks/useFilters';

export interface IFilterDetailListProps {
  filterProperties: ISearchProperty[];
}

export default function FilterDetailList(props: IFilterDetailListProps) {
  const { t } = useTranslation();
  const { setValue, trigger, getValues } = useFormContext<ISearchFilter>();
  const { filterProperties } = props;

  // 更新
  const handleChange = useCallback(
    (name, data) => {
      setValue(name, data.value, {
        shouldDirty: true,
        shouldTouch: true,
      });
      trigger();
    },
    [filterProperties],
  );

  const formSelector = useCallback(
    (property: ISearchProperty, idx: number) => {
      // ファイル添付
      if (property.propertyType === PROPERTY_TYPE.FILE) {
        return (
          <div className=" pt-2">
            <div className="flex flex-row items-center  w-full">
              <div className="flex flex-row items-center basis-1/2">
                <Toggle
                  idx={idx}
                  name="searchProperties"
                  property="isNotEmptyFile"
                  onChange={(val) =>
                    handleChange(`searchProperties.${idx}.isNotEmptyFile`, val)
                  }
                  defaultValue={getValues(
                    `searchProperties.${idx}.isNotEmptyFile`,
                  )}
                />
                <span className="pl-2">{t('ファイルありに限定')}</span>
              </div>
            </div>
          </div>
        );
      }
      // チェックボックス
      if (property.propertyType === PROPERTY_TYPE.CHECKBOX) {
        return (
          <div className=" pt-2">
            <div className="flex flex-row items-center w-full">
              <div className="flex flex-row items-center  basis-1/2">
                <Toggle
                  idx={idx}
                  name="searchProperties"
                  property="booleanValue"
                  onChange={(val) =>
                    handleChange(`searchProperties.${idx}.booleanValue`, val)
                  }
                  defaultValue={getValues(
                    `searchProperties.${idx}.booleanValue`,
                  )}
                />
                <span className="pl-2">{t('チェックありに限定')}</span>
              </div>
            </div>
          </div>
        );
      }
      // 担当者
      if (property.propertyType === PROPERTY_TYPE.INCHARGE) {
        return <FilterUser idx={idx} placeHolder="担当者" />;
      }
      // 日付
      if (property.propertyType === PROPERTY_TYPE.DATE) {
        return (
          <div className="flex flex-col ">
            <div className="flex flex-row gap-4">
              <FilterDate idx={idx} property="numberFrom" />
              <span className="self-center text-gray-700 dark:text-gray-400">
                ～
              </span>
              <FilterDate idx={idx} property="numberTo" />
            </div>
            <div className="flex flex-row pt-2">
              <div className="flex items-center w-full">
                <div className="flex flex-row items-center basis-1/2">
                  <Toggle
                    name={`searchProperties.${idx}.isDueDate`}
                    onChange={(val) =>
                      handleChange(`searchProperties.${idx}.isDueDate`, val)
                    }
                    defaultValue={getValues(
                      `searchProperties.${idx}.isDueDate`,
                    )}
                  />
                  <span className="pl-2">{t('期日ありに限定')}</span>
                </div>
              </div>
            </div>
          </div>
        );
      }
      // 数値
      if (property.propertyType === PROPERTY_TYPE.NUMBER) {
        if (property.format === NUMBER_PROPERTY_FORMAT_TYPE.PERCENT) {
          return (
            <div className="flex flex-row gap-4">
              <Input
                name="searchProperties"
                property="numberFrom"
                idx={idx}
                addOn={{
                  right: <div className="text-center w-11">{t('％以上')}</div>,
                }}
                number
              />
              <Input
                name="searchProperties"
                property="numberTo"
                idx={idx}
                addOn={{
                  right: <div className="text-center w-11">{t('％未満')}</div>,
                }}
                number
              />
            </div>
          );
        }
        if (property.format === NUMBER_PROPERTY_FORMAT_TYPE.YEN) {
          return (
            <div className="flex flex-row gap-4">
              <Input
                name="searchProperties"
                idx={idx}
                property="numberFrom"
                addOn={{
                  right: <div className="text-center w-11">{t('円以上')}</div>,
                }}
                number
              />
              <Input
                name="searchProperties"
                idx={idx}
                property="numberTo"
                addOn={{
                  right: <div className="text-center w-11">{t('円未満')}</div>,
                }}
                number
              />
            </div>
          );
        }
        return (
          <div className="flex flex-row gap-4">
            <Input
              name="searchProperties"
              idx={idx}
              property="numberFrom"
              addOn={{
                right: <div className="text-center w-11">{t('以上')}</div>,
              }}
              number
            />
            <Input
              name="searchProperties"
              idx={idx}
              property="numberTo"
              addOn={{
                right: <div className="text-center w-11">{t('未満')}</div>,
              }}
              number
            />
          </div>
        );
      }
      // シングルセレクト・マルチセレクト
      if (
        property.propertyType === PROPERTY_TYPE.MULTI_SELECT ||
        property.propertyType === PROPERTY_TYPE.SINGLE_SELECT
      ) {
        return <FilterMultiSelect idx={idx} />;
      }
      // テキスト
      return <Input name="searchProperties" idx={idx} property="stringValue" />;
    },
    [filterProperties],
  );

  return (
    <>
      {filterProperties.map((property, idx) => (
        <div className="min-w-full pt-6" key={property.docId}>
          <label
            htmlFor={property.propertyName}
            className="block pb-1 font-medium text-gray-700 dark:text-gray-400"
          >
            {property.propertyName}
          </label>
          {formSelector(property, idx)}
        </div>
      ))}
    </>
  );
}
