import { Transition, Dialog } from '@headlessui/react';
import React, { ReactNode, useRef } from 'react';

export interface IDrawerTransitionProps {
  show: boolean;
  children?: ReactNode;
  onClose: any;
  undefinedInitialFocus?: boolean;
}

/**
 * ドロワーベース
 * @param props
 * @returns
 */
export default function BaseDrawer(props: IDrawerTransitionProps) {
  const { show, onClose, undefinedInitialFocus, children } = props;

  // consoleエラー回避用のhidden要素
  const allCommentFocusDiv = useRef(null);

  return (
    <Transition.Root show={show} as="div">
      <Dialog
        initialFocus={undefinedInitialFocus ? allCommentFocusDiv : undefined}
        as="div"
        className="fixed inset-0 z-40 h-screen overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <Transition.Child
            as="div"
            className="fixed inset-y-0 right-0 flex max-w-full"
            enter="transform transition ease-in-out duration-500 sm:duration-400"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-400"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            {children}
            {undefinedInitialFocus && <div ref={allCommentFocusDiv} hidden />}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
