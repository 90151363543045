import React from 'react';

import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '@/components/Common/Forms/Input';

import FilterDate from '@/components/Filter/FilterDate';
import FilterUser from '@/components/Filter/FilterUser';

import { ISearchFilter } from '@/hooks/useFilters';

export interface IFilterBasicListProps {
  methods: UseFormReturn<ISearchFilter, any>;
}

export default function FilterBasicList(props: IFilterBasicListProps) {
  const { t } = useTranslation();
  const { methods } = props;
  const FORMAT_MORE = <div className="text-center w-7">{t('以上')}</div>;
  const FORMAT_UNDER = <div className="text-center w-7">{t('未満')}</div>;
  return (
    // 基本情報
    <div>
      {/* いいね */}
      <div className="min-w-full">
        <label
          htmlFor="likeCount"
          className="block pb-1 font-medium text-gray-700 dark:text-gray-400"
        >
          {t('いいね')}
        </label>
        <div className="flex flex-row  gap-4">
          <Input
            name="basicFilter.likeCountFrom"
            property="numberValue"
            addOn={{ right: FORMAT_MORE }}
            number
          />
          <Input
            name="basicFilter.likeCountTo"
            property="numberValue"
            addOn={{ right: FORMAT_UNDER }}
            number
          />
        </div>
      </div>
      {/* コメント */}
      <div className="min-w-full pt-6">
        <label
          htmlFor="comment"
          className="block pb-1 font-medium text-gray-700 dark:text-gray-400"
        >
          {t('コメント')}
        </label>
        <div className="flex flex-row  gap-4">
          <Input
            name="basicFilter.commentCountFrom"
            property="numberValue"
            addOn={{ right: FORMAT_MORE }}
            number
          />
          <Input
            name="basicFilter.commentCountTo"
            property="numberValue"
            addOn={{ right: FORMAT_UNDER }}
            number
          />
        </div>
      </div>
      {/* 作成者 */}
      <div className="min-w-full pt-6">
        <label
          htmlFor="createdBys"
          className="block pb-1 font-medium text-gray-700 dark:text-gray-400"
        >
          {' '}
          {t('作成者')}
        </label>
        <div className="">
          <FilterUser
            name="createdBys"
            methods={methods}
            placeHolder="作成者"
          />
        </div>
      </div>
      {/* 作成日 */}
      <div className="min-w-full pt-6">
        <label
          htmlFor="createDate"
          className="block pb-1 font-medium text-gray-700 dark:text-gray-400"
        >
          {' '}
          {t('作成日')}
        </label>
        <div className="flex flex-row  gap-4">
          <FilterDate name="createdAtFrom" methods={methods} />
          <span className="self-center text-gray-700 dark:text-gray-400">
            ～
          </span>
          <FilterDate methods={methods} name="createdAtTo" />
        </div>
      </div>
      {/* 更新者 */}
      <div className="min-w-full pt-6">
        <label
          htmlFor="updatedBys"
          className="block pb-1 font-medium text-gray-700 dark:text-gray-400"
        >
          {' '}
          {t('更新者')}
        </label>
        <div className="">
          <FilterUser
            name="updatedBys"
            methods={methods}
            placeHolder="更新者"
          />
        </div>
      </div>
      {/* 更新日 */}
      <div className="min-w-full pt-6">
        <label
          htmlFor="updateDate"
          className="block pb-1 font-medium text-gray-700 dark:text-gray-400"
        >
          {' '}
          {t('更新日')}
        </label>
        <div className="flex flex-row gap-4">
          <FilterDate name="updatedAtFrom" methods={methods} />
          <span className="self-center text-gray-700 dark:text-gray-400">
            ～
          </span>
          <FilterDate name="updatedAtTo" methods={methods} />
        </div>
      </div>
    </div>
  );
}
