import { MyProject, RootState } from '@/@types/models';
import { IInvitingGuestDictionary } from '@/@types/viewItem';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import {
  ReduxFirestoreQuerySetting,
  useFirestoreConnect,
} from 'react-redux-firebase';

import {
  COL_INVITING_GUESTS,
  getInvitingGuestsPath,
} from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * 招待中ゲストの集約に関するHooks
 * @returns { invitingGuests }
 */
export default function useInvitingGuests() {
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject, myProjects } = useProjects();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const invitingGuests = useSelector(
    (state: RootState) => state.firestore.ordered[COL_INVITING_GUESTS] || [],
  );

  useFirestoreConnect(() =>
    workspaceId && projectId && myProjects.length > 0
      ? [
          {
            collection: getInvitingGuestsPath(workspaceId, projectId),
            orderBy: ['invitedAt', 'asc'],
            storeAs: COL_INVITING_GUESTS,
          },
          ...myProjects.map(
            (p: MyProject) =>
              ({
                collection: getInvitingGuestsPath(workspaceId, p.id),
                orderBy: ['email', 'asc'],
                storeAs: `${COL_INVITING_GUESTS}/${p.id}`,
              } as ReduxFirestoreQuerySetting),
          ),
        ]
      : [],
  );

  const ordered = useSelector((state: any) => state.firestore.ordered);

  const myProjectsInvitingGuestsDic = useMemo(
    () =>
      myProjects.reduce<IInvitingGuestDictionary>((acc, cur) => {
        const values = ordered[`${COL_INVITING_GUESTS}/${cur.id}`];
        if (!values) return acc;
        values.forEach((v: any) => {
          const projects: string[] = acc[v.id]?.projectId || [];
          acc[v.id] = {
            ...v,
            projectId: [cur.id]
              .concat(projects)
              .filter((s): s is string => Boolean(s)),
          };
        });
        return acc;
      }, {}),
    [ordered],
  );

  return { invitingGuests, myProjectsInvitingGuestsDic };
}
