import { collection, doc, getFirestore } from 'firebase/firestore';

import { firebaseApp } from '@/firebase';

const firestore = getFirestore(firebaseApp);
/**
 * ドキュメントの自動生成IDを取得する
 * @param {string} docPath
 * @param {string[]} pathSegments
 * @returns {string} firestoreにより自動生成されるuuid
 */
const generateDocId = (docPath: string, ...pathSegments: string[]) => {
  const collectionRef = collection(firestore, docPath, ...pathSegments);
  return doc(collectionRef).id;
};

export { firestore, generateDocId };
