import { RootState, Comment, Item, RRFUploadedFile } from '@/@types/models';
import {
  ChatBubbleLeftRightIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import InfiniteScroll from 'react-infinite-scroll-component';
import Skeleton from 'react-loading-skeleton';

import { useSelector } from 'react-redux';

import ReactTooltip from 'react-tooltip';

import { DEFAULT_COMMENT_NOTIFY, SHARING_PERMISSION_TYPES } from '@/libs/const';
import { DayjsUtil } from '@/libs/dayjs';

import { classNames } from '@/libs/styleUtils';

import { parseFsTimestamp } from '@/libs/utils';

import ConfirmDialog from '@/components/Common/ConfirmDialog';
import Divider from '@/components/Common/Divider';

import { FileSource } from '@/components/Common/FileLoader';
import CommentEditor, {
  ICommentEditorHandler,
} from '@/components/Common/Lexical/CommentEditor';

import CommentItem from '@/components/Items/Comments/CommentItem';

import useCommentFiles from '@/hooks/useCommentFiles';
import useCommentReactions from '@/hooks/useCommentReactions';
import useComments from '@/hooks/useComments';

import useMentions from '@/hooks/useMentions';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';
import useTempFiles from '@/hooks/useTempFiles';
import useUnknownGuests from '@/hooks/useUnknownGuests';

export interface ICommentsProps {
  itemId: Item['id'];
  commentCountNr: number;
  className?: string;
  isEditorChildDisabled: boolean;
}

// commentFilesはcommentのIDをキーとする
export interface CommentFiles {
  [key: string]: RRFUploadedFile[];
}

/**
 * コメントリスト
 * @param props
 * @returns
 */
export default function Comments(props: ICommentsProps) {
  const editorRef = useRef({} as ICommentEditorHandler);
  const { t } = useTranslation();
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [deleteCommentId, setDeleteCommentId] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editCommentId, setEditCommentId] = useState<string | null>(null);
  const [initialState, setInitialState] = useState<string>('');

  const { itemId, commentCountNr, className, isEditorChildDisabled } = props;
  const {
    comments,
    hasNext,
    loadNextPage,
    createComment,
    deleteComment,
    updateComment,
  } = useComments(itemId);
  const { membersDic, guestsDic } = useMentions();
  const { removedMembersDic } = useRemovedMembers();
  const { removedGuestsDic } = useRemovedGuests();
  const { unknownGuestsDic } = useUnknownGuests();
  const { sharingPermission } = useProjects();

  const {
    handleSetPreUploadedFiles,
    preUploadedFiles,
    handleDeletePreFile,
    handleSelectFiles,
    isSelectFileLoading,
    clearPreUploadedFiles,
  } = useTempFiles();
  const { currentCommentFiles } = useCommentFiles(comments);
  const { currentCommentReactions } = useCommentReactions(comments);

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  // メモ化したメンバー/ゲストの辞書
  const dic = useMemo(() => {
    const allMembersDic = { ...removedMembersDic, ...membersDic };
    const allGuestsDic = { ...removedGuestsDic, ...guestsDic };
    const membersAndGuestsDic = {
      ...unknownGuestsDic,
      ...removedGuestsDic,
      ...removedMembersDic,
      ...guestsDic,
      ...membersDic,
    };
    return {
      membersAndGuestsDic,
      allMembersDic,
      allGuestsDic,
      unknownGuestsDic,
    };
  }, [
    membersDic,
    removedMembersDic,
    guestsDic,
    removedGuestsDic,
    unknownGuestsDic,
  ]);

  // ワークスペース権限
  const { isMember } = useMyWorkspaces();

  // コメント削除クリック時ハンドラ
  const handleDeleteClick = (commentId: Comment['id']) => {
    if (!commentId) return;
    setDeleteCommentId(commentId);
    setDeleteDialogOpen(true);
  };

  // コメント削除確認ダイアログハンドリング
  const handleDeleteConfirm = async (deleteFlg: boolean | undefined) => {
    if (!deleteCommentId) return;
    if (deleteFlg) {
      await deleteComment(deleteCommentId, itemId);
    }
    setDeleteCommentId(null);
    setDeleteDialogOpen(false);
  };

  // コメント更新クリック時ハンドラ
  const handleEditClick = (
    commentId: Comment['id'],
    editorState: Comment['editorState'],
  ) => {
    if (!commentId) return;
    if (commentId === editCommentId) {
      editorRef.current.setContent('');
      editorRef.current.setShouldAlert(DEFAULT_COMMENT_NOTIFY);
      setEditCommentId(null);
    } else {
      editorRef.current.setContent(editorState ?? '');
      setInitialState(editorState ?? '');
      setEditCommentId(commentId);
      const files: FileSource[] = currentCommentFiles[commentId].map((f) => ({
        fileName: f.name,
        path: f.fullPath,
        contentType: f.contentType,
      }));
      handleSetPreUploadedFiles([...files]);
    }
  };

  // コメント返信クリック時ハンドラ
  const handleReplyClick = (
    commentId: Comment['id'],
    editorState: Comment['editorState'],
    postedBy: Comment['postedBy'],
  ) => {
    if (!commentId) return;
    const postedById = postedBy as string;
    const postedByUser = dic.membersAndGuestsDic[postedById];
    editorRef.current.setQuoteContent(editorState ?? '');
    editorRef.current.setShouldAlert(DEFAULT_COMMENT_NOTIFY);
    editorRef.current.setMentionContent(
      postedById,
      postedByUser.displayName as string,
    );
  };

  // コメント送信時ハンドラ
  // eslint-disable-next-line no-unused-vars
  const onCommentSend = useCallback(
    (editorState: string, plainText: string) => {
      const filePaths = preUploadedFiles.map(
        (mp) => mp.path.split('/').slice(-1)[0],
      );
      if (editCommentId) {
        // コメント編集
        const comment = comments.find((o) => o.id === editCommentId) || null;
        if (comment === null) return;
        updateComment(comment.id, editorState, plainText, filePaths, itemId);
        setEditCommentId(null);
      } else {
        // コメント新規作成
        createComment(editorState, plainText, filePaths, itemId);
        // TODO: スクロール量が足りない DOMの構成を検討
        setTimeout(() => {
          scrollBottomRef!.current!.scrollIntoView({
            behavior: 'smooth',
          });
        });
      }
      setInitialState('');
      clearPreUploadedFiles();
      editorRef.current.setShouldAlert(DEFAULT_COMMENT_NOTIFY);
    },
    [editCommentId, preUploadedFiles],
  );

  // 編集モード解除時ハンドラ
  const onEditCancel = () => {
    setEditCommentId(null);
    // 一時添付ファイルをクリア
    clearPreUploadedFiles();
    editorRef.current.setShouldAlert(DEFAULT_COMMENT_NOTIFY);
  };

  useEffect(
    () => editorRef.current.setChildDisabled(isEditorChildDisabled),
    [isEditorChildDisabled],
  );

  // コメント本体のレンダリング処理
  // 1. コメント投稿あり
  // 2. コメント投稿0件 && 最初のコメントフラグ
  // 3. その他
  const render = useMemo(() => {
    const sortedComments = [...comments].sort((a, b) => {
      const self = new DayjsUtil(parseFsTimestamp(a.postedAt));
      const target = new DayjsUtil(parseFsTimestamp(b.postedAt));
      const isBefore = self.isBefore(target, 'second', true);
      return isBefore ? -1 : 1;
    });

    // 1-1. コメントコンポーネント表示
    if (
      commentCountNr > 0 &&
      sortedComments.find((o) => o.postedBy === userId)?.postedBy !== userId
    ) {
      return (
        <>
          {!isMember &&
            sharingPermission !== SHARING_PERMISSION_TYPES.READ &&
            sortedComments.length === 0 && (
              <div className="inline-block w-full p-4 text-left align-middle bg-white border rounded-md dark:border-gray-600 dark:bg-gray-800">
                <div className="flex items-start">
                  <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-0">
                    <ChatBubbleLeftRightIcon className="w-10 h-10 text-primary-500 dark:text-primary-600" />
                  </div>
                  <div className="ml-4 text-left">
                    <span>
                      {t(
                        'コメントを投稿して<br>メンバーとアイデアを共有しましょう！',
                        { br: <br /> },
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
          {!isMember && sharingPermission === SHARING_PERMISSION_TYPES.READ && (
            <div className="inline-block w-full p-4 text-left align-middle bg-white border rounded-md dark:border-gray-600 dark:bg-gray-800">
              <div className="flex items-start">
                <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-0">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full dark:bg-red-800 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="w-6 h-6 text-red-600 dark:text-red-300"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="my-auto ml-4 text-left ">
                  <span className="my-auto">
                    {t('閲覧権限のためコメントできません')}
                  </span>
                </div>
              </div>
            </div>
          )}
          {sortedComments.map((comment) => (
            // コメントの表示判定
            <li key={comment.id}>
              <CommentItem
                membersAndGuestsDic={dic.membersAndGuestsDic}
                comment={comment}
                onDelete={handleDeleteClick}
                onEdit={handleEditClick}
                onReply={handleReplyClick}
                editCommentId={editCommentId ?? null}
                commentFiles={
                  comment.id && currentCommentFiles[comment.id]
                    ? currentCommentFiles[comment.id]
                    : []
                }
                commentReactions={
                  comment.id && currentCommentReactions[comment.id]
                    ? currentCommentReactions[comment.id]
                    : []
                }
                isReadOnly={
                  !isMember &&
                  sharingPermission === SHARING_PERMISSION_TYPES.READ
                }
              />
            </li>
          ))}
        </>
      );
    }

    // 1-2. コメントコンポーネント表示
    if (
      commentCountNr > 0 &&
      sortedComments.find((o) => o.postedBy === userId)?.postedBy === userId
    ) {
      return (
        <>
          {!isMember && sharingPermission === SHARING_PERMISSION_TYPES.READ && (
            <div className="inline-block w-full p-4 text-left align-middle bg-white border rounded-md dark:border-gray-600 dark:bg-gray-800">
              <div className="flex items-start">
                <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-0">
                  <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full dark:bg-red-800 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="w-6 h-6 text-red-600 dark:text-red-300"
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <div className="my-auto ml-4 text-left ">
                  <span className="my-auto">
                    {t('閲覧権限のためコメントできません')}
                  </span>
                </div>
              </div>
            </div>
          )}
          {sortedComments.map((comment) => (
            // コメントの表示判定
            <li key={comment.id}>
              <CommentItem
                membersAndGuestsDic={dic.membersAndGuestsDic}
                comment={comment}
                onDelete={handleDeleteClick}
                onEdit={handleEditClick}
                onReply={handleReplyClick}
                editCommentId={editCommentId ?? null}
                commentFiles={
                  comment.id && currentCommentFiles[comment.id]
                    ? currentCommentFiles[comment.id]
                    : []
                }
                commentReactions={
                  comment.id && currentCommentReactions[comment.id]
                    ? currentCommentReactions[comment.id]
                    : []
                }
                isReadOnly={
                  !isMember &&
                  sharingPermission === SHARING_PERMISSION_TYPES.READ
                }
              />
            </li>
          ))}
        </>
      );
    }

    // 2. 最初のコメントコンポーネント表示
    if (
      !comments.length &&
      commentCountNr === 0 &&
      (isMember || sharingPermission !== SHARING_PERMISSION_TYPES.READ)
    ) {
      return (
        <div className="inline-block w-full p-4 text-left align-middle bg-white border rounded-md dark:border-gray-600 dark:bg-gray-800">
          <div className="flex items-start">
            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 mx-0">
              <ChatBubbleLeftRightIcon className="w-10 h-10 text-primary-500 dark:text-primary-600" />
            </div>
            <div className="ml-4 text-left">
              <span>
                {t(
                  'コメントを投稿して<br>メンバーとアイデアを共有しましょう！',
                  { br: <br /> },
                )}
              </span>
            </div>
          </div>
        </div>
      );
    }

    // 3. スケルトン表示
    return Array(3)
      .fill(null)
      .map((_, i) => i)
      .map((num) => (
        <div
          key={num}
          className="relative flex items-start space-x-3 comment__panel"
        >
          {/* アバターアイコン */}
          <div>
            <Skeleton circle className="!w-9 !h-9 skeleton-100" />
          </div>
          {/* コメント本体 */}
          <div className="static flex-1 min-w-0 group">
            {/* 投稿者 */}
            <div className="static min-w-0 font-medium text-gray-500 dark:text-gray-400">
              <span>
                <Skeleton className="!w-40 skeleton-100" />
              </span>
            </div>
            {/* コメント表示欄 */}
            <div className="flex flex-1 min-w-0 mt-1 text-gray-500 bg-white border rounded-tl-none dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400 ring-opacity-100 rounded-tr-xl rounded-md shadow-sm rounded-br-xl rounded-bl-xl">
              <div className="w-full">
                <div className="p-4">
                  {/* コメント */}
                  <div className="break-all">
                    <Skeleton className="h-10 !max-w-md" />
                  </div>
                </div>
              </div>
            </div>
            {/* 更新日時 */}
            <div className="static mr-1 font-medium text-gray-500 dark:text-gray-400">
              <p className="my-auto text-right">
                <span className="text-xs text-gray-400 dark:text-gray-500">
                  <Skeleton className="!w-32 skeleton-100" />
                </span>
              </p>
            </div>
          </div>
        </div>
      ));
  }, [
    comments,
    commentCountNr,
    editCommentId,
    dic,
    isMember,
    currentCommentFiles,
    sharingPermission,
  ]);

  // コメントリストを時差表示しないとInfiniteScrollが正常に発火しない問題の対処
  const [isDelayCommentList, setIsDelayCommentList] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsDelayCommentList(true);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild());
  }, [comments]);

  return (
    <div
      id="commentScroll"
      className={classNames(
        className,
        'flex flex-col-reverse overflow-y-auto bg-gray-100 dark:bg-gray-800 sm:mt-4 dark:border-gray-600 rounded-md border',
      )}
    >
      <div className="flex flex-col-reverse h-full">
        {(isMember || sharingPermission !== SHARING_PERMISSION_TYPES.READ) && (
          <div className="sticky bottom-0 z-10 w-full dark:border-t dark:border-gray-600 rounded-b-md">
            <CommentEditor
              className="overflow-y-auto max-h-[calc(100vh-30rem)]"
              ref={editorRef}
              uploadedFiles={preUploadedFiles || []}
              onSend={onCommentSend}
              onEditCancel={onEditCancel}
              isEditMode={!!editCommentId}
              onSelectFiles={handleSelectFiles}
              onDetachFile={handleDeletePreFile}
              isSelectFileLoading={isSelectFileLoading}
              initialData={initialState}
            />
          </div>
        )}
        <div className="grow">
          <div className="flex flex-col-reverse">
            {isDelayCommentList && (
              <InfiniteScroll
                inverse
                scrollThreshold="100px"
                dataLength={comments.length}
                next={loadNextPage}
                hasMore={hasNext}
                loader={<Divider label={t('読込中')} className="mx-4 mb-3" />}
                scrollableTarget="commentScroll"
                initialScrollY={0}
              >
                {/* コメント本体のレンダリング処理 */}
                <ul className="p-4 space-y-4">{render}</ul>
                <div ref={scrollBottomRef} />
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
      {/* 削除確認ダイアログ */}
      <ConfirmDialog
        key="comment-delete-confirm"
        open={deleteDialogOpen}
        onClose={handleDeleteConfirm}
        title={t('コメントを削除')}
        positive={t('削除')}
        warning
      >
        <div />
        <p>
          {t(
            '削除されたコメントは復元できません。<br> 本当によろしいですか？',
            { br: <br /> },
          )}
        </p>
      </ConfirmDialog>
    </div>
  );
}
