import {
  AccountStatus,
  Project,
  RootState,
  View,
  Workspace,
} from '@/@types/models';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';

import {
  COL_ACCOUNT_STATUSES,
  getAccountStatusesPath,
} from '@/libs/docPathUtils';

import { getUpdateSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

import useViews from '@/hooks/useViews';

/**
 * カレントIDを監視して最終アクセス情報を保存するHooks
 */
export default function useLastAccess() {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const { currentView } = useViews();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace?.id],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );
  const viewId = useMemo(() => currentView?.id ?? undefined, [currentView?.id]);

  const accountSettings = useSelector(
    (state: RootState) => state.firestore.ordered[COL_ACCOUNT_STATUSES] || [],
  );

  const accountSetting = useMemo(
    () =>
      accountSettings && accountSettings.length > 0
        ? accountSettings[0]
        : ({
            lastWorkspaceId: null,
            lastProjectId: null,
            lastViewId: null,
          } as AccountStatus),
    [accountSettings],
  );

  /** 直前のworkspaceID */
  const [prevWorkspaceId, setPrevWorkspaceId] =
    useState<Workspace['id']>(undefined);
  /** 直前のprojectID */
  const [prevProjectId, setPrevProjectId] = useState<Project['id']>(undefined);
  /** 直前のviewID */
  const [prevViewId, setPrevViewId] = useState<View['id']>(undefined);

  useEffect(() => {
    exec(async () => {
      if (
        !userId ||
        !workspaceId ||
        prevWorkspaceId === workspaceId ||
        rrfAuth.isAnonymous
      )
        return;
      await rrfFirestore.update<AccountStatus>(
        `${getAccountStatusesPath(userId)}/${userId}`,
        {
          lastWorkspaceId: workspaceId,
          ...getUpdateSystemFields(userId),
        },
      );
      setPrevWorkspaceId(workspaceId);
    }, t('ステートの更新に失敗しました'));
  }, [userId, workspaceId, rrfAuth.isAnonymous]);

  useEffect(() => {
    exec(async () => {
      if (
        !userId ||
        !projectId ||
        prevProjectId === projectId ||
        rrfAuth.isAnonymous
      )
        return;
      await rrfFirestore.update<AccountStatus>(
        `${getAccountStatusesPath(userId)}/${userId}`,
        {
          lastProjectId: projectId,
          ...getUpdateSystemFields(userId),
        },
      );
      setPrevProjectId(projectId);
    }, t('ステートの更新に失敗しました'));
  }, [userId, projectId, rrfAuth.isAnonymous]);

  useEffect(() => {
    exec(async () => {
      if (!userId || !viewId || prevViewId === viewId || rrfAuth.isAnonymous)
        return;
      await rrfFirestore.update<AccountStatus>(
        `${getAccountStatusesPath(userId)}/${userId}`,
        {
          lastViewId: viewId,
          ...getUpdateSystemFields(userId),
        },
      );
      setPrevViewId(viewId);
    }, t('ステートの更新に失敗しました'));
  }, [userId, viewId, rrfAuth.isAnonymous]);

  return { accountSetting };
}
