import {
  IFuncCreateProject,
  IFuncCreateProjectResult,
  IFbFuncCreateProject,
  IFuncCopyProject,
  IFuncCopyProjectResult,
  IFbFuncCopyProject,
} from '@/@types/common';
import { Project, Workspace } from '@/@types/models';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';

import { REP_ERROR_MESSAGE } from '@/libs/const';

import { getProjectsPath } from '@/libs/docPathUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { functions } from '@/firebase';
import { firestore, generateDocId } from '@/firestore';

export interface IProjectRepository {
  workspaceId: Workspace['id'];
  findById: (docId: string) => Promise<Project | null>;
  list: () => Promise<Project[]>;
  createProject: (
    data: IFuncCreateProject,
  ) => Promise<IFuncCreateProjectResult | null>;
  copyProject: (
    data: IFuncCopyProject,
  ) => Promise<IFuncCopyProjectResult | null>;
  generateDocId: () => string;
}

const funcCreateProject = httpsCallable<
  IFbFuncCreateProject,
  IFuncCreateProjectResult
>(functions, 'createProject');

const funcCopyProject = httpsCallable<
  IFbFuncCopyProject,
  IFuncCopyProjectResult
>(functions, 'copyProject');

export default class ProjectRepository implements IProjectRepository {
  workspaceId: Workspace['id'];

  constructor(private wsid: string) {
    this.workspaceId = wsid;
  }

  // 単体プロジェクト取得
  async findById(docId: Project['id']): Promise<Project | null> {
    try {
      const path = `${getProjectsPath(this.workspaceId)}/${docId}`;
      const docRef = doc(firestore, path).withConverter(fsConverter<Project>());
      const data = await getDoc(docRef);
      const project = data.data();
      if (project === undefined) {
        return null;
      }
      project.id = data.id;
      return project;
    } catch (err: any) {
      repositoryError(this.findById.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * List project docs
   * @returns Promise<Project[]>
   */
  async list(): Promise<Project[]> {
    try {
      const collectionRef = collection(
        firestore,
        getProjectsPath(this.workspaceId),
      ).withConverter(fsConverter<Project>());
      return (await getDocs(collectionRef)).docs.map((d) => {
        const project = d.data();
        project.id = d.id;
        return project;
      });
    } catch (err: any) {
      repositoryError(this.list.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  /**
   * プロジェクト作成
   * @param data zodScheme
   * @returns
   */
  async createProject(data: IFuncCreateProject) {
    try {
      const res = await funcCreateProject({
        workspaceId: this.workspaceId,
        ...data,
      });
      return res.data;
    } catch (error) {
      throw new Error('Project creation failed.');
    }
  }

  /**
   * プロジェクト複製
   * Function内のバリデーションをエラーとして表示するため、tryCatch はコンポーネントに委ねる
   * @param data zodScheme
   * @returns
   */
  async copyProject(data: IFuncCopyProject) {
    const res = await funcCopyProject({
      fromWorkspaceId: this.workspaceId,
      ...data,
    });
    return res.data;
  }

  // Firestore自動生成のuuidを取得する
  generateDocId(): string {
    return generateDocId(getProjectsPath(this.workspaceId));
  }
}
