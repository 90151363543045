import {
  CommentReactionGroup,
  CommentReactionGroupUser,
  IEmoji,
} from '@/@types/common';
import { RootState, Comment } from '@/@types/models';

import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  REACTION_ABB_THRESHOLD,
  REACTION_DISPLAY_NAME_ABB_THRESHOLD,
} from '@/libs/const';

import { classNames } from '@/libs/styleUtils';

import { t2s } from '@/libs/utils';

import BaseButton from '@/components/Items/Comments/Reaction/BaseButton';

import { emojis } from '@/assets/native.jp.json';

import useCommentReactionWriter from '@/hooks/useCommentReactionWriter';
import useMentions from '@/hooks/useMentions';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';
import useUnknownGuests from '@/hooks/useUnknownGuests';

export interface IReactionIconProps {
  className?: string;
  emojiId: string;
  users: CommentReactionGroupUser[];
  commentId: Comment['id'];
  disabled?: boolean;
  reactionGroup: CommentReactionGroup[];
}

/**
 * リアクションアイコン表示
 * @param props
 * @returns
 */
export default function ReactionIcon(props: IReactionIconProps) {
  const { className, emojiId, users, disabled, commentId, reactionGroup } =
    props;
  const { t } = useTranslation();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const { createReaction, deleteReaction } = useCommentReactionWriter();
  const { membersDic, guestsDic } = useMentions();
  const { removedMembersDic } = useRemovedMembers();
  const { removedGuestsDic } = useRemovedGuests();
  const { unknownGuestsDic } = useUnknownGuests();

  /** ユーザー辞書 */
  const dic = useMemo(
    () => ({
      ...unknownGuestsDic,
      ...removedGuestsDic,
      ...removedMembersDic,
      ...guestsDic,
      ...membersDic,
    }),
    [
      membersDic,
      removedMembersDic,
      guestsDic,
      removedGuestsDic,
      unknownGuestsDic,
    ],
  );

  /** 自分がリアクションした */
  const isContainsMe = useMemo(
    () => !!users.find((o) => o.uid === userId),
    [users],
  );

  /** カウント */
  const count = useMemo(
    () =>
      users.length > REACTION_ABB_THRESHOLD
        ? `${REACTION_ABB_THRESHOLD}+`
        : users.length,
    [users],
  );
  /** 絵文字オブジェクト */
  const emoji = useMemo(() => (emojis as IEmoji)[emojiId], [emojiId]);

  /** すでにリアクション済みの場合のリアクションID */
  const reactionId = useMemo(
    () =>
      reactionGroup
        .find((o) => o.emojiId === emojiId)
        ?.row.find((o) => o.uid === userId)?.reactionId,
    [reactionGroup, emojiId, userId],
  );

  const displayNames = useMemo(() => {
    const uids = reactionGroup.find((o) => o.emojiId === emojiId)
      ?.row as CommentReactionGroupUser[];
    let nms = uids
      ?.slice(0, REACTION_DISPLAY_NAME_ABB_THRESHOLD)
      .map((u) => dic[u.uid].displayName)
      .join(', ');
    if (uids.length > REACTION_DISPLAY_NAME_ABB_THRESHOLD) {
      nms = `${nms}, ${t2s(
        t('他<count>人', {
          count: uids.length - REACTION_DISPLAY_NAME_ABB_THRESHOLD,
        }),
      )}`;
    }
    return nms;
  }, [reactionGroup, emojiId]);

  /** リアクションクリック */
  const handleClick = useCallback(() => {
    if (reactionId) {
      deleteReaction(commentId, reactionId);
    } else {
      createReaction(emojiId, commentId);
    }
  }, [emojiId, commentId, reactionId]);

  return (
    <BaseButton
      dataTip={t2s(
        t('<displayNames> がリアクションしました', {
          displayNames,
        }),
      )}
      className={classNames(
        isContainsMe ? 'dark:border-sky-700 dark:bg-sky-900/50' : '',
        isContainsMe && !disabled
          ? 'dark:hover:bg-red-700/50 dark:hover:border-red-700 border-sky-300 bg-sky-300/50 hover:bg-red-400/50 hover:border-red-400'
          : '',
        disabled ? 'cursor-default' : '',
        className,
      )}
      disabled={disabled}
      onClick={handleClick}
    >
      <span>{emoji.skins[0].native}</span>
      <span className="text-xs">{count}</span>
    </BaseButton>
  );
}
