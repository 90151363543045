import { Project, View } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';

import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import { t2s } from '@/libs/utils';

import { VIEW_NAME } from '@/libs/validations';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import useSubmitState from '@/hooks/useSubmitState';
import useViews from '@/hooks/useViews';

interface IEditViewNameDialog extends IModalDialogProps {
  project: Project;
  view: View;
}

export default function EditViewNameDialog(props: IEditViewNameDialog) {
  const { project, view, onClose } = props;
  const { t } = useTranslation();
  const { updateView } = useViews();

  // Zod schema定義
  const schema = z.object({
    viewName: z
      .string()
      .min(1, { message: t('入力してください') })
      .max(VIEW_NAME.max, t2s(t('<max>文字以内で入力してください', VIEW_NAME))),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    const { viewName } = data;
    await updateView(
      viewName,
      view.viewType,
      true,
      view.id,
      project.id as string,
    );
    // Reset form state
    methods.reset({ ...data });
    onClose();
  });

  // mount時処理
  useEffect(() => {
    methods.reset({ viewName: view.viewName });
  }, []);
  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <FormDialog
      onClose={onClose}
      title={t('ビュー名を変更')}
      onSubmit={formSubmit}
    >
      <FormProvider {...methods}>
        <FormCol>
          <Input name="viewName" label={t('ビュー名')} />
        </FormCol>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="changeViewName"
          disabled={isDisabledApply}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('更新')}
        </FormButton>
        <FormButton
          id="cancelChangeViewName"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
