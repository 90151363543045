import { RootState } from '@/@types/models';

import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ExtendedFirebaseInstance,
  useFirebase,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { toast } from 'react-toastify';

import { FILE_SIZE_LIMIT, PLANS } from '@/libs/const';
import { COL_TEMP_FILES, getTempFilesPath } from '@/libs/docPathUtils';

import { t2s, uploadSelectFiles } from '@/libs/utils';

import { FileSource } from '@/components/Common/FileLoader';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import usePlans from '@/hooks/usePlans';

/**
 * アップロードしたファイルのリアルタイムアップデートを購読するためのHooks
 * @returns
 */
export default function useTempFiles() {
  const firebase: ExtendedFirebaseInstance = useFirebase();

  const { currentMyWorkspace } = useMyWorkspaces();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);

  const [preUploadedFiles, setPreUploadedFiles] = useState<FileSource[]>([]);
  const [isSelectFileLoading, setIsSelectFileLoading] =
    useState<boolean>(false);
  const { plan } = usePlans();

  // カレントワークスペースID
  const currentWorkspaceId = useMemo(() => {
    if (!currentMyWorkspace || !currentMyWorkspace.workspaceId) return null;
    return currentMyWorkspace.workspaceId;
  }, [currentMyWorkspace]);

  useFirestoreConnect(() =>
    currentWorkspaceId
      ? [
          {
            collection: getTempFilesPath(currentWorkspaceId),
            storeAs: COL_TEMP_FILES,
          },
        ]
      : [],
  );

  // 一時ファイル取得
  const uploadedFiles = useSelector(
    (state: RootState) => state.firestore.ordered[COL_TEMP_FILES],
  );

  // ファイルアップロード時のファイル名変更処理
  const onSelectFiles = useCallback(
    async (files: File[]) => {
      if (!currentWorkspaceId) throw new Error('current workspace not loaded.');
      return uploadSelectFiles(
        files,
        getTempFilesPath(currentWorkspaceId),
        rrfAuth,
        firebase,
      );
    },
    [currentWorkspaceId],
  );

  // アップロード済ファイル削除
  const onDeleteFile = useCallback((file: FileSource, docPath: string) => {
    // 一時ファイル以外はリターン
    if (!file.path.split('/').includes(COL_TEMP_FILES)) return;

    firebase.deleteFile(file.path, docPath);
  }, []);

  const handleSelectFiles = useCallback(
    async (files: File[]) => {
      if (isSelectFileLoading) return [];
      setIsSelectFileLoading(true);
      const resultFiles = await onSelectFiles(files);
      const fs = resultFiles.filter((f) => {
        if (
          (f.File.size || 0) >
          (plan?.type === PLANS.FREE
            ? FILE_SIZE_LIMIT.FREE
            : FILE_SIZE_LIMIT.STANDARD)
        ) {
          toast.error(
            t2s(
              t('アップロードできるファイルサイズは<size>MBまでです', {
                size:
                  (plan?.type === PLANS.FREE
                    ? FILE_SIZE_LIMIT.FREE
                    : FILE_SIZE_LIMIT.STANDARD) /
                  (1000 * 1000),
              }),
            ),
          );
          return false;
        }
        return true;
      });
      setIsSelectFileLoading(false);
      const uploadFile: FileSource[] = fs.map((f) => ({
        path: f.File.fullPath,
        contentType: f.File.contentType,
        fileName: f.File.name,
      }));
      // 登録時のレスポンスをローカルステートに保持（サブスクリプションしない）
      setPreUploadedFiles((old) => [...old, ...uploadFile]);
      return uploadFile;
    },
    [preUploadedFiles, plan, isSelectFileLoading],
  );

  /**
   * 添付ファイル削除ハンドラ
   * @param e
   */
  const handleDeletePreFile = useCallback(
    (file: FileSource, docPath: string) => {
      onDeleteFile(file, docPath);
      setPreUploadedFiles((old) => old.filter((f) => f.path !== file.path));
    },
    [preUploadedFiles],
  );

  /**
   * 仮アップロードファイル配列をクリアする
   */
  const clearPreUploadedFiles = () => {
    setPreUploadedFiles([]);
  };

  const handleSetPreUploadedFiles = (files: FileSource[]) => {
    setPreUploadedFiles(files);
  };

  return {
    uploadedFiles,
    onSelectFiles,
    onDeleteFile,
    preUploadedFiles,
    isSelectFileLoading,
    handleSelectFiles,
    handleDeletePreFile,
    clearPreUploadedFiles,
    handleSetPreUploadedFiles,
  };
}
