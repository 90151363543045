import React, {
  forwardRef,
  LegacyRef,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';

import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

import { SpinnerCircleIcon } from '@/components/Common/Icon/Icons';

export type IMenuButtonProps = {
  id: string;
  className?: string;
  children: ReactNode;
  variant?: 'primary' | 'warning';
  type: 'text' | 'icon';
  disabled?: boolean;
  active?: boolean;
  tabIndex?: number;
  align?: 'left' | 'center' | 'right';
  toolTip?: string;
  spinner?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default forwardRef(
  (props: IMenuButtonProps, ref: LegacyRef<HTMLButtonElement>) => {
    const {
      id,
      className,
      children,
      type,
      variant,
      onClick,
      disabled,
      active,
      align,
      toolTip,
      spinner,
      tabIndex,
    } = props;

    const colorThemeClass = useMemo(() => {
      if (disabled || spinner) {
        return `cursor-not-allowed text-gray-50 bg-gray-400/40 dark:text-gray-500 dark:bg-gray-700/40 border-transparent ${
          !variant ? 'sm:mt-0' : ''
        }`;
      }
      if (variant === 'warning') {
        return 'text-white border-transparent dark:text-white bg-red-400 dark:bg-red-500 hover:bg-red-500 dark:hover:bg-red-600 focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-red-400 dark:focus:ring-red-500';
      }
      if (variant === 'primary') {
        return 'text-white border-transparent dark:text-white bg-primary-500 dark:bg-primary-600 hover:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600';
      }
      return 'text-gray-500 bg-white border-gray-300 sm:mt-0 dark:border-gray-600 hover:bg-gray-100 focus:outline-none ring-0 focus:ring-2 dark:ring-gray-500 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600';
    }, [disabled, variant]);

    const alignClass = useMemo(() => {
      if (align === 'left') {
        return '';
      }
      if (align === 'right') {
        return 'justify-end';
      }
      return 'justify-center';
    }, [align]);

    useEffect(() => {
      ReactTooltip.rebuild();
    }, [toolTip]);

    useEffect(
      () => () => {
        // unmountされるタイミングでツールチップを消す
        ReactTooltip.hide();
      },
      [],
    );

    return (
      <>
        {type === 'text' && (
          <button
            id={id}
            ref={ref}
            data-tip={toolTip}
            type="button"
            tabIndex={tabIndex}
            onClick={onClick}
            disabled={disabled || spinner}
            className={classNames(
              'inline-flex w-full px-4 py-1 font-medium text-left rounded-md shadow-sm border',
              alignClass,
              colorThemeClass,
              className,
            )}
          >
            <span
              data-tip={toolTip}
              data-tip-disable={false}
              className="inline-flex items-center"
            >
              {spinner && <SpinnerCircleIcon className="w-4 h-4 mr-3" />}
              {children}
            </span>
          </button>
        )}
        {type === 'icon' && (
          <button
            id={id}
            ref={ref}
            data-tip={toolTip}
            type="button"
            tabIndex={tabIndex}
            onClick={onClick}
            disabled={disabled || spinner}
            className={classNames(
              className,
              'inline-flex p-1',
              alignClass,
              variant === 'primary'
                ? 'text-primary-500 dark:text-primary-600'
                : '',
              disabled ? 'cursor-not-allowed' : '',
              'flex items-center border border-transparent rounded-md hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600',
              'block',
              active
                ? 'text-white border-transparent bg-primary-500 dark:bg-primary-600'
                : 'text-gray-400 dark:text-gray-400',
            )}
          >
            <span
              data-tip={toolTip}
              data-tip-disable={false}
              className="inline-flex items-center"
            >
              {spinner && <SpinnerCircleIcon className="w-4 h-4 mr-3" />}
              {children}
            </span>
          </button>
        )}
      </>
    );
  },
);
