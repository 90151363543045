import { zodResolver } from '@hookform/resolvers/zod';
import React, { MouseEventHandler, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';

import {
  FUNCTIONS_ERROR_DETAILS,
  RE_CAPTCHA_ACTIONS,
  VALIDATION_MODE,
} from '@/libs/const';

import { t2s } from '@/libs/utils';
import { PASSWORD_REGEXP } from '@/libs/validations';

import AccountRepository from '@/repositories/AccountRepository';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import Form from '@/components/Common/Forms/Form';

import FormCol from '@/components/Common/Forms/FormCol';

import Input from '@/components/Common/Forms/Input';

import useFirebaseAuth from '@/hooks/useFirebaseAuth';
import useLogin from '@/hooks/useLogin';

import useReCaptcha from '@/hooks/useReCaptcha';
import useSubmitState from '@/hooks/useSubmitState';

export interface ISignUpFormProps {
  email: string;
  onBack: MouseEventHandler<HTMLButtonElement>;
}

/**
 * サインアップ フォーム
 * @param props
 * @returns
 */
export default function SignUpForm(props: ISignUpFormProps) {
  const { t } = useTranslation();
  const { email, onBack } = props;

  const { createUserWithEmailPassword } = useFirebaseAuth();
  const { reCaptchaToken } = useReCaptcha(RE_CAPTCHA_ACTIONS.PASSWORD_SIGN_IN);
  const accountRepository = new AccountRepository();

  let checkConfirmPassword = (v: string) => typeof v === 'string';

  // Zod schema定義
  const schema = z.object({
    email: z.string().min(1, { message: t('入力してください') }),
    password: z
      .string()
      .trim()
      .min(1, { message: t('入力してください') })
      .regex(PASSWORD_REGEXP, t2s(t('パスワードポリシーを満たしていません'))),
    confirmPassword: z
      .string()
      .trim()
      .refine(
        (v) => checkConfirmPassword(v),
        t2s(t('パスワードが一致しません')),
      ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  checkConfirmPassword = (v: string) => methods.getValues().password === v;

  useEffect(() => {
    methods.setValue('email', email);
  }, [email]);

  const login = useLogin();

  const onPasswordSignInClick = async (
    signupEmail: string,
    signupPassword: string,
  ) => {
    await login(async () => {
      await createUserWithEmailPassword(signupEmail, signupPassword);
    });
  };

  useEffect(() => {
    if (methods.getValues().password.length > 0) methods.trigger();
  }, [methods.getValues().password]);

  const formSignUpSubmit = methods.handleSubmit(async (data) => {
    try {
      await accountRepository.checkReCaptcha(reCaptchaToken || '');
    } catch (e: any) {
      if (e.details === FUNCTIONS_ERROR_DETAILS.RECAPTCHA_ERROR) {
        toast.error(
          t(
            '一定時間経過などを理由にBOTとして判断されたためログインできませんでした。ページを再読み込みの上、再度ログインまたはアカウント登録をお試しください。',
          ),
        );
        return;
      }
      toast.error(t('Bot判定に失敗しました'));
      throw e;
    }
    await onPasswordSignInClick(methods.getValues().email, data.password);
  });

  // Submitボタン状態
  const isDisabledSubmit = useSubmitState(methods) || !reCaptchaToken;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={formSignUpSubmit}>
        <FormCol>
          <Input name="email" label={t('メールアドレス')} disabled />
        </FormCol>
        <FormCol>
          <Input
            autoFocus
            password
            name="password"
            label={t('パスワードを決めてください')}
          />
          <p className="mt-3 text-xs font-bold text-gray-500">
            {t('パスワードポリシー')}
          </p>
          <p className="mt-2 text-xs text-gray-500" id="email-description">
            {t(
              '8文字以上かつ英字大文字小文字と数字を1文字以上含めてください。また次の記号も使用できます（<dot> ! _ / ` ( ) [ ] + - = $ # & @ ~）',
              { dot: '.' },
            )}
          </p>
        </FormCol>
        <FormCol>
          <Input
            password
            name="confirmPassword"
            label={t('確認のためもう一度')}
          />
        </FormCol>
        <div className="relative sm:col-span-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-100 dark:border-gray-700" />
          </div>
        </div>
        <FormCol>
          <FormButton
            id="signUpAccount"
            className="sm:w-full"
            submit
            variant="primary"
            disabled={isDisabledSubmit}
          >
            {t('アカウント登録')}
          </FormButton>
        </FormCol>
        <FormCol>
          <FormButton
            id="returnLogin"
            className="sm:w-full"
            onClick={onBack}
            disabled={methods.formState.isSubmitting}
          >
            {t('戻る')}
          </FormButton>
        </FormCol>
      </Form>
    </FormProvider>
  );
}
