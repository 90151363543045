import {
  SearchFilter,
  CreateUpdateItemBody,
  CreateUpdateItemItem,
} from '@/@types/common';
import { RootState, Item, Body, ItemOrder } from '@/@types/models';
import { serverTimestamp } from 'firebase/firestore';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import {
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';

import { ITEMS_ORDER_MAX } from '@/libs/const';
import {
  COL_ITEMS,
  getItemOrdersPath,
  getItemsPath,
} from '@/libs/docPathUtils';

import { getUpdateSystemFields } from '@/libs/utils';

import { IItemSearchState, setItemSearchState } from '@/reducers/itemsReducer';

import { isSelectPublishItemModeContext } from '@/components/Share/SelectPublishItemsContext';

import { generateDocId } from '@/firestore';
import useHandleApi from '@/hooks/useHandleApi';
import useItemOrders from '@/hooks/useItemOrders';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useProjects from '@/hooks/useProjects';

/**
 * itemsのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useItems() {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();
  const { t } = useTranslation();
  const { currentItemId } = useSelector((state: RootState) => state.items);
  const { currentMyProject, myProjects } = useProjects();
  const { currentMyWorkspace } = useMyWorkspaces();
  const itemsState = useSelector((state: RootState) => state.items);
  const dispatch = useDispatch();
  const { currentItemOrderList, getNewItemOrders } = useItemOrders();
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);

  // アイテム数上限オーバー可否フラグ
  const isItemLimitOver = useMemo(
    () => currentItemOrderList.length >= ITEMS_ORDER_MAX,
    [currentItemOrderList.length],
  );

  // アイテムID切替ごとにストアが洗い替えされないためprojectId毎にサブスクリプションしている
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => {
      const query: ReduxFirestoreQuerySetting = {
        collection: getItemsPath(workspaceId, p.id as string),
        storeAs: `${COL_ITEMS}/${p.id}`,
        orderBy: [['title', 'asc']],
      };
      // ゲストの場合は絞り込みを追加
      if (
        ['unknownGuest', 'guest'].includes(
          currentMyWorkspace?.joinType as string,
        )
      ) {
        query.where = [['isShare', '==', true]];
      }
      return query;
    });
  });

  const ordered = useSelector((state: any) => state.firestore.ordered);
  const currentSearchState: IItemSearchState = useMemo(() => {
    const fr = itemsState.itemSearchStates.find(
      (o) =>
        o.workspaceId === currentMyWorkspace?.id &&
        o.projectId === currentMyProject?.id,
    );
    return (
      fr ||
      ({
        workspaceId: currentMyWorkspace?.id,
        projectId: currentMyProject?.id,
        keyword: null,
        filter: null,
        itemIds: [],
      } as IItemSearchState)
    );
  }, [itemsState.itemSearchStates, currentMyWorkspace, currentMyProject]);

  const items = useMemo(() => {
    if (!currentMyProject?.id) return [];
    const currentAllItems = (ordered[`${COL_ITEMS}/${currentMyProject.id}`] ||
      []) as Item[];

    // 検索結果が存在する、かつ公開アイテム選択モードでない場合はitemIdをフィルタリングする
    return (currentSearchState.keyword ||
      currentSearchState.filter !== null ||
      currentSearchState.itemIds.length > 0) &&
      !isSelectPublishItemMode
      ? currentAllItems.filter((i) =>
          currentSearchState.itemIds.includes(i.id as string),
        )
      : currentAllItems;
  }, [ordered, currentMyProject, currentSearchState, isSelectPublishItemMode]);

  const currentItem = useMemo(
    () => items.find((i) => i.id === currentItemId),
    [items, currentItemId],
  );

  // 自身が作成したアイテムか
  const getIsOwnItem = useCallback(
    (itemId: Item['id']) => {
      const targetItem = items.find((i) => i.id === itemId);
      return targetItem?.itemCreatedBy === userId;
    },
    [items],
  );

  // アイテム更新時の共通フィールド
  const itemUpdateCommonField = useCallback(
    () => ({
      itemUpdatedAt: serverTimestamp(),
      itemUpdatedBy: userId,
      sysUpdatedAt: serverTimestamp(),
      sysUpdatedBy: userId,
    }),
    [userId],
  );

  // アイテムの新規ID取得
  const generateItemId = () => {
    if (!workspaceId || !projectId) throw new Error('item generateId failed.');
    return generateDocId(getItemsPath(workspaceId, projectId));
  };

  /**
   * アイテム新規作成用オブジェクト
   * @param newItemId
   * @param title
   * @param statusId
   * @param position
   * @param editorState
   * @param plainText
   * @returns
   */
  const getCreateItem = (
    newItemId: string,
    title: Item['title'],
    statusId: Item['statusId'],
    position: string,
    editorState: Body['editorState'],
    plainText: Body['plainText'],
  ) => {
    if (!userId || !workspaceId || !projectId)
      throw new Error('create item failed.');
    /** アイテム基本情報保存 */
    const newItem: CreateUpdateItemItem = {
      id: newItemId,
      title,
      statusId,
    };

    /** newItemOrdersを作成 */
    const newOrderList: ItemOrder['orderList'] = getNewItemOrders(
      newItemId,
      position,
    );

    /** bodiesを作成 */
    const itemBody: CreateUpdateItemBody = {
      editorState,
      plainText,
    };

    return {
      item: newItem,
      itemOrderList: newOrderList,
      body: itemBody,
    };
  };
  // アイテム更新処理共通関数
  const handleItemUpdate = useCallback(
    async (fields: Item) => {
      const { id, ...rest } = fields;
      if (!userId || !workspaceId || !projectId || !id)
        throw new Error('update item failed');
      rrfFirestore.update<Item>(
        `${getItemsPath(workspaceId, projectId)}/${id}`,
        { ...rest },
      );
    },
    [userId, workspaceId, projectId],
  );

  // ステータス更新
  const updateStatus = async (id: Item['id'], statusId: Item['statusId']) => {
    exec(
      async () =>
        handleItemUpdate({ id, statusId, ...itemUpdateCommonField() }),
      t('ステータスの更新に失敗しました'),
    );
  };

  /**
   * itemをdrag(itemとitemOrdersを更新)
   * @param id
   * @param statusId
   * @param position
   */
  const dragItem = async (
    id: Item['id'],
    statusId: Item['statusId'],
    position: string,
  ) => {
    const itemOrdersRef = rrfFirestore
      .collection(`${getItemOrdersPath(workspaceId, projectId)}`)
      .doc(projectId);

    // 新規itemOrdersを取得
    const newOrderList = getNewItemOrders(id, position);

    const newItemOrders = {
      orderList: newOrderList,
      ...getUpdateSystemFields(userId),
    };

    const itemRef = rrfFirestore.doc(
      `${getItemsPath(workspaceId, projectId)}/${id}`,
    );

    const shouldUpdateStatusId =
      (await itemRef.get())?.data()?.statusId !== statusId;

    // バッチ実行
    const batch = rrfFirestore.batch();
    try {
      batch.update(itemOrdersRef, newItemOrders);
      if (shouldUpdateStatusId) {
        batch.update(itemRef, { statusId, ...itemUpdateCommonField() });
      }
      await batch.commit();
    } catch (error: any) {
      t('アイテムの並び変えに更新に失敗しました');
    }
  };

  // アイテム削除
  const deleteItem = async (itemId: Item['id']) => {
    if (!workspaceId || !projectId) throw new Error('delete item failed.');
    const filteredOrderList = currentItemOrderList.filter((v) => v !== itemId);
    const newItemOrders = {
      orderList: filteredOrderList,
      ...getUpdateSystemFields(userId),
    };
    const itemOrdersRef = rrfFirestore
      .collection(`${getItemOrdersPath(workspaceId, projectId)}`)
      .doc(projectId);

    const itemRef = rrfFirestore.doc(
      `${getItemsPath(workspaceId, projectId)}/${itemId}`,
    );
    // バッチ実行
    const batch = rrfFirestore.batch();
    try {
      batch.update(itemOrdersRef, newItemOrders);
      batch.delete(itemRef);
      await batch.commit();
    } catch (error: any) {
      t('アイテムの削除に失敗しました');
    }
  };

  const getItem = (id: string) => items.filter((p) => p.id === id);

  /**
   * 公開設定更新
   * @param id
   * @param isShare
   * @param title
   */
  const updateIsShare = async (
    id: Item['id'],
    isShare: boolean,
    title?: string,
  ) => {
    exec(
      async () =>
        handleItemUpdate({ id, isShare, ...getUpdateSystemFields(userId) }),
      title
        ? t('<title> の公開設定の更新に失敗しました', {
            title,
          })
        : t('公開設定の更新に失敗しました'),
    );
  };

  /**
   * 公開設定更新(一括)
   * @param updateValues
   */
  const bulkUpdateItemIsShare = async (
    updateValues: { id: string; isShare: boolean; title: string }[],
  ) => {
    if (!workspaceId || !projectId)
      throw new Error('update item isShare failed.');
    updateValues.forEach((v) => {
      updateIsShare(v.id, v.isShare, v.title);
    });
  };

  /**
   * カレントプロジェクトの検索キーワード状態保存
   * @param keyword
   * @param itemIds
   */
  const setCurrentSearchState = (
    keyword: string | null,
    filter: SearchFilter | null,
    itemIds: string[],
  ) => {
    // reduxに保存
    dispatch(
      setItemSearchState(
        currentMyWorkspace?.id as string,
        currentMyProject?.id as string,
        keyword,
        filter,
        itemIds,
      ),
    );
  };

  return {
    items,
    currentItemId,
    currentItem,
    generateItemId,
    updateStatus,
    deleteItem,
    getItem,
    getIsOwnItem,
    updateIsShare,
    bulkUpdateItemIsShare,
    currentSearchState,
    setCurrentSearchState,
    dragItem,
    getCreateItem,
    isItemLimitOver,
  };
}
