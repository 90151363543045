import { Property, PropertyOrder } from '@/@types/models';
import { useMemo } from 'react';

/**
 * propertyOrdersによってプロパティをソートするHook
 * @param properties
 * @param propertyOrder
 * @returns {Property[]} ソートされたプロパティ
 */
export default function useOrderedProperties(
  properties: Property[],
  propertyOrder: PropertyOrder,
) {
  // TODO: 可能であれば処理最適化
  return useMemo(() => {
    if (!propertyOrder.orderList || propertyOrder.orderList.length === 0) {
      return [];
    }
    return propertyOrder.orderList.reduce<Property[]>((acc, po) => {
      const found = properties.find((p) => p.id === po);
      if (!found) return acc;

      acc.push(found);

      return acc;
    }, []);
  }, [properties, propertyOrder]);
}
