import { IJoinType } from '@/@types/models';
import { LexicalComposer } from '@lexical/react/LexicalComposer';

import { RootNode } from 'lexical';
import React, {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import { classNames } from '@/libs/styleUtils';

import BaseLexicalEditor, {
  IBaseLexicalEditorHandler,
} from '@/components/Common/Lexical/BaseLexicalEditor';
import { ICommentToolbarHandler } from '@/components/Common/Lexical/CommentToolbar';

import editorConfig from '@/components/Common/Lexical/configs/editorConfig';

import ProjectDescriptionToolbar from '@/components/Common/Lexical/ProjectDescriptionToolbar';

export interface IMentionList {
  id: string;
  value: string;
  name: string;
  optionName?: string;
  photoURL?: string;
  isAnonymous: boolean;
  type?: IJoinType;
}

export interface IMentionTo {
  id: string;
  displayName: string;
  joinType?: IJoinType;
}
// 外部コンポーネントから命令的に実行できるインターフェース
export type IProjectDescriptionEditorHandler = {
  setContent: (content: string) => void;
  setQuoteContent: (content: string) => void;
  setShouldAlert: (shouldAlert: boolean) => void;
  setMentionContent: (id: string, name: string) => void;
  setEditorFocus: () => void;
  resetContent: () => void;
};

type IProjectDescriptionEditorProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  onSend: (jsonState: string, textState: string) => void;
  onEditCancel: () => void;
  handleChange: (
    lexicalRootNode: RootNode,
    isDirty: boolean,
    htmlString: string,
  ) => void;
  isDirty: boolean;
};

/**
 * Lexicalプロジェクト説明エディタ
 * @param props
 * @returns
 */
const ProjectDescriptionEditor = forwardRef<
  IProjectDescriptionEditorHandler,
  IProjectDescriptionEditorProps
>((props: IProjectDescriptionEditorProps, ref) => {
  const toolbarRef = useRef({} as ICommentToolbarHandler);
  const lexicalRef = useRef({} as IBaseLexicalEditorHandler);
  const { className, onSend, onEditCancel, handleChange, isDirty } = props;
  // 選択中の宛先管理用のローカルステート
  /**
   * HACK: stateだと更新時に最新データが取得できないので、値参照用にはrefを用意する.
   * 逆にrefを更新しても宛先コンポーネントで更新内容が反映されないのでstateを使用する.
   * 不格好な組み方だが一旦問題は解決できた.
   */
  // 外部コンポーネントから命令的に実行できるメソッド
  useImperativeHandle(ref, () => ({
    setContent(content: string) {
      if (!lexicalRef.current) return;
      lexicalRef.current.setContent(content);
    },
    resetContent() {
      if (!lexicalRef.current) return;
      lexicalRef.current.resetContent();
    },
    setQuoteContent(content: string) {
      if (!lexicalRef.current) return;
      lexicalRef.current.setQuoteContent(content);
    },
    setMentionContent(id: string, name: string) {
      if (!lexicalRef.current) return;
      lexicalRef.current.setMentionContent(id, name);
    },
    setShouldAlert(st: boolean) {
      toolbarRef.current.setShouldAlert(st);
    },
    setEditorFocus() {
      setTimeout(() => {
        lexicalRef.current.setFocus();
      });
    },
  }));

  // キャンセル時ハンドラ
  const handleEditCancel = useCallback(() => {
    lexicalRef.current.resetContent();
    onEditCancel();
  }, [onEditCancel]);

  // 送信時ハンドラ
  const handleFormSend = useCallback(
    (jsonState: string, textState: string) => {
      onSend(jsonState, textState);
      lexicalRef.current.resetContent();
    },
    [onSend, lexicalRef],
  );

  const initialConfig = {
    namespace: 'ProjectDescriptionEditor',
    ...editorConfig,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div
        className={classNames('editor-container rounded-b-md focus !border-0')}
      >
        <ProjectDescriptionToolbar
          ref={toolbarRef}
          canSend={isDirty}
          onSend={handleFormSend}
          onCancel={handleEditCancel}
        >
          <>
            {/* エディタ本体 */}
            <BaseLexicalEditor
              ref={lexicalRef}
              handleChange={handleChange}
              className={classNames(
                className,
                'max-h-64 md:max-h-72 lg:max-h-80 xl:max-h-96 overflow-y-auto',
              )}
            />
          </>
        </ProjectDescriptionToolbar>
      </div>
    </LexicalComposer>
  );
});
export default ProjectDescriptionEditor;
