import { EV } from '@/@types/events';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import Checkbox from '@/components/Common/Forms/Checkbox';

import { IItemFormDefaultValues } from '@/hooks/useEditItem';

export interface IPropertyCheckboxProps {
  idx: number;
}

export interface ICalContainerProps {
  children: ReactNode;
}

/**
 * プロパティ選択肢用CheckboxInput
 * @param props
 * @returns
 */
export default function PropertyCheckbox(props: IPropertyCheckboxProps) {
  const { idx } = props;
  const { getValues, setValue, trigger } =
    useFormContext<IItemFormDefaultValues>();

  // プロパティ値該当データ取得
  const fields = getValues('properties');
  const property = useMemo(() => {
    const v = fields[idx].booleanValue;
    return v;
  }, [fields[idx].booleanValue, idx]);

  const handleChange = useCallback(
    (e: EV<HTMLInputElement>) => {
      const values = getValues('properties');
      const input = e.currentTarget.checked;
      values[idx].booleanValue = input;
      setValue(`properties.${idx}.booleanValue`, input, {
        shouldDirty: true,
        shouldTouch: true,
      });
      trigger();
    },
    [idx],
  );

  return (
    <div className="w-full h-full">
      <Checkbox
        name={`properties.${idx}`}
        onChange={handleChange}
        checked={property}
      />
    </div>
  );
}
