import { Item, PropertyValue } from '@/@types/models';
import { ClockIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import usePropertyValues from '@/hooks/usePropertyValues';

export interface IDueDateButtonProps {
  className?: string;
  itemId: Item['id'];
  propertyId: PropertyValue['id'];
}

/**
 * 期日アイコンボタン
 * @param props
 * @returns
 */
export default function DueDateButton(props: IDueDateButtonProps) {
  const { t } = useTranslation();
  const { itemId, propertyId, className } = props;
  const { releaseDueFlg } = usePropertyValues();

  const { isReadOnly } = useProjects();
  const { isMember } = useMyWorkspaces();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  // メンバー以外で閲覧権限の場合はボタンを無効化
  const disabled = useMemo(
    () => !isMember && isReadOnly,
    [isMember, isReadOnly],
  );

  return (
    <button
      type="button"
      data-tip={!disabled ? t('クリックで期日設定を解除') : undefined}
      className={classNames(
        'leading-none text-gray-400 dark:text-gray-400 cursor-pointer',
        className,
        !disabled ? 'hover:text-red-500 hover:dark:text-red-600' : '',
      )}
      onClick={(e) => {
        if (disabled) return;
        e.stopPropagation();
        releaseDueFlg(itemId, propertyId);
      }}
      disabled={disabled}
    >
      <ClockIcon className="w-4 h-4" />
    </button>
  );
}
