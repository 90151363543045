import React from 'react';

import { classNames } from '@/libs/styleUtils';

export interface IDividerProps {
  className?: string;
  label?: string;
}

/**
 * 罫線
 * @param props
 * @returns
 */
export default function Divider(props: IDividerProps) {
  const { className, label } = props;

  return (
    <div>
      {label ? (
        <div className={classNames('relative', className)}>
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-200 dark:border-gray-700" />
          </div>
          <div className="relative flex justify-center">
            <span className="px-2 text-sm text-gray-400 bg-white dark:text-gray-500 dark:bg-gray-800">
              {label}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            'w-full border-t border-gray-200 dark:border-gray-700',
            className,
          )}
        />
      )}
    </div>
  );
}
