import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

/**
 * reCAPTCHA v3
 * @returns { exec }
 */
export default function useReCaptcha(action: string) {
  const [token, setToken] = useState<string | null | undefined>(null); // reCAPTCHA Token
  const { executeRecaptcha } = useGoogleReCaptcha();

  const clearToken = () => {
    setToken(null);
  };

  const refreshToken = useCallback(async () => {
    if (!executeRecaptcha) {
      clearToken();
      return;
    }
    setToken(await executeRecaptcha(action));
  }, [executeRecaptcha]);

  useEffect(() => {
    refreshToken();
  }, [executeRecaptcha]);

  return { refreshToken, clearToken, reCaptchaToken: token };
}
