import { useMemo } from 'react';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useProjects from '@/hooks/useProjects';
import useViews from '@/hooks/useViews';

export default function useJoinType() {
  const { myProjects } = useProjects();
  const { views } = useViews();

  const { isMember } = useMyWorkspaces();

  const projectsByJoinType = useMemo(() => {
    const filterProjects = [...myProjects];
    return filterProjects;
  }, [myProjects]);

  const viewsByJoinType = useMemo(() => {
    let filterViews;
    if (isMember) {
      filterViews = [...views];
    } else {
      filterViews = views.filter((v) => v.isGuestAccessible === true);
    }
    return filterViews;
  }, [isMember, views]);

  return { projectsByJoinType, viewsByJoinType };
}
