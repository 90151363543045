import React, { useRef, useMemo, useCallback } from 'react';

import { useFormContext, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DestinationMultiSelect, {
  IMultiSelectHandler,
  ISelectOption,
} from '@/components/Common/Forms/DestinationMultiSelect';

import { ISearchFilter } from '@/hooks/useFilters';
import useMentions from '@/hooks/useMentions';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';

export interface IFilterUserProps {
  name?: 'createdBys' | 'updatedBys';
  idx?: number;
  methods?: UseFormReturn<ISearchFilter, any>;
  placeHolder: string;
}

/**
 * フィルタ用User
 * @param props
 * @returns
 */
export default function FilterUser(props: IFilterUserProps) {
  const { name, methods, idx, placeHolder } = props;
  const { t } = useTranslation();
  const selectedToListRef = useRef<ISelectOption[]>([]);
  const multiSelectRef = useRef({} as IMultiSelectHandler);
  const { memberMentions, guestMentions } = useMentions();
  const { setValue, trigger, getValues } = useFormContext<ISearchFilter>();
  const { removedMemberMentions } = useRemovedMembers();
  const { removedGuestMentions } = useRemovedGuests();

  const zodName: any = useMemo(() => {
    if (name) {
      return `basicFilter.${name}`;
    }
    return `searchProperties.${idx}.arrayValue`;
  }, [idx, name]);

  const fields = getValues(zodName) as string[];

  const displayUserList = useMemo(
    () => [
      ...removedMemberMentions,
      ...memberMentions,
      ...removedGuestMentions,
      ...guestMentions,
    ],
    [
      memberMentions,
      removedMemberMentions,
      guestMentions,
      removedGuestMentions,
    ],
  );

  //  担当者リスト
  const inchargeList = useMemo<ISelectOption[]>(() => {
    const concatMentions = memberMentions.concat(guestMentions);
    const uniqueMentions = Array.from(
      concatMentions
        .reduce((map, item) => map.set(item.id, item), new Map())
        .values(),
    );
    return uniqueMentions;
  }, [memberMentions, guestMentions]);

  // メンションのセレクト用選択肢(removedUserを含まない)
  const optionList = useMemo(() => {
    const mapped = inchargeList.map((m) => ({
      ...m,
      unavailable: false,
    }));
    return [...mapped];
  }, [inchargeList]);

  const handleChange = useCallback(
    (selectedItems: ISelectOption[]) => {
      const stringArray = selectedItems.map((i) => i.id);
      if (methods) {
        methods.setValue(zodName, stringArray, {
          shouldDirty: true,
          shouldTouch: true,
        });
        methods.trigger();
      } else {
        setValue(zodName, stringArray, {
          shouldDirty: true,
          shouldTouch: true,
        });
        trigger();
      }
    },
    [zodName],
  );
  const selectedOptions = useMemo<ISelectOption[]>(
    () =>
      fields.reduce<ISelectOption[]>((acc, po) => {
        const found = displayUserList.find((v) => v.id === po);
        if (!found) return acc;
        const id = found?.id;
        if (id) {
          acc.push({
            id,
            photoURL: found.photoURL || undefined,
            value: found.value || '',
            name: found.name || '',
          });
        }
        return acc;
      }, []),
    [fields, displayUserList],
  );

  return (
    selectedOptions && (
      <div className="w-full h-full">
        <DestinationMultiSelect
          ref={multiSelectRef}
          placeholder={t(placeHolder)}
          selectedOptions={selectedOptions}
          optionDirection="top"
          optionList={optionList}
          showIcon
          onChangeSelected={(selectedItems) => {
            selectedToListRef.current = [...selectedItems];
            handleChange(selectedItems);
          }}
        />
      </div>
    )
  );
}
