import { Comment, RRFUploadedFile, View } from '@/@types/models';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DayjsUtil, FormatType } from '@/libs/dayjs';
import { classNames } from '@/libs/styleUtils';

import { getNavigatePath, parseFsTimestamp, t2s } from '@/libs/utils';

import { openItemEditDialog } from '@/reducers/itemsReducer';

import AvatarIcon from '@/components/Common/AvatarIcon';

import LexicalReadOnlyEditor from '@/components/Common/Lexical/LexicalReadOnlyEditor';

import CommentFileItem from '@/components/Items/Comments/CommentFileItem';

import useCommentFiles from '@/hooks/useCommentFiles';
import useItems from '@/hooks/useItems';
import useMentions from '@/hooks/useMentions';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';
import useUnknownGuests from '@/hooks/useUnknownGuests';
import useViews from '@/hooks/useViews';

export interface IAllCommentItemProps {
  comment: Comment;
  className?: string;
  closeDrawer: (value: boolean) => void;
}

/**
 * 全てのコメント アイテム
 * @param props
 * @returns
 */
export default function AllCommentItem(props: IAllCommentItemProps) {
  const { t } = useTranslation();
  const { comment, className, closeDrawer } = props;
  const { items } = useItems();
  const navigate = useNavigate();
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject } = useProjects();
  const { getProjectTopView } = useViews();
  const dispatch = useDispatch();
  const { membersDic, guestsDic } = useMentions();
  const { removedMembersDic } = useRemovedMembers();
  const { removedGuestsDic } = useRemovedGuests();
  const { unknownGuestsDic } = useUnknownGuests();
  const { currentCommentFiles } = useCommentFiles([comment]);

  /** 親アイテム */
  const parentItem = useMemo(
    () => items.find((it) => it.id === comment.itemId),
    [comment, items],
  );

  const commentFiles = useMemo(
    () => currentCommentFiles[comment?.id as string],
    [currentCommentFiles, comment],
  );

  /** 枠クリック */
  const handleClick = useCallback(() => {
    // クリック時ハンドラ
    const view = getProjectTopView(currentMyProject?.id) as View;
    navigate(
      getNavigatePath(
        currentMyWorkspace?.id as string,
        currentMyProject?.id as string,
        view.id,
        parentItem?.id as string,
      ),
      {
        replace: true,
      },
    );
    dispatch(openItemEditDialog(parentItem?.id as string));
    closeDrawer(false);
  }, [comment, parentItem, currentMyWorkspace, currentMyProject]);

  /** 投稿日時 */
  const postedAtString = useMemo(() => {
    const _postedAt: DayjsUtil = new DayjsUtil(
      parseFsTimestamp(comment.postedAt),
    );
    return _postedAt?.format(t('YYYY-MM-DD HH-mm') as FormatType);
  }, [comment]);

  /** ユーザー辞書 */
  const userDic = useMemo(
    () => ({
      ...unknownGuestsDic,
      ...removedGuestsDic,
      ...removedMembersDic,
      ...guestsDic,
      ...membersDic,
    }),
    [
      unknownGuestsDic,
      removedGuestsDic,
      removedMembersDic,
      guestsDic,
      membersDic,
    ],
  );

  /** 投稿ユーザー */
  const user = useMemo(
    () => (comment?.postedBy ? userDic[comment?.postedBy as string] : null),
    [comment, userDic],
  );

  return (
    <li
      className={classNames(
        'relative mx-1 my-1 bg-white rounded-lg shadow group dark:bg-gray-800 col-span-1 hover:ring-2 hover:ring-offset-0 hover:ring-primary-500 dark:hover:ring-primary-600',
        className,
      )}
    >
      <div
        role="button"
        className="w-full text-left"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={() => {}}
      >
        <div className="flex items-start w-full p-5 space-x-6">
          <AvatarIcon
            src={user?.photoURL || ''}
            avatarName={user?.displayName || ''}
            size="md"
            isAnonymous={!user}
          />

          <div className="flex-1">
            <div className="flex items-center space-x-3">
              <h3 className="text-sm font-bold text-gray-900 break-all dark:text-gray-300">
                {t2s(
                  t('<displayName> が <title> にコメントしました。', {
                    displayName: user?.displayName,
                    title: parentItem?.title,
                  }),
                )}
              </h3>
            </div>
            <div className="mt-2 text-gray-600 dark:text-gray-400">
              {/* コメント */}
              <div className="break-all">
                {comment.editorState && (
                  <LexicalReadOnlyEditor initialData={comment.editorState} />
                )}
              </div>
              {/* 画像表示欄 */}
              {commentFiles && commentFiles.length !== 0 && (
                <div className="flex flex-col pt-1 mt-1 gap-3">
                  {commentFiles.map((file: RRFUploadedFile) => (
                    <CommentFileItem src={file} key={file.id} />
                  ))}
                </div>
              )}
            </div>
            <p className="mt-2 text-xs text-gray-500 truncate">
              {postedAtString}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
}
