import { IFuncCopyProject } from '@/@types/common';
import { EV } from '@/@types/events';
import { MyProject } from '@/@types/models';
import { IModalDialogProps } from '@/@types/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import { z } from 'zod';

import {
  FUNCTIONS_ERROR_DETAILS,
  JOIN_TYPE,
  PROJECT_COPY_ITEM_COUNT,
  PROJECT_COPY_TARGET_TYPE,
  VALIDATION_MODE,
} from '@/libs/const';

import ProjectRepository from '@/repositories/ProjectRepository';

import Alert from '@/components/Common/Alert';
import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import FormCol from '@/components/Common/Forms/FormCol';

import RadioGroup, { RadioItem } from '@/components/Common/Forms/RadioGroup';

import Select, { ISelectOption } from '@/components/Common/Forms/Select';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

interface ICopyProjectDialogProps extends IModalDialogProps {
  myProject: MyProject;
}

/**
 * プロジェクト複製ダイアログ
 * @param props
 * @returns
 */
export default function CopyProjectDialog(props: ICopyProjectDialogProps) {
  const { onClose, myProject } = props;
  const { t } = useTranslation();
  const { currentMyWorkspace, myWorkspaces, isMember } = useMyWorkspaces();

  const projectRepository = new ProjectRepository(
    currentMyWorkspace?.id as string,
  );

  // ターゲットとなるワークスペース
  const targetWorkspaceOptions = useMemo(
    () =>
      myWorkspaces
        .filter((w) => w.joinType === JOIN_TYPE.MEMBER) // メンバー参加している
        .map(
          (w) =>
            ({
              label: w.workspaceName as string,
              value: w.id as string,
            } as ISelectOption),
        ),
    [myWorkspaces],
  );

  // Zod schema定義
  const schema = z
    .object({
      targetType: z.nativeEnum(PROJECT_COPY_TARGET_TYPE),
      targetWorkspace: z
        .string()

        .nullable(),
    })
    .refine(
      (data) =>
        data.targetType === PROJECT_COPY_TARGET_TYPE.CURRENT ||
        (data.targetType === PROJECT_COPY_TARGET_TYPE.OTHER &&
          !!data.targetWorkspace),
      {
        message: t('複製先のワークスペースを選択してください'),
        path: ['targetWorkspace'],
      },
    );

  type SchemaType = z.infer<typeof schema>;

  // Zod Form
  const methods = useForm<SchemaType>({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      // eslint-disable-next-line no-nested-ternary
      targetType: isMember
        ? PROJECT_COPY_TARGET_TYPE.CURRENT
        : targetWorkspaceOptions.length
        ? PROJECT_COPY_TARGET_TYPE.OTHER
        : undefined,
      // eslint-disable-next-line no-nested-ternary
      targetWorkspace: isMember
        ? (currentMyWorkspace?.id as string)
        : targetWorkspaceOptions.length
        ? targetWorkspaceOptions[0].value
        : undefined,
    },
  });
  const watchFields = methods.watch(['targetType']);

  // Submitボタン状態（初期状態でも送信可能にする必要があるため useSubmitState は使わない）
  const isDisabledApply = useMemo(
    () => methods.formState.isSubmitting || !methods.formState.isValid,
    [methods.formState.isSubmitting, methods.formState.isValid],
  );

  // 複製先区分がotherであるか
  const disabledSelectWorkspace = useMemo(
    () => methods.getValues('targetType') !== PROJECT_COPY_TARGET_TYPE.OTHER,
    [watchFields],
  );

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    try {
      const req: IFuncCopyProject = {
        projectId: myProject.id,
        toWorkspaceId: '',
      };
      if (data.targetType === PROJECT_COPY_TARGET_TYPE.CURRENT) {
        req.toWorkspaceId = currentMyWorkspace?.id;
      } else {
        req.toWorkspaceId = data.targetWorkspace as string;
      }
      await projectRepository.copyProject(req);
      toast.success(t('プロジェクトが複製されました'));
      onClose();
    } catch (error: any) {
      if (
        [
          FUNCTIONS_ERROR_DETAILS.PROJECT_CREATION_LIMIT_EXCEEDED,
          FUNCTIONS_ERROR_DETAILS.WORKSPACE_IS_FREE_PLAN,
        ].includes(error.details)
      ) {
        toast.error(
          t(
            '複製先のプロジェクト数が上限を超えているか、プランによる制限により作成に失敗しました',
          ),
        );
      } else {
        toast.error(t('プロジェクトの複製に失敗しました'));
        throw error;
      }
    }
  });

  const handleTargetWorkspaceChange = useCallback(
    (event: EV<HTMLSelectElement>) => {
      methods.setValue('targetWorkspace', event.currentTarget.value as string, {
        shouldDirty: true,
        shouldTouch: true,
      });
      methods.trigger('targetWorkspace');
    },
    [],
  );

  /** 対象ワークスペース 選択肢 */
  const targetType: RadioItem[] = useMemo(
    () => [
      {
        id: 'currentWorkspace',
        label: t('現在のワークスペースへ複製する'),
        value: PROJECT_COPY_TARGET_TYPE.CURRENT,
        disabled: !isMember,
      },
      {
        id: 'otherWorkspace',
        label: t('ワークスペースを選択して複製する'),
        value: PROJECT_COPY_TARGET_TYPE.OTHER,
        disabled: targetWorkspaceOptions.length === 0,
      },
    ],
    [isMember, targetWorkspaceOptions],
  );

  // マウント時にバリデーション実行
  useEffect(() => {
    methods.trigger();
  }, []);

  return (
    <FormDialog
      onClose={onClose}
      title={t('プロジェクトを複製')}
      onSubmit={formSubmit}
      top
    >
      <FormProvider {...methods}>
        <Alert color="gray">
          {t(
            '<projectName>の<underLineWord>プロジェクトが複製されます。また、プロジェクト複製時に、サンプルアイテムが<projectCopyItemCount>つ作成されます。',
            {
              projectName: (
                <span className="font-bold">{myProject.projectName}</span>
              ),
              underLineWord: (
                <span className="underline">
                  {t('ビュー、ステータス、プロパティの設定のみ引き継いだ')}
                </span>
              ),
              projectCopyItemCount: PROJECT_COPY_ITEM_COUNT,
            },
          )}
        </Alert>
        <FormCol>
          <RadioGroup name="targetType" items={targetType} />
        </FormCol>
        {methods.getValues('targetType') === PROJECT_COPY_TARGET_TYPE.OTHER && (
          <>
            <div className="text-gray-500 dark:text-gray-400 col-start-1 col-span-3">
              <small>{t('メンバーとして参加しているワークスペース')}</small>
            </div>
            <FormCol>
              <Select
                name="targetWorkspace"
                value={methods.getValues('targetWorkspace') || undefined}
                options={targetWorkspaceOptions}
                disabled={disabledSelectWorkspace}
                onChange={handleTargetWorkspaceChange}
              />
            </FormCol>
          </>
        )}
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="copyProject"
          disabled={isDisabledApply}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('複製')}
        </FormButton>
        <FormButton
          id="cancelCopyProject"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
