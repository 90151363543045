import { IFirestoreQuery } from '@/@types/common';
import { MyProject, MyWorkspace, Project, Auth } from '@/@types/models';
import { collection, getDocs, query } from 'firebase/firestore';

import { REP_ERROR_MESSAGE } from '@/libs/const';

import { getMyProjectsPath } from '@/libs/docPathUtils';
import { composeAppendQuery } from '@/libs/repositoryUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { firestore } from '@/firestore';

export interface IMyProjectRepository {
  accountId: Auth['uid'];
  myWorkspaceId: MyWorkspace['id'];
  list: (params: IFirestoreQuery<MyProject>) => Promise<Project[]>;
}

export default class MyProjectRepository implements IMyProjectRepository {
  accountId: Auth['uid'];

  myWorkspaceId: MyWorkspace['id'];

  constructor(private acId: string, private wsid: string) {
    this.accountId = acId;
    this.myWorkspaceId = wsid;
  }

  /**
   * List project docs
   * @returns Promise<MyProject[]>
   */
  async list(params?: IFirestoreQuery<MyProject>): Promise<MyProject[]> {
    const appends = composeAppendQuery(params);
    try {
      const path = getMyProjectsPath(this.accountId, this.myWorkspaceId);
      const collectionRef = query(
        collection(firestore, path).withConverter(fsConverter<MyProject>()),
        ...appends,
      );
      return (await getDocs(collectionRef)).docs.map((d) => {
        const project = d.data();
        project.id = d.id;
        return project;
      });
    } catch (err: any) {
      repositoryError(this.list.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }
}
