import { OriginalColorType } from '@/@types/common';
import { Listbox } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import React, { useCallback } from 'react';

import { PickerBadge } from '@/components/Common/PickerBadge';

import PopupTransition from '@/components/Common/Transitions/PopupTransition';

export interface ColorSingleSelectOption {
  id: string;
  text: string;
  color: OriginalColorType;
}

interface IColorSingleSelectProps {
  onSelect: (value: string) => void;
  options: ColorSingleSelectOption[];
  selected?: ColorSingleSelectOption;
  prependEmpty?: boolean;
}

export default function ColorSingleSelect(props: IColorSingleSelectProps) {
  const { onSelect, selected, prependEmpty, options } = props;

  const listBoxClass = useCallback(
    ({ active }) =>
      `cursor-pointer select-none relative py-2 pl-2 pr-4 ${
        active
          ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
          : ''
      }`,
    [],
  );
  return (
    <div className="w-full h-full">
      <Listbox value={selected?.text || ''} onChange={onSelect}>
        {({ open }) => (
          <div className="relative h-full">
            <Listbox.Button className="relative block w-full h-full px-3 pl-8 text-left text-gray-700 bg-white border border-gray-300 dark:bg-gray-700 focus:outline-none rounded-md dark:border-gray-600 shadow-sm dark:text-gray-300 hover:bg-gray-50 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 min-h-[2.4rem] max-h-[2.4rem]">
              <span className="absolute inset-y-0 left-0 flex items-center w-full pointer-events-none">
                <PickerBadge
                  size="xs"
                  className="my-auto ml-2"
                  color={selected?.color}
                />
              </span>
              <span className="block truncate">{selected?.text || ''}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-4 h-4 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <PopupTransition show={open}>
              <Listbox.Options className="absolute z-30 w-full py-1 mt-1 overflow-auto bg-white shadow-lg dark:bg-gray-700 rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {prependEmpty && (
                  <Listbox.Option className={listBoxClass} value={null}>
                    <div className="pl-2">&nbsp;</div>
                  </Listbox.Option>
                )}
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={listBoxClass}
                    value={option.id}
                  >
                    <div className="flex items-center w-full">
                      <PickerBadge size="xs" color={option.color} />
                      <div className="w-full pl-2 truncate">
                        <span className=" align-middle">{option.text}</span>
                      </div>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </PopupTransition>
          </div>
        )}
      </Listbox>
    </div>
  );
}
