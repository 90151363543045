import { RootState } from '@/@types/models';
import { zodResolver } from '@hookform/resolvers/zod';
import { $isLinkNode, LinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { t } from 'i18next';

import {
  SELECTION_CHANGE_COMMAND,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  RangeSelection,
  NodeSelection,
  GridSelection,
  $getNodeByKey,
  TextNode,
} from 'lexical';
import React, { useRef, useState, useCallback, useEffect } from 'react';

import { createPortal } from 'react-dom';

import { useForm, FormProvider } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';
import { zodString, t2s } from '@/libs/utils';
import { LINK_URL } from '@/libs/validations';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';
import {
  LowPriority,
  getSelectedNode,
  isLinkNode,
  isTextNode,
} from '@/components/Common/Lexical/utils/lexicalUtils';
import useLexicalMonitor from '@/components/Common/Lexical/utils/useLexicalMonitor';

import useSubmitState from '@/hooks/useSubmitState';

interface IPosition {
  opacity: string;
  top: string;
  zIndex: string;
  right?: string;
  left?: string;
}

const initialStyle: IPosition = {
  opacity: '0',
  top: '-1000px',
  left: '-1000px',
  zIndex: '40',
};

/**
 * エディターの位置調整
 * @param editor
 * @param rect
 * @returns
 */
export const positionEditorElement = (
  editor: HTMLDivElement,
  rect: DOMRect | null,
): IPosition => {
  if (rect === null) {
    return {
      opacity: '0',
      top: '-1000px',
      left: '-1000px',
      zIndex: initialStyle.zIndex,
    };
  }

  // 左位置
  const left =
    rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2;

  // 下のはみ出しを判定
  let top = rect.top + rect.height + window.pageYOffset + 10;
  if (top + editor.offsetHeight > document.body.clientHeight) {
    top -= editor.offsetHeight + rect.height + 10;
  }
  // 右のはみ出しを判定
  let right = left + editor.offsetWidth;
  if (right > document.body.clientWidth) right = 0;

  const pos: IPosition = {
    opacity: '1',
    top: `${top}px`,
    zIndex: initialStyle.zIndex,
  };

  if (right === 0) {
    // 右がはみ出したので調整して左をautoにする
    pos.right = '0px';
    pos.left = 'auto';
  } else {
    pos.left = `${left < 0 ? 0 : left}px`;
    pos.right = 'auto';
  }

  return pos;
};

type SomeHTMLElement<T extends HTMLDivElement> = T;

const useLinkEditor = (editor: LexicalEditor, show: boolean) => {
  const { isLink } = useLexicalMonitor(editor);
  const { isImageOpen } = useSelector((state: RootState) => state.items);
  const editorRef = useRef<SomeHTMLElement<HTMLDivElement> | null>(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [linkTitle, setLinkTitle] = useState('');
  const [lastSelection, setLastSelection] = useState<
    RangeSelection | NodeSelection | GridSelection | null
  >(null);
  const [lastNodeKey, setLastNodeKey] = useState('');
  const [editorStyle, setEditorStyle] = useState({ ...initialStyle });
  // useEffectでフォーム値の更新を制御するためのフラグ
  const [disableUpdateValue, setDisableUpdateValue] = useState<boolean>(false);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      const content = node.getTextContent();
      setLastNodeKey(node.getKey());
      setLinkTitle(content);
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const editorElem = editorRef.current;
    const rootElement = editor.getRootElement();
    const nativeSelection = window.getSelection()! as Selection;
    const { activeElement } = document;

    let _editorStyle = { ...initialStyle };

    if (editorElem === null) return false;

    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      isLink &&
      isImageOpen === false
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect: DOMRect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner: HTMLElement | Element = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        _editorStyle = positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      _editorStyle = positionEditorElement(editorElem, null);
      setLastSelection(null);
      setLinkUrl('');
    }

    setEditorStyle(_editorStyle);
    return true;
  }, [editor, isLink, isImageOpen]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor();
            return true;
          },
          LowPriority,
        ),
      ),
    [editor, updateLinkEditor],
  );

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  /**
   * 該当LinkNodeのURLと表示名を変更
   */
  const onAcceptEditLink = useCallback(
    (title: string, url: string) => {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
      editor.update(() => {
        // TextNodeが取れる場合とLinkNodeが取れる場合がある
        const lexicalNode = $getNodeByKey<TextNode | LinkNode>(lastNodeKey);
        if (lexicalNode) {
          if (isLinkNode(lexicalNode)) {
            // LinkNodeの場合はもう一段階子供のNodeをとる
            const childNode = lexicalNode.getLastChild();
            if (childNode && isTextNode(childNode)) {
              childNode.setTextContent(title);
            }
          } else if (isTextNode(lexicalNode)) {
            lexicalNode.setTextContent(title);
          }
        }
      });
    },
    [editor, lastSelection, lastNodeKey],
  );

  const schema = z.object({
    linkTitle: zodString(
      z
        .string()
        .min(1, { message: t('入力してください') })
        .max(LINK_URL.max, t2s(t('<max>文字以内で入力してください', LINK_URL))),
    ),
    linkUrl: zodString(
      z
        .string()
        .min(1, { message: t('入力してください') })
        .max(LINK_URL.max, t2s(t('<max>文字以内で入力してください', LINK_URL))),
    ),
  });

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  useEffect(() => {
    if (disableUpdateValue) return;
    methods.reset({
      linkTitle,
      linkUrl,
    });
  }, [linkUrl, linkTitle]);

  // mount時処理
  useEffect(() => {
    const s = methods.setFocus;
    setTimeout(() => s('linkUrl'));
  }, []);

  const closeEditor = useCallback(() => {
    if (editorRef.current) {
      const _editorStyle = positionEditorElement(editorRef.current, null);
      setTimeout(() => {
        setDisableUpdateValue(false);
        setEditorStyle(_editorStyle);
      }, 100);
    }
  }, [editorRef]);

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    setDisableUpdateValue(true);
    const { linkTitle: _linkTitle, linkUrl: _linkUrl } = data;
    methods.reset(data);
    onAcceptEditLink(_linkTitle, _linkUrl);
    closeEditor();
  });

  const onDeleteLink = useCallback(() => {
    setDisableUpdateValue(true);
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    closeEditor();
  }, []);

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return createPortal(
    <div
      ref={editorRef}
      className="overflow-hidden link-editor"
      style={{ ...editorStyle }}
      hidden={!show}
    >
      <form
        onSubmit={(e) => {
          formSubmit(e);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className="px-2 pb-2 sm:px-4 sm:pb-4">
          <div className="mt-4 space-y-5">
            <FormProvider {...methods}>
              <FormCol>
                <Input name="linkTitle" label={t('表示するリンク名')} />
              </FormCol>
              <FormCol>
                <Input name="linkUrl" label={t('リンク')} />
              </FormCol>
            </FormProvider>
          </div>
        </div>
        <div className="px-4 py-4 dark:border-t bg-gray-50 dark:bg-gray-800 dark:border-gray-700  sm:flex sm:flex-row-reverse">
          <FormProvider {...methods}>
            <FormButton
              id="changeEditorLink"
              disabled={isDisabledApply}
              variant="primary"
              submit
              className="sm:w-auto sm:ml-3"
            >
              {t('更新')}
            </FormButton>

            <FormButton
              id="deleteEditorLink"
              className="mt-2 mr-3 sm:mr-0 sm:w-auto"
              onClick={onDeleteLink}
            >
              {t('削除')}
            </FormButton>
          </FormProvider>
        </div>
      </form>
    </div>,
    document.body,
  );
};

interface ILinkEditorPluginProps {
  show: boolean;
}

export default function LinkEditorPlugin(
  props: ILinkEditorPluginProps,
): JSX.Element | null {
  const [editor] = useLexicalComposerContext();
  const { show } = props;
  return useLinkEditor(editor, show);
}
