import {
  Auth,
  Item,
  Project,
  Workspace,
  Comment,
  Property,
} from '@/@types/models';

export const COL_ACCOUNTS = 'accounts' as const;
export const COL_WORKSPACES = 'workspaces' as const;
export const COL_PROJECTS = 'projects' as const;
export const COL_PLANS = 'plans' as const;
export const COL_PLAN_PROTECTIONS = 'planProtections' as const;
export const COL_MEMBERS = 'members' as const;
export const COL_X_MEMBERS = '_members' as const;
export const COL_ADMIN_MEMBERS = 'adminMembers' as const;
export const COL_INVITING_MEMBERS = 'invitingMembers' as const;
export const COL_REMOVED_MEMBERS = 'removedMembers' as const;
export const COL_GUESTS = 'guests' as const;
export const COL_X_GUESTS = '_guests' as const;
export const COL_INVITING_GUESTS = 'invitingGuests' as const;
export const COL_TEMP_GUESTS = 'tempGuests' as const;
export const COL_REMOVED_GUESTS = 'removedGuests' as const;
export const COL_UNKNOWN_GUESTS = 'unknownGuests' as const;
export const COL_MY_PROJECTS_UNKNOWN_GUESTS =
  'myProjectsUnknownGuests' as const;
export const COL_STATUSES = 'statuses' as const;
export const COL_STATUS_ORDERS = 'statusOrders' as const;
export const COL_PROPERTIES = 'properties' as const;
export const COL_PROPERTY_ORDERS = 'propertyOrders' as const;
export const COL_VIEWS = 'views' as const;
export const COL_ACCESS_SETTINGS = 'accessSettings' as const;
export const COL_PRESENCES = 'presences' as const;
export const COL_ITEMS = 'items' as const;
export const COL_BODY = 'bodies' as const;
export const COL_PROPERTY_VALUES = 'propertyValues' as const;
export const COL_PROPERTY_VALUE_FILES = 'propertyValueFiles' as const;
export const COL_COMMENTS = 'comments' as const;
export const COL_LIKED_USERS = 'likedUsers' as const;
export const COL_MY_WORKSPACES = 'myWorkspaces' as const;
export const COL_PROFILES = 'profiles' as const;
export const COL_MY_PROJECTS = 'myProjects' as const;
export const COL_TEMP_FILES = 'tempFiles' as const;
export const COL_COMMENT_FILES = 'commentFiles' as const;
export const COL_AVATAR_FILES = 'avatarFiles' as const;
export const COL_TO_COMMENTS = 'toComments' as const;
export const COL_ADMIN = 'admin' as const;
export const COL_CHECKOUT_SESSIONS = 'checkoutSessions' as const;
export const COL_SLACK_INTEGRATIONS = 'slackIntegrations' as const;
export const COL_LIKED_ITEMS = 'likedItems' as const;
export const COL_ACCOUNT_STATUSES = 'accountStatuses' as const;
export const COL_COMMENT_REACTIONS = 'commentReactions' as const;
export const COL_PROJECT_DESCRIPTIONS = 'projectDescriptions' as const;
export const COL_ITEM_ORDERS = 'itemOrders' as const;
export const COL_MEMBER_VIEW_ITEMS = 'memberViewItems' as const;
export const COL_GUEST_VIEW_ITEMS = 'guestViewItems' as const;

/**
 * admin
 * - docID は 用途タイプ ADMIN_DOC_TYPE
 * @returns {string}
 */
export const getAdminPath = () => COL_ADMIN;

/**
 * accounts
 * - docID は uid
 * @returns {string}
 */
export const getAccountsPath = () => COL_ACCOUNTS;

/**
 * accountStatuses
 * - docID は uid
 * @param uid
 * @returns {string}
 */
export const getAccountStatusesPath = (uid: Auth['uid']) =>
  `${getAccountsPath()}/${uid}/${COL_ACCOUNT_STATUSES}` as const;

/**
 * workspaces
 * - docID は workspaceId
 * @returns {string}
 */
export const getWorkspacesPath = () => COL_WORKSPACES;

/**
 * projects
 * - docID は 発番
 * @param workspaceId
 * @returns {string}
 */
export const getProjectsPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_PROJECTS}` as const;

/**
 * plans
 * - docID は workspaceId
 * @param workspaceId
 * @returns {string}
 */
export const getPlansPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_PLANS}` as const;

/**
 * adminMembers
 * - docID は workspaceId
 * @param workspaceId
 * @returns {string}
 */
export const getPlanProtectionsPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_PLAN_PROTECTIONS}` as const;

/**
 * members
 * - docID は uid
 * @param workspaceId
 * @returns {string}
 */
export const getMembersPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_MEMBERS}` as const;

/**
 * _members
 * - docID は uid
 * @param workspaceId
 * @returns {string}
 */
export const getXMembersPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_X_MEMBERS}` as const;

/**
 * adminMembers
 * - docID は workspaceId
 * @param workspaceId
 * @returns {string}
 */
export const getAdminMembersPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_ADMIN_MEMBERS}` as const;

/**
 * invitingMembers
 * - docID は workspaceId
 * @param workspaceId
 * @returns {string}
 */
export const getInvitingMembersPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_INVITING_MEMBERS}` as const;

/**
 * removedMembers
 * - docID は workspaceId
 * @param workspaceId
 * @returns {string}
 */
export const getRemovedMembersPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_REMOVED_MEMBERS}` as const;

/**
 * guests
 * - docID は uid
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getGuestsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_PROJECTS}/${projectId}/${COL_GUESTS}` as const;

/**
 * _guests
 * - docID は uid
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getXGuestsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_PROJECTS}/${projectId}/${COL_X_GUESTS}` as const;

/**
 * invitingGuests
 * - docID は 発番
 * @param workspaceId
 * @returns {string}
 */
export const getInvitingGuestsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getProjectsPath(
    workspaceId,
  )}/${projectId}/${COL_INVITING_GUESTS}` as const;

/**
 * tempGuests
 * - docID は 発番
 * @param workspaceId
 * @returns {string}
 */
export const getTempGuestsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) => `${getProjectsPath(workspaceId)}/${projectId}/${COL_TEMP_GUESTS}` as const;

/**
 * removedGuests
 * - docID は uid
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getRemovedGuestsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getProjectsPath(workspaceId)}/${projectId}/${COL_REMOVED_GUESTS}` as const;

/**
 * unknownGuests
 * - docID は uid
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getUnknownGuestsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getProjectsPath(workspaceId)}/${projectId}/${COL_UNKNOWN_GUESTS}` as const;

/**
 * statuses
 * - docID は 発番
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getStatusesPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) => `${getProjectsPath(workspaceId)}/${projectId}/${COL_STATUSES}` as const;

/**
 * statusOrders
 * - docID は projectId
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getStatusOrdersPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getProjectsPath(workspaceId)}/${projectId}/${COL_STATUS_ORDERS}` as const;

/**
 * properties
 * - docID は 発番
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getPropertiesPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) => `${getProjectsPath(workspaceId)}/${projectId}/${COL_PROPERTIES}` as const;

/**
 * propertyOrders
 * - docID は projectId
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getPropertyOrdersPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getProjectsPath(
    workspaceId,
  )}/${projectId}/${COL_PROPERTY_ORDERS}` as const;

/**
 * views
 * - docID は 発番
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getViewsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) => `${getProjectsPath(workspaceId)}/${projectId}/${COL_VIEWS}` as const;

/**
 * accessSettings
 * - docID は projectId
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getAccessSettingsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getProjectsPath(
    workspaceId,
  )}/${projectId}/${COL_ACCESS_SETTINGS}` as const;

/**
 * presences
 * - docID は uid
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getPresencesPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) => `${getProjectsPath(workspaceId)}/${projectId}/${COL_PRESENCES}` as const;

/**
 * items
 * - docID は 発番
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getItemsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) => `${getProjectsPath(workspaceId)}/${projectId}/${COL_ITEMS}` as const;

/**
 * bodies
 * - docID は itemId
 * @param workspaceId
 * @param projectId
 * @param itemId
 * @returns {string}
 */
export const getBodiesPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
  itemId: Item['id'],
) => `${getItemsPath(workspaceId, projectId)}/${itemId}/${COL_BODY}` as const;

/**
 * propertyValues
 * - docID は propertyId
 * @param workspaceId
 * @param projectId
 * @param itemId
 * @returns {string}
 */
export const getPropertyValuesPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
  itemId: Item['id'],
) =>
  `${getItemsPath(
    workspaceId,
    projectId,
  )}/${itemId}/${COL_PROPERTY_VALUES}` as const;

/**
 * propertyValueFiles
 * - docID は 発番
 * @param workspaceId
 * @param projectId
 * @param itemId
 * @returns {string}
 */
export const getPropertyValueFilesPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
  itemId: Item['id'],
  propertyId: Property['id'],
) =>
  `${getItemsPath(
    workspaceId,
    projectId,
  )}/${itemId}/${COL_PROPERTY_VALUES}/${propertyId}/${COL_PROPERTY_VALUE_FILES}` as const;

/**
 * comments
 * - docID は 発番
 * @param workspaceId
 * @param projectId
 * @param itemId
 * @returns {string}
 */
export const getCommentsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
  itemId: Item['id'],
) =>
  `${getItemsPath(workspaceId, projectId)}/${itemId}/${COL_COMMENTS}` as const;

/**
 * likedUsers
 * - docID は uid
 * @param workspaceId
 * @param projectId
 * @param itemId
 * @returns {string}
 */
export const getLikedUsersPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
  itemId: Item['id'],
) =>
  `${getItemsPath(
    workspaceId,
    projectId,
  )}/${itemId}/${COL_LIKED_USERS}` as const;

/**
 * myWorkspaces
 * - docID は workspaceId
 * @param uid
 * @returns {string}
 */
export const getMyWorkspacesPath = (uid: Auth['uid']) =>
  `${getAccountsPath()}/${uid}/${COL_MY_WORKSPACES}` as const;

/**
 * profiles
 * - docID は workspaceId
 * @param uid
 * @param workspaceId
 * @returns {string}
 */
export const getProfilesPath = (
  uid: Auth['uid'],
  workspaceId: Workspace['id'],
) => `${getMyWorkspacesPath(uid)}/${workspaceId}/${COL_PROFILES}` as const;

/**
 * myProjects
 * - docID は projectId
 * @param uid
 * @param workspaceId
 * @returns {string}
 */
export const getMyProjectsPath = (
  uid: Auth['uid'],
  workspaceId: Workspace['id'],
) => `${getMyWorkspacesPath(uid)}/${workspaceId}/${COL_MY_PROJECTS}` as const;

/**
 * 一時ファイルパス
 * @param workspaceId
 * @returns
 */
export const getTempFilesPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_TEMP_FILES}` as const;

/**
 * commentFiles
 * - docId は 発番
 * @param workspaceId
 * @param projectId
 * @param itemId
 * @param commentId
 * @returns
 */
export const getCommentFilesPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
  itemId: Item['id'],
  commentId: Comment['id'],
) =>
  `${getCommentsPath(
    workspaceId,
    projectId,
    itemId,
  )}/${commentId}/${COL_COMMENT_FILES}` as const;

/**
 * avatarFiles
 * - docId は 発番
 * @param workspaceId
 * @returns
 */
export const getAvatarFilesPath = (workspaceId: Workspace['id']) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_AVATAR_FILES}` as const;

/**
 * toComments
 * - docID は 発番
 * @param uid
 * @param workspaceId
 * @returns
 */
export const getToCommentsPath = (
  uid: Auth['uid'],
  workspaceId: Workspace['id'],
) => `${getMyWorkspacesPath(uid)}/${workspaceId}/${COL_TO_COMMENTS}` as const;

/**
 * checkoutSessions
 * - docID は workspaceId
 * @param workspaceId
 * @returns
 */
export const getCheckoutSessionsPath = (workspaceId: string) =>
  `${getWorkspacesPath()}/${workspaceId}/${COL_CHECKOUT_SESSIONS}` as const;

/**
 * slackIntegrations
 * - docID は projectId
 * @param workspaceId
 * @param projectId
 * @returns
 */
export const getSlackIntegrationsPath = (
  workspaceId: string,
  projectId: string,
) =>
  `${getProjectsPath(
    workspaceId,
  )}/${projectId}/${COL_SLACK_INTEGRATIONS}` as const;

/**
 * likedItems
 * - docID は itemId
 * @param uid
 * @param workspaceId
 * @param projectId
 * @returns
 */
export const getLikedItemsPath = (
  uid: string,
  workspaceId: string,
  projectId: string,
) =>
  `${getMyProjectsPath(
    uid,
    workspaceId,
  )}/${projectId}/${COL_LIKED_ITEMS}` as const;

/**
 * commentReactions
 * - docId は 発番
 * @param workspaceId
 * @param projectId
 * @param itemId
 * @param commentId
 * @returns
 */
export const getCommentReactionsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
  itemId: Item['id'],
  commentId: Comment['id'],
) =>
  `${getCommentsPath(
    workspaceId,
    projectId,
    itemId,
  )}/${commentId}/${COL_COMMENT_REACTIONS}` as const;

/**
 * projectDescriptions
 * - docID は projectId
 * @param workspaceId
 * @param projectId
 * @returns {string}
 */
export const getProjectDescriptionsPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) =>
  `${getProjectsPath(
    workspaceId,
  )}/${projectId}/${COL_PROJECT_DESCRIPTIONS}` as const;

/**
 * itemOrders
 * - docID は projectId
 * @param workspaceId
 * @param projectId
 * @returns
 */
export const getItemOrdersPath = (
  workspaceId: Workspace['id'],
  projectId: Project['id'],
) => `${getProjectsPath(workspaceId)}/${projectId}/${COL_ITEM_ORDERS}` as const;

/**
 * memberViewItems
 * - docID は itemId
 * @param workspaceId
 * @param projectId
 * @returns
 */
export const getMemberViewItemsPath = (
  workspaceId: string,
  projectId: string,
) =>
  `${getProjectsPath(
    workspaceId,
  )}/${projectId}/${COL_MEMBER_VIEW_ITEMS}` as const;

/**
 * guestViewItems
 * - docID は itemId
 * @param workspaceId
 * @param projectId
 * @returns
 */
export const getGuestViewItemsPath = (workspaceId: string, projectId: string) =>
  `${getProjectsPath(
    workspaceId,
  )}/${projectId}/${COL_GUEST_VIEW_ITEMS}` as const;

export default {};
