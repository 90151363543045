import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface IInvitingGuestProps {
  workspaceName: string;
}

/**
 * ゲスト招待用ログイン画面表示
 * @returns
 */
export default function InvitingGuest(props: IInvitingGuestProps) {
  const { t } = useTranslation();
  const { workspaceName } = props;

  return (
    <div>
      <div className="mb-4 text-center sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <div className="relative mb-3">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300 dark:border-gray-700" />
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white dark:bg-gray-800">
                {t('以下のワークスペースにゲスト参加します')}
              </span>
            </div>
          </div>
          <div className="py-2">
            <p className="block text-xs font-medium text-gray-700 dark:text-gray-400">
              {t('ワークスペース')}
            </p>
            <p className="block font-bold">{workspaceName}</p>
          </div>
        </div>
      </div>
      <div className="mb-6 border-b border-gray-200 dark:border-gray-700" />
    </div>
  );
}
