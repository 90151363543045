import { EV } from '@/@types/events';
import * as React from 'react';

import { classNames } from '@/libs/styleUtils';

export interface IInputProps {
  name: string;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  ref?: React.LegacyRef<HTMLInputElement>;
  onChange?: (e: EV<HTMLInputElement>) => void;
}

export default function Checkbox(props: IInputProps) {
  const { label, name, ref, onChange, disabled, checked, readOnly } = props;
  return (
    <div className="sm:col-span-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id={name}
            type="checkbox"
            ref={ref}
            checked={checked}
            readOnly={readOnly}
            autoComplete={name}
            className={classNames(
              disabled
                ? 'bg-gray-200 text-gray-500 dark:bg-gray-800 dark:text-gray-400 opacity-50'
                : '',
              !readOnly
                ? 'focus:ring-primary-500 dark:focus:ring-primary-600 dark:focus:ring-offset-gray-700 cursor-pointer'
                : 'focus:ring-0 focus:ring-offset-0',
              'w-4 h-4 bg-white dark:bg-gray-600 border-gray-300 dark:border-gray-500 text-primary-500 dark:text-primary-600 rounded-sm',
            )}
            onChange={onChange}
            disabled={disabled}
          />
          {label && (
            <label
              htmlFor={name}
              className={classNames(
                disabled ? 'opacity-40' : '',
                'block ml-3 font-medium text-gray-700 dark:text-gray-300',
              )}
            >
              {label}
            </label>
          )}
        </div>
      </div>
    </div>
  );
}
