import React, { useRef, useMemo, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import DestinationMultiSelect, {
  IMultiSelectHandler,
  ISelectOption,
} from '@/components/Common/Forms/DestinationMultiSelect';

import { IItemFormDefaultValues } from '@/hooks/useEditItem';
import useMentions from '@/hooks/useMentions';
import useRemovedGuests from '@/hooks/useRemovedGuests';
import useRemovedMembers from '@/hooks/useRemovedMembers';

export interface IPPropertyInchargeProps {
  idx: number;
}

/**
 * プロパティ選択肢用CheckboxInput
 * @param props
 * @returns
 */
export default function PropertyIncharge(props: IPPropertyInchargeProps) {
  const { idx } = props;
  const { t } = useTranslation();
  const { getValues, setValue, trigger } =
    useFormContext<IItemFormDefaultValues>();
  const selectedToListRef = useRef<ISelectOption[]>([]);
  const multiSelectRef = useRef({} as IMultiSelectHandler);
  const { memberMentions, guestMentions } = useMentions();

  const { removedMemberMentions } = useRemovedMembers();
  const { removedGuestMentions } = useRemovedGuests();

  const displayUserList = useMemo(
    () => [
      ...removedMemberMentions,
      ...memberMentions,
      ...removedGuestMentions,
      ...guestMentions,
    ],
    [
      memberMentions,
      removedMemberMentions,
      guestMentions,
      removedGuestMentions,
    ],
  );

  //  担当者リスト
  const inchargeList = useMemo<ISelectOption[]>(() => {
    const concatMentions = memberMentions.concat(guestMentions);
    const uniqueMentions = Array.from(
      concatMentions
        .reduce((map, item) => map.set(item.id, item), new Map())
        .values(),
    );
    return uniqueMentions;
  }, [memberMentions, guestMentions]);

  const fields = getValues('properties');

  // 選択中のユーザ（removedUserも名前を取得）
  const selectedOptions = useMemo<ISelectOption[]>(() => {
    const userIdArray = fields[idx].arrayValue as string[];
    const users = userIdArray.reduce<ISelectOption[]>((acc, po) => {
      const found = displayUserList.find((v) => v.id === po);
      if (!found) return acc;
      const id = found?.id;
      if (id) {
        acc.push({
          id,
          photoURL: found.photoURL || undefined,
          value: found.value || '',
          name: found.name || '',
        });
      }
      return acc;
    }, []);
    return users;
  }, [fields[idx].arrayValue, idx, displayUserList]);

  // メンションのセレクト用選択肢(removedUserを含まない)
  const optionList = useMemo(() => {
    const mapped = inchargeList.map((m) => ({
      ...m,
      unavailable: false,
    }));
    return [...mapped];
  }, [inchargeList]);

  const handleChange = useCallback(
    (selectedItems: ISelectOption[]) => {
      const values = getValues('properties');
      values[idx].arrayValue = selectedItems.map((i) => i.id);
      setValue(`properties.${idx}`, values[idx], {
        shouldDirty: true,
        shouldTouch: true,
      });
      trigger();
    },
    [idx],
  );
  return (
    selectedOptions && (
      <div className="w-full h-full">
        <DestinationMultiSelect
          ref={multiSelectRef}
          placeholder={t('担当者を選択')}
          selectedOptions={selectedOptions}
          optionDirection="bottom"
          optionList={optionList}
          showIcon
          onChangeSelected={(selectedItems) => {
            selectedToListRef.current = [...selectedItems];
            handleChange(selectedItems);
          }}
        />
      </div>
    )
  );
}
