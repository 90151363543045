import { zodResolver } from '@hookform/resolvers/zod';

import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';
import { getNavigatePath, t2s } from '@/libs/utils';

import { WORKSPACE_NAME } from '@/libs/validations';

import WorkspaceRepository from '@/repositories/WorkspaceRepository';

import { setIsLoaded } from '@/reducers/uiStateReducer';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import Form from '@/components/Common/Forms/Form';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';
import Logo from '@/components/Common/Logo';

import { auth } from '@/firebase';
import useSubmitState from '@/hooks/useSubmitState';

export default function GetStarted() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const workspaceRepository = new WorkspaceRepository();
  const dispatch = useDispatch();

  // Zod schema定義
  const schema = z.object({
    workspaceName: z
      .string()
      .min(1, { message: t('入力してください') })
      .max(
        WORKSPACE_NAME.max,
        t2s(t('<max>文字以内で入力してください', WORKSPACE_NAME)),
      ),
  });

  type SchemaType = z.infer<typeof schema>;

  // Zod Form
  const methods = useForm<SchemaType>({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  // Form Submit
  const formSubmit = methods.handleSubmit(async (data) => {
    if (!auth.currentUser) {
      return;
    }
    try {
      // Create Workspace
      const res = await workspaceRepository.createWorkspace(data);
      // Reset form state
      methods.reset({ ...data });
      // Go to new Workspace
      navigate(getNavigatePath(res.workspaceId as string));
    } catch (e) {
      toast.error(t('ワークスペースの作成に失敗しました'));
      throw e;
    }
  });

  useEffect(() => {
    // 画面初期化時に必要な処理
    dispatch(setIsLoaded(true));
    setTimeout(() => methods.setFocus('workspaceName'), 100);
  }, []);

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <div className="flex flex-col justify-center min-h-full px-6 -mt-10 lg:px-8">
      <div className="absolute left-0 right-0 z-10">
        <Logo className="w-auto h-full mx-auto opacity-20" />
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-600 dark:text-gray-400">
          {t('ワークスペースを作成してコラボレーションを始めましょう！')}
        </h2>
      </div>

      <div className="z-20 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="px-4 pb-8 border shadow pt-7 sm:rounded-lg sm:px-10 dark:border-gray-700">
          <FormProvider {...methods}>
            <Form onSubmit={formSubmit}>
              <FormCol>
                <Input name="workspaceName" label={t('ワークスペース名')} />
              </FormCol>
              <FormCol>
                <FormButton
                  id="createWorkspace"
                  disabled={isDisabledApply}
                  className="sm:w-full"
                  variant="primary"
                  submit
                >
                  {t('作成して始める')}
                </FormButton>
              </FormCol>
            </Form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}
