import { EV } from '@/@types/events';
import { IModalDialogProps } from '@/@types/ui';
import { zodResolver } from '@hookform/resolvers/zod';

import React, { useState, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { toast } from 'react-toastify';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';
import { convertPath2URL, isImageFile } from '@/libs/utils';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import FormCol from '@/components/Common/Forms/FormCol';
import ImageInput from '@/components/Common/Forms/ImageInput';

import { InsertImagePayload } from '@/components/Common/Lexical/plugins/ImagesPlugin';

import useTempFiles from '@/hooks/useTempFiles';

export type ILexicalImageEditDialogProps = IModalDialogProps & {
  onSubmit: ({ altText, src }: InsertImagePayload) => void;
  isUploadLimit: boolean;
};

export default function ImageEditorDialog(props: ILexicalImageEditDialogProps) {
  const [files, setFiles] = useState<File[]>([]);
  const [src, setSrc] = useState('');
  const [fileName, setFileName] = useState('');

  const { onClose, onSubmit, isUploadLimit } = props;
  const { t } = useTranslation();

  const { handleSelectFiles } = useTempFiles();

  const schema = z.object({
    src: z
      .string()
      .trim()
      .min(1, { message: t('入力してください') }),
  });

  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      src: '',
    },
  });

  const loadImage = (e: EV<HTMLInputElement>) => {
    const { files: _files } = e.target;
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setSrc(reader.result);
      }
      return '';
    };

    if (_files !== null) {
      if (_files.length === 0) {
        setFiles([]);
        setSrc('');
      } else {
        reader.readAsDataURL(_files[0]);
        setFileName(_files[0].name);
        setFiles(Array.from(_files));
      }
    }
  };

  // Form Submit
  const formSubmit = methods.handleSubmit(async () => {
    // ファイル形式チェック
    const ret = await isImageFile(files[0]);
    if (!ret) {
      toast.error(t('ファイル形式が画像ファイルではありません'));
      return;
    }
    // 1. 画像をTempにアップロード
    const response = await handleSelectFiles(files);
    // 2. パスを取得
    const { path } = response[0];
    // 3. パスを置換
    const _src = await convertPath2URL(path);
    // 3. srcを設置
    methods.reset({ src: '' });
    // altTextは設定しない
    onSubmit({ altText: '', src: _src, path });
    onClose();
  });

  // Submitボタン状態 altTextのdirty判定はしない
  const isDisabledApply = useMemo(
    () =>
      !(methods.formState.isValid && !methods.formState.isSubmitting) &&
      src === '',
    [methods, src],
  );

  // mount時処理
  useEffect(() => {
    methods.reset({ src: '' });
  }, []);

  useEffect(() => {
    methods.setValue('src', src);
    methods.trigger();
  }, [src]);

  return (
    <FormDialog
      title={t('画像を挿入')}
      onSubmit={(e) => {
        formSubmit(e);
        e.stopPropagation();
        e.preventDefault();
      }}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <FormCol>
          <ImageInput
            handleFileLoad={loadImage}
            fileName={fileName}
            isUploadLimit={isUploadLimit}
          />
        </FormCol>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          submit
          variant="primary"
          className="mr-1 sm:w-auto sm:ml-3 sm:mt-4"
          disabled={isDisabledApply}
          id="addEditorImage"
        >
          {t('追加')}
        </FormButton>
        <FormButton
          className="sm:mt-4 md-mt-0 sm:w-auto sm:ml-3"
          onClick={() => onClose()}
          id="cancelAddEditorImage"
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
