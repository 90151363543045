import { EV } from '@/@types/events';
import { IJoinedGuestWithProjects } from '@/@types/models';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import lodash from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import useJoinType from '@/hooks/useJoinType';

import Alert from '../Common/Alert';
import { Badge } from '../Common/Badge';

export interface IGuestProjectListProps {
  defaultProjects: Set<string>;
  accounts?: IJoinedGuestWithProjects | null;
}

export default function GuestProjectList(props: IGuestProjectListProps) {
  const { defaultProjects, accounts } = props;
  const { projectsByJoinType: myProjects } = useJoinType();
  const { t } = useTranslation();

  const { getValues, setValue, trigger, register, watch, formState } =
    useFormContext();

  const projects = watch('projects', []);

  const sharedProjects = useMemo(
    () => myProjects.filter((x) => !!x && !!x.isShare),
    [myProjects],
  );

  // チェックボックス全解除用
  useEffect(() => {
    const oldProjects = lodash.cloneDeep(projects);
    projects.forEach((p: any, idx: number) => {
      projects[idx].hasChanged = `${
        projects[idx].shouldBeGuest !==
        defaultProjects.has(projects[idx].projectId)
      }`;
    });
    if (!formState.isDirty) {
      projects.forEach((p: any, idx: number) => {
        projects[idx].shouldBeGuest = defaultProjects.has(
          projects[idx].projectId,
        );
      });
    }
    if (!lodash.isEqual(oldProjects, projects)) {
      setValue('projects', projects);
    }
  }, [...projects.map((p: any) => p.shouldBeGuest), defaultProjects]);

  // ローカルステート
  const handleChange = useCallback((idx: number, e: EV<HTMLInputElement>) => {
    const values = getValues('projects');
    const input = e.currentTarget.checked;
    values[idx].shouldBeGuest = input;
    setValue('projects', values, { shouldDirty: values[idx].hasChanged });
    trigger();
  }, []);

  return (
    <div className="relative flex-1 pt-1 pb-6 mx-2">
      {sharedProjects.length === 0 ? (
        <div>
          <Alert color="gray" className="" icon={ExclamationTriangleIcon}>
            <h3 className="font-medium">
              {t(
                '共有中のプロジェクトがありません。ゲストを招待するには、プロジェクトの共有設定より共有を許可してください。',
              )}
            </h3>
          </Alert>
        </div>
      ) : (
        <ul className="grid grid-cols-1 gap-3">
          {sharedProjects.map((project, i) => (
            <div
              key={project.id}
              className="relative mx-1 my-1 bg-white rounded-lg shadow group dark:bg-gray-800 col-span-1 hover:ring-2 hover:ring-offset-0 hover:ring-primary-500 dark:hover:ring-primary-600"
            >
              <label>
                <div className="flex items-center w-full p-5 space-x-6">
                  <div className="flex-1">
                    <div className="flex items-center space-x-3">
                      <h3 className="text-sm font-bold text-gray-900 dark:text-gray-300">
                        {project.projectName}
                      </h3>
                    </div>
                  </div>
                  <input
                    type="hidden"
                    {...register(`projects[${i}].projectId`)}
                    value={project.id}
                  />
                  <input
                    type="hidden"
                    {...register(`projects[${i}].hasChanged`)}
                    defaultValue="false"
                  />
                  <div className="sm:col-span-6">
                    <div className="flex items-center mt-4 md:mt-0 space-x-0 md:space-x-4">
                      {accounts?.joinType === 'guest' &&
                      accounts?.userIdAndProjectIds?.find(
                        (v) => v.projectId === project.id,
                      ) ? (
                        <Badge
                          className="mr-4 md:mr-0"
                          color="blue"
                          content={t('参加中')}
                        />
                      ) : (
                        ''
                      )}
                      {accounts?.joinType === undefined &&
                      accounts?.userIdAndProjectIds?.find(
                        (v) => v.projectId === project.id,
                      ) ? (
                        <Badge
                          className="mr-4 md:mr-0"
                          color="blue"
                          content={t('参加待ち')}
                        />
                      ) : (
                        ''
                      )}
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          className="w-4 h-4 bg-white border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 dark:bg-gray-600 dark:border-gray-500 text-primary-500 dark:text-primary-600"
                          {...register(`projects[${i}].shouldBeGuest`)}
                          onChange={(e) => handleChange(i, e)}
                          defaultChecked={defaultProjects.has(
                            project?.id || '',
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          ))}
        </ul>
      )}
    </div>
  );
}
