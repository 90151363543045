import { LinkNode } from '@lexical/link';
import { $isAtNodeEnd } from '@lexical/selection';

import { RangeSelection, TextNode } from 'lexical';

export const LowPriority = 1;

/**
 * フォーカスの当たっているノードを取得
 * @param selection
 * @returns
 */
export const getSelectedNode = (selection: RangeSelection) => {
  const { anchor } = selection;
  const { focus } = selection;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }
  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
};

interface LexNode {
  root: any;
}
const typeCheckLexNode = (obj: unknown): obj is LexNode => {
  const node = obj as LexNode;
  return 'root' in node;
};

/**
 * typeを指定してnodeの配列を取得
 * @param st
 * @param type
 * @returns
 */
export const getNodeByType = (st: string, type: string) => {
  // 再帰処理でネストしたchildrenのtypeも取得
  const func = (state: any, targetType: string) => {
    const list: any[] = [];
    if (state.type === targetType) list.push(state);
    (state.children || []).forEach((it: any) =>
      func(it, targetType).forEach((val) => {
        list.push(val);
      }),
    );
    return list;
  };
  const obj = JSON.parse(st);
  if (typeCheckLexNode(obj)) {
    return func(obj.root, type);
  }
  return [];
};

/**
 * LinkNodeの型判定
 * @param node extends LexicalNode
 */
export const isLinkNode = (node: any): node is LinkNode => {
  const nodeType = node.getType();
  return nodeType === 'link';
};

/**
 * TextNodeの型判定
 * @param node extends LexicalNode
 */
export const isTextNode = (node: any): node is TextNode => {
  const nodeType = node.getType();
  return nodeType === 'text';
};
