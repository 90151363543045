import '@/errorReporting';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ReactPostprocessor from 'i18next-react-postprocessor';
import React from 'react';

import ReactDOM from 'react-dom';

import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import enCommon from '@/locales/en/translation.json'; // 日本語辞書

import jaCommon from '@/locales/ja/translation.json'; // 英語辞書

import { ENV } from '@/libs/const';

import App from '@/App';

import '@/index.css';
import '@themesberg/flowbite';
import ReduxFirebase from '@/ReduxFirebase';

const init = async () => {
  // 開発環境ではwhy-did-you-renderを有効化する
  if (import.meta.env.VITE_NODE_ENV === ENV.DEV) {
    // eslint-disable-next-line import/no-extraneous-dependencies
    const whyDidYouRender = await import(
      '@welldone-software/why-did-you-render'
    );
    whyDidYouRender.default(React, {
      trackAllPureComponents: true,
    });
  }
};

// i18n設定（ブラウザの言語設定によって辞書を選定する
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(new ReactPostprocessor()) // 翻訳文字列内にJSXを指定可能にするPlugin
  .init({
    postProcess: ['reactPostprocessor'],
    returnEmptyString: false,
    debug: false,
    resources: {
      ja: { translation: jaCommon },
      en: { translation: enCommon },
    },
    fallbackLng: 'ja',
    keySeparator: false,
    interpolation: { escapeValue: true },
    detection: {
      // order and from where user language should be detected
      // 'querystring','cookie','localStorage','sessionStorage','htmlTag','path','subdomain',
      order: ['navigator'],
    },
  });

init().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <ReduxFirebase>
          <App />
        </ReduxFirebase>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
  );
});
