import { Project, Property, RootState, View } from '@/@types/models';
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  ReduxFirestoreQuerySetting,
  useFirestore,
  useFirestoreConnect,
} from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';

import {
  IS_GUEST_ACCESSIBLE_DEFAULT,
  KANBAN_PROPERTY_VIEW_COUNT,
} from '@/libs/const';
import { COL_VIEWS, getViewsPath } from '@/libs/docPathUtils';

import {
  getFullSystemFields,
  getNavigatePath,
  getUpdateSystemFields,
} from '@/libs/utils';

import PropertyOrderRepository from '@/repositories/PropertyOrderRepository';

import { setCurrentViewId as setCurrentViewIdForRedux } from '@/reducers/currentViewIdReducer';

import { setNewViewFlag } from '@/reducers/viewsReducer';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * viewsのリアルタイムアップデートを購読するためのHooks
 * @param {string} isGuest?
 * @returns { }
 */
export default function useViews(isGuest?: boolean) {
  const rrfFirestore = useFirestore();
  const { t } = useTranslation();
  const { exec } = useHandleApi();
  const { myProjects } = useProjects();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const { currentMyWorkspace } = useMyWorkspaces();

  // ※ このフックの生成時には必ずworkspaceIdが設定されていることが保証されていなければいけない
  const { workspaceId, originalWorkspaceId } = useMemo(
    () => ({
      workspaceId: currentMyWorkspace?.workspaceId,
      originalWorkspaceId: currentMyWorkspace?.originalWorkspaceId,
    }),
    [currentMyWorkspace],
  );

  // 現在
  const projectIds = useMemo(
    () => myProjects.map((d) => d.projectId as string),
    [myProjects],
  );

  // プロジェクトID毎にサブスクリプションを実行
  useFirestoreConnect(() => {
    if (!workspaceId || projectIds.length === 0) return [];

    return projectIds.map((pId) => {
      const query: ReduxFirestoreQuerySetting = {
        collection: getViewsPath(workspaceId, pId),
        storeAs: `${COL_VIEWS}/${pId}`,
        orderBy: ['viewName', 'asc'],
      };
      // ゲストの場合は絞り込みを追加
      if (
        ['unknownGuest', 'guest'].includes(
          currentMyWorkspace?.joinType as string,
        )
      ) {
        query.where = [['isGuestAccessible', '==', true]];
      }
      return query;
    });
  });

  // viewsのセレクタ（project毎にサブスクリプションする必要があるため、stateの型はany）
  const ordered = useSelector((state: any) => state.firestore.ordered);
  const views = useMemo(
    () =>
      projectIds.flatMap<View>((pId) => {
        const pViews = ordered[`${COL_VIEWS}/${pId}`];
        if (!pViews) return [];

        const ret: View[] = (pViews as View[]).map((v) => ({
          ...v,
          _projectId: pId,
        }));

        if (isGuest) {
          return ret.filter((v) => v.isGuestAccessible);
        }

        return ret;
      }),
    [ordered],
  );

  // カレントビューの設定
  const currentViewId = useSelector((state: RootState) => state.currentViewId);
  const setCurrentViewId = useCallback(
    (vid?: string) => {
      dispatch(setCurrentViewIdForRedux(vid));
    },
    [dispatch, setCurrentViewIdForRedux],
  );
  const currentView = useMemo(
    () => views.find((v) => v.id === currentViewId) || null,
    [views, currentViewId],
  );

  // ビュー新規作成
  const createView = async (
    viewName: View['viewName'],
    viewType: View['viewType'],
    projectId: Project['id'],
  ) => {
    exec(async () => {
      if (!originalWorkspaceId || !workspaceId || !projectId)
        throw new Error('createView error');
      const propertyOrderRepository = new PropertyOrderRepository(
        workspaceId as string,
        projectId as string,
      );

      // propertyOrderList の取得
      const propertyOrder = await propertyOrderRepository.findOne();
      // リストビューの場合のビューに表示するプロパティは、views.propertyOrderList
      const propertyOrderList =
        viewType === 'kanban'
          ? propertyOrder?.orderList?.slice(0, KANBAN_PROPERTY_VIEW_COUNT) || []
          : currentView?.propertyOrderList || [];

      const newView: View = {
        viewName,
        viewType,
        propertyOrderList,
        propertyOptions: {},
        isGuestAccessible: IS_GUEST_ACCESSIBLE_DEFAULT,
        ...getFullSystemFields(userId),
      };
      const { id } = await rrfFirestore.add<View>(
        `${getViewsPath(workspaceId, projectId)}`,
        newView,
      );
      dispatch(setNewViewFlag(false));
      // 3. 画面遷移

      // HACK: そのままnavigateを行うと404になる場合があるのでディレイを行う
      setTimeout(() => {
        navigate(getNavigatePath(originalWorkspaceId, projectId, id));
      });
    }, t('ビューの作成に失敗しました'));
  };

  // ビュー更新
  const updateView = async (
    viewName: View['viewName'],
    viewType: View['viewType'],
    isGuestAccessible: View['isGuestAccessible'],
    docId: View['id'],
    projectId: Project['id'],
  ) => {
    exec(async () => {
      if (!docId || !userId || !workspaceId || !projectId)
        throw new Error('updateView error');

      const view: View = {
        viewName,
        viewType,
        isGuestAccessible,
        ...getUpdateSystemFields(userId),
      };
      await rrfFirestore.update<View>(
        `${getViewsPath(workspaceId, projectId)}/${docId}`,
        view,
      );
    }, t('ビューの更新に失敗しました'));
  };

  // ビューの公開設定を更新
  const updateIsGuestAccessible = async (
    composedData: Array<{
      view: View;
      isGuestAccessible: View['isGuestAccessible'];
    }>,
    projectId: Project['id'],
  ) => {
    exec(async () => {
      if (!workspaceId || !projectId || !userId)
        throw new Error('updateIsGuestAccessible error');

      composedData.forEach((d) => {
        const view: View = {
          isGuestAccessible: d.isGuestAccessible,
          ...getUpdateSystemFields(userId),
        };
        rrfFirestore.update<View>(
          `${getViewsPath(workspaceId, projectId)}/${d.view.id}`,
          view,
        );
      });
    }, t('ビューの公開設定の更新に失敗しました'));
  };

  // ビュー削除(ナビゲーション制御はindex.tsxで行われる)
  const deleteView = async (viewId: View['id'], projectId: Project['id']) => {
    exec(async () => {
      if (!workspaceId || !projectId) throw new Error();
      await rrfFirestore.delete(
        `${getViewsPath(workspaceId, projectId)}/${viewId}`,
      );
    }, t('ビューの削除に失敗しました'));
  };

  // ビューのプロパティ表示順を更新
  const updatePropertyOrderList = async (
    viewId: View['id'],
    projectId: Project['id'],
    propertyOrderList: View['propertyOrderList'],
  ) => {
    exec(async () => {
      if (!workspaceId || !projectId || !userId)
        throw new Error('updatePropertyOrderList error');
      const view: View = {
        propertyOrderList,
        ...getUpdateSystemFields(userId),
      };
      rrfFirestore.update<View>(
        `${getViewsPath(workspaceId, projectId)}/${viewId}`,
        view,
      );
    }, t('ビューのプロパティ表示順の更新に失敗しました'));
  };

  /**
   * プロジェクトIDを指定して、適当なviewを取得する
   * @param projectId
   * @returns
   */
  const getProjectTopView = (projectId: Project['id']) =>
    views.find((v) => v._projectId === projectId) || null;

  /**
   * リストビューのカラム幅更新
   * @param viewId
   * @param projectId
   * @param propertyId
   * @param width
   */
  const updateColumnWidth = async (
    viewId: View['id'],
    projectId: Project['id'],
    propertyId: Property['id'],
    width: number,
  ) => {
    exec(async () => {
      if (!viewId || !userId || !workspaceId || !projectId || !propertyId)
        throw new Error('updateView error');

      // ドット付きキーで更新すると部分的に更新できるが、型安全にできない？
      const view: any = {
        [`propertyOptions.${propertyId}.columnWidth`]: width,
        ...getUpdateSystemFields(userId),
      };
      await rrfFirestore.update(
        `${getViewsPath(workspaceId, projectId)}/${viewId}`,
        view,
      );
    }, t('カラム幅の更新に失敗しました'));
  };

  return {
    views,
    currentView,
    setCurrentViewId,
    createView,
    updateView,
    updateIsGuestAccessible,
    deleteView,
    updatePropertyOrderList,
    getProjectTopView,
    updateColumnWidth,
  };
}
