import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// プラグインの追加
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// いったん利用可能フォーマットはpottosを踏襲
export type FormatType =
  | 'ISO8601_UTC'
  | 'YYYY-MM-DD HH-mm-ss'
  | 'YYYY-MM-DD'
  | 'YYYY-MM-DD HH-mm'
  | 'HH:mm'; // 他のフォーマットが必要なら都度追加する

export type TimeUnitType = 'month' | 'day' | 'hour' | 'minute' | 'second';

export class DayjsUtil {
  dayjsObj: Dayjs;

  constructor(obj?: any, format?: FormatType) {
    this.dayjsObj = dayjs(obj, format);
  }

  /**
   * 初期可変数のバリデーション
   * @param obj
   * @param format
   * @returns boolean
   */
  static isValid(obj: any, format: FormatType): boolean {
    return dayjs(obj, format, true).isValid();
  }

  /**
   * unixタイムスタンプの取得
   * @returns number
   */
  unix(): number {
    return this.dayjsObj.unix();
  }

  /**
   * フォーマットした文字列を返す
   * 使用できるフォーマットは引数の型を参照
   * @param  {FormatType} format
   * @returns string
   */
  format<T extends FormatType>(format: T): T {
    if (format === 'ISO8601_UTC') {
      return this.dayjsObj.clone().utc().format() as T;
    }
    return this.dayjsObj.format(format) as T;
  }

  /**
   * 指定した日を経過した日付を取得
   * @param day
   * @returns
   */
  shiftDay(day: number): Date {
    return this.dayjsObj.add(day, 'day').toDate();
  }

  /**
   * 引数のDateオブジェクトとの日時差分を返す
   * @param date
   * @returns
   */
  diff<T extends Date>(date: T, unit?: TimeUnitType): number {
    const targetObj = dayjs(date);
    return this.dayjsObj.diff(targetObj, unit);
  }

  /**
   * 引数のDateオブジェクトとの日時差分があるか検証する
   * @param date
   * @returns
   */
  isDiff<T extends Date>(date: T): boolean {
    return this.diff(date, 'second') !== 0;
  }

  /**
   * 引数の日時以前か判定する
   * @param {DayjsUtil} dj
   * @param {TimeUnitType}
   * @param {boolean} 指定日時を含むか
   */
  isBefore(dj: DayjsUtil, unit: TimeUnitType, isEdgeInclude: boolean): boolean {
    const self = this.dayjsObj.clone().startOf(unit);
    const target = dj.dayjsObj.clone().startOf(unit);
    return isEdgeInclude ? self.isSameOrBefore(target) : self.isBefore(target);
  }

  /**
   * 引数の日時以降か判定する
   * @param {DayjsUtil} dj
   * @param {TimeUnitType}
   * @param {boolean} 指定日時を含むか
   */
  isAfter(dj: DayjsUtil, unit: TimeUnitType, isEdgeInclude: boolean): boolean {
    const self = this.dayjsObj.clone().startOf(unit);
    const target = dj.dayjsObj.clone().startOf(unit);
    return isEdgeInclude ? self.isSameOrAfter(target) : self.isAfter(target);
  }

  /**
   * 時刻を0クリアしてインスタンスを返却
   * @returns DayjsUtil
   */
  clearTime(): DayjsUtil {
    // タイムゾーンをBASE_TIMEZONEに直してから時刻を0クリアする
    this.dayjsObj = this.dayjsObj.hour(0).minute(0).second(0).millisecond(0);
    return this;
  }
}
