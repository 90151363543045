import { RootState } from '@/@types/models';
import React, { useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { AUTO_ERROR_WINDOW_CLOSE_DELAY } from '@/libs/const';
import {
  routeHandleMyWorkspaceQuery,
  getMyWorkspaceList,
} from '@/libs/repositoryUtils';

import IntegrationRepository from '@/repositories/IntegrationRepository';

import Loading from '@/components/Common/Loading';

import useQuery from '@/hooks/useQuery';

export default function SlackCallback() {
  const { t } = useTranslation();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const queries = useQuery();
  const { workspaceId: originalWorkspaceId, projectId } = useParams();

  const integrationRepository = new IntegrationRepository();

  useEffect(() => {
    const fn = async () => {
      const code = queries.get('code') as string;
      const state = queries.get('state') as string;
      if (!code) window.close();
      if (originalWorkspaceId) {
        try {
          // 要求されたWSの存在検証
          const workspaceQuery =
            routeHandleMyWorkspaceQuery(originalWorkspaceId);
          const myWorkspaceList = await getMyWorkspaceList(
            userId,
            workspaceQuery,
          );
          if (myWorkspaceList.length > 0 && myWorkspaceList[0].id) {
            await integrationRepository.createSlackToken({
              workspaceId: myWorkspaceList[0].id,
              projectId,
              code,
              state,
            });
          }
          window.close();
        } catch (error) {
          toast.error(t('Slackの接続に失敗しました'));
          setTimeout(() => {
            window.close();
          }, AUTO_ERROR_WINDOW_CLOSE_DELAY);
          throw error;
        }
      }
    };
    fn();
  }, []);

  // ローディング表示
  return <Loading className="w-screen h-screen" />;
}
