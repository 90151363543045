import { ActionType } from '@/@types/common';
import { DialogState } from '@/@types/ui';

import actions from '@/libs/actions';

type Types = Pick<typeof actions, 'SET_NEW_PROJECT_DIALOG'>;

type Action = ActionType<
  Types,
  { SET_NEW_PROJECT_DIALOG: { payload: boolean } }
>;

export const dialogStateReducer = (
  // eslint-disable-next-line default-param-last
  state: DialogState | null = {
    newProjectDialog: false,
  },
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_NEW_PROJECT_DIALOG:
      // eslint-disable-next-line no-param-reassign
      state = { ...state, newProjectDialog: action.payload };
      break;
    default:
  }
  return state;
};

export const setNewProjectDialogState = (st: boolean) => ({
  type: actions.SET_NEW_PROJECT_DIALOG,
  payload: st,
});

export default {};
