import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

interface ISecondaryNavigation {
  name: string;
  href: string;
}

/**
 * その他の機能 ナビゲーション
 * @returns
 */
export default function FunctionNavigation() {
  const { t } = useTranslation();
  const { currentMyWorkspace } = useMyWorkspaces();

  const secondaryNavigation: Array<ISecondaryNavigation> = [
    { name: t('設定'), href: '/settings/' },
  ];

  return (
    <div className="pt-5 space-y-1">
      <h3
        className="px-3 mb-2 text-sm font-semibold tracking-wider text-gray-400 uppercase dark:text-gray-500"
        id="projects-headline"
      >
        {t('ナビゲーション')}
      </h3>
      <div
        className="space-y-1"
        role="group"
        aria-labelledby="projects-headline"
      >
        {secondaryNavigation.map((item: ISecondaryNavigation) => (
          <Link
            key={item.name}
            to={`/${currentMyWorkspace?.originalWorkspaceId}${item.href}`}
            className="flex items-center px-3 py-2 font-medium text-gray-700 dark:text-gray-300 group rounded-md hover:bg-primary-500 dark:hover:bg-primary-600 hover:text-white dark:hover:text-white"
          >
            <span className="truncate">{item.name}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}
