import { Dialog } from '@headlessui/react';
import { BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { classNames } from '@/libs/styleUtils';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import NotificationBadge from '@/components/Notification/NotificationBadge';
import NotificationList from '@/components/Notification/NotificationList';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import BaseDrawer from '../Common/BaseDrawer';

export interface INotificationDropdownProps {
  className?: string;
  iconOnly?: boolean;
}

export default function NotificationDropdown(
  props: INotificationDropdownProps,
) {
  const { t } = useTranslation();
  const { currentMyWorkspace } = useMyWorkspaces();

  const { className, iconOnly } = props;
  const [open, setOpen] = useState(false);

  // consoleエラー回避用のhidden要素
  const notificationFocusDiv = useRef(null);

  const unread = useMemo(
    () => !!currentMyWorkspace?.toCommentsUnReadCount,
    [currentMyWorkspace?.toCommentsUnReadCount],
  );

  const closeDrawer = (value: boolean) => {
    setOpen(value);
  };

  return (
    <>
      {iconOnly ? (
        <div className={classNames('flex w-full', className)}>
          <MenuButton
            id="showNotification"
            type="icon"
            onClick={() => setOpen(true)}
          >
            <BellIcon
              className={classNames(
                unread ? ' animate-swing text-amber-500' : '',
                'w-5 h-5',
              )}
              aria-hidden="true"
            />
          </MenuButton>
        </div>
      ) : (
        <div className={classNames('flex w-full')}>
          <button
            id="showNotification"
            type="button"
            onClick={() => setOpen(true)}
            className={classNames(
              className,
              'flex flex-1 group items-center text-left text-sm font-medium rounded-md hover:text-gray-500 dark:hover:text-gray-300',
            )}
          >
            <BellIcon
              className={classNames(
                unread ? ' animate-swing' : '',
                'mr-3 w-4 h-4 text-gray-400',
              )}
              aria-hidden="true"
            />
            <div className="flex-1">{t('通知')}</div>
            <NotificationBadge
              unread={unread}
              total={currentMyWorkspace?.toCommentsUnReadCount || 0}
              className=""
            />
          </button>
        </div>
      )}
      <BaseDrawer show={open} onClose={setOpen} undefinedInitialFocus>
        <div className="w-screen max-w-md md::max-w-md lg:max-w-lg xl:max-w-xl">
          <div className="flex flex-col h-full pt-6 border-l border-gray-200 shadow-xl dark:border-gray-800 bg-gray-50 dark:bg-gray-700">
            <div className="px-4 sm:px-6">
              <div className="flex items-center justify-between">
                <Dialog.Title className="flex font-medium text-gray-800 text-md dark:text-gray-300">
                  <>
                    <div className="flex-1 mr-4">{t('通知')}</div>
                    <NotificationBadge
                      unread={unread}
                      total={currentMyWorkspace?.toCommentsUnReadCount || 0}
                    />
                  </>
                </Dialog.Title>
                <div className="flex items-center ml-3 h-7">
                  <MenuButton
                    id="closeNotification"
                    type="icon"
                    tabIndex={-1}
                    onClick={() => setOpen(false)}
                  >
                    <XMarkIcon className="w-4 h-4" />
                  </MenuButton>
                  <div ref={notificationFocusDiv} hidden />
                </div>
              </div>
            </div>
            <NotificationList
              closeDrawer={closeDrawer}
              key="notificationList"
            />
          </div>
        </div>
      </BaseDrawer>
    </>
  );
}
