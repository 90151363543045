import { IModalDialogProps } from '@/@types/ui';

import { zodResolver } from '@hookform/resolvers/zod';
import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';
import { t2s } from '@/libs/utils';

import { EMAIL } from '@/libs/validations';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';

import useSubmitState from '@/hooks/useSubmitState';

export interface IModifyEmailDialogProps extends IModalDialogProps {}

/**
 * Eメール変更ダイアログ
 * @param props
 * @returns
 */
export default function ModifyEmailDialog(props: IModifyEmailDialogProps) {
  const { t } = useTranslation();
  const { onClose } = props;

  // Zod schema定義
  const schema = z.object({
    email: z
      .string()
      .min(1, { message: t('入力してください') })
      .email({ message: t('正しいメールアドレスを入力してください') })
      .max(EMAIL.max, {
        message: t2s(t('<max>文字以内で入力してください', EMAIL)),
      }),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
  });

  // Form Submit
  const formSubmit = methods.handleSubmit((data) => {
    // Reset form state
    methods.reset({ ...data });
    onClose();
  });

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <FormDialog
      onClose={onClose}
      title={t('メールアドレスを変更')}
      onSubmit={formSubmit}
    >
      <FormProvider {...methods}>
        <FormCol>
          {t('変更後のメールアドレス宛に認証メールを送信します。')}
        </FormCol>
        <FormCol>
          <Input name="email" label={t('変更後のメールアドレス')} />
        </FormCol>
      </FormProvider>
      <FormProvider {...methods}>
        <FormButton
          id="sendAuthenticationEmail"
          disabled={isDisabledApply}
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
        >
          {t('認証メール送信')}
        </FormButton>
        <FormButton
          id="cancelSendAuthenticationEmail"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={() => onClose()}
        >
          {t('キャンセル')}
        </FormButton>
      </FormProvider>
    </FormDialog>
  );
}
