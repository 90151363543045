/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Picker } from 'emoji-mart';
import i18next from 'i18next';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import RelativePortal from 'react-relative-portal';

import ua from '@/libs/ua';

import data from '@/assets/native.jp.json';

import useToggleTheme from '@/hooks/useToggleTheme';

interface Coords {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

export interface IEmojiPickerProps {
  coords: Coords;
  onEmojiSelect: (emoji: any) => void;
  onClickOutside: () => void;
  isReaction: boolean;
  disableSkinTone?: boolean;
}

export default function EmojiPicker(props: any) {
  const { t } = useTranslation();
  const { coords, onClickOutside, isReaction, disableSkinTone } = props;
  const ref = useRef(null);
  const { isDarkTheme } = useToggleTheme();

  // useAgentによるfirefox判定
  const isFireFox = ua.isFireFox();

  const i18n = {
    search: t('英語で検索'),
    skins: {
      choose: isReaction ? t('リアクションを選択') : t('絵文字を選択'),
    },
    pick: isReaction ? t('リアクションを選択') : t('絵文字を選択'),
    categories: {
      frequent: isReaction ? t('よく使うリアクション') : t('よく使う絵文字'),
      people: t('顔 & 人'),
      nature: t('動物 & 自然'),
      foods: t('食べ物 & 飲み物'),
      activity: t('アクティビティ'),
      places: t('旅行 & 場所'),
      objects: t('オブジェクト'),
      symbols: t('記号'),
      flags: t('旗'),
    },
  };
  useEffect(() => {
    // ※ 変数への格納が必要ないようなのでルールを無視する
    // eslint-disable-next-line no-new
    new Picker({
      emojiVersion: isFireFox ? 11 : undefined, // firefoxは最新バージョンで絵文字が被る
      data: isFireFox ? undefined : data,
      ...props,
      ref,
      theme: isDarkTheme ? 'dark' : 'light',
      i18n,
      skinTonePosition: disableSkinTone ? 'none' : 'preview',
      onClickOutside: () => {},
      locale: i18next.resolvedLanguage,
    });
  }, [isDarkTheme, disableSkinTone]);

  return (
    <RelativePortal component="div" left={0}>
      <div
        className="fixed top-0 left-0 z-50 w-screen h-screen bg-transparent"
        onClick={(event) => onClickOutside(event)}
      />
      <div
        ref={ref}
        style={{
          left: coords.left || 0,
          bottom: coords.bottom || 0,
          top: coords.top - 50 || 0,
          right: coords.right || 0,
          position: 'absolute',
          display: 'inline-block',
          zIndex: 1000,
        }}
      />
    </RelativePortal>
  );
}
