import { ActionType } from '@/@types/common';
import { Status } from '@/@types/models';

import actions from '@/libs/actions';

type Types = Pick<
  typeof actions,
  'OPEN_ITEM_CREATE_DIALOG' | 'CLOSE_ITEM_CREATE_DIALOG'
>;

type Action = ActionType<
  Types,
  {
    OPEN_ITEM_CREATE_DIALOG: {
      payload: { statusId: Status['id']; isAddingLast: boolean };
    };
    CLOSE_ITEM_CREATE_DIALOG: {};
  }
>;
export interface INewItemDialogState {
  isOpen: boolean;
  addingStatus: Status['id'];
  isAddingLast: boolean;
}

const initialState: INewItemDialogState = {
  isOpen: false,
  addingStatus: undefined,
  isAddingLast: false,
};

export const newItemDialogReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case actions.OPEN_ITEM_CREATE_DIALOG:
      return {
        ...state,
        isOpen: true,
        addingStatus: action.payload.statusId || initialState.addingStatus,
        isAddingLast: action.payload.isAddingLast || initialState.isAddingLast,
      };
    case actions.CLOSE_ITEM_CREATE_DIALOG:
      return {
        ...state,
        isOpen: false,
        addingStatus: initialState.addingStatus,
        isAddingLast: initialState.isAddingLast,
      };
    default:
      return state;
  }
};

export const openNewItemDialog = (
  statusId?: Status['id'],
  isAddingLast?: boolean,
) => ({
  type: actions.OPEN_ITEM_CREATE_DIALOG,
  payload: { statusId, isAddingLast },
});

export const closeNewItemDialog = () => ({
  type: actions.CLOSE_ITEM_CREATE_DIALOG,
});

export default {};
