import { RootState } from '@/@types/models';
import { Transition } from '@headlessui/react';
import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { setNewProjectDialogState } from '@/reducers/dialogStateReducer';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import NewProjectDialog from '@/components/Project/NewProjectDialog';

/**
 * プロジェクト新規作成
 * @param props
 * @returns
 */
export default function NewProject() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialogState = useSelector((state: RootState) => state.dialogState);
  return (
    <>
      <div className="flex flex-col justify-center h-screen min-h-full px-6 -mt-10 lg:px-8flex">
        <div className="p-8 mx-auto border-4 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
          <div className="sm:w-full sm:max-w-lg ">
            <h2 className="text-xl font-extrabold text-center text-gray-600 dark:text-gray-400">
              {t(
                'このワークスペースにはまだプロジェクトがありません。新しいプロジェクトを作成しましょう！',
              )}
            </h2>
          </div>

          <div className=" sm:mx-auto sm:w-full sm:max-w-md">
            <div className="pt-7 sm:px-10">
              <MenuButton
                id="createProject"
                type="text"
                className="sm:w-full !py-2"
                variant="primary"
                onClick={() => dispatch(setNewProjectDialogState(true))}
              >
                {t('作成する')}
              </MenuButton>
            </div>
          </div>
        </div>
      </div>
      {/* ※ dialogStateReducerのGlobalなステートで開閉制御する */}
      <Transition.Root show={dialogState.newProjectDialog} unmount>
        <NewProjectDialog
          onClose={() => dispatch(setNewProjectDialogState(false))}
        />
      </Transition.Root>
    </>
  );
}
