import { RootState, Member, Account } from '@/@types/models';
import { t } from 'i18next';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { COL_MEMBERS, getMembersPath } from '@/libs/docPathUtils';

import { getUpdateSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * Membersの集約に関するHooks
 * @returns { members }
 */
export default function useMembers() {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();

  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  useFirestoreConnect(() =>
    workspaceId
      ? [
          {
            collection: getMembersPath(workspaceId),
            storeAs: COL_MEMBERS,
          },
        ]
      : [],
  );

  const members = useSelector(
    (state: RootState) => state.firestore.ordered.members || [],
  );

  /**
   * DisplayNameの更新
   * @param {string} uid
   * @param {string} displayName
   */
  const updateMember = async (
    uid: Account['uid'],
    displayName: Member['displayName'],
  ) => {
    exec(async () => {
      if (!uid || !workspaceId || !members) throw new Error();
      const currentMember = members.find((o) => o.uid === uid);
      if (!currentMember) throw new Error();

      rrfFirestore.update<Member>(
        `${getMembersPath(workspaceId)}/${currentMember.id}`,
        {
          displayName: displayName || null,
          ...getUpdateSystemFields(uid),
        },
      );
    }, t('アカウントの更新に失敗しました'));
  };

  return { members, updateMember };
}
