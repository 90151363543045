import { RootState, Profile } from '@/@types/models';
import { t } from 'i18next';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import { COL_PROFILES, getProfilesPath } from '@/libs/docPathUtils';

import { getUpdateSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * Profilesの集約に関するHooks
 */
export default function useProfiles() {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();

  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  useFirestoreConnect(() =>
    workspaceId
      ? [
          {
            collection: getProfilesPath(userId, workspaceId),
            doc: workspaceId,
            storeAs: COL_PROFILES,
          },
        ]
      : [],
  );

  const profile = useSelector((state: RootState) => {
    const d = state.firestore.ordered.profiles;
    if (d && d.length > 0) return d[0];
    return null;
  });

  /**
   * DisplayNameの更新
   * @param {string} uid
   * @param {string} displayName
   */
  const updateDisplayName = async (displayName: Profile['displayName']) => {
    exec(async () => {
      if (!profile || !workspaceId || !displayName) throw new Error();

      rrfFirestore.update<Profile>(
        `${getProfilesPath(userId, workspaceId)}/${workspaceId}`,
        {
          displayName,
          ...getUpdateSystemFields(userId),
        },
      );
    }, t('表示名の更新に失敗しました'));
  };

  /**
   * photoURLの更新
   * @param {string} uid
   * @param {string} photoURL
   */
  const updatePhotoURL = async (photoURL: Profile['photoURL']) => {
    exec(async () => {
      if (!profile || !workspaceId || !photoURL) throw new Error();

      rrfFirestore.update<Profile>(
        `${getProfilesPath(userId, workspaceId)}/${workspaceId}`,
        {
          photoURL,
          ...getUpdateSystemFields(userId),
        },
      );
    }, t('アバター画像の更新に失敗しました'));
  };

  /**
   * isConfiguredの更新
   * @param {string} uid
   * @param {boolean} isConfigured
   */
  const updateIsConfigured = async (isConfigured: Profile['isConfigured']) => {
    exec(async () => {
      if (!profile || !workspaceId || isConfigured === undefined)
        throw new Error();
      await rrfFirestore.update<Profile>(
        `${getProfilesPath(userId, workspaceId)}/${workspaceId}`,
        {
          isConfigured,
          ...getUpdateSystemFields(userId),
        },
      );
    }, t('設定に失敗しました'));
  };

  return { profile, updateDisplayName, updatePhotoURL, updateIsConfigured };
}
