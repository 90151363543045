import { Item, LikedUser } from '@/@types/models';
import { useMemo } from 'react';

import { PAGINATE } from '@/libs/const';
import { COL_LIKED_USERS, getLikedUsersPath } from '@/libs/docPathUtils';

import useAccessSettings from '@/hooks/useAccessSettings';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePaginate from '@/hooks/usePaginate';
import useProjects from '@/hooks/useProjects';

/**
 * likedUsersのリアルタイムアップデートを購読するためのHooks
 * 現在開いているアイテムの`いいね`のみサブスクリプションする
 * @returns { }
 */
export default function useLikedUsers(itemId: Item['id']) {
  const { isDisplayLiked } = useAccessSettings();
  const { currentMyProject } = useProjects();
  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );
  // ページネーション込みいいね取得
  const {
    dataList: likedUsers,
    hasNext,
    loadNextPage,
  } = usePaginate<LikedUser, 'likedUsers'>(
    getLikedUsersPath(workspaceId, projectId, itemId),
    undefined,
    ['likedAt', 'desc'],
    PAGINATE.LIKED_USERS,
    COL_LIKED_USERS,
    () => !workspaceId || !projectId || !itemId || !isDisplayLiked,
  );

  return {
    likedUsers,
    hasNext,
    loadNextPage,
  };
}
