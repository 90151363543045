import {
  IJoinedGuest,
  IJoinedGuestWithProjects,
  IJoinedMember,
} from '@/@types/models';
import { zodResolver } from '@hookform/resolvers/zod';
import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { z } from 'zod';

import { VALIDATION_MODE } from '@/libs/const';

import { t2s } from '@/libs/utils';

import { INVITE_EMAIL } from '@/libs/validations';

import GuestProjectAccountList from '@/components/Guest/GuestProjectAccountList';
import {
  IChengeAuth,
  IInviteEmail,
} from '@/components/Member/MemberAccountList';

import useAdminMembers from '@/hooks/useAdminMembers';
import useGuests from '@/hooks/useGuests';
import useInvitingGuests from '@/hooks/useInvitingGuests';
import useInvitingMembers from '@/hooks/useInvitingMembers';
import useMembers from '@/hooks/useMembers';
import useMonitorSubscription from '@/hooks/useMonitorSubscription';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useXGuests from '@/hooks/useXGuests';
import useXMembers from '@/hooks/useXMembers';

export default function Guests() {
  const { t } = useTranslation();
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const { invitingMembers } = useInvitingMembers();
  const { members } = useMembers();
  const { _members } = useXMembers();
  const { myProjectsXGuestsDic } = useXGuests();
  const { myProjectsGuestsDic } = useGuests();
  const { myProjectsInvitingGuestsDic } = useInvitingGuests();
  const { adminMember } = useAdminMembers();
  const [joinedMembers, setJoinedMembers] = useState<IJoinedMember[]>([]);
  const { currentMyWorkspace } = useMyWorkspaces();

  const [joinedGuests, setJoinedGuests] = useState<IJoinedGuestWithProjects[]>(
    [],
  );

  // ゲスト一覧
  useEffect(() => {
    const g = _.toPairs(myProjectsGuestsDic).map<IJoinedGuest>(
      ([userId, o]) => ({
        ...o,
        id: userId,
        email: myProjectsXGuestsDic[userId]?.email,
        joined: true,
        userIdAndProjectIds:
          o.projectId?.map((p) => ({ guestId: userId, projectId: p })) || [],
      }),
    );
    const ig = _.values(myProjectsInvitingGuestsDic).map<IJoinedGuest>((o) => ({
      id: o.id,
      email: o.email,
      joined: false,
      userIdAndProjectIds:
        o.projectId?.map((p) => ({ guestId: o.id || '', projectId: p })) || [],
    }));
    const grouped = _.groupBy([...g, ...ig], 'email');
    const merged = _.mapValues(
      grouped,
      (l) =>
        _.mergeWith(
          { userIdAndProjectIds: [] },
          ...l,
          (objValue: any, srcValue: any) => {
            if (_.isArray(objValue)) {
              return objValue.concat(srcValue);
            }
            return undefined;
          },
        ) as IJoinedGuestWithProjects,
    );
    const toArray = _.values(merged);
    setJoinedGuests(toArray);
  }, [myProjectsGuestsDic, myProjectsInvitingGuestsDic, myProjectsXGuestsDic]);

  // メールアドレス重複チェック（mock）
  let checkDuplicateEmail = (v: string) => typeof v === 'string';
  let checkAuthRule = (v: IChengeAuth) => typeof v === 'object';

  // Zod schema定義
  const schema = z.object({
    removeIds: z.array(z.string()),
    inviteRemoveIds: z.array(z.string()),
    inviteEmails: z.array(
      z.object({
        value: z.preprocess(
          (val) => String(val).trim(),
          z
            .string()
            .min(1, { message: t('入力してください') })
            .email(t2s(t('正しいメールアドレスを入力してください')))
            .max(
              INVITE_EMAIL.max,
              t2s(t('<max>文字以内で入力してください', INVITE_EMAIL)),
            )
            .refine(
              (v) => checkDuplicateEmail(v),
              t2s(t('<label>が重複しています', { label: t('メールアドレス') })),
            ),
        ),
      }),
    ),
    changeAuths: z.array(
      z
        .any()
        .refine(
          (v) => checkAuthRule(v),
          t2s(t('最低1人は管理者権限を設定してください')),
        ),
    ),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues: {
      removeIds: [] as string[],
      inviteRemoveIds: [] as string[],
      inviteEmails: [] as IInviteEmail[],
      changeAuths: [] as IChengeAuth[],
    },
  });

  // joinedMembersを生成
  useEffect(() => {
    const m = members.map<IJoinedMember>((o) => ({
      ...o,
      email: _members.find((p) => p.id === o.id)?.email,
      authType: adminMember.memberList?.find((id) => id === o.id)
        ? 'administrator'
        : 'member',
      joined: true,
    }));
    const im = invitingMembers.map<IJoinedMember>((o) => ({
      id: o.id,
      email: o.email,
      joined: false,
    }));
    setJoinedMembers([...m, ...im]);
    setTimeout(() => {
      // snapshot監視でデータが変化した場合、バリデーションを実施する
      methods.trigger();
    });
  }, [members, _members, invitingMembers, adminMember]);

  // メールアドレス重複チェック
  checkDuplicateEmail = (v: string) => {
    const { removeIds, inviteRemoveIds, inviteEmails } = methods.getValues();

    const tgt = joinedMembers.filter(
      (o) =>
        !removeIds.includes(o.id as string) &&
        !inviteRemoveIds.includes(o.id as string),
    );
    const res = tgt.some((o) => o.email === v);
    const exists = res || inviteEmails.some((f) => f.value === v);
    return !exists;
  };

  // 現在の管理者IDリストを取得
  const getAdmins = useCallback(() => {
    const { changeAuths, removeIds } = methods.getValues();
    const mIds = changeAuths
      .filter((co) => co.value === 'member')
      .map((m) => m.id);
    const aIds = changeAuths
      .filter((co) => co.value === 'administrator')
      .map((m) => m.id);
    const admins = _.difference(
      _.union(_.difference(adminMember.memberList, mIds), aIds),
      removeIds,
    );
    return admins || [];
  }, [
    methods.getValues().changeAuths,
    methods.getValues().removeIds,
    adminMember.memberList,
  ]);

  // 更新後のメンバーに管理者が存在するかチェック
  checkAuthRule = (v: IChengeAuth) => {
    if (v.value === 'administrator') return true;
    return getAdmins().length > 0;
  };

  // 必要なデータのサブスクリプション状況をキーの有無で検知
  // 動的にストア名を振っているものはanyで対応
  const { isSubscriptionPrepared } = useMonitorSubscription([
    'invitingMembers',
    'members',
    '_members',
    'adminMembers',
  ]);

  // 必要な処理終了後はサブスクリプション & レンダリング
  if (!currentMyWorkspace || !isSubscriptionPrepared) return <div />;

  return (
    <div className="px-4 pt-4">
      <h1 className="flex items-center mb-6 text-xl font-semibold xs:text-l justify-content-center">
        {t('ワークスペースのゲスト')}
      </h1>
      <div className="mb-6">
        {t('参加中のゲスト数：')}
        {joinedGuests.length}
      </div>
      <GuestProjectAccountList
        className="max-w-3xl mt-5"
        accounts={joinedGuests}
      />
      <div ref={scrollBottomRef} />
    </div>
  );
}
