import { Guest } from '@/@types/models';
import { IAccountDictionary, IMention } from '@/@types/viewItem';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useFirestoreConnect } from 'react-redux-firebase';

import { COL_REMOVED_GUESTS, getRemovedGuestsPath } from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * RemovedGuestsの集約に関するHooks
 * @returns { removedGuests }
 */
export default function useRemovedGuests() {
  const { currentMyWorkspace } = useMyWorkspaces();
  const { currentMyProject, myProjects } = useProjects();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  // サブスクリプション
  useFirestoreConnect(() => {
    if (!workspaceId || !myProjects) return [];
    return myProjects.map((p) => ({
      collection: getRemovedGuestsPath(workspaceId, p.id),
      storeAs: `${COL_REMOVED_GUESTS}/${p.id}`,
    }));
  });

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);

  const removedGuests: Guest[] = useMemo(() => {
    if (projectId && ordered[`${COL_REMOVED_GUESTS}/${projectId}`])
      return ordered[`${COL_REMOVED_GUESTS}/${projectId}`];
    return [];
  }, [ordered, projectId]);

  /**
   * メンバー一覧の表示名 画像リンクを取得するための辞書
   * [uid]: { photoURL, displayName }
   * @returns {IMemberDictionary}
   */
  const removedGuestsDic = useMemo(
    () =>
      removedGuests.reduce<IAccountDictionary>((acc, cur) => {
        const value: IAccountDictionary['uid'] = {
          photoURL: cur.photoURL || null,
          displayName: cur.displayName ?? '',
          joinType: 'guest',
        };
        acc[cur.id as string] = value;

        return acc;
      }, {}),
    [removedGuests],
  );

  const removedGuestMentions = useMemo(() => {
    const mentions = removedGuests.map((o) => {
      const mention: IMention = {
        id: o.uid,
        photoURL: o.photoURL || null,
        value: o.uid,
        name: o.displayName || '',
      };
      return mention;
    });
    return mentions;
  }, [removedGuests]);

  const myProjectsRemovedGuestsDic = useMemo(
    () =>
      myProjects.reduce<IAccountDictionary>((acc, cur) => {
        const values = ordered[`${COL_REMOVED_GUESTS}/${cur.id}`];
        if (!values) return acc;
        values.forEach((v: any) => {
          const dic: IAccountDictionary['uid'] = {
            photoURL: null,
            displayName: v.displayName ?? '',
            joinType: 'guest',
          };
          acc[v.id] = dic;
        });
        return acc;
      }, {}),
    [ordered],
  );

  return {
    removedGuests,
    removedGuestsDic,
    removedGuestMentions,
    myProjectsRemovedGuestsDic,
  };
}
