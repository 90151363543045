import { IFuncManageMembers, FunctionsResult } from '@/@types/common';
import { InvitingMember, Workspace } from '@/@types/models';
import { doc, getDoc } from 'firebase/firestore';

import { httpsCallable } from 'firebase/functions';
import i18next from 'i18next';

import { REP_ERROR_MESSAGE } from '@/libs/const';

import { getInvitingMembersPath } from '@/libs/docPathUtils';
import { fsConverter, repositoryError } from '@/libs/utils';

import { functions } from '@/firebase';
import { firestore } from '@/firestore';

export interface IInvitingMemberRepository {
  findById: (
    workspaceId: Workspace['id'],
    invitingMemberId: string,
  ) => Promise<InvitingMember | null>;
}

const funcManageMembers = httpsCallable<IFuncManageMembers, FunctionsResult>(
  functions,
  'manageMembers',
);

export default class InvitingMemberRepository
  implements IInvitingMemberRepository
{
  /**
   * ワークスペースID 招待メンバーIDを指定してレコード単体取得
   * @param workspaceId
   * @param invitingMemberId
   */
  async findById(
    workspaceId: Workspace['id'],
    invitingMemberId: InvitingMember['id'],
  ) {
    try {
      const path = `${getInvitingMembersPath(workspaceId)}/${invitingMemberId}`;
      const docRef = doc(firestore, path).withConverter(
        fsConverter<InvitingMember>(),
      );
      const d = await getDoc(docRef);
      const invitingMember = d.data();
      if (invitingMember === undefined) {
        return null;
      }
      invitingMember.id = d.id;
      return invitingMember;
    } catch (err) {
      repositoryError(this.findById.name, err);
      throw new Error(REP_ERROR_MESSAGE);
    }
  }

  async applyMemberSetting(data: Omit<IFuncManageMembers, 'language'>) {
    try {
      const res = await funcManageMembers({
        ...data,
        language: i18next.resolvedLanguage,
      });
      return res.data;
    } catch (error) {
      throw new Error('Members update failed.');
    }
  }
}
