/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ProjectDescription } from '@/@types/models';
import {
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
} from '@heroicons/react/24/outline';
import { t } from 'i18next';
import { RootNode } from 'lexical';
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useDispatch } from 'react-redux';

import { classNames } from '@/libs/styleUtils';

import { closeProjectDescriptionEmojiPopup } from '@/reducers/currentProjectReducer';

import ConfirmDialog from '@/components/Common/ConfirmDialog';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import LexicalReadOnlyEditor from '@/components/Common/Lexical/LexicalReadOnlyEditor';

import ProjectDescriptionEditor, {
  IProjectDescriptionEditorHandler,
} from '@/components/Common/Lexical/ProjectDescriptionEditor';

import useAccessSettings from '@/hooks/useAccessSettings';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

import useProjectDescriptions from '@/hooks/useProjectDescriptions';
import useProjects from '@/hooks/useProjects';

// 外部コンポーネントから命令的に実行できるインターフェース
export type IDescriptionHandler = {
  setFocus: () => void;
  setBlur: () => void;
};

export interface IDescriptionProps {}

/**
 * プロジェクトの説明
 * @param props
 * @returns
 */
const Description = forwardRef<IDescriptionHandler, IDescriptionProps>(
  (props: IDescriptionProps, ref) => {
    const { isDisplayDescription } = useAccessSettings();
    const { isMember } = useMyWorkspaces();
    const { updateDescriptionVisibility, currentMyProject } = useProjects();
    const { projectDescription, isEmptyDescription, updateDescription } =
      useProjectDescriptions();
    const dispatch = useDispatch();

    /** 即時反映のように見せかける一時記憶領域 */
    const [tempEditorState, setTempEditorState] =
      useState<ProjectDescription['editorState']>(undefined);

    /** 破棄確認ダイアログ */
    const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false);

    const editorRef = useRef({} as IProjectDescriptionEditorHandler);

    /** 編集状態 */
    const [editable, setEditable] = useState(false);

    /** テキスト差分チェック用 */
    const [oldEditorState, setOldEditorState] = useState<string | undefined>();

    const focusHandler = useCallback(() => {
      if (!isMember) return;
      if (projectDescription?.editorState)
        editorRef.current.setContent(projectDescription?.editorState);
      setEditable(true);
      editorRef.current.setEditorFocus();
    }, [isMember, projectDescription?.editorState]);

    /** 表示状態変更処理 */
    const displayStateHandler = useCallback(() => {
      updateDescriptionVisibility(!currentMyProject?.isShowDescription);
    }, [isMember, updateDescriptionVisibility, currentMyProject]);

    /** 送信処理 */
    const sendHandler = useCallback(
      (jsonState: string, textState: string) => {
        updateDescription(jsonState, textState);
        setTempEditorState(jsonState);
        setEditable(false);
        setOldEditorState(undefined);
      },
      [updateDescription],
    );

    /** データが更新された場合は一時領域をクリア */
    useEffect(() => {
      setTempEditorState(undefined);
    }, [projectDescription]);

    const [isDirty, setDirty] = useState<boolean>(false);

    const handleChange = useCallback(
      // eslint-disable-next-line no-shadow
      (lexicalRootNode: RootNode, isDirty: boolean, htmlString: string) => {
        if (!oldEditorState) {
          setOldEditorState(htmlString);
          setDirty(false);
        } else {
          setDirty(oldEditorState !== htmlString);
        }
      },
      [oldEditorState],
    );

    /** 編集モードを終了 */
    const unEditable = useCallback(() => {
      setOldEditorState(undefined);
      setEditable(false);
      dispatch(closeProjectDescriptionEmojiPopup());
    }, []);

    /** タブキーのモニタリング */
    const tabKeyMonitoring = useCallback((e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault();
      }
    }, []);

    /** モード実行中はタブキーを無効にする */
    useEffect(() => {
      if (editable) {
        window.addEventListener('keydown', tabKeyMonitoring, false);
      } else {
        window.removeEventListener('keydown', tabKeyMonitoring);
      }
    }, [editable]);

    /** フォーカスを失った処理 */
    const blurHandler = useCallback(() => {
      if (isDirty) {
        setDiscardDialogOpen(true);
        return;
      }
      unEditable();
      editorRef.current.resetContent();
    }, [isDirty]);

    /** 破棄確認 */
    const handleDiscardConfirm = useCallback(
      (feedback: boolean | undefined) => {
        setDiscardDialogOpen(false);
        if (feedback) unEditable();
      },
      [],
    );

    // 外部コンポーネントから命令的に実行できるメソッド
    useImperativeHandle(ref, () => ({
      setFocus() {
        focusHandler();
      },
      setBlur() {
        blurHandler();
      },
    }));

    return (
      <>
        <div className={classNames(editable ? '' : 'hidden')}>
          <div className="relative z-30 mt-2">
            <ProjectDescriptionEditor
              ref={editorRef}
              className="!text-sm rounded-md"
              onSend={sendHandler}
              onEditCancel={blurHandler}
              handleChange={handleChange}
              isDirty={isDirty}
            />
          </div>
          <div
            className="fixed top-0 bottom-0 left-0 right-0 z-20 w-screen h-screen bg-transparent"
            onClick={blurHandler}
          />
        </div>
        {!editable && isDisplayDescription && !isEmptyDescription && (
          <>
            {currentMyProject?.isShowDescription && (
              <div className="p-[1px]">
                <div
                  className={classNames(
                    'border border-transparent rounded-md',
                    isMember
                      ? 'mt-2 hover:border-gray-200 dark:hover:border-gray-700 hover:cursor-pointer'
                      : '',
                  )}
                  onClick={focusHandler}
                >
                  <LexicalReadOnlyEditor
                    className="px-4 py-3 overflow-y-auto max-h-64 md:max-h-72 lg:max-h-80 xl:max-h-96"
                    initialData={
                      tempEditorState || projectDescription?.editorState || ''
                    }
                  />
                </div>
              </div>
            )}
            <div className={classNames(!isEmptyDescription ? 'mt-1' : 'mt-3')}>
              <MenuButton
                id="projectDescriptionToggle"
                type="text"
                className="w-auto mt-6 text-xs font-normal border-0 shadow-none !px-2 focus:ring-offset-0 ring-0 !ring-transparent dark:!text-gray-400"
                onClick={displayStateHandler}
              >
                {currentMyProject?.isShowDescription ? (
                  <ChevronDoubleUpIcon className="w-3 h-3" aria-hidden="true" />
                ) : (
                  <ChevronDoubleDownIcon
                    className="w-3 h-3"
                    aria-hidden="true"
                  />
                )}
                <div className="ml-1">
                  {currentMyProject?.isShowDescription
                    ? t('説明を非表示')
                    : t('説明を表示')}
                </div>
              </MenuButton>
            </div>
          </>
        )}
        {/* 編集破棄確認ダイアログ */}
        <ConfirmDialog
          key="project-description-discard-confirm"
          open={discardDialogOpen}
          onClose={handleDiscardConfirm}
          title={t('プロジェクト説明の編集を破棄')}
          positive={t('破棄')}
          warning
        >
          <p className="mb-2">
            {t('プロジェクト説明の編集を破棄しようとしています。')}
          </p>
          <p>
            {t('破棄された内容は復元できません。<br> 本当によろしいですか？', {
              br: <br />,
            })}
          </p>
        </ConfirmDialog>
      </>
    );
  },
);
export default Description;
