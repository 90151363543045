import { EV } from '@/@types/events';

import { PlusIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '@/libs/styleUtils';

interface IImageInputProps {
  fileName: string;
  handleFileLoad: (e: EV<HTMLInputElement>) => void;
  isUploadLimit?: boolean;
}

/**
 * 画像アップロード用のInputコンポーネント
 * @param props
 * @returns
 */
export default function ImageInput(props: IImageInputProps) {
  const { fileName, handleFileLoad, isUploadLimit } = props;
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  /**
   * 添付ファイル選択
   * ※ fileInputRefのクリックイベント発火のみ
   *    ファイル読み込み時処理は`handleFileLoad`に続く
   */
  const selectLocalFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  return (
    <div className="w-full h-full">
      <div
        className={classNames(
          'flex flex-row w-full h-full p-0 text-left text-gray-700 bg-white border border-gray-300 dark:bg-gray-700 focus:outline-none rounded-md dark:border-gray-600 shadow-sm dark:text-gray-300  focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 min-h-[2.4rem]',
          !isUploadLimit ? 'hover:bg-gray-50' : '',
        )}
      >
        {!isUploadLimit && (
          <button
            type="button"
            className="flex flex-row w-full  text-left text-gray-500  dark:text-gray-300 dark:hover:text-primary-600 hover:text-primary-600 gap-1"
            onClick={selectLocalFile}
          >
            {!isUploadLimit && (
              <div className="flex items-center px-3 py-2 basis-1/4">
                <PlusIcon
                  className="flex items-center inline-block w-4 h-4"
                  aria-hidden="true"
                />
                <div className="flex items-center inline-block align-text-top">
                  {t('画像を追加')}
                </div>
              </div>
            )}

            {fileName !== '' && (
              <div className="items-center flex-auto px-3 py-2">{fileName}</div>
            )}
            {fileName === '' && (
              <div className="items-center flex-auto px-3 py-2">
                {t('画像が選択されていません')}
              </div>
            )}
          </button>
        )}
        {isUploadLimit && (
          <button
            disabled
            type="button"
            className="flex flex-row w-full text-left text-gray-500  dark:text-gray-300 gap-1"
          >
            <div className="items-center flex-auto px-3 py-2">
              {t('ファイル上限数（1アイテムあたり20件まで）に達しています')}
            </div>
          </button>
        )}

        {/* ファイル読み込み処理時利用する<input> */}
        {!isUploadLimit && (
          <input
            type="file"
            accept="image/gif,image/jpeg,image/png"
            ref={fileInputRef}
            className="hidden"
            onChange={handleFileLoad}
          />
        )}
      </div>
    </div>
  );
}
