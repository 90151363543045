import { Status, StatusOrder } from '@/@types/models';
import { useMemo } from 'react';

/**
 * statusOrdersによってステータスをソートするHook
 * @param {Status[]} statuses
 * @param {StatusOrder} statusOrder
 * @returns {Status[]} ソートされたステータス
 */
export default function useOrderedStatuses(
  statuses: Status[],
  statusOrder: StatusOrder,
) {
  // TODO: 可能であれば処理最適化
  return useMemo(() => {
    if (!statusOrder.orderList || statusOrder.orderList.length === 0) {
      return [];
    }
    return statusOrder.orderList.reduce<Status[]>((acc, so) => {
      const found = statuses.find((s) => s.id === so);
      if (!found) return acc;

      acc.push(found);

      return acc;
    }, []);
  }, [statuses, statusOrder]);
}
