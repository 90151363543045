import { originalColors, OriginalColorType } from '@/@types/common';
import { IPropertyOptionType } from '@/@types/models';

import { Bars2Icon, PlusIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useRef, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import RelativePortal from 'react-relative-portal';

import { classNames } from '@/libs/styleUtils';

import FormButton from '@/components/Common/Forms/Buttons/FormButton';

import PropertyOptionInput from '@/components/Items/PropertyOptionInput';

export interface IPropertyDefaultValues {
  propertyTypeOptions: IPropertyOptionType[];
}

export interface ISingleSelectFormProps {
  className?: string;
}

/**
 * シングルセレクト型プロパティ編集フォーム
 * @param props
 * @returns
 */
export default function SingleSelectForm(props: ISingleSelectFormProps) {
  const { t } = useTranslation();
  const { className } = props;
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const [optionId, setOptionId] = useState<number>(0); // 自動選択色解決用の連番

  // 親コンポーネントのフォームコンテキスト
  const { control, trigger, getValues } =
    useFormContext<IPropertyDefaultValues>();

  // バッジ表示時の色を解決する
  const resolveColor = (): OriginalColorType => {
    const colorKey = optionId % originalColors.length;
    return originalColors[colorKey];
  };

  // プロパティ選択肢フィールド
  const { fields, append, remove, update, move } = useFieldArray({
    control,
    name: 'propertyTypeOptions',
    keyName: 'key',
  });

  // プロパティ削除
  const onRemoveOption = (id: number) => remove(id);

  // プロパティ追加
  const onAppendOption = (silent = false) => {
    append({
      id: new Date().getTime().toString(),
      text: '',
      color: resolveColor(),
    });
    if (!silent) trigger(); // バリデーション発火
    setTimeout(() => {
      scrollBottomRef!.current!.scrollIntoView({ behavior: 'smooth' });
    });
    setOptionId(optionId + 1);
  };

  // オプションカラー更新
  const onUpdateOption = (idx: number, value: OriginalColorType) => {
    const values = getValues();
    if (
      fields[idx] === undefined ||
      values.propertyTypeOptions[idx] === undefined
    )
      return;
    const target = fields[idx];
    const { text } = values.propertyTypeOptions[idx];
    // ※ text値が""のままなのでgetValues()で取得した値で上書き
    if (target) {
      const newOption = {
        ...target,
        text,
        color: value,
      };
      update(idx, newOption);
    }
  };

  // ドラッグ終了時ハンドラ
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    // リストの外にD&Dした場合は何もしない
    if (!destination) return null;
    const sIndex = source.index;
    const dIndex = destination.index;

    // 別の列にD&Dした場合は移動
    if (sIndex === dIndex) return null;
    return move(sIndex, dIndex);
  };

  // マウント時処理
  useEffect(() => {
    const options = getValues('propertyTypeOptions');
    if (options.length === 0) {
      onAppendOption(false);
    }
  }, []);

  return (
    <div
      className={classNames(
        className,
        'overflow-y-scroll border border-t border-gray-200 flow-root rounded-md dark:border-gray-700 max-h-96',
      )}
    >
      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
        <li className="block px-4 py-3 font-medium text-gray-700 dark:text-gray-400">
          {t('選択肢')}
        </li>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="status-setting">
            {(providedDroppable) => (
              <div
                ref={providedDroppable.innerRef}
                {...providedDroppable.droppableProps}
                className="flex flex-wrap py-3 space-y-3"
              >
                {fields.map((option, idx) => (
                  <li key={option.id} className="w-full">
                    <Draggable
                      isDragDisabled={false}
                      draggableId={option.id as string}
                      index={idx}
                    >
                      {(providedDraggable, snapshot) => {
                        const elem = (
                          <div
                            className="flex pl-3 pr-2 space-x-3"
                            ref={providedDraggable.innerRef}
                            {...providedDraggable.draggableProps}
                            {...providedDraggable.dragHandleProps}
                          >
                            <div className="flex-shrink-0 m-auto hover:dark:text-gray-600 dark:text-gray-500">
                              <Bars2Icon className="w-4 h-4 cursor-grab" />
                            </div>
                            <div className="flex-grow">
                              <PropertyOptionInput
                                option={option}
                                idx={idx}
                                name="propertyTypeOptions"
                                property="text"
                                onDelete={() => onRemoveOption(idx)}
                                onChangeColor={onUpdateOption}
                              />
                            </div>
                          </div>
                        );
                        return snapshot.isDragging ? (
                          <RelativePortal component="div">
                            {elem}
                          </RelativePortal>
                        ) : (
                          elem
                        );
                      }}
                    </Draggable>
                  </li>
                ))}
                {providedDroppable.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <li
          key="property-add-btn"
          className="sticky bottom-0 px-0 py-0 text-center"
        >
          <FormButton
            id="addSelectItem"
            className="flex items-center justify-center border-0 rounded-none outline-none py-3.5 ring-0 focus:ring-0 focus:!ring-offset-0"
            onClick={() => onAppendOption(false)}
          >
            <PlusIcon
              className="inline-block w-4 h-4 my-auto"
              aria-hidden="true"
            />
            <div className="inline-block ml-2 align-text-top">
              {t('選択肢を追加')}
            </div>
          </FormButton>
        </li>
      </ul>
      <div ref={scrollBottomRef} />
    </div>
  );
}
