import { ActionType } from '@/@types/common';

import actions from '@/libs/actions';

type Types = Pick<typeof actions, 'SET_NEW_VIEW_FLAG'>;

type Action = ActionType<Types, { SET_NEW_VIEW_FLAG: { payload: boolean } }>;

export interface IViewState {
  newViewFlg: boolean;
}

const initialState: IViewState = {
  newViewFlg: false,
};

export const viewsReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_NEW_VIEW_FLAG:
      return { ...state, newViewFlg: action.payload };
    default:
      return state;
  }
};

export const setNewViewFlag = (flag: boolean) => ({
  type: actions.SET_NEW_VIEW_FLAG,
  payload: flag,
});

export default {};
