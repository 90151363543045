import { AccessSetting, RootState } from '@/@types/models';
import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_ACCESS_SETTINGS,
  getAccessSettingsPath,
} from '@/libs/docPathUtils';

import { getUpdateSystemFields } from '@/libs/utils';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';

/**
 * AccessSettingsの集約に関するHooks
 * @returns { removedMembers }
 */
export default function useAccessSettings() {
  const { currentMyProject } = useProjects();
  const { currentMyWorkspace, isMember } = useMyWorkspaces();

  const rrfFirestore = useFirestore();
  const { t } = useTranslation();
  const { exec } = useHandleApi();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );
  const projectId = useMemo(
    () => currentMyProject?.id ?? undefined,
    [currentMyProject?.id],
  );

  useFirestoreConnect(() =>
    workspaceId && projectId
      ? [
          {
            collection: getAccessSettingsPath(workspaceId, projectId),
            storeAs: COL_ACCESS_SETTINGS,
          },
        ]
      : [],
  );

  const accessSettings = useSelector(
    (state: RootState) => state.firestore.ordered.accessSettings || [],
  );

  const accessSetting = useMemo(
    () => (accessSettings.length > 0 ? accessSettings[0] : null),
    [accessSettings, projectId],
  );

  /**
   * 公開設定を更新
   * @param docId
   * @param isBodyVisible
   * @param isCommentVisible
   * @param isLikeVisible
   * @param isDescriptionVisible
   */
  const updateAccessSetting = async (
    docId: string,
    isBodyVisible: boolean,
    isCommentVisible: boolean,
    isLikeVisible: boolean,
    isDescriptionVisible: boolean,
  ) => {
    exec(async () => {
      if (!workspaceId || !projectId || !userId)
        throw new Error('updateAccessSettings error');

      const newAccessSettings: AccessSetting = {
        isBodyVisible,
        isCommentVisible,
        isLikeVisible,
        isDescriptionVisible,
        ...getUpdateSystemFields(userId),
      };

      rrfFirestore.update<AccessSetting>(
        `${getAccessSettingsPath(workspaceId, projectId)}/${docId}`,
        newAccessSettings,
      );
    }, t('公開設定の更新に失敗しました。'));
  };

  // コメントを表示するか
  const isDisplayComment = useMemo(
    () => isMember || accessSetting?.isCommentVisible === true,
    [isMember, accessSetting],
  );

  // いいねを表示するか
  const isDisplayLiked = useMemo(
    () => isMember || accessSetting?.isLikeVisible === true,
    [isMember, accessSetting],
  );

  // プロジェクト概要を表示するか
  const isDisplayDescription = useMemo(
    () => isMember || accessSetting?.isDescriptionVisible === true,
    [isMember, accessSetting],
  );

  return {
    accessSetting,
    updateAccessSetting,
    isDisplayComment,
    isDisplayLiked,
    isDisplayDescription,
  };
}
