import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { FcGoogle } from 'react-icons/fc';

import { OAUTH_PROVIDER_ICON_OPTION } from '@/libs/const';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import useFirebaseAuth from '@/hooks/useFirebaseAuth';
import useLogin from '@/hooks/useLogin';

interface IGoogleLogin {
  label?: string;
}

/**
 * Google OAuth
 * @param props
 * @returns
 */
export default function GoogleLogin(props: IGoogleLogin) {
  const { t } = useTranslation();
  const { label } = props;
  const { loginWithGoogle } = useFirebaseAuth();
  const login = useLogin();

  const onClickHandler = useCallback(async () => {
    await login(loginWithGoogle);
  }, []);

  const buttonLabel = useMemo(() => label || t('Googleアカウント'), [label]);

  return (
    <MenuButton
      id="loginGoogle"
      type="text"
      onClick={onClickHandler}
      className="!py-2"
    >
      <span className="sr-only">Sign in with Google</span>
      <IconContext.Provider value={OAUTH_PROVIDER_ICON_OPTION}>
        <FcGoogle className="w-5 h-5" />
        <span className="ml-2">{buttonLabel}</span>
      </IconContext.Provider>
    </MenuButton>
  );
}
