import { IViewRow } from '@/@types/viewItem';
import { ClockIcon, PlusIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { classNames, getOriginalColorClass } from '@/libs/styleUtils';

import { openNewItemDialog } from '@/reducers/newItemDialogReducer';

import EllipsisText from '@/components/Common/EllipsisText';
import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';

import Checkbox from '@/components/Common/Forms/Checkbox';
import {
  isSelectPublishItemModeContext,
  itemContext,
} from '@/components/Share/SelectPublishItemsContext';

import useItems from '@/hooks/useItems';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import useProjects from '@/hooks/useProjects';
import useViewItems from '@/hooks/useViewItems';

export interface IViewStatusHeaderProps {
  row: IViewRow;
  className?: string;
}

/**
 * ビューのステータスヘッダコンポーネント
 * @param props
 * @returns
 */
export default function ViewStatusHeader(props: IViewStatusHeaderProps) {
  const { row, className } = props;
  const { status } = row;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMember } = useMyWorkspaces();
  const isSelectPublishItemMode = useContext(isSelectPublishItemModeContext);
  const { checkedStatusIds, checkStatusId } = useContext(itemContext);
  const { viewItems, isExistsHighlight } = useViewItems();
  const { isItemLimitOver } = useItems();

  const originalColor = useMemo(
    () => getOriginalColorClass(status.statusColor),
    [status.statusColor],
  );
  const { isReadOnly } = useProjects();

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const itemLength = useMemo(
    () => viewItems.filter((i) => i.statusId === status.id).length,
    [status, viewItems],
  );

  const highlightCount = useMemo(() => {
    let c = 0;
    row.viewItems.forEach((item) => {
      if (isExistsHighlight(item)) c += 1;
    });
    return c;
  }, [row, isExistsHighlight]);

  return (
    <div
      className={classNames(
        'flex items-center pl-3 pr-1 py-1 rounded-md',
        'text-gray-800 dark:text-gray-100 h-8 w-full',
        className,
        originalColor?.bg,
        originalColor?.text,
      )}
    >
      <EllipsisText>{status.statusName}</EllipsisText>
      <span className="inline-flex items-center justify-center h-4 px-1 ml-4 mr-2 text-gray-800 bg-white rounded dark:bg-gray-800 dark:text-gray-100 whitespace-nowrap">
        {itemLength}
      </span>
      {highlightCount > 0 && (
        <div className="flex items-center flex-shrink-0 whitespace-nowrap">
          <ClockIcon className="w-3 h-3 ml-2 mr-1" />
          <span className="ml-0">{highlightCount}</span>
        </div>
      )}
      {/** アイテム選択モードの場合は選択チェックボックスを表示 */}
      {isSelectPublishItemMode && status.id && (
        <div className="ml-auto mr-2">
          <Checkbox
            name="selected"
            checked={checkedStatusIds.includes(status.id)}
            onChange={() => checkStatusId(status.id as string)}
          />
        </div>
      )}
      {!isSelectPublishItemMode && (isMember || !isReadOnly) && (
        <div
          className="ml-auto"
          data-tip={
            !isItemLimitOver
              ? t('アイテムを追加')
              : t(
                  'アイテム数上限に達しました。アイテムを作成したい場合は、既存のアイテムを削除してください。',
                )
          }
        >
          <MenuButton
            id="showNewItem"
            type="icon"
            variant="warning"
            className={classNames(
              originalColor?.text,
              originalColor?.bgHover,
              originalColor?.textHover,
              isItemLimitOver
                ? 'hover:!bg-transparent !bg-transparent hover:!text-black/20 !text-black/20'
                : '',
            )}
            onClick={() => dispatch(openNewItemDialog(status.id, false))}
            disabled={isItemLimitOver}
          >
            <PlusIcon className="w-3 h-3" aria-hidden="true" />
          </MenuButton>
        </div>
      )}
    </div>
  );
}
