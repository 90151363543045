import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { classNames } from '@/libs/styleUtils';

export interface ILinkButtonProps {
  id: string;
  className?: string;
  children: ReactNode;
  primary?: boolean;
  disabled?: boolean;
  to: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export default function LinkButton(props: ILinkButtonProps) {
  const { id, className, children, primary, disabled, to, onClick } = props;
  function getColorTheme() {
    if (disabled) {
      return `cursor-not-allowed text-gray-50 bg-gray-400 dark:text-gray-500 dark:bg-gray-700 opacity-40 border-transparent ${
        !primary ? 'sm:mt-0' : ''
      }`;
    }
    if (primary) {
      return 'text-white border border-transparent dark:text-white bg-primary-500 dark:bg-primary-600 shadow-sm hover:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600';
    }
    return 'text-gray-500 bg-white border border-gray-300 dark:border-gray-600 hover:bg-gray-100 focus:outline-none ring-0 focus:ring-2 dark:ring-gray-500 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600';
  }

  return (
    <Link
      id={id}
      to={to}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (disabled) {
          e.preventDefault();
        } else if (onClick) {
          onClick(e);
        }
      }}
      className={classNames(
        className,
        'inline-flex items-center px-4 py-2 font-medium rounded-md ',
        getColorTheme(),
      )}
    >
      {children}
    </Link>
  );
}
