import { ActionType } from '@/@types/common';
import { Project } from '@/@types/models';

import actions from '@/libs/actions';

type Types = Pick<
  typeof actions,
  | 'SET_CURRENT_PROJECT'
  | 'OPEN_PROJECT_DESCRIPTION_EMOJI_POPUP'
  | 'CLOSE_PROJECT_DESCRIPTION_EMOJI_POPUP'
>;

type Action = ActionType<
  Types,
  {
    SET_CURRENT_PROJECT: { payload: Project['id'] };
    OPEN_PROJECT_DESCRIPTION_EMOJI_POPUP: {};
    CLOSE_PROJECT_DESCRIPTION_EMOJI_POPUP: {};
  }
>;

export interface IProjectState {
  currentProjectId: Project['id'];
  isDescriptionEmojiOpen: boolean;
}

const initialState: IProjectState = {
  currentProjectId: '',
  isDescriptionEmojiOpen: false,
};

export const currentProjectReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_CURRENT_PROJECT:
      return {
        ...state,
        currentProjectId: action.payload,
      };
    case actions.OPEN_PROJECT_DESCRIPTION_EMOJI_POPUP:
      return {
        ...state,
        isDescriptionEmojiOpen: true,
      };
    case actions.CLOSE_PROJECT_DESCRIPTION_EMOJI_POPUP:
      return {
        ...state,
        isDescriptionEmojiOpen: false,
      };

    default:
      return state;
  }
};

export const setCurrentProjectIdAction = (
  pid: Project['id'] | null | undefined,
) => ({
  type: actions.SET_CURRENT_PROJECT,
  payload: pid,
});

export const openProjectDescriptionEmojiPopup = () => ({
  type: actions.OPEN_PROJECT_DESCRIPTION_EMOJI_POPUP,
});

export const closeProjectDescriptionEmojiPopup = () => ({
  type: actions.CLOSE_PROJECT_DESCRIPTION_EMOJI_POPUP,
});

export default {};
