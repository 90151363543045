import { Account } from '@/@types/models';
import firebase from 'firebase/compat/app';
import i18next from 'i18next';
import React, { memo, ReactNode } from 'react';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/database';

import { Provider } from 'react-redux';
import {
  firebaseReducer,
  ReactReduxFirebaseProvider,
  ReactReduxFirebaseProviderProps,
} from 'react-redux-firebase';
import {
  // eslint-disable-next-line camelcase
  legacy_createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import {
  createFirestoreInstance,
  firestoreReducer,
  reduxFirestore,
} from 'redux-firestore'; // <- needed if using firestore
import thunk from 'redux-thunk';

import { ENV } from '@/libs/const';

import { getFullSystemFields } from '@/libs/utils';

import { currentProjectReducer } from '@/reducers/currentProjectReducer';
import { currentViewIdReducer } from '@/reducers/currentViewIdReducer';
import { currentWorkspaceReducer } from '@/reducers/currentWorkspaceReducer';
import { dialogStateReducer } from '@/reducers/dialogStateReducer';

import { invitingReducer } from '@/reducers/invitingReducer';
import { itemsReducer } from '@/reducers/itemsReducer';
import { newItemDialogReducer } from '@/reducers/newItemDialogReducer';
import { staticMyWorkspacesReducer } from '@/reducers/staticMyWorkspacesReducer';
import { uiStateReducer } from '@/reducers/uiStateReducer';
import { viewsReducer } from '@/reducers/viewsReducer';

interface IReduxFirebaseProps {
  children: ReactNode;
}

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// react-redux-firebase config
const rrfConfig = {
  profileFactory: (userData: any) => {
    if (userData.operationType === 'signIn') {
      const account: Account = {
        uid: userData.user.uid,
        displayName:
          userData.user.displayName || userData.user.email.split('@')[0],
        email: userData.user.email,
        photoURL: userData.user.photoURL,
        language: i18next.resolvedLanguage,
        ...getFullSystemFields(userData.user.uid),
      };
      return account;
    }
    const account: Account = {
      uid: userData.uid,
      displayName: userData.displayName,
      email: userData.email,
      photoURL: userData.photoURL,
      language: i18next.resolvedLanguage,
      ...getFullSystemFields(userData.uid),
    };
    return account;
  },
  userProfile: 'accounts',
  // presence: 'presence', // where list of online users is stored in database
  // sessions: 'sessions', // where list of user sessions is stored in database (presence must be enabled)
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true,
  useFirestoreForStorageMeta: true,
  dispatchRemoveAction: true,
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const rdb = firebase.database();

// Initialize Performance Monitoring and get a reference to the service
firebase.performance();

db.settings({
  cacheSizeBytes: 10485760, // 10MB
});

// for Emulator
if (import.meta.env.VITE_NODE_ENV === ENV.DEV) {
  db.useEmulator('localhost', 8080);
  const auth = firebase.auth();
  auth.useEmulator('http://localhost:9099');
  const storage = firebase.storage();
  storage.useEmulator('localhost', 9199);
  rdb.useEmulator('localhost', 9000);
}

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  currentWorkspace: currentWorkspaceReducer,
  items: itemsReducer,
  staticMyWorkspaces: staticMyWorkspacesReducer,
  currentWorkspaceId: currentWorkspaceReducer,
  projectState: currentProjectReducer,
  currentViewId: currentViewIdReducer,
  dialogState: dialogStateReducer,
  invitingState: invitingReducer,
  newItemDialogState: newItemDialogReducer,
  views: viewsReducer,
  uiState: uiStateReducer,
});

// Create store with reducers and initial state
const store = legacy_createStore(
  rootReducer,
  compose(applyMiddleware(thunk), reduxFirestore(firebase)),
);

const rrfProps: ReactReduxFirebaseProviderProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export function ReduxFirebase(props: IReduxFirebaseProps) {
  const { children } = props;
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        {children}
      </ReactReduxFirebaseProvider>
    </Provider>
  );
}
export default memo(ReduxFirebase);
