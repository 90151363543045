import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * URL Query Paramsを使用するHook
 * URLSearchParamsがIE非対応なのでIEでは動作しません
 */
export default () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};
