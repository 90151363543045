import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import ColorMultiSelect, {
  ColorMultiSelectOption,
} from '@/components/Common/Forms/ColorMultiSelect';

import { ISearchFilter } from '@/hooks/useFilters';

export interface IFilterMultiSelectProps {
  idx: number;
}

/**
 * フィルターのマルチセレクトセレクト型の入力コンポーネント
 * @param props
 * @returns
 */
function FilterMultiSelect(props: IFilterMultiSelectProps) {
  const { idx } = props;

  // 提供されるFormProviderのコンテキストを参照
  const { getValues, setValue, trigger } = useFormContext<ISearchFilter>();

  const fields = getValues('searchProperties');

  // プロパティ値
  const property = useMemo(
    () => fields[idx].arrayValue,
    [fields[idx].arrayValue, idx],
  );

  // 選択肢一覧
  const options = useMemo(() => fields[idx]?.options || [], [fields, idx]);

  // 選択済み
  const selected = useMemo(() => {
    const inMultiList = (property as string[]).flatMap((p) =>
      options.filter((option) => option.id === p),
    );

    return inMultiList;
  }, [property, options]);

  // 値更新(配列の場合などで処理を分ける必要あり)
  const onSelect = useCallback(
    (newValues: ColorMultiSelectOption[]) => {
      const values = getValues('searchProperties');
      if (newValues) {
        // キー指定してvalue値を更新
        values[idx].arrayValue = (newValues ?? []).map((x) => x.id);
        setValue(`searchProperties.${idx}`, values[idx], {
          shouldDirty: true,
          shouldTouch: true,
        });
        trigger();
      }
    },
    [idx],
  );
  return (
    fields && (
      <div className="w-full h-full">
        <ColorMultiSelect
          selected={selected}
          options={options}
          onSelect={onSelect}
          optionDirection="top"
        />
      </div>
    )
  );
}
export default FilterMultiSelect;
