import { ILoginMode } from '@/@types/common';
import React, { createContext, ReactNode } from 'react';

export interface ILoginModeContextProps {
  children: ReactNode | ReactNode[];
  mode?: ILoginMode;
}

export const loginModeContext = createContext<ILoginMode | undefined>(
  undefined,
);

/**
 * ログインモード Context
 * @param props
 * @returns
 */
export default function LoginModeContext(props: ILoginModeContextProps) {
  const { children, mode } = props;

  return (
    <loginModeContext.Provider value={mode}>
      {children}
    </loginModeContext.Provider>
  );
}
