import { RootState, ToComment } from '@/@types/models';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useFirestore } from 'react-redux-firebase';

import { PAGINATE } from '@/libs/const';
import { COL_TO_COMMENTS, getToCommentsPath } from '@/libs/docPathUtils';

import useHandleApi from '@/hooks/useHandleApi';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePaginate from '@/hooks/usePaginate';

/**
 * ToCommentsのHooks
 * @returns { }
 */
export default function useToComments() {
  const rrfFirestore = useFirestore();
  const { exec } = useHandleApi();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  // ページネーション込みコメントリスト取得
  const {
    dataList: toComments,
    hasNext,
    loadNextPage,
  } = usePaginate<ToComment, 'toComments'>(
    getToCommentsPath(userId, workspaceId),
    undefined,
    ['sysCreatedAt', 'desc'],
    PAGINATE.TO_COMMENTS,
    COL_TO_COMMENTS,
    () => !workspaceId || !userId,
  );

  // コメント削除
  const deleteToComment = async (toCommentId: ToComment['id']) => {
    exec(async () => {
      if (!userId || !workspaceId || !toCommentId)
        throw new Error('invalid user');
      await rrfFirestore.delete(
        `${getToCommentsPath(userId, workspaceId)}/${toCommentId}`,
      );
    }, t('コメントの削除に失敗しました'));
  };

  return {
    toComments,
    hasNext,
    loadNextPage,
    deleteToComment,
  };
}
