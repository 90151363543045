import React from 'react';
import { useTranslation } from 'react-i18next';

interface IErrorPageProps {
  code: number;
  title: string;
  description?: string;
}

export default function ErrorPage(props: IErrorPageProps) {
  const { code, title, description } = props;
  const { t } = useTranslation();
  return (
    <div className="min-h-full px-4 py-16 bg-white dark:bg-gray-800 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-primary-500 dark:text-primary-600 sm:text-5xl">
            {code}
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-gray-300 sm:text-5xl">
                {title}
              </h1>
              {description && (
                <p className="mt-1 text-base text-gray-500 dark:text-gray-400">
                  {description}
                </p>
              )}
            </div>
            <div className="flex mt-10 space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a
                href="/"
                className="inline-flex items-center px-4 py-2 font-medium text-white border border-transparent rounded-md dark:text-white bg-primary-500 dark:bg-primary-600 shadow-sm hover:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600"
              >
                {t('ホームに戻る')}
              </a>
              {/* <a
                href="/"
                className="inline-flex items-center px-4 py-2 font-medium text-gray-500 bg-white border border-gray-300 rounded-md dark:border-gray-600 hover:bg-gray-100 focus:outline-none ring-0 focus:ring-2 dark:ring-gray-500 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600"
              >
                {t('サポートに連絡')}
              </a> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
