import {
  RootState,
  Project,
  MyProject,
  TempGuest,
  Guest,
} from '@/@types/models';
import { serverTimestamp } from 'firebase/firestore';
import i18next from 'i18next';
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useFirestore,
  useFirestoreConnect,
  ReduxFirestoreQuerySetting,
} from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';

import { SHARING_PERMISSION_TYPES } from '@/libs/const';
import {
  COL_GUESTS,
  COL_MY_PROJECTS,
  getGuestsPath,
  getInvitingGuestsPath,
  getMyProjectsPath,
  getProjectsPath,
  getTempGuestsPath,
} from '@/libs/docPathUtils';

import { getNavigatePath, getUpdateSystemFields } from '@/libs/utils';

import InvitingGuestRepository from '@/repositories/InvitingGuestRepository';

import { setCurrentProjectIdAction } from '@/reducers/currentProjectReducer';

import { IInviteEmail } from '@/components/Member/MemberAccountList';

import useHandleApi from '@/hooks/useHandleApi';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * projectsのリアルタイムアップデートを購読するためのHooks
 * @returns { }
 */
export default function useProjects() {
  const rrfFirestore = useFirestore();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { exec } = useHandleApi();
  const navigate = useNavigate();

  const { currentMyWorkspace } = useMyWorkspaces();

  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  const currentProjectId = useSelector(
    (state: RootState) => state.projectState?.currentProjectId,
  );

  useFirestoreConnect(() => {
    if (!userId || !workspaceId || !currentMyWorkspace) return [];

    const myProjectsQuery: ReduxFirestoreQuerySetting = {
      collection: getMyProjectsPath(userId, currentMyWorkspace.id),
      orderBy: ['projectName', 'asc'],
      storeAs: COL_MY_PROJECTS,
    };

    // ゲストの場合は絞り込みを追加
    if (
      ['unknownGuest', 'guest'].includes(currentMyWorkspace?.joinType as string)
    ) {
      myProjectsQuery.where = [['isShare', '==', true]];
    }

    return [myProjectsQuery];
  });

  const myProjects = useSelector(
    (state: RootState) => state.firestore.ordered.myProjects || [],
  );

  // カレントMyプロジェクト
  const currentMyProject = useMemo(
    () => myProjects?.find((p) => p.projectId === currentProjectId) || null,
    [myProjects, currentProjectId],
  );

  // 動的に参照先を変更するためanyで取得している
  const ordered = useSelector((state: any) => state.firestore.ordered);
  // useProjects内でuseGuestsを使用すると循環参照になるので、useGuestsと同様のロジックでguestsを取得
  const guests: Guest[] = useMemo(() => {
    if (
      currentMyProject?.projectId &&
      ordered[`${COL_GUESTS}/${currentMyProject?.projectId}`]
    )
      return ordered[`${COL_GUESTS}/${currentMyProject?.projectId}`];
    return [];
  }, [ordered, currentMyProject?.projectId]);

  /**
   * プロジェクト名更新
   * @param myProjectId
   * @param projectId
   * @param projectName
   */
  const updateProjectName = async (
    myProjectId: MyProject['id'],
    projectId: Project['id'],
    projectName: Project['projectName'],
  ) => {
    exec(async () => {
      if (
        !userId ||
        !workspaceId ||
        !currentMyWorkspace ||
        !myProjectId ||
        !projectId
      )
        throw new Error();

      const project: Project = {
        projectName,
        ...getUpdateSystemFields(userId),
      };

      await rrfFirestore.update<Project>(
        `${getProjectsPath(workspaceId)}/${projectId}`,
        project,
      );

      const myWsId = currentMyWorkspace.id as string;
      const newMyProject: MyProject = {
        projectId,
        projectName,
        ...getUpdateSystemFields(userId),
      };

      await rrfFirestore.update<MyProject>(
        `${getMyProjectsPath(userId, myWsId)}/${myProjectId}`,
        newMyProject,
      );
    }, t('プロジェクトの更新に失敗しました'));
  };

  /**
   * プロジェクト共有設定更新
   * @param docId
   * @param isShare
   * @param allowGuestCopy
   * @param allowLinkShare
   * @param sharingPermission
   * @param inviteEmails
   * @param removeIds
   * @param tempGuests
   */
  const updateProjectSharing = async (
    docId: Project['id'],
    isShare: Project['isShare'],
    allowGuestCopy: Project['allowGuestCopy'],
    allowLinkShare: Project['allowLinkShare'],
    sharingPermission: Project['sharingPermission'],
    inviteEmails: IInviteEmail[],
    removeIds: string[],
    tempGuests: IInviteEmail[],
  ) => {
    exec(async () => {
      const pnm = currentMyProject?.projectName;
      if (
        !workspaceId ||
        !currentMyWorkspace?.workspaceName ||
        !currentMyProject ||
        !pnm ||
        !docId ||
        allowGuestCopy === undefined ||
        allowLinkShare === undefined ||
        !sharingPermission ||
        !userId
      )
        throw new Error('invalid params');
      const project: Project = {
        isShare: isShare || false,
        allowGuestCopy: allowGuestCopy || false,
        allowLinkShare: allowLinkShare || false,
        sharingPermission:
          sharingPermission || SHARING_PERMISSION_TYPES.COMMENT,
        sysUpdatedAt: serverTimestamp(),
        sysUpdatedBy: userId,
      };

      await rrfFirestore.update<Project>(
        `${getProjectsPath(workspaceId)}/${docId}`,
        project,
      );
      const invitingGuestRepository = new InvitingGuestRepository();
      // invitingGuestの追加
      inviteEmails.forEach((o) => {
        // function：inviteGuestをコール
        invitingGuestRepository.inviteGuest(
          currentMyWorkspace.id,
          [docId],
          o.value,
          i18next.resolvedLanguage,
        );
      });

      // tempGestの追加
      tempGuests.forEach((o) => {
        const newTempGuest: TempGuest = {
          sysCreatedAt: serverTimestamp(),
          sysCreatedBy: userId,
          sysUpdatedAt: serverTimestamp(),
          sysUpdatedBy: userId,
        };
        rrfFirestore.set<TempGuest>(
          `${getTempGuestsPath(workspaceId, docId)}/${o.id}`,
          newTempGuest,
        );
      });

      // 招待中、既存ゲストの除外
      removeIds.forEach((id) => {
        const guest = guests.find((o) => o.uid === id); // ゲスト検索
        if (guest) {
          rrfFirestore.delete<Guest>(
            `${getGuestsPath(workspaceId, docId)}/${guest.uid}`,
          ); // ゲスト削除
        } else {
          rrfFirestore.delete(
            `${getInvitingGuestsPath(workspaceId, docId)}/${id}`,
          ); // 招待中ゲスト削除
        }
      });
    }, t('共有設定の更新に失敗しました'));
  };

  /**
   * プロジェクト削除
   * @param myProjectId
   * @param projectId
   */
  const deleteProject = async (
    myProjectId: MyProject['id'],
    projectId: Project['id'],
  ) => {
    exec(async () => {
      if (!workspaceId) throw new Error();

      await rrfFirestore.delete<Project>(
        `${getProjectsPath(workspaceId)}/${projectId}`,
      );

      await rrfFirestore.delete<MyProject>(
        `${getMyProjectsPath(userId, workspaceId)}/${myProjectId}`,
      );

      // カレントプロジェクトと削除対象プロジェクトが同一ならワークスペースURLに遷移
      if (currentProjectId === projectId) {
        navigate(
          getNavigatePath(currentMyWorkspace?.originalWorkspaceId as string),
        );
      }
    }, t('プロジェクトの削除に失敗しました'));
  };

  /**
   *
   * @param pid
   */
  const setCurrentProjectId = (pid?: string) => {
    dispatch(setCurrentProjectIdAction(pid));
  };

  /**
   *
   * @param id
   * @returns
   */
  const getMyProject = (id: string) => {
    const prj = myProjects.filter((p) => p.id === id);
    return prj.length !== 0 ? prj[0] : null;
  };

  // ゲストのうち、編集権限があるか
  const isReadOnly = useMemo(() => {
    const _joinType = currentMyWorkspace?.joinType;
    const _sharingPermission = currentMyProject?.sharingPermission;
    return (
      _joinType !== 'member' &&
      _sharingPermission !== SHARING_PERMISSION_TYPES.EDIT
    );
  }, [currentMyWorkspace, currentMyProject]);

  // 共有権限
  const sharingPermission = useMemo(
    () => currentMyProject?.sharingPermission,
    [currentMyProject],
  );

  /**
   * プロジェクトをアクセス済みに
   * @param myProject
   * @returns
   */
  const updateAccessedProject = async (myProject: MyProject) => {
    // アクセス済みの場合は何もしない
    if (myProject.isAccessed) return;
    exec(async () => {
      if (!userId || !workspaceId || !myProject) throw new Error();

      const updateMyProject: MyProject = {
        isAccessed: true,
        ...getUpdateSystemFields(userId),
      };

      await rrfFirestore.update<Project>(
        `${getMyProjectsPath(userId, workspaceId)}/${myProject.id}`,
        updateMyProject,
      );
    }, t('アクセス状態の更新に失敗しました'));
  };

  /**
   * コメントを既読にする
   * @returns
   */
  const updateMarkAsRead = useCallback(async () => {
    exec(async () => {
      if (!userId || !workspaceId || !currentProjectId) throw new Error();

      const updateMyProject: MyProject = {
        isCommentListUnRead: false,
        ...getUpdateSystemFields(userId),
      };

      await rrfFirestore.update<Project>(
        `${getMyProjectsPath(userId, workspaceId)}/${currentProjectId}`,
        updateMyProject,
      );
    }, t('コメントの既読化に失敗しました'));
  }, [workspaceId, currentProjectId, userId]);

  /**
   * プロジェクト説明の表示状態更新
   * @param isShowDescription
   * @returns
   */
  const updateDescriptionVisibility = useCallback(
    async (isShowDescription: boolean) => {
      exec(async () => {
        if (!userId || !workspaceId || !currentProjectId) throw new Error();

        const updateMyProject: MyProject = {
          isShowDescription,
          ...getUpdateSystemFields(userId),
        };

        await rrfFirestore.update<Project>(
          `${getMyProjectsPath(userId, workspaceId)}/${currentProjectId}`,
          updateMyProject,
        );
      }, t('説明欄の表示切り替えに失敗しました'));
    },
    [workspaceId, currentProjectId, userId],
  );

  return {
    myProjects,
    currentMyProject,
    updateProjectName,
    updateProjectSharing,
    deleteProject,
    setCurrentProjectId,
    getMyProject,
    isReadOnly,
    sharingPermission,
    updateAccessedProject,
    updateMarkAsRead,
    updateDescriptionVisibility,
  };
}
