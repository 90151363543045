import { ActionType } from '@/@types/common';
import { View } from '@/@types/models';

import actions from '@/libs/actions';

type Types = Pick<typeof actions, 'SET_CURRENT_VIEW_ID'>;

type Action = ActionType<
  Types,
  { SET_CURRENT_VIEW_ID: { payload: View['id'] } }
>;

export const currentViewIdReducer = (
  // eslint-disable-next-line default-param-last
  state: View['id'] | null = null,
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_CURRENT_VIEW_ID:
      return action.payload;
    default:
  }
  return state;
};

export const setCurrentViewId = (ws: View['id'] | null | undefined) => ({
  type: actions.SET_CURRENT_VIEW_ID,
  payload: ws,
});

export default {};
