import { OriginalColorType } from '@/@types/common';
import { Combobox } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { useCallback, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { classNames } from '@/libs/styleUtils';

import EllipsisText from '@/components/Common/EllipsisText';

import { PickerBadge } from '@/components/Common/PickerBadge';

import PopupTransition from '@/components/Common/Transitions/PopupTransition';

export interface ColorMultiSelectOption {
  id: string;
  text: string;
  color: OriginalColorType;
}

interface IColorMultiSelectProps {
  onSelect: (values: ColorMultiSelectOption[]) => void;
  options: ColorMultiSelectOption[];
  selected: ColorMultiSelectOption[];
  optionDirection?: 'top' | 'bottom';
}

export default function ColorMultiSelect(props: IColorMultiSelectProps) {
  const { onSelect, selected, options, optionDirection } = props;
  const { t } = useTranslation();

  /**
   * 該当のIDを選択済みリストから除外する
   */
  const removeSingle = useCallback(
    (id: string | number) => {
      onSelect(selected.filter((o) => o.id !== id));
    },
    [selected],
  );

  const notSelectedOptions = useMemo(
    () => options.filter((o) => !selected.map((so) => so.id).includes(o.id)),
    [options, selected],
  );
  return (
    <Combobox value={selected} onChange={onSelect} multiple>
      {({ open }) => (
        <div className="relative h-full">
          <Combobox.Button
            as="div"
            className="flex flex-wrap h-full pr-2 text-left text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-700 focus:outline-none dark:border-gray-600 shadow-sm dark:text-gray-300 hover:bg-gray-50 focus:ring-1 focus:ring-primary-500 focus:border-primary-500 dark:focus:ring-primary-600 dark:focus:border-primary-600 min-h-[2.4rem]"
            role="button"
          >
            {/* Combobox.Buttonだとキー操作が効かない問題に対するhack. https://github.com/tailwindlabs/headlessui/issues/1094#issuecomment-1107439664 */}
            <Combobox.Input
              onChange={() => {}}
              className="block w-0 h-0 p-0 m-0 overflow-hidden opacity-0"
            />
            {/* 選択済みタグ表示 */}
            {selected.length > 0 &&
              selected.map((o) => (
                <div
                  className="relative flex max-w-full py-1 pr-8 m-1 bg-gray-200 dark:bg-gray-500 dark:text-gray-300 rounded-md"
                  key={o.id}
                >
                  <div className="flex max-w-full space-x-1">
                    <PickerBadge
                      size="xs"
                      className="my-auto ml-2 shrink-0"
                      color={o.color}
                    />
                    <EllipsisText>{o.text}</EllipsisText>
                  </div>
                  <button
                    type="button"
                    className="absolute ml-1 right-2 top-2 shrink-0"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSingle(o.id);
                    }}
                  >
                    <XMarkIcon className="w-3" />
                  </button>
                </div>
              ))}
          </Combobox.Button>
          {notSelectedOptions.length > 0 && (
            <PopupTransition show={open}>
              <Combobox.Options
                className={classNames(
                  optionDirection === 'top' ? 'bottom-12' : 'mt-1',
                  'absolute z-30 w-full py-1 overflow-auto bg-white shadow-lg dark:bg-gray-700 rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none',
                )}
              >
                {notSelectedOptions.map((option) => (
                  <Combobox.Option key={option.id} value={option} as={Fragment}>
                    {({ active }) => (
                      <div
                        className={classNames(
                          'flex items-center w-full cursor-pointer relative py-2 pl-2 pr-4',
                          active
                            ? 'bg-primary-500 dark:bg-primary-600 text-white dark:text-white'
                            : '',
                        )}
                      >
                        <PickerBadge size="xs" color={option.color} />
                        <div className="w-full pl-2 truncate">
                          <span className=" align-middle">{option.text}</span>
                        </div>
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </PopupTransition>
          )}
          {notSelectedOptions.length === 0 && (
            <PopupTransition show={open}>
              <div className="absolute z-30 w-full p-3 mt-1 overflow-auto text-gray-400 bg-white shadow-lg dark:text-gray-500 dark:bg-gray-700 rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {t('選択肢がありません')}
              </div>
            </PopupTransition>
          )}
        </div>
      )}
    </Combobox>
  );
}
