import { RootState } from '@/@types/models';
import { IAccountDictionary, IMention } from '@/@types/viewItem';
import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import {
  COL_REMOVED_MEMBERS,
  getRemovedMembersPath,
} from '@/libs/docPathUtils';

import useMyWorkspaces from '@/hooks/useMyWorkspaces';

/**
 * RemovedMembersの集約に関するHooks
 * @returns { removedMembers }
 */
export default function useRemovedMembers() {
  const { currentMyWorkspace } = useMyWorkspaces();
  const workspaceId = useMemo(
    () => currentMyWorkspace?.workspaceId,
    [currentMyWorkspace],
  );

  const removedMembers = useSelector(
    (state: RootState) => state.firestore.ordered.removedMembers || [],
  );

  useFirestoreConnect(() =>
    workspaceId && currentMyWorkspace
      ? [
          {
            collection: getRemovedMembersPath(workspaceId),
            storeAs: COL_REMOVED_MEMBERS,
          },
        ]
      : [],
  );

  /**
   * メンバー一覧の表示名 画像リンクを取得するための辞書
   * [uid]: { photoURL, displayName }
   * @returns {IMemberDictionary}
   */
  const removedMembersDic = useMemo(
    () =>
      removedMembers.reduce<IAccountDictionary>((acc, cur) => {
        const value: IAccountDictionary['uid'] = {
          photoURL: cur.photoURL || null,
          displayName: cur.displayName ?? '',
          joinType: 'member',
        };
        acc[cur.id as string] = value;

        return acc;
      }, {}),
    [removedMembers],
  );

  const removedMemberMentions = useMemo(() => {
    const mentions = removedMembers.map((o) => {
      const mention: IMention = {
        id: o.id,
        photoURL: o.photoURL || null,
        value: o.id,
        name: o.displayName || '',
      };
      return mention;
    });
    return mentions;
  }, [removedMembers]);

  return { removedMembers, removedMembersDic, removedMemberMentions };
}
