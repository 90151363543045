import { ActionType, ValueOf } from '@/@types/common';

import actions from '@/libs/actions';

import { LOCAL_STORAGE_KEYS, THEMES } from '@/libs/const';
import { getLocalStorageInfo, setLocalStorageInfo } from '@/libs/utils';

type ThemeType = ValueOf<typeof THEMES>;

type Types = Pick<
  typeof actions,
  'SET_IS_LOADED' | 'SET_THEME' | 'SET_AFTER_LOGIN' | 'SET_SIDEBAR_MINIMIZE'
>;

type Action = ActionType<
  Types,
  {
    SET_AFTER_LOGIN: { payload: boolean };
    SET_IS_LOADED: { payload: boolean };
    SET_THEME: { payload: ThemeType };
    SET_SIDEBAR_MINIMIZE: { payload: boolean };
  }
>;

export interface IUiState {
  isLoaded: boolean;
  theme: ThemeType;
  isAfterLogin: boolean;
  isSidebarMinimize: boolean;
}

const initialState: IUiState = {
  isLoaded: false,
  theme: getLocalStorageInfo(LOCAL_STORAGE_KEYS.THEME) || THEMES.DEFAULT,
  isAfterLogin: false,
  isSidebarMinimize:
    getLocalStorageInfo(LOCAL_STORAGE_KEYS.SIDEBAR_MINIMIZE) || false,
};

export const uiStateReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: Action,
) => {
  switch (action.type) {
    case actions.SET_IS_LOADED: {
      return { ...state, isLoaded: action.payload };
    }
    case actions.SET_THEME: {
      const theme = action.payload;
      setLocalStorageInfo(LOCAL_STORAGE_KEYS.THEME, theme);
      return { ...state, theme };
    }
    case actions.SET_AFTER_LOGIN: {
      return { ...state, isAfterLogin: action.payload };
    }
    case actions.SET_SIDEBAR_MINIMIZE: {
      setLocalStorageInfo(LOCAL_STORAGE_KEYS.SIDEBAR_MINIMIZE, action.payload);
      return { ...state, isSidebarMinimize: action.payload };
    }
    default:
      return state;
  }
};

export const setIsLoaded = (isLoaded: boolean) => ({
  type: actions.SET_IS_LOADED,
  payload: isLoaded,
});

export const setTheme = (theme: ThemeType) => ({
  type: actions.SET_THEME,
  payload: theme,
});

export const setAfterLogin = (isAfterLogin: boolean) => ({
  type: actions.SET_AFTER_LOGIN,
  payload: isAfterLogin,
});

/**
 * サイドバーの最小化状態更新
 * @param isSidebarMinimize
 * @returns
 */
export const setSidebarMinimize = (isSidebarMinimize: boolean) => ({
  type: actions.SET_SIDEBAR_MINIMIZE,
  payload: isSidebarMinimize,
});

export default {};
