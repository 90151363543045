import { RootState } from '@/@types/models';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getMyWorkspaceList } from '@/libs/repositoryUtils';

import { getNavigatePath } from '@/libs/utils';

import AccountStatusesRepository from '@/repositories/AccountStatusesRepository';

import { setCurrentMyWorkspaceIdAction } from '@/reducers/currentWorkspaceReducer';
import { setIsLoaded } from '@/reducers/uiStateReducer';

/**
 * `/` ルートアクセスのルーティング制御
 * @returns void
 */
function useRouteNavigate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const { isAfterLogin } = useSelector((state: RootState) => state.uiState);
  const accountStatusesRepository = new AccountStatusesRepository();

  const resolve = useCallback(async () => {
    dispatch(setIsLoaded(false));
    const myWorkspaceList = await getMyWorkspaceList(userId);
    const accountStatus = await accountStatusesRepository.findById(
      userId,
      rrfAuth.isAnonymous,
    );
    if (myWorkspaceList.length > 0) {
      // 最終アクセスワークスペースIDが保管されていればそちらを優先する
      const { workspaceId, originalWorkspaceId } =
        myWorkspaceList.find(
          (o) => o.workspaceId === accountStatus?.lastWorkspaceId,
        ) || myWorkspaceList[0];
      dispatch(setCurrentMyWorkspaceIdAction(workspaceId));
      return navigate(getNavigatePath(originalWorkspaceId as string));
    }

    // ログイン直後にlocation.hrefが実行される前にget_startedを表示しないようにする
    if (isAfterLogin) return null;
    return navigate('/get_started');
  }, [isAfterLogin]);

  return {
    resolve,
  };
}

/**
 * `/` ルートアクセスの場合
 */
export default function Index() {
  const { resolve } = useRouteNavigate();

  useEffect(() => {
    resolve();
  }, []);

  // レンダリングは行わない
  return <div />;
}
