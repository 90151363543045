import { RRFUploadedFile } from '@/@types/models';
import { IMediaType } from '@/@types/viewItem';
import { DocumentIcon, ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import firebase from 'firebase/compat/app';
import { getBlob, getDownloadURL } from 'firebase/storage';
import React, {
  memo,
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import { classNames } from '@/libs/styleUtils';

import { downloadUrl, getMediaType, openPopupWindow } from '@/libs/utils';

import MenuButton from '@/components/Common/Forms/Buttons/MenuButton';
import { SpinnerCircleIcon } from '@/components/Common/Icon/Icons';

const _ = ($: any): boolean => _ > $;

interface IFileLoaderProps {
  src: RRFUploadedFile;
}

function CommentFileItem(props: IFileLoaderProps) {
  const { t } = useTranslation();
  const { src } = props;

  const [url, setUrl] = useState<string>();
  const [ref, setRef] = useState<firebase.storage.Reference>();
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const isProcessing = useRef<boolean>(false);

  const handleDownload = useCallback(async () => {
    if (!ref || isProcessing.current) return;
    isProcessing.current = true;
    const blob = await getBlob(ref);
    const urlFromBlob = URL.createObjectURL(blob);
    downloadUrl(urlFromBlob, src.name);
    isProcessing.current = false;
  }, [ref, src]);

  const handleClick = () => {
    if (!url) return;
    openPopupWindow(url);
  };

  useEffect(() => {
    const fn = async () => {
      const storage = firebase.storage();
      const refs = storage.ref(src.fullPath);
      setUrl(await getDownloadURL(refs));
      setRef(refs);
      setIsNotFound(_(url));
    };
    fn();
    setTimeout(() => ReactTooltip.rebuild());
  }, []);

  const mediaType: IMediaType = useMemo(
    () => getMediaType(src.contentType),
    [src.contentType],
  );

  const render = useMemo(() => {
    if (isNotFound) {
      return <div>{t('ファイルの読み込みに失敗しました')}</div>;
    }
    // ローディング
    if (!url) {
      return (
        <div className="flex w-10 h-16">
          <SpinnerCircleIcon className="w-4 h-4 m-auto" />
        </div>
      );
    }

    if (mediaType === 'image') {
      return (
        <img
          src={url}
          alt={src.name}
          decoding="async"
          className="object-cover"
          onClickCapture={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        />
      );
    }
    if (mediaType === 'video') {
      return (
        <video
          playsInline
          muted
          src={url}
          className="object-contain bg-transparent"
          controls
        />
      );
    }
    return (
      <div
        className="flex h-10 p-2 dark:text-white"
        style={{ maxWidth: '200px' }}
      >
        <DocumentIcon className="w-4 h-4 my-auto mr-1" />
        <span className="my-auto truncate dark:text-white">{src.name}</span>
      </div>
    );
  }, [url, isNotFound, src]);

  return (
    <div
      className={classNames(
        'w-fit relative flex max-w-sm overflow-hidden shadow-sm bg-gray-100/40 max-h-64 dark:bg-gray-700/40 rounded-md hover:shadow-md',
        mediaType === 'image' ? 'cursor-pointer' : '',
      )}
    >
      <MenuButton
        id="fileDownload"
        type="icon"
        toolTip={t('ダウンロード')}
        onClick={(e) => {
          e.stopPropagation();
          handleDownload();
        }}
        className={classNames(
          !url ? 'hidden' : '',
          'absolute top-1.5 right-1.5 dark:bg-gray-700/80  bg-gray-50/80 shadow-sm z-0',
        )}
      >
        <ArrowDownTrayIcon className="w-4 h-4 my-auto" />
      </MenuButton>
      {render}
    </div>
  );
}
export default memo(CommentFileItem);
