import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import React, { useEffect, useRef } from 'react';

import editorConfig from '@/components/Common/Lexical/configs/editorConfig';
import ClickableLinkPlugin from '@/components/Common/Lexical/plugins/ClickableLinkPlugin';

import UpdateStatePlugin, {
  IUpdateStatePluginHandler,
} from '@/components/Common/Lexical/plugins/UpdatePlugin';

interface ILexicalReadOnlyEditorProps {
  className?: string;
  initialData: string;
}

/**
 * Lexicalの保存データをHTML表示するためのコンポーネント
 * @param props
 * @returns
 */
function LexicalReadOnlyEditor(props: ILexicalReadOnlyEditorProps) {
  const { className, initialData } = props;
  const updateRef = useRef({} as IUpdateStatePluginHandler);
  const initialConfig = {
    namespace: 'readOnlyEditor',
    ...editorConfig,
    editable: false,
  };

  useEffect(() => {
    if (!updateRef.current) return;
    updateRef.current.updateState(initialData);
  }, [initialData]);

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        ErrorBoundary={LexicalErrorBoundary}
        placeholder=""
        contentEditable={<ContentEditable className={className} />}
      />
      <UpdateStatePlugin ref={updateRef} />
      <ClickableLinkPlugin />
    </LexicalComposer>
  );
}
export default LexicalReadOnlyEditor;
