import { MultiSelectArrayValue } from '@/@types/models';
import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import ColorMultiSelect, {
  ColorMultiSelectOption,
} from '@/components/Common/Forms/ColorMultiSelect';

import { IItemFormDefaultValues } from '@/hooks/useEditItem';

export interface IPropertyMultiSelectProps {
  idx: number;
}

/**
 * プロパティマルチセレクトセレクト型の入力コンポーネント
 * @param props
 * @returns
 */
function PropertyMultiSelect(props: IPropertyMultiSelectProps) {
  const { idx } = props;

  // 提供されるFormProviderのコンテキストを参照
  const { getValues, setValue, trigger } =
    useFormContext<IItemFormDefaultValues>();

  // プロパティ値
  const fields = getValues('properties');
  const property = useMemo(
    () => fields[idx].arrayValue,
    [fields[idx].arrayValue, idx],
  );

  // 選択肢一覧
  const options = useMemo(() => fields[idx]?.options || [], [fields, idx]);

  // 選択済み
  const selected = useMemo(() => {
    const inMultiList = (property as MultiSelectArrayValue[]).flatMap((p) =>
      options.filter((option) => option.id === p.stringValue),
    );

    return inMultiList;
  }, [property, options]);

  // 値更新(配列の場合などで処理を分ける必要あり)
  const onSelect = useCallback(
    (newValues: ColorMultiSelectOption[]) => {
      const values = getValues('properties');
      if (newValues) {
        // キー指定してvalue値を更新
        values[idx].arrayValue = (newValues ?? []).map(
          (x) => ({ stringValue: x.id } as MultiSelectArrayValue),
        );
        setValue(`properties.${idx}`, values[idx], {
          shouldDirty: true,
          shouldTouch: true,
        });
        trigger();
      }
    },
    [idx],
  );

  return (
    <div className="w-full h-full">
      <ColorMultiSelect
        selected={selected}
        options={options}
        onSelect={onSelect}
      />
    </div>
  );
}
export default PropertyMultiSelect;
