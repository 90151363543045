import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { KEY_ENTER_COMMAND } from 'lexical';

import { isHoldingCtrlOrCmd } from '@/libs/utils';

import { LowPriority } from '@/components/Common/Lexical/utils/lexicalUtils';

interface ICustomKeyBindingsPluginProps {
  onCtrlEnter?: () => void;
}

/**
 * Lexical用カスタムキーバインディングのプラグイン
 * @param props
 * @returns
 */
export default function CustomKeyBindingsPlugin(
  props: ICustomKeyBindingsPluginProps,
) {
  const { onCtrlEnter } = props;
  const [editor] = useLexicalComposerContext();

  /** カスタムのキーバインディング追加 From */

  /** Ctrl + Enter */
  editor.registerCommand(
    KEY_ENTER_COMMAND,
    (event: KeyboardEvent) => {
      if (isHoldingCtrlOrCmd(event) && onCtrlEnter) {
        onCtrlEnter();
      }
      return true;
    },
    LowPriority,
  );

  return null;
}
