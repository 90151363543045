import { EV } from '@/@types/events';
import {
  IPropertyType,
  IPropertyOptionType,
  Property,
  IPropertyFormatType,
} from '@/@types/models';
import { IProperty } from '@/@types/viewItem';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import {
  VALIDATION_MODE,
  PROPERTY_TYPE,
  NUMBER_PROPERTY_FORMAT_TYPE,
} from '@/libs/const';
import { revMatch, t2s, unwrapEV } from '@/libs/utils';

import { PROPERTY_NAME } from '@/libs/validations';

import FormDialog from '@/components/Common/FormDialog';
import FormButton from '@/components/Common/Forms/Buttons/FormButton';
import FormCol from '@/components/Common/Forms/FormCol';
import Input from '@/components/Common/Forms/Input';
import Select, { ISelectOption } from '@/components/Common/Forms/Select';

import MultiSelectForm from '@/components/Items/PropertyEditForms/MultiSelectForm';
import NumberForm from '@/components/Items/PropertyEditForms/NumberForm';
import SingleSelectForm from '@/components/Items/PropertyEditForms/SingleSelectForm';

import useSubmitState from '@/hooks/useSubmitState';

interface IPropertyAddDialogProps {
  zIndex: 10 | 20 | 30 | 40 | 50;
  onAddProperty: (newProperty: IProperty) => void;
  onClose: () => void;
}

export default function PropertyAddDialog(props: IPropertyAddDialogProps) {
  const { onAddProperty, onClose } = props;
  const { t } = useTranslation();
  // 選択可能データ型
  const options = [
    { value: PROPERTY_TYPE.TEXT, label: t('テキスト') },
    { value: PROPERTY_TYPE.SINGLE_SELECT, label: t('シングルセレクト') },
    { value: PROPERTY_TYPE.MULTI_SELECT, label: t('マルチセレクト') },
    { value: PROPERTY_TYPE.FILE, label: t('ファイル添付') },
    { value: PROPERTY_TYPE.CHECKBOX, label: t('チェックボックス') },
    { value: PROPERTY_TYPE.INCHARGE, label: t('担当者') },
    { value: PROPERTY_TYPE.DATE, label: t('日付') },
    { value: PROPERTY_TYPE.NUMBER, label: t('数値') },
  ];

  const [propertyType, setPropertyType] = useState<ISelectOption>(options[0]);
  const [tempPropertyOptions, setTempPropertyOptions] = useState<
    IPropertyOptionType[]
  >([]);
  // フォーム入力値初期値
  const defaultValues = {
    propertyName: '',
    propertyTypeOptions: [] as IPropertyOptionType[],
    propertyTypeFormat: (propertyType.value === PROPERTY_TYPE.NUMBER
      ? 'float'
      : null) as IPropertyFormatType | null,
  };

  // プロパティ追加モーダルステート初期化
  const clearState = () => {
    setTempPropertyOptions([]);
    setPropertyType(options[0]);
  };

  // Zod schema定義
  const schema = z.object({
    propertyName: z
      .string()
      .trim()
      .min(1, { message: t('入力してください') })
      .max(
        PROPERTY_NAME.max,
        t2s(t('<max>文字以内で入力してください', PROPERTY_NAME)),
      ),
    propertyTypeOptions: z
      .array(
        z.object({
          id: z.string(),
          text: z
            .string()
            .trim()
            .min(1, { message: t('入力してください') }),
          color: z
            .string()
            .trim()
            .min(1, { message: t('入力してください') }),
        }),
      )
      .optional(),
    propertyTypeFormat: z.nativeEnum(NUMBER_PROPERTY_FORMAT_TYPE).nullable(),
  });

  // Zod Form
  const methods = useForm({
    resolver: zodResolver(schema),
    mode: VALIDATION_MODE,
    defaultValues,
  });

  // 送信時処理
  const formSubmit = methods.handleSubmit((data) => {
    const docId = `__${new Date().getTime().toString()}`; // 新規追加のプロパティについては'__'を付与して既存と区別する
    const stringValue =
      data.propertyTypeOptions.length > 0 ? data.propertyTypeOptions[0].id : '';
    const saveOptions =
      propertyType.value === PROPERTY_TYPE.TEXT
        ? ([] as IPropertyOptionType[])
        : [...data.propertyTypeOptions];
    const saveFormat =
      propertyType.value === PROPERTY_TYPE.NUMBER
        ? data.propertyTypeFormat || NUMBER_PROPERTY_FORMAT_TYPE.FLOAT
        : null;
    const newProp: IProperty = {
      docId,
      propertyName: data.propertyName,
      stringValue,
      arrayValue: [],
      numberValue: null,
      booleanValue: false,
      propertyType: propertyType.value as IPropertyType,
      options: saveOptions,
      format: saveFormat,
      editOption: {
        name: true,
        content: true,
        delete: true,
      },
      isShare: true,
    };

    // 種別により変更可否のオプションを更新する
    // ※ プロパティパターンマッチ
    revMatch<IProperty, Property, void>(newProp)({
      text: () => {
        newProp.editOption.content = false;
      },
      file: () => {
        newProp.editOption.content = false;
      },
      singleSelect: () => {
        newProp.editOption.content = true;
      },
      multiSelect: () => {
        newProp.editOption.content = true;
      },
      date: () => {
        newProp.editOption.content = false;
        newProp.isDueDate = false;
      },
      checkbox: () => {
        newProp.editOption.content = false;
      },
      incharge: () => {
        newProp.editOption.content = false;
      },
      number: () => {
        newProp.editOption.content = true;
      },
    });
    methods.reset(defaultValues);
    onAddProperty(newProp);
    clearState();
    onClose();
  });

  // プロパティデータ型選択時
  const onSelectProperty = (e: EV<HTMLSelectElement>) => {
    const value = unwrapEV(e);
    const currentValues = methods.getValues();
    // シングルセレクト型 -> テキスト型の場合
    if (propertyType.value === PROPERTY_TYPE.SINGLE_SELECT) {
      // 現状選択肢の入力値をローカルに一時保存し データをクリア
      setTempPropertyOptions(currentValues.propertyTypeOptions);
      methods.setValue('propertyTypeOptions', []);
    } else {
      // テキスト -> シングルセレクトの場合 一時保存入力値をリストア
      methods.setValue('propertyTypeOptions', tempPropertyOptions);
    }
    setPropertyType((old) => {
      const newProperty =
        options.find((option) => option.value === value) ?? old;
      return newProperty;
    });
  };

  // キャンセル押下時ハンドラ
  const onCancelClick = () => {
    methods.reset(defaultValues);
    clearState();
    onClose();
  };

  // Submitボタン状態
  const isDisabledApply = useSubmitState(methods);

  return (
    <FormDialog
      onClose={() => {}}
      title={t('プロパティを追加')}
      onSubmit={formSubmit}
      top
      className="!max-w-2xl"
    >
      <FormProvider {...methods}>
        <div className="space-y-2">
          <FormCol>
            <Input name="propertyName" label={t('プロパティ名')} />
          </FormCol>
          <FormCol>
            <Select
              name="property-data-type"
              label={t('プロパティのデータ型')}
              options={options}
              value={propertyType.value}
              onChange={onSelectProperty}
            />
          </FormCol>
        </div>

        {/* 可変数領域 シングルセレクトが選ばれた場合は選択肢入力が表示される */}
        {propertyType.value === PROPERTY_TYPE.SINGLE_SELECT && (
          <SingleSelectForm />
        )}
        {/* 可変数領域 マルチセレクトが選ばれた場合は選択肢入力が表示される */}
        {propertyType.value === PROPERTY_TYPE.MULTI_SELECT && (
          <MultiSelectForm />
        )}
        {/* 可変数領域 数値が選ばれた場合はフォーマット入力が表示される */}
        {propertyType.value === PROPERTY_TYPE.NUMBER && (
          <NumberForm className="sm:max-w-xs" />
        )}
      </FormProvider>
      <>
        <FormButton
          id="addProperty"
          submit
          variant="primary"
          className="sm:w-auto sm:ml-3"
          disabled={isDisabledApply}
          onClick={formSubmit}
        >
          {t('追加')}
        </FormButton>
        <FormButton
          id="cancelAddProperty"
          className="mr-3 sm:mr-0 sm:w-auto"
          onClick={onCancelClick}
        >
          {t('キャンセル')}
        </FormButton>
      </>
    </FormDialog>
  );
}
