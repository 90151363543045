import { z } from 'zod';

// Min && Max 型の制約定義
export interface IVMinMax {
  min?: number;
  max: number;
}

/** workspaces */
export const WORKSPACE_NAME: IVMinMax = { max: 40 };
export const ORIGINAL_WORKSPACE_ID: IVMinMax = { max: 30 };
export const ORIGINAL_WORKSPACE_ID_REGEXP: RegExp =
  /(^[a-zA-Z0-9]$)|(^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$)/;

/** projects */
export const PROJECT_NAME: IVMinMax = { max: 30 };

/** views */
export const VIEW_NAME: IVMinMax = { max: 40 };
export const VIEW_TYPE = ['list', 'kanban', 'billboard'] as const;

/** inviteMembers */
export const INVITE_EMAIL: IVMinMax = { max: 256 };
export const INVITING_MEMBER_LANGUAGE: IVMinMax = { max: 5 };

/** Accounts */
export const DISPLAY_NAME: IVMinMax = { max: 30 };
export const EMAIL: IVMinMax = { max: 256 };

/** items */
export const TITLE: IVMinMax = { max: 100 };

/** リンクURLの最大文字数（IEを対象外としsafariの4096文字を最大とする） */
export const LINK_URL: IVMinMax = { max: 4096 };

/** properties */
export const PROPERTY_NAME: IVMinMax = { max: 30 };
export const PROPERTY_STRING: IVMinMax = { max: 500 };

/**
 * パスワードバリデーション用正規表現
 * ポリシー
 * ・英字大文字小文字、数字混合
 * ・８以上、100文字以下
 * ・一部の記号OK（.!_/`()[]+-=$#&@~）
 */
export const PASSWORD_REGEXP: RegExp =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d&.!_/`()\[\]\+\-=$#&@~]{8,100}$/;

/**
 * ZODのバリデーション実行時の型推論用関数
 * @param data
 * @param schema
 */
export function validateSchema<T extends z.AnyZodObject>(
  data: unknown,
  schema: T,
): asserts data is z.infer<T> {
  schema.parse(data);
}

const FILE_SIZE = 1024 * 1024 * 10;
export const AVATAR_FILES = {
  ext: ['gif', 'jpeg', 'jpg', 'png'],
  size: FILE_SIZE,
  magicNumber: /^(47494638|FFD8FF|89504E47)/i,
};

export const BODY_IMAGE_FILES = {
  ext: /(gif|jpeg|jpg|png)$/i,
  magicNumber: /^(47494638|FFD8FF|89504E47)/i,
};
