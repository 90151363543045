import { RootState } from '@/@types/models';
import { User } from 'firebase/auth';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

import { toast } from 'react-toastify';

import { FUNCTIONS_ERROR_DETAILS } from '@/libs/const';
import { COL_ACCOUNTS, getAccountsPath } from '@/libs/docPathUtils';

import AccountRepository from '@/repositories/AccountRepository';

import useHandleApi from '@/hooks/useHandleApi';

const accountRepo = new AccountRepository();

/**
 * アカウントに関するHooks
 * @returns { updateAccount }
 */
export default function useAccounts() {
  const { t } = useTranslation();
  const { exec } = useHandleApi();

  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);

  useFirestoreConnect(() =>
    userId
      ? [
          {
            collection: getAccountsPath(),
            doc: userId,
            storeAs: COL_ACCOUNTS,
          },
        ]
      : [],
  );

  const account = useSelector((state: RootState) => {
    const d = state.firestore.ordered.accounts;
    if (d && d.length > 0) return d[0];
    return null;
  });

  const deleteAccount = async (user: User) => {
    exec(async () => {
      const currentAccount = await accountRepo.findById(user.uid);
      if (!currentAccount) {
        throw new Error();
      }

      try {
        await accountRepo.deleteAccount();
      } catch (error: any) {
        if (
          error.details ===
          FUNCTIONS_ERROR_DETAILS.WORKSPACE_UNDER_CONTRACT_EXISTS
        ) {
          toast.error(
            t('このアカウントはいくつかのワークスペースを契約中です'),
          );
          return;
        }
        if (
          error.details ===
          FUNCTIONS_ERROR_DETAILS.WORKSPACE_ADMINISTRATOR_NEEDED
        ) {
          toast.error(
            t(
              'アカウント削除により管理者権限メンバーが存在しなくなるワークスペースが存在します',
            ),
          );
          return;
        }
        throw new Error();
      }

      window.location.href = '/login';
    }, t('アカウントの削除に失敗しました'));
  };

  const getMe = useMemo(() => account ?? undefined, [account]);

  return { account, deleteAccount, getMe };
}
