import { RootState } from '@/@types/models';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getMyProjectList,
  getMyWorkspaceList,
  routeHandleMyProjectQuery,
  routeHandleMyWorkspaceQuery,
} from '@/libs/repositoryUtils';

import { getNavigatePath } from '@/libs/utils';

import AccountStatusesRepository from '@/repositories/AccountStatusesRepository';

import { setCurrentMyWorkspaceIdAction } from '@/reducers/currentWorkspaceReducer';

import { setNewProjectDialogState } from '@/reducers/dialogStateReducer';

import { setIsLoaded } from '@/reducers/uiStateReducer';

import BasicLayout from '@/pages/layouts/BasicLayout';

import NewProject from '@/pages/projects/NewProject';

import useFirebaseAuth from '@/hooks/useFirebaseAuth';

import useMembers from '@/hooks/useMembers';

import useMonitorSubscription from '@/hooks/useMonitorSubscription';
import useMyWorkspaces from '@/hooks/useMyWorkspaces';
import usePlans from '@/hooks/usePlans';
import useProjects from '@/hooks/useProjects';

/**
 * `/:workspaceId` workspaceIdアクセスのルーティング制御
 * @returns void
 */
function useRouteNavigate() {
  const rrfAuth = useSelector((state: RootState) => state.firebase.auth);
  const urlParams = useParams();
  const { workspaceId: originalWorkspaceId } = urlParams;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logOutWithoutNavigation } = useFirebaseAuth();
  const accountStatusesRepository = new AccountStatusesRepository();

  const userId = useMemo(() => rrfAuth.uid, [rrfAuth]);
  const resolve = useCallback(async () => {
    dispatch(setIsLoaded(false));
    if (originalWorkspaceId) {
      // 要求されたWSの存在検証
      const workspaceQuery = routeHandleMyWorkspaceQuery(originalWorkspaceId);
      const myWorkspaceList = await getMyWorkspaceList(userId, workspaceQuery);

      const ws = myWorkspaceList.find(
        (o) => o.originalWorkspaceId === originalWorkspaceId,
      );

      if (myWorkspaceList.length > 0 && ws && ws.id) {
        const { joinType, id: myWorkspaceId, workspaceId } = ws;
        // myProjectの存在検証
        const query = routeHandleMyProjectQuery(joinType);
        const myProjectList = await getMyProjectList(
          userId,
          myWorkspaceId,
          query,
        );
        const accountStatus = await accountStatusesRepository.findById(
          userId,
          rrfAuth.isAnonymous,
        );

        if (myProjectList.length > 0) {
          // 最終アクセスプロジェクトIDが保管されていればそちらを優先する
          const { projectId } =
            myProjectList.find((o) => o.id === accountStatus?.lastProjectId) ||
            myProjectList[0];

          return navigate(getNavigatePath(originalWorkspaceId, projectId));
        }

        // プロジェクトに参加していないメンバー
        if (joinType === 'member') {
          dispatch(setCurrentMyWorkspaceIdAction(workspaceId));
          dispatch(setNewProjectDialogState(true));
          // return setIsLoaded(true);
          return dispatch(setIsLoaded(true));
        }
        // プロジェクトに参加していないゲスト
        if (joinType === 'guest') {
          return navigate('/404');
        }
        // プロジェクトに参加していない それ以外の権限
        await logOutWithoutNavigation();
      }
      return navigate('/404');
    }
    return null;
  }, [originalWorkspaceId]);

  return {
    resolve,
  };
}

/**
 * `/:workspaceId` workspaceアクセスの場合
 */
export default function Index() {
  const { resolve } = useRouteNavigate();
  const { isLoaded } = useSelector((state: RootState) => state.uiState);
  const { workspaceId: originalWorkspaceId } = useParams();

  useEffect(() => {
    resolve();
  }, [originalWorkspaceId]);

  // 必要なSubscriptionの開始をチェック
  const { currentMyWorkspace } = useMyWorkspaces();
  useProjects();
  useMembers();
  usePlans();

  const { isSubscriptionPrepared } = useMonitorSubscription([
    'myWorkspaces',
    'myProjects',
    'members',
    'plans',
  ]);

  // 必要な処理終了後はサブスクリプション & レンダリング
  if (!isLoaded || !currentMyWorkspace || !isSubscriptionPrepared)
    return <div />;
  return (
    <BasicLayout>
      <NewProject />
    </BasicLayout>
  );
}
