import { SystemProperties } from '@/@types/viewItem';
import { Popover } from '@headlessui/react';
import {
  ClockIcon,
  InformationCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import AvatarIcon from '@/components/Common/AvatarIcon';
import PopupTransition from '@/components/Common/Transitions/PopupTransition';

export interface SystemPropertyProps {
  systemProperties: SystemProperties;
}

/**
 * システムプロパティ
 * @param props
 * @returns
 */
export default function SystemProperty(props: SystemPropertyProps) {
  const { systemProperties } = props;
  const { t } = useTranslation();

  return (
    <Popover as="div" className="relative inline-block p-1 ml-auto">
      {({ open }) => (
        <>
          <Popover.Button
            type="button"
            data-tip={t('更新情報')}
            tabIndex={-1}
            className="inline-flex items-center justify-center p-1 text-gray-400 border border-transparent rounded-md hover:bg-primary-500 hover:text-white dark:hover:bg-primary-600 dark:hover:text-white focus:outline-none ring-2 ring-transparent focus:ring-offset-2 focus:ring-offset-white dark:focus:ring-offset-gray-800 focus:ring-primary-500 dark:focus:ring-primary-600"
          >
            <InformationCircleIcon className="w-4 h-4" aria-hidden="true" />
          </Popover.Button>
          <PopupTransition show={open}>
            <Popover.Panel className="absolute right-0 z-20 w-64 mt-2 overflow-y-auto bg-white shadow-lg sm:w-72 origin-top-right rounded-md dark:bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-600 focus:outline-none">
              {systemProperties &&
                systemProperties.sysCreatedBy &&
                systemProperties.sysUpdatedBy && (
                  <div>
                    <div className="p-2 border-b border-gray-200 max-h-14 dark:border-gray-600 grid gap-1 grid-cols-9">
                      <div className="flex items-center text-gray-800 col-span-3 dark:text-gray-100">
                        <UserIcon className="w-4 h-4 text-gray-400 mr-0.5" />
                        <span className="flex items-center text-xs">
                          {t('作成者')}
                        </span>
                      </div>
                      <div className="flex items-center flex-1 space-x-2 col-span-6">
                        <div>
                          <AvatarIcon
                            size="sm"
                            src={systemProperties.sysCreatedBy.photoURL}
                            avatarName={
                              systemProperties.sysCreatedBy.displayName
                            }
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="mr-0 font-normal break-all md:mr-2">
                            {systemProperties.sysCreatedBy.displayName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-2 border-b border-gray-200 min-h-14 dark:border-gray-600 grid gap-1 grid-cols-9 max-h-18">
                      <div className="flex items-center text-gray-800 col-span-3 dark:text-gray-100">
                        <ClockIcon className="w-4 h-4 text-gray-400 mr-0.5" />
                        <span className="flex items-center text-xs">
                          {t('作成日')}
                        </span>
                      </div>
                      <div className="flex items-center col-span-6">
                        {systemProperties.sysCreatedAt}
                      </div>
                    </div>
                    <div className="p-2 border-b border-gray-200 max-h-14 dark:border-gray-600 grid gap-1 grid-cols-9">
                      <div className="flex items-center  text-gray-800 col-span-3 dark:text-gray-100">
                        <UserIcon className="w-4 h-4 text-gray-400 mr-0.5" />
                        <span className="flex items-center text-xs">
                          {t('更新者')}
                        </span>
                      </div>
                      <div className="flex items-center flex-1 space-x-2 col-span-6">
                        <div className="flex items-center">
                          <AvatarIcon
                            size="sm"
                            src={systemProperties.sysUpdatedBy.photoURL}
                            avatarName={
                              systemProperties.sysUpdatedBy.displayName
                            }
                          />
                        </div>
                        <div className="items-center flex-1 min-w-0">
                          <p className="mr-0 font-normal break-all md:mr-2">
                            {systemProperties.sysUpdatedBy.displayName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="p-2 border-gray-200 min-h-14 dark:border-gray-600 grid gap-1 grid-cols-9 max-h-18">
                      <div className="flex items-center text-gray-800 col-span-3 dark:text-gray-100">
                        <ClockIcon className="w-4 h-4 text-gray-400 mr-0.5" />
                        <span className="flex items-center text-xs">
                          {t('更新日')}
                        </span>
                      </div>
                      <div className="flex items-center col-span-6">
                        {systemProperties.sysUpdatedAt}
                      </div>
                    </div>
                  </div>
                )}
            </Popover.Panel>
          </PopupTransition>
        </>
      )}
    </Popover>
  );
}
